import { DeleteButton } from "@mobilemind/common/src/components/DeleteButton"
import { Box, Stack, TextField } from "@mui/material"
import { FastFieldWithError } from "components/FastFieldWithError"
import { ReactComponent as VennDiagramIcon } from "../../../img/venn-diagram.svg"

type RubricDomainHeaderProps = {
  name: string
  index: number
  onRemove?: () => void
}

export function RubricDomainHeader({
  name,
  index,
  onRemove,
}: RubricDomainHeaderProps) {
  return (
    <Stack
      direction="row"
      useFlexGap={true}
      sx={{
        alignItems: "center",
        padding: 3,
        paddingBottom: 1,
        backgroundColor: "#b1e3f3",
        gap: 4,
      }}
    >
      <VennDiagramIcon
        style={{ position: "relative", top: -5 }}
        width={35}
        height={35}
      />
      <FastFieldWithError
        size="medium"
        as={TextField}
        name={`${name}.name`}
        variant="standard"
        multiline
        label="Domain Name"
        margin="normal"
        required={true}
      />
      <Box
        sx={{
          paddingInline: 3,
          paddingBlock: 1,
          borderRadius: "20px",
          backgroundColor: "white",
          fontWeight: "bold",
          textTransform: "uppercase",
          fontSize: 12,
          whiteSpace: "nowrap",
        }}
      >
        Domain {index + 1}
      </Box>
      {onRemove && <DeleteButton onClick={onRemove} />}
    </Stack>
  )
}
