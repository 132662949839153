import { Stack, Box, Typography, alpha } from "@mui/material"

import nl2br from "nl2br"
import he from "he"
import CalendarIcon from "../../../../img/calendar-white.svg"
import ConferenceIcon from "../../../../img/conference-white.svg"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import moment from "moment"

type CardProps = {
  event: SessionResult
  onClick: () => void
  isSelected: boolean
}

type SessionResult = {
  created: string
  field_description: string
  field_event_image: string
  field_hex_color: string
  field_parent_conference: string
  field_event_date_time_value: string
  name: string
  id: string
  uuid: string
}

export function SessionImportResult({ event, onClick, isSelected }: CardProps) {
  const RESULT_CARD_HEIGHT = 120

  return (
    <li onClick={onClick} style={{ cursor: "pointer", marginBottom: 15 }}>
      <Box
        sx={(theme) => ({
          height: RESULT_CARD_HEIGHT,
          overflow: "hidden",
          borderRadius: "10px",
          boxShadow: 1,
          outline: isSelected
            ? "4px solid " + theme.palette.secondary.main
            : "4px solid transparent",
          "&:hover": {
            backgroundColor: alpha(theme.palette.secondary.main, 0.15),
          },
        })}
      >
        <Stack direction="row" spacing={2}>
          <Box
            sx={(theme) => ({
              flexShrink: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: RESULT_CARD_HEIGHT,
              height: RESULT_CARD_HEIGHT,
              backgroundSize: "cover",
              backgroundColor:
                event.field_hex_color.length > 0
                  ? event.field_hex_color
                  : theme.palette.primary.main,
              backgroundImage:
                "url(" +
                process.env.REACT_APP_API_URL +
                event.field_event_image +
                ")",
            })}
          >
            {!event.field_event_image && (
              <img
                alt={"Calendar"}
                src={CalendarIcon}
                width={RESULT_CARD_HEIGHT / 2.5}
                height={RESULT_CARD_HEIGHT / 2.5}
              />
            )}
          </Box>

          <Box
            sx={{
              flex: 1,
              paddingBlock: 1.5,
              "& p": {
                fontSize: 12,
                overflow: "hidden",
                maxHeight: "3.9em",
                lineHeight: "1.3em",
                paddingRight: 2,
              },
            }}
          >
            <Box>
              <Stack
                sx={{ alignItems: "center" }}
                direction={"row"}
                spacing={1}
              >
                <Typography sx={{ flex: 1 }} variant="h3">
                  {he.decode(event.name)}
                </Typography>
                <strong
                  style={{
                    paddingRight: 10,
                    fontSize: 12,
                    opacity: 0.6,
                    paddingTop: 2,
                  }}
                >
                  {moment(event.field_event_date_time_value).format("M/D/YYYY")}
                </strong>
              </Stack>
              <Typography
                sx={{
                  // @todo - add this to the theme somehow
                  backgroundColor: "#00587c",
                  paddingInline: 2,
                  paddingBlock: 0.5,
                  display: "inline-flex",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: 3,
                  marginTop: 1,
                  fontSize: 12,
                }}
                component="span"
              >
                <img
                  alt={"Conference"}
                  src={ConferenceIcon}
                  width={12}
                  height={12}
                  style={{ marginRight: 5 }}
                />
                {he.decode(event.field_parent_conference)}
              </Typography>
            </Box>
            <SanitizedHTML
              html={nl2br(
                he
                  .decode(event.field_description)
                  .replaceAll("<p>", "")
                  .replaceAll("</p>", "")
              )}
            />
          </Box>
        </Stack>
      </Box>
    </li>
  )
}
