import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"

import KnowBe4Icon from "../../img/kb4.svg"

import he from "he"

import "../../styles/reports.scss"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import { useAppSelector } from "../../store/hooks"
import ProgressBar from "@mobilemind/common/src/components/ProgressBar"
import { generateTimestamp } from "@mobilemind/common/src/functions"
import moment from "moment"
import _ from "lodash"
import pluralize from "pluralize"

function KnowBe4ComplianceTable(props) {
  const compliance = useAppSelector((state) => state.compliance)
  const { filters } = compliance

  const activeCampaign = compliance.knowBe4CampaignList.find(
    (campaign) =>
      Number(campaign.campaign_id) === Number(filters.knowBe4Campaign)
  )

  const filteredData = compliance.data.filter((row) => {
    const hasSearch = filters.knowBe4Search
    const matchesFirst =
      hasSearch &&
      row.first_name.toLowerCase().includes(filters.knowBe4Search.toLowerCase())
    const matchesLast =
      hasSearch &&
      row.last_name.toLowerCase().includes(filters.knowBe4Search.toLowerCase())

    return !filters.knowBe4Search || matchesFirst || matchesLast
  })

  let sortBy =
    filters.sortBy === "field_first_name_value" ? "first_name" : "last_name"
  if (filters.sortBy === "field_status_value") {
    sortBy = "completion_date"
  }

  const sortedData = _.orderBy(filteredData, [sortBy], ["asc"])
  const data = sortedData
  const compliantUsersCount = compliance.data.filter(
    (row) => row.completion_date
  ).length

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <div className="flexRow">
              {compliantUsersCount.toLocaleString()}{" "}
              {pluralize("Learner", compliantUsersCount)} Completed this
              Campaign
            </div>
          </TableCell>
          <TableCell>
            <div className="entityName">
              <img
                src={KnowBe4Icon}
                alt={"KnowBe4"}
                style={{ height: 25, width: 25, marginRight: 10 }}
              />
              {activeCampaign.name}
            </div>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {data.length > 0 &&
          data.map((row) => {
            const numCourses = row.courseStatuses.length ?? 0
            const numComplete = row.courseStatuses?.filter(
              (course) => course === "Passed"
            ).length

            return (
              <TableRow
                key={row.userId}
                className={row.status === "Completed" ? "active" : "inactive"}
              >
                <TableCell>
                  <div className="flexRow" style={{ padding: 15 }}>
                    <div
                      className="userInfo flexRow"
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div className="flexRow">
                        <UserLabel
                          firstName={he.decode(row.first_name)}
                          lastName={he.decode(row.last_name)}
                          imagePath={null}
                          subgroups={""}
                        />
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell align={"right"}>
                  {!row.completion_date ? (
                    <div className="complianceProgress">
                      <span>
                        {numComplete} / {numCourses} Passed
                      </span>
                      <ProgressBar
                        width={(numComplete / numCourses) * 100}
                        trackWidth={0}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <strong>
                        Completed{" "}
                        {generateTimestamp(moment(row.completion_date))}
                      </strong>
                      <span style={{ marginLeft: 10 }} className="icon check" />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  )
}

export default KnowBe4ComplianceTable
