import { Button, Stack } from "@mui/material"
import { SecondaryNavbar } from "components/SecondaryNavbar"
import { useState } from "react"
import { saveCourse } from "../../actions/courses"
import ModalCopy from "../../components/ModalCopy"
import { ReactComponent as CommunityIcon } from "../../img/community-white.svg"
import { ReactComponent as CopyIcon } from "../../img/copy.svg"
import { useAppDispatch } from "../../store/hooks"
import "../../styles/navbar.scss"
import { CourseFormValues } from "./types"

type CourseTemplateNavbarProps = {
  course: CourseFormValues
}

export const CourseTemplateNavbar = (props: CourseTemplateNavbarProps) => {
  const { course } = props
  const dispatch = useAppDispatch()

  const [isCopyOpen, setIsCopyOpen] = useState(false)

  return (
    <>
      <ModalCopy
        type={"course"}
        open={isCopyOpen}
        path={"/create/courses"}
        isTemplate={true}
        copy={() => {
          dispatch(
            saveCourse({
              activeCourse: course,
              isDraft: true,
              isCopy: true,
              fromTemplate: true,
            })
          )
        }}
        onClose={() => {
          setIsCopyOpen(false)
        }}
      />
      <SecondaryNavbar
        icon={<CommunityIcon width={25} height={25} />}
        title="Community Course"
        isTemplate={true}
      >
        <Stack direction="row" spacing={4}>
          <Button
            size="small"
            startIcon={<CopyIcon width={20} height={20} />}
            onClick={() => setIsCopyOpen(true)}
          >
            Use This Course
          </Button>
        </Stack>
      </SecondaryNavbar>
    </>
  )
}
