import { createSlice } from "@reduxjs/toolkit"

export const roomsSlice = createSlice({
  name: "roomsSlice",
  initialState: {
    data: [],
    lastFetch: [],
    fetched: false,
  },
  reducers: {
    addRoom: (state, action) => {
      state.data.push({
        id: "newRoom-" + state.data.length,
        attributes: {
          name: "",
        },
        relationships: {
          field_location: {
            data: {
              id: action.payload,
            },
          },
        },
      })
    },
    archiveRoom: (state, action) => {
      state.data = state.data.filter((room) => room.id !== action.payload.id)
    },
    updateRoom: (state, action) => {
      let targetRoom = state.data.find(
        (room) => room.id === action.payload.room.id
      )
      targetRoom.attributes[action.payload.field] = action.payload.value

      if (action.payload.field === "field_room_number_name") {
        targetRoom.attributes.name = action.payload.value
      }
    },
  },
})

export const { addRoom, updateRoom } = roomsSlice.actions

export default roomsSlice.reducer
