import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import Loading from "@mobilemind/common/src/components/Loading"
import { generateCalendarTimestamp } from "@mobilemind/common/src/functions"
import theme from "@mobilemind/common/src/theme/theme"
import { Card } from "@mui/material"
import he from "he"
import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { setSidebarItem } from "../../store/reducers/calendar"
import { fetchEvents } from "../../store/reducers/dashboard"

const WidgetUpcomingEvents = () => {
  const calendar = useAppSelector((state) => state.dashboard.widgets.calendar)
  const dispatch = useAppDispatch()

  const [isFetching, setFetching] = useState(false)

  useEffect(() => {
    if (!calendar.fetched && !isFetching) {
      setFetching(true)
      dispatch(
        fetchEvents({
          min: moment().startOf("day").format("YYYY-MM-DD"),
          max: moment().add(1, "month").format("YYYY-MM-DD"),
        })
      )
    }
  }, [calendar.fetched, isFetching, dispatch])

  return (
    <Card className="dashboard-widget widget-events">
      <header style={{ backgroundColor: theme.palette.secondary.main }}>
        Upcoming Events
      </header>
      <div className="inner" style={{ padding: 0 }}>
        {calendar.data.length > 0 ? (
          <ul style={{ margin: 0, padding: 0, listStyleType: "none" }}>
            {calendar.data.map((event: any) => {
              const isConference = event.field_award_credit_for_conferenc
                ? true
                : false
              let path = isConference
                ? "/events/view/conference/" + event.id
                : "/events/view/event/" + event.id

              return (
                <li className="flexRow hoverable" key={event.id}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Link style={{ width: "100%" }} to={path}>
                      <div
                        style={{
                          padding: 10,
                          alignItems: "center",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: isConference
                              ? "#00587C"
                              : "#59C3E5",
                            width: 12,
                            height: 12,
                            marginRight: 8,
                            borderRadius: 20,
                          }}
                        />
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <strong style={{ fontSize: 12 }}>
                            {he.decode(event.name)}
                          </strong>
                          <strong style={{ opacity: 0.5, fontSize: 12 }}>
                            {generateCalendarTimestamp(event.start_date)}
                          </strong>
                        </div>
                      </div>
                    </Link>
                  }{" "}
                </li>
              )
            })}
          </ul>
        ) : (
          <>
            {calendar.fetched ? (
              <>
                <span className="icon calendar" />
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  You have no upcoming events.
                </p>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <Loading message={"Getting events..."} />
              </div>
            )}
          </>
        )}
        <footer>
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Link
              onClick={() => dispatch(setSidebarItem("Event Calendar"))}
              to="/events"
            >
              <ButtonSmall>Go To Events</ButtonSmall>
            </Link>
          }{" "}
        </footer>
      </div>
    </Card>
  )
}

export default WidgetUpcomingEvents
