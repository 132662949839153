import { Dialog, Stack, Box, Typography } from "@mui/material"

import { CourseFormValues } from "../../types"
import MobilePhoneTemplate from "../../../../img/iphone-template.png"
import IdeaBox from "../../../../img/idea-box.svg"
import PencilBox from "../../../../img/pencil-box.svg"
import { MobileAppCourseHeader } from "./MobileAppCourseHeader"

type ModalProps = {
  onClose: () => void
  open: boolean
  course: CourseFormValues
}

export const MobileAppPreviewModal = (props: ModalProps) => {
  const { open, onClose, course } = props

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      className="modal"
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          padding: 6,
          minHeight: 600,
          minWidth: 300,
        }}
      >
        <img
          style={{ zIndex: 4, pointerEvents: "none", position: "absolute" }}
          src={MobilePhoneTemplate}
          alt="Mobile Phone Template"
          width={300}
        />

        <Box
          sx={() => ({
            position: "absolute",
            top: 56,
            left: 56,
            right: 56,
            bottom: 50,
            overflow: "hidden",
            borderTopLeftRadius: 35,
            borderBottomLeftRadius: 35,
            borderTopRightRadius: 35,
            backgroundColor: "white",
            borderBottomRightRadius: 35,
          })}
        >
          <Box
            sx={{
              maxHeight: 440,
              overflow: "auto",
              marginRight: -2,
              paddingTop: 31,
            }}
          >
            <Box sx={{ position: "absolute", top: 0, right: 0, left: 0 }}>
              <Box
                sx={(theme) => ({
                  padding: 2,
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: theme.palette.primary.main,
                })}
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "white", marginTop: 5 }}
                >
                  Course
                </Typography>
              </Box>
              <MobileAppCourseHeader course={course} />
            </Box>

            <Stack direction="column" spacing={5} sx={{ padding: 5 }}>
              <Box>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ marginBottom: 2, alignItems: "center" }}
                >
                  <img src={IdeaBox} width={25} height={25} alt="Learn" />
                  <Typography variant="h3">Learn</Typography>
                </Stack>
                <Typography sx={{ lineHeight: 1.3, fontSize: 12 }}>
                  {course.objective}
                </Typography>
              </Box>

              <Box>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ marginBottom: 2, alignItems: "center" }}
                >
                  <img src={PencilBox} width={25} height={25} alt="Apply" />
                  <Typography variant="h3">Apply</Typography>
                </Stack>
                <Typography sx={{ lineHeight: 1.3, fontSize: 12 }}>
                  {course.content}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
