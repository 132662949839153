import { Component } from "react"
import { connect } from "react-redux"
import { saveBadge, deleteBadge } from "../../actions/badges"
import NavbarSecondary from "../../components/NavbarSecondary"
import ModalDelete from "../../components/ModalDelete"
import ModalBadgePreview from "../../components/ModalBadgePreview"
import { getCategories } from "../../store/reducers/categories"
import { isArrayIdentical } from "../../functions"
import { isGroupLevel } from "../../functions"

import { updateField } from "./activeBadgeSlice"

import "../../styles/navbar.scss"

const mapStateToProps = ({ activeBadge, categories, session }) => {
  return {
    activeBadge,
    categories,
    session,
  }
}

const mapDispatchToProps = {
  saveBadge,
  updateField,
  deleteBadge,
  getCategories,
}

class BadgeNavbarContainer extends Component {
  state = {
    isDeleteOpen: false,
    isPreviewOpen: false,
  }

  componentDidMount = () => {
    !this.props.categories.fetched && this.props.getCategories()
  }

  saveDraft = () => {
    this.props.saveBadge(this.props.activeBadge, true).then(() => {
      this.props.history.push("/create/badges")
    })
  }

  deleteDraft = () => {
    this.props.deleteBadge(this.props.activeBadge.id).then(() => {
      this.props.history.push("/create/badges")
    })
  }

  publish = () => {
    const { activeBadge } = this.props
    this.props.history.push("/create/badges")

    this.props.saveBadge(activeBadge).then((response) => {
      const hasSameCourses =
        !activeBadge.originalCourses.length ||
        isArrayIdentical(activeBadge.originalCourses, activeBadge.courses)
      const hasSameSubGroups =
        !activeBadge.originalSubGroups.length ||
        isArrayIdentical(activeBadge.originalSubGroups, activeBadge.subGroups)
      const hasSameJobTitles =
        !activeBadge.originalJobTitles.length ||
        isArrayIdentical(activeBadge.originalJobTitles, activeBadge.jobTitles)

      if (
        !activeBadge.id ||
        activeBadge.field_draft ||
        !hasSameCourses ||
        !hasSameSubGroups ||
        !hasSameJobTitles
      ) {
        let windowParams =
          "left=300,top=350,width=525,height=250,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        const confirmWindow =
          process.env.REACT_APP_API_URL +
          "/user_badge/backfill/" +
          response.attributes.drupal_internal__id
        window.open(confirmWindow, "", windowParams)
      }
    })
  }

  highlightFields = (missingFields) => {
    this.props.updateField({ field: "missingFields", value: missingFields })
  }

  render() {
    const { activeBadge, categories, session } = this.props

    let missingFields = []
    let missingDraftFields = []
    !activeBadge.name && missingFields.push("Badge Name")
    !activeBadge.name && missingDraftFields.push("Badge Name")
    !activeBadge.image && missingFields.push("Image")
    !activeBadge.courses.length &&
      missingFields.push("Include at least 1 course")

    if (
      isGroupLevel(session) &&
      session.subgroups &&
      session.subgroups.data &&
      session.subgroups.data.length > 1
    ) {
      if (!activeBadge.subGroups.length) {
        missingFields.push("Make visible to at least 1 group")
      }
    }

    const draftCourses = activeBadge.courses.filter(
      (course) => course.attributes.field_draft
    )
    if (draftCourses.length) {
      missingFields.push(
        <>
          Draft courses must be either published or removed from this Badge.
          <ul style={{ marginTop: 10 }}>
            {draftCourses.map((course) => {
              return <li>{course.attributes.name}</li>
            })}
          </ul>
        </>
      )
    }

    let canSave = activeBadge.name
    let canPublish = !missingFields.length

    const props = {
      type: "Badge",
      iconClass: "icon badge",
      publish: this.publish,
      saveDraft: this.saveDraft,
      onHover: this.highlightFields,
      openDeleteModal: () => {
        this.setState({ isDeleteOpen: true })
      },
      openPreview: (type) => {
        this.setState({ isPreviewOpen: true, previewType: type })
      },
      activeItem: activeBadge,
      canSave,
      canPublish,
      missingDraftFields,
      missingFields,
      groupRoles: session.groupRoles,
      orgRoles: session.orgRoles,
    }

    return (
      <>
        <ModalDelete
          type={"badge"}
          open={this.state.isDeleteOpen}
          path={"/create/badges"}
          deleteItem={this.deleteDraft}
          onClose={() => {
            this.setState({ isDeleteOpen: false })
          }}
        />
        <ModalBadgePreview
          badge={activeBadge}
          open={this.state.isPreviewOpen}
          type={this.state.previewType}
          categories={categories}
          user={session.user}
          onClose={() => {
            this.setState({ isPreviewOpen: false })
          }}
        />
        <NavbarSecondary {...props} />
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BadgeNavbarContainer)
