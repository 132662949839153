import { GenieRewriteAllowedField } from "@mobilemind/genie-api/src/types"
import { TextField } from "@mui/material"
import { FastFieldWithError } from "components/FastFieldWithError"
import { useContext } from "react"
import { GenieContext } from "./GenieContext"

export const ReviewerInstructionsField = () => {
  const { getGenieElementProps } = useContext(GenieContext)
  return (
    <FastFieldWithError
      as={TextField}
      name="reviewerInstructions"
      variant="standard"
      multiline
      label="Reviewer Instructions"
      margin="normal"
      helperText={
        "Guidelines for the reviewer to determine whether the challenge was completed."
      }
      {...getGenieElementProps(
        GenieRewriteAllowedField.ReviewerInstructions,
        "reviewerInstructions"
      )}
    />
  )
}
