import { getCategories } from "@mobilemind/common/src/actions/course"
import Loading from "@mobilemind/common/src/components/Loading"
import { useAsyncEffect } from "@react-hook/async"
import { createNextState } from "@reduxjs/toolkit"
import { saveCourse } from "actions/courses"
import { resetFilters } from "features/courses/coursesSlice"
import { CreateCourseGenieStartValues } from "features/genie/common/types"
import { useEffect, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { getSubGroups } from "../../store/reducers/session"
import "../../styles/create.scss"
import {
  fetchActiveCourse,
  lockCourse,
  unlockCourse,
} from "./activeCourseSlice"
import { CourseForm } from "./components/CourseForm"
import { CourseFormValues } from "./types"
import { getInitialCourseFormValues } from "./utility"
import ContentLock from "components/ContentLock"

export const CourseFormContainer = () => {
  const history = useHistory()
  const location = useLocation<CreateCourseGenieStartValues>()
  const { id: courseId } = useParams<{
    id: string
  }>()

  const dispatch = useAppDispatch()

  const session = useAppSelector((state) => state.session)
  const categories = useAppSelector((state) => state.categories)
  const { editorLock, currentEditor } = useAppSelector(
    (state) => state.activeCourse
  )

  const [message, setMessage] = useState<string | null>(null)

  const {
    status,
    error,
    value: course,
  } = useAsyncEffect<CourseFormValues>(async () => {
    setMessage("Loading data…")
    if (!session.subGroups.fetched && session.group.uuid) {
      await dispatch(getSubGroups(session.group.uuid[0].value))
    }
    // @todo this may be unnecessary, since categories may only be used
    // by genie, and the generateCourse function currently re-fetches categories
    if (!categories.fetched) {
      await dispatch(getCategories())
    }

    // @ts-ignore
    dispatch(resetFilters())

    if (courseId) {
      setMessage("Getting course…")
      // Fetch the course
      return await dispatch(
        fetchActiveCourse({ id: courseId, template: false, shouldLock: true })
      ).unwrap()
    } else {
      if (location.state) {
        const { videoId, level, submissionType } = location.state
        return createNextState(getInitialCourseFormValues(), (draft) => {
          draft.field_video_link = `http://www.youtube.com/watch?v=${videoId}`
          draft.level = level
          draft.submissionType = submissionType
          draft.field_created_with_genie = true
          // Clear the state after course generation, unless "preserve state" mode is enabled.
          if (
            process.env.REACT_APP_CREATE_COURSE_GENIE_PRESERVE_STATE !== "TRUE"
          ) {
            history.replace(location.pathname, undefined)
          }
        })
      } else {
        return getInitialCourseFormValues()
      }
    }
  }, [courseId, categories.fetched])

  useEffect(() => {
    if (status === "success" && course?.id) {
      const { id } = course
      // Lock the course if the course is not locked, or
      // update the lock if the current user is the editor
      if (
        !editorLock ||
        !currentEditor ||
        currentEditor.id === session.user.id
      ) {
        dispatch(lockCourse(id))
        return () => {
          // Clear the course when the user leaves the page or the course ID changes
          dispatch(unlockCourse(id))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, course])

  if (error) {
    throw error
  }

  if (status !== "success" || !course) {
    return <Loading fullPage={true} message={message} />
  }

  const handleSubmit = async (values: CourseFormValues) => {
    await dispatch(saveCourse({ activeCourse: values, isDraft: false }))
    history.push("/create/courses")
  }

  return (
    <>
      {course.id &&
        editorLock &&
        currentEditor &&
        currentEditor.id !== session.user.id && (
          <ContentLock
            contentType={"course"}
            editor={currentEditor}
            id={course.id}
            type={"course_entity--course_entity"}
            editorLock={editorLock}
            updateLock={() => {
              if (course.id) {
                // Lock the course with the current user
                dispatch(lockCourse(course.id))
              }
            }}
          />
        )}
      <CourseForm initialValues={course} onSubmit={handleSubmit} />
    </>
  )
}
