import { isObject, isString, uniq } from "lodash"
import * as yup from "yup"

yup.setLocale({
  mixed: {
    // eslint-disable-next-line no-template-curly-in-string
    required: "${label} is required",
  },
})

// Transforms a yup error object to an array of unique strings
export const flatMapHelperText = (error?: object | string | null): string[] => {
  if (isObject(error)) {
    return uniq(Object.values(error).map(flatMapHelperText).flat())
  } else if (isString(error)) {
    return [error]
  }
  return []
}

export default yup
