import { Component } from "react"
import { connect } from "react-redux"
import { getOrgAnnouncements } from "../../actions/announcements"

import EntityList from "./EntityList"
import SidebarEntityList from "./SidebarEntityList"
import { fetchAnnouncementTypes } from "../announcementTypes/announcementTypesSlice"
import {
  setCurrentPage,
  increasePage,
  setSortOrder,
} from "../entityList/entityListSlice"
import Loading from "@mobilemind/common/src/components/Loading"
import { getSubGroups, getOrganizations } from "../../store/reducers/session"
import BottomScrollListener from "react-bottom-scroll-listener"

const mapStateToProps = ({ session, announcementTypes, entityList }) => {
  return {
    currentTab: entityList.orgAnnouncements.currentTab,
    orgAnnouncements: entityList.orgAnnouncements,
    filters: entityList.orgAnnouncements.filters,
    announcementTypes,
    entityList,
    session,
    fetched: entityList.orgAnnouncements.fetched,
  }
}

const mapDispatchToProps = {
  getOrgAnnouncements,
  fetchAnnouncementTypes,
  getSubGroups,
  getOrganizations,
  setCurrentPage,
  increasePage,
  setSortOrder,
}

class AnnouncementsListContainer extends Component {
  componentDidMount() {
    const { session } = this.props
    this.props.setCurrentPage("Announcements")
    !this.props.announcementTypes.fetched && this.props.fetchAnnouncementTypes()
    !this.props.session.subGroups.fetched && this.props.getSubGroups()

    session.isSiteAdmin &&
      !session.organizations.fetched &&
      this.props.getOrganizations()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.announcementTypes.fetched !==
      this.props.announcementTypes.fetched
    ) {
      !this.props.orgAnnouncements.fetched &&
        this.props.getOrgAnnouncements(this.props.filters)
    }
  }

  handleScroll = () => {
    const { orgAnnouncements } = this.props.entityList

    const { currentPage, totalPages } = orgAnnouncements

    if (currentPage + 1 < totalPages) {
      this.props.increasePage({ type: "orgAnnouncements" })
      setTimeout(() => {
        this.props.getOrgAnnouncements()
      }, 450)
    }
  }

  render() {
    const { fetched, currentTab, filters, entityList, session } = this.props

    const props = {
      entityType: "Announcement",
      iconClass: "icon announcement",
      createPath: "/create/announcements/new",
      setSortOrder: this.props.setSortOrder,
      total: entityList.orgAnnouncements.totalRecords,
      fetched,
      filters,
      session,
      isSiteAdmin: session.isSiteAdmin,
      currentTab,
    }

    const sidebarProps = {
      type: "Announcements",
      filters,
      entityList,
      session,
      organizations: session.organizations,
      currentTab,
      announcementTypes: this.props.announcementTypes.data,
      subGroups: session.subGroups,
      fetchData: this.props.getOrgAnnouncements,
    }

    if (this.props.announcementTypes.fetched) {
      return (
        <BottomScrollListener onBottom={this.handleScroll}>
          <SidebarEntityList {...sidebarProps} />
          <EntityList {...props} />
          {entityList.orgAnnouncements.isFetchingMore && (
            <div className="loadingMoreContainer">
              <Loading message={"Getting more announcements..."} />
            </div>
          )}
        </BottomScrollListener>
      )
    }
    return null
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementsListContainer)
