import { Box, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import { CourseFormValues } from "../types"
import { CourseMetaCapsule } from "./CourseMetaCapsule"

type FormHeaderProps = {
  course?: CourseFormValues
}
export const CourseFormHeader = ({ course }: FormHeaderProps) => {
  let values = useFormikContext<CourseFormValues>()?.values
  if (!values && course) {
    values = course
  }
  return (
    <>
      <Box
        sx={{
          position: "relative",
          padding: 3,
          height: 100,
          borderTopLeftRadius: "inherit",
          borderTopRightRadius: "inherit",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          backgroundColor: values.field_category
            ? values.field_category.attributes.field_hex_color
            : "primary.main",
        }}
      >
        {values.field_category && values.field_category.image && (
          <div
            style={{
              height: 130,
              position: "absolute",
              top: 0,
              overflow: "hidden",
              opacity: 0.2,
            }}
          >
            <img
              style={{
                width: 220,
                height: 220,
                border: "12px solid white",
                borderRadius: 150,
                marginTop: -45,
              }}
              src={
                process.env.REACT_APP_API_URL +
                values.field_category.image.attributes.uri.url
              }
              alt={values.field_category.attributes.name}
            />
          </div>
        )}
        <Typography
          variant="h1"
          color="white"
          align="center"
          sx={{
            ...(!values.name && { opacity: 0.5 }),
            zIndex: 1,
            textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          {values.name || "Course Title"}
        </Typography>
      </Box>
      <CourseMetaCapsule course={values} />
    </>
  )
}
