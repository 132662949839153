import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import {
  ButtonBase,
  Step,
  StepLabel,
  StepProps,
  useStepperContext,
} from "@mui/material"
import { useFormikContext } from "formik"
import { get } from "lodash"
import { forwardRef, useMemo } from "react"
import { useAppSelector } from "store/hooks"
import { challengeSchema, getSettingsSchema, overviewSchema } from "../schemas"
import { CourseFormValues } from "../types"
import { CourseFormStep } from "./CourseFormStepper"

type CourseFormStepButtonProps = StepProps & {
  step: number
}

export const CourseFormStepButton = forwardRef<
  HTMLButtonElement,
  CourseFormStepButtonProps
>((props: CourseFormStepButtonProps, ref) => {
  const { step, children, ...otherProps } = props
  const session = useAppSelector((state) => state.session)
  const { status, setStatus, values } = useFormikContext<CourseFormValues>()
  const { furthestStep } = status
  const stepperContext = useStepperContext()

  const error = useMemo(() => {
    switch (step) {
      case CourseFormStep.Overview: {
        return !overviewSchema.isValidSync(values)
      }
      case CourseFormStep.Challenge: {
        return !challengeSchema.isValidSync(values)
      }
      case CourseFormStep.Settings: {
        const settingsSchema = getSettingsSchema(session)
        return !settingsSchema.isValidSync(values)
      }
      default: {
        return false
      }
    }
  }, [session, step, values])

  const icon = useMemo(() => {
    const activeStep = get(stepperContext, "activeStep")
    // If this step has been reached and it isn't the active step,
    // show either the error or completed icon
    if (step !== activeStep && step <= furthestStep) {
      if (error) {
        return <ErrorIcon color="warning" />
      } else {
        return <CheckCircleIcon color="secondary" />
      }
    }
    return undefined
  }, [stepperContext, step, furthestStep, error])

  return (
    <Step
      ref={ref}
      component={ButtonBase}
      onClick={() => {
        setStatus({ ...status, activeStep: step })
      }}
      {...otherProps}
      sx={(theme) => ({
        paddingBlock: theme.spacing(4),
        marginBlock: theme.spacing(-4),
        "&>.MuiStepConnector-root": {
          top: `calc(12px + ${theme.spacing(4)})`,
        },
      })}
    >
      <StepLabel icon={icon}>{children}</StepLabel>
    </Step>
  )
})
