import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"

import {
  updateSummaryFilters,
  increaseSummaryPage,
} from "./activeConferenceSlice"
import Loading from "@mobilemind/common/src/components/Loading"
import AttendanceSummary from "../AttendanceSummary"
import "../styles/conference-attendance-summary.scss"
import classNames from "classnames"
import moment from "moment"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { useAppDispatch } from "store/hooks"

const mapStateToProps = ({ session }, ownProps) => {
  return {
    eventId: ownProps.match ? ownProps.match.params.id : "new",
    session,
  }
}

function ConferenceAttendanceSummary(props) {
  const { activeConference } = props
  const [hasfetched, setHasFetched] = useState(false)
  const dispatch = useAppDispatch()

  const { data, filters, isFetching, totalPages, currentPage } =
    activeConference.attendanceSummary
  const { search, sortBy, sortOrder } = filters

  function updateFilters({ field, value }) {
    dispatch(updateSummaryFilters({ field, value }))
  }

  useEffect(() => {
    if (!hasfetched) {
      dispatch(updateSummaryFilters())
      setHasFetched(true)
    }
  }, [hasfetched, dispatch])

  const atLastPage = currentPage + 1 === totalPages
  const showFooter = !atLastPage && totalPages > 1

  return (
    <div className="conference-attendance-summary">
      <div className="flexRow">
        <div className="session-filters flexRow">
          <Button
            onClick={() => {
              updateFilters({
                field: "sortOrder",
                value: sortOrder === "ASC" ? "DESC" : "ASC",
              })
            }}
            className="sort-button"
          >
            <span
              className={classNames(
                "icon sortOrder",
                sortOrder === "ASC" && "asc"
              )}
            />
          </Button>{" "}
          <div className="inputSearch">
            <span className="icon search" />
            <FormControl variant="standard">
              <TextField
                variant="standard"
                label="Search for sessions"
                value={search}
                onChange={(event) =>
                  updateFilters({ field: "search", value: event.target.value })
                }
              />
            </FormControl>
          </div>
          <FormControl variant="standard" className="inputSelect sortBy">
            <InputLabel id="label-sortBy-select">Sort By</InputLabel>
            <Select
              variant="standard"
              labelId="label-sortBy-select"
              id="sortBy-select"
              value={sortBy}
              onChange={(event) =>
                updateFilters({ field: "sortBy", value: event.target.value })
              }
            >
              <MenuItem value={"name"}>Session Name</MenuItem>
              <MenuItem value={"attended"}>Attended</MenuItem>
              <MenuItem value={"accepted"}>Accepted</MenuItem>
              <MenuItem value={"tentative"}>Tentative</MenuItem>
              <MenuItem value={"declined"}>Decline</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <ul id="summary-list">
        {data &&
          data.map((event) => {
            let roomName = !event.room_name ? "Virtual" : event.room_name

            return (
              <li key={event.session_id} className="row">
                <Link
                  style={{
                    borderColor: event.session_hex,
                  }}
                  to={"/events/view/session/" + event.session_id + "/summary"}
                >
                  <div className="title">
                    <div style={{ flex: 1 }}>
                      {event.session_name}
                      <div className="datetime">
                        {moment.unix(event.start_time).format("MMMM Do, h:mma")}{" "}
                        - {moment.unix(event.end_time).format("h:mma")}
                      </div>
                    </div>
                    <div className="roomInfo">
                      {roomName === "Virtual" ? (
                        <span className="icon laptop" />
                      ) : (
                        <span className="icon room" />
                      )}
                      {roomName}
                    </div>
                  </div>
                  <div
                    className={"flexRow"}
                    style={{
                      marginTop: -20,
                      flexWrap: "wrap",
                      paddingBottom: 10,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <AttendanceSummary
                        alwaysShowAttended={true}
                        attendance={{
                          accept_events: event.accept_count,
                          attended: event.attended,
                          decline_events: event.decline_count,
                          no_reply_events: event.no_reply_count,
                          tentative_events: event.tentative_count,
                          user_events: event.total_invited,
                        }}
                      />
                    </div>
                    {event.max_capacity > 0 && (
                      <div className="capsule">
                        <span className="icon chair white" />
                        {event.max_capacity - event.accept_count > 0 ? (
                          <>
                            {event.max_capacity - event.accept_count} /{" "}
                            {event.max_capacity} seats available
                          </>
                        ) : (
                          <>No seats available</>
                        )}
                      </div>
                    )}
                  </div>
                </Link>
              </li>
            )
          })}
      </ul>

      {showFooter && (
        <>
          <footer className="flexRow" style={{ justifyContent: "center" }}>
            {isFetching ? (
              <footer
                className="flexRow"
                style={{ margin: "15px 0", justifyContent: "center" }}
              >
                <Loading message="Getting sessions..." />
              </footer>
            ) : (
              <ButtonLarge
                onClick={() => {
                  dispatch(increaseSummaryPage())
                }}
              >
                Load More Sessions
              </ButtonLarge>
            )}
          </footer>
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(ConferenceAttendanceSummary)
