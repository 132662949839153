import { Box, TextField } from "@mui/material"
import { FastFieldWithError } from "components/FastFieldWithError"
import { ReactComponent as RubricIcon } from "../../../img/shopping-list.svg"
import { DeleteButton } from "@mobilemind/common/src/components/DeleteButton"

type RubricCriteriaHeaderProps = {
  name: string
  index: number
  onRemove: () => void
}

export function RubricCriteriaHeader({
  name,
  index,
  onRemove,
}: RubricCriteriaHeaderProps) {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        paddingInline: 3,
        paddingBlock: 4,
        minHeight: "48px!important",
        borderLeft: "4px solid black",
        backgroundColor: theme.palette.grey.A100,
        alignSelf: "flex-start",
      })}
    >
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "max-content",
        }}
      >
        <RubricIcon width={20} height={20} />
      </Box>
      <FastFieldWithError
        as={TextField}
        name={`${name}.name`}
        variant="standard"
        multiline
        label={`Criteria ${index + 1} Name`}
        margin="none"
        required={true}
        sx={{
          marginLeft: 2,
        }}
      />
      <FastFieldWithError
        as={TextField}
        name={`${name}.weight`}
        variant="standard"
        label="Weight"
        type={"number"}
        margin="none"
        sx={{
          width: 60,
          marginLeft: 4,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "max-content",
        }}
      >
        <DeleteButton onClick={onRemove} sx={{ marginTop: "16px" }} />
      </Box>
    </Box>
  )
}
