import { SkillLevel, SubmissionType } from "@mobilemind/common/src/types/course"
import { MobileMindUser } from "@mobilemind/common/src/types/session"
import { isArray, memoize } from "lodash"
import moment from "moment"
import { CourseFormValues } from "./types"
import getYouTubeID from "get-youtube-id"
import { genieApi } from "features/genie/common/genieApi"
import { YouTubeVideo } from "@mobilemind/genie-api/src/types"

export const getInitialCourseFormValues = (): CourseFormValues => {
  return {
    id: null,
    name: "",
    tags: [],
    archived: false,
    field_draft: false,
    field_category: null,
    estimatedTime: 0,
    objective: "",
    content: "",
    level: SkillLevel.Basic,
    resources: "",
    submissionType: SubmissionType.MultipleChoice,
    reviewMethod: "exact-match",
    exactMatch: "",
    includeOption: "any",
    phrases: ["", ""],
    question: "",
    field_video_link: "",
    learnerInstructions: "",
    reviewerInstructions: "",
    unauthorized: false,
    answerKeyId: null,
    hint: "",
    relatedCourses: [],
    checkboxOptions: [""],
    subGroups: [],
    jobTitles: [],
    users: [],
    quizPassThreshold: 75,
    quizId: null,
    showMissedQuestions: false,
    showCorrectAnswers: false,
    mobileFriendly: true,
    excludeFromExplore: false,
    shareSettings: ["credit"],
    creatorOrgName: "",
    isTemplate: false,
    supportEmail: "",
    subGroupOwner: null,
    reviewers: [],
    reviewerGroups: [],
    audioLink: "",
    mc_shuffle: false,
    answers: [
      {
        attributes: {
          field_mc_answer: "",
          field_mc_answer_type: "correct",
        },
      },
      {
        attributes: {
          field_mc_answer: "",
          field_mc_answer_type: "incorrect",
        },
      },
    ],
    quizQuestions: [
      {
        value: "",
        requireAllAnswers: false,
        answers: [
          {
            value: "",
            isCorrect: true,
          },
          {
            value: "",
            isCorrect: false,
          },
        ],
      },
      {
        value: "",
        requireAllAnswers: false,
        answers: [
          {
            value: "",
            isCorrect: true,
          },
          {
            value: "",
            isCorrect: false,
          },
        ],
      },
    ],
    shareWithCommunity: false,
    shareWithMM: false,
    releaseDate: moment().format(),
  }
}

export const transformCourseToFormValues = (course: any): CourseFormValues => {
  let state = getInitialCourseFormValues()

  state.subGroupOwner =
    course.relationships.field_subgroup_owner.data &&
    course.relationships.field_subgroup_owner.data.id

  if (course.answerData) {
    const { answerData } = course
    state.answerKeyId = answerData.id
    if (answerData.attributes.field_answer) {
      state.exactMatch = answerData.attributes.field_answer
      state.phrases = answerData.attributes.field_answer.split(",")
    }

    let operator = answerData.attributes.field_operator
    state.includeOption = operator === "and" ? "all" : "any"

    if (course.attributes.field_automatic_review) {
      if (answerData.attributes.field_exact) {
        state.reviewMethod = "exact-match"
      } else {
        state.reviewMethod = "include"
      }
    } else {
      state.reviewMethod = "manual"
    }
  } else {
    state.answerKeyId = null
    state.reviewMethod = "manual"
  }

  state.id = course.id
  state.name = course.attributes.name

  // Handle tags
  if (course.tags && course.tags.length) {
    state.tags = course.tags.map((tag: any) => {
      return {
        id: tag.id,
        uuid: tag.id,
        text: tag.attributes.name,
      }
    })
  }

  state.shareSettings = course.attributes.field_mm_community
  state.releaseDate = course.attributes.field_release_date ?? moment().format()

  state.excludeFromExplore = course.attributes.field_ex_from_lib
  state.requireAllAnswers = course.attributes.field_mc_require_all
    ? true
    : false

  state.archived = course.attributes.field_archive
  state.field_draft = course.attributes.field_draft
  state.field_category = course.attributes.field_category
    ? course.attributes.field_category
    : null
  state.estimatedTime = course.attributes.field_course_estimated_time
  state.objective = course.attributes.field_course_objective
    ? course.attributes.field_course_objective
    : ""
  state.content = course.attributes.field_course_content
    ? course.attributes.field_course_content
    : ""
  state.level = course.attributes.field_level
  state.hint = course.attributes.field_course_hint
    ? course.attributes.field_course_hint
    : ""
  state.resources = course.attributes.field_additional_resources
    ? course.attributes.field_additional_resources.value
    : ""
  state.submissionType = course.attributes.field_submission_type
  state.question = course.attributes.field_ques
    ? course.attributes.field_ques
    : ""
  state.field_video_link = course.attributes.field_video_link ?? ""
  state.learnerInstructions = course.attributes.field_teacher_instructions
    ? course.attributes.field_teacher_instructions
    : ""

  state.field_created_with_genie = Boolean(
    course.attributes.field_created_with_genie
  )

  // Reviewers
  if (
    course.users &&
    course.relationships.field_reviewers &&
    course.relationships.field_reviewers.data
  ) {
    state.reviewers = course.users.filter((user: MobileMindUser) => {
      return course.relationships.field_reviewers.data.find(
        (reviewer: MobileMindUser) => reviewer.id === user.id
      )
    })
  }

  state.reviewerGroups = course.relationships.field_reviewer_group
    ? course.relationships.field_reviewer_group.data
    : []

  state.shareSettings = course.attributes.field_mm_community
  state.displayCredit = course.attributes.field_credit_organization

  state.isFromTemplate =
    course.relationships.field_source_template &&
    course.relationships.field_source_template.data &&
    true
  state.isTemplate = course.type === "course_template--course_template"

  let multipleChoiceAnswers =
    course.attributes.field_multiple_choice_options &&
    course.attributes.field_multiple_choice_options.length
      ? course.attributes.field_multiple_choice_options
      : []

  if (multipleChoiceAnswers.length) {
    multipleChoiceAnswers.forEach((answer: any) => {
      if (answer.attributes.field_mc_answer === "null") {
        answer.attributes.field_mc_answer = ""
      }
    })
  }

  state.answers = multipleChoiceAnswers.length
    ? multipleChoiceAnswers
    : [
        {
          attributes: {
            field_mc_answer: "",
            field_mc_answer_type: "correct",
          },
        },
        {
          attributes: {
            field_mc_answer: "",
            field_mc_answer_type: "incorrect",
          },
        },
      ]

  state.mc_shuffle = course.attributes.field_mc_shuffle
  state.reviewerInstructions = course.attributes.field_reviewer
    ? course.attributes.field_reviewer
    : ""

  state.relatedCourses = course.relatedCourses
    ? course.relatedCourses.map((course: any) => {
        return {
          name: course.attributes.name,
          uuid: course.id,
        }
      })
    : []

  state.jobTitles = course.jobTitles ?? []
  state.subGroups = course.subGroups ?? []
  state.users = course.users ?? []
  state.checkboxOptions =
    course.attributes.field_checkbox_options &&
    course.attributes.field_checkbox_options.length
      ? course.attributes.field_checkbox_options
      : [""]

  if (
    isArray(course.attributes.field_support_email) &&
    course.attributes.field_support_email.length > 0
  ) {
    state.supportEmail = course.attributes.field_support_email[0]
  } else {
    state.supportEmail = ""
  }

  state.creatorOrg =
    course.relationships.field_creator_org &&
    course.relationships.field_creator_org.data

  state.mobileFriendly = course.attributes.field_mobile_friendly

  if (course.sourceTemplate) {
    state.sourceTemplate = course.sourceTemplate
  }

  if (course.attributes.field_creator_org_name) {
    state.creatorOrgName = course.attributes.field_creator_org_name
  }

  if (course.quizEntity) {
    state.quizId = course.quizEntity.id
    state.quizPassThreshold =
      Number(course.quizEntity.attributes.field_pass_rate) * 100
    state.showMissedQuestions =
      course.quizEntity.attributes.field_show_missed_questions
    state.showCorrectAnswers =
      course.quizEntity.attributes.field_show_correct_answers

    if (!course.quiz.questions.content) {
      state.quizQuestions = course.quiz.questions.map((question: any) => {
        let questionAnswers
        if (question && !question.answers.content) {
          questionAnswers = question.answers.map((answer: any) => {
            return {
              id: answer.answer_uuid,
              value: answer.value ? answer.value.replaceAll("***", '"') : "",
              isCorrect: answer.isCorrect,
            }
          })
        } else {
          questionAnswers = [
            {
              value: "",
              isCorrect: true,
            },
            {
              value: "",
              isCorrect: false,
            },
          ]
        }

        return {
          id: question.question_uuid,
          requireAllAnswers: question.require_all === "1" ? true : false,
          value:
            question.question === "missing"
              ? ""
              : question.question.replaceAll("***", '"'),
          answers: questionAnswers,
        }
      })
    }
  } else {
    state.quizId = null
    state.quizPassThreshold = 75
    state.showMissedQuestions = false
    state.showCorrectAnswers = false

    state.quizQuestions = [
      {
        value: "",
        requireAllAnswers: false,
        answers: [
          {
            value: "",
            isCorrect: true,
          },
          {
            value: "",
            isCorrect: false,
          },
        ],
      },
      {
        value: "",
        requireAllAnswers: false,
        answers: [
          {
            value: "",
            isCorrect: true,
          },
          {
            value: "",
            isCorrect: false,
          },
        ],
      },
    ]
  }
  return state
}

// `memoize` should ensure that the YouTubeVideo is fetched from the API only
// once per session. This is a lazy state management implementation, but
// redux feels like overkill here.
export const getYouTubeVideo = memoize(
  async (videoUrl: string): Promise<YouTubeVideo | null> => {
    const videoId = getYouTubeID(videoUrl)
    if (videoId) {
      try {
        const video = await genieApi
          .get(`/video/${videoId}`)
          .then<YouTubeVideo>((response) => response.data)
        return video
      } catch (err) {
        console.error(err)
      }
    }
    return null
  }
)
