import { Button, ButtonProps, styled } from "@mui/material"
import { useFormikContext } from "formik"
import { CourseFormStep } from "./CourseFormStepper"

type BackButtonProps = Partial<ButtonProps> & {
  step: CourseFormStep
}

export const BackButton = styled((props: BackButtonProps) => {
  const { step, ...otherProps } = props
  const { status, setStatus } = useFormikContext()
  return (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      onClick={() => {
        setStatus({ ...status, activeStep: step })
        window.scrollTo(0, 0)
      }}
      {...otherProps}
    >
      Back
    </Button>
  )
})(() => ({}))
