import SubmitTicketLayout from "@mobilemind/common/src/components/SubmitTicketLayout"

function SubmitTicket() {
  return (
    <SubmitTicketLayout
      returnButtonText="Go to Dashboard"
      portalId={"20989892"}
      formId={"73a04e11-0baa-481c-9e13-fb156fb1875a"}
    />
  )
}

export default SubmitTicket
