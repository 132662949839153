import { connect } from "react-redux"
import { useState, useEffect, useCallback } from "react"
import {
  getServiceAccount,
  updateServiceAccount,
  getJwtToken,
} from "../../actions/serviceAccount"
import moment from "moment"
import {
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
  Card,
  Box,
  Stack,
  Typography,
  Divider,
  Link,
} from "@mui/material"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import ModalServiceAccountToken from ".//ModalServiceAccountToken"
import Loading from "@mobilemind/common/src/components/Loading"
import classNames from "classnames"
import ServiceAccountPicture from "./ServiceAccountPicture"
import "../../styles/profile.scss"
import theme from "@mobilemind/common/src/theme/theme"
import { useAppDispatch } from "store/hooks"
const mapStateToProps = ({ session }) => {
  return {
    session,
  }
}

function ServiceAccountLayout(props) {
  const dispatch = useAppDispatch()
  const { session } = props
  const [isFetched, setFetched] = useState(false)
  const [imageFile, setImageFile] = useState([])
  const [csvFile, setcsvFile] = useState([])
  const [serviceAccount, setServiceAccount] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [serviceAccountPassword, setServiceAccountPassword] = useState("")
  const [jwtToken, setJwtToken] = useState("")
  const [expiry, setExpiry] = useState("")
  const [isExpired, setIsExpired] = useState(false)
  const [isIncorrectPassword, setisIncorrectPassword] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      let account = await dispatch(getServiceAccount())
      let included = account?.payload?.included

      if (included) {
        let image = included.filter(
          (included) => included.type === "file--image"
        )

        image.length ? setImageFile(image) : setImageFile([])

        let file = included.filter(
          (included) => included.type === "file--document"
        )

        file.length ? setcsvFile(file[file.length - 1]) : setcsvFile([])
      }

      setServiceAccount(account.payload.data)
      let token_expiry = account.payload.data.attributes.field_token_expiry
      setExpiry(token_expiry)
      setIsExpired(moment().isAfter(token_expiry))
      setFetched(true)
    }

    if (!isFetched) {
      fetchData()
    }
  }, [isFetched, dispatch])

  const setImage = (image) => {
    setImageFile(image)
  }

  let save = useCallback(
    async (event) => {
      let account = await dispatch(
        updateServiceAccount({
          field_create_new_subgroups: event.target.checked,
        })
      )
      setServiceAccount(account.payload.data)
      setIsSaving(true)

      setTimeout(() => {
        setIsSaving(false)
      }, 1800)
    },
    [dispatch]
  )

  let removeImage = useCallback(async () => {
    await dispatch(updateServiceAccount({}, true))
    setImageFile([])
    setIsSaving(true)

    setTimeout(() => {
      setIsSaving(false)
    }, 1800)
  }, [dispatch])

  let getToken = useCallback(
    async (serviceAccountInfo) => {
      let token = await dispatch(getJwtToken(serviceAccountInfo))
      if (token?.payload?.http_code && token.payload.http_code === "401") {
        setisIncorrectPassword(true)
      } else {
        setJwtToken(token.payload.id_token)
        setExpiry(token.payload.expiry)
        setIsExpired(moment().isAfter(token.payload.expiry))
        setisIncorrectPassword(false)
        setServiceAccountPassword("")
      }
    },
    [dispatch]
  )

  return (
    <div>
      {isFetched ? (
        <>
          {isSaving && (
            <div>
              <Loading fullPage={true} message="Saving Service Account..." />
            </div>
          )}

          <div className="service">
            <div className="page profile">
              <div
                className={classNames(
                  "flexRow profileWrapper",
                  isSaving && "locked"
                )}
              >
                <div className="profilePictureWrapper">
                  <ServiceAccountPicture
                    serviceAccountPicture={imageFile}
                    setImage={setImage}
                  />
                  {imageFile.length > 0 && (
                    <div className="tooltip">
                      <Button onClick={removeImage} className="button small">
                        Remove Image
                      </Button>
                    </div>
                  )}
                  {imageFile.length < 1 && (
                    <div className="tooltip">
                      Click or Drag and Drop
                      <br />
                      to Upload Image
                    </div>
                  )}
                </div>
                <div className="profileContent">
                  <h2>Service Account</h2>
                  <div className="profileForm">
                    <FormControlLabel
                      className="checkbox"
                      control={
                        <Checkbox
                          checked={
                            serviceAccount.attributes.field_create_new_subgroups
                          }
                          onChange={(event) => save(event)}
                          name="visible"
                        />
                      }
                      label="Create new subgroups during imports"
                    />
                    <div>
                      {!expiry ? (
                        <p>Your have not generated a JWT token yet.</p>
                      ) : isExpired ? (
                        <div>
                          Your current token expired on{" "}
                          <p
                            style={{ borderColor: "#bf4040" }}
                            className={classNames("date")}
                          >
                            <span className="red">
                              {moment(expiry).format(
                                "dddd, MMMM Do YYYY, h:mm:ss A"
                              )}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <div>
                          Your current token will expire on{" "}
                          <p
                            style={{
                              borderColor: theme.palette.secondary.main,
                            }}
                            className={classNames("date")}
                          >
                            <span className="green">
                              {moment(expiry).format(
                                "dddd, MMMM Do YYYY, h:mm:ss A"
                              )}
                            </span>
                          </p>
                        </div>
                      )}

                      <ButtonLarge
                        onClick={() => setIsModalOpen(true)}
                        className={"button primary large"}
                      >
                        <span className="icon generate-token" />
                        Generate New Token
                      </ButtonLarge>
                      <FormHelperText>
                        Generating a new token will expire your current token.
                      </FormHelperText>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"instruction"}>
              {csvFile.length < 1 ? (
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ marginBottom: 2 }}
                >
                  Your have not uploaded any files yet.
                </Typography>
              ) : (
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ marginBottom: 2 }}
                >
                  File&nbsp;
                  {
                    <Link
                      color="primary"
                      target="_blank"
                      href={
                        process.env.REACT_APP_API_URL +
                        csvFile.attributes.uri.url
                      }
                    >
                      <strong>{csvFile.attributes.filename}</strong>
                    </Link>
                  }
                  &nbsp;was uploaded{" "}
                  {moment(csvFile.attributes.created).format(
                    "MMMM Do YYYY, h:mm A"
                  )}
                </Typography>
              )}
            </div>
            <div className={"instruction"}>
              <Card variant="outlined" sx={{ maxWidth: 1000 }}>
                <Box sx={{ p: 2 }}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="baseline"
                  >
                    <Typography
                      color="text.secondary"
                      variant="h6"
                      sx={{ marginBottom: 2 }}
                    >
                      Upload your csv file
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="subtitle1"
                      sx={{ marginBottom: 2, paddingLeft: 1 }}
                    >
                      PATCH to this exact endpoint with your csv file as the
                      request body
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ marginBottom: 2, paddingLeft: 2 }}
                    >
                      {process.env.REACT_APP_API_URL +
                        "/api/service/user-upsert/csv/" +
                        session.serviceAccountId +
                        "?_format=json"}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="subtitle1"
                      sx={{ marginBottom: 2, paddingLeft: 1 }}
                    >
                      Use the following headers
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ marginBottom: 1, paddingLeft: 2 }}
                    >
                      Authorization: Bearer my_recieved_jwt_token
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ marginBottom: 1, paddingLeft: 2 }}
                    >
                      Content-Type: application/octet-stream
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ marginBottom: 1, paddingLeft: 2 }}
                    >
                      Accept: application/json
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ marginBottom: 1, paddingLeft: 2 }}
                    >
                      Content-Disposition: file; filename="myFileName.csv"
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body1"
                      align="left"
                      sx={{ marginTop: 2, marginBottom: 2, paddingLeft: 1 }}
                    >
                      CSV files must be formatted correctly or syncing will
                      fail. You can download a properly formatted csv of your
                      current MobileMind users{" "}
                      <Link
                        color="primary"
                        target="_blank"
                        href={
                          process.env.REACT_APP_API_URL +
                          "/org-portal/user-import"
                        }
                      >
                        <strong>here</strong>
                      </Link>
                      .
                    </Typography>
                  </Stack>
                  <Divider light />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="baseline"
                  >
                    <Typography
                      color="text.secondary"
                      variant="body1"
                      align="left"
                      sx={{ marginTop: 2, marginBottom: 2 }}
                    >
                      Early each morning, the MobileMind environment will be
                      synced with the data from your most recently uploaded
                      file.
                    </Typography>
                  </Stack>
                  <Divider light />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Typography
                      color="text.secondary"
                      variant="subtitle1"
                      sx={{ marginTop: 2, marginBottom: 1 }}
                    >
                      An example Postman request
                    </Typography>
                    <span className={classNames("postman request")} />
                  </Stack>
                  <Divider light />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Typography
                      color="text.secondary"
                      variant="subtitle1"
                      sx={{ marginTop: 2, marginBottom: 1 }}
                    >
                      A successful response will return:
                    </Typography>
                    <span className={classNames("postman response")} />
                  </Stack>
                </Box>
              </Card>
            </div>
          </div>
        </>
      ) : (
        <Loading message="Getting service account info..." />
      )}
      <>
        <ModalServiceAccountToken
          type={"token"}
          serviceAccountName={serviceAccount?.attributes?.name}
          serviceAccountId={serviceAccount?.attributes?.drupal_internal__uid}
          serviceAccountPassword={serviceAccountPassword}
          setServiceAccountPassword={(password) =>
            setServiceAccountPassword(password)
          }
          getJwtToken={getToken}
          jwtToken={jwtToken}
          isIncorrectPassword={isIncorrectPassword}
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
            setJwtToken("")
          }}
          dispatch={dispatch}
        />
      </>
    </div>
  )
}

export default connect(mapStateToProps)(ServiceAccountLayout)
