import { useState } from "react"
import { Button, Popover } from "@mui/material"
import { makeStyles } from "@mui/styles"
import classNames from "classnames"

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}))

function SubNav(props) {
  const {
    missingFields,
    missingDraftFields,
    publishLabel,
    showPublishButton,
    canSave,
    showDraftButton,
    canPublish,
    backgroundColor,
    draftLabel,
    navTitle,
    iconClass,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [missingFieldsList, setMissingFieldsList] = useState([])

  const open = Boolean(anchorEl)
  const classes = useStyles()

  const onHover = (event, missingFields) => {
    setMissingFieldsList(missingFields)
    if (missingFields.length) {
      setAnchorEl(event.currentTarget)
    }
    props.onHover(missingFields)
  }

  const onHoverEnd = () => {
    setAnchorEl(null)
    props.onHover([])
  }

  return (
    <div
      className={classNames("subnav", !props.fetched && "loading")}
      style={{ backgroundColor }}
    >
      <div className="inner flexRow" style={{ flex: 1 }}>
        {props.fetched && (
          <>
            <span className="title">
              <span className={iconClass} />
              {navTitle}
            </span>

            {props.children}

            {showDraftButton && !canSave && (
              <Button
                aria-owns={open ? "publish-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={(event) => {
                  onHover(event, missingDraftFields)
                }}
                onMouseLeave={() => {
                  onHoverEnd()
                }}
                className="button small"
                style={{ opacity: 0.5 }}
              >
                <span className="icon draft white" />
                {draftLabel ? <>{draftLabel}</> : <> Save Draft</>}
              </Button>
            )}
            {!canPublish && missingFields.length > 0 && showPublishButton && (
              <>
                <Button
                  aria-owns={open ? "publish-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={(event) => {
                    onHover(event, missingFields)
                  }}
                  onMouseLeave={() => {
                    onHoverEnd()
                  }}
                  className="button small"
                  style={{ opacity: 0.5 }}
                >
                  <span className="icon rocket white" />
                  {publishLabel}
                </Button>

                <Popover
                  id="publish-popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={() => setAnchorEl(null)}
                  disableRestoreFocus
                >
                  <p>Uh oh, you're missing the following:</p>
                  <ul>
                    {missingFieldsList.map((field, index) => {
                      return <li key={index}>{field}</li>
                    })}
                  </ul>
                </Popover>
              </>
            )}
          </>
        )}
        {!props.fetched && <div className="loading-small" />}
      </div>
    </div>
  )
}

export default SubNav
