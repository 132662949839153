import { useState } from "react"
import {
  Dialog,
  FormControl,
  Button,
  TextField,
  Alert,
  FormHelperText,
  Link,
} from "@mui/material"

import "../../styles/modal.scss"
import "../../styles/widget.scss"

function ModalServiceAccountToken(props) {
  const {
    onClose,
    serviceAccountName,
    serviceAccountId,
    setServiceAccountPassword,
    serviceAccountPassword,
    isIncorrectPassword,
    getJwtToken,
    jwtToken,
    open,
  } = props
  const [isCopying, setIsCopying] = useState(false)

  let serviceAccountInfo = {
    name: serviceAccountName,
    password: serviceAccountPassword,
  }

  function handleFocus(event) {
    event.target.select()
    document.execCommand("copy")
    setIsCopying(true)
    setTimeout(() => {
      setIsCopying(false)
      onClose()
    }, 2000)
  }

  return (
    <Dialog className="modal token-modal" onClose={() => onClose()} open={open}>
      <h2>
        Generate JWT Token
        <span
          onClick={() => {
            onClose()
          }}
          style={{}}
          className="icon close"
        />
      </h2>
      {jwtToken.length === 0 ? (
        <>
          <p>Please enter the service account password.</p>
          {isIncorrectPassword && (
            <Alert severity="error">Invalid password</Alert>
          )}
          <div className="flexRow">
            <TextField
              id="standard-password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="standard"
              onChange={(event) =>
                setServiceAccountPassword(event.target.value)
              }
            />
            <Button
              onClick={() => {
                getJwtToken(serviceAccountInfo)
              }}
              className="button small"
            >
              Retrieve Token
            </Button>
          </div>
          <div className="flexRow">
            <FormHelperText>
              {
                <Link
                  color="primary"
                  target="_blank"
                  href={
                    process.env.REACT_APP_API_URL +
                    "/user/" +
                    serviceAccountId +
                    "/edit"
                  }
                >
                  Change Password
                </Link>
              }
            </FormHelperText>
          </div>
        </>
      ) : (
        <>
          <p>
            Keep this token in a secure place, you will not be able to view it
            again after closing this modal.
          </p>
          <FormControl>
            <TextField
              variant="outlined"
              onFocus={(event) => {
                handleFocus(event)
              }}
              helperText={
                isCopying ? "Token copied to clipboard!" : "Click to copy token"
              }
              value={jwtToken}
            />
          </FormControl>
        </>
      )}
    </Dialog>
  )
}

export default ModalServiceAccountToken
