import { useEffect } from "react"
import { connect } from "react-redux"
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"
import classNames from "classnames"

import TeacherAccessNavbarContainer from "./TeacherAccessNavbarContainer"
import SidebarTeacherAccess from "./SidebarTeacherAccess"
import { Link } from "react-router-dom"
import { getSubGroups } from "../../store/reducers/session"
import {
  getTeacherAccess,
  increaseCurrentPage,
  setSortOrder,
} from "./teacherAccessSlice"
import moment from "moment"
import BottomScrollListener from "react-bottom-scroll-listener"
import Loading from "@mobilemind/common/src/components/Loading"
import he from "he"

import "../../styles/reports.scss"

import ErrorBoundary from "../../components/ErrorBoundary"
import { resetBackpack } from "../../store/reducers/backpack"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import { useAppDispatch } from "store/hooks"

const mapStateToProps = ({ teacherAccess, session }) => {
  return {
    teacherAccess,
    session,
  }
}

function TeacherAccessLayout(props) {
  const dispatch = useAppDispatch()
  if (!props.session.subGroups.fetched && !props.session.subGroups.fetching) {
    dispatch(getSubGroups(props.session.group.uuid[0].value))
  }

  if (props.session.subGroups.fetched) {
    return <TeacherAccessReport {...props} />
  }
  return null
}

function TeacherAccessReport(props) {
  const { filters } = props.teacherAccess
  const dispatch = useAppDispatch()

  let groupId
  if (
    props.session.orgRoles.includes("organization-admin") ||
    props.session.orgRoles.includes("organization-reporter")
  ) {
    groupId = props.session.group.id[0].value
  } else if (
    props.session.groupRoles.includes("group-admin") ||
    props.session.groupRoles.includes("group-reporter")
  ) {
    groupId = props.session.subgroup.id[0].value
  }

  useEffect(() => {
    dispatch(
      getTeacherAccess({
        filters: {
          group: filters.group,
          groupAdminGroup: filters.groupAdminGroup,
          page: props.teacherAccess.currentPage,
          sortBy: filters.sortBy,
          sortOrder: filters.sortOrder,
        },
        searchQuery: props.teacherAccess.searchQuery,
        orgRoles: props.session.orgRoles,
        groupId,
        subGroups: props.session.subGroups.data,
      })
    )
  }, [
    filters.sortBy,
    filters.sortOrder,
    filters.groupAdminGroup,
    props.teacherAccess.searchQuery,
    filters.group,
    dispatch,
    props.teacherAccess.currentPage,
    props.session.group.id,
    props.session.subGroups.data,
    groupId,
    props.session.orgRoles,
  ])

  function handleScroll() {
    // If we aren't already fetching more results
    if (!props.teacherAccess.fetching) {
      // And if there are more pages to fetch
      if (
        props.teacherAccess.currentPage <
        props.teacherAccess.totalPages - 1
      ) {
        dispatch(increaseCurrentPage())
      }
    }
  }

  return (
    <ErrorBoundary>
      <div className="page hasSidebar reports teacherAccess">
        <TeacherAccessNavbarContainer />
        <SidebarTeacherAccess {...props} />

        <BottomScrollListener onBottom={handleScroll} />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <div
                  style={{ cursor: "pointer" }}
                  className="flexRow"
                  onClick={() => dispatch(setSortOrder())}
                >
                  <span
                    className={classNames(
                      "icon sort",
                      filters.sortOrder === "ASC" && "flipped"
                    )}
                  />
                  <span className="total">
                    {props.teacherAccess.totalItems.toLocaleString()}
                  </span>{" "}
                  Users
                </div>
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Last Login</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.teacherAccess.data.length > 0 &&
              props.teacherAccess.data.map((user) => {
                let fiveDaysAgo = moment().subtract(5, "days")
                let today = moment()
                let useCalendar = moment(user.access).isBetween(
                  fiveDaysAgo,
                  today
                )
                let accessDisplay = useCalendar
                  ? moment(user.access).subtract(0, "days").calendar()
                  : moment(user.access).format("MMMM Do, YYYY h:mm A")
                if (!moment(user.access).unix()) {
                  accessDisplay = "Never"
                }

                return (
                  <TableRow key={user.uid}>
                    <TableCell>
                      <div className="flexRow">
                        <div className="userInfo flexRow">
                          <Link
                            onClick={() => {
                              dispatch(resetBackpack())
                            }}
                            to={"/backpack/" + user.uid}
                          >
                            <UserLabel
                              firstName={he.decode(user.field_first_name)}
                              lastName={he.decode(user.field_last_name)}
                              imagePath={
                                process.env.REACT_APP_API_URL +
                                user.user_picture
                              }
                              subgroups={user.field_subgroup}
                            />
                          </Link>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Link to={"/backpack/" + user.uid}>
                        <span>{user.mail}</span>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={"/backpack/" + user.uid}>
                        <span>{accessDisplay}</span>
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>

        {props.teacherAccess.fetching && (
          <Loading
            message={
              props.teacherAccess.currentPage > 0
                ? "Getting more results..."
                : "Getting results..."
            }
          />
        )}
      </div>
    </ErrorBoundary>
  )
}

export default connect(mapStateToProps)(TeacherAccessLayout)
