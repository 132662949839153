import { connect } from "react-redux"
import NavbarSecondary from "../../components/NavbarSecondary"
import { generateReportExportURL } from "../../functions"

import "../../styles/navbar.scss"

const mapStateToProps = ({ leaderboards, session }) => {
  return {
    session,
    leaderboards,
  }
}

function LeaderboardsNavbarContainer(props) {
  const { filters } = props.leaderboards
  const { session } = props
  const subGroupId =
    props.session.subgroup && props.session.subgroup.id[0].value

  let exportArgs = {
    reportType:
      props.session.orgRoles.includes("organization-admin") ||
      props.session.orgRoles.includes("organization-reporter")
        ? "leaderboard-" + filters.leaderboardType
        : "group-leaderboard-" + filters.leaderboardType,
    orgId:
      props.session.orgRoles.includes("organization-admin") ||
      props.session.orgRoles.includes("organization-reporter")
        ? props.session.group.id[0].value
        : subGroupId,
    subGroup: props.session.subGroups.data.find(
      (group) => group.id === filters.group
    ),
    orgRoles: props.session.orgRoles,
    filters,
  }

  const navbarProps = {
    type: "Leaderboards",
    activeItem: {},
    iconClass: "icon leaderboards",
    onHover: () => {},
    exportLink: generateReportExportURL(exportArgs, session),
    isPartner: props.session.isPartner,
  }

  return <NavbarSecondary {...navbarProps} />
}

export default connect(mapStateToProps)(LeaderboardsNavbarContainer)
