import { Component } from "react"
import { connect } from "react-redux"
import { getOrgGoals } from "../../actions/goals"
import SidebarEntityList from "./SidebarEntityList"

import EntityList from "./EntityList"
import Loading from "@mobilemind/common/src/components/Loading"

import { setCurrentPage, increasePage, setSortOrder } from "./entityListSlice"

import { getSubGroups, getOrganizations } from "../../store/reducers/session"
import { getJobTitles } from "../jobTitles/jobTitlesSlice"
import BottomScrollListener from "react-bottom-scroll-listener"

const mapStateToProps = ({ session, jobTitles, entityList }) => {
  return {
    currentTab: entityList.orgGoals.currentTab,
    orgGoals: entityList.orgGoals,
    filters: entityList.orgGoals.filters,
    session,
    jobTitles,
    entityList,
    fetched: entityList.orgGoals.fetched,
  }
}

const mapDispatchToProps = {
  getOrgGoals,
  getSubGroups,
  getJobTitles,
  setCurrentPage,
  increasePage,
  getOrganizations,
  setSortOrder,
}

class GoalListContainer extends Component {
  componentDidMount() {
    const { filters, session, jobTitles } = this.props
    this.props.setCurrentPage("Goals")
    !this.props.orgGoals.fetched && this.props.getOrgGoals(filters)
    !session.subGroups.fetched && this.props.getSubGroups()

    session.isSiteAdmin &&
      !session.organizations.fetched &&
      this.props.getOrganizations()
    !jobTitles.fetched && this.props.getJobTitles()
  }

  handleScroll = () => {
    const { orgGoals } = this.props.entityList
    const { currentPage, totalPages } = orgGoals

    if (currentPage + 1 < totalPages) {
      this.props.increasePage({ type: "orgGoals" })
      setTimeout(() => {
        this.props.getOrgGoals()
      }, 450)
    }
  }

  render() {
    const { filters, session, jobTitles, entityList, fetched, currentTab } =
      this.props

    const props = {
      entityType: "Goal",
      iconClass: "icon goal black",
      createPath: "/create/goals/new",
      fetched,
      total: entityList.orgGoals.totalRecords,
      isSiteAdmin: session.isSiteAdamin,
      setSortOrder: this.props.setSortOrder,
      filters,
      session,
      currentTab,
    }

    const sidebarProps = {
      type: "Goals",
      filters,
      entityList,
      subGroups: session.subGroups,
      session,
      organizations: session.organizations,
      jobTitles,
      fetchData: this.props.getOrgGoals,
      currentTab,
    }

    return (
      <BottomScrollListener onBottom={this.handleScroll}>
        <SidebarEntityList {...sidebarProps} />
        <EntityList {...props} />
        {entityList.orgGoals.isFetchingMore && (
          <div className="loadingMoreContainer">
            <Loading message={"Getting more goals..."} />
          </div>
        )}
      </BottomScrollListener>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalListContainer)
