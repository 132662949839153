import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import theme from "@mobilemind/common/src/theme/theme"
import { MobileMindUser } from "@mobilemind/common/src/types/session"
import moment from "moment"
import { useMemo } from "react"
import Values from "values.js"
import { setContentLock } from "../actions"
import { useAppDispatch } from "../store/hooks"

const secondary = new Values(theme.palette.secondary.main)

type ContentLockProps = {
  editorLock: string
  editor: MobileMindUser
  contentType: string
  id: string
  updateLock: () => void
  type: string
}

function ContentLock(props: ContentLockProps) {
  const { editorLock, editor, type, id, contentType, updateLock } = props
  const dispatch = useAppDispatch()

  const editorName = useMemo(() => {
    if (
      editor.attributes.field_first_name &&
      editor.attributes.field_last_name
    ) {
      return (
        <strong>
          {editor.attributes.field_first_name}{" "}
          {editor.attributes.field_last_name}
        </strong>
      )
    }
    return "another user"
  }, [editor])

  return (
    <div id="contentLock">
      <div
        style={{ backgroundColor: secondary.tint(50).hexString() }}
        className="inner"
      >
        This {contentType} is being edited by {editorName} who opened it on{" "}
        <strong>
          {moment(editorLock).format("MMMM Do")} at{" "}
          {moment(editorLock).format("h:mm A")}
        </strong>
        .
        <ButtonSmall
          onClick={() => {
            updateLock()
            dispatch(setContentLock({ type, id, clear: false }))
          }}
        >
          <span className="icon open-lock" />
          Break Lock
        </ButtonSmall>
      </div>
    </div>
  )
}

export default ContentLock
