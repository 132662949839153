import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"

export const getJobTitles = createAsyncThunk(
  "jobTitlesSlice/getJobTitles",
  async () => {
    let response = await fetchWrapper.get("/api/taxonomy_term/job_titles")
    if (response.ok) {
      let data = await response.json()
      return data
    }
  }
)

export const jobTitlesSlice = createSlice({
  name: "jobTitlesSlice",
  initialState: {
    data: [],
    fetched: false,
  },
  extraReducers: {
    [getJobTitles.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.fetched = true
    },
  },
})

export const { setJobTitles } = jobTitlesSlice.actions

export default jobTitlesSlice.reducer
