import Sidebar from "@mobilemind/common/src/components/SidebarSectioned"
import { useEffect, useState } from "react"
import { useAppSelector } from "store/hooks"
import CalendarLayout from "./calendar/CalendarLayout"
import RecommendedEventModal from "./externalPD/RecommendedEventModal"

import { useAppDispatch } from "../../store/hooks"
import { changeTab } from "../../store/reducers/navbar"

import { setShowArchive, setSidebarItem } from "../../store/reducers/calendar"
import {
  fetchExternalRequests,
  fetchRecommendedEvents,
  openEvent,
  startNewExternalEvent,
  toggleExternalEventModal,
} from "../../store/reducers/externalPD"

import ManageLocationsLayout from "../locations/ManageLocationsLayout"
import ArchivedEventsContainer from "./ArchivedEventsContainer"
import RecommendedEvents from "./externalPD/RecommendedEvents"
import RequestList from "./externalPD/RequestList"
import { setFeedbackFormsModalOpen } from "store/reducers/feedbackForms"

const EventsContainer = (props: any) => {
  const calendar = useAppSelector((state) => state.calendar)
  const externalPD = useAppSelector((state) => state.externalPD)
  const session = useAppSelector((state) => state.session)

  const { orgRoles, canReview, canSchedule, groupRoles, reviewerTypes } =
    session

  const [hasFetchedRequests, setFetchedRequests] = useState(false)

  const dispatch = useAppDispatch()

  const allRoles = orgRoles.concat(groupRoles).join()
  const canReviewExternal =
    allRoles.includes("organization-admin") ||
    groupRoles.includes("group-admin") ||
    (canReview && reviewerTypes.includes("external_event"))

  const canViewCalendar =
    allRoles.includes("organization-admin") ||
    groupRoles.includes("group-admin") ||
    allRoles.includes("scheduler") ||
    allRoles.includes("observer") ||
    allRoles.includes("observation") ||
    allRoles.includes("personnel")

  useEffect(() => {
    dispatch(changeTab("events"))

    if (!hasFetchedRequests) {
      setFetchedRequests(true)
      dispatch(fetchExternalRequests({ status: "pending" }))
      dispatch(fetchExternalRequests({ status: "reviewed" }))
    }

    if (!canViewCalendar && canReviewExternal) {
      dispatch(setSidebarItem("Pending"))
    }
  }, [canViewCalendar, canReviewExternal, hasFetchedRequests, dispatch])

  const calendarSection: any = {
    title: "Calendar",
    children: [
      {
        title: "Event Calendar",
        iconClass: "calendar",
        onClick: () => {
          dispatch(setSidebarItem("Event Calendar"))
        },
      },
    ],
  }

  if (
    canSchedule &&
    (allRoles.includes("organization-admin") ||
      groupRoles.includes("group-admin") ||
      allRoles.includes("scheduler"))
  ) {
    calendarSection.children = calendarSection.children.concat([
      {
        title: "New Event",
        iconClass: "event",
        onClick: () => {
          props.history.push("/events/event/new")
        },
      },
      {
        title: "New Conference",
        iconClass: "conference",
        onClick: () => {
          props.history.push("/events/conference/new")
        },
      },
      {
        title: "Archived Events",
        iconClass: "archived",
        onClick: () => {
          dispatch(setSidebarItem("Archived Events"))
          dispatch(setShowArchive(true))
        },
      },
    ])
  }

  const sidebarSections: any[] = []

  if (canViewCalendar) {
    sidebarSections.push(calendarSection)
  }

  let externalSection: any = {
    title: "External Events",
    children: [],
  }

  // Show this to org admins or schedulers
  if (
    allRoles.includes("organization-admin") ||
    groupRoles.includes("group-admin") ||
    allRoles.includes("scheduler")
  ) {
    externalSection.children = externalSection.children.concat(
      {
        title: "New External Event",
        iconClass: "plus-black",
        onClick: () => {
          dispatch(startNewExternalEvent(true))
          dispatch(toggleExternalEventModal(true))
        },
      },
      {
        title: "Recommended",
        iconClass: "recommended",
        onClick: () => {
          dispatch(setSidebarItem("Recommended"))
        },
      }
    )
  }

  // If they can review external requests, also show these XPD items
  if (canReviewExternal) {
    externalSection.children = externalSection.children.concat(
      {
        title: "Pending",
        iconClass: "pending",
        onClick: () => {
          dispatch(setSidebarItem("Pending"))
        },
        count: externalPD.pending.total,
      },
      {
        title: "Reviewed",
        iconClass: "reviewed",
        onClick: () => {
          dispatch(setSidebarItem("Reviewed"))
        },
      }
    )
  }

  if (canReviewExternal) {
    sidebarSections.push(externalSection)
  }

  if (
    session.isOrgAdmin ||
    session.isGroupAdmin ||
    allRoles.includes("observer") ||
    orgRoles.includes("organization-scheduler") ||
    allRoles.includes("observation")
  ) {
    sidebarSections.push({
      title: "Customize",
      children: [
        {
          title: "Locations",
          iconClass: "location",
          onClick: () => {
            dispatch(setSidebarItem("Locations"))
          },
        },
      ],
    })
  }

  if (
    orgRoles.includes("organization-admin") ||
    orgRoles.includes("organization-scheduler")
  ) {
    if (!sidebarSections.some((section) => section.title === "Customize")) {
      sidebarSections.push({
        title: "Customize",
        children: [],
      })
    }

    sidebarSections.forEach((section) => {
      if (section.title === "Customize") {
        section.children.push({
          title: "Feedback Forms",
          iconClass: "feedback-form",
          onClick: () => {
            dispatch(setFeedbackFormsModalOpen(true))
          },
        })
      }
    })
  }

  let action: any
  if (canSchedule) {
    action = {
      href: process.env.REACT_APP_TEACHER_URL + "/events",
      label: "View Learn Calendar",
    }
  }

  return (
    <div className="events-layout">
      <Sidebar
        activeItem={calendar.sidebarActiveItem}
        action={action}
        sections={sidebarSections}
      />

      <RecommendedEventModal
        close={() => dispatch(toggleExternalEventModal(false))}
        open={externalPD.currentEvent.isOpen}
      />

      {calendar.sidebarActiveItem === "Event Calendar" && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <CalendarLayout history={props.history} isRequestModalOpen={false} />
      )}
      {calendar.sidebarActiveItem === "Recommended" && (
        <RecommendedEvents
          openEvent={openEvent}
          fetchRecommendedEvents={fetchRecommendedEvents}
          recommendedEvents={externalPD.recommended}
          toggleModal={toggleExternalEventModal}
        />
      )}

      {calendar.sidebarActiveItem === "Locations" && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <ManageLocationsLayout />
      )}

      {calendar.sidebarActiveItem === "Archived Events" && (
        <ArchivedEventsContainer />
      )}

      {calendar.sidebarActiveItem === "Pending" && (
        <RequestList status={"pending"} />
      )}
      {calendar.sidebarActiveItem === "Reviewed" && (
        <RequestList status={"reviewed"} />
      )}
    </div>
  )
}

export default EventsContainer
