import { Card, Dialog } from "@mui/material"
import { forwardRef, useEffect, useRef, useState } from "react"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import SanitizeHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { generateVideoEmbedURL } from "@mobilemind/common/src/functions/index"
import theme from "@mobilemind/common/src/theme/theme"
import moment from "moment"
import Carousel from "react-material-ui-carousel"
import { Link } from "react-router-dom"
import { setHQAnnouncementsFetched } from "../../actions"
import ButtonSmall from "../../components/ButtonSmall"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { isUndefined } from "lodash"

type Announcement = {
  id: string
  attributes: {
    title: string
    body: {
      value: string
    }
    field_video_link?: string
    field_calendar_link?: string
    field_new_feature_link?: string
    created: string
  }
  image: {
    attributes: {
      uri: {
        url: string
      }
    }
  }
}

type CarouselItemProps = {
  announcement: Announcement
}

const CarouselItem = forwardRef<HTMLDivElement, CarouselItemProps>(
  ({ announcement }, ref) => {
    const videoEmbed =
      announcement.attributes.field_video_link &&
      generateVideoEmbedURL(announcement.attributes.field_video_link)

    return (
      <div
        ref={ref}
        className="tipsWidget"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <strong>{announcement.attributes.title}</strong>

        <div
          style={{
            marginBottom: 10,
            fontWeight: "bold",
            fontSize: 14,
            lineHeight: "16px",
            display: "flex",
            alignItems: "center",
          }}
        />
        {announcement.image && (
          <a
            href={
              process.env.REACT_APP_API_URL +
              announcement.image.attributes.uri.url
            }
            className="media"
            target="_blank"
            rel="noreferrer"
          >
            <span
              className="image"
              style={{
                backgroundImage:
                  "url(" +
                  process.env.REACT_APP_API_URL +
                  announcement.image.attributes.uri.url +
                  ")",
              }}
            />
          </a>
        )}

        {videoEmbed && (
          <div style={{ marginBottom: 5 }} className="videoContainer">
            <iframe
              className="iframeVideo"
              title={announcement.attributes.title}
              id="ytplayer"
              width="345"
              height="194"
              src={videoEmbed}
            />
          </div>
        )}

        <div>
          <SanitizeHTML html={announcement.attributes.body.value} />
        </div>
      </div>
    )
  }
)

type ModalCarouselItemProps = {
  announcement: Announcement
  closeModal: () => void
}

const ModalCarouselItem = ({
  announcement,
  closeModal,
}: ModalCarouselItemProps) => {
  const session = useAppSelector((state) => state.session)
  const dispatch = useAppDispatch()
  const videoEmbed =
    announcement.attributes.field_video_link &&
    generateVideoEmbedURL(announcement.attributes.field_video_link)

  const internalRoute =
    announcement.attributes.field_new_feature_link &&
    announcement.attributes.field_new_feature_link.split(".io")

  const showGotIt = !internalRoute

  function LinkButton(props: any) {
    return (
      <ButtonLarge
        style={{
          backgroundColor:
            props.label === "Check It Out Now"
              ? theme.palette.secondary.main
              : "#f0f0f0",
        }}
      >
        {props.label === "Check It Out Now" ? (
          <span className="icon pointer" />
        ) : (
          <span className="icon phone" />
        )}
        {props.label}
      </ButtonLarge>
    )
  }

  return (
    <>
      <div className="carousel-item-container" style={{ display: "flex" }}>
        <div className="info">
          <header>{announcement.attributes.title}</header>
          <div>
            <SanitizeHTML html={announcement.attributes.body.value} />
          </div>
        </div>

        {announcement.image && (
          <Link
            className="feature-link"
            onClick={() => {
              closeModal()
              dispatch(setHQAnnouncementsFetched())
            }}
            to={announcement.attributes.field_new_feature_link ?? ""}
          >
            <img
              className="feature-image"
              src={
                process.env.REACT_APP_API_URL +
                announcement.image.attributes.uri.url
              }
              alt={announcement.attributes.title}
            />
          </Link>
        )}

        {videoEmbed && (
          <div className="videoContainer">
            <iframe
              className="iframeVideo"
              title={announcement.attributes.title}
              id="ytplayer"
              width="345"
              height="194"
              src={videoEmbed}
            />
          </div>
        )}
      </div>

      <footer>
        {!session.collection && announcement.attributes.field_calendar_link && (
          <a
            onClick={() => {
              closeModal()
              dispatch(setHQAnnouncementsFetched())
            }}
            href={announcement.attributes.field_calendar_link}
            rel="noreferrer"
            target="_blank"
          >
            <LinkButton label="Schedule an HQ Session" />
          </a>
        )}

        {showGotIt && (
          <ButtonLarge
            style={{
              marginLeft: announcement.attributes.field_calendar_link ? 20 : 0,
              backgroundColor: theme.palette.secondary.main,
            }}
            onClick={() => {
              closeModal()
              dispatch(setHQAnnouncementsFetched())
            }}
          >
            Got it!
          </ButtonLarge>
        )}

        {internalRoute && (
          <>
            {announcement.attributes.field_new_feature_link &&
            announcement.attributes.field_new_feature_link.includes(
              "mobilemind.io"
            ) ? (
              <Link
                onClick={() => {
                  closeModal()
                  dispatch(setHQAnnouncementsFetched())
                }}
                to={internalRoute[1]}
              >
                <LinkButton label="Check It Out Now" />
              </Link>
            ) : (
              <a
                onClick={() => {
                  closeModal()
                  dispatch(setHQAnnouncementsFetched())
                }}
                href={announcement.attributes.field_new_feature_link}
                rel="noreferrer"
                target="_blank"
              >
                <LinkButton label="Check It Out Now" />
              </a>
            )}
          </>
        )}
      </footer>
    </>
  )
}

const WidgetModalTips = () => {
  const session = useAppSelector((state) => state.session)
  const hqAnnouncements = useAppSelector((state) => state.hqAnnouncements)
  const [newAnnouncements, setNewAnnouncements] = useState<any[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (hqAnnouncements.fetched) {
      // If we have announcements, decide if we need to show any unseen announcements in the modal
      const lastViewedHQAnnouncements = session.user.attributes
        .field_hq_announcements_last_fetc
        ? moment(session.user.attributes.field_hq_announcements_last_fetc)
        : null

      setNewAnnouncements(
        hqAnnouncements.data.filter((announcement: any) => {
          if (
            moment(announcement.attributes.created).isAfter(
              moment(lastViewedHQAnnouncements)
            ) ||
            !lastViewedHQAnnouncements
          ) {
            setModalOpen(true)
            return true
          }
          return false
        })
      )
    }
  }, [
    hqAnnouncements.fetched,
    session.user.attributes.field_hq_announcements_last_fetc,
    hqAnnouncements.data,
  ])

  useEffect(() => {
    if (
      moment(session.user.attributes.field_hq_announcements_last_fetc).format(
        "MM/DD/YYYY"
      ) === moment().format("MM/DD/YYYY")
    ) {
      setModalOpen(false)
    }
  }, [session.user.attributes.field_hq_announcements_last_fetc])

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const itemRefs = useRef<Record<number, HTMLDivElement | null>>({})
  const [navOffset, setNavOffset] = useState<number>(0)

  return (
    <>
      <Dialog id="new-features-modal" open={isModalOpen}>
        <div className="dialog">
          <h2>
            <span className="icon lightbulb" />
            <span style={{ flex: 1 }}>What's New in HQ</span>
            <ButtonSmall
              onClick={() => {
                setModalOpen(false)
                dispatch(setHQAnnouncementsFetched())
              }}
            >
              <span className="icon close" />
            </ButtonSmall>
          </h2>

          <div className="inner">
            {newAnnouncements.length > 0 && (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Carousel
                navButtonsAlwaysInvisible={
                  newAnnouncements.length === 1 ? true : false
                }
                indicators={newAnnouncements.length > 1 ? true : false}
                animation={"slide"}
                autoPlay={false}
              >
                {newAnnouncements.map((announcement: any) => {
                  return (
                    <ModalCarouselItem
                      key={announcement.id}
                      announcement={announcement}
                      closeModal={() => setModalOpen(false)}
                    />
                  )
                })}
              </Carousel>
            )}
          </div>
        </div>
      </Dialog>

      <Card className="dashboard-widget widget-tips">
        <header style={{ backgroundColor: theme.palette.secondary.main }}>
          What's New in HQ
        </header>
        <div className="inner">
          {hqAnnouncements.data.length > 0 ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Carousel
              navButtonsAlwaysInvisible={true}
              indicators={hqAnnouncements.data.length > 1 ? true : false}
              animation={"slide"}
              autoPlay={false}
              navButtonsWrapperProps={{
                style: {
                  paddingTop: navOffset,
                  top: 0,
                  bottom: 0,
                  height: "unset",
                  transition: "top, 0.2s",
                },
              }}
              onChange={(now?: number) => {
                if (!isUndefined(now)) {
                  setTimeout(() => {
                    const mediaEl = itemRefs.current[
                      now
                    ]?.querySelector<HTMLDivElement>(".image,.videoContainer")
                    if (mediaEl) {
                      const offsetTop = mediaEl.offsetTop ?? 0
                      const offsetHeight = mediaEl.offsetHeight ?? 0
                      setNavOffset(offsetTop + offsetHeight)
                    } else {
                      setNavOffset(0)
                    }
                    // Wait for the animation to start so that we can get the correct offset
                  }, 50)
                }
              }}
            >
              {hqAnnouncements.data.map((announcement: Announcement, index) => {
                return (
                  <CarouselItem
                    key={announcement.id}
                    announcement={announcement}
                    // Keep a reference to this element so that we can calculate the offset
                    // of the first video or image in the carousel item
                    ref={(el) => (itemRefs.current[index] = el)}
                  />
                )
              })}
            </Carousel>
          ) : (
            <>
              <span className="icon lightbulb" />
              <p style={{ fontWeight: "bold", textAlign: "center", margin: 0 }}>
                You have no HQ Tips to display.
              </p>
            </>
          )}
        </div>
      </Card>
    </>
  )
}

export default WidgetModalTips
