import { useEffect, useState } from "react"

import { Box, Grid } from "@mui/material"
import Loading from "@mobilemind/common/src/components/Loading"
import {
  increaseRecommendedPage,
  updateRecommendedFilters,
} from "../../../store/reducers/externalPD"
import RecommendedCard from "./RecommendedCard"

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import ButtonSmall from "../../../components/ButtonSmall"

import {
  startNewExternalEvent,
  toggleExternalEventModal,
} from "../../../store/reducers/externalPD"
import { useAppDispatch } from "../../../store/hooks"
import BottomScrollListener from "react-bottom-scroll-listener"

type ContainerProps = {
  recommendedEvents: any
  toggleModal: (args: boolean) => void
  openEvent: (args: any) => any
  fetchRecommendedEvents: (args: any) => any
}

const RecommendedEventsContainer = ({
  recommendedEvents,
  fetchRecommendedEvents,
  toggleModal,
  openEvent,
}: ContainerProps) => {
  const dispatch = useAppDispatch()
  const [hasFetched, setFetched] = useState(false)
  const activeFilters = recommendedEvents.filters

  useEffect(() => {
    !hasFetched &&
      !recommendedEvents.fetched &&
      dispatch(fetchRecommendedEvents({}))
    setFetched(true)
  }, [hasFetched, recommendedEvents, fetchRecommendedEvents, dispatch])

  function handleScroll() {
    if (recommendedEvents.currentPage < recommendedEvents.totalPages - 1) {
      dispatch(increaseRecommendedPage({}))
    }
  }

  return (
    <BottomScrollListener onBottom={handleScroll}>
      <div className="page events-layout recommended-events">
        <h2>
          <div style={{ flex: 1 }}>Recommended External Events</div>
          {!recommendedEvents.fetched && (
            <Loading message={"Getting recommended events..."} />
          )}
          <ButtonSmall
            onClick={() => {
              dispatch(startNewExternalEvent(true))
              dispatch(toggleExternalEventModal(true))
            }}
          >
            <span className="icon add" />
            Create External Event
          </ButtonSmall>
        </h2>

        <div
          style={{ marginBottom: 30, display: "flex", alignItems: "center" }}
        >
          <FormControl
            style={{ marginRight: 25 }}
            variant="standard"
            className="inputSelect"
          >
            <TextField
              variant="standard"
              label="Search"
              value={activeFilters.search}
              onChange={(event) => {
                dispatch(
                  updateRecommendedFilters({
                    field: "search",
                    value: event.target.value,
                  })
                )
              }}
            />
          </FormControl>

          <FormControl
            variant="standard"
            style={{ marginLeft: 25, marginRight: 25 }}
            className="inputSelect"
          >
            <InputLabel id="label-status-select">Status</InputLabel>
            <Select
              variant="standard"
              style={{ minWidth: 100 }}
              labelId="label-status-select"
              id="status-select"
              value={activeFilters.status}
              onChange={(event) => {
                dispatch(
                  updateRecommendedFilters({
                    field: "status",
                    value: event.target.value,
                  })
                )
              }}
            >
              <MenuItem value={"false"}>Published</MenuItem>
              <MenuItem value={"true"}>Archived</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="standard" className="inputSelect">
            <InputLabel id="label-sort-select">Sort</InputLabel>
            <Select
              variant="standard"
              style={{ minWidth: 100 }}
              labelId="label-sort-select"
              id="sort-select"
              value={activeFilters.sort}
              onChange={(event) => {
                dispatch(
                  updateRecommendedFilters({
                    field: "sort",
                    value: event.target.value,
                  })
                )
              }}
            >
              <MenuItem value={"start_date"}>Event Date</MenuItem>
              <MenuItem value={"title"}>Title</MenuItem>
              <MenuItem value={"category"}>Category</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="recommended-grid">
          <Grid container spacing={3}>
            {recommendedEvents.data.map((event: any, index: number) => {
              return (
                <RecommendedCard
                  key={index}
                  animationDelay={index}
                  event={event}
                  onClick={() => {
                    // @ts-ignore
                    dispatch(toggleModal(true))
                    dispatch(openEvent(event))
                  }}
                />
              )
            })}
          </Grid>
          {recommendedEvents.isFetchingMore && (
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
            >
              <Loading message={"Getting more events..."} />
            </Box>
          )}
        </div>
      </div>
    </BottomScrollListener>
  )
}

export default RecommendedEventsContainer
