import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

export const getUserList = createAsyncThunk(
  "userSelectSlice/getUserList",
  async (args, thunkAPI) => {
    const { session, userSelect } = thunkAPI.getState()

    const isOrgLevel =
      session.orgRoles.includes("organization-admin") ||
      session.orgRoles.includes("organization-drafter") ||
      session.orgRoles.includes("organization-creator") ||
      session.orgRoles.includes("organization-scheduler") ||
      session.orgRoles.includes("organization-observer") ||
      session.orgRoles.includes("organization-observation_admin")

    const orgId = session.group.id[0].value

    let users = []

    if (userSelect.learnerSearch) {
      let query = {
        search: userSelect.learnerSearch,
      }
      query["role[0]"] = "all"

      let response
      let allSubGroupIds = []

      // If they aren't org level, we've got to go through all of their subgroups
      if (!isOrgLevel) {
        allSubGroupIds = session.subgroups.data.map(
          (subgroup) => subgroup.drupal_internal__id
        )

        // We have to do a while loop and throw all results into an array here
        let i = 0
        while (i < allSubGroupIds.length) {
          response = await fetchWrapper.get(
            "/api/organization-members/" +
              allSubGroupIds[i] +
              "?" +
              qs.stringify(query)
          )

          if (response.ok) {
            let data = await response.json()

            if (data.rows.content) {
              data.rows = []
            } else {
              data.rows.forEach((user) => {
                user.id = user.uuid
              })
            }

            users = users.concat(data.rows)
          }
          i++
        }
      }
      // Otherwise just fetch all users in the org
      else {
        response = await fetchWrapper.get(
          "/api/organization-members/" + orgId + "?" + qs.stringify(query)
        )

        if (response.ok) {
          let data = await response.json()

          if (data.rows.content) {
            data.rows = []
          } else {
            data.rows.forEach((user) => {
              user.id = user.uuid
            })
          }

          users = data.rows
        }
      }

      return users
    }
  }
)

const debouncedGetUserList = debounceThunk(getUserList, 750)

export const updateUserSearch = createAsyncThunk(
  "userSelectSlice/updateUserSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetUserList())

    return args
  }
)

export const userSelectSlice = createSlice({
  name: "userSelectSlice",
  initialState: {
    learnerSearch: "",
    subGroupSearch: "",
    orgUsers: [],
    allOrgUsers: [],
    fetched: false,
    userRoles: "all",
  },
  reducers: {
    clearSearch: (state) => {
      state.subGroupSearch = ""
      state.learnerSearch = ""
    },
    setRoles: (state, action) => {
      state.userRoles = action.payload
    },
    updateGroupSearch: (state, action) => {
      state.subGroupSearch = action.payload
    },
  },
  extraReducers: {
    [updateUserSearch.fulfilled]: (state, action) => {
      if (action.payload) {
        state.learnerSearchActive = true
      }
      state.orgUsers = []
      state.learnerSearch = action.payload
    },
    [getUserList.fulfilled]: (state, action) => {
      state.allOrgUsers = state.allOrgUsers.concat(action.payload)
      state.orgUsers = action.payload

      if (!state.learnerSearch) {
        state.orgUsers = action.payload.slice(0, 20)
      }
      state.learnerSearchActive = false
    },
  },
})

export const { updateGroupSearch, clearSearch, setRoles } =
  userSelectSlice.actions

export default userSelectSlice.reducer
