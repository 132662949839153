import { Component, useState } from "react"
import { useAppDispatch } from "store/hooks"
import { Button, FormControl, Select, MenuItem } from "@mui/material"
import { connect } from "react-redux"

import classNames from "classnames"

import plusImg from "../../../img/plus.svg"
import DeleteButton from "../../../components/DeleteButton"

import {
  addPersonnel,
  removePersonnel,
  setPersonnelRole,
  fetchRoles,
} from "./activeEventSlice"
import {
  addConferencePersonnel,
  removeConferencePersonnel,
  setConferencePersonnelRole,
  fetchConferenceRoles,
} from "../conference/activeConferenceSlice"

import UserSelect from "../../userSelect/UserSelect"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { deletePersonnel } from "../../../actions/events"
import Loading from "@mobilemind/common/src/components/Loading"

const mapStateToProps = ({ session, activeConference }, ownProps) => {
  return {
    activeConference,
    isConference: ownProps.isConference,
    session,
  }
}

const mapDispatchToProps = {
  fetchRoles,
  fetchConferenceRoles,
  deletePersonnel,
}

function EventPersonnel(props) {
  const event = props.activeEvent

  const dispatch = useAppDispatch()

  const addItem = props.isConference ? addConferencePersonnel : addPersonnel
  const removeItem = props.isConference
    ? removeConferencePersonnel
    : removePersonnel
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  let title = props.isConference ? "Conference" : "Event"
  if (props.isSession) {
    title = "Session"
  }

  const allRoles = props.session.orgRoles
    .concat(props.session.groupRoles)
    .join(",")

  let targetPersonnel =
    props.activeConference &&
    props.activeConference.personnel.find(
      (personnel) => personnel.uuid === props.session.user.id
    )
  let canEdit = allRoles.includes("admin") || allRoles.includes("scheduler")

  if (
    !canEdit &&
    targetPersonnel &&
    targetPersonnel.field_event_role_name === "Manager"
  ) {
    canEdit = true
  }

  return (
    <div className="event-content two-column event-personnel">
      <div
        style={{
          opacity: canEdit ? 1 : 0.5,
          pointerEvents: canEdit ? "all" : "none",
        }}
      >
        <UserSelect
          open={isModalOpen}
          entity={event}
          update={(action) => {
            if (action.type === "add") {
              dispatch(addItem(action.user))
            } else {
              dispatch(removeItem(action.user))
            }
          }}
          initialTab={"users"}
          userLabel={"users"}
          isPersonnel={true}
          searchText={"Search for users to add them to this event."}
          onClose={() => setIsModalOpen(false)}
          visibleTabs={"users"}
        />

        <h2>
          <span style={{ display: "flex", alignItems: "center", flex: 1 }}>
            {title} Personnel
            <div className="eventRolesTooltip">
              <span className="icon info" />
              What are {title.toLowerCase()} personnel?
              <div className="inner">
                <p>
                  You can assign one of the following roles to users in your
                  organization to give them specific permissions for this{" "}
                  {title.toLowerCase()}:
                </p>
                <ul>
                  {event.eventRoles &&
                    event.eventRoles.map((role) => {
                      return (
                        <li key={role.id}>
                          <div className="flexRow">
                            <span
                              className={classNames(
                                "icon",
                                role.attributes.name.toLowerCase()
                              )}
                            />
                            <strong>{role.attributes.name}</strong>
                          </div>
                          <div>
                            {role.attributes.description && (
                              <SanitizedHTML
                                html={role.attributes.description.value.replace(
                                  "event",
                                  title.toLowerCase()
                                )}
                              />
                            )}
                          </div>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
          </span>

          {isDeleting && <Loading />}

          <Button
            onClick={() => setIsModalOpen(true)}
            className={classNames("button small", isDeleting && "disabled")}
          >
            <img alt={"Add Personnel"} src={plusImg} /> Add Personnel
          </Button>
        </h2>

        <div className="panel current-people">
          <ul className="current-people-list">
            {event.personnel.length > 0 ? (
              event.personnel.map((user) => {
                let userImage =
                  process.env.REACT_APP_API_URL + user.field_user_image_path
                let fullRole =
                  event.eventRoles &&
                  event.eventRoles.find((role) => role.id === user.role)

                return (
                  <li key={user.id}>
                    <div className="flexRow">
                      {userImage.includes("default_temp") ||
                      !user.field_user_image_path ? (
                        <UserInitial
                          containerStyle={{ marginRight: 10 }}
                          fontSize={18}
                          size={50}
                          initials={
                            user.field_first_name
                              .substring(0, 1)
                              .toUpperCase() +
                            user.field_last_name.substring(0, 1).toUpperCase()
                          }
                        />
                      ) : (
                        <span
                          className="userImage"
                          style={{ backgroundImage: "url(" + userImage + ")" }}
                        />
                      )}
                      <strong style={{ flex: 1 }} className="presenter-name">
                        {user.field_first_name} {user.field_last_name}
                      </strong>

                      <FormControl
                        variant="standard"
                        className="inputSelect eventRoles"
                      >
                        <Select
                          variant="standard"
                          labelId="label-eventRoles-select"
                          id="eventRoles-select"
                          value={user.role}
                          onChange={(event) => {
                            if (props.isConference) {
                              dispatch(
                                setConferencePersonnelRole({
                                  user,
                                  role: event.target.value,
                                })
                              )
                            } else {
                              dispatch(
                                setPersonnelRole({
                                  user,
                                  role: event.target.value,
                                })
                              )
                            }
                          }}
                        >
                          {event.eventRoles &&
                            event.eventRoles.map((role) => {
                              if (
                                props.isConference &&
                                role.attributes.name === "Presenter"
                              ) {
                                return null
                              }
                              return (
                                <MenuItem key={role.id} value={role.id}>
                                  <span
                                    className={classNames(
                                      "icon",
                                      role.attributes.name.toLowerCase()
                                    )}
                                  />
                                  {title} {role.attributes.name}
                                </MenuItem>
                              )
                            })}
                        </Select>
                      </FormControl>

                      <DeleteButton
                        onClick={() => {
                          dispatch(removeItem(user))
                          if (user.personnelId) {
                            setIsDeleting(true)
                            dispatch(deletePersonnel(user)).then(() => {
                              setIsDeleting(false)
                            })
                          }
                        }}
                      />
                    </div>
                    {user.field_bio &&
                      fullRole &&
                      fullRole.attributes.name === "Presenter" && (
                        <p className={"bio"}>
                          <SanitizedHTML html={user.field_bio} />
                        </p>
                      )}
                  </li>
                )
              })
            ) : (
              <p style={{ padding: 20 }}>
                <em>
                  Click the button above to add personnel and define their
                  roles.
                </em>
              </p>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

class EventPersonnelContainer extends Component {
  componentDidMount() {
    if (this.props.isConference) {
      this.props.fetchConferenceRoles()
    } else {
      this.props.fetchRoles()
    }
  }

  render() {
    const props = this.props
    return <EventPersonnel {...props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventPersonnelContainer)
