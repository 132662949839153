import { alpha, styled } from "@mui/material"
import "../styles/navbar.scss"
import { HTMLAttributes, ReactNode } from "react"

type NavProps = HTMLAttributes<HTMLDivElement> & {
  icon?: ReactNode
  title: string
  isTemplate: boolean
}

export const SecondaryNavbar = styled((props: NavProps) => {
  const { icon, title, children, isTemplate, ...otherProps } = props
  return (
    <nav {...otherProps}>
      <div className="title">
        {icon}
        <span>{title}</span>
      </div>
      {children}
    </nav>
  )
})(({ theme, isTemplate }) => ({
  paddingInline: theme.spacing(4),
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  paddingTop: 50,
  height: 50,
  zIndex: 15,
  boxShadow: theme.shadows[1],
  backgroundColor: isTemplate
    ? theme.palette.secondary.main
    : theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  color: "white",
  "&>.title": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    flex: 1,
  },
  "& .MuiButton-root": {
    color: "white",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.3),
    },
    "&.Mui-disabled": {
      opacity: 0.6,
    },
  },
}))
