import { Component } from "react"

import { connect } from "react-redux"

import {
  saveEventBase,
  saveEventAddOn,
  savePersonnel,
  attachEventAddOn,
  saveEventAttachments,
  deleteEvent,
} from "../../../actions/events"

import { setEventSaving, startNewEvent, updateField } from "./activeEventSlice"

import ModalDelete from "../../../components/ModalDelete"
import ModalCopy from "../../../components/ModalCopy"
import { isArrayIdentical } from "../../../functions"

import { Button } from "@mui/material"

import ModalEventConfirmation from "../../../components/ModalEventConfirmation"
import ModalArchiveEvent from "../../../components/ModalArchiveEvent"
import { setShowArchive } from "../../../store/reducers/calendar"
import ModalResendEmail from "./ModalResendEmail"

import {
  setIsEditingEvent,
  updateConferenceField,
  changeConferenceTab,
  setIsSessionArchived,
  setConferenceSaving,
  fetchSessionsForConference,
  deleteSessionDraft,
} from "../conference/activeConferenceSlice"

import SubNav from "../../../components/SubNav"
import moment from "moment"
import classNames from "classnames"

import { unArchiveEvent } from "../../../actions/events"

const mapStateToProps = (
  { session, activeEvent, activeConference },
  ownProps
) => {
  let eventRef = ownProps.isConference ? activeConference : activeEvent

  let allParticipants = eventRef.attendees.concat(
    eventRef.subGroups,
    eventRef.jobTitles
  )

  let originalParticipantArray = eventRef.originalParticipants
    .map((entity) => (entity ? entity.id : null))
    .filter((id) => {
      if (!eventRef.isOrgLevel && eventRef.attendeeMethod === "custom") {
        return id !== eventRef.groupData.id
      } else {
        return id
      }
    })

  let allParticipantArray = allParticipants
    .map((entity) => {
      return entity && entity.id
    })
    .filter((entity) => entity)

  let hasSameParticipants = isArrayIdentical(
    originalParticipantArray,
    allParticipantArray
  )

  if (eventRef.originalAttendeeMethod !== eventRef.attendeeMethod) {
    hasSameParticipants = false
  }

  if (!eventRef.id) {
    hasSameParticipants = true
  }

  return {
    hasSameParticipants,
    isConference: ownProps.isConference,
    session,
    activeConference,
    activeEvent: eventRef,
  }
}

const mapDispatchToProps = {
  saveEventAttachments,
  setEventSaving,
  saveEventAddOn,
  saveEventBase,
  attachEventAddOn,
  setIsSessionArchived,
  startNewEvent,
  setShowArchive,
  fetchSessionsForConference,
  setConferenceSaving,
  unArchiveEvent,
  updateField,
  updateConferenceField,
  changeConferenceTab,
  deleteEvent,
  deleteSessionDraft,
  setIsEditingEvent,
  savePersonnel,
}

class EventNavbarContainer extends Component {
  state = {
    isDeleteOpen: false,
    isConfirmModalOpen: false,
    isArchiveOpen: false,
    isResendEmailOpen: false,
    isCopyOpen: false,
  }

  uploadAttachments = async (isCopy, event, isSession, eventRef) => {
    this.props.setEventSaving(true)
    this.props.setConferenceSaving(true)

    let attachments = []
    let existingAttachments = []

    eventRef.attachments &&
      eventRef.attachments.forEach((file) => {
        if (!file.file.includes("/sites")) {
          attachments.push(file)
        } else {
          existingAttachments.push(file)
        }
      })

    await this.props.saveEventAttachments({
      attachments,
      existingAttachments,
      event,
      isConference: this.props.isConference,
      isSession,
    })

    this.props.setEventSaving(false)
    this.props.setConferenceSaving(false)
  }

  saveDraft = () => {
    this.completePublish({ isDraft: true })
  }

  deleteDraft = () => {
    this.props.deleteEvent(
      this.props.isConference
        ? this.props.activeConference
        : this.props.activeEvent
    )
  }

  redirect = (event) => {
    const { isObservation, isConference, isSession, history } = this.props

    if (
      !isObservation &&
      !isConference &&
      !isSession &&
      !window.location.href.includes("session")
    ) {
      history.push(
        "/events/view/event/" + event.data.attributes.drupal_internal__id
      )
    } else if (isConference) {
      history.push(
        "/events/view/conference/" + event.data.attributes.drupal_internal__id
      )
    } else if (isSession || window.location.href.includes("session")) {
      history.push(
        "/events/view/session/" + event.data.attributes.drupal_internal__id
      )
    } else if (isObservation) {
      history.push(
        "/events/view/observation/" + event.data.attributes.drupal_internal__id
      )
    }
    this.props.setIsEditingEvent(false)
  }

  completePublish = async (args) => {
    const {
      activeEvent,
      activeConference,
      isSession,
      isObservation,
      isConference,
    } = this.props
    let eventRef = isConference ? { ...activeConference } : { ...activeEvent }

    const isCopy = args && args.isCopy
    let isDraft = (args && args.isDraft) || isCopy ? true : false
    let isPreSave = args && args.isPreSave
    let isArchive = args && args.isArchive

    !isPreSave && this.props.setEventSaving(true)
    !isPreSave && this.props.setConferenceSaving(true)

    const forceParticipantsChanged = Boolean(
      !isPreSave && !isDraft && eventRef.field_draft && eventRef.id
    )

    this.setState({ canPublish: false })

    const eventBase = await this.props.saveEventBase({
      isDraft,
      isCopy,
      isConference,
      isSession,
      isObservation,
      isArchive,
    })

    if (!isArchive) {
      const addOn = await this.props.saveEventAddOn({
        forceParticipantsChanged,
        eventBase,
        eventRef,
        isSession,
        isObservation,
        isCopy,
        isConference,
      })

      await this.props.attachEventAddOn({
        eventBase,
        eventRef,
        addOn,
        isDraft,
        isCopy,
        isConference,
        isObservation,
        isSession,
      })

      !isPreSave && this.setState({ isConfirmModalOpen: false })

      !isPreSave &&
        (await this.props.savePersonnel({
          event: eventBase,
          personnel: eventRef.personnel,
          isCopy,
        }))

      if (activeEvent.attachments.length) {
        !isPreSave &&
          (await this.uploadAttachments(isCopy, eventBase, isSession, eventRef))
      }

      this.setState({ canPublish: true })

      if (args && args.isDraft) {
        if (this.props.isSession) {
          !isPreSave && this.props.setIsEditingEvent(false)
        } else if (!isPreSave && this.props.isConference) {
          this.props.history.push(
            "/events/view/conference/" +
              eventBase.data.attributes.drupal_internal__id
          )
        } else if (!isPreSave) {
          this.props.history.push("/events")
        }
      } else {
        if (
          !isPreSave &&
          !isCopy &&
          !window.location.href.includes("events/view") &&
          !this.props.isSession
        ) {
          this.redirect(eventBase)
        }
      }

      if (this.props.isSession) {
        this.props.fetchSessionsForConference({
          id: this.props.activeConference.drupal_internal__id,
        })
      }

      !isPreSave && this.props.setIsEditingEvent(false)
      !isPreSave && this.props.setEventSaving(false)
      !isPreSave && this.props.setConferenceSaving(false)
      !isPreSave && this.props.startNewEvent()
    }
  }

  publish = () => {
    const { activeEvent, hasSameParticipants } = this.props

    this.setState({ canPublish: false })

    if (!activeEvent.id && hasSameParticipants) {
      this.completePublish({ isDraft: true, isPreSave: true })
      this.setState({ isConfirmModalOpen: true })
    } else if (activeEvent.field_archive || activeEvent.field_draft) {
      this.setState({ isConfirmModalOpen: true, canPublish: true })
    } else if (activeEvent.id && !hasSameParticipants) {
      this.setState({ isResendEmailOpen: true })
    } else {
      this.completePublish()
    }
  }

  archive = () => {
    const { activeEvent } = this.props

    if (!activeEvent.field_archive) {
      this.setState({ isArchiveOpen: true })
    } else {
      this.completeArchive()
    }
  }

  completeArchive = () => {
    this.setState({ isArchiveOpen: false })
    this.completePublish({ isArchive: true }).then(() => {
      if (!this.props.isSession) {
        this.props.history.push("/events")
      } else {
        this.props.setIsSessionArchived({
          id: this.props.activeEvent.id,
          status: true,
        })
        this.props.setEventSaving(false)
      }

      this.props.setIsEditingEvent(false)
    })
  }

  highlightFields = (missingFields) => {
    if (this.props.isConference) {
      this.props.updateConferenceField({
        field: "missingFields",
        value: missingFields,
      })
    } else {
      this.props.updateField({ field: "missingFields", value: missingFields })
    }
  }

  copySession = (event) => {
    this.setState({ isCopyOpen: false })
    this.props.setIsEditingEvent(false)

    this.props.updateConferenceField({ field: "isCopyingEvent", value: true })

    this.completePublish({
      isDraft: true,
      isCopy: true,
      isSession: this.props.isSession,
    }).then(() => {
      this.props.updateConferenceField({
        field: "isCopyingEvent",
        value: false,
      })
      this.props.startNewEvent()
    })
  }

  editConference = () => {
    this.props.changeConferenceTab("details")
    this.props.history.push(
      "/events/conference/" + this.props.activeConference.drupal_internal__id
    )
  }

  unarchive = () => {
    this.props.unArchiveEvent(this.props.activeEvent)
    this.props.setIsEditingEvent(false)
    if (!this.props.isSession) {
      this.props.history.push("/events")
    } else {
      this.props.setIsSessionArchived({
        id: this.props.activeEvent.id,
        status: false,
      })
    }
    this.props.setShowArchive(false)
  }

  exportSessions = () => {
    window.location.href =
      process.env.REACT_APP_API_URL +
      "/api/conference_attendance/export/" +
      this.props.activeConference.drupal_internal__id
  }

  render() {
    const { activeConference, isObservation, session } = this.props

    let activeEvent = this.props.isConference
      ? activeConference
      : this.props.activeEvent

    let missingFields = []
    let missingDraftFields = []

    if (activeEvent.type === "Virtual Live" && !activeEvent.meetingLink) {
      missingFields.push(
        "Meeting Link: Virtual events must have a meeting link"
      )
    }

    // @todo - need a new way to determine if they are blocked
    // activeEvent.attendees.forEach((user) => {
    //   let fullUser = session.orgUsers.data.find((item) => item.uuid === user.id)
    //   if (!fullUser) {
    //     !missingFields.includes(
    //       "Participants: You must remove blocked users before publishing"
    //     ) &&
    //       missingFields.push(
    //         "Participants: You must remove blocked users before publishing"
    //       )
    //   }
    // })

    if (!activeEvent.name) {
      missingFields.push("Details: Name")
      missingDraftFields.push("Details: Name")
    }
    if (!activeEvent.description) {
      missingFields.push("Details: Description")
    }

    if (!activeEvent.category && !isObservation) {
      missingFields.push("Details: Category")
    }

    if (this.props.isConference) {
      if (
        activeConference.type !== "Virtual Live" &&
        !activeConference.locations.length
      ) {
        missingDraftFields.push("Location: Location")
      }
    }

    function isValidHttpUrl(string) {
      let url
      try {
        url = new URL(string)
      } catch (_) {
        return false
      }

      return url.protocol === "http:" || url.protocol === "https:"
    }

    let missingInvalidMeetingLink =
      !isValidHttpUrl(activeEvent.meetingLink) || !activeEvent.meetingLink

    if (activeEvent.type === "In Person Live") {
      if (
        (!this.props.isConference && !activeEvent.currentLocation) ||
        (this.props.isConference && !activeEvent.locations.length)
      ) {
        missingFields.push("Location: Location")
      }

      activeEvent.meetingLink &&
        !isValidHttpUrl(activeEvent.meetingLink) &&
        missingFields.push("Location: Meeting Link must be a valid URL")
    }

    if (activeEvent.type === "Virtual Live") {
      activeEvent.meetingLink &&
        !isValidHttpUrl(activeEvent.meetingLink) &&
        missingFields.push("Location: Meeting Link must be a valid URL")
    }

    if (activeEvent.registrationWindow === "dateRange") {
      if (!activeEvent.registrationStarts || !activeEvent.registrationEnds) {
        missingFields.push("Registration: Registration Start/End")
      }
    }

    if (activeEvent.type === "Blended") {
      missingInvalidMeetingLink &&
        !activeEvent.currentLocation &&
        missingFields.push("Location: Location/Meeting Link")
    }

    if (
      (activeEvent.attendeeMethod === "unselected" && !isObservation) ||
      (isObservation && !activeEvent.attendees.length)
    ) {
      missingFields.push("Participants: Attendee Selection")
    } else if (activeEvent.attendeeMethod === "custom") {
      if (
        !activeEvent.attendees.length &&
        !activeEvent.subGroups.length &&
        !activeEvent.jobTitles.length
      ) {
        missingFields.push(
          "Participants: Invite at least one Group, Job Title, or Learner"
        )
      }
    }

    if (
      activeConference.isEditingEvent &&
      activeConference.availableRooms &&
      activeConference.availableRooms.length &&
      !activeEvent.eventRoom
    ) {
      if (activeEvent.type !== "Virtual Live") {
        missingFields.push("Location: Event Room")
      }
    }

    const { startDate, endDate } = activeEvent

    if (startDate && moment(startDate).isAfter(endDate)) {
      missingFields.push("End time must be after start time")
    }

    const canPublish = !missingFields.length
    const showPublishButton =
      !window.location.href.includes("/view") || this.props.isSession

    let showEndDisplayDate =
      activeEvent &&
      moment(startDate).format("MM/DD/YYYY") !==
        moment(endDate).format("MM/DD/YYYY")

    let type = this.props.isConference ? "Conference" : "Event"
    if (this.props.isObservation) {
      type = "Observation"
    }
    if (this.props.isSession) {
      type = "Session"
    }
    const allRoles = session.orgRoles.concat(session.groupRoles).join(",")

    let targetPersonnel =
      activeEvent.personnel &&
      activeConference.personnel.find((user) => user.id === session.user.id)
    let eventRole = targetPersonnel && targetPersonnel.field_event_role_name

    const canEditConference =
      (allRoles.includes("admin") || allRoles.includes("scheduler")) &&
      eventRole !== "Secretary"
    const canArchive =
      allRoles.includes("admin") || allRoles.includes("scheduler")

    const navTitle = (
      <span className="eventTitle">
        {activeEvent.name ? activeEvent.name : "New " + type}

        <span className="dateTime">
          {
            <div
              className="flexRow"
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <div>
                {startDate && !startDate.includes("Invalid") ? (
                  <>{moment(startDate).format("dddd, MMMM Do")}</>
                ) : (
                  <>{moment(startDate).format("dddd, MMMM Do")}</>
                )}
                {!activeEvent.allDay && (
                  <>
                    ,{" "}
                    {startDate && !startDate.includes("Invalid") ? (
                      <>{moment(startDate).format("h:mma")}</>
                    ) : (
                      <>{moment(startDate).format("h:mma")}</>
                    )}
                    {showEndDisplayDate && (
                      <> - {moment(endDate).format("dddd, MMMM Do")},</>
                    )}{" "}
                    {!endDate.includes("Invalid") && (
                      <>
                        {!showEndDisplayDate && <>-</>}{" "}
                        {moment(endDate).format("h:mma")}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          }
        </span>
      </span>
    )

    const subNavProps = {
      missingFields,
      missingDraftFields,
      fetched: activeEvent.fetched,
      canPublish,
      backgroundColor: this.props.isConference ? "#54839F" : "#59C3E5",
      iconClass: !this.props.isObservation
        ? "icon calendar white"
        : "icon observation white",
      navTitle,
      showPublishButton,
      showDraftButton: true,
      canSave: !missingDraftFields.length,
      onHover: this.highlightFields,
      openCopyModal: () => {
        this.setState({ isCopyOpen: true })
      },
      publishLabel:
        activeEvent.id && !activeEvent.field_draft
          ? "Update " + type
          : "Publish",
      draftLabel: this.props.isConference
        ? "Save Draft & View Schedule"
        : "Save Draft",
    }

    let isContentLocked =
      activeEvent.editorLock &&
      activeEvent.currentEditor &&
      activeEvent.currentEditor.id &&
      activeEvent.currentEditor.id !== session.user.id

    return (
      <>
        <ModalCopy
          type={this.props.isConference ? "conference" : "event"}
          open={this.state.isCopyOpen}
          path={
            this.props.isSession
              ? "/events/view/conference/" +
                this.props.activeConference.drupal_internal__id
              : "/events"
          }
          copy={this.copySession}
          onClose={() => {
            this.setState({ isCopyOpen: false })
          }}
        />

        <ModalDelete
          type={this.props.isConference ? "conference" : "event"}
          open={this.state.isDeleteOpen}
          path={!this.props.isSession ? "/events" : null}
          deleteItem={() => {
            if (this.props.isSession) {
              this.deleteDraft()
              this.props.deleteSessionDraft({ id: this.props.activeEvent.id })
              this.props.setIsEditingEvent(false)
            } else {
              this.deleteDraft()
            }
          }}
          onClose={() => {
            this.setState({ isDeleteOpen: false })
          }}
        />

        <ModalArchiveEvent
          isConference={this.props.isConference}
          open={this.state.isArchiveOpen}
          archive={this.completeArchive}
          path={
            this.props.isSession
              ? "/events/view/conference/" +
                this.props.activeConference.drupal_internal__id
              : "/events"
          }
          onClose={() => {
            this.setState({ isArchiveOpen: false })
          }}
        />

        <ModalEventConfirmation
          canPublish={this.state.canPublish}
          conference={activeConference}
          open={this.state.isConfirmModalOpen}
          event={activeEvent}
          session={session}
          path={"/events"}
          confirm={this.completePublish}
          isConference={this.props.isConference}
          isSession={this.props.isSession}
          onClose={() => {
            this.setState({ isConfirmModalOpen: false })
          }}
        />

        <ModalResendEmail
          conference={activeConference}
          open={this.state.isResendEmailOpen}
          event={activeEvent}
          path={"/events"}
          confirm={this.completePublish}
          isConference={this.props.isConference}
          isSession={this.props.isSession}
          onClose={() => {
            this.setState({ isResendEmailOpen: false })
          }}
        />

        <SubNav {...subNavProps}>
          {!activeEvent.field_archive &&
            !activeEvent.field_draft &&
            activeEvent.id &&
            canArchive && (
              <Button
                onClick={this.archive}
                className={classNames(
                  "button small",
                  isContentLocked && "disabled"
                )}
              >
                <span className={"icon archive"} />
                Archive
              </Button>
            )}

          {this.props.isConference &&
            window.location.href.includes("/view") && (
              <Button
                onClick={this.exportSessions}
                className={classNames("button small")}
              >
                <span className="icon export white" /> Export Sessions
              </Button>
            )}

          {activeEvent.field_archive && (
            <Button
              onClick={() => {
                this.unarchive()
              }}
              className={classNames("button small")}
            >
              <span
                className={
                  ("icon unarchive white", isContentLocked && "disabled")
                }
              />
              Unarchive
            </Button>
          )}

          {!activeEvent.field_draft &&
            activeEvent.id &&
            !this.props.isConference &&
            !window.location.href.includes("session") && (
              <Button
                onClick={() => {
                  this.setState({ isCopyOpen: true })
                }}
                className={classNames(
                  "button small",
                  isContentLocked && "disabled"
                )}
              >
                <span className={"icon copy"} />
                Copy
              </Button>
            )}

          {activeEvent.field_draft && showPublishButton && activeEvent.id && (
            <Button
              onClick={() => {
                this.setState({ isDeleteOpen: true })
              }}
              className={classNames(
                "button small",
                isContentLocked && "disabled",
                missingDraftFields.length && "disabled"
              )}
            >
              <span className={"icon trash-white"} />
              Delete
            </Button>
          )}
          {(activeEvent.field_draft || !activeEvent.id) &&
            !missingDraftFields.length &&
            showPublishButton && (
              <Button
                onClick={this.saveDraft}
                className={classNames(
                  "button small",
                  missingDraftFields.length && "disabled",
                  isContentLocked && "disabled"
                )}
              >
                <span className={"icon draft white"} />

                {this.props.isConference ? (
                  <>Save Draft & View Schedule</>
                ) : (
                  <> Save Draft </>
                )}
              </Button>
            )}
          {canPublish && showPublishButton && (
            <Button
              onClick={this.publish}
              className={classNames(
                "button small",
                isContentLocked && "disabled",
                missingDraftFields.length && "disabled"
              )}
            >
              <span className={"icon calendar white"} />
              {!activeEvent.id || activeEvent.field_draft
                ? "Publish"
                : "Update " + type}
            </Button>
          )}
          {this.props.isConference &&
            window.location.href.includes("/view") &&
            canEditConference && (
              <Button
                onClick={this.editConference}
                className={classNames("button small")}
              >
                <span className="icon edit white" /> Edit Conference
              </Button>
            )}
        </SubNav>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventNavbarContainer)
