import { styled } from "@mui/material"

type PanelProps = {
  disabled?: boolean
}
export const Panel = styled("div")<PanelProps>(({ theme, disabled }) => ({
  ...(!disabled
    ? {
        padding: theme.spacing(4),
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.spacing(1),
        // @todo: use theme.shadows[]
        boxShadow: `1px 4px 5px 0 rgba(0, 0, 0, 0.15);`,
      }
    : {
        // Disable pointer events when the panel is disabled
        pointerEvents: "none",
      }),
  marginBottom: theme.spacing(4),
  "&>h3:first-child": {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}))
