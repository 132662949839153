import { Card, CardContent } from "@mui/material"
import Rating from "./Rating"
import CategoryIcon from "./CategoryIcon"

import "../styles/courseCard.scss"
import theme from "@mobilemind/common/src/theme/theme"

function CourseCard(props) {
  const { course } = props
  const { field_category } = course
  return (
    <>
      <Card variant={"elevation"} className="card small" />
      <Card variant={"elevation"} className="card small">
        <div
          className="card-image"
          style={{
            backgroundColor:
              field_category?.attributes?.field_hex_color ??
              theme.palette.primary.main,
          }}
        />
        <CardContent>
          <header>
            <div className="category-icon-wrapper">
              {field_category && field_category.attributes ? (
                <CategoryIcon category={field_category} />
              ) : (
                <span className="category-icon other" />
              )}
            </div>
            <div className="course-label">
              <span className="category-label">
                {field_category?.attributes?.name?.toLowerCase() ?? "Other"}
              </span>
              <div className="course-level">
                <Rating level={course.level.toLowerCase()} />
              </div>
            </div>
          </header>
          <h2>{course.name}</h2>
        </CardContent>
        <footer className="course-details">
          <p>{course.objective}</p>
        </footer>
      </Card>
      <Card variant={"elevation"} className="card small" />
    </>
  )
}

export default CourseCard
