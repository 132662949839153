import { OrgRubricCriteriaLevel } from "@mobilemind/common/src/types/rubrics"
import { Box, Button, Stack, TextField } from "@mui/material"
import { FastField, FieldArray, useField } from "formik"
import plusImg from "../../../img/plus.svg"
import { criteriaLevelSchema } from "../EditRubricForm"
import { FastFieldWithError } from "components/FastFieldWithError"
import { DeleteButton } from "@mobilemind/common/src/components/DeleteButton"

type RubricCriteriaLevelsContainerProps = {
  name: string
  index: number
}

export function RubricCriteriaLevelsContainer({
  name,
  index,
}: RubricCriteriaLevelsContainerProps) {
  const [field] = useField<OrgRubricCriteriaLevel[]>(name)

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: 4,
            }}
          >
            {field.value.map((level, levelIndex) => {
              return (
                <Stack
                  key={`${name}.${levelIndex}`}
                  spacing={5}
                  useFlexGap={true}
                >
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    spacing={1}
                    useFlexGap={true}
                  >
                    <FastFieldWithError
                      as={TextField}
                      name={`${name}.${levelIndex}.name`}
                      variant="standard"
                      label="Level Name"
                      required={true}
                    />
                    <DeleteButton
                      onClick={() => {
                        arrayHelpers.remove(levelIndex)
                      }}
                      sx={{ mt: "16px" }}
                    />
                  </Stack>
                  <FastField
                    as={TextField}
                    name={`${name}.${levelIndex}.description`}
                    variant="outlined"
                    multiline
                    label={"Level Description"}
                    margin="none"
                    rows={10}
                    InputProps={{
                      sx: { fontSize: 14, lineHeight: 1.2 },
                    }}
                  />
                  <FastField
                    as={TextField}
                    name={`${name}.${levelIndex}.points`}
                    variant="outlined"
                    label="Points"
                    type={"number"}
                    margin="none"
                    sx={{ width: 90 }}
                  />
                </Stack>
              )
            })}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="small"
              startIcon={<img src={plusImg} alt="Add Level" />}
              onClick={() => {
                arrayHelpers.push(criteriaLevelSchema.getDefault())
              }}
            >
              Add Level
            </Button>
          </Box>
        </>
      )}
    />
  )
}
