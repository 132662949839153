import { getOrgRubricById } from "@mobilemind/common/src/api/rubricApi"
import Loading from "@mobilemind/common/src/components/Loading"
import { OrgRubric } from "@mobilemind/common/src/types/rubrics"
import { Container } from "@mui/material"
import { useAsyncEffect } from "@react-hook/async"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { selectOrgId } from "store/selectors"
import { EditRubricForm, rubricSchema } from "./EditRubricForm"

export function EditRubricContainer() {
  const [message, setMessage] = useState<string | null>(null)

  const dispatch = useAppDispatch()
  const params = useParams<{ uuid: string }>()
  const organization = useAppSelector(selectOrgId)

  const {
    status,
    error,
    value: initialValues,
  } = useAsyncEffect<OrgRubric>(async () => {
    setMessage("Loading data…")

    if (params.uuid) {
      setMessage("Getting rubric...")
      return await dispatch(getOrgRubricById.initiate(params.uuid)).unwrap()
    } else {
      return {
        ...rubricSchema.getDefault(),
        organization,
      }
    }
  }, [params])

  if (error) {
    throw error
  }

  if (status !== "success" || !initialValues) {
    return <Loading fullPage={true} message={message} />
  }

  return (
    <Container
      fixed={true}
      maxWidth="lg"
      sx={{
        position: "relative",
        marginTop: 25,
        marginBottom: 5,
      }}
    >
      <EditRubricForm initialValues={initialValues} />
    </Container>
  )
}
