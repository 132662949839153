import { Box, Typography } from "@mui/material"
import { CourseFormValues } from "../types"
import { CategoryIcon } from "@mobilemind/common/src/components/CategoryIcon"
import { SkillLevel } from "@mobilemind/common/src/types/course"

import Star from "../../../img/star-white.svg"
import Clock from "../../../img/clock-white.svg"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"

type CourseMetaCapsuleProps = {
  course: CourseFormValues
}

export const CourseMetaCapsule = ({ course }: CourseMetaCapsuleProps) => {
  const estimatedTime = convertMinutesToHoursMinutes(course.estimatedTime)

  return (
    <Box
      sx={{
        position: "relative",
        color: "white",
        backgroundColor: "black",
        paddingBlock: 1,
        paddingInline: 3,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        borderRadius: 4,
        marginTop: "-15px",
        marginInline: "auto",
        width: "max-content",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        {course.field_category && course.field_category.image && (
          <CategoryIcon
            size="small"
            src={
              process.env.REACT_APP_API_URL +
              course.field_category.image.attributes.uri.url
            }
          />
        )}
        <Typography sx={{ fontSize: 14 }}>
          {course.field_category ? (
            <> {course.field_category.attributes.name} </>
          ) : (
            <>No Category</>
          )}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <img src={Clock} alt="Clock" width={15} height={15} />
        {estimatedTime && (
          <Typography sx={{ fontSize: 14 }}>
            {estimatedTime.minutes}m
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
        }}
      >
        <img src={Star} alt="Star" width={15} height={15} />
        {course.level === SkillLevel.Intermediate && (
          <img src={Star} alt="Star" width={15} height={15} />
        )}
        {course.level === SkillLevel.Advanced && (
          <img src={Star} alt="Star" width={15} height={15} />
        )}
      </Box>
    </Box>
  )
}
