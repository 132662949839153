import { Component } from "react"
import { connect } from "react-redux"
import NavbarSecondary from "../../components/NavbarSecondary"
import {
  saveAnnouncement,
  sendAnnouncementEmail,
  deleteAnnouncement,
} from "../../actions/announcements"
import ModalDelete from "../../components/ModalDelete"
import ModalAnnouncementPreview from "../../components/ModalAnnouncementPreview"
import ModalAnnouncementEmailConfirm from "../../components/ModalAnnouncementEmailConfirm"
import { fetchActiveLearningPath } from "../createLearningPath/activeLearningPathSlice"
import { updateField } from "./activeAnnouncementSlice"
import { isGroupLevel } from "../../functions"

import "../../styles/navbar.scss"

const mapStateToProps = ({
  categories,
  courses,
  learningPaths,
  activeAnnouncement,
  announcementTypes,
  session,
}) => {
  return {
    categories,
    courses,
    learningPaths,
    activeAnnouncement,
    announcementTypes,
    session,
  }
}

const mapDispatchToProps = {
  saveAnnouncement,
  fetchActiveLearningPath,
  updateField,
  deleteAnnouncement,
  sendAnnouncementEmail,
}

class AnnouncementNavbarContainer extends Component {
  state = {
    isDeleteOpen: false,
    isPreviewOpen: false,
    isEmailConfirmOpen: false,
  }

  saveDraft = () => {
    this.props
      .saveAnnouncement(this.props.activeAnnouncement, true)
      .then(() => {
        this.props.history.push("/create/announcements")
      })
  }

  deleteDraft = () => {
    this.props.deleteAnnouncement(this.props.activeAnnouncement.id).then(() => {
      this.props.history.push("/create/announcements")
    })
  }

  publish = ({ noResend }) => {
    const { activeAnnouncement } = this.props
    // If this has already been published and an email went out
    if (
      !this.state.isEmailConfirmOpen &&
      !activeAnnouncement.field_draft &&
      activeAnnouncement.publishMethod !== "no_email"
    ) {
      this.setState({ isEmailConfirmOpen: true })
    } else {
      this.props
        .saveAnnouncement(this.props.activeAnnouncement)
        .then((response) => {
          let body = this.props.activeAnnouncement.id
            ? this.props.activeAnnouncement
            : response

          if (!noResend) {
            this.props.sendAnnouncementEmail(body)
          }
        })
      this.props.history.push("/create/announcements")
    }
  }

  resendEmail = () => {
    let announcement = { ...this.props.activeAnnouncement }
    this.props
      .saveAnnouncement(this.props.activeAnnouncement, false, true)
      .then((response) => {
        this.props.sendAnnouncementEmail(announcement)
        this.props.history.push("/create/announcements")
      })
  }

  highlightFields = (missingFields) => {
    this.props.updateField({ field: "missingFields", value: missingFields })
  }

  render() {
    const {
      activeAnnouncement,
      learningPaths,
      announcementTypes,
      courses,
      session,
    } = this.props

    let missingFields = []
    let missingDraftFields = []
    !activeAnnouncement.name && missingFields.push("Title")
    !activeAnnouncement.name && missingDraftFields.push("Title")

    activeAnnouncement.type === "Select Type" && missingFields.push("Type")
    !activeAnnouncement.body && missingFields.push("Body")

    if (!session.isCollectionAdmin) {
      if (
        isGroupLevel(session) &&
        session.subgroups.data &&
        session.subgroups.data.length > 1
      ) {
        if (!activeAnnouncement.subGroups.length) {
          missingFields.push("Make visible to at least 1 group")
        }
      }
    }
    let type = announcementTypes.data.find(
      (type) => type.id === activeAnnouncement.type
    )

    if (type && type.attributes) {
      if (type.attributes.name === "Featured Course") {
        if (!activeAnnouncement.featuredCourse) {
          missingFields.push("Select a featured course")
        }
      }
      if (type.attributes.name === "Featured Learning Path") {
        if (!activeAnnouncement.featuredLearningPath) {
          missingFields.push("Select a featured learning path")
        }
      }
    }
    let canSave = activeAnnouncement.name && !missingFields.length

    const subGroupsData =
      session.subgroups && session.subgroups.data ? session.subgroups.data : []
    let canPublish =
      activeAnnouncement.name &&
      activeAnnouncement.body &&
      activeAnnouncement.type !== "Select Type" &&
      (!isGroupLevel(session) ||
        activeAnnouncement.subGroups.length ||
        subGroupsData.length < 2) &&
      !missingFields.length

    const props = {
      type: "Announcement",
      iconClass: "icon announcement",
      publish: this.publish,
      saveDraft: this.saveDraft,
      onHover: this.highlightFields,
      openDeleteModal: () => {
        this.setState({ isDeleteOpen: true })
      },
      openPreview: () => {
        this.setState({ isPreviewOpen: true })
      },
      activeItem: activeAnnouncement,
      canSave,
      canPublish,
      missingFields,
      missingDraftFields,
      groupRoles: session.groupRoles,
      orgRoles: session.orgRoles,
    }

    let featuredCourse, featuredLearningPath, label
    let announcementType = announcementTypes.data.find(
      (type) => type.id === activeAnnouncement.type
    )
    label = announcementType && announcementType.attributes.name

    if (label === "Featured Course") {
      if (activeAnnouncement.featuredCourse) {
        featuredCourse = courses.data.find(
          (course) => course.id === activeAnnouncement.featuredCourse.id
        )
      }
    }
    if (label === "Featured Learning Path") {
      if (activeAnnouncement.featuredLearningPath) {
        featuredLearningPath = learningPaths.data.find(
          (path) => path.id === activeAnnouncement.featuredLearningPath.id
        )
      }
    }

    return (
      <>
        <ModalAnnouncementEmailConfirm
          type={"announcement"}
          open={this.state.isEmailConfirmOpen}
          history={this.props.history}
          saveAnnouncement={this.publish}
          resendEmail={this.resendEmail}
          onClose={() => {
            this.setState({ isEmailConfirmOpen: false })
          }}
        />

        <ModalDelete
          type={"announcement"}
          open={this.state.isDeleteOpen}
          path={"/create/announcements"}
          deleteItem={this.deleteDraft}
          onClose={() => {
            this.setState({ isDeleteOpen: false })
          }}
        />
        <ModalAnnouncementPreview
          announcement={activeAnnouncement}
          open={this.state.isPreviewOpen}
          label={label}
          featuredCourse={featuredCourse}
          featuredLearningPath={featuredLearningPath}
          onClose={() => {
            this.setState({ isPreviewOpen: false })
          }}
        />
        <NavbarSecondary {...props} />
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementNavbarContainer)
