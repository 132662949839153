const sessionColors = [
  "#A7124A",
  "#D71B54",
  "#E66E69",
  "#D41B06",
  "#F84024",
  "#F25C1C",
  "#FAB539",
  "#F38524",
  "#E4BB49",
  "#BCC241",
  "#25AD72",
  "#71A946",
  "#0C753F",
  "#1A8C7D",
  "#327CEC",
  "#2F92DD",
  "#2D49A7",
  "#697CC0",
  "#935EA1",
  "#A893D2",
  "#821F9B",
  "#6F4A40",
  "#9E9084",
  "#565656",
]

export default sessionColors
