import { FormControlLabel, Switch, Tooltip, Typography } from "@mui/material"
import { useAsyncEffect } from "@react-hook/async"
import { useFormikContext } from "formik"
import { ReactNode, useContext } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { selectHasGenieAccess } from "store/selectors"
import GenieIcon from "../../../img/genie.svg"
import {
  activateCourseGenie,
  resetCourseGenie,
  resumeCourse,
  selectCourseGenieActive,
  showCourseGenieError,
} from "../store/courseGenieSlice"
import { CourseFormValues } from "../types"
import { GenieContext } from "./GenieContext"

export const GenieActiveSwitch = () => {
  const { genieEnabled, videoItem } = useContext(GenieContext)
  const { values } = useFormikContext<CourseFormValues>()
  const dispatch = useAppDispatch()
  const hasGenieAccess = useAppSelector(selectHasGenieAccess)
  const genieActive = useAppSelector(selectCourseGenieActive)

  const { value: switchProps } = useAsyncEffect<Partial<{
    disabled: boolean
    tooltip?: ReactNode
  }> | null>(async () => {
    if (!genieEnabled) {
      // Disable the switch if the genie isn't enabled for this org/course
      return {
        disabled: true,
        tooltip: (
          <>
            <strong>You're out of wishes.</strong>
            <br />
            Contact MobileMind for unlimited Genie access!
          </>
        ),
      }
    }
    if (!videoItem) {
      // Disable the switch if the video link isn't a youtube video
      return {
        disabled: true,
        tooltip: "AI Genie is only usable with YouTube videos.",
      }
    }
    if (!hasGenieAccess) {
      return {
        tooltip:
          "I can help you edit this course but contact MobileMind for unlimited AI Genie access!",
      }
    }
    return null
  }, [genieEnabled, videoItem, hasGenieAccess])

  return (
    <Tooltip
      title={switchProps?.tooltip}
      placement="bottom-end"
      slotProps={{
        tooltip: {
          sx: {
            padding: 2,
            maxWidth: 220,
            boxShadow: 4,
            lineHeight: 1.7,
            fontSize: 10,
            opacity: 0.8,
          },
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      <FormControlLabel
        labelPlacement="start"
        label={
          <Typography sx={{ fontWeight: "bold" }} variant="caption">
            Use AI Genie?
          </Typography>
        }
        control={
          <Switch
            // Disable in edit mode if org in trial mode or if youtube video is missing
            disabled={switchProps?.disabled}
            sx={{
              "& .MuiSwitch-thumb:before": {
                content: "''",
                position: "absolute",
                width: 23,
                height: 22,
                left: 0,
                top: 1,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                filter: "grayscale(1)",
                backgroundImage: "url(" + GenieIcon + ")",
              },
              "& .MuiSwitch-switchBase": {
                "&.Mui-checked": {
                  "& .MuiSwitch-thumb:before": {
                    content: "''",
                    position: "absolute",
                    width: 23,
                    height: 22,
                    left: 0,
                    top: 1,
                    backgroundSize: "contain",
                    filter: "grayscale(0)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundImage: "url(" + GenieIcon + ")",
                  },
                },
              },
            }}
            checked={genieActive}
            onChange={async (event, checked) => {
              if (checked) {
                dispatch(activateCourseGenie())
              } else {
                dispatch(resetCourseGenie())
              }
              if (values.id && videoItem && checked) {
                try {
                  await dispatch(
                    resumeCourse({ values, video: videoItem })
                  ).unwrap()
                } catch (err) {
                  dispatch(showCourseGenieError())
                  dispatch(resetCourseGenie())
                }
              }
            }}
            size="small"
          />
        }
        sx={{
          gap: 1,
          backgroundColor: "white",
          paddingBlock: 1,
          paddingInline: 2,
          borderRadius: 5,
          margin: 0,
        }}
      />
    </Tooltip>
  )
}
