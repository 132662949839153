export function generateQuizQuestionsRequest(questions, createdQuiz, isCopy) {
  const quiz = createdQuiz.data

  // The first request just gets the paragraph type
  let quizQuestionsRequest = [
    {
      requestId: "paragraph_type",
      action: "view",
      uri: "/api/paragraphs_type/paragraphs_type?filter[label]=MC Question",
      headers: { Accept: "application/vnd.api+json" },
    },
  ]

  let requestsToWaitFor
  let field_mc_questions = []

  questions.forEach((question, questionIndex) => {
    let questionValue = question.value ? question.value : "missing"
    const field_require_all = question.requireAllAnswers ? "1" : "0"

    // For each answer, create a paragraph we will later attach to the quiz, or update the existing one if changes were made
    if (question.id && question.id !== "new" && !isCopy) {
      quizQuestionsRequest.push({
        action: "update",
        requestId: "question-" + questionIndex,
        uri: "/api/paragraph/mc_question/" + question.id,
        headers: { "Content-Type": "application/vnd.api+json" },
        waitFor: ["paragraph_type"],
        body:
          '{"data": {"type": "paragraph--mc_question","id":"' +
          question.id +
          '","attributes": {"status": true,"parent_id":"' +
          quiz.attributes.drupal_internal__id +
          '","parent_type": "quiz_entity","field_require_all": "' +
          field_require_all +
          '","parent_field_name": "field_mc_questions","field_question":"' +
          questionValue.replaceAll('"', "***").replaceAll('"', "***") +
          '"}}}', // eslint-disable-line no-useless-escape
      })
    } else {
      quizQuestionsRequest.push({
        action: "create",
        requestId: "question-" + questionIndex,
        uri: "/api/paragraph/mc_question/",
        headers: { "Content-Type": "application/vnd.api+json" },
        waitFor: ["paragraph_type"],
        body:
          '{"data": {"type": "paragraph--mc_question","attributes": {"status": true,"parent_id":"' +
          quiz.attributes.drupal_internal__id +
          '","parent_type": "quiz_entity","field_require_all": "' +
          field_require_all +
          '","parent_field_name": "field_mc_questions","field_question":"' +
          questionValue.replaceAll('"', "***").replaceAll('"', "***") +
          '"}}}', // eslint-disable-line no-useless-escape
      })
    }

    field_mc_questions.push(
      '{"type": "paragraph--mc_question","id": "{{question-' +
        questionIndex +
        '.body@$.data.id}}","meta": {"target_revision_id": "{{question-' +
        questionIndex +
        '.body@$.data.attributes.drupal_internal__revision_id}}"}}'
    )

    let updateWaitFor = ["question-" + questionIndex]
    let updateAnswers = "["

    question.answers.forEach((answer, index) => {
      updateWaitFor.push("answer-" + questionIndex + "-" + index)
      updateAnswers +=
        '{"type": "paragraph--multiple_choice","id": "{{answer-' +
        questionIndex +
        "-" +
        index +
        '.body@$.data.id}}","meta": {"target_revision_id": "{{answer-' +
        questionIndex +
        "-" +
        index +
        '.body@$.data.attributes.drupal_internal__revision_id}}"}}'

      if (index < question.answers.length - 1) {
        updateAnswers += ","
      } else {
        updateAnswers += "]"
      }

      requestsToWaitFor = questions.map((question, mapIndex) => {
        return "question-" + mapIndex
      })

      let type = answer.isCorrect ? "correct" : "incorrect"

      if (answer.id && !isCopy) {
        quizQuestionsRequest.push({
          action: "update",
          requestId: "answer-" + questionIndex + "-" + index,
          uri: "/api/paragraph/multiple_choice/" + answer.id,
          headers: { "Content-Type": "application/vnd.api+json" },
          waitFor: requestsToWaitFor,
          body:
            '{"data": {"type": "paragraph--multiple_choice","id": "' +
            answer.id +
            '","attributes": {"parent_id": "{{question-' +
            questionIndex +
            '.body@$.data.attributes.drupal_internal__id}}", "field_mc_answer": "' +
            answer.value.replaceAll('"', "***").replaceAll('"', "***") +
            '","field_mc_answer_type":"' +
            type +
            '", "parent_field_name": "field_answers"}}}', // eslint-disable-line no-useless-escape
        })
      } else {
        quizQuestionsRequest.push({
          action: "create",
          requestId: "answer-" + questionIndex + "-" + index,
          uri: "/api/paragraph/multiple_choice/",
          headers: { "Content-Type": "application/vnd.api+json" },
          waitFor: requestsToWaitFor,
          body:
            '{"data": {"type": "paragraph--multiple_choice","attributes": {"parent_id": "{{question-' +
            questionIndex +
            '.body@$.data.attributes.drupal_internal__id}}", "field_mc_answer": "' +
            answer.value.replaceAll('"', "***").replaceAll('"', "***") +
            '","field_mc_answer_type":"' +
            type +
            '", "parent_field_name": "field_answers"}}}', // eslint-disable-line no-useless-escape
        })
      }
    })

    quizQuestionsRequest.push({
      action: "update",
      requestId: "question-" + questionIndex + "-update",
      uri:
        "/api/paragraph/mc_question/{{question-" +
        questionIndex +
        ".body@$.data.id}}",
      headers: {
        "Content-Type": "application/vnd.api+json",
      },
      waitFor: updateWaitFor,
      body:
        '{"data": { "type": "paragraph--mc_question","id": "{{question-' +
        questionIndex +
        '.body@$.data.id}}","relationships": {"field_answers": {"data":' +
        updateAnswers +
        "}}}}",
    })
  })

  quizQuestionsRequest.push({
    action: "update",
    requestId: "quiz",
    uri: "/api/quiz_entity/course_quiz/" + createdQuiz.data.id,
    headers: { "Content-Type": "application/vnd.api+json" },
    waitFor: requestsToWaitFor,
    body:
      '{"data": {"type": "quiz_entity--course_quiz", "id": "' +
      createdQuiz.data.id +
      '", "relationships": {"field_mc_questions": {"data":[' +
      field_mc_questions +
      "]}}}}",
  })

  return quizQuestionsRequest
}
