import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useAppDispatch } from "store/hooks"

import { updateField, updatePartnerFilters } from "./leaderboardsSlice"

import moment from "moment"

function SidebarPartnerLeaderboards(props) {
  const dispatch = useAppDispatch()

  const filters = props.leaderboards.partnerFilters
  const { leaderboardType, start, end } = filters

  function handleTabChange(event, newValue) {
    dispatch(updateField({ field: "dateOption", value: newValue }))

    if (newValue === "allTime") {
      dispatch(updatePartnerFilters({ name: "start", value: null }))
    }
    if (newValue === "week") {
      dispatch(
        updatePartnerFilters({
          name: "start",
          value: JSON.stringify(
            moment().startOf("week").add(1, "day").format()
          ),
        })
      )
    }
    if (newValue === "month") {
      dispatch(
        updatePartnerFilters({
          name: "start",
          value: JSON.stringify(moment().startOf("month").format()),
        })
      )
    }
    if (newValue === "year") {
      dispatch(
        updatePartnerFilters({
          name: "start",
          value: JSON.stringify(moment().startOf("year").format()),
        })
      )
    }
    if (newValue === "custom") {
      dispatch(updatePartnerFilters({ name: "start", value: null }))
    }
  }

  return (
    <div id="sidebar">
      <form className="filters">
        <header style={{ marginBottom: 15 }}>
          <strong>Filter</strong>
          {/* <Button
            className="button small"
            onClick={() => {
              dispatch(resetFilters())
            }}
          >
            Reset
          </Button> */}
        </header>

        <FormControl variant="standard" className="inputSelect leaderboardType">
          <InputLabel id="label-leaderboardType-select">Leaderboard</InputLabel>
          <Select
            variant="standard"
            labelId="label-leaderboardType-select"
            id="leaderboardType-select"
            value={leaderboardType}
            onChange={(event) =>
              dispatch(
                updatePartnerFilters({
                  name: "leaderboardType",
                  value: event.target.value,
                })
              )
            }
          >
            <MenuItem value={"course_leaderboard"}>Courses Completed</MenuItem>
            <MenuItem value={"badges_leaderboard"}>Badges Earned</MenuItem>
            <MenuItem value={"lp_leaderboard"}>
              Learning Paths Completed
            </MenuItem>
          </Select>
        </FormControl>

        <Tabs
          className="dateTabs"
          value={props.leaderboards.dateOption}
          onChange={handleTabChange}
        >
          <Tab value="allTime" label="All Time" />
          <Tab value="week" label="This Week" />
          <Tab value="month" label="This Month" />
          <Tab value="year" label="This Year" />
          <Tab value="dateRange" label="Custom" />
        </Tabs>

        {props.leaderboards.dateOption === "dateRange" && (
          <div id="date-range-container">
            <DatePicker
              label="Date From"
              value={moment(start)}
              onChange={(date) => {
                dispatch(
                  updatePartnerFilters({
                    name: "start",
                    value: JSON.stringify(date),
                  })
                )
              }}
            />

            <DatePicker
              label="Date To"
              value={moment(end)}
              onChange={(date) => {
                dispatch(
                  updatePartnerFilters({
                    name: "dateTo",
                    value: JSON.stringify(date),
                  })
                )
              }}
            />
          </div>
        )}
      </form>
    </div>
  )
}

export default SidebarPartnerLeaderboards
