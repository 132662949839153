import { createSlice } from "@reduxjs/toolkit"

type InitialState = {
  isStarted: boolean
  hasStarted: boolean
}

const initialState: InitialState = {
  isStarted: false,
  hasStarted: false,
}

export const productTour = createSlice({
  name: "productTour",
  initialState,
  reducers: {
    startTour: (state) => {
      state.isStarted = true
      state.hasStarted = true
    },
    endTour: (state) => {
      state.isStarted = false
      state.hasStarted = true
    },
  },
})

export const { startTour, endTour } = productTour.actions

export default productTour.reducer
