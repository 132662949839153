import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import classNames from "classnames"
import { CourseFormValues } from "../types"
import { useFormikContext } from "formik"
import { useAppSelector } from "store/hooks"
import { isGroupLevel } from "functions"
import { SubGroup } from "@mobilemind/common/src/types/groups"
import { selectSubGroups } from "store/selectors"

export const CourseOwnerField = () => {
  const session = useAppSelector((state) => state.session)
  const subGroups = useAppSelector(selectSubGroups)

  const { values, setFieldValue } = useFormikContext<CourseFormValues>()

  // Single group member
  const isSingleGroupMember =
    isGroupLevel(session) && subGroups.data.length === 1

  let ownerValue: string = ""

  if (isGroupLevel(session)) {
    ownerValue = values.subGroupOwner
      ? values.subGroupOwner
      : subGroups && subGroups.data && subGroups.data[0] && subGroups.data[0].id
  } else {
    ownerValue = values.subGroupOwner ? values.subGroupOwner : "org"
    let canFindGroup = subGroups.data.find(
      (group: SubGroup) => group.id === values.subGroupOwner
    )

    if (!canFindGroup && ownerValue !== "org") {
      let byEntityId = subGroups.data.find(
        (group: SubGroup) =>
          group.relationships.entity_id.data?.id === values.subGroupOwner
      )
      if (byEntityId) {
        ownerValue = byEntityId.id
      }
    }
  }

  return (
    <FormControl
      variant="standard"
      className={classNames(
        "inputSelect subGroupSelect",
        (isSingleGroupMember || !isGroupLevel) && "locked"
      )}
    >
      <Typography
        component="label"
        htmlFor="subgroup-owner-select"
        variant="h3"
      >
        Course Owner
      </Typography>
      <FormHelperText>
        {ownerValue === "org" ? (
          <>
            Organization Admins and Creators will be able to edit this course.
          </>
        ) : (
          <>
            Group Admins and Creators in{" "}
            {isSingleGroupMember ? (
              <>your group</>
            ) : (
              <strong>
                {
                  subGroups.data.find((group: any) => group.id === ownerValue)
                    .attributes.label
                }
              </strong>
            )}{" "}
            will be able to edit this course. Organization Admins and Creators
            will also be able to edit.
          </>
        )}
      </FormHelperText>
      <Select
        sx={{ marginTop: 3 }}
        variant="standard"
        labelId="label-subgroup-owner-select"
        id="subgroup-owner-select"
        value={ownerValue}
        onChange={(event) => {
          setFieldValue("subGroupOwner", event.target.value)
        }}
      >
        {session.orgRoles.includes("organization-admin") && (
          <MenuItem key={0} value={"org"}>
            Org - {session.group.label[0].value}
          </MenuItem>
        )}
        {subGroups.data.map((group: SubGroup) => {
          return (
            <MenuItem key={group.id} value={group.id}>
              {group.attributes.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
