import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

type Session = {
  token: string
}

type RootState = {
  session: Session
}

export enum CacheTag {
  OrgRubric = "OrgRubric",
}

export const api = createApi({
  reducerPath: "api",
  tagTypes: Object.values(CacheTag),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: "include",
    jsonContentType: "application/vnd.api+json",
    prepareHeaders: (headers, { getState }) => {
      headers.set("Accept", "application/vnd.api+json")
      const { session } = getState() as RootState
      headers.set("X-CSRF-Token", session.token)
      return headers
    },
  }),
  endpoints: () => ({}),
})
