import {
  GenieRewriteAllowedField,
  YouTubeVideo,
} from "@mobilemind/genie-api/src/types"
import { createContext } from "react"
import { GenieElementProps } from "../types"

export type GenieContextValue = {
  genieEnabled: boolean
  getGenieElementProps: (
    field: GenieRewriteAllowedField,
    name: string
  ) => GenieElementProps | void
  videoItem: YouTubeVideo | null
}

export const GenieContext = createContext<GenieContextValue>({
  genieEnabled: false,
  getGenieElementProps: () => {},
  videoItem: null,
})
