import { Component } from "react"
import { connect } from "react-redux"

import EventSingleLayout from "../eventSingle/EventSingleLayout"

import {
  fetchActiveConference,
  startNewConference,
  setConferenceIsFetched,
} from "../conference/activeConferenceSlice"

import { withRouter } from "react-router-dom"

const mapStateToProps = ({ session, activeConference }, ownProps) => {
  return {
    eventId: ownProps.match ? ownProps.match.params.id : "new",
    conference: activeConference,
    session,
  }
}

const mapDispatchToProps = {
  fetchActiveConference,
  startNewConference,
  setConferenceIsFetched,
}

class ConferenceContainer extends Component {
  componentDidMount = () => {
    const { eventId } = this.props
    if (eventId === "new") {
      this.props.startNewConference(true)
    }
    if (eventId !== "new") {
      if (!this.props.conference.fetched) {
        this.props
          .fetchActiveConference({ isConference: true, id: eventId })
          .then(() => {
            this.props.setConferenceIsFetched(true)
          })
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.eventId !== prevProps.eventId) {
      this.props.startNewConference(true)
    }
  }

  render() {
    const { conference } = this.props

    return (
      <>
        <EventSingleLayout
          history={this.props.history}
          conference={this.props.conference}
          isConference={true}
        />
        {conference.isEditingEvent && (
          <div className="conferenceEventWrapper">
            <div className="conferencePanel"></div>
            <EventSingleLayout history={this.props.history} isSession={true} />
          </div>
        )}
      </>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConferenceContainer)
)
