import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import moment from "moment"
import pluralize from "pluralize"

export const fetchActiveAnnouncement = createAsyncThunk(
  "activeAnnouncementSlice/fetchActiveAnnouncement",
  async (announcementId, thunkAPI) => {
    let query = {
      filter: {
        drupal_internal__nid: announcementId,
      },
      include:
        "field_announcement_type,field_media,field_video,field_renewable_course_series,field_survey,field_course,field_learning_path,field_subgroup",
    }
    let response = await fetchWrapper.get(
      "/api/node/announcement?" + qs.stringify(query)
    )
    let data = await response.json()
    let announcement = data.data[0]

    if (announcement) {
      let announcement = data.data[0]
      announcement.image =
        data.included &&
        data.included.filter((included) => included.type === "file--image")
      announcement.type =
        data.included &&
        data.included.filter(
          (included) => included.type === "taxonomy_term--announcement_type"
        )

      announcement.featuredCourse =
        data.included &&
        data.included.find(
          (included) => included.type === "course_entity--course_entity"
        )
      announcement.featuredLearningPath =
        data.included &&
        data.included.find(
          (included) => included.type === "learning_path--learning_path"
        )

      announcement.selectedTraining =
        data.included &&
        data.included.find(
          (included) => included.type === "renew_course_ser--renew_course_ser"
        )
      if (announcement.selectedTraining) {
        announcement.selectedTraining.name =
          announcement.selectedTraining.attributes.title
        announcement.selectedTraining.uuid = announcement.selectedTraining.id
      }

      let subGroups =
        data.included &&
        data.included.filter((included) => included.type === "group--group")
      let subGroupIds =
        announcement.relationships.field_subgroup.data &&
        announcement.relationships.field_subgroup.data.map((group) => group.id)

      if (!subGroups || !subGroups.length) {
        let groupLevelSubGroups = thunkAPI.getState().session.subgroups
        announcement.subGroups =
          groupLevelSubGroups &&
          groupLevelSubGroups.data &&
          groupLevelSubGroups.data.filter((group) =>
            subGroupIds.includes(group.id)
          )
      } else {
        announcement.subGroups = subGroups
      }
      return announcement
    } else {
      return false
    }
  }
)

export const fetchLearningPathList = createAsyncThunk(
  "activeAnnouncementSlice/fetchLearningPathList",
  async (thunkAPI) => {
    let learningPaths = []
    let numPages = 1
    let i = 0
    let query = {}

    while (i < numPages) {
      query.page = {
        offset: i * 25,
      }

      let response = await fetchWrapper.get(
        "/api/learning_path/explore?" + qs.stringify(query)
      )

      if (response.ok) {
        const data = await response.json()
        learningPaths = learningPaths.concat(data.lp_data)

        numPages = Math.ceil(data.total_records / 25)
      }
      i++
    }

    return learningPaths
  }
)

const expirationDate = moment().add(14, "days").format()

export const activeAnnouncementSlice = createSlice({
  name: "activeAnnouncementSlice",
  initialState: {
    unauthorized: false,
    fetched: false,
    id: null,
    emailEntityId: null,
    type: "Select Type",
    publishMethod: "email_and_announcement",
    types: [],
    subGroups: [],
    image: null,
    name: "",
    body: "",
    coursesRequired: 0,
    expirationDate,
    missingFields: [],
    learningPaths: {
      fetched: false,
      data: [],
    },
    subGroupOwner: null,
    featuredCourse: null,
    featuredLearningPath: null,
    selectedTraining: null,
  },
  reducers: {
    startNewAnnouncement: (state) => {
      state.id = null
      state.emailEntityId = null
      state.image = null
      state.name = ""
      state.body = ""
      state.field_draft = true
      state.type = "Select Type"
      state.publishMethod = "email_and_announcement"
      state.subGroups = []
      state.coursesRequired = 0
      state.expirationDate = expirationDate
      state.fetched = true
      state.unauthorized = false
      state.missingFields = []
      state.subGroupOwner = null
      state.featuredCourse = null
      state.featuredLearningPath = null
      state.selectedTraining = null
      state.learningPaths = {
        fetched: false,
        data: [],
      }
    },
    setPartnerType: (state, action) => {
      state.type = action.payload?.id
    },
    startNewPartnerAnnouncement: (state, action) => {
      const { partner, image } = action.payload
      const { field_badges_count, field_course_count, field_lp_count } = partner

      const coursePhrase =
        field_course_count > 0 &&
        `<strong>${field_course_count}</strong> ${pluralize(
          "Course",
          Number(field_course_count)
        )}`

      const badgePhrase =
        field_badges_count > 0 &&
        `<strong>${field_badges_count}</strong> ${pluralize(
          "Badge",
          Number(field_badges_count)
        )}`

      const lpPhrase =
        field_lp_count > 0 &&
        `<strong>${field_lp_count}</strong> ${pluralize(
          "Learning Path",
          Number(field_lp_count)
        )}`

      let body = `<p>If you're reading this, you now have access to brand new content from <strong>${partner.name}</strong>!<br/><br/>This includes:<br/><ul>`
      if (coursePhrase) {
        body += `<li>${coursePhrase}</li>`
      }
      if (badgePhrase) {
        body += `<li>${badgePhrase}</li>`
      }
      if (lpPhrase) {
        body += `<li>${lpPhrase}</li>`
      }
      state.name = partner.name + " content now available!"
      state.image = image
      state.body = body
      state.field_draft = true
    },
    updateField: (state, action) => {
      state[action.payload.field] = action.payload.value
    },
    setName: (state, action) => {
      state.name = action.payload
    },
    setImage: (state, action) => {
      state.image = action.payload
    },
    removeImage: (state) => {
      state.image = null
    },
    setFilename: (state, action) => {
      state.filename = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setPublishMethod: (state, action) => {
      state.publishMethod = action.payload
    },
    setBody: (state, action) => {
      state.body = action.payload
    },
    setFeaturedCourse: (state, action) => {
      state.featuredCourse = action.payload
    },
    setFeaturedLearningPath: (state, action) => {
      state.featuredLearningPath = action.payload
    },
    setMandatedTraining: (state, action) => {
      state.selectedTraining = action.payload
    },
    setExpirationDate: (state, action) => {
      state.expirationDate = moment(action.payload.split("T")[0]).format()
    },
    setSubGroups: (state, action) => {
      state.subGroups = action.payload
    },
  },
  extraReducers: {
    [fetchLearningPathList.fulfilled]: (state, action) => {
      state.learningPaths = {
        fetched: true,
        data: action.payload,
      }
    },
    [fetchActiveAnnouncement.pending]: (state) => {
      state.fetched = false
    },
    [fetchActiveAnnouncement.fulfilled]: (state, action) => {
      if (action.payload) {
        state.unauthorized = false

        state.id = action.payload.id
        state.emailEntityId =
          action.payload.relationships.field_announcement_email.data &&
          action.payload.relationships.field_announcement_email.data.id

        state.name = action.payload.attributes.title
        state.subGroupOwner =
          action.payload.relationships.field_subgroup_owner.data &&
          action.payload.relationships.field_subgroup_owner.data.id
        state.image = action.payload.image && action.payload.image[0]
        state.body =
          action.payload.attributes.body && action.payload.attributes.body.value
            ? action.payload.attributes.body.value
            : ""

        state.type =
          action.payload.type && action.payload.type[0]
            ? action.payload.type[0].id
            : "Select Type"

        state.featuredCourse = action.payload.featuredCourse
        state.featuredLearningPath = action.payload.featuredLearningPath
        state.selectedTraining = action.payload.selectedTraining
        state.coursesRequired = action.payload.attributes.field_courses_complete
          ? action.payload.attributes.field_courses_complete
          : 0
        state.expirationDate = moment(
          action.payload.attributes.field_expiration_date
        ).format()

        state.field_draft = action.payload.attributes.field_draft
        state.publishMethod = action.payload.attributes.field_email
        state.fetched = true
        state.subGroups = action.payload.subGroups
          ? action.payload.subGroups
          : []
      } else {
        state.unauthorized = true
      }
    },
  },
})

export const {
  setName,
  setType,
  setPublishMethod,
  setBody,
  setFeaturedCourse,
  setFeaturedLearningPath,
  setMandatedTraining,
  setExpirationDate,
  setFilename,
  startNewAnnouncement,
  setPartnerType,
  setImage,
  removeImage,
  updateField,
  startNewPartnerAnnouncement,
  setSubGroups,
} = activeAnnouncementSlice.actions

export default activeAnnouncementSlice.reducer
