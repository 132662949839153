import {
  createOrgRubric,
  updateOrgRubric,
} from "@mobilemind/common/src/api/rubricApi"
import { OrgRubric } from "@mobilemind/common/src/types/rubrics"
import { Button, Stack } from "@mui/material"
import { SecondaryNavbar } from "components/SecondaryNavbar"
import { useFormikContext } from "formik"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "store/hooks"
import { ReactComponent as ArchiveIcon } from "../../../img/i-archive.svg"
import { ReactComponent as DraftIcon } from "../../../img/i-draft.svg"
import { ReactComponent as LaptopIcon } from "../../../img/laptop-white.svg"
import { RubricSubmitButton } from "./RubricSubmitButton"
import { openSnackbar } from "features/snackbar/snackbarSlice"

export function RubricNavbar() {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { initialValues, isSubmitting, setSubmitting, values } =
    useFormikContext<OrgRubric>()

  const handleArchive = async () => {
    if (values.uuid) {
      try {
        setSubmitting(true)
        const nextValues: OrgRubric = { ...values, archive: "1" }
        await dispatch(updateOrgRubric.initiate(nextValues)).unwrap()
        setSubmitting(false)
        history.push("/create/rubrics")
      } catch (error) {
        console.error(error)
        dispatch(
          openSnackbar({ message: "There was an problem saving the rubric." })
        )
      }
    }
  }

  const handleSaveDraft = async () => {
    try {
      setSubmitting(true)
      const nextValues: OrgRubric = { ...values, draft: "1" }
      if (values.uuid) {
        await dispatch(updateOrgRubric.initiate(nextValues)).unwrap()
      } else {
        await dispatch(createOrgRubric.initiate(nextValues)).unwrap()
      }
      setSubmitting(false)
      history.push("/create/rubrics")
    } catch (error) {
      console.error(error)
      dispatch(
        openSnackbar({ message: "There was an problem saving the rubric." })
      )
    }
  }

  return (
    <SecondaryNavbar
      isTemplate={false}
      icon={<LaptopIcon width={25} height={25} />}
      title={initialValues.id ? "Edit Rubric" : "New Rubric"}
    >
      <Stack direction="row" spacing={4}>
        {initialValues.uuid &&
          initialValues.archive === "0" &&
          initialValues.draft === "0" && (
            <Button
              size="small"
              startIcon={<ArchiveIcon width={20} height={20} />}
              disabled={isSubmitting}
              onClick={handleArchive}
            >
              Archive
            </Button>
          )}
        {initialValues.draft === "1" && (
          <Button
            size="small"
            startIcon={<DraftIcon width={20} height={20} />}
            disabled={isSubmitting}
            onClick={handleSaveDraft}
          >
            Save Draft
          </Button>
        )}
        <RubricSubmitButton />
      </Stack>
    </SecondaryNavbar>
  )
}
