import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import _ from "lodash"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import parseDataUrl from "parse-data-url"
import Values from "values.js"
import { filterPartnerCategories } from "@mobilemind/common/src/functions"
import he from "he"
import type { RootState } from "../types"
import { CategoryTaxonomy } from "@mobilemind/common/src/types/taxonomy/category"

export const getCategories = createAsyncThunk<
  Array<Array<CategoryTaxonomy>>,
  void,
  { state: RootState }
>("categories/getCategories", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()
  let excludedCategories: any[] = []

  if (
    session.group &&
    session.group.field_categories_to_exclude &&
    session.group.field_categories_to_exclude.length
  ) {
    excludedCategories =
      session.group &&
      session.group.field_categories_to_exclude &&
      session.group.field_categories_to_exclude
  }

  let pages = 1
  let i = 0
  let categories: any[] = []

  while (i < pages) {
    let query = {
      filter: {
        main: {
          group: {
            conjunction: "OR",
          },
        },
        mobilemind: {
          group: {
            conjunction: "AND",
            memberOf: "main",
          },
        },
        orgNull: {
          condition: {
            path: "field_organization",
            operator: "IS NULL",
            memberOf: "mobilemind",
          },
        },
        partnerNull: {
          condition: {
            path: "field_partner",
            operator: "IS NULL",
            memberOf: "mobilemind",
          },
        },
        org: {
          condition: {
            path: "field_organization.id",
            operator: "=",
            value: session.group.uuid[0].value,
            memberOf: "main",
          },
        },
      },
      include: "field_category_image",
      page: {
        offset: i * 50,
      },
    }

    let response = await fetchWrapper.get(
      "/api/taxonomy_term/category?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      pages = Math.ceil(Number(data.meta.count) / 50)
      data.data = filterPartnerCategories(data.data, session)

      data.data.forEach((category: any) => {
        let imageData =
          category.relationships.field_category_image &&
          category.relationships.field_category_image.data
        let categoryImage =
          data.included &&
          data.included.find(
            (included: any) => imageData && imageData.id === included.id
          )

        category.image = categoryImage

        if (excludedCategories) {
          if (
            excludedCategories.find(
              (excluded: any) =>
                excluded.target_id === category.attributes.drupal_internal__tid
            )
          ) {
            category.isVisible = false
          } else {
            category.isVisible = true
          }
        } else {
          category.isVisible = true
        }

        categories.push(category)
      })
    }

    i++
  }

  return categories
})

export const saveCategory = createAsyncThunk<any, any, { state: RootState }>(
  "categories/saveCategory",
  async (args, thunkAPI) => {
    const { session, categories } = thunkAPI.getState()

    const orgId =
      session.group && session.group.uuid && session.group.uuid[0].value
    const { newCategory, image, categoryColor, activeIcon } = args

    let body: {
      data: {
        id?: any
        type: string
        attributes: any
        relationships: {
          field_partner?: any
          field_organization?: any
          parent?: any
        }
      }
    } = {
      data: {
        type: "taxonomy_term--category",
        attributes: {
          name: newCategory.name,
          field_hex_color: new Values(categoryColor).shade(20).hexString(),
          field_icon_path: activeIcon,
        },
        relationships: {},
      },
    }

    if (session.isPartner) {
      body.data.relationships.field_partner = {
        data: {
          type: "group--partner",
          id: orgId,
        },
      }
    } else {
      body.data.relationships.field_organization = {
        data: {
          type: "group--organization",
          id: orgId,
        },
      }
    }

    if (newCategory.parentCategoryId !== "none") {
      body.data.relationships.parent = {
        data: {
          type: "taxonomy_term--category",
          id: newCategory.parentCategoryId,
        },
      }
    }

    let response, imageData

    if (!newCategory.id) {
      response = await fetchWrapper.post(
        "/api/taxonomy_term/category",
        session.token,
        JSON.stringify(body)
      )
    } else {
      body.data.id = newCategory.id
      response = await fetchWrapper.patch(
        "/api/taxonomy_term/category/" + newCategory.id,
        session.token,
        JSON.stringify(body)
      )
    }

    let savedCategory = await response.json()

    if (image) {
      const dataUrl = parseDataUrl(image)
      let imageResponse = await fetch(
        process.env.REACT_APP_API_URL +
          "/api/taxonomy_term/category/" +
          savedCategory.data.id +
          "/field_category_image",
        {
          credentials: "include",
          method: "POST",
          headers: new Headers({
            Accept: "application/vnd.api+json",
            "Content-Type": "application/octet-stream",
            "X-CSRF-Token": session.token,
            "Content-Disposition":
              'file; filename="' +
              he.encode(savedCategory.data.attributes.name) +
              '-image.png"',
          }),
          body: dataUrl ? dataUrl.toBuffer() : undefined,
        }
      )

      imageData = await imageResponse.json()
    }

    // If there's no image, reconstruct a JSONAPI-looking
    // image structure with the path we already have
    else {
      imageData = {
        data: {
          attributes: {
            filename: newCategory.customImageFilename,
            uri: {
              url: newCategory.customImage.replace(
                process.env.REACT_APP_API_URL,
                ""
              ),
            },
          },
        },
      }
    }

    const fullCategory = categories.data.find(
      (cat: any) => cat.id === savedCategory.data.id
    )
    let isVisible = fullCategory ? fullCategory.isVisible : true

    return {
      category: savedCategory.data,
      image: imageData.data,
      isVisible,
      children: fullCategory ? fullCategory.children : [],
    }
  }
)

export const updateCategories = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("categories/updateCategories", async (args, thunkAPI) => {
  const { session, categories } = thunkAPI.getState()
  const orgId = session.group.uuid[0].value

  let excludedCategories: any[] = []

  categories.topCategories.forEach((category: any) => {
    if (!category.isVisible) {
      excludedCategories.push({
        type: "taxonomy_term--category",
        id: category.id,
      })
    }
  })

  categories.subCategories.forEach((subCategory: any) => {
    // Determine if this is a second level category since we'll be
    // going through its children (which are also in categories.subCategories)
    const isSecondLevel = categories.topCategories.find(
      (top: any) => top.id === subCategory.relationships.parent.data[0].id
    )

    if (isSecondLevel && !subCategory.isVisible) {
      excludedCategories.push({
        type: "taxonomy_term--category",
        name: subCategory.attributes.name,
        id: subCategory.id,
      })
    }

    subCategory.children.forEach((childCategory: any) => {
      if (!childCategory.isVisible) {
        excludedCategories.push({
          type: "taxonomy_term--category",
          id: childCategory.id,
        })
      }
    })
  })

  let body = {
    data: {
      id: orgId,
      type: "group--organization",
      relationships: {
        field_categories_to_exclude: {
          data: excludedCategories,
        },
      },
    },
  }

  await fetchWrapper.patch(
    "/api/group/organization/" + orgId,
    session.token,
    JSON.stringify(body)
  )

  return true
})

type InitialState = {
  isOpen: boolean
  fetched: boolean
  isSaving: boolean
  data: CategoryTaxonomy[]
  topCategories: CategoryTaxonomy[]
  subCategories: CategoryTaxonomy[]
}

const initialState: InitialState = {
  isOpen: false,
  fetched: false,
  isSaving: false,
  data: [],
  topCategories: [],
  subCategories: [],
}

export const categories = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategoryModalOpen: (state, action) => {
      state.isOpen = action.payload
    },
    setIsVisible: (state, action) => {
      const category = action.payload.category
      const isVisible = action.payload.value

      let targetCategory = state.data.find((cat: any) => cat.id === category.id)
      if (targetCategory) {
        targetCategory.isVisible = isVisible
        if (targetCategory.children) {
          targetCategory.children.forEach((child: any) => {
            child.isVisible = isVisible
          })
        }
      }

      if (category.relationships.parent.data[0].id !== "virtual") {
        targetCategory = state.subCategories.find(
          (cat) => cat.id === category.id
        )
        if (targetCategory) {
          if (targetCategory.isThirdLevel) {
            let parent = state.subCategories.find(
              (cat) => cat.id === category.relationships.parent.data[0].id
            )
            targetCategory = parent?.children?.find(
              (cat: any) => cat.id === category.id
            )
          } else {
            targetCategory.children?.forEach((child: any) => {
              child.isVisible = isVisible
            })
          }
        }
      } else {
        targetCategory = state.topCategories.find(
          (cat) => cat.id === category.id
        )
        let children = state.subCategories.filter(
          (cat) => cat.relationships.parent.data[0].id === category.id
        )

        children.forEach((child: any) => {
          child.isVisible = isVisible
          child.children.forEach((third: any) => {
            third.isVisible = isVisible
          })
        })
      }

      if (targetCategory) {
        targetCategory.isVisible = isVisible
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, action: any) => {
      state.fetched = true
      let categories = action.payload
      state.data = categories

      state.topCategories = categories.filter(
        (category: any) =>
          category.relationships.parent.data[0].id === "virtual"
      )
      state.topCategories = _.orderBy(
        state.topCategories,
        ["attributes.name"],
        ["asc"]
      )

      state.topCategories.forEach((category: any) => {
        category.hasChildren = categories.find(
          (sub: any) => category.id === sub.relationships.parent.data[0].id
        )
          ? true
          : false
        category.isTopLevel = true
      })

      // Categories with a parent
      const subCategories = categories.filter(
        (category: any) =>
          category.relationships.parent.data[0].id !== "virtual"
      )

      subCategories.forEach((category: any) => {
        category.isSecondLevel = true

        category.children = subCategories.filter((sub: any) => {
          return sub.relationships.parent.data[0].id === category.id
        })

        category.children.forEach((child: any) => {
          child.isThirdLevel = true
        })

        category.children = _.orderBy(
          category.children,
          ["attributes.name"],
          ["asc"]
        )
      })
      state.subCategories = subCategories
    })

    builder.addCase(saveCategory.fulfilled, (state, action) => {
      const { category, image, isVisible, children } = action.payload

      category.isVisible = isVisible

      if (image) {
        category.image = image
      }

      if (category.relationships.parent.data[0].id === "virtual") {
        category.isTopLevel = true
        state.topCategories = state.topCategories.filter(
          (cat: any) => cat.id !== category.id
        )
        state.topCategories.push(category)
        state.topCategories = _.orderBy(
          state.topCategories,
          ["attributes.name"],
          ["asc"]
        )
      }

      if (category.relationships.parent.data[0].id !== "virtual") {
        let isSecondLevel = state.topCategories.find(
          (cat: any) => cat.id === category.relationships.parent.data[0].id
        )
        state.topCategories = state.topCategories.filter(
          (cat: any) => cat.id !== category.id
        )

        if (isSecondLevel) {
          category.isSecondLevel = true
          category.isTopLevel = false
          category.children = children
          state.subCategories = state.subCategories.filter(
            (cat: any) => cat.id !== category.id
          )
          state.subCategories.push(category)
          state.subCategories = _.orderBy(
            state.subCategories,
            ["attributes.name"],
            ["asc"]
          )
        } else {
          let parent = state.subCategories.find(
            (cat: any) => cat.id === category.relationships.parent.data[0].id
          )
          if (parent) {
            category.isThirdLevel = true
            parent.children = parent.children?.filter(
              (cat: any) => cat.id !== category.id
            )

            parent.children?.push(category)
            parent.children = _.orderBy(
              parent.children,
              ["attributes.name"],
              ["asc"]
            )
          }
        }
      }
    })
    builder.addCase(updateCategories.pending, (state, action) => {
      state.isSaving = true
    })
    builder.addCase(updateCategories.fulfilled, (state, action) => {
      state.isSaving = false
    })
  },
})

export const { setIsVisible, setCategoryModalOpen } = categories.actions

export default categories.reducer
