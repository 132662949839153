import { SkillLevel, SubmissionType } from "@mobilemind/common/src/types/course"

export type YouTubeVideo = {
  id: string
  title: string
  description: string
  thumbnail?: string | null
  publishedAt?: string | null
  channelTitle?: string | null
  duration: string
}

export type YouTubeSearchItem = Omit<YouTubeVideo, "duration">

export type YouTubeSearchResponse = {
  items: YouTubeSearchItem[]
  prevPageToken?: string | null
  nextPageToken?: string | null
}

export type GenieCourseData = {
  "Course Name"?: string
  Category?: string
  "Estimated Time"?: number
  Learn?: string
  Tags?: string | string[]
}

export type GenieCategory = string

export type GenieAdditionalResource = {
  Title: string
  URL: string
}

export type GenieCourseQuestion = {
  Question?: string
  Options?: string[]
  "Correct Answer"?: string
}

export type GenerateCourseParameters = {
  userId: string
  videoId: string
  level: SkillLevel
  transcript: string | null
}

export type UpdateCourseParameters = {
  userId?: string
  course: GenieCourseData
  level: SkillLevel
  videoId: string
  transcript?: string | null
}

export type GenerateCategoryParameters = {
  categories: string[]
}

export type GenerateApplyParameters = {
  submissionType: SubmissionType
}

export type GenerateLearnerInstructionsParameters = {
  submissionType: SubmissionType
}

export type GenerateQuestionParameters = {
  submissionType: SubmissionType
}

export enum GenieRewriteAllowedField {
  Learn = "Learn",
  Apply = "Apply",
  LearnerInstructions = "Learner Instructions",
  Question = "Question",
  ReviewerInstructions = "Reviewer Instructions",
}

export type GenieRewriteFieldParameters = {
  field: GenieRewriteAllowedField
  content: string
  action: "rewrite" | "longer" | "shorter"
}

export type GenieCourseFeedbackParameters = {
  rating: number
  comment: string
}

export type GenieCourseFinishParameters = {
  courseId: string
}
