import { FastField, useFormikContext } from "formik"
import { has } from "lodash"
import { ComponentProps } from "react"

/**
 * Utility component to automatically set the `error` property on a component
 * (like `FormControl` or `TextField`) that is used with Formik's `FastField`
 * based on our heuristics (the field is in the error state if the form
 * has been submitted and the field has an error).
 */
export const FastFieldWithError = (props: ComponentProps<typeof FastField>) => {
  const { errors, submitCount } = useFormikContext()
  return (
    <FastField {...props} error={submitCount > 0 && has(errors, props.name)} />
  )
}
