import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"

export function saveQuickLinks(quickLinks) {
  return async (dispatch, getState) => {
    const { session } = getState()
    let response
    const orgId =
      session.group && session.group.uuid && session.group.uuid[0].value

    const body = {
      data: {
        type: "node--quick_links",
        attributes: {
          title:
            session.group &&
            session.group.label &&
            session.group.label[0].value + " Quick Links",
          body: {
            format: "basic_html",
            value: quickLinks.body.replaceAll("<p><br></p>", ""),
          },
          field_draft: !quickLinks.isHidden,
        },
        relationships: {
          field_organization: {
            data: {
              type: "group--organization",
              id: orgId,
            },
          },
        },
      },
    }

    try {
      if (quickLinks.id) {
        body.data.id = quickLinks.id
        response = await fetchWrapper.patch(
          "/api/node/quick_links/" + quickLinks.id,
          session.token,
          JSON.stringify(body)
        )
      } else {
        response = await fetchWrapper.post(
          "/api/node/quick_links",
          session.token,
          JSON.stringify(body)
        )
      }

      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function hideQuickLinks(quickLinks) {
  return async (dispatch, getState) => {
    const { session } = getState()
    let response

    const body = {
      data: {
        attributes: {
          body: {
            value: quickLinks.body,
          },
        },
      },
    }

    try {
      if (quickLinks.id) {
        response = await fetchWrapper.patch(
          "/api/node/quick_links/" + quickLinks.id,
          session.token,
          JSON.stringify(body)
        )
      } else {
        response = await fetchWrapper.post(
          "/api/node/quick_links",
          session.token,
          JSON.stringify(body)
        )
      }

      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}
