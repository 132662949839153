import { Dialog, Grid } from "@mui/material"
import pluralize from "pluralize"
import classNames from "classnames"
import ProgressBar from "./ProgressBar"
import moment from "moment"

import "../styles/modal.scss"
import "../styles/certificate.scss"
import "../styles/badgeItem.scss"

import logoImg from "../img/logo.png"
import badgeCertificateBgImg from "../img/badge-certificate-bg.png"

function ModalBadgePreview(props) {
  const { onClose, open, categories, badge, type, user } = props

  let imageSrc
  if (badge.image) {
    if (badge.image.attributes) {
      imageSrc = process.env.REACT_APP_API_URL + badge.image.attributes.uri.url
    } else {
      imageSrc = badge.image
    }
  }

  if (type === "certificate") {
    return (
      <Dialog
        className="modal badge-preview badge-certificate-preview"
        onClose={() => onClose()}
        open={open}
      >
        <p>
          Here's what this badge's certificate will look like. Certificates are
          awarded to users who earn this badge.
        </p>

        <div className="certificateBadge">
          <img
            className="bg-badge"
            src={badgeCertificateBgImg}
            alt="You earned a badge!"
          />
          <h2>
            <span style={{ textTransform: "capitalize" }}>
              {user.attributes.field_first_name.toLowerCase()}{" "}
              {user.attributes.field_last_name.toLowerCase()}
            </span>
            <strong>Earned a Badge!</strong>
          </h2>
          <div className="badgeImageWrapper">
            <div
              className="badgeImage"
              style={{ backgroundImage: "url(" + imageSrc + ")" }}
            />
          </div>
          <footer>
            <span className="title">{badge.name}</span>
            <span className="timestamp">
              {moment().format("MMMM DD, YYYY")}
            </span>
            <img className="logo" src={logoImg} alt="MobileMind" />
          </footer>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog
      className="modal badge-preview"
      onClose={() => onClose()}
      open={open}
    >
      <p>
        Here's what this badge will look like in the <strong>Learn</strong> app:
      </p>
      <Grid item xs={3} className="badgeWrapper">
        <div className="badgeItem active">
          <div className="innerTop">
            {imageSrc && <img src={imageSrc} alt={badge.name} />}
            <div className="details">
              <h2>{badge.name}</h2>
              <div className="badgeProgress">
                <p className="statusText">
                  0/{badge.courses.length} Courses Completed
                </p>
                <ProgressBar width={0} />
              </div>
            </div>
          </div>

          <div className="innerBottom">
            <header>
              {pluralize("Course", badge.courses.length)} Required
            </header>
            <ul>
              {categories.fetched &&
                badge.courses.map((course) => {
                  let category = categories.data.find(
                    (cat) =>
                      course.relationships.field_category.data &&
                      cat.id === course.relationships.field_category.data.id
                  )
                  let categoryLabel =
                    category && category.attributes.name.toLowerCase()
                  return (
                    <li key={course.id}>
                      <div className="category-icon-wrapper">
                        <span
                          className={classNames("category-icon", categoryLabel)}
                        ></span>
                      </div>
                      <div className="title">{course.attributes.name}</div>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      </Grid>
    </Dialog>
  )
}

export default ModalBadgePreview
