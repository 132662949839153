import { Component } from "react"
import PropTypes from "prop-types"

class ProgressBar extends Component {
  render() {
    const { width, trackWidth } = this.props

    return (
      <div className="progressBarWrapper">
        {trackWidth > 0 && (
          <div className="track" style={{ width: trackWidth + "%" }} />
        )}
        <div className="progressBar" style={{ width: width + "%" }}>
          <div className="progressBarInner"></div>
        </div>
      </div>
    )
  }
}

ProgressBar.propTypes = {
  width: PropTypes.number,
}

export default ProgressBar
