import SidebarSectioned from "@mobilemind/common/src/components/SidebarSectioned"
import "./dashboard.scss"
import { CollectionOrgList } from "./CollectionOrgList"

const CollectionDashboardLayout = (props: any) => {
  const sidebarSections = [
    {
      title: "Quick Actions",
      id: "quickActions",
      children: [
        {
          iconClass: "puzzle-piece quick-action-icon",
          title: "ReadyMade Marketplace",
          onClick: () => {
            props.history.push("marketplace")
          },
        },
        {
          iconClass: "create announcement quick-action-icon",
          title: "Create Announcement",
          onClick: () => {
            props.history.push("/create/announcements/new")
          },
        },
      ],
    },
  ]

  return (
    <div
      style={{ marginTop: 75 }}
      className={"hideLearnerSearch page dashboard-layout"}
    >
      <SidebarSectioned activeItem={"none"} sections={sidebarSections} />
      <CollectionOrgList {...props} />
    </div>
  )
}

export default CollectionDashboardLayout
