import {
  createOrgRubric,
  updateOrgRubric,
} from "@mobilemind/common/src/api/rubricApi"
import Loading from "@mobilemind/common/src/components/Loading"
import {
  OrgRubric,
  OrgRubricCriteria,
  OrgRubricCriteriaLevel,
  OrgRubricDomain,
} from "@mobilemind/common/src/types/rubrics"
import yup from "@mobilemind/common/src/utility/yup"
import { Box, Button, Card, Stack, TextField } from "@mui/material"
import { FastFieldWithError } from "components/FastFieldWithError"
import { FieldArray, Form, Formik } from "formik"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "store/hooks"
import plusImg from "../../img/plus.svg"
import { RubricCriteriaContainer } from "./components/RubricCriteriaContainer"
import { RubricDomainHeader } from "./components/RubricDomainHeader"
import { RubricNavbar } from "./components/RubricNavbar"
import { openSnackbar } from "features/snackbar/snackbarSlice"

type EditRubricFormProps = {
  initialValues: OrgRubric
}

export const criteriaLevelSchema: yup.Schema<OrgRubricCriteriaLevel> =
  yup.object({
    points: yup.mixed<string | number>().label("Points").required().default(0),
    name: yup.string().label("Level name").required(),
    description: yup.string().label("Description").defined().default(""),
  })

export const criteriaSchema: yup.Schema<OrgRubricCriteria> = yup.object({
  uuid: yup.string().uuid(),
  name: yup.string().label("Criteria name").required().default(""),
  weight: yup.mixed<string | number>().label("Weight").default(1).required(),
  description: yup.string().label("Description").defined().default(""),
  criteria_levels: yup
    .array(criteriaLevelSchema.required())
    .min(1)
    .default(() => [
      criteriaLevelSchema.getDefault(),
      criteriaLevelSchema.getDefault(),
      criteriaLevelSchema.getDefault(),
      criteriaLevelSchema.getDefault(),
    ]),
})

const domainSchema: yup.Schema<OrgRubricDomain> = yup.object({
  name: yup.string().label("Domain name").required().default(""),
  description: yup.string().label("Description").defined().default(""),
  criteria: yup
    .array(criteriaSchema.required())
    .required()
    .min(1)
    .default(() => [criteriaSchema.getDefault()]),
})

export const rubricSchema: yup.Schema<OrgRubric> = yup.object({
  id: yup.string(),
  uuid: yup.string().uuid(),
  organization: yup.string().required(),
  name: yup.string().label("Rubric name").required().default(""),
  archive: yup.mixed<"0" | "1">().label("Archive").default("0"),
  draft: yup.mixed<"0" | "1">().label("Draft").default("1"),
  domain: yup
    .array(domainSchema)
    .required()
    .min(1)
    .default(() => [domainSchema.getDefault()]),
})

export function EditRubricForm({ initialValues }: EditRubricFormProps) {
  const history = useHistory()
  const dispatch = useAppDispatch()
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          const nextValues: OrgRubric = {
            ...values,
            draft: "0",
            archive: "0",
          }
          if (values.uuid) {
            await dispatch(updateOrgRubric.initiate(nextValues)).unwrap()
          } else {
            await dispatch(createOrgRubric.initiate(nextValues)).unwrap()
          }
          history.push("/create/rubrics")
        } catch (error) {
          console.error(error)
          dispatch(
            openSnackbar({ message: "There was an problem saving the rubric." })
          )
        }
      }}
      validationSchema={rubricSchema}
      validateOnMount={true}
    >
      {({ errors, isSubmitting, values }) => (
        <Form>
          {console.debug(errors)}
          {isSubmitting && <Loading fullPage={true} message="Saving rubric…" />}
          <RubricNavbar />
          <Stack spacing={5}>
            <FastFieldWithError
              size="medium"
              as={TextField}
              name="name"
              variant="standard"
              multiline
              sx={{
                marginLeft: 4,
              }}
              label="Rubric Name"
              margin="normal"
            />
            <FieldArray
              name="domain"
              render={(arrayHelpers) => (
                <>
                  {values.domain.map((domain, domainIndex) => {
                    return (
                      <Card key={`domain.${domainIndex}`}>
                        <RubricDomainHeader
                          name={`domain.${domainIndex}`}
                          index={domainIndex}
                          onRemove={
                            values.domain.length > 1
                              ? () => arrayHelpers.remove(domainIndex)
                              : undefined
                          }
                        />
                        <Box sx={{ padding: 4 }}>
                          <FastFieldWithError
                            as={TextField}
                            name={`domain.${domainIndex}.description`}
                            variant="standard"
                            multiline
                            label="Description"
                          />
                        </Box>
                        <RubricCriteriaContainer
                          name={`domain.${domainIndex}.criteria`}
                          index={domainIndex}
                        />
                      </Card>
                    )
                  })}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      size="small"
                      startIcon={<img src={plusImg} alt="Add Domain" />}
                      onClick={() => {
                        arrayHelpers.push(domainSchema.getDefault())
                      }}
                    >
                      Add Domain
                    </Button>
                  </Box>
                </>
              )}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
