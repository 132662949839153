import { useState, useEffect } from "react"
import { useAppDispatch } from "store/hooks"
import classNames from "classnames"
import Dropzone from "react-dropzone"
import { Button } from "@mui/material"

function ImageUpload(props) {
  const dispatch = useAppDispatch()

  function onDrop(file) {
    let uploaded = file[0] ? file[0] : file.target.files[0]
    setIsDragging(false)
    if (uploaded) {
      // Limit to 10MB
      if (uploaded.size <= 10000000) {
        setFileSizeExceeded(0)
        // If it's an image
        if (uploaded.type.indexOf("image") > -1) {
          // Read the file
          let reader = new FileReader()
          reader.readAsDataURL(uploaded)

          // Once we're done, get the file and name it
          reader.onloadend = async (event) => {
            if (!props.clearOnUpload) {
              setImagePreview(event.target.result)
            }
            if (props.category) {
              dispatch(
                props.setImage({
                  category: props.category,
                  image: event.target.result,
                })
              )
            } else {
              dispatch(props.setImage(event.target.result))
              if (props.clearOnUpload) {
                setImagePreview(null)
              }
            }

            let filename = uploaded.name.replaceAll(" ", "-")
            if (props.setFilename) {
              dispatch(props.setFilename(filename))
            }
          }
        }
      } else {
        setFileSizeExceeded(1)
        setIsRejected(1)
        setTimeout(() => {
          setIsRejected(0)
        }, 2000)
      }
    }
  }

  const [isDragging, setIsDragging] = useState(0)
  const [imagePreview, setImagePreview] = useState(0)
  const [fileSizeExceeded, setFileSizeExceeded] = useState(0)
  const [isRejected, setIsRejected] = useState(0)

  useEffect(() => {
    const { activeImage, setImage, useFormik } = props

    if (!useFormik) {
      if (activeImage && !imagePreview) {
        dispatch(setImage(activeImage))
      }
    }
    // eslint-disable-next-line
  }, [imagePreview, dispatch])

  function remove() {
    const removeImage = props.removeImage
    dispatch(removeImage())
    setImagePreview(0)
    setFileSizeExceeded(0)
  }

  let showHelperText = (!props.activeImage && !imagePreview) || props.disabled

  const loadedImageSrc =
    props.activeImage && props.activeImage.attributes
      ? process.env.REACT_APP_API_URL + props.activeImage.attributes.uri.url
      : props.activeImage

  return (
    <div
      className={classNames("uploadImageWrapper", props.disabled && "disabled")}
    >
      <Dropzone
        accept="image/jpeg, image/png, image/jpg"
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDrop={onDrop.bind(this)}
      >
        {({ getRootProps }) => (
          <div
            {...getRootProps()}
            className={classNames(
              "fileUpload",
              { isDragging: isDragging },
              { isRejected: isRejected }
            )}
          >
            <input onChange={onDrop.bind(this)} type="file" />
            {props.activeImage && !imagePreview && (
              <div
                style={{
                  backgroundImage: "url(" + loadedImageSrc + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className={classNames("uploadedImage", props.shape, props.type)}
              ></div>
            )}
            {imagePreview !== 0 && (
              <div
                style={{
                  backgroundImage: "url(" + imagePreview + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className={classNames("uploadedImage", props.shape, props.type)}
              />
            )}
            {!imagePreview && !props.activeImage && (
              <>
                <div
                  className={classNames("border", props.shape, props.type)}
                />
                <div className="tooltip">
                  Click to Upload
                  <br />
                  or Drag and Drop
                  <span>
                    10MB limit
                    <br />
                    png jpg jpeg
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </Dropzone>
      {!showHelperText && (
        <div className="tooltip">
          <Button
            onClick={() => {
              remove()
            }}
            className="button small"
          >
            Remove Image
          </Button>
        </div>
      )}
      {fileSizeExceeded > 0 && (
        <strong>
          Uh oh! This file is too large. Try again with an image smaller than
          10MB.{" "}
        </strong>
      )}

      {showHelperText && props.helperText && (
        <p className="helperText">{props.helperText}</p>
      )}
    </div>
  )
}

export default ImageUpload
