import { createSlice } from "@reduxjs/toolkit"

type InitialState = {
  activeItem: string | null
}

const initialState: InitialState = {
  activeItem: null,
}

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.activeItem = action.payload
    },
  },
})

export const { changeTab } = navbarSlice.actions

export default navbarSlice.reducer
