function importAll(r) {
  let images = {}
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item)
  })
  return images
}

const allImages = Object.values(
  importAll(require.context("../../img/studio/education", false, /\.(svg)$/))
)
  .concat(
    Object.values(
      importAll(require.context("../../img/studio/office", false, /\.(svg)$/))
    )
  )
  .concat(
    Object.values(
      importAll(
        require.context(
          "../../img/studio/mentoring-training",
          false,
          /\.(svg)$/
        )
      )
    )
  )
  .concat(
    Object.values(
      importAll(require.context("../../img/studio/teamwork", false, /\.(svg)$/))
    )
  )
  .concat(
    Object.values(
      importAll(require.context("../../img/studio/animals", false, /\.(svg)$/))
    )
  )
  .concat(
    Object.values(
      importAll(require.context("../../img/studio/food", false, /\.(svg)$/))
    )
  )
  .concat(
    Object.values(
      importAll(require.context("../../img/studio/nature", false, /\.(svg)$/))
    )
  )
  .concat(
    Object.values(
      importAll(
        require.context("../../img/studio/rewards-badges", false, /\.(svg)$/)
      )
    )
  )
  .concat(
    Object.values(
      importAll(require.context("../../img/studio/holidays", false, /\.(svg)$/))
    )
  )
  .concat(
    Object.values(
      importAll(require.context("../../img/studio/numbers", false, /\.(svg)$/))
    )
  )
  .concat(
    Object.values(
      importAll(require.context("../../img/studio/letters", false, /\.(svg)$/))
    )
  )

export function getIconsForPack({ currentPack }) {
  if (currentPack === "all") {
    return allImages
  }
  if (currentPack === "education") {
    return Object.values(
      importAll(
        require.context("../../img/studio/education", false, /\.(svg)$/)
      )
    )
  }
  if (currentPack === "animals") {
    return Object.values(
      importAll(require.context("../../img/studio/animals", false, /\.(svg)$/))
    )
  }
  if (currentPack === "food") {
    return Object.values(
      importAll(require.context("../../img/studio/food", false, /\.(svg)$/))
    )
  }
  if (currentPack === "office") {
    return Object.values(
      importAll(require.context("../../img/studio/office", false, /\.(svg)$/))
    )
  }
  if (currentPack === "mentoring-training") {
    return Object.values(
      importAll(
        require.context(
          "../../img/studio/mentoring-training",
          false,
          /\.(svg)$/
        )
      )
    )
  }
  if (currentPack === "nature") {
    return Object.values(
      importAll(require.context("../../img/studio/nature", false, /\.(svg)$/))
    )
  }
  if (currentPack === "teamwork") {
    return Object.values(
      importAll(require.context("../../img/studio/teamwork", false, /\.(svg)$/))
    )
  }
  if (currentPack === "holidays") {
    return Object.values(
      importAll(require.context("../../img/studio/holidays", false, /\.(svg)$/))
    )
  }
  if (currentPack === "rewards-badges") {
    return Object.values(
      importAll(
        require.context("../../img/studio/rewards-badges", false, /\.(svg)$/)
      )
    )
  }
  if (currentPack === "numbers") {
    return Object.values(
      importAll(require.context("../../img/studio/numbers", false, /\.(svg)$/))
    )
  }
  if (currentPack === "letters") {
    return Object.values(
      importAll(require.context("../../img/studio/letters", false, /\.(svg)$/))
    )
  }
}

export { allImages }
