import { Component } from "react"
import { connect } from "react-redux"
import QuickLinksNavbarContainer from "./QuickLinksNavbarContainer"
import { Card, Switch } from "@mui/material"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { fetchQuickLinks, setBody, setVisibility } from "./quickLinksSlice"

import ReactQuill from "react-quill"

import "react-quill/dist/quill.snow.css"
import ErrorBoundary from "../../components/ErrorBoundary"
import { useAppDispatch } from "store/hooks"
import { formats, modules } from "../../constants/reactQuillConfig"

const mapStateToProps = ({ session, quickLinks }) => {
  return {
    session,
    quickLinks,
  }
}

const mapDispatchToProps = {
  fetchQuickLinks,
}

function QuickLinksForm(props) {
  const dispatch = useAppDispatch()
  const { body, isHidden } = props.quickLinks

  function handleChange(newValue) {
    dispatch(setBody(newValue))
  }

  const handleSwitch = () => {
    dispatch(setVisibility(!isHidden))
  }

  return (
    <div className="flexRow">
      <div className="column left">
        <h2>Widget Preview </h2>
        <p>
          This widget is pinned to the learner's <strong>Dashboard</strong>. Use
          the form on the right to include any helpful links for your
          organization.
        </p>

        <Card className={"widget announcement"} style={{ animationDelay: 0 }}>
          <div className={"widgetIcon icon quick-links"} />
          <div className={"widgetLabel"}>Quick Links</div>
          <SanitizedHTML html={body.replaceAll("<p><br></p>", "")} />
        </Card>
      </div>

      <div className="column right">
        <div onClick={handleSwitch} className="flexRow switch">
          <Switch
            checked={isHidden}
            color="secondary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          Show in Learn App
        </div>

        <ReactQuill
          theme={"snow"}
          onChange={handleChange}
          value={props.quickLinks.body}
          modules={modules}
          formats={formats}
          placeholder={"Write something..."}
        />
      </div>
    </div>
  )
}

class QuickLinksLayout extends Component {
  componentDidMount = () => {
    this.props.fetchQuickLinks()
  }

  render() {
    const props = this.props
    return (
      <ErrorBoundary>
        <div className="page create quickLinks">
          <QuickLinksNavbarContainer {...props} />
          <QuickLinksForm quickLinks={this.props.quickLinks} />
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickLinksLayout)
