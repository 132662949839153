import Loading from "@mobilemind/common/src/components/Loading"
import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import {
  convertMinutesToHoursMinutes,
  generateTimestamp,
} from "@mobilemind/common/src/functions"
import theme from "@mobilemind/common/src/theme/theme"
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import classNames from "classnames"
import he from "he"
import moment from "moment"
import pluralize from "pluralize"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { publishCourseFromList } from "../../actions/courses"
import manageImg from "../../img/manage.svg"
import plusImg from "../../img/plus.svg"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { setCategoryModalOpen } from "../../store/reducers/categories"
import { openSnackbar } from "../snackbar/snackbarSlice"
import { CreateCourseMenu } from "./CreateCourseMenu"
import {
  handleCourseCheck,
  selectEntityList,
  setCurrentTab,
} from "./entityListSlice"

function EntityList(props) {
  const dispatch = useAppDispatch()

  const {
    currentTab,
    session,
    entityType,
    total,
    iconClass,
    createPath,
    fetched,
    isSiteAdmin,
  } = props
  const selectedEntityList = useAppSelector(selectEntityList)

  const [isPublishing, setIsPublishing] = useState(false)
  const [hasRedirected, setRedirect] = useState(false)

  const orgRoles = session.orgRoles
  const isOrgLevel =
    orgRoles.includes("organization-admin") ||
    orgRoles.includes("organization-creator") ||
    orgRoles.includes("organization-drafter")

  const isDrafter =
    orgRoles.includes("organization-drafter") ||
    session.groupRoles.includes("group-drafter")

  const selectedItems = selectedEntityList.filter((item) => item.isChecked)

  function handleSelect(entity, event) {
    dispatch(
      handleCourseCheck({ uuid: entity.uuid, isChecked: event.target.checked })
    )
  }

  function publishSelected() {
    let promises = []
    setIsPublishing(true)

    selectedItems.forEach((item) => {
      promises.push(dispatch(publishCourseFromList(item)))
    })

    Promise.all(promises).then(() => {
      setIsPublishing(false)
      dispatch(
        openSnackbar({
          message:
            "Successfully published " +
            promises.length +
            " " +
            pluralize("course", promises.length) +
            ".",
        })
      )
    })
  }

  // Redirect with link straight to community
  useEffect(() => {
    if (!hasRedirected) {
      setRedirect(true)
      if (window.location.search === "?community") {
        dispatch(setCurrentTab({ page: "orgCourses", tab: "Templates" }))
      }
    }
  }, [hasRedirected, dispatch])

  let pageLabel =
    currentTab === "Templates" ? "Community Courses" : pluralize(currentTab, 2)
  if (currentTab === "Mandated Training") {
    pageLabel = "Mandated Training"
  }

  return (
    <div className="page hasSidebar entityList">
      <h2 style={{ position: "relative" }}>
        <span className={iconClass} />
        <span className="title">
          {pageLabel}

          {(currentTab === "Badges" || currentTab === "Learning Paths") && (
            <span className="learnMore">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.google.com/document/d/1iO_2wR6GepySWbn1x6eKjS_q9s3oY2AR-avKNbrlcRA/edit"
              >
                <span className="icon info" />
                Learn More
              </a>
            </span>
          )}
        </span>
        {currentTab !== "Templates" && (
          <>
            {entityType === "Course" &&
              (session.isOrgAdmin || session.isPartner) && (
                <Button
                  style={{
                    marginRight: 15,
                    opacity: selectedItems.length ? 0 : 1,
                    marginTop: selectedItems.length ? -150 : 0,
                    transition: ".3s",
                  }}
                  onClick={() => {
                    dispatch(setCategoryModalOpen(true))
                  }}
                  className="button small"
                >
                  <img alt={"Manage Categories"} src={manageImg} /> Manage
                  Categories
                </Button>
              )}
            {currentTab !== "Courses" ? (
              <Button className="button small">
                <Link to={createPath}>
                  <img alt={"Create " + entityType} src={plusImg} /> Create{" "}
                  {entityType}
                </Link>
              </Button>
            ) : (
              <>
                <CreateCourseMenu hidden={selectedItems.length > 0} />
                {!isPublishing ? (
                  <Button
                    style={{
                      opacity: !selectedItems.length ? 0 : 1,
                      marginTop: !selectedItems.length ? -150 : 0,
                      transition: ".3s",
                      position: "absolute",
                      right: 0,
                    }}
                    onClick={publishSelected}
                    className="button small"
                  >
                    <span className="icon rocket blue" />
                    Publish Selected Courses
                  </Button>
                ) : (
                  <Loading
                    message={
                      "Publishing " +
                      selectedItems.length +
                      " " +
                      pluralize("course", selectedItems.length) +
                      "..."
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </h2>

      {currentTab === "Templates" && (
        <p style={{ fontSize: 14 }}>
          <em>
            Please review each micro-course before choosing to publish it to the
            learners in your organization. MobileMind provides the platform to
            crowdsource community micro-courses, but does not review the videos
            or content contributed. While you are welcome to leverage any
            courses in the community, each state differs in their compliance
            requirements, and MobileMind can not verify that content meets your
            district or state requirements.
            <br />
            <br />
            Additionally, please note that someone in your organization will be
            responsible for reviewing non-automated micro-courses that are
            copied and published from the MobileMind community (unless you
            change the submission type to an automated option).
          </em>
        </p>
      )}

      {fetched ? (
        <TableContainer>
          {selectedEntityList && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="flexRow entityCount">
                      <div
                        style={{ cursor: "pointer" }}
                        className="flexRow"
                        onClick={() => {
                          props.setSortOrder()
                        }}
                      >
                        <span
                          className={classNames(
                            "icon sort",
                            props.filters.sortOrder === "ASC" && "flipped"
                          )}
                        />
                      </div>
                      <span className="count">{total ? total : 0}</span>{" "}
                      {pluralize(
                        currentTab !== "Templates" ? currentTab : "Courses",
                        selectedEntityList.length
                      )}
                    </div>
                  </TableCell>
                  {currentTab === "Goals" && (
                    <TableCell>Learning Path</TableCell>
                  )}
                  {(currentTab === "Courses" ||
                    currentTab === "Announcements" ||
                    currentTab === "Badges" ||
                    currentTab === "Learning Paths") &&
                    isSiteAdmin && <TableCell>Organization</TableCell>}
                  {currentTab === "Templates" && (
                    <TableCell>Estimated Time</TableCell>
                  )}
                  {currentTab === "Courses" && (
                    <>
                      {isOrgLevel && <TableCell>Groups</TableCell>}
                      <TableCell>Updated By</TableCell>
                    </>
                  )}

                  {(currentTab === "Badges" ||
                    currentTab === "Learning Paths" ||
                    currentTab === "Goals" ||
                    currentTab === "Mandated Training" ||
                    currentTab === "Announcements") && (
                    <>{isOrgLevel && <TableCell>Groups</TableCell>}</>
                  )}

                  {currentTab !== "Templates" && (
                    <TableCell>Last Updated</TableCell>
                  )}

                  {entityType === "Announcement" && (
                    <TableCell>Expires</TableCell>
                  )}
                  {currentTab === "Goals" && <TableCell>Deadline</TableCell>}
                  {currentTab !== "Templates" && <TableCell>Status</TableCell>}

                  {currentTab === "Templates" && (
                    <>
                      <TableCell>Contributor State</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Orgs Using
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          width: 160,
                        }}
                      >
                        Challenges Completed
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedEntityList.length > 0 &&
                  selectedEntityList.map((entity) => {
                    let isArchived =
                      Number(entity.field_archive) === 1 ||
                      Number(entity.field_learning_path_archive) === 1
                    let isDraft =
                      entity.field_draft === "1" ||
                      entity.field_draft === true ||
                      entity.field_draft === "True"
                    let entityId, firstName, lastName

                    if (entity.attributes) {
                      firstName =
                        session && session.user.attributes.field_first_name
                      lastName =
                        session && session.user.attributes.field_last_name

                      if (entity.attributes.drupal_internal__nid) {
                        entityId = entity.attributes.drupal_internal__nid
                      } else {
                        entityId = entity.attributes.drupal_internal__id
                      }
                    } else {
                      firstName = entity.field_first_name
                      lastName = entity.field_last_name
                      entityId = entity.id
                    }

                    let orgText, group, groupText, groupList, numMore

                    if (entity.field_group) {
                      if (entity.field_group.includes(",")) {
                        let numMore = entity.field_group.replace(
                          /[^,]/g,
                          ""
                        ).length
                        orgText =
                          entity.field_group.split(",")[0] +
                          " +" +
                          numMore +
                          " More"
                      } else orgText = entity.field_group
                    }

                    if (entity.field_group) {
                      group = entity.field_group
                    } else if (entity.field_subgroup) {
                      group = entity.field_subgroup
                    }
                    if (group) {
                      if (group.includes(",")) {
                        let groupArray = group.split(",")
                        let groupLength = groupArray.length

                        if (groupLength > 3) {
                          groupList = groupArray.slice(0, 3).join(", ")
                          numMore = groupLength - 3
                          groupText = (
                            <>
                              {groupList}{" "}
                              <strong className="moreIndicator">
                                +{numMore} More
                              </strong>
                            </>
                          )
                        } else {
                          groupText = group
                        }
                      } else groupText = group
                    } else {
                      groupText = "All Groups"
                    }

                    let path =
                      "/create/" +
                      pluralize(entityType, 2).toLowerCase().replace(" ", "-") +
                      "/" +
                      entityId +
                      "/edit"

                    if (entityType === "Mandated Training") {
                      path = "/create/mandated-training/" + entityId + "/edit"
                    }

                    if (entity.cat_name) {
                      path =
                        "/create/" +
                        pluralize(entityType, 2)
                          .toLowerCase()
                          .replace(" ", "-") +
                        "/" +
                        entityId +
                        "/template"
                    }

                    let imageUrl, categoryIcon
                    if (entity.image && entity.image.length) {
                      imageUrl = entity.image.includes("data:image")
                        ? entity.image
                        : process.env.REACT_APP_API_URL + entity.image
                    }

                    if (entity.type === "course_entity--course_entity") {
                      if (entity.relationships.field_category) {
                        categoryIcon =
                          entity.relationships.field_category.attributes.name.toLowerCase()
                      }
                    }

                    let showCheckboxes = selectedEntityList.find(
                      (item) => item.isChecked
                    )

                    let selectable =
                      props.selectable &&
                      entity.field_draft === "1" &&
                      entity.field_ready_to_publish === "True" &&
                      !isDrafter

                    return (
                      <TableRow key={entity.id}>
                        <TableCell
                          style={{ display: "flex", alignItems: "center" }}
                          component="td"
                          scope="row"
                          className={classNames(
                            entity.cat_name && "template",
                            selectable && "selectable",
                            showCheckboxes && "hasSelection"
                          )}
                        >
                          {selectable && (
                            <Checkbox
                              className="entitySelect"
                              checked={entity.isChecked}
                              onChange={(event) => handleSelect(entity, event)}
                              name="shuffle"
                            />
                          )}

                          <Link to={path}>
                            <div className="flexRow">
                              {entityType === "Course" ? (
                                <>
                                  {currentTab === "Templates" &&
                                  entity.cat_name ? (
                                    <span
                                      className={
                                        "category-icon " +
                                        entity.cat_name.toLowerCase()
                                      }
                                    />
                                  ) : (
                                    <>
                                      {imageUrl ? (
                                        <img
                                          alt={he.decode(entity.name)}
                                          className="entityImage course"
                                          src={imageUrl}
                                        />
                                      ) : (
                                        <span
                                          className={
                                            "category-icon " + categoryIcon
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {entityType !== "Goal" && (
                                    <>
                                      {imageUrl ? (
                                        <div
                                          className={classNames(
                                            "entityImage",
                                            entityType.toLowerCase()
                                          )}
                                          style={{
                                            borderRadius: 6,
                                            backgroundImage:
                                              "url(" + imageUrl + ")",
                                          }}
                                        ></div>
                                      ) : (
                                        <span
                                          className={
                                            "icon " + entityType.toLowerCase()
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              <div className="flexRow name">
                                {he.decode(entity.name)}
                                {currentTab === "Templates" && (
                                  <div className="orgName">
                                    {entity.field_creator_org ? (
                                      <>
                                        {entity.field_credit_organization &&
                                        entity.field_credit_organization !==
                                          "0" ? (
                                          <>{entity.field_creator_org}</>
                                        ) : (
                                          <>Community Contributor</>
                                        )}
                                      </>
                                    ) : (
                                      <>MobileMind</>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Link>
                        </TableCell>
                        {(currentTab === "Courses" ||
                          currentTab === "Announcements" ||
                          currentTab === "Badges" ||
                          currentTab === "Learning Paths") &&
                          isSiteAdmin && (
                            <TableCell>
                              <Link to={path}>
                                {currentTab === "Badges" ||
                                currentTab === "Announcements" ? (
                                  <>
                                    {entity.field_group
                                      ? orgText
                                      : "MobileMind"}
                                  </>
                                ) : (
                                  <>
                                    {entity.field_organization
                                      ? entity.field_organization
                                      : "MobileMind"}
                                  </>
                                )}
                              </Link>
                            </TableCell>
                          )}

                        {currentTab === "Goals" && (
                          <TableCell>
                            <Link to={path}>{entity.field_learning_path}</Link>
                          </TableCell>
                        )}

                        {currentTab === "Templates" && (
                          <TableCell className="largeText">
                            <Link className="flexRow" to={path}>
                              {Number(entity.field_course_estimated_time) >
                                0 && (
                                <TimeCapsule
                                  hours={
                                    convertMinutesToHoursMinutes(
                                      Number(entity.field_course_estimated_time)
                                    ).hours
                                  }
                                  minutes={
                                    convertMinutesToHoursMinutes(
                                      Number(entity.field_course_estimated_time)
                                    ).minutes
                                  }
                                />
                              )}
                            </Link>
                          </TableCell>
                        )}
                        {(currentTab === "Badges" ||
                          currentTab === "Learning Paths" ||
                          currentTab === "Goals" ||
                          currentTab === "Mandated Training" ||
                          currentTab === "Announcements") && (
                          <>
                            {isOrgLevel && (
                              <TableCell style={{ maxWidth: 275 }}>
                                <Link to={path}>{groupText}</Link>
                              </TableCell>
                            )}
                          </>
                        )}
                        {currentTab === "Courses" && (
                          <>
                            {isOrgLevel && (
                              <TableCell style={{ maxWidth: 275 }}>
                                <Link to={path}>{groupText}</Link>
                              </TableCell>
                            )}
                            <TableCell>
                              <Link
                                style={{ textTransform: "capitalize" }}
                                to={path}
                              >
                                {firstName &&
                                  he.decode(firstName.toLowerCase())}{" "}
                                {lastName && he.decode(lastName.toLowerCase())}
                              </Link>
                            </TableCell>
                          </>
                        )}

                        {currentTab !== "Templates" && (
                          <TableCell>
                            <Link to={path}>
                              {moment(
                                entity.attributes
                                  ? entity.attributes.changed
                                  : entity.changed
                              ).format("M/D/YYYY")}
                            </Link>
                          </TableCell>
                        )}

                        {entityType === "Announcement" && (
                          <TableCell>
                            <Link to={path}>
                              {moment(entity.field_expiration_date).format(
                                "MM/DD/YYYY"
                              )}
                            </Link>
                          </TableCell>
                        )}
                        {currentTab === "Goals" && (
                          <TableCell>
                            <Link to={path}>
                              {moment(entity.field_goal_date).format(
                                "MM/DD/YYYY"
                              )}
                            </Link>
                          </TableCell>
                        )}

                        {currentTab === "Templates" && (
                          <TableCell>
                            <Link to={path}>{entity.org_state}</Link>
                          </TableCell>
                        )}

                        {currentTab === "Templates" && (
                          <>
                            <TableCell>
                              <Link to={path}>
                                {generateTimestamp(
                                  moment
                                    .unix(Number(entity.created))
                                    .format("MM/DD/YYYY"),
                                  "dateOnly"
                                )}
                              </Link>
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Link to={path}>
                                <strong
                                  style={{
                                    fontSize: 24,
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  {Number(entity.number_used)}
                                </strong>
                              </Link>
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Link to={path}>
                                <span className="icon check color"></span>
                                <strong
                                  style={{
                                    fontSize: 24,
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  {Number(
                                    entity.number_completed
                                  ).toLocaleString()}
                                </strong>
                              </Link>
                            </TableCell>
                          </>
                        )}

                        {currentTab !== "Templates" && (
                          <TableCell>
                            <Link to={path} style={{ position: "relative" }}>
                              {isArchived && !isDraft && <>Archived</>}
                              {!isArchived && !isDraft && !isDrafter && (
                                <>Published</>
                              )}
                              {(isDraft || isDrafter) && <>Draft</>}

                              {isDraft &&
                                !isDrafter &&
                                entity.field_ready_to_publish === "True" && (
                                  <span
                                    style={{
                                      fontSize: 10,
                                      position: "absolute",
                                      top: 40,
                                      fontWeight: "bold",
                                      display: "block",
                                      textTransform: "uppercase",
                                      color: theme.palette.primary.dark,
                                    }}
                                  >
                                    Ready to Publish
                                  </span>
                                )}
                            </Link>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      ) : (
        <Loading
          message={"Getting " + pluralize(currentTab, 2).toLowerCase() + "..."}
        />
      )}
    </div>
  )
}

export default EntityList
