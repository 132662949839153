import { useState, useEffect } from "react"

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { connect } from "react-redux"
import { getOrgRubrics } from "actions/rubrics"
import { updateField, fetchObservationTypes } from "./activeEventSlice"
import { useAppDispatch } from "store/hooks"
import Loading from "@mobilemind/common/src/components/Loading"
import he from "he"

const mapStateToProps = ({ session, entityList }, ownProps) => {
  return {
    rubrics: entityList.orgRubrics,
    session,
  }
}

function EventRubric(props) {
  const event = props.activeEvent
  const dispatch = useAppDispatch()

  const [rubricsFetched, setRubricsFetched] = useState(props.rubrics.fetched)
  const [observationTypesFetched, setObservationTypesFetched] = useState(false)

  useEffect(() => {
    if (!rubricsFetched) {
      setRubricsFetched(true)
      dispatch(getOrgRubrics())
    }
    if (!observationTypesFetched) {
      setObservationTypesFetched(true)
      dispatch(fetchObservationTypes())
    }
  }, [rubricsFetched, observationTypesFetched, dispatch])

  return (
    <div className="event-content event-rubric two-column">
      <div className="flexRow columns">
        <div className="column left">
          <h2>Observation Rubric</h2>

          <FormControl variant="standard" className="select">
            <InputLabel id="observationType-label">Observation Type</InputLabel>

            <Select
              variant="standard"
              id="event-observationType-select"
              value={event.observationType}
              onChange={(event) =>
                dispatch(
                  updateField({
                    field: "observationType",
                    value: event.target.value,
                  })
                )
              }
            >
              {event.observationTypes?.map((type) => {
                return (
                  <MenuItem value={type.id}>{type.attributes.name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>

          {!props.rubrics.fetched ? (
            <Loading message="Getting rubrics..." />
          ) : (
            <FormControl variant="standard" className="select">
              <InputLabel id="observationRubric-label">Rubric</InputLabel>
              <Select
                variant="standard"
                id="event-observationRubric-select"
                value={event.observationRubricUUID}
                onChange={(event) =>
                  dispatch(
                    updateField({
                      field: "observationRubricUUID",
                      value: event.target.value,
                    })
                  )
                }
              >
                {props.rubrics.data.map((rubric) => {
                  if (rubric.field_draft === "1") return null

                  return (
                    <MenuItem key={rubric.uuid} value={rubric.uuid}>
                      {he.decode(rubric.name)}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(EventRubric)
