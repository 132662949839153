import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import moment from "moment"
import { useState } from "react"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { useAppSelector } from "store/hooks"

import ReviewerQuote from "@mobilemind/common/src/components/ReviewerQuote"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import RatingStar from "@mobilemind/common/src/icons/RatingStar"
import { Button, TextField } from "@mui/material"
import classNames from "classnames"

import CategorySelect from "@mobilemind/common/src/components/CategorySelect"

import {
  addRequestTimeBlock,
  approveExternalRequest,
  declineExternalRequest,
  deleteRequestTimeBlock,
  reopenExternalRequest,
  setCurrentAttachment,
  updateRequestTimeBlock,
} from "../../../store/reducers/externalPD"

import he from "he"
import DeleteButton from "../../../components/DeleteButton"

import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import Plus from "@mobilemind/common/src/icons/Plus"
import ButtonSmall from "../../../components/ButtonSmall"
import { useAppDispatch } from "../../../store/hooks"

interface CardProps {
  animationDelay?: number
  event?: any
  activeItem?: string
  onClick?: (event: any) => void
  preview?: boolean
}

const RequestCard = ({
  animationDelay,
  event,
  activeItem,
  onClick,
  preview,
}: CardProps) => {
  const dispatch = useAppDispatch()

  const categories = useAppSelector((state) => state.categories)
  const session = useAppSelector((state) => state.session)
  const [confirmDecline, setConfirmDecline] = useState(false)

  const isDeclined = event.field_status_value === "declined"
  const isPending = event.field_status_value === "pending"
  const isAccepted = event.field_status_value === "accepted"

  const [comments, setComments] = useState("")
  const [isEditing, setIsEditing] = useState(false)

  const { timeBlocks } = event

  let totalMinutes = 0
  timeBlocks.forEach((block: any) => (totalMinutes += block.minutes))
  timeBlocks.forEach((block: any) => (totalMinutes += block.hours * 60))

  const hours = convertMinutesToHoursMinutes(totalMinutes).hours
  const minutes = convertMinutesToHoursMinutes(totalMinutes).minutes

  const existingCategoryIds: string[] = timeBlocks.map(
    (block: any) => block.categoryId
  )

  const user = event.user_data[0]
  const timestamp = isPending
    ? moment.unix(event.changed).format("YYYY-MM-DDTHH:mm:ss")
    : event.field_reviewed_date_value

  function updateTimeBlocks(timeBlock: any) {
    dispatch(updateRequestTimeBlock({ eventId: event.id, timeBlock }))
  }

  function deleteTimeBlock(timeBlock: any) {
    dispatch(deleteRequestTimeBlock({ eventId: event.id, timeBlock }))
  }

  function addTimeBlock() {
    let newCategory = categories.topCategories.find(
      (category: any) => !existingCategoryIds.includes(category.id)
    )
    dispatch(
      addRequestTimeBlock({
        eventId: event.id,
        newBlock: {
          categoryId: newCategory && newCategory.id,
          hours: 1,
          minutes: 0,
          id: "new-" + event.timeBlocks.length,
        },
      })
    )
  }

  return (
    <li
      className="request-list-item"
      style={{ animationDelay: animationDelay + "s" }}
    >
      <Accordion expanded={activeItem === event.id}>
        <AccordionSummary onClick={onClick}>
          <div className="flexRow">
            <div style={{ width: 350 }}>
              {event.user_data[0]?.user_first_name &&
                event.user_data[0]?.user_last_name && (
                  <UserLabel
                    firstName={he.decode(event.user_data[0].user_first_name)}
                    lastName={he.decode(event.user_data[0].user_last_name)}
                    imagePath={event.user_data[0].user_pic}
                    subgroups={event.user_data[0].user_subgroup}
                  />
                )}
            </div>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <div style={{ marginLeft: 10 }}>
                <h3>
                  {event.field_rec_ext_event_target_id && (
                    <span
                      style={{
                        width: 15,
                        height: 15,
                        marginRight: 5,
                        position: "relative",
                        top: 1,
                      }}
                      className="icon recommended"
                    />
                  )}
                  {event.title}
                </h3>
                <span className="timestamp">
                  {moment(event.field_start_date_value).format("MMMM Do, YYYY")}
                </span>
              </div>
            </div>

            <TimeCapsule hours={hours} minutes={minutes} />

            {isPending && <span className="icon status-icon raised-hand" />}
            {isDeclined && <span className="icon status-icon declined" />}
            {isAccepted && <span className="icon status-icon reviewed" />}

            <strong
              className="requested-time"
              style={{
                display: "block",
                color: isDeclined ? "#EB5252" : "black",
              }}
            >
              {isDeclined && <>Declined </>}
              {isPending && <>Requested </>}
              {isAccepted && <>Approved </>}

              {moment(timestamp).fromNow()}
            </strong>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          <div className="request-content">
            <div className="column">
              <section
                style={{ position: "relative" }}
                onClick={() => isPending && setIsEditing(true)}
              >
                <header>PD Categories</header>
                <ul
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className={classNames(
                    "timeBlockList",
                    !isPending && "reviewed",
                    isEditing && "isEditing"
                  )}
                >
                  <span
                    className="icon edit"
                    style={{
                      width: 25,
                      height: 25,
                      position: "absolute",
                      top: 40,
                      right: 10,
                    }}
                  />

                  {timeBlocks.map((timeBlock: any, index: number) => {
                    const blockCategory = categories.data.find(
                      (cat: any) => cat.id === timeBlock.categoryId
                    )

                    return (
                      <li
                        key={index}
                        className={classNames("flexRow", !index && "first")}
                        style={{ marginTop: 10 }}
                      >
                        <TextField
                          className="timeBlocks"
                          variant="standard"
                          type="number"
                          label={!index && "Hours"}
                          value={timeBlock.hours}
                          onChange={(event) => {
                            const hours =
                              event.target.value.length < 3 &&
                              Number(event.target.value) < 60 &&
                              Number(event.target.value) >= 0
                                ? Number(event.target.value)
                                : Number(timeBlock.hours)

                            const updatedBlock = {
                              hours,
                              minutes: Number(timeBlock.minutes),
                              categoryId: timeBlock.categoryId,
                              id: timeBlock.id,
                            }
                            updateTimeBlocks({ index, value: updatedBlock })
                          }}
                        />

                        <TextField
                          className="timeBlocks"
                          variant="standard"
                          type="number"
                          label={!index && "Minutes"}
                          value={timeBlock.minutes}
                          onChange={(event) => {
                            const minutes =
                              event.target.value.length < 3 &&
                              Number(event.target.value) < 60 &&
                              Number(event.target.value) >= 0
                                ? Number(event.target.value)
                                : Number(timeBlock.minutes)

                            const updatedBlock = {
                              hours: Number(timeBlock.hours),
                              minutes,
                              categoryId: timeBlock.categoryId,
                              id: timeBlock.id,
                            }
                            updateTimeBlocks({ index, value: updatedBlock })
                          }}
                        />

                        <CategorySelect
                          currentValue={blockCategory ?? null}
                          onChange={(selected: any) => {
                            const updatedBlock = {
                              hours: Number(timeBlock.hours),
                              minutes: Number(timeBlock.minutes),
                              categoryId: selected && selected.id,
                            }
                            updateTimeBlocks({ index, value: updatedBlock })
                          }}
                          categories={categories}
                          label={!index ? "Category" : ""}
                          allowedLevels={3}
                        />

                        {timeBlocks.length > 1 && (
                          <DeleteButton
                            onClick={() => {
                              deleteTimeBlock(timeBlock)
                            }}
                          />
                        )}
                      </li>
                    )
                  })}
                  {existingCategoryIds.length <
                    categories.topCategories.length && (
                    <li style={{ listStyleType: "none", marginTop: 10 }}>
                      <ButtonSmall
                        onClick={() => {
                          addTimeBlock()
                        }}
                      >
                        <Plus />
                        <span style={{ paddingLeft: 5 }}>
                          Add Time Category
                        </span>
                      </ButtonSmall>
                    </li>
                  )}
                </ul>
              </section>

              <section className="ratingContainer">
                <header>{user.user_first_name}'s Rating</header>
                <div className="flexRow">
                  {[1, 2, 3, 4, 5].map((value) => {
                    return (
                      <RatingStar
                        key={value}
                        selected={event.field_rating_value >= value}
                      />
                    )
                  })}
                </div>
              </section>

              {event.field_learner_comment_value && (
                <section>
                  <header>Comments</header>
                  <div className="flexRow">
                    <SanitizedHTML html={event.field_learner_comment_value} />
                  </div>
                </section>
              )}
            </div>

            <div className="column">
              {event.description__value && (
                <section>
                  <header>Description</header>
                  <SanitizedHTML html={event.description__value} />
                </section>
              )}

              {event.attachments.length > 0 && (
                <section>
                  <header>Attachments</header>
                  <ul className="pd-attachment-list">
                    {event.attachments.map((file: any) => {
                      const itemContent = (
                        <>
                          {file.extension !== "png" &&
                          file.extension !== "jpg" &&
                          file.extension !== "jpeg" ? (
                            <span
                              className={classNames("icon", file.extension)}
                            />
                          ) : (
                            <span
                              className={classNames("icon image")}
                              style={{
                                backgroundImage: "url(" + file.file + ")",
                              }}
                            />
                          )}
                          {file.filename}
                        </>
                      )

                      return (
                        <li key={file.filename}>
                          {file.extension !== "png" &&
                          file.extension !== "jpg" &&
                          file.extension !== "jpeg" ? (
                            <Button>
                              <a
                                href={file.file}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {itemContent}
                              </a>
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                dispatch(setCurrentAttachment(file))
                              }}
                            >
                              {itemContent}
                            </Button>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </section>
              )}
            </div>
          </div>

          {event.reviewer && (
            <div className="reviewer-comments">
              <ReviewerQuote
                groupLabel={session.group.label[0].value}
                imageSrc={event.reviewer.reviewer_pic}
                firstName={event.reviewer.reviewer_first_name}
                lastName={event.reviewer.reviewer_last_name}
                text={event.field_rev_comment_value}
              />
            </div>
          )}

          {!isPending && (
            <footer
              className="flexRow"
              style={{ marginTop: 0, justifyContent: "flex-end" }}
            >
              <Button
                className="button secondary large"
                onClick={() => {
                  dispatch(reopenExternalRequest({ event }))
                }}
              >
                <span className="icon resubmit" />
                Reopen Request
              </Button>
            </footer>
          )}

          {!preview && isPending && (
            <footer className="flexRow" style={{ justifyContent: "flex-end" }}>
              {!confirmDecline ? (
                <>
                  <TextField
                    variant="standard"
                    style={{ width: "100%" }}
                    value={comments}
                    placeholder={"Your comments (optional)"}
                    onChange={(event) => {
                      setComments(event.target.value)
                    }}
                  />

                  <Button
                    className="button secondary large"
                    onClick={() => {
                      setConfirmDecline(true)
                    }}
                  >
                    Decline
                  </Button>

                  <ButtonLarge
                    onClick={() => {
                      dispatch(
                        approveExternalRequest({ event, timeBlocks, comments })
                      )
                      setComments("")
                      setIsEditing(false)
                    }}
                  >
                    Approve
                  </ButtonLarge>
                </>
              ) : (
                <>
                  <TextField
                    variant="standard"
                    style={{ width: "100%" }}
                    value={comments}
                    placeholder={"Enter your reason for declining this request"}
                    onChange={(event) => {
                      setComments(event.target.value)
                    }}
                  />
                  <Button
                    className="button secondary large"
                    onClick={() => {
                      setConfirmDecline(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classNames(
                      "button secondary large delete",
                      !comments && "disabled"
                    )}
                    onClick={() => {
                      dispatch(declineExternalRequest({ event, comments }))
                      setConfirmDecline(false)
                      setComments("")
                      setIsEditing(false)
                    }}
                  >
                    Decline
                  </Button>
                </>
              )}
            </footer>
          )}
        </AccordionDetails>
      </Accordion>
    </li>
  )
}

export default RequestCard
