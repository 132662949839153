import { useAppDispatch } from "store/hooks"
import { Dialog, Button, FormControlLabel, Checkbox } from "@mui/material"
import "../../../styles/modal.scss"

import { updateField } from "./activeEventSlice"
import { updateConferenceField } from "../conference/activeConferenceSlice"

function ModalResendEmail(props) {
  const { onClose, open, event, confirm, isConference, isSession } = props

  const dispatch = useAppDispatch()
  const updateInfo = isConference ? updateConferenceField : updateField

  const handleClose = () => {
    onClose()
  }

  const confirmPublish = () => {
    confirm()
    onClose()
  }

  let publishText = isConference ? "Conference" : "Event"
  if (isSession) {
    publishText = "Session"
  }

  return (
    <Dialog
      className="modal eventConfirmationModal"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <header>
        <h2 style={{ marginBottom: 0 }}>
          <span className="icon paper-plane" />
          New Participants Added
        </h2>
      </header>
      <p>
        It looks like you've changed the participants for this{" "}
        {publishText.toLowerCase()}. Would you like to notify newly added
        participants?
      </p>

      <div className="flexRow">
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              checked={event.sendPublishEmail}
              onChange={(event) => {
                dispatch(
                  updateInfo({
                    field: "sendPublishEmail",
                    value: event.target.checked,
                  })
                )
              }}
              name="visible"
            />
          }
        />
        <span style={{ marginLeft: -20 }}>Notify via Email</span>
      </div>

      <div className="flexRow">
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              checked={event.pushNotifications}
              onChange={(event) => {
                dispatch(
                  updateInfo({
                    field: "pushNotifications",
                    value: event.target.checked,
                  })
                )
              }}
              name="visible"
            />
          }
        />
        <span style={{ marginLeft: -20 }}>Notify via Push Notification</span>
      </div>

      <footer style={{ width: "100%", marginLeft: -10 }}>
        <Button
          style={{ flex: 1, margin: 0 }}
          onClick={handleClose}
          className="button small cancel"
        >
          Continue Editing
        </Button>
        <Button
          style={{
            flex: 1,
            margin: 0,
          }}
          onClick={() => {
            confirmPublish()
          }}
          className="button small"
        >
          Update {publishText}
        </Button>
      </footer>
    </Dialog>
  )
}

export default ModalResendEmail
