import {
  defaultRubricFilters,
  RubricFilters,
} from "@mobilemind/common/src/api/rubricApi"

import { setRubricStatusFilter } from "./entityListSlice"

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import "../../styles/sidebar.scss"
import { useMemo } from "react"
import { useAppDispatch } from "store/hooks"

type RubricSidebarProps = {
  filters: RubricFilters
  setFilters: (filters: RubricFilters) => void
}

export function RubricSidebar(props: RubricSidebarProps) {
  const { filters, setFilters } = props
  const { search, sort_by, status, field_archive_value, field_draft_value } =
    filters
  const dispatch = useAppDispatch()

  const statusFilterValue = useMemo<
    "draft" | "published" | "archived" | "any" | undefined
  >(() => {
    if (status === "any") {
      return "any"
    } else if (field_draft_value === 1 && field_archive_value === 0) {
      return "draft"
    } else if (field_draft_value === 0 && field_archive_value === 1) {
      return "archived"
    } else if (field_draft_value === 0 && field_archive_value === 0) {
      return "published"
    }
  }, [status, field_archive_value, field_draft_value])

  return (
    <div id="sidebar" style={{ paddingTop: 20 }}>
      <form className="filters">
        <header>
          <strong>Filter Rubrics</strong>
          <Button
            className="button small"
            onClick={() => {
              setFilters({ ...defaultRubricFilters })
            }}
          >
            Reset
          </Button>
        </header>

        <div className="inputSearch">
          <span className="icon search" />
          <TextField
            variant="standard"
            label="Search"
            value={search}
            onChange={(event) => {
              setFilters({ ...filters, search: event.target.value })
            }}
          />
        </div>

        <FormControl variant="standard" className="inputSelect status">
          <InputLabel id="label-status-select">Status</InputLabel>
          <Select
            variant="standard"
            labelId="label-status-select"
            id="status-select"
            value={statusFilterValue}
            onChange={(event) => {
              dispatch(setRubricStatusFilter(event.target.value))
              if (event.target.value === "draft") {
                setFilters({
                  ...filters,
                  field_draft_value: 1,
                  field_archive_value: 0,
                  status: event.target.value,
                })
              } else if (event.target.value === "archived") {
                setFilters({
                  ...filters,
                  field_archive_value: 1,
                  field_draft_value: 0,
                  status: event.target.value,
                })
              } else if (event.target.value === "published") {
                setFilters({
                  ...filters,
                  field_archive_value: 0,
                  field_draft_value: 0,
                  status: event.target.value,
                })
              } else {
                setFilters({
                  ...filters,
                  status: event.target.value,
                })
              }
            }}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={"published"}>Published</MenuItem>
            <MenuItem value={"draft"}>Draft</MenuItem>
            <MenuItem value={"archived"}>Archived</MenuItem>
          </Select>
        </FormControl>

        <header style={{ marginTop: 10, marginBottom: 20 }}>
          <strong>Sort Rubrics</strong>
        </header>

        <FormControl variant="standard" className="inputSelect">
          <InputLabel>Sort By</InputLabel>
          <Select
            variant="standard"
            value={sort_by}
            onChange={(event) =>
              setFilters({
                ...filters,
                sort_by: event.target.value,
                sort_order: event.target.value === "name" ? "ASC" : "DESC",
              })
            }
          >
            <MenuItem value={"changed"}>Date Updated</MenuItem>
            <MenuItem value={"name"}>Name</MenuItem>
          </Select>
        </FormControl>
      </form>
    </div>
  )
}
