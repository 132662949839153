import { Component } from "react"
import { connect } from "react-redux"
import BottomScrollListener from "react-bottom-scroll-listener"

import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
} from "@mui/material"
import { getCourses, updateFilters, resetFilters } from "./coursesSlice"

import CourseListItem from "../../components/CourseListItem"
import Loading from "@mobilemind/common/src/components/Loading"

import classNames from "classnames"
import "../../styles/courseSelect.scss"
import { getCategories } from "../../store/reducers/categories"
import { getJobTitles } from "../jobTitles/jobTitlesSlice"
import CategorySelect from "@mobilemind/common/src/components/CategorySelect"
import { useAppDispatch } from "store/hooks"

const mapStateToProps = ({ courses, jobTitles, categories, session }) => {
  return {
    courses,
    jobTitles,
    categories,
    session,
  }
}

const mapDispatchToProps = {
  getCourses,
  getCategories,
  getJobTitles,
  resetFilters,
}

class CourseSelect extends Component {
  componentDidMount = async () => {
    !this.props.categories.fetched && (await this.props.getCategories())

    !this.props.courses.currentPage !== this.props.courses.totalPages - 1 &&
      this.props.getCourses({ hideMM: this.props.hideMM })
    !this.props.jobTitles.fetched && this.props.getJobTitles()
  }
  render() {
    return <CourseSelectForm {...this.props} />
  }
}

function CourseSelectForm(props) {
  const { categories, courses, session, jobTitles, coursesAdded, hideMM } =
    props
  const { filters } = courses
  const { category, source, searchQuery } = filters

  const dispatch = useAppDispatch()

  const handleClick = (course) => {
    let isAdded = coursesAdded.find(
      (addedCourse) => addedCourse.id === course.id
    )
    if (isAdded) {
      dispatch(props.removeCourse(course))
    } else {
      dispatch(props.addCourse(course))
    }
  }

  function handleScroll(manual) {
    // If we aren't already fetching more results
    if (!courses.isFetching) {
      // And if there are more pages to fetch
      if (courses.currentPage < courses.totalPages - 1) {
        dispatch(updateFilters("increaseCurrentPage"))
      }
    }
  }

  return (
    <div
      className={classNames(
        "courseSelect",
        courses.isFetching && "isFetching",
        courses.hasFetched && "hasFetched"
      )}
    >
      <BottomScrollListener onBottom={handleScroll}>
        <header>
          <h2 className="title">
            <div style={{ flex: 1 }}>Add Courses</div>
            {courses.isFetching && <Loading message={"Getting courses..."} />}
          </h2>

          <div className="mm-only-disclaimer">
            <p>
              Please note, your learners already have access to these
              micro-courses. Only use this feature if you want to edit any part
              of the micro-course before publishing to your learners.
            </p>
            <p>
              Any edits will be released as a new micro-course that your team
              will be responsible for reviewing (unless you select a fully
              automated submission type).
            </p>
          </div>

          <div className="flexRow filterRow">
            <CategorySelect
              label={"Category"}
              allowedLevels={3}
              currentValue={category}
              categories={categories}
              onChange={(newCategory) =>
                dispatch(
                  updateFilters({
                    name: "category",
                    value: newCategory,
                    hideMM,
                  })
                )
              }
            />

            {session.group.uuid && (
              <FormControl variant="standard" className="inputSelect source">
                <InputLabel id="label-source-select">Source</InputLabel>
                <Select
                  variant="standard"
                  labelId="label-source-select"
                  id="source-select"
                  value={source}
                  onChange={(event) =>
                    dispatch(
                      updateFilters({
                        name: "source",
                        value: event.target.value,
                      })
                    )
                  }
                >
                  <MenuItem value={"any"}>Any</MenuItem>
                  <MenuItem value={session.group.uuid[0].value}>
                    {session.group.label[0].value}
                  </MenuItem>
                  <MenuItem value={"mobilemind"}>MobileMind</MenuItem>
                </Select>
              </FormControl>
            )}

            <TextField
              variant="standard"
              label="Search"
              value={searchQuery}
              onChange={(event) =>
                dispatch(
                  updateFilters({
                    name: "searchQuery",
                    value: event.target.value,
                    hideMM,
                  })
                )
              }
            />
          </div>
        </header>
        <ul>
          {courses.data.map((course) => {
            const courseAdded =
              coursesAdded &&
              coursesAdded.find((addedCourse) => addedCourse.id === course.id)

            const excludedCategories = session.group.field_categories_to_exclude

            const isExcluded =
              excludedCategories &&
              excludedCategories.length &&
              course.category &&
              excludedCategories.find(
                (cat) => cat.target_uuid === course.category.id
              )

            if (
              isExcluded ||
              (hideMM &&
                !course.relationships.field_organization.data &&
                !course.relationships.field_partner.data)
            ) {
              return null
            }

            const courseJobTitles =
              course.relationships.field_job_title.data.map(
                (courseJobTitle) => {
                  return jobTitles.data.find(
                    (jobTitle) => jobTitle.id === courseJobTitle.id
                  )
                }
              )

            let courseGroups = []
            if (session.subGroups && session.subGroups.data) {
              courseGroups = course.relationships.field_subgroup.data.map(
                (courseGroup) => {
                  return session.subGroups.data.find(
                    (subgroup) =>
                      subgroup.entity_id ===
                      courseGroup.meta.drupal_internal__target_id
                  )
                }
              )
            }
            if (
              !course.attributes.field_draft ||
              window.location.href.includes("badges/") ||
              window.location.href.includes("learning-paths/")
            ) {
              return (
                <CourseListItem
                  groups={courseGroups}
                  jobTitles={courseJobTitles}
                  courseAdded={courseAdded}
                  onClick={() => {
                    handleClick(course)
                  }}
                  key={course.id}
                  animationDelay={"0s"}
                  category={course.category}
                  course={course}
                  isSingle={courses.data.length === 1}
                />
              )
            }
            return null
          })}
        </ul>
      </BottomScrollListener>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseSelect)
