import { Component } from "react"
import { connect } from "react-redux"
import NavbarSecondary from "../../components/NavbarSecondary"
import { saveQuickLinks, hideQuickLinks } from "../../actions/quickLinks"

import "../../styles/navbar.scss"

const mapStateToProps = ({ quickLinks }) => {
  return {
    quickLinks,
  }
}

const mapDispatchToProps = {
  saveQuickLinks,
  hideQuickLinks,
}

class QuickLinksNavbarContainer extends Component {
  publish = () => {
    this.props.saveQuickLinks(this.props.quickLinks)
    this.props.history && this.props.history.push("/")
  }

  hide = () => {
    this.props.hideQuickLinks(this.props.quickLinks)
    this.props.history && this.props.history.push("/")
  }

  render() {
    const props = {
      type: "Quick Links Widget",
      iconClass: "icon quick-links white",
      path: "/",
      publish: this.publish,
      activeItem: this.props.quickLinks,
      canSave: true,
      canPublish: true,
      isQuickLinks: true,
    }

    return <NavbarSecondary {...props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickLinksNavbarContainer)
