import { Button, Box } from "@mui/material"

import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"

import { CourseVideoContainer } from "features/courseForm/components/CourseVideoContainer"
import { CourseFormValues } from "features/courseForm/types"
import nl2br from "nl2br"

type PreviewInitialProps = {
  course: CourseFormValues
  setPage: (page: number) => void
}

export function ExtensionPreviewInitial({
  course,
  setPage,
}: PreviewInitialProps) {
  return (
    <>
      <Box
        sx={{
          height: 500,
          paddingInline: 3,
          color: "white",
          overflowY: "auto",
        }}
      >
        <SanitizedHTML html={nl2br(course.learnerInstructions)} />
        <CourseVideoContainer videoLink={course.field_video_link} />
      </Box>

      <Box sx={{ display: "flex", padding: 3, justifyContent: "center" }}>
        <Button
          sx={{ minWidth: 200 }}
          onClick={() => setPage(1)}
          variant="contained"
          size="large"
          color="secondary"
        >
          Next
        </Button>
      </Box>
    </>
  )
}
