import { configureStore } from "@reduxjs/toolkit"
import reducers from "./reducers"
import { api } from "@mobilemind/common/src/api"

const store = configureStore({
  reducer: reducers,
  preloadedState: {},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
})

export default store
