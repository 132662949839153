import { Component } from "react"
import { connect } from "react-redux"
import { uploadImage, getServiceAccount } from "../../actions/serviceAccount"

import moment from "moment"
import classNames from "classnames"
import Dropzone from "react-dropzone"
import PropTypes from "prop-types"

import profilePicImg from "../../img/profile-pic.svg"

const mapStateToProps = ({ session }) => {
  return {
    session,
  }
}

const mapDispatchToProps = {
  uploadImage,
  getServiceAccount,
}

class ServiceAccountPicture extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isUploading: false,
    }
  }

  onDrop = (file) => {
    let { serviceAccountId } = this.props.session
    let uploaded = file[0] ? file[0] : file.target.files[0]
    if (uploaded) {
      // If it's an image
      if (uploaded.type.indexOf("image") > -1) {
        // Update the UI
        this.setState({
          isDragging: false,
          isUploading: true,
        })

        // Read the file
        let reader = new FileReader()
        reader.readAsDataURL(uploaded)

        // Once we're done, get the file and name it
        reader.onloadend = async (event) => {
          let filename =
            serviceAccountId + "-" + new moment().unix() + "-" + uploaded.name

          // And upload it to the user
          let url = "/api/user/service/" + serviceAccountId + "/user_picture"
          let newserviceAccountPicture = await this.props.uploadImage(
            event.target.result,
            url,
            filename
          )

          this.props.setImage([newserviceAccountPicture])
          this.setState({ isUploading: false })
        }
      }
    } else {
      this.setState({ isDragging: false, isUploading: false })
    }
  }

  render() {
    const { isUploading, isDragging } = this.state
    const { serviceAccountPicture } = this.props

    return (
      <Dropzone
        accept="image/jpeg, image/png, image/jpg"
        onDragEnter={() => this.setState({ isDragging: true })}
        onDragLeave={() => this.setState({ isDragging: false })}
        onDrop={this.onDrop.bind(this)}
      >
        {({ getRootProps }) => (
          <div
            {...getRootProps()}
            className={classNames(
              "fileUpload",
              { isDragging: isDragging },
              { isUploading: isUploading }
            )}
          >
            <input onChange={this.onDrop.bind(this)} type="file" />
            {serviceAccountPicture.length ? (
              <div
                className="profilePicture userPicture"
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_API_URL +
                    serviceAccountPicture[0].attributes.uri.url +
                    ")",
                }}
              />
            ) : (
              <>
                <img
                  alt="profilePicture"
                  className="profilePicture"
                  src={profilePicImg}
                />
                <div className="border" />
                {
                  <div className="tooltip">
                    {isUploading ? (
                      <>Uploading Image...</>
                    ) : (
                      <>
                        Click to Upload
                        <br />
                        or Drag and Drop
                        <span>
                          256MB limit
                          <br />
                          png jpg jpeg
                        </span>
                      </>
                    )}
                  </div>
                }
              </>
            )}
          </div>
        )}
      </Dropzone>
    )
  }
}

ServiceAccountPicture.propTypes = {
  session: PropTypes.object,
  serviceAccountPicture: PropTypes.array,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceAccountPicture)
