import { Card, CardContent } from "@mui/material"
import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"

import theme from "@mobilemind/common/src/theme/theme"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import moment from "moment"

interface CapsuleProps {
  animationDelay: number
  event: any
  totalTime?: number
  onClick: () => void
}

const RecommendedCard = ({
  animationDelay,
  event,
  totalTime,
  onClick,
}: CapsuleProps) => {
  const totalMinutes = totalTime ? totalTime : event.field_total_time_value

  let startTime = event.field_start_date_value

  return (
    <Card
      onClick={onClick}
      variant={"elevation"}
      className={"card recommended-event"}
      style={{ animationDelay: animationDelay + "s" }}
    >
      <CardContent>
        <div style={{ overflow: "hidden", borderRadius: "10px 10px 0 0" }}>
          {event.image ? (
            <div
              className="image-wrapper"
              style={{ backgroundImage: "url(" + event.image + ")" }}
            ></div>
          ) : (
            <div className="image-wrapper">
              <span className="icon external-pd" />
              <div className="external-backdrop" />
              <div
                className="image-wrapper"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  opacity: 0.8,
                }}
              ></div>
            </div>
          )}
        </div>

        <div className="inner">
          <header
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <strong>{event.title}</strong>

            <span
              style={{
                opacity: 0.6,
                fontSize: 12,
              }}
            >
              {event.field_start_date_value ? (
                <>
                  {moment(event.field_start_date_value).format("MMMM Do, YYYY")}
                  <br />
                  {moment(startTime).format("h:mma")}
                </>
              ) : (
                <>Always Available</>
              )}
            </span>
          </header>

          {event.field_archive_value === "1" && (
            <span
              className="icon archive blue"
              style={{
                position: "absolute",
                bottom: 10,
                right: 10,
                height: 15,
                width: 15,
              }}
            />
          )}
        </div>
        <div className={"capsule-wrapper"}>
          <TimeCapsule
            hours={convertMinutesToHoursMinutes(totalMinutes).hours}
            minutes={convertMinutesToHoursMinutes(totalMinutes).minutes}
          />
        </div>
      </CardContent>
    </Card>
  )
}

export default RecommendedCard
