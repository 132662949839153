import { flatMapHelperText } from "@mobilemind/common/src/utility/yup"
import { Button } from "@mui/material"
import { useFormikContext } from "formik"
import { useEffect, useMemo, useRef, useState } from "react"
import { useAppSelector } from "store/hooks"
import { PublishPopover } from "../../../components/PublishPopover"
import { ReactComponent as PublishIcon } from "../../../img/publish.svg"
import { ReactComponent as SaveIcon } from "../../../img/save.svg"
import { selectIsContentLocked } from "../activeCourseSlice"
import { CourseFormValues } from "../types"

export const CourseSubmitButton = () => {
  const ref = useRef<HTMLDivElement>(null)
  const isContentLocked = useAppSelector(selectIsContentLocked)
  const { errors, isValid, submitCount, submitForm, values } =
    useFormikContext<CourseFormValues>()

  const [showErrors, setShowErrors] = useState(false)

  useEffect(() => {
    if (submitCount > 0 && !isValid) {
      setShowErrors(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount])

  const errorMessages = useMemo(() => flatMapHelperText(errors), [errors])

  return (
    <div
      ref={ref}
      onMouseEnter={() => {
        if (submitCount > 0 && !isValid) {
          setShowErrors(true)
        }
      }}
      onMouseLeave={() => setShowErrors(false)}
    >
      <Button
        size="small"
        disabled={isContentLocked || (submitCount > 0 && !isValid)}
        startIcon={
          values.isTemplate ? (
            <SaveIcon width={20} height={20} />
          ) : (
            <PublishIcon width={20} height={20} />
          )
        }
        onClick={submitForm}
      >
        {values.isTemplate ? <>Save</> : <>Publish</>}
      </Button>
      <PublishPopover
        open={showErrors}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setShowErrors(false)}
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              padding: 4,
            },
          },
        }}
      >
        <p>Uh oh, you're missing the following:</p>
        <ul>
          {errorMessages.map((value) => {
            return <li key={value}>{value}</li>
          })}
        </ul>
      </PublishPopover>
    </div>
  )
}
