import { Box, Tooltip, TooltipProps } from "@mui/material"

export const CourseTooltip = (props: Partial<TooltipProps>) => {
  return (
    <Tooltip
      arrow={true}
      title={
        <div>
          <p>
            Following these guidelines will give your learners the best possible
            experience:
          </p>
          <ul>
            <li>
              We recommend that videos be no longer than 5 minutes for maximum
              engagement.
            </li>
            <li>
              Start with the core “why” so that learners understand the
              intention behind the instruction.
            </li>
            <li>
              Be very clear in your learner instructions (and reviewer, if
              applicable), and number steps when there are several.
            </li>
            <li>
              When choosing the challenge submission type, carefully consider
              the skills / knowledge you wish to assess, as well as whether you
              want to review manually or automatically.
            </li>
            <li>
              Leverage the “additional resources” section and “related courses”
              feature to extend and remediate learning when possible.
            </li>
          </ul>
        </div>
      }
      placement="bottom-end"
      {...props}
      slotProps={{
        tooltip: {
          sx: {
            boxShadow: 1,
            padding: 2,
            borderRadius: 1,
            "& p": {
              marginTop: 1,
              marginBottom: 2,
            },
            "& ul": {
              margin: 0,
              padding: 0,
              paddingLeft: 3,
              "& li": {
                padding: 0,
                marginBlock: 1,
              },
            },
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          gap: 1,
          "& .icon": { width: 15, height: 15 },
        }}
      >
        <span className="icon info" />
        What makes a great course?
      </Box>
    </Tooltip>
  )
}

export default CourseTooltip
