import { Stepper } from "@mui/material"
import { useFormikContext } from "formik"
import { CourseFormValues } from "../types"
import { CourseFormStepButton } from "./CourseFormStepButton"
import { useEffect } from "react"

export enum CourseFormStep {
  Overview,
  Challenge,
  Settings,
  Review,
}

export const CourseFormStepper = () => {
  const { status, setStatus } = useFormikContext<CourseFormValues>()

  useEffect(() => {
    if (status.activeStep > status.furthestStep) {
      setStatus({ ...status, furthestStep: status.activeStep })
    }
  }, [status, setStatus])

  return (
    <Stepper
      activeStep={status.activeStep}
      nonLinear={true}
      alternativeLabel={true}
    >
      <CourseFormStepButton step={CourseFormStep.Overview}>
        Course
      </CourseFormStepButton>
      <CourseFormStepButton step={CourseFormStep.Challenge}>
        Challenge
      </CourseFormStepButton>
      <CourseFormStepButton step={CourseFormStep.Settings}>
        Settings
      </CourseFormStepButton>
      <CourseFormStepButton step={CourseFormStep.Review}>
        Review
      </CourseFormStepButton>
    </Stepper>
  )
}
