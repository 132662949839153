import {
  defaultRubricFilters,
  RubricFilters,
  useGetOrgRubricsQuery,
} from "@mobilemind/common/src/api/rubricApi"
import Loading from "@mobilemind/common/src/components/Loading"
import { useState } from "react"
import BottomScrollListener from "react-bottom-scroll-listener"
import { useAppSelector } from "store/hooks"
import ErrorBoundary from "../../components/ErrorBoundary"
import { RubricList } from "./RubricList"
import { RubricSidebar } from "./RubricSidebar"

export function RubricListContainer() {
  const session = useAppSelector((state) => state.session)
  const [filters, setFilters] = useState<RubricFilters>({
    ...defaultRubricFilters,
  })

  const [currentPage, setCurrentPage] = useState(0)
  const requestFilters = { ...filters }

  if (requestFilters.status === "any") {
    delete requestFilters.field_archive_value
    delete requestFilters.field_draft_value
  }

  const { isLoading, isFetching, data } = useGetOrgRubricsQuery({
    orgId: session.group.id?.[0].value,
    currentPage,
    filters: requestFilters,
  })

  function handleScroll() {
    if (data) {
      const { total_pages } = data.pager
      if (currentPage + 1 < total_pages) {
        setCurrentPage(currentPage + 1)
      }
    }
  }

  if (isLoading) {
    return <Loading message={"Getting rubrics…"} />
  }

  if (!data) {
    return null
  }

  const loadingMoreMessage = "Getting Rubrics..."

  return (
    <ErrorBoundary>
      <BottomScrollListener onBottom={handleScroll}>
        <RubricSidebar filters={filters} setFilters={setFilters} />
        <RubricList
          setFilters={setFilters}
          filters={filters}
          rows={data.rows}
          pager={data.pager}
        />
        {isFetching && (
          <div className="loadingMoreContainer">
            <Loading message={loadingMoreMessage} />
          </div>
        )}
      </BottomScrollListener>
    </ErrorBoundary>
  )
}

export default RubricListContainer
