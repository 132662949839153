import { RubricFilters } from "@mobilemind/common/src/api/rubricApi"
import theme from "@mobilemind/common/src/theme/theme"
import { DrupalViewPager } from "@mobilemind/common/src/types/utilities"
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import classNames from "classnames"
import he from "he"
import pluralize from "pluralize"
import { Link } from "react-router-dom"
import plusImg from "../../img/plus.svg"
import { useAppSelector } from "../../store/hooks"
import moment from "moment"

type RubricListProps = {
  filters: RubricFilters
  setFilters: (filters: RubricFilters) => void
  rows: unknown[]
  pager: DrupalViewPager
}

export function RubricList(props: RubricListProps) {
  const session = useAppSelector((state) => state.session)
  const { orgRoles } = session

  const { rows, pager, filters, setFilters } = props

  const isDrafter =
    orgRoles.includes("organization-drafter") ||
    session.groupRoles.includes("group-drafter")

  let pageLabel = "Rubrics"

  return (
    <div className="page hasSidebar entityList">
      <h2 style={{ position: "relative" }}>
        <span className={"icon rubric"} />
        <span className="title">{pageLabel}</span>
        <Button className="button small">
          <Link to="/create/rubrics/new">
            <img alt={"Create Rubric"} src={plusImg} /> Create Rubric
          </Link>
        </Button>
      </h2>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="flexRow entityCount">
                  <div
                    style={{ cursor: "pointer" }}
                    className="flexRow"
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort_order:
                          filters.sort_order === "ASC" ? "DESC" : "ASC",
                      })
                    }}
                  >
                    <span
                      className={classNames(
                        "icon sort",
                        filters.sort_order === "ASC" && "flipped"
                      )}
                    />
                  </div>
                  <span className="count">{pager.total_items ?? 0}</span>{" "}
                  {pluralize("Rubrics", pager.total_items)}
                </div>
              </TableCell>

              <TableCell>Last Updated</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((entity: any) => {
              let isArchived =
                Number(entity.field_archive) === 1 ||
                Number(entity.field_learning_path_archive) === 1
              let isDraft =
                entity.field_draft === "1" ||
                entity.field_draft === true ||
                entity.field_draft === "True"

              let path = "/create/rubrics/" + entity.uuid

              let imageUrl
              if (entity.image && entity.image.length) {
                imageUrl = entity.image.includes("data:image")
                  ? entity.image
                  : process.env.REACT_APP_API_URL + entity.image
              }

              return (
                <TableRow
                  key={entity.id}
                  component={Link}
                  to={path}
                  sx={(theme) => ({
                    "&>td": { padding: `${theme.spacing(4)}!important` },
                  })}
                >
                  <TableCell>
                    <div className="flexRow">
                      <>
                        <>
                          {imageUrl ? (
                            <div
                              className={"entityImage rubric"}
                              style={{
                                backgroundImage: "url(" + imageUrl + ")",
                              }}
                            ></div>
                          ) : (
                            <span className={"icon rubric"} />
                          )}
                        </>
                      </>
                      <div className="flexRow name">
                        {he.decode(entity.name)}
                      </div>
                    </div>
                  </TableCell>

                  <TableCell>
                    {moment(entity.changed).format("M/D/YYYY")}
                  </TableCell>
                  <TableCell>
                    {isArchived && !isDraft && <>Archived</>}
                    {!isArchived && !isDraft && !isDrafter && <>Published</>}
                    {(isDraft || isDrafter) && <>Draft</>}

                    {isDraft &&
                      !isDrafter &&
                      entity.field_ready_to_publish === "True" && (
                        <span
                          style={{
                            fontSize: 10,
                            position: "absolute",
                            top: 40,
                            fontWeight: "bold",
                            display: "block",
                            textTransform: "uppercase",
                            color: theme.palette.primary.dark,
                          }}
                        >
                          Ready to Publish
                        </span>
                      )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
