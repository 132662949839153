import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { FastFieldWithError } from "components/FastFieldWithError"
import { FastField, Field, FieldArray, useFormikContext } from "formik"
import "react-quill/dist/quill.snow.css"
import DeleteButton from "../../../components/DeleteButton"
import plusImg from "../../../img/plus.svg"
import "../../../styles/create.scss"
import { CourseFormValues } from "../types"
import { Panel } from "./Panel"

export const TextQuestionCourseFields = () => {
  const { values } = useFormikContext<CourseFormValues>()

  return (
    <>
      <FormControl variant="standard" margin="normal">
        <InputLabel id="label-text-review-method-select">
          Review Method
        </InputLabel>
        <Field
          as={Select}
          name="reviewMethod"
          variant="standard"
          labelId="label-text-review-method-select"
          id="text-review-method-select"
        >
          <MenuItem value={"exact-match"}>
            Automatic Review: Exact Match
          </MenuItem>
          <MenuItem value={"include"}>
            Automatic Review: Include Words/Phrases
          </MenuItem>
          <MenuItem value={"manual"}>Manual</MenuItem>
        </Field>
        {values.textSubmissionType === "manual" && (
          <FormHelperText>
            The learner's text response will be submitted for review by a
            reviewer in your organization.
          </FormHelperText>
        )}
      </FormControl>
      {values.reviewMethod === "exact-match" && (
        <FastFieldWithError
          as={TextField}
          name="exactMatch"
          label="Required Response"
          margin="normal"
          helperText={
            "The learner's response will be reviewed automatically and must match the content above exactly. After 2 failed attempts, the response will be submitted for review by a reviewer in your organization."
          }
          variant="standard"
        />
      )}
      {values.reviewMethod === "include" && (
        <Panel>
          <h3>Words/Phrases Required</h3>

          <Typography>
            The learner's response will be reviewed automatically and must
            include any/all of the phrases below. After 2 failed attempts, the
            response will be submitted for review by a reviewer in your
            organization.
          </Typography>

          <Field
            as={RadioGroup}
            name="includeOption"
            aria-label="Include"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <FormControlLabel value="any" control={<Radio />} label="Any" />
            <FormControlLabel value="all" control={<Radio />} label="All" />
          </Field>

          <FieldArray
            name="phrases"
            render={(arrayHelpers) => (
              <>
                <List>
                  {values.phrases.map((phrase: string, index: number) => {
                    return (
                      <ListItem key={index} disableGutters={true}>
                        <FastField
                          as={TextField}
                          name={`phrases.${index}`}
                          className="phrase"
                          variant="standard"
                          required
                        />
                        {values.phrases.length > 2 && (
                          <DeleteButton
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        )}
                      </ListItem>
                    )
                  })}
                </List>
                <Button
                  onClick={() => arrayHelpers.push("")}
                  className="button small"
                  startIcon={<img alt="Add Answer" src={plusImg} />}
                >
                  Add Word/Phrase
                </Button>
              </>
            )}
          />
        </Panel>
      )}
    </>
  )
}
