import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material"
import { connect } from "react-redux"
import { updateConferenceField } from "../conference/activeConferenceSlice"
import { regenerateJoinCode, updateField } from "./activeEventSlice"

import { generateNewJoinCode } from "../../../actions/events"

import moment from "moment"

import { DateTimePicker } from "@mui/x-date-pickers"
import { useAppDispatch } from "store/hooks"

const mapStateToProps = ({ session }, ownProps) => {
  return {
    isConference: ownProps.isConference,
    isObservation: ownProps.isObservation,
    session,
  }
}

function EventRegistration(props) {
  const event = props.activeEvent
  const dispatch = useAppDispatch()

  const registrationStart = moment(event.registrationStarts).format()
  const registrationEnd = moment(event.registrationEnds).format()

  let checkInStart = event.checkInStart
    ? moment(event.checkInStart)._i
    : moment(event.startDate)._i
  let checkInEnd = event.checkInEnd
    ? moment(event.checkInEnd).format()
    : moment(event.endDate).format()
  const { isObservation } = props
  let title = props.isConference ? "Conference" : "Event"
  if (isObservation) {
    title = "Observation"
  }
  const updateInfo = props.isConference ? updateConferenceField : updateField

  if (props.isSession) {
    title = "Session"
  }

  function generateCode() {
    dispatch(regenerateJoinCode())
    dispatch(generateNewJoinCode(event))
  }

  return (
    <div className="event-content event-registration two-column">
      <div className="flexRow columns">
        <div className="column left">
          <h2>
            {props.isConference ? (
              <>Conference Registration</>
            ) : (
              <>Registration</>
            )}{" "}
            Info
          </h2>
          {props.isConference && (
            <p
              style={{
                fontWeight: 700,
                fontSize: 14,
                color: "rgb(231, 92, 93)",
                maxWidth: 410,
              }}
            >
              Note: When registration closes, unregistered users will lose
              access to all sessions.
            </p>
          )}
          <FormControl variant="standard">
            <RadioGroup
              value={event.registrationWindow}
              onChange={(event) =>
                dispatch(
                  updateInfo({
                    field: "registrationWindow",
                    value: event.target.value,
                  })
                )
              }
            >
              <FormControlLabel
                value={"unlimited"}
                control={<Radio />}
                label={
                  "Allow registration until the " +
                  title.toLowerCase() +
                  " starts"
                }
              />
              <FormControlLabel
                value={"dateRange"}
                control={<Radio />}
                label={"Only allow registration between specific dates"}
              />
            </RadioGroup>
          </FormControl>
          {event.registrationWindow === "dateRange" && (
            <>
              <h3>Registration Window</h3>

              <div className="dateTimeSelect registrationWindow flexRow">
                <div>
                  <DateTimePicker
                    label="Registration Starts"
                    value={moment(registrationStart)}
                    onChange={(date) =>
                      dispatch(
                        updateInfo({
                          field: "registrationStarts",
                          value: moment(date).format(),
                        })
                      )
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                </div>
                <div>
                  <DateTimePicker
                    label="Registration Ends"
                    value={moment(registrationEnd)}
                    onChange={(date) =>
                      dispatch(
                        updateInfo({
                          field: "registrationEnds",
                          value: moment(date).format(),
                        })
                      )
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                </div>
              </div>
            </>
          )}
          {props.isConference && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={event.requireRSVP}
                  onChange={(event) =>
                    dispatch(
                      updateInfo({
                        field: "requireRSVP",
                        value: event.target.checked,
                      })
                    )
                  }
                  name="requireRSVP"
                  color="secondary"
                />
              }
              label="Require RSVP to View Session Details"
            />
          )}

          {!isObservation && (
            <>
              <h3>{title} Max Capacity</h3>
              <FormControl variant="standard">
                <TextField
                  style={{ width: 400 }}
                  variant="standard"
                  inputProps={{ type: "number", min: 0 }}
                  className="max-capacity"
                  value={event.eventMax}
                  onChange={(event) =>
                    dispatch(
                      updateInfo({
                        field: "eventMax",
                        value: event.target.value,
                      })
                    )
                  }
                  helperText="Enter 0 for unlimited capacity"
                />
              </FormControl>
            </>
          )}

          {!props.isSession && (
            <>
              <h3>Registration Reminders</h3>
              <FormControl variant="standard" className="select">
                <Select
                  variant="standard"
                  id="event-type-select"
                  multiple
                  value={event.sendRegistrationReminders}
                  onChange={(event) =>
                    dispatch(
                      updateInfo({
                        field: "sendRegistrationReminders",
                        value: event.target.value,
                      })
                    )
                  }
                >
                  <MenuItem className="multiSelect" value={"none"}>
                    No Reminders
                  </MenuItem>
                  <MenuItem className="multiSelect" value={"1_day"}>
                    1 Day Before
                  </MenuItem>
                  <MenuItem className="multiSelect" value={"3_day"}>
                    3 Days Before
                  </MenuItem>
                  <MenuItem className="multiSelect" value={"1_week"}>
                    1 Week Before
                  </MenuItem>
                  <MenuItem className="multiSelect" value={"1_month"}>
                    1 Month Before
                  </MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </div>

        <div className="column right">
          <h2>Check In</h2>

          <h3>Check In Method</h3>
          <div
            className="flexRow"
            style={{ alignItems: "flex-end", marginBottom: 15 }}
          >
            <FormControl variant="standard" style={{ flex: 1, maxWidth: 150 }}>
              <Select
                variant="standard"
                labelId="label-attendance-method"
                id="attendance-method-select"
                value={event.attendanceMethod}
                onChange={(event) =>
                  dispatch(
                    updateInfo({
                      field: "attendanceMethod",
                      value: event.target.value,
                    })
                  )
                }
              >
                <MenuItem key={"join_code"} value={"join_code"}>
                  Join Code
                </MenuItem>
                <MenuItem key={"manual"} value={"manual"}>
                  Manual
                </MenuItem>
              </Select>
            </FormControl>

            {event.id && event.attendanceMethod === "join_code" && (
              <div className="joinCode">
                {!event.isGeneratingCode && (
                  <Button
                    onClick={() => {
                      generateCode()
                    }}
                    className="button small"
                  >
                    Generate New Code
                  </Button>
                )}
                <span>{event.joinCode}</span>
              </div>
            )}
          </div>

          {event.attendanceMethod === "join_code" && (
            <>
              <h3>Check In Window</h3>

              <div className="dateTimeSelect joinCodeRange flexRow">
                <div>
                  <DateTimePicker
                    label="Check In Starts"
                    value={moment(checkInStart)}
                    onChange={(date) => {
                      dispatch(
                        updateInfo({
                          field: "checkInStart",
                          value: moment(date).format(),
                        })
                      )
                    }}
                  />
                </div>
                <div>
                  <DateTimePicker
                    label="Check In Ends"
                    minDate={moment(checkInStart)}
                    value={moment(checkInEnd)}
                    onChange={(date) =>
                      dispatch(
                        updateInfo({
                          field: "checkInEnd",
                          value: moment(date).format(),
                        })
                      )
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(EventRegistration)
