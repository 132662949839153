import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import _ from "lodash"
import moment from "moment"

export const getOrganizations = createAsyncThunk(
  "session/getOrganizations",
  async () => {
    let pages = 1
    let i = 0
    let content = []

    while (i < pages) {
      let query = {
        page: {
          offset: i * 50,
        },
      }

      let response = await fetchWrapper.get(
        "/api/group/organization?" + qs.stringify(query)
      )
      if (response.ok) {
        let data = await response.json()
        pages = Math.ceil(Number(data.meta.count) / 50)
        data.data.forEach((org) => content.push(org))
      }
      i++
    }

    let sortedByName = _.sortBy(content, (organization) => {
      return organization.attributes.label
    })

    return sortedByName
  }
)

export const getJobTitles = createAsyncThunk(
  "session/getJobTitles",
  async () => {
    let response = await fetchWrapper.get("/api/taxonomy_term/job_titles")
    let data = await response.json()

    return data
  }
)

export const getSubGroups = createAsyncThunk(
  "session/getSubGroups",
  async (args, thunkAPI) => {
    const org = thunkAPI.getState().session.group
    let query = {
      filter: {
        "gid.id": org && org.uuid && org.uuid[0].value,
      },
      include: "entity_id",
    }

    let pages = 1
    let i = 0
    let subGroups = []

    while (i < pages) {
      query.page = {
        offset: i * 50,
      }

      let response = await fetchWrapper.get(
        "/api/group_content/organization-subgroup-group?" + qs.stringify(query)
      )
      let data = await response.json()

      pages = Math.ceil(data.meta.count / 50)
      // Match groups with the drupal_internal__id of their entity_id relationship (Groups module...😵)

      data.data.forEach((group) => {
        let targetEntityId =
          data.included &&
          data.included.find(
            (included) => included.attributes.label === group.attributes.label
          )

        if (targetEntityId) {
          group.entity_id = targetEntityId.attributes.drupal_internal__id
          group.id = targetEntityId.id
        }
        subGroups.push(group)
      })
      i++
    }

    // Sort groups alphabetically
    let sortedGroups = _.orderBy(subGroups, [
      (group) => group.attributes.label && group.attributes.label.toUpperCase(),
    ])

    return sortedGroups
  }
)

export const updateOrgSettings = createAsyncThunk(
  "session/updateOrgSettings",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()
    const org = session.group
    const { values } = args

    const {
      field_teacher_leaderboard_visibi,
      field_rewind_begin,
      field_rewind_end,
      field_open_time,
      field_close_time,
      field_knowbe4_api_key,
      field_org_time_zone,
      field_disable_observation,
    } = values

    const body = {
      data: {
        type: "group--organization",
        id: org.uuid[0].value,
        attributes: {
          field_teacher_leaderboard_visibi,
          field_knowbe4_api_key,
          field_org_time_zone,
          field_disable_observation,
          field_rewind_begin: moment(field_rewind_begin).format("YYYY-MM-DD"),
          field_rewind_end: moment(field_rewind_end).format("YYYY-MM-DD"),
          field_open_time: moment(field_open_time).format("h:mma"),
          field_close_time: moment(field_close_time).format("h:mma"),
        },
      },
    }

    let response = await fetchWrapper.patch(
      "/api/group/organization/" + org.uuid[0].value,
      session.token,
      JSON.stringify(body)
    )

    let data = await response.json()

    return data
  }
)

export const removeKB4APIKey = createAsyncThunk(
  "session/removeKB4APIKey",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()
    const org = session.group

    const body = {
      data: {
        type: "group--organization",
        id: org.uuid[0].value,
        attributes: {
          field_knowbe4_api_key: null,
        },
      },
    }

    let response = await fetchWrapper.patch(
      "/api/group/organization/" + org.uuid[0].value,
      session.token,
      JSON.stringify(body)
    )

    let data = await response.json()

    return data
  }
)

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    token: null,
    userId: null,
    user: {},
    userPicture: {},
    group: {},
    groupRoles: [],
    orgRoles: [],
    userBadges: [],
    userLearningPaths: [],
    organizations: {
      fetched: false,
      data: [],
    },
    users: {
      fetched: false,
      data: [],
    },
    subGroups: {
      fetched: false,
      data: [],
    },
    jobTitles: {
      fetched: false,
      data: [],
    },
    orgUsers: {
      fetched: false,
      data: [],
    },
  },
  reducers: {
    getUserId: (state, action) => {
      state.group = action.payload.group
      state.userId = action.payload.userId
      state.roles = action.payload.roles
      state.orgRoles = Object.keys(action.payload.orgRoles)
      state.groupRoles = action.payload.subgroup
        ? action.payload.subgroup[1] && Object.keys(action.payload.subgroup[1])
        : []
      state.subgroup = action.payload.subgroup && action.payload.subgroup[0]
      state.collection = action.payload.collection

      let constructedSubGroups =
        action.payload.subgroups &&
        action.payload.subgroups.map((group) => {
          return {
            id: group[0].uuid[0].value,
            drupal_internal__id: group[0].id[0].value,
            attributes: {
              label: group[0].label[0].value,
            },
            relationships: {
              entity_id: {
                data: {
                  id: group[0].uuid[0].value,
                },
              },
            },
          }
        })

      state.subgroups = action.payload.subgroups && {
        data: constructedSubGroups,
      }
      state.isSiteAdmin = action.payload.roles.find(
        (role) => role.target_id === "administrator"
      )
      state.isSiteReviewer =
        action.payload.roles.find((role) => role.target_id === "reviewer") ||
        action.payload.roles.find((role) => role.target_id === "tier_1_grader")
    },
    userIsLoggedIn: (state, action) => {
      state.user = action.payload
      let userPicture =
        action.meta.included &&
        action.meta.included.filter(
          (included) => included.type === "file--image"
        )
      state.userPicture = userPicture && userPicture[0]
    },
    getToken: (state, action) => {
      state.token = action.payload
    },
    updateUserPicture: (state, action) => {
      state.userPicture = action.payload
    },
    setProductTourStarted: (state, action) => {
      state.user.attributes.field_hq_product_tour_started = action.payload
    },
    updateUser: (state, action) => {
      state.user = action.payload
    },
    updateQuickActions: (state, action) => {
      state.user.quickActions = action.payload
    },
  },
  extraReducers: {
    [getSubGroups.pending]: (state) => {
      state.subGroups.fetching = true
    },
    [getSubGroups.fulfilled]: (state, action) => {
      state.subGroups.fetched = true
      state.subGroups.fetching = false
      state.subGroups.data = action.payload ? action.payload : []
    },
    [getJobTitles.fulfilled]: (state, action) => {
      state.jobTitles.fetched = true

      let fetchedTitles = action.payload.data.filter(
        (title) =>
          title.attributes.name !== "School" &&
          title.attributes.name !== "District"
      )
      let jobTitles = fetchedTitles.filter(
        (title) =>
          !title.attributes.name.includes("School") &&
          !title.attributes.name.includes("District")
      )
      let schoolTitles = fetchedTitles.filter((title) =>
        title.attributes.name.includes("School")
      )
      let districtTitles = fetchedTitles.filter((title) =>
        title.attributes.name.includes("District")
      )

      state.jobTitles.data = jobTitles.concat(schoolTitles, districtTitles)
    },
    [getOrganizations.fulfilled]: (state, action) => {
      state.organizations.data = action.payload
      state.organizations.fetched = true
    },
  },
})

export const { updateQuickActions } = sessionSlice.actions

export default sessionSlice.reducer
