import { Typography, Box, TextField } from "@mui/material"
import { CourseFormValues } from "features/courseForm/types"
import "../../../../styles/extension.scss"
import { ExtensionPreviewChallengeFooter } from "./ExtensionPreviewChallengeFooter"
import { SubmissionType } from "@mobilemind/common/src/types/course"

type URLProps = {
  course: CourseFormValues
  setPage: (page: number) => void
}

export function ExtensionPreviewText({ course, setPage }: URLProps) {
  return (
    <>
      <Box
        sx={{
          paddingInline: 3,
          paddingBlock: 5,
          color: "white",
          overflowY: "auto",
        }}
      >
        <Typography sx={{ marginBottom: 2 }}>{course.question}</Typography>

        <TextField
          sx={{ background: "white", borderRadius: 1 }}
          placeholder={
            course.submissionType === SubmissionType.Url
              ? "Enter your URL..."
              : "Enter your response..."
          }
          variant="outlined"
        />
      </Box>

      <ExtensionPreviewChallengeFooter setPage={setPage} />
    </>
  )
}
