import { Box, Button, TextField } from "@mui/material"
import { FastFieldWithError } from "components/FastFieldWithError"
import { FieldArray, useFormikContext } from "formik"
import "react-quill/dist/quill.snow.css"
import DeleteButton from "../../../components/DeleteButton"
import plusImg from "../../../img/plus.svg"
import "../../../styles/create.scss"
import { CourseFormValues } from "../types"

export const CheckboxQuestionFields = () => {
  const { values } = useFormikContext<CourseFormValues>()
  return (
    <FieldArray
      name="checkboxOptions"
      render={(arrayHelpers) => (
        <>
          <Box role="list">
            {values.checkboxOptions.map((option: string, index: number) => {
              return (
                <Box
                  key={"checkbox-option-" + index}
                  role="listitem"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 4,
                    marginBottom: 4,
                  }}
                >
                  <FastFieldWithError
                    as={TextField}
                    name={`checkboxOptions.${index}`}
                    className="answer"
                    variant="standard"
                    multiline
                    required
                  />
                  {values.checkboxOptions.length > 1 && (
                    <DeleteButton onClick={() => arrayHelpers.remove(index)} />
                  )}
                </Box>
              )
            })}
          </Box>
          <Box
            component="footer"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => arrayHelpers.push("")}
              className="button small"
              startIcon={<img alt="Add Answer" src={plusImg} />}
            >
              Add Option
            </Button>
          </Box>
        </>
      )}
    />
  )
}
