import { api, CacheTag } from "./index"
import { OrgRubric } from "../types/rubrics"
import { DrupalViewPager } from "../types/utilities"

export type RubricFilters = {
  sort_order: string
  search: string
  status: string
  sort_by: string
  field_draft_value?: number
  field_archive_value?: number
}

export const defaultRubricFilters: RubricFilters = {
  sort_order: "DESC",
  search: "",
  status: "any",
  sort_by: "changed",
}

export const rubricApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrgRubrics: builder.query<
      { rows: unknown[]; pager: DrupalViewPager },
      { orgId: string; currentPage?: number; filters?: Partial<RubricFilters> }
    >({
      query: ({ orgId, filters }) => ({
        url: `/api/rubric_entity/rubric_list/${orgId}`,
        method: "GET",
        params: filters,
      }),
      providesTags: [CacheTag.OrgRubric],
    }),
    getOrgRubricById: builder.query<OrgRubric, string>({
      query: (uuid) => `/api/rubric/${uuid}`,
      transformResponse: (response: { data: OrgRubric }) => response.data,
      providesTags: [CacheTag.OrgRubric],
    }),
    createOrgRubric: builder.mutation<OrgRubric, OrgRubric>({
      query: (data) => ({
        url: `/api/rubric`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: { data },
      }),
      invalidatesTags: [CacheTag.OrgRubric],
    }),
    updateOrgRubric: builder.mutation<OrgRubric, OrgRubric>({
      query: (data) => ({
        url: `/api/rubric/${data.uuid}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: { data },
      }),
      invalidatesTags: [CacheTag.OrgRubric],
    }),
  }),
})

export const {
  endpoints: { getOrgRubricById, createOrgRubric, updateOrgRubric },
  useGetOrgRubricsQuery,
  useGetOrgRubricByIdQuery,
} = rubricApi
