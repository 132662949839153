import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import type { RootState } from "../types"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"

import {
  fetchEventsAttended,
  fetchCompletedCourses,
  fetchEarnedBadges,
  fetchCompletedLPs,
  fetchUserRubrics,
  fetchMandatedTrainingHistory,
} from "@mobilemind/common/src/actions/backpack"

export const increaseEventsPage = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("backpack/increaseEventsPage", async (args, thunkAPI) => {
  const { filters } = thunkAPI.getState().backpack
  const fetchUserId =
    window.location.href.split("/")[window.location.href.split("/").length - 1]
  thunkAPI.dispatch(fetchEventsAttended(filters, fetchUserId))
  return args
})

export const increaseBadgePage = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("backpack/increaseBadgePage", async (args, thunkAPI) => {
  const fetchUserId =
    window.location.href.split("/")[window.location.href.split("/").length - 1]
  const { filters } = thunkAPI.getState().backpack
  thunkAPI.dispatch(fetchEarnedBadges(filters, fetchUserId))
  return args
})

export const increaseCoursePage = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("backpack/increaseCoursePage", async (args, thunkAPI) => {
  const { filters } = thunkAPI.getState().backpack
  const fetchUserId =
    window.location.href.split("/")[window.location.href.split("/").length - 1]
  thunkAPI.dispatch(fetchCompletedCourses(filters, fetchUserId))
  return args
})

export const increaseLPPage = createAsyncThunk<any, any, { state: RootState }>(
  "backpack/increaseLPPage",
  async (args, thunkAPI) => {
    const { filters } = thunkAPI.getState().backpack
    const fetchUserId =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ]
    thunkAPI.dispatch(fetchCompletedLPs(filters, fetchUserId))
    return args
  }
)

export const increaseRubricPage = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("backpack/increaseRubricPage", async (args, thunkAPI) => {
  const { filters } = thunkAPI.getState().backpack
  const fetchUserId =
    window.location.href.split("/")[window.location.href.split("/").length - 1]
  thunkAPI.dispatch(fetchUserRubrics(filters, fetchUserId))
  return args
})

export const increaseMandatedTrainingPage = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("backpack/increaseMandatedTrainingPage", async (args, thunkAPI) => {
  const { filters } = thunkAPI.getState().backpack
  const fetchUserId =
    window.location.href.split("/")[window.location.href.split("/").length - 1]
  thunkAPI.dispatch(fetchMandatedTrainingHistory(filters, fetchUserId))
  return args
})

export const fetchSingleUserRubric = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("backpack/fetchSingleUserRubric", async (args, thunkAPI) => {
  const userRubricId = args

  let rubricResponse = await fetchWrapper.get(
    "/api/user_rubric_details/" + userRubricId
  )

  if (rubricResponse.ok) {
    let rubricData = await rubricResponse.json()
    return rubricData.rubric_data
  }
})

type InitialState = {
  totalTime: number
  categoryData: any[]
  fetched: boolean
  saveDateSetting: boolean
  activeRubric: any
  userData: {
    field_first_name_value: string
    field_last_name_value: string
    user_subgroups: string
    user_image: string
  }
  events: {
    data: any[]
    fetched: boolean
    isFetching: boolean
    totalCompleted: number
    totalPages: number
    currentPage: number
    activeIndex: number
  }
  mandatedTraining: {
    data: any[]
    fetched: boolean
    isFetching: boolean
    totalCompleted: number
    totalPages: number
    currentPage: number
    activeIndex: number
  }
  external: {
    data: any[]
    fetched: boolean
    isFetching: boolean
    totalCompleted: number
    totalPages: number
    currentPage: number
    activeIndex: number
  }
  courses: {
    data: any[]
    fetched: boolean
    isFetching: boolean
    totalCompleted: number
    totalPages: number
    currentPage: number
    activeIndex: number
  }
  badges: {
    data: any[]
    fetched: boolean
    isFetching: boolean
    totalCompleted: number
    totalPages: number
    currentPage: number
    activeIndex: number
  }
  learningPaths: {
    data: any[]
    fetched: boolean
    isFetching: boolean
    totalCompleted: number
    totalPages: number
    currentPage: number
    activeIndex: number
  }
  userRubrics: {
    // Set this type for data once kyle sets up the view
    data: any[]
    fetched: boolean
    isFetching: boolean
    totalCompleted: number
    totalPages: number
    currentPage: number
    activeIndex: number
  }
  filters: {
    dateRange: string
    dateMin: null | string
    dateMax: string
    category: string
  }
}

const today = new Date()
const saveDateSetting = localStorage.getItem("backpackSavedFilters")
  ? true
  : false
let savedFilters = localStorage.getItem("backpackSavedFilters")

const initialState: InitialState = {
  totalTime: 0,
  categoryData: [],
  fetched: false,
  saveDateSetting,
  activeRubric: null,
  userData: {
    field_first_name_value: "",
    field_last_name_value: "",
    user_subgroups: "",
    user_image: "",
  },
  events: {
    data: [],
    fetched: false,
    isFetching: false,
    totalCompleted: 0,
    totalPages: 0,
    currentPage: 0,
    activeIndex: 0,
  },
  mandatedTraining: {
    data: [],
    fetched: false,
    isFetching: false,
    totalCompleted: 0,
    totalPages: 0,
    currentPage: 0,
    activeIndex: 0,
  },
  external: {
    data: [],
    fetched: false,
    isFetching: false,
    totalCompleted: 0,
    totalPages: 0,
    currentPage: 0,
    activeIndex: 0,
  },
  courses: {
    data: [],
    fetched: false,
    isFetching: false,
    totalCompleted: 0,
    totalPages: 0,
    currentPage: 0,
    activeIndex: 0,
  },
  badges: {
    data: [],
    fetched: false,
    isFetching: false,
    totalCompleted: 0,
    totalPages: 0,
    currentPage: 0,
    activeIndex: 0,
  },
  learningPaths: {
    data: [],
    fetched: false,
    isFetching: false,
    totalCompleted: 0,
    totalPages: 0,
    currentPage: 0,
    activeIndex: 0,
  },
  userRubrics: {
    data: [],
    fetched: false,
    isFetching: false,
    totalCompleted: 0,
    totalPages: 0,
    currentPage: 0,
    activeIndex: 0,
  },
  filters: savedFilters
    ? JSON.parse(savedFilters)
    : {
        dateRange: "all",
        dateMin: null,
        dateMax: JSON.stringify(new Date(today)),
        category: "all",
      },
}

export const backpackSlice = createSlice({
  name: "backpack",
  initialState,
  reducers: {
    resetBackpack: (state) => {
      if (!state.saveDateSetting) {
        state.filters = {
          dateRange: "all",
          dateMin: null,
          dateMax: JSON.stringify(new Date(today)),
          category: "all",
        }
      }

      state.fetched = false
    },
    setDateMin: (state, action) => {
      state.filters.dateMin = action.payload
    },
    setSaveDateSetting: (state, action) => {
      state.saveDateSetting = action.payload
    },
    setDateMax: (state, action) => {
      state.filters.dateMax = action.payload
    },

    setEventsCarousel: (state, action) => {
      state.events.activeIndex += action.payload
    },
    setCourseCarousel: (state, action) => {
      state.courses.activeIndex += action.payload
    },
    setBadgeCarousel: (state, action) => {
      state.badges.activeIndex += action.payload
    },
    setLPCarousel: (state, action) => {
      state.learningPaths.activeIndex += action.payload
    },

    setRubricCarousel: (state, action) => {
      state.userRubrics.activeIndex += action.payload
    },
    setMandatedTrainingCarousel: (state, action) => {
      state.mandatedTraining.activeIndex += action.payload
    },

    clearBackpackRubric: (state) => {
      state.activeRubric = null
    },

    updateFilters: (state, action) => {
      const { dateRange, category } = action.payload
      state.filters.dateRange = dateRange
      state.filters.category = category
      state.fetched = false

      state.events.fetched = false
      state.events.currentPage = 0

      state.external.fetched = false
      state.external.currentPage = 0

      state.courses.fetched = false
      state.courses.currentPage = 0
      state.courses.activeIndex = 0

      state.badges.fetched = false
      state.badges.currentPage = 0
      state.badges.activeIndex = 0

      state.learningPaths.fetched = false
      state.learningPaths.currentPage = 0
    },
    fetchBackpack: (state, action) => {
      const data = action.payload.backpack_data
      const categoryKeys = Object.keys(data.category_data)

      state.userData = data.user_data[0]
      state.fetched = true
      state.totalTime = data.total_time
      state.categoryData = categoryKeys.map((key) => data.category_data[key])
    },
    fetchEventsAttended: (state, action) => {
      if (action.payload.rows) {
        let fetchedEvents = !action.payload.rows.content
          ? action.payload.rows
          : []
        state.events.data = state.events.currentPage
          ? state.events.data.concat(fetchedEvents)
          : fetchedEvents
        state.events.totalPages = action.payload.pager
          ? action.payload.pager.total_pages
          : 0
        state.events.totalCompleted = action.payload.pager
          ? action.payload.pager.total_items
          : 0
      } else {
        state.events.totalCompleted = 0
        state.events.data = []
      }

      state.events.fetched = true
      state.events.isFetching = false
      state.events.currentPage && state.events.activeIndex++
    },
    fetchMandatedTrainingHistory: (state, action) => {
      if (action.payload.rows) {
        let fetchedTraining = !action.payload.rows.content
          ? action.payload.rows
          : []
        state.mandatedTraining.data = state.mandatedTraining.currentPage
          ? state.mandatedTraining.data.concat(fetchedTraining)
          : fetchedTraining
        state.mandatedTraining.totalPages = action.payload.pager
          ? action.payload.pager.total_pages
          : 0
        state.mandatedTraining.totalCompleted = action.payload.pager
          ? action.payload.pager.total_items
          : 0
      } else {
        state.mandatedTraining.totalCompleted = 0
        state.mandatedTraining.data = []
      }

      state.mandatedTraining.fetched = true
      state.mandatedTraining.isFetching = false
      state.mandatedTraining.currentPage && state.mandatedTraining.activeIndex++
    },
    fetchExternalApprovals: (state, action) => {
      state.external.data = action.payload.external_user_event_data
      state.external.totalPages = Math.ceil(action.payload.total_records / 25)
      state.external.totalCompleted = action.payload.total_records
      state.external.fetched = true
    },
    fetchCompletedCourses: (state, action) => {
      state.courses.data = state.courses.currentPage
        ? state.courses.data.concat(action.payload.data)
        : action.payload.data
      state.courses.totalPages = Math.ceil(action.payload.total_records / 50)
      state.courses.totalCompleted = action.payload.total_records
      state.courses.fetched = true
      state.courses.isFetching = false
      state.courses.currentPage && state.courses.activeIndex++
    },
    fetchEarnedBadges: (state, action) => {
      state.badges.data = state.badges.currentPage
        ? state.badges.data.concat(action.payload.badges_data)
        : action.payload.badges_data
      state.badges.totalPages = Math.ceil(action.payload.total_records / 25)
      state.badges.totalCompleted = action.payload.total_records
      state.badges.fetched = true
      state.badges.isFetching = false
      state.badges.currentPage && state.badges.activeIndex++
    },
    fetchCompletedLPs: (state, action) => {
      state.learningPaths.data = state.learningPaths.currentPage
        ? state.learningPaths.data.concat(action.payload.lp_data)
        : action.payload.lp_data
      state.learningPaths.totalPages = Math.ceil(
        action.payload.total_records / 25
      )
      state.learningPaths.totalCompleted = action.payload.total_records
      state.learningPaths.fetched = true
      state.learningPaths.isFetching = false
      state.learningPaths.currentPage && state.learningPaths.activeIndex++
    },
    fetchUserRubrics: (state, action) => {
      state.userRubrics.data = state.userRubrics.currentPage
        ? state.userRubrics.data.concat(action.payload.rows)
        : action.payload.rows
      state.userRubrics.totalPages = Math.ceil(
        action.payload.pager.total_pages / 25
      )
      state.userRubrics.totalCompleted = action.payload.pager.total_items
      state.userRubrics.fetched = true
      state.userRubrics.isFetching = false
      state.userRubrics.currentPage && state.userRubrics.activeIndex++

      if (action.payload.userData) {
        state.userData = action.payload.userData
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(increaseEventsPage.pending, (state, action) => {
      state.events.isFetching = true
      state.events.activeIndex = action.meta.arg
      state.events.currentPage++
    })

    builder.addCase(increaseCoursePage.pending, (state, action) => {
      state.courses.isFetching = true
      state.courses.activeIndex = action.meta.arg
      state.courses.currentPage++
    })

    builder.addCase(increaseBadgePage.pending, (state, action) => {
      state.badges.isFetching = true
      state.badges.activeIndex = action.meta.arg
      state.badges.currentPage++
    })

    builder.addCase(increaseLPPage.pending, (state, action) => {
      state.learningPaths.isFetching = true
      state.learningPaths.activeIndex = action.meta.arg
      state.learningPaths.currentPage++
    })
    builder.addCase(increaseRubricPage.pending, (state, action) => {
      state.userRubrics.isFetching = true
      state.userRubrics.activeIndex = action.meta.arg
      state.userRubrics.currentPage++
    })
    builder.addCase(fetchSingleUserRubric.pending, (state) => {
      state.activeRubric = null
    })
    builder.addCase(fetchSingleUserRubric.fulfilled, (state, action) => {
      state.activeRubric = action.payload
    })
  },
})

export const {
  resetBackpack,
  setDateMin,
  setDateMax,
  setSaveDateSetting,
  updateFilters,
  setEventsCarousel,
  setCourseCarousel,
  setBadgeCarousel,
  setLPCarousel,
  setRubricCarousel,
  setMandatedTrainingCarousel,
  clearBackpackRubric,
} = backpackSlice.actions

export default backpackSlice.reducer
