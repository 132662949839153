import { GenieRewriteAllowedField } from "@mobilemind/genie-api/src/types"
import { Box, Typography } from "@mui/material"
import { ReactComponent as MagicLampIcon } from "../../../img/magic-lamp.svg"
import { ReactComponent as MakeLongerIcon } from "../../../img/make-longer.svg"
import { ReactComponent as MakeShorterIcon } from "../../../img/make-shorter.svg"
import { GenieMessage } from "../../genie/components/GenieMessage"
import { GenieRecreateQuestionButton } from "./GenieRecreateQuestionButton"
import { GenieRewriteFieldButton } from "./GenieRewriteFieldButton"

type GeniePopperFieldContentProps = {
  field: GenieRewriteAllowedField
  name: string
}

export const GeniePopperFieldContent = (
  props: GeniePopperFieldContentProps
) => {
  const { field, name } = props
  return (
    <>
      <GenieMessage size="small">
        <Typography variant="body2">
          <strong>How's this look?</strong> You can make your own changes or I
          can help you here.
        </Typography>
      </GenieMessage>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "center",
        }}
      >
        {field === GenieRewriteAllowedField.Question ? (
          <>
            <GenieRecreateQuestionButton field={field} name={name} />
          </>
        ) : (
          <>
            <GenieRewriteFieldButton
              field={field}
              name={name}
              action="rewrite"
              startIcon={
                <MagicLampIcon width={20} height={20} fill="currentColor" />
              }
            >
              Rewrite
            </GenieRewriteFieldButton>
            <GenieRewriteFieldButton
              field={field}
              name={name}
              action="longer"
              variant="text"
              size="small"
              startIcon={
                <MakeLongerIcon width={20} height={20} fill="currentColor" />
              }
            >
              Make longer
            </GenieRewriteFieldButton>
            <GenieRewriteFieldButton
              field={field}
              name={name}
              action="shorter"
              variant="text"
              size="small"
              startIcon={
                <MakeShorterIcon width={20} height={20} fill="currentColor" />
              }
            >
              Make shorter
            </GenieRewriteFieldButton>
          </>
        )}
      </Box>
    </>
  )
}
