import { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Button } from "@mui/material"

import he from "he"
import moment from "moment"

import classNames from "classnames"
import ErrorBoundary from "../../../components/ErrorBoundary"

import { withRouter } from "react-router-dom"

const mapStateToProps = ({ session, activeConference }, ownProps) => {
  return {
    eventId: ownProps.match ? ownProps.match.params.id : "new",
    conference: activeConference,
    session,
  }
}

class ConferenceEventList extends Component {
  render() {
    const { conference, filteredEvents, allRooms, session } = this.props

    let targetPersonnel =
      conference.personnel &&
      conference.personnel.find((user) => user.id === session.user.id)

    let eventRole = targetPersonnel && targetPersonnel.field_event_role_name

    return (
      <ErrorBoundary>
        <div className="conference-events-list">
          <ul>
            {filteredEvents.map((event) => {
              let formattedDate = moment(event.startDate).format(
                "dddd, MMMM Do, h:mma"
              )
              let fullRoom = allRooms.find((room) => room.id === event.roomId)

              return (
                <li key={event.id} className="flexRow">
                  <div
                    className={classNames(
                      "title",
                      event.field_draft && "draft"
                    )}
                  >
                    <strong style={{ display: "flex", alignItems: "center" }}>
                      <span
                        className={classNames("chip")}
                        style={{
                          backgroundColor: event.color,
                          borderRadius: 20,
                          height: 20,
                          width: 20,
                          display: "inline-block",
                          marginRight: 10,
                        }}
                      />
                      {event.title && he.decode(event.title)}

                      {event.field_draft && (
                        <span
                          style={{
                            backgroundColor: "#666",
                            color: "white",
                            fontSize: 10,
                            padding: "5px 10px",
                            marginLeft: 10,
                            borderRadius: 20,
                            fontWeight: "normal",
                            letterSpacing: 1,
                            textTransform: "uppercase",
                          }}
                        >
                          Draft
                        </span>
                      )}

                      {event.field_archive && <span className="icon archive" />}
                    </strong>
                    <div className="dateTime">
                      <span className="date">{formattedDate}</span>
                      {fullRoom && (
                        <div className="roomInfo flexRow">
                          <span className="icon room color" />{" "}
                          {fullRoom.attributes.name}
                        </div>
                      )}
                    </div>
                  </div>
                  {eventRole !== "Secretary" && (
                    <div className="actions" style={{ marginRight: 20 }}>
                      <Link
                        style={{ marginRight: 20 }}
                        to={"/events/view/session/" + event.drupal_internal__id}
                      >
                        <Button className="button small">
                          <span className="icon view"></span>
                          View Session
                        </Button>
                      </Link>
                      <Button
                        onClick={() => this.props.loadConferenceEvent(event)}
                        className="button small"
                      >
                        <span className="icon edit"></span>
                        Edit Details
                      </Button>
                    </div>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      </ErrorBoundary>
    )
  }
}

export default withRouter(connect(mapStateToProps)(ConferenceEventList))
