import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"

export const fetchAnnouncementTypes = createAsyncThunk(
  "announcementTypesSlice/fetchAnnouncementTypes",
  async () => {
    let response = await fetchWrapper.get(
      "/api/taxonomy_term/announcement_type"
    )
    let data = await response.json()
    return data.data
  }
)

export const announcementTypesSlice = createSlice({
  name: "announcementTypesSlice",
  initialState: {
    fetched: false,
    data: [],
  },
  extraReducers: {
    [fetchAnnouncementTypes.fulfilled]: (state, action) => {
      state.fetched = true
      state.data = action.payload
    },
  },
})

export default announcementTypesSlice.reducer
