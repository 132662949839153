import { TextField } from "@mui/material"
import { FastField } from "formik"

export const HintField = () => {
  return (
    <FastField
      as={TextField}
      name="hint"
      variant="standard"
      multiline
      label="Hint"
      margin="normal"
      helperText={
        "Text to show the learner if they attempt to submit an incorrect response."
      }
    />
  )
}
