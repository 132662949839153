import {
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperProps,
  styled,
} from "@mui/material"
import { ReactNode, useRef, useState } from "react"
import { useAppDispatch } from "store/hooks"
import { showPopper } from "../store/courseGenieSlice"

export const GENIE_POPPER_WIDTH = "350px"

// Arrow styles borrowed from:
// https://github.com/mui/material-ui/blob/next/docs/data/material/components/popper/ScrollPlayground.js
export const Arrow = styled("div")(({ theme }) => ({
  position: "absolute",
  fontSize: 7,
  width: "3em",
  height: "3em",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid",
  },
  '[data-popper-placement*="bottom"] &': {
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: "-0.9em",
    width: "3em",
    height: "1em",
    "&::before": {
      borderWidth: "0 1em 1em 1em",
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
  '[data-popper-placement*="top"] &': {
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    marginBottom: "-0.9em",
    width: "3em",
    height: "1em",
    marginLeft: "0.25em",
    "&::before": {
      borderWidth: "1em 1em 0 1em",
      borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
    },
  },
  '[data-popper-placement*="right"] &': {
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    marginLeft: "-0.9em",
    height: "3em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 1em 1em 0",
      borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
    },
  },
  '[data-popper-placement*="left"] &': {
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    marginRight: "-0.9em",
    height: "3em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 0 1em 1em",
      borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    },
  },
}))

type GeniePopperProps = PopperProps & {
  anchorEl?: Element
}

export const GeniePopper = (props: GeniePopperProps) => {
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null)
  const dispatch = useAppDispatch()

  const contentRef = useRef<ReactNode>()
  if (props.children) {
    contentRef.current = props.children
  }

  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      placement={props.placement}
      transition={true}
      sx={{ width: GENIE_POPPER_WIDTH }}
      keepMounted={false}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
            padding: 10,
          },
        },
      ]}
      disablePortal={true}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener
          onClickAway={(event) => {
            // Don't hide the popper if the user clicks in the field element
            if (props.open && !props.anchorEl?.contains(event.target as Node)) {
              dispatch(showPopper(null))
            }
          }}
        >
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                padding: 2,
                margin: 2,
                // Use `drop-shadow` so the shadow follows the arrow
                filter: "drop-shadow(rgba(0,0,0,0.12) 0px 1px 3px)",
              }}
            >
              <Arrow ref={setArrowRef} />
              {/* Use the popper state ref to prevent the content from disappearing when the popper is exiting */}
              {contentRef.current}
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  )
}
