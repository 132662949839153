import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import moment from "moment"
import qs from "qs"

export function getOrgRubrics() {
  return async (dispatch, getState) => {
    const { session, entityList } = getState()
    const filters = entityList.orgRubrics.filters
    const orgId = session.group.id && session.group.id[0].value

    const url = "/api/rubric_entity/rubric_list/" + orgId

    let query = {
      sort_order: filters.sortOrder,
      search: filters.searchQuery,
      sort: filters.sortBy,
    }

    if (!filters.status) {
      query.field_draft_value = 1
    }
    if (filters.status === 1) {
      query.field_learning_path_archive_value = 0
      query.field_draft_value = 0
    }

    if (filters.status === "archived") {
      query.field_learning_path_archive_value = 1
    }
    try {
      let content = []
      query.page = entityList.orgRubrics.currentPage

      let response = await fetchWrapper.get(url + "?" + qs.stringify(query))
      if (response.ok) {
        let data = await response.json()

        if (!data.rows.content) {
          data.rows.forEach((row) => {
            content.push(row)
          })
        }

        dispatch({
          type: "entityList/getOrgRubrics",
          payload: content,
          meta: {
            pages: data.pager.total_pages,
            totalRecords: Number(data.pager.total_items),
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}
export function getRubricCompliance(args) {
  return async (dispatch, getState) => {
    const { compliance, session } = getState()

    dispatch({
      type: "complianceSlice/getRubricCompliancePending",
    })

    const { filters } = compliance
    const targetRubricId = args?.rubricId ?? filters.rubric

    const allRoles = session.orgRoles.concat(session.groupRoles).join(",")
    const isOrgLevel =
      allRoles.includes("organization-admin") ||
      allRoles.includes("organization-reporter") ||
      allRoles.includes("organization-observation_admin")

    let query = {
      search: filters.searchInput,
      end: moment(filters.dateTo.split("T")[0]).format("YYYY-MM-DD"),
    }

    if (filters.group !== "all") {
      query.subgroup = filters.group
    } else if (!isOrgLevel) {
      query.subgroup = session.subgroup.label[0].value
    }

    if (filters.jobTitle !== "all") {
      query.job_title = filters.jobTitle
    }
    if (filters.dateFrom) {
      query.start = moment(filters.dateFrom.split("T")[0]).format("YYYY-MM-DD")
    }

    if (filters.sortBy === "field_first_name_value") {
      query.sort_by = "first_name"
    } else if (filters.sortBy === "field_last_name_value") {
      query.sort_by = "last_name"
    } else {
      query.sort_by = "completed_date"
      query.sort_order = "DESC"
    }
    query.page = {
      offset: compliance.currentPage * 50,
    }

    try {
      let response = await fetchWrapper.get(
        "/api/compliance_rubric/" + targetRubricId + "?" + qs.stringify(query)
      )
      if (response.ok) {
        let data = await response.json()
        dispatch({
          type: "complianceSlice/getRubricCompliance",
          payload: data.data.user_rubric_data,
          meta: {
            total: data.total_records,
            totalPages: Math.ceil(data.total_records / 50),
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}
