import { Typography, Box } from "@mui/material"
import { CourseFormValues } from "features/courseForm/types"
import "../../../../styles/extension.scss"
import { ExtensionPreviewChallengeFooter } from "./ExtensionPreviewChallengeFooter"
import ImageUpload from "components/ImageUpload"

type URLProps = {
  course: CourseFormValues
  setPage: (page: number) => void
}

export function ExtensionPreviewImage({ course, setPage }: URLProps) {
  return (
    <>
      <Box
        sx={{
          paddingInline: 3,
          paddingBlock: 5,
          color: "white",
          overflowY: "auto",
        }}
      >
        <Typography sx={{ marginBottom: 2 }}>{course.question}</Typography>
      </Box>
      <div id="extension-preview-image">
        <ImageUpload />
      </div>
      <ExtensionPreviewChallengeFooter setPage={setPage} />
    </>
  )
}
