import Plus from "@mobilemind/common/src/icons/Plus"
import yup from "@mobilemind/common/src/utility/yup"
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { createNextState } from "@reduxjs/toolkit"
import { useFormikContext } from "formik"
import { useState } from "react"
import "react-quill/dist/quill.snow.css"
import { useAppSelector } from "store/hooks"
import ButtonSmall from "../../../components/ButtonSmall"
import "../../../styles/external-pd.scss"
import UserSelect from "../../userSelect/UserSelect"
import { ParticipantList } from "./ParticipantList"
import { ExternalEvent } from "./RecommendedEventModal"

export const validationSchema3 = yup.object({
  participantMethod: yup.string().required().oneOf(["all", "custom"]),
  subGroups: yup
    .mixed()
    .when("participantMethod", ([participantMethod], schema) => {
      if (participantMethod === "custom") {
        return yup.array()
      }
      return schema.strip()
    }),
  jobTitles: yup
    .mixed()
    .when("participantMethod", ([participantMethod], schema) => {
      if (participantMethod === "custom") {
        return yup.array()
      }
      return schema.strip()
    }),
})

export const ExternalEventStep3 = () => {
  const { setFieldValue, setValues, values } = useFormikContext<ExternalEvent>()
  const [isUserSelectOpen, openUserSelect] = useState(false)
  const session = useAppSelector((state) => state.session)

  let groupLabel = session.group.label[0].value

  // If we're group level
  if (
    (session.isGroupAdmin || session.groupRoles.join().includes("scheduler")) &&
    !session.orgRoles.join().includes("admin") &&
    !session.orgRoles.join().includes("scheduler")
  ) {
    groupLabel = session.subgroup.label[0].value
  }

  return (
    <section>
      <header>Participants</header>
      <span className="helperText">
        Specify who should be able to request credit for this event.
      </span>

      <FormControl variant="standard">
        <RadioGroup
          value={values.participantMethod}
          onChange={(event) =>
            setFieldValue("participantMethod", event.target.value)
          }
        >
          <FormControlLabel
            value={"all"}
            control={<Radio />}
            label={"All " + groupLabel + " Learners"}
          />
          <FormControlLabel
            value={"custom"}
            control={<Radio />}
            label={"Custom List"}
          />
        </RadioGroup>
      </FormControl>

      {values.participantMethod !== "all" && <ParticipantList />}

      {values.participantMethod !== "all" && (
        <ButtonSmall
          onClick={() => {
            openUserSelect(true)
          }}
        >
          <Plus />
          <span style={{ paddingLeft: 5 }}>Add Participants</span>
        </ButtonSmall>
      )}

      {
        <UserSelect
          open={isUserSelectOpen}
          entity={values}
          update={(args: unknown) => () => {
            // @ts-ignore
            const { field, type, value } = args
            setValues(
              createNextState((draft) => {
                if (field === "subGroups") {
                  if (type === "add") {
                    draft.subGroups.push(value)
                  } else {
                    draft.subGroups = draft.subGroups.filter(
                      (existing) => existing.entity_id !== value.entity_id
                    )
                  }
                }
                if (field === "jobTitles") {
                  if (type === "add") {
                    draft.jobTitles.push(value)
                  } else {
                    draft.jobTitles = draft.jobTitles.filter(
                      (existing) =>
                        existing.entity_id !==
                        value.attributes.drupal_internal__tid
                    )
                  }
                }
              })
            )
          }}
          onClose={() => openUserSelect(false)}
          visibleTabs={"groups, job-titles"}
          userLabel={"Learners"}
          searchText={
            "Search for reviewers to allow them to review this course."
          }
        />
      }
    </section>
  )
}
