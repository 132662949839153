import { Box, Typography } from "@mui/material"

import { CourseFormValues } from "../../types"

import { CategoryIcon } from "@mobilemind/common/src/components/CategoryIcon"
import { SkillLevel } from "@mobilemind/common/src/types/course"

import Star from "../../../../img/star-white.svg"
import Clock from "../../../../img/clock-white.svg"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
type ModalProps = {
  course: CourseFormValues
}

export const MobileAppCourseHeader = ({ course }: ModalProps) => {
  const estimatedTime = convertMinutesToHoursMinutes(course.estimatedTime)

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          paddingInline: 8,
          paddingBlock: 5,
          backgroundColor: course.field_category?.attributes?.field_hex_color,
        }}
      >
        <Box
          sx={{
            width: 150,
            height: 150,
            boxShadow: 1,
            borderRadius: 150,
            position: "absolute",
            opacity: 0.2,
            top: -30,
            backgroundSize: "cover",
            backgroundImage:
              "url(" +
              process.env.REACT_APP_API_URL +
              course.field_category?.image?.attributes?.uri.url +
              ")",
          }}
        ></Box>
        <Box
          sx={{
            borderRadius: 5,
            width: 18,
            height: 18,
            border: "2px solid white",
            boxShadow: 1,
            position: "absolute",
            left: 18,
            top: 8,
            backgroundSize: "cover",
            backgroundImage:
              "url(" +
              process.env.REACT_APP_API_URL +
              course.field_category?.image?.attributes?.uri.url +
              ")",
          }}
        />

        <Typography
          variant="h1"
          color="white"
          align="center"
          sx={{
            ...(!course.name && { opacity: 0.5 }),
            zIndex: 1,
            fontSize: 14,
            marginBottom: -1,
            marginTop: 1,
            textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          {course.name || "Course Title"}
        </Typography>
      </Box>

      <Box
        sx={{
          position: "relative",
          color: "white",
          backgroundColor: "black",
          paddingBlock: 1,
          paddingInline: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
          borderRadius: 4,
          marginTop: "-15px",
          marginBottom: -2,
          marginInline: "auto",
          width: "max-content",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          {course.field_category && course.field_category.image && (
            <CategoryIcon
              size="small"
              src={
                process.env.REACT_APP_API_URL +
                course.field_category.image.attributes.uri.url
              }
            />
          )}
          <Typography sx={{ fontSize: 12 }}>
            {course.field_category ? (
              <> {course.field_category.attributes.name} </>
            ) : (
              <>No Category</>
            )}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img src={Clock} alt="Clock" width={13} height={13} />
          {estimatedTime && (
            <Typography sx={{ fontSize: 12 }}>
              {estimatedTime.minutes}m
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <img src={Star} alt="Star" width={12} height={12} />
          {course.level === SkillLevel.Intermediate && (
            <img src={Star} alt="Star" width={12} height={12} />
          )}
          {course.level === SkillLevel.Advanced && (
            <img src={Star} alt="Star" width={12} height={12} />
          )}
        </Box>
      </Box>
    </>
  )
}
