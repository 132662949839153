import { useAppSelector } from "store/hooks"
import GenieIcon from "../../../img/genie.svg"
import { selectCourseGenieProgress } from "../store/courseGenieSlice"
import Loading from "@mobilemind/common/src/components/Loading"
import { Backdrop, Box, LinearProgress } from "@mui/material"
import { useMemo } from "react"

export const GenieLoading = () => {
  const progress = useAppSelector(selectCourseGenieProgress)

  const value = useMemo(() => {
    if (progress?.tick && progress?.numTicks) {
      return Math.min((progress.tick / progress.numTicks) * 100, 100)
    }
  }, [progress?.tick, progress?.numTicks])

  return (
    <>
      <Backdrop
        open={!!progress}
        sx={(theme) => ({
          // @ts-ignore
          ...theme.mixins.backdropBlur,
          zIndex: 999,
        })}
      />
      {progress?.message && (
        <Loading
          fullPage={true}
          hideSpinner={true}
          message={
            <Box
              sx={{
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                minWidth: 200,
              }}
            >
              <img
                className="floating"
                alt="Genie"
                src={GenieIcon}
                width={50}
                height={50}
              />
              <Box sx={{ marginTop: 4 }}>{progress?.message}</Box>
              {/* Only show the progress bar if we know how many steps there will be */}
              {progress && (
                <LinearProgress
                  sx={{
                    width: "100%",
                    marginTop: 2,
                    borderRadius: 1,
                    height: 6,
                  }}
                  color="secondary"
                  variant="determinate"
                  value={value}
                />
              )}
            </Box>
          }
          sx={{ zIndex: "1000!important" }}
        />
      )}
    </>
  )
}
