import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"

export const fetchQuickLinks = createAsyncThunk(
  "quickLinksSlice/fetchQuickLinks",
  async (args, thunkAPI) => {
    const group = thunkAPI.getState().session.group

    let query = {
      filter: {
        "field_organization.id": group.uuid[0].value,
      },
    }

    let response = await fetchWrapper.get(
      "/api/node/quick_links?" + qs.stringify(query)
    )
    let data = await response.json()
    let quickLinks = data.data[0]
    return quickLinks
  }
)

export const quickLinksSlice = createSlice({
  name: "quickLinksSlice",
  initialState: {
    fetched: false,
    body: "",
    isHidden: false,
  },
  reducers: {
    setBody: (state, action) => {
      state.body = action.payload
    },
    setVisibility: (state, action) => {
      state.isHidden = action.payload
    },
  },
  extraReducers: {
    [fetchQuickLinks.fulfilled]: (state, action) => {
      state.fetched = true
      if (action.payload) {
        state.body = action.payload.attributes.body.processed
        state.id = action.payload.id
        state.isHidden = !action.payload.attributes.field_draft
      }
    },
  },
})

export const { setBody, setVisibility } = quickLinksSlice.actions

export default quickLinksSlice.reducer
