import { connect } from "react-redux"
import NavbarSecondary from "../../components/NavbarSecondary"
import { generateReportExportURL } from "../../functions"

import "../../styles/navbar.scss"

const mapStateToProps = ({ session, teacherAccess }) => {
  return {
    session,
    teacherAccess,
  }
}

function TeacherAccessNavbarContainer(props) {
  let { filters } = props.teacherAccess

  let exportArgs = {
    reportType:
      props.session.orgRoles.includes("organization-admin") ||
      props.session.orgRoles.includes("organization-reporter")
        ? "learner-access"
        : "group-learner-access",
    orgId:
      props.session.orgRoles.includes("organization-admin") ||
      props.session.orgRoles.includes("organization-reporter")
        ? props.session.group.id[0].value
        : props.session.subgroup.id[0].value,
    subGroup: props.session.subGroups.data.find(
      (group) => group.id === filters.group
    ),
    filters,
  }

  const navbarProps = {
    type: "Access",
    iconClass: "icon key",
    onHover: () => {},
    activeItem: {},
    exportLink: generateReportExportURL(exportArgs),
  }

  return <NavbarSecondary {...navbarProps} />
}

export default connect(mapStateToProps)(TeacherAccessNavbarContainer)
