import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import Loading from "@mobilemind/common/src/components/Loading"
import yup from "@mobilemind/common/src/utility/yup"
import { Box, Card, TextField } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { saveCollectionSettings } from "../../actions/partners"
import { RootState } from "../../store/types"
import "../marketplace/marketplace.scss"
import { formats, modules } from "../../constants/reactQuillConfig"

export const CollectionSettingsLayout = () => {
  const collectionInfo = useAppSelector((state: RootState) => {
    return state.session.userCollection
  })

  type CollectionSettings = {
    field_email_subject: string
    field_email_body: string
  }

  const initialValues: CollectionSettings = {
    field_email_subject: collectionInfo[0].field_email_subject[0].value,
    field_email_body: collectionInfo[0].field_email_body[0].value,
  }

  const [isSaving, setIsSaving] = useState(false)

  const dispatch = useAppDispatch()

  const validationSchema = yup.object({
    field_email_subject: yup.string().required(),
    field_email_body: yup.string().required(),
  })

  return (
    <>
      {isSaving && <Loading fullPage={true} message="Saving settings..." />}
      <div
        className="page partner-settings"
        style={{ ...styles.container, opacity: isSaving ? 0.2 : 1 }}
      >
        {initialValues ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setIsSaving(true)
              dispatch(saveCollectionSettings(values)).then(() => {
                setTimeout(() => {
                  setIsSaving(false)
                }, 750)
              })
            }}
          >
            {({ values, setFieldValue, submitForm }) => (
              <Card style={styles.card}>
                <Box
                  component="h2"
                  sx={{
                    marginBottom: 10,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <span className="icon gear-color" style={styles.headerIcon} />
                  {collectionInfo[0].label[0].value} Settings
                </Box>

                <Form>
                  <Field
                    as={TextField}
                    label="Email Subject"
                    id="field_email_subject"
                    name="field_email_subject"
                  />
                  <span style={styles.descriptionLabel}>Email Body</span>

                  <ReactQuill
                    theme={"snow"}
                    onChange={(event) => {
                      setFieldValue("field_email_body", event)
                    }}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    value={values.field_email_body}
                    modules={modules}
                    formats={formats}
                    placeholder={"Email Body"}
                  />
                  <Box
                    component="footer"
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      paddingBottom: 4,
                    }}
                  >
                    <ButtonLarge
                      onClick={() => {
                        submitForm()
                      }}
                    >
                      Save Settings
                    </ButtonLarge>
                  </Box>
                </Form>
              </Card>
            )}
          </Formik>
        ) : (
          <Loading fullPage={true} message="Getting your settings..." />
        )}
      </div>
    </>
  )
}

const styles = {
  container: {
    paddingTop: 75,
    maxWidth: 1030,
    display: "flex",
    flexDirection: "column" as "column",
    margin: "0 auto",
  },
  headerIcon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  form: {
    display: "flex",
    flexDirection: "column" as "column",
    paddingTop: 20,
  },
  descriptionLabel: {
    fontSize: 12,
    position: "absolute" as "absolute",
    display: "block",
    top: 278,
    paddingLeft: 15,
    backgroundColor: "white",
    padding: 5,
    zIndex: 2,
    marginLeft: 9,
  },

  card: {
    padding: 20,
    flex: 1,
    borderRadius: 12,
    marginLeft: 20,
    backgroundColor: "white",
  },
}
