import { LoadingButton } from "@mui/lab"
import { FieldArrayRenderProps } from "formik"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  generateQuizQuestion,
  selectCourseGenieActive,
  showCourseGenieError,
} from "../store/courseGenieSlice"
import { QuizQuestion } from "../types"

export const QuizAddQuestionButton = (props: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch()
  const genieActive = useAppSelector(selectCourseGenieActive)
  const { push } = props
  const [loading, setLoading] = useState<boolean>(false)
  return (
    <LoadingButton
      onClick={async () => {
        if (genieActive) {
          try {
            setLoading(true)
            const question: QuizQuestion = await dispatch(
              generateQuizQuestion()
            ).unwrap()
            push(question)
          } catch {
            dispatch(showCourseGenieError())
          } finally {
            setLoading(false)
          }
        } else {
          const question: QuizQuestion = {
            id: "new",
            value: "",
            requireAllAnswers: false,
            answers: [
              { value: "", isCorrect: true },
              { value: "", isCorrect: false },
            ],
          }
          push(question)
        }
      }}
      variant="contained"
      color="secondary"
      size="large"
      loading={loading}
    >
      Add Question
    </LoadingButton>
  )
}
