import { useAppSelector } from "store/hooks"
import { LineChart } from "@mui/x-charts/LineChart"

import { Box, Card, Typography } from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"
import moment from "moment"

type MonthChartProps = {
  width: number
}

export function YearChart({ width }: MonthChartProps) {
  const analytics = useAppSelector((state) => state.analytics)

  const { dateFrom, dateTo } = analytics.filters
  const { yearData } = analytics

  const yearsArray = []
  const startYear = moment(dateFrom).year()
  const endYear = moment(dateTo).year()

  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year)
  }

  const dataset = yearData.map((datapoint: any) => {
    return {
      year: datapoint.year,
    }
  })
  const seriesData = yearData.map((datapoint: any) => {
    return Number(datapoint.totalScoreAverage)
  })

  return (
    <Card sx={{ display: "inline-flex" }}>
      {yearsArray.length > 1 ? (
        <LineChart
          colors={[theme.palette.secondary.dark]}
          xAxis={[
            {
              label: "Date",

              dataKey: "year",
              valueFormatter: (value) => value.toString(),
              min: startYear,
              max: endYear,
              tickLabelInterval: (value) => !value.toString().includes("."),
            },
          ]}
          series={[
            {
              data: seriesData,
              showMark: false,
            },
          ]}
          yAxis={[{ label: "Average Score" }]}
          grid={{ vertical: true, horizontal: true }}
          dataset={dataset}
          width={width}
          height={600}
        />
      ) : (
        <Box
          sx={{
            padding: 6,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Average Total Score for {yearsArray[0]}:
          </Typography>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.main,
              borderRadius: 1,
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <Typography sx={{ fontSize: 28, fontWeight: "bold" }}>
              {seriesData[0]}
            </Typography>
          </Box>
        </Box>
      )}
    </Card>
  )
}
