import { Component, useEffect, useRef, useState } from "react"
import {
  Button,
  Dialog,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  FormControl,
} from "@mui/material"
import * as htmlToImage from "html-to-image"

import "../../styles/badgeStudio.scss"
import { connect } from "react-redux"

import classNames from "classnames"
import { saveBadge, getOrgBadges } from "../../actions/badges"

import { setImage, setFilename } from "./activeBadgeSlice"
import moment from "moment"
import { allImages, getIconsForPack } from "./BadgeImages"
import { setBadgeName } from "../events/eventSingle/activeEventSlice"
import { setConferenceBadgeName } from "../events/conference/activeConferenceSlice"
import { saveAs } from "file-saver"

import { HexColorPicker, HexColorInput } from "react-colorful"
import { useAppDispatch } from "store/hooks"

const Spinner = require("react-spinkit")

const mapStateToProps = ({ activeBadge }) => {
  return {
    badge: activeBadge,
  }
}

const mapDispatchToProps = {
  setImage,
}

function BadgeStudioContainer(props) {
  const { onClose, sync, hasName, badgeName } = props
  let publicUrl = window.location.href.includes("/badge-studio")
  let { open } = props

  const badgeRef = useRef()
  const dispatch = useAppDispatch()

  const [starColor, setStarColor] = useState("#24DC91")
  const [outerColor, setOuterColor] = useState("#59C3E5")

  const [isSaving, setIsSaving] = useState(false)
  const [search, setSearch] = useState("")

  const [isOuterOpen, setIsOuterOpen] = useState(false)
  const [isStarOpen, setIsStarOpen] = useState(false)

  const [fileSizeExceeded, setFileSizeExceeded] = useState(0)

  const [selectedImage, setSelectedImage] = useState(null)
  const [currentPack, setPack] = useState("education")

  const [currentPackImages, setCurrentPackImages] = useState(
    Object.values(
      importAll(
        require.context("../../img/studio/education", false, /\.(svg)$/)
      )
    )
  )

  async function savePublicBadge() {
    setIsSaving(true)
    let image

    // Convert the DOM node to a PNG
    await htmlToImage.toPng(badgeRef.current).then((dataUrl) => {
      image = dataUrl
    })

    saveAs(image, "Badge created on " + moment().format("M-D-YYYY-h-mmA"))
    setIsSaving(false)
  }

  async function generateImage() {
    setIsSaving(true)
    let image
    let filename = "badgeImage" + moment().unix() + ".png"

    // Convert the DOM node to a PNG
    await htmlToImage.toPng(badgeRef.current).then((dataUrl) => {
      image = dataUrl
    })

    if (sync) {
      let isConference = sync.isConference

      await dispatch(
        saveBadge(
          {
            name: badgeName,
            archived: false,
            image,
            filename,
            subGroups: [],
            jobTitles: [],
            users: [],
          },
          false,
          isConference
        )
      )
      await dispatch(
        getOrgBadges({
          searchQuery: "",
          status: "any",
          category: null,
          subGroup: "any",
          jobTitle: "any",
          sortBy: "created",
          sortOrder: "DESC",
        })
      )
    } else {
      dispatch(setImage(image))
      dispatch(setFilename(filename))
    }

    props.onClose()
    setIsSaving(false)
  }

  function importAll(r) {
    let images = {}
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item)
    })
    return images
  }

  useEffect(() => {
    if (search.length) {
      setCurrentPackImages(
        allImages.filter((image) =>
          image.toLowerCase().includes(search.toLowerCase())
        )
      )
    } else if (currentPack) {
      setCurrentPackImages(getIconsForPack({ currentPack }))
    } else {
      setPack("all")
    }
  }, [search, currentPack])

  function uploadImage(file) {
    let uploaded = file[0] ? file[0] : file.target.files[0]
    if (uploaded) {
      // Limit to 10MB
      if (uploaded.size <= 10000000) {
        setFileSizeExceeded(0)
        // If it's an image
        if (uploaded.type.indexOf("image") > -1) {
          // Read the file
          let reader = new FileReader()
          reader.readAsDataURL(uploaded)

          // Once we're done, get the file and name it
          reader.onloadend = async (event) => {
            setSelectedImage(event.target.result)
            let filename = new Date().getTime() + "-" + uploaded.name
            dispatch(setFilename(filename))
          }
        }
      } else {
        setFileSizeExceeded(1)
        setTimeout(() => {
          setFileSizeExceeded(0)
        }, 4000)
      }
    }
  }

  if (publicUrl) {
    open = true
  }

  return (
    <Dialog
      transitionDuration={0}
      className={classNames(
        "modal badgeStudioContainer",
        publicUrl && "fullScreen"
      )}
      onClose={() => onClose()}
      open={open}
    >
      {isSaving && (
        <div className="spinner">
          <Spinner className="spinner-inner" name="pulse" color={"#24DC91"} />
          <strong>Generating Badge...</strong>
        </div>
      )}

      <header className="studio-header">
        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <span className="icon chair" />
          Badge Studio
        </div>
        {!publicUrl && (
          <span onClick={() => onClose()} className="icon close" />
        )}
      </header>

      <div className="colorsContainer">
        <div className={classNames("picker", isOuterOpen && "active")}>
          <HexColorPicker color={outerColor} onChange={setOuterColor} />
          <div className="hexPicker">
            <span className="hash">#</span>
            <HexColorInput color={outerColor} onChange={setOuterColor} />
          </div>
        </div>

        <div className={classNames("picker", isStarOpen && "active")}>
          <HexColorPicker color={starColor} onChange={setStarColor} />
          <div className="hexPicker">
            <span className="hash">#</span>
            <HexColorInput color={starColor} onChange={setStarColor} />
          </div>
        </div>
      </div>

      <div className={classNames("inner", isSaving && "blur")}>
        <div className="badgeContainer">
          {hasName && (
            <div className="badgeNameContainer">
              <TextField
                variant="standard"
                className="badgeName"
                value={badgeName}
                label="Badge Name"
                required
                onChange={(event) => {
                  if (sync && sync.isConference) {
                    dispatch(setConferenceBadgeName(event.target.value))
                  } else {
                    dispatch(setBadgeName(event.target.value))
                  }
                }}
              />
            </div>
          )}

          <div
            ref={badgeRef}
            style={{ position: "relative", width: 300, height: 300 }}
          >
            {selectedImage && (
              <div className="selectedImageContainer">
                {selectedImage.includes("data:") ? (
                  <div
                    className="customImage"
                    style={{
                      backgroundImage: "url(" + selectedImage + ")",
                      width: 165,
                      height: 165,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                ) : (
                  <img
                    alt={selectedImage}
                    className={"selectedImage"}
                    src={selectedImage}
                  />
                )}
              </div>
            )}
            <div className="badge-star">
              <svg width="65px" height="65px" viewBox="0 0 74 71">
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Artboard"
                    transform="translate(-1690.000000, -1114.000000)"
                    fill={starColor}
                    fillRule="nonzero"
                    stroke="#000000"
                    strokeWidth="7"
                  >
                    <path
                      onClick={() => {
                        setIsStarOpen(true)
                        setIsOuterOpen(false)
                      }}
                      d="M1726.99332,1117.5 C1727.37147,1117.49992 1727.75169,1117.59665 1728.08137,1117.80132 C1728.38339,1117.98882 1728.63569,1118.26923 1728.81354,1118.62908 L1728.81354,1118.62908 L1738.06975,1137.35888 L1758.7567,1140.35677 C1759.15482,1140.41449 1759.50034,1140.56749 1759.77265,1140.79679 C1760.06873,1141.0461 1760.27808,1141.37658 1760.39474,1141.73508 C1760.51126,1142.09312 1760.53659,1142.48294 1760.444,1142.85812 C1760.35894,1143.20278 1760.16998,1143.52877 1759.88253,1143.80886 L1759.88253,1143.80886 L1744.91602,1158.39405 L1748.45545,1178.98336 C1748.52336,1179.37824 1748.4846,1179.75262 1748.35098,1180.08117 C1748.20517,1180.4397 1747.95509,1180.7407 1747.64989,1180.96242 C1747.34402,1181.18463 1746.97949,1181.3296 1746.59242,1181.3577 C1746.23718,1181.38349 1745.86748,1181.3047 1745.51154,1181.11786 L1745.51154,1181.11786 L1727.00745,1171.40277 L1708.50871,1181.12769 C1708.15256,1181.31491 1707.7829,1181.39395 1707.42762,1181.36839 C1707.04061,1181.34054 1706.67607,1181.19581 1706.37014,1180.97384 C1706.06478,1180.75228 1705.81451,1180.45138 1705.66846,1180.09291 C1705.53463,1179.76442 1705.49563,1179.39004 1705.5633,1178.99517 L1705.5633,1178.99517 L1709.09207,1158.40385 L1694.11809,1143.82635 C1693.83061,1143.54646 1693.64147,1143.2205 1693.55624,1142.87583 C1693.46345,1142.50063 1693.48859,1142.11074 1693.60494,1141.75257 C1693.72144,1141.39393 1693.93066,1141.06327 1694.22665,1140.81373 C1694.49886,1140.58424 1694.84431,1140.431 1695.242,1140.3731 L1695.242,1140.3731 L1715.92753,1137.36383 L1725.17378,1118.62974 C1725.35144,1118.26982 1725.60357,1117.98935 1725.90545,1117.80175 C1726.23502,1117.59696 1726.61518,1117.50008 1726.99332,1117.5 Z"
                      id="star"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>

            <div className="badge-top-ring">
              <svg width="222px" height="245px" viewBox="0 0 253 245">
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Artboard"
                    transform="translate(-1609.000000, -1293.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                    stroke="#FFFFFF"
                    strokeWidth="10"
                  >
                    <g
                      id="Group-3"
                      transform="translate(1614.964072, 1298.000000)"
                    >
                      <path
                        d="M121,234.964072 C187.719976,234.964072 242,180.612979 242,113.805646 C242,64.5643621 212.633492,20.5953925 167.185508,1.78891246 C162.280206,-0.241933525 156.667151,2.09469325 154.642802,7.00257106 C152.617492,11.9104489 154.948183,17.5337383 159.849643,19.5616996 C198.086603,35.384413 222.793651,72.378119 222.793651,113.805646 C222.793651,170.008732 177.129595,215.732576 121,215.732576 C64.8704048,215.732576 19.2063492,170.008732 19.2063492,113.805646 C19.2063492,72.2790768 43.9940635,35.2526773 82.3558651,19.477081 C87.262127,17.4596971 89.6062619,11.8412155 87.5915159,6.92949138 C85.5758095,2.01680569 79.9665952,-0.329436833 75.0603333,1.68698554 C29.4625397,20.4386558 0,64.44705 0,113.805646 C0,180.612979 54.2809841,234.964072 121,234.964072 Z"
                        id="XMLID_887_"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <div className="badge-bottom-layer">
              <svg width="300px" height="300px" viewBox="0 0 350 350">
                <title>Group 9 Copy 19</title>
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Artboard"
                    transform="translate(-1237.000000, -996.000000)"
                  >
                    <g
                      id="Group-9-Copy-19"
                      transform="translate(1237.000000, 996.000000)"
                    >
                      <circle
                        onClick={() => {
                          setIsOuterOpen(true)
                          setIsStarOpen(false)
                        }}
                        id="outerCircle"
                        fill={outerColor}
                        fillRule="nonzero"
                        cx="174.664671"
                        cy="174.664671"
                        r="167.664671"
                      ></circle>
                      <circle
                        id="XMLID_1458_"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                        cx="175"
                        cy="175"
                        r="121.556886"
                      ></circle>
                      <circle
                        id="XMLID_1458_"
                        fill={starColor}
                        fillRule="nonzero"
                        opacity="0.25"
                        cx="175"
                        cy="175"
                        r="121.556886"
                      ></circle>
                      <g
                        id="XMLID_212_"
                        transform="translate(115.964072, 124.964072)"
                      ></g>
                      <circle
                        id="Oval-2"
                        stroke="#000000"
                        strokeWidth="13"
                        cx="175"
                        cy="175"
                        r="168.5"
                      ></circle>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>

          <div className="content">
            <p style={{ fontSize: 14 }}>
              <span className="icon palette" />
              Click the <strong>outer ring</strong> or the <strong>star</strong>{" "}
              to adjust the colors of your badge.
            </p>

            <Button
              style={
                hasName && !badgeName
                  ? { pointerEvents: "none", opacity: 0.5 }
                  : {}
              }
              onClick={() => {
                setIsOuterOpen(false)
                setIsStarOpen(false)
                if (publicUrl) {
                  savePublicBadge()
                } else {
                  generateImage()
                }
              }}
              className={classNames("button primary active")}
            >
              {publicUrl ? (
                <>
                  <span className="icon download" />
                  Download
                </>
              ) : (
                <>
                  <span className="icon diskette" />
                  Save and Use
                </>
              )}
            </Button>

            <p style={{ marginTop: 0, fontSize: 14 }}>
              You can also upload your own image. For best results, we recommend
              a square image at least 350px by 350px.
            </p>
            <div className={classNames("fileUpload")}>
              <Button className={classNames("button primary")}>
                Upload Image
              </Button>
              <input
                onChange={(event) => {
                  uploadImage(event)
                }}
                type="file"
              />
            </div>

            <p
              style={{
                margin: 0,
                fontSize: 14,
                color: "#DC5959",
                fontWeight: "bold",
              }}
              className={classNames(
                "exceededText",
                fileSizeExceeded && "active"
              )}
            >
              Uh oh, it looks like that file is too large. Try again with a
              smaller file.
            </p>
          </div>
        </div>

        <div
          onClick={() => {
            setIsOuterOpen(false)
            setIsStarOpen(false)
          }}
          className="studioContainer"
        >
          <div className="column right">
            <div className="flexRow header-container">
              <FormControl
                variant="standard"
                className="inputSelect collection"
              >
                <InputLabel id="label-collection-select">
                  Image Collection
                </InputLabel>
                <Select
                  variant="standard"
                  labelId="label-collection-select"
                  id="collection-select"
                  onChange={(event) => {
                    setSearch("")
                    setPack(event.target.value)
                  }}
                  value={currentPack}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"education"}>Education</MenuItem>
                  <MenuItem value={"mentoring-training"}>
                    Mentoring & Training
                  </MenuItem>
                  <MenuItem value={"teamwork"}>Teamwork</MenuItem>
                  <MenuItem value={"rewards-badges"}>Rewards & Badges</MenuItem>
                  <MenuItem value={"office"}>Office & Organization</MenuItem>
                  <MenuItem value={"animals"}>Animals</MenuItem>
                  <MenuItem value={"food"}>Food</MenuItem>
                  <MenuItem value={"holidays"}>Holidays</MenuItem>
                  <MenuItem value={"nature"}>Nature</MenuItem>
                  <MenuItem value={"letters"}>Letters</MenuItem>
                  <MenuItem value={"numbers"}>
                    Numbers & Special Characters
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                variant="standard"
                label="Image Search"
                value={search}
                inputProps={{ maxLength: 50 }}
                onChange={(event) => {
                  setPack("all")
                  setSearch(event.target.value)
                }}
              />
            </div>

            <div className="image-grid">
              <ul>
                {currentPackImages.map((image, index) => {
                  return (
                    <li
                      key={index}
                      className={classNames(
                        selectedImage === image && "active"
                      )}
                      onClick={() => setSelectedImage(image)}
                    >
                      <img alt={image} src={image} />
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

class BadgeStudio extends Component {
  render() {
    return <BadgeStudioContainer {...this.props} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgeStudio)
