import { useGetJobTitlesQuery } from "@mobilemind/common/src/api/taxonomyApi"
import Loading from "@mobilemind/common/src/components/Loading"
import { JobTitle } from "@mobilemind/common/src/types/taxonomy/jobTitle"
import { List, ListItem } from "@mui/material"
import classNames from "classnames"
import { isArray, isString } from "lodash"

type UserSelectJobTitlesProps = {
  onAdd: (value: JobTitle) => void
  onRemove: (value: JobTitle) => void
  value: JobTitle[] | "all"
}

export const UserSelectJobTitles = (props: UserSelectJobTitlesProps) => {
  const { isLoading, data: jobTitles } = useGetJobTitlesQuery()

  if (isLoading) {
    return <Loading message="Getting job titles..." sx={{ margin: 4 }} />
  }

  return (
    <List className="event-select-list">
      {jobTitles
        ?.filter((jobTitle) => !!jobTitle.relationships.parent)
        .map((jobTitle, index) => {
          const isActive = isArray(props.value)
            ? props.value.some((item) => item.id === jobTitle.id)
            : isString(props.value) && props.value === "all"
          return (
            <ListItem
              style={{ animationDelay: index * 0.05 + "s" }}
              onClick={() => {
                if (isActive) {
                  props.onRemove(jobTitle)
                } else {
                  props.onAdd(jobTitle)
                }
              }}
              key={jobTitle.id}
              className={classNames("event-select-item", isActive && "active")}
            >
              <div className="flexRow">
                <span
                  className={classNames(
                    "icon role",
                    jobTitle.attributes.name.toLowerCase()
                  )}
                />
                <strong>{jobTitle.attributes.name}</strong>
              </div>
            </ListItem>
          )
        })}
    </List>
  )
}
