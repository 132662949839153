import theme from "@mobilemind/common/src/theme/theme"
import { Card } from "@mui/material"
//import classNames from "classnames"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ButtonSmall from "../../components/ButtonSmall"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { getChallenges } from "../../store/reducers/dashboard"
import type { RootState } from "../../store/types"
import { setIsViewByCourseOpen } from "../review/reviewSlice"

const WidgetChallenges = () => {
  const dashboard = useAppSelector((state: RootState) => state.dashboard)
  const session = useAppSelector((state: RootState) => state.session)

  const { challenges } = dashboard.widgets
  const { isOrgAdmin } = session
  const dispatch = useAppDispatch()
  const [isFetching, setFetching] = useState(false)

  useEffect(() => {
    if (!challenges.fetched && !isFetching) {
      setFetching(true)
      dispatch(getChallenges({}))
    }
  }, [challenges.fetched, isFetching, dispatch])

  return (
    <Card className="dashboard-widget widget-challenges">
      {
        <>
          <header
            style={{
              color: "black",
              position: "relative",
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            Challenges to Review
          </header>
          <div className="inner" style={{ padding: 0 }}>
            {challenges.fetched && (
              <Link className="challenge-widget-inner" to="/review">
                {challenges.total > 0 ? (
                  <>
                    <span className="icon review" />

                    <span
                      style={{
                        fontSize: 24,
                        color: theme.palette.primary.main,
                        marginLeft: 5,
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                      className="total"
                    >
                      {challenges.total}
                    </span>

                    <strong style={{ fontSize: 14 }}>
                      Submitted Challenges
                    </strong>
                  </>
                ) : (
                  <>
                    <span className="icon review" />
                    <strong style={{ fontSize: 14, marginLeft: 5 }}>
                      You're all caught up!
                    </strong>
                  </>
                )}
              </Link>
            )}

            <footer>
              <Link to="/review">
                <ButtonSmall>Start Reviewing</ButtonSmall>
              </Link>
              {isOrgAdmin && (
                <Link
                  onClick={() => {
                    dispatch(setIsViewByCourseOpen(true))
                  }}
                  to="/review"
                >
                  <ButtonSmall>View by Course</ButtonSmall>
                </Link>
              )}
            </footer>
          </div>
        </>
      }{" "}
    </Card>
  )
}

export default WidgetChallenges
