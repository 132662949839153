import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { PartnerMarketplaceItem } from "./PartnerMarketplaceItem"
import { SidebarMarketplace } from "./SidebarMarketplace"
import { RootState } from "../../store/types"
import { PartnerSingleModal } from "./PartnerSingleModal"
import { useEffect, useState } from "react"

import { MarketplaceItem } from "./types"
import Loading from "@mobilemind/common/src/components/Loading"
import { SubGroup } from "@mobilemind/common/src/types/groups"
import "./marketplace.scss"
import { getSubGroups } from "../../store/reducers/session"
import { Masonry } from "@mui/lab"

export const PartnerMarketplaceLayout = () => {
  const marketplace = useAppSelector((state: RootState) => {
    return state.marketplace
  })
  const { subGroups } = useAppSelector((state: RootState) => {
    return state.session
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPartner, setSelectedPartner] =
    useState<MarketplaceItem | null>(null)

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!subGroups.fetched) {
      dispatch(getSubGroups({}))
    }
  }, [subGroups.fetched, dispatch])

  function getPartnerSubGroups(partner: MarketplaceItem) {
    const partnerGroups: SubGroup[] = subGroups.data.filter(
      (subGroup: SubGroup) => {
        const partnerData =
          subGroup.field_partner && subGroup.field_partner.data
        let hasPartner
        if (partnerData && partnerData.length) {
          hasPartner = partnerData.find((p: any) => {
            return partner && p.id === partner.uuid
          })
        }

        return hasPartner
      }
    )
    return partnerGroups
  }

  const selectedPartnerSubGroups = selectedPartner
    ? getPartnerSubGroups(selectedPartner)
    : []

  return (
    <div className="page hasSidebar marketplace" style={{ maxWidth: 1024 }}>
      <SidebarMarketplace />

      <PartnerSingleModal
        open={isModalOpen}
        partner={selectedPartner}
        subGroupsWithPartner={selectedPartnerSubGroups}
        onClose={() => setIsModalOpen(false)}
      />

      <h2 style={{ marginBottom: 20 }}>
        <span
          className={"icon puzzle-pieces"}
          style={{ width: 30, height: 30, marginRight: 10 }}
        />
        ReadyMade Marketplace
      </h2>
      {marketplace.fetched ? (
        <Masonry columns={3} spacing={3}>
          {marketplace.data.map((partner: MarketplaceItem, index: number) => {
            return (
              <PartnerMarketplaceItem
                key={index}
                selectedGroups={getPartnerSubGroups(partner)}
                onClick={() => {
                  setSelectedPartner(partner)
                  setIsModalOpen(true)
                }}
                index={index}
                partner={partner}
              />
            )
          })}
          {marketplace.data.length < 1 && (
            <p>
              <strong>No partners to display.</strong>
            </p>
          )}
        </Masonry>
      ) : (
        <Loading message="Getting marketplace..." />
      )}
    </div>
  )
}
