import { Link } from "react-router-dom"

import { Dialog, Button } from "@mui/material"
import "../styles/modal.scss"

function ModalDelete(props) {
  const { onClose, open, type, deleteItem, path } = props

  const handleClose = () => {
    onClose()
  }

  const confirm = () => {
    deleteItem()
  }

  return (
    <Dialog
      className="modal"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <p>Are you sure you want to delete this {type}?</p>
      <footer>
        <Button onClick={handleClose} className="button small cancel">
          Cancel
        </Button>
        {path ? (
          <Link to={path}>
            <Button onClick={confirm} className="button small delete">
              Yes, Delete {type}
            </Button>
          </Link>
        ) : (
          <Button onClick={confirm} className="button small delete">
            Yes, Delete {type}
          </Button>
        )}
      </footer>
    </Dialog>
  )
}

export default ModalDelete
