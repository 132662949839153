import Loading from "@mobilemind/common/src/components/Loading"
import { useState } from "react"
import { useAppSelector } from "store/hooks"

import { SubGroup } from "@mobilemind/common/src/types/groups"
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import BottomScrollListener from "react-bottom-scroll-listener"
import { useAppDispatch } from "../../../store/hooks"
import {
  increaseRequestPage,
  setCurrentAttachment,
  updateFilters,
} from "../../../store/reducers/externalPD"
import RequestCard from "./RequestCard"

interface PendingProps {
  status: string
}

const RequestList = ({ status }: PendingProps) => {
  const { pending, reviewed, currentAttachment } = useAppSelector(
    (state) => state.externalPD
  )
  const session = useAppSelector((state) => state.session)
  const categories = useAppSelector((state) => state.calendar.categories)

  const activeTabItem = status === "reviewed" ? reviewed : pending
  const pendingList = status === "reviewed" ? reviewed.data : pending.data
  const activeFilters =
    status === "reviewed" ? reviewed.filters : pending.filters

  const [activeItem, setActiveItem] = useState("")
  const dispatch = useAppDispatch()

  function handleScroll() {
    const { totalPages, currentPage } = activeTabItem

    if (currentPage < totalPages - 1) {
      dispatch(increaseRequestPage({ status, nextPage: currentPage + 1 }))
    }
  }

  return (
    <BottomScrollListener onBottom={handleScroll}>
      <div className="page events-layout recommended-events">
        <Dialog
          id="xpd-attachment-modal"
          open={currentAttachment ? true : false}
          onClose={() => dispatch(setCurrentAttachment(null))}
        >
          {currentAttachment && (
            <div className="modal">
              {(currentAttachment.extension === "png" ||
                currentAttachment.extension === "jpg" ||
                currentAttachment.extension === "jpeg") && (
                <img
                  src={currentAttachment.file}
                  alt={currentAttachment.filename}
                />
              )}
            </div>
          )}
        </Dialog>

        <h2>
          <div style={{ textTransform: "capitalize", flex: 1 }}>
            {status} Requests
          </div>
          {!activeTabItem.fetched && (
            <Loading
              message={"Getting " + status.toLowerCase() + " requests..."}
            />
          )}
        </h2>

        <div style={{ display: "flex" }} className={"request-filters"}>
          <FormControl variant="standard" className="textSingle">
            <TextField
              variant="standard"
              label="Search"
              style={{ maxWidth: 250 }}
              value={activeFilters.search}
              onChange={(event) => {
                dispatch(
                  updateFilters({
                    status,
                    field: "search",
                    value: event.target.value,
                  })
                )
              }}
            />
          </FormControl>

          {(session.orgRoles.includes("organization-admin") ||
            session.orgRoles.includes("organization-reporter")) && (
            <FormControl
              style={{ marginLeft: 20 }}
              variant="standard"
              className="inputSelect"
            >
              <InputLabel id="label-subgroup-select">Group</InputLabel>
              <Select
                variant="standard"
                style={{ minWidth: 100 }}
                labelId="label-subgroup-select"
                id="subgroup-select"
                value={activeFilters.subgroup}
                onChange={(event) => {
                  dispatch(
                    updateFilters({
                      status,
                      field: "subgroup",
                      value: event.target.value,
                    })
                  )
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                {session.subGroups.data &&
                  session.subGroups.data.map((group: SubGroup) => {
                    return (
                      <MenuItem key={group.id} value={group.entity_id}>
                        {group.attributes.label}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          )}
          <FormControl
            variant="standard"
            style={{ marginLeft: 20, marginRight: 20 }}
            className="inputSelect"
          >
            <InputLabel id="label-category-select">Category</InputLabel>
            <Select
              variant="standard"
              style={{ minWidth: 100 }}
              labelId="label-category-select"
              id="category-select"
              value={activeFilters.category}
              onChange={(event) => {
                dispatch(
                  updateFilters({
                    status,
                    field: "category",
                    value: event.target.value,
                  })
                )
              }}
            >
              <MenuItem value={"any"}>Any</MenuItem>
              {categories.data.map((category: any) => {
                return (
                  <MenuItem
                    key={category.id}
                    value={category.attributes.drupal_internal__tid}
                  >
                    {category.attributes.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <FormControl
            style={{ minWidth: 250 }}
            variant="standard"
            className="inputSelect"
          >
            <InputLabel id="label-sort-select">Sort</InputLabel>
            <Select
              variant="standard"
              style={{ minWidth: 100 }}
              labelId="label-sort-select"
              id="sort-select"
              value={activeFilters.sort}
              onChange={(event) => {
                dispatch(
                  updateFilters({
                    status,
                    field: "sort",
                    value: event.target.value,
                  })
                )
              }}
            >
              <MenuItem value={"changed-newest"}>
                Date{" "}
                {status === "pending" ? (
                  <>Submitted (Newest First)</>
                ) : (
                  <>Reviewed (Newest First)</>
                )}
              </MenuItem>
              <MenuItem value={"changed-oldest"}>
                Date{" "}
                {status === "pending" ? (
                  <>Submitted (Oldest First)</>
                ) : (
                  <>Reviewed (Oldest First)</>
                )}
              </MenuItem>
              <MenuItem value={"title"}>Title</MenuItem>
              <MenuItem value={"total_time"}>Total Time</MenuItem>
              <MenuItem value={"category"}>Category</MenuItem>
              <MenuItem value={"first_name"}>Requester First Name</MenuItem>
              <MenuItem value={"last_name"}>Requester Last Name</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="pending-list">
          <ul>
            {pendingList.map((event: any, index: number) => {
              return (
                <RequestCard
                  key={index}
                  activeItem={activeItem}
                  onClick={() => {
                    event.id === activeItem
                      ? setActiveItem("")
                      : setActiveItem(event.id)
                  }}
                  animationDelay={index}
                  event={event}
                />
              )
            })}
            {pendingList.length < 1 && (
              <>
                {activeFilters.search || activeFilters.category !== "any" ? (
                  <em>No requests match your criteria.</em>
                ) : (
                  <em>You don't have any {status} requests.</em>
                )}
              </>
            )}
          </ul>
          <footer>
            {activeTabItem.isFetchingMore && (
              <Loading message={"Getting more requests..."} />
            )}
          </footer>
        </div>
      </div>
    </BottomScrollListener>
  )
}

export default RequestList
