import { Component, useState, useEffect } from "react"
import { connect } from "react-redux"
import {
  Button,
  FormControl,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"

import Loading from "@mobilemind/common/src/components/Loading"

import {
  setEditingLocation,
  updateEditingLocation,
  updateLocationSearch,
  resetState,
  cancelEdit,
} from "./locationsSlice"
import {
  getLocations,
  saveLocation,
  archiveLocation,
  updateLocation,
  archiveRoom,
  getRoomsForLocation,
} from "../../actions/events"
import { addressToString } from "@mobilemind/common/src/functions"
import plusImg from "../../img/plus.svg"
import classNames from "classnames"

import "../../styles/create.scss"
import "../../styles/modal.scss"

import {
  addNewLocation,
  setNewLocation,
  addRoom,
  updateNewLocation,
  updateRoom,
} from "../locations/locationsSlice"

import LocationSearchInput from "../events/eventSingle/LocationSearchInput"
import ErrorBoundary from "../../components/ErrorBoundary"
import { useAppDispatch } from "store/hooks"

const mapStateToProps = ({ locations, rooms }) => {
  return {
    locations,
    rooms,
  }
}

const mapDispatchToProps = {
  getLocations,
  getRoomsForLocation,
  setEditingLocation,
  cancelEdit,
  updateEditingLocation,
  saveLocation,
  updateLocationSearch,
  updateLocation,
  setNewLocation,
  archiveLocation,
}

function LocationsList(props) {
  const { locations } = props
  const { editingLocation } = locations

  const [confirmArchive, setConfirmArchive] = useState(false)
  const [confirmRoomArchive, setConfirmRoomArchive] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [showAddNew, setShowAddNew] = useState(false)
  const [searchQuery, setSearch] = useState("")
  const [roomSearch, setRoomSearch] = useState("")
  const [isSaving, setSaving] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (editingLocation) {
      dispatch(getRoomsForLocation(editingLocation)).then(() => {
        setSaving(false)
      })
    } else {
      setSaving(false)
    }
  }, [editingLocation, dispatch])

  const saveEditingLocation = (editingLocation) => {
    setSaving(true)
    setShowArchived(false)
    setRoomSearch("")

    let current = locations.data.find((loc) => loc.id === editingLocation.id)
    dispatch(updateLocation(editingLocation, true, current.rooms)).then(() => {
      setSaving(false)
    })

    props.endEdit()
  }

  function archive(location) {
    setConfirmArchive(false)
    dispatch(resetState())
    dispatch(archiveLocation(location))
    dispatch(resetState())
  }

  function toggleView(isArchived) {
    dispatch(resetState())
    setShowArchived(isArchived)
  }

  function startAdd() {
    setShowAddNew(true)
    setShowArchived(false)
  }

  function cancelAdd() {
    setShowAddNew(false)
    dispatch(addNewLocation())
    dispatch(resetState())
  }

  async function saveLocation() {
    await props.saveNewLocation(props.locations.newLocation, true)

    setShowAddNew(false)
    dispatch(resetState())
  }

  function promptArchiveRoom(room) {
    if (room) {
      if (confirmRoomArchive !== room.id) {
        setConfirmRoomArchive(room.id)
      } else {
        dispatch(archiveRoom(room))
      }
    } else {
      setConfirmRoomArchive(null)
    }
  }

  function cancelEdit() {
    setRoomSearch("")
    dispatch(props.cancelEdit())
    dispatch(resetState())
  }

  const activeLocations = locations.data.filter(
    (location) =>
      location && (!location.attributes || !location.attributes.field_archive)
  )
  const archivedLocations = locations.data.filter(
    (location) =>
      location && location.attributes && location.attributes.field_archive
  )

  let displayedList = showArchived
    ? archivedLocations.filter((location) =>
        location.attributes.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    : activeLocations.filter((location) =>
        location.attributes.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )

  if (!locations.fetched) {
    return <Loading fullPage={true} message={"Getting locations..."} />
  } else {
    return (
      <div
        className="page manageLocations"
        style={{
          pointerEvents: isSaving ? "none" : "all",
          opacity: isSaving ? 0.6 : 1,
        }}
      >
        {isSaving && <Loading fullPage={true} message="Saving changes..." />}
        <header>
          <div style={{ flex: 1 }} className="flexRow inputSearch">
            <span className="icon search" />
            <TextField
              variant="standard"
              label="Search locations"
              value={searchQuery}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>

          {!showArchived ? (
            <Button
              style={{ height: 36, marginBottom: -15 }}
              onClick={() => {
                toggleView(true)
              }}
              className="button small"
            >
              <span className="icon archive blue" />
              Archived Locations
            </Button>
          ) : (
            <Button
              style={{ height: 36, marginBottom: -15 }}
              onClick={() => {
                toggleView(false)
              }}
              className="button small"
            >
              <span className="icon location blue" />
              Back to Locations
            </Button>
          )}
          <Button
            style={{ height: 36, marginBottom: -15, marginLeft: 25 }}
            onClick={() => {
              startAdd()
            }}
            className="button small"
          >
            <img src={plusImg} alt={"Add Location"} />
            Add Location
          </Button>
        </header>

        {showAddNew && (
          <div className="add-new panel addNew">
            <LocationSearchInput {...props} />

            <FormControl variant="standard">
              <TextField
                variant="standard"
                required
                label="Location Name"
                value={
                  props.locations.newLocation.attributes.name
                    ? props.locations.newLocation.attributes.name
                    : ""
                }
                onChange={(event) =>
                  dispatch(
                    updateNewLocation({
                      field: "name",
                      value: event.target.value,
                    })
                  )
                }
              />
            </FormControl>

            <FormControl variant="standard">
              <TextField
                variant="standard"
                multiline
                label="Additional Instructions"
                value={props.locations.newLocation.attributes.field_directions}
                onChange={(event) =>
                  dispatch(
                    updateNewLocation({
                      field: "field_directions",
                      value: event.target.value,
                    })
                  )
                }
              />
            </FormControl>

            <FormControl variant="standard" style={{ width: 100 }}>
              <TextField
                variant="standard"
                inputProps={{ type: "number", min: 0 }}
                label="Max Capacity"
                className="max-capacity"
                value={props.locations.newLocation.attributes.field_capacity}
                onChange={(event) =>
                  dispatch(
                    updateNewLocation({
                      field: "field_capacity",
                      value: event.target.value,
                    })
                  )
                }
              />
            </FormControl>

            <footer className="flexRow">
              <Button
                onClick={() => {
                  cancelAdd()
                }}
                className="button small cancel"
              >
                Cancel
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                onClick={() => {
                  saveLocation()
                }}
                className={classNames(
                  "button small",
                  !props.locations.newLocation.attributes.name && "disabled"
                )}
              >
                Save
              </Button>
            </footer>
          </div>
        )}

        <ul className="locations-list">
          {displayedList.map((location, index) => {
            const address = location.attributes.field_address

            if (!location.attributes.field_archive || showArchived) {
              return (
                <li
                  onClick={() => {
                    if (
                      !editingLocation ||
                      editingLocation.id !== location.id
                    ) {
                      props.getRoomsForLocation(location)
                      dispatch(setEditingLocation(location))
                    }
                  }}
                  style={{ animationDelay: index * 0.02 + "s" }}
                  key={location.id}
                >
                  {editingLocation && editingLocation.id === location.id ? (
                    <>
                      <div
                        className="flexRow"
                        style={{
                          alignItems: "flex-start",
                          cursor: "pointer",
                          flex: 1,
                        }}
                      >
                        <div className="details">
                          <div className="editing">
                            <FormControl variant="standard">
                              <TextField
                                className="title"
                                variant="standard"
                                required
                                label="Location Name"
                                value={
                                  editingLocation.attributes.name
                                    ? editingLocation.attributes.name
                                    : ""
                                }
                                onChange={(event) =>
                                  dispatch(
                                    updateEditingLocation({
                                      field: "name",
                                      value: event.target.value,
                                    })
                                  )
                                }
                              />
                            </FormControl>

                            <LocationSearchInput editing={true} {...props} />

                            <div className="flexRow">
                              <FormControl
                                variant="standard"
                                style={{ marginTop: 10 }}
                              >
                                <TextField
                                  variant="standard"
                                  multiline
                                  label="Additional Instructions"
                                  value={
                                    editingLocation.attributes.field_directions
                                      ? editingLocation.attributes
                                          .field_directions
                                      : ""
                                  }
                                  onChange={(event) => {
                                    dispatch(
                                      props.updateEditingLocation({
                                        field: "field_directions",
                                        value: event.target.value,
                                      })
                                    )
                                  }}
                                />
                              </FormControl>

                              <FormControl
                                variant="standard"
                                style={{ width: 150, marginLeft: 20 }}
                              >
                                <TextField
                                  variant="standard"
                                  inputProps={{ type: "number", min: 0 }}
                                  label="Max Capacity"
                                  className="max-capacity"
                                  value={
                                    editingLocation.attributes.field_capacity
                                      ? editingLocation.attributes
                                          .field_capacity
                                      : ""
                                  }
                                  onChange={(event) => {
                                    dispatch(
                                      props.updateEditingLocation({
                                        field: "field_capacity",
                                        value: event.target.value,
                                      })
                                    )
                                  }}
                                />
                              </FormControl>
                            </div>
                          </div>

                          <div className="roomEdit">
                            <h3>
                              Rooms
                              <header>
                                <div
                                  style={{ flex: 1 }}
                                  className="flexRow inputSearch"
                                >
                                  <span className="icon search" />
                                  <TextField
                                    variant="standard"
                                    label="Search rooms"
                                    value={roomSearch}
                                    onChange={(event) =>
                                      setRoomSearch(event.target.value)
                                    }
                                  />
                                </div>
                              </header>
                            </h3>

                            {location.rooms &&
                              location.rooms.length > 0 &&
                              location.rooms.map((room) => {
                                if (
                                  !roomSearch ||
                                  room.attributes.name
                                    .toLowerCase()
                                    .includes(roomSearch.toLowerCase())
                                ) {
                                  return (
                                    <div
                                      className={classNames("roomItem")}
                                      key={room.id}
                                    >
                                      {confirmRoomArchive === room.id && (
                                        <div className="archiveConfirm">
                                          <div className="flexRow">
                                            <p>
                                              Are you sure you want to archive
                                              this room?
                                            </p>
                                            <Button
                                              onClick={() => {
                                                promptArchiveRoom()
                                              }}
                                              className="button cancel small"
                                            >
                                              Cancel
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                promptArchiveRoom(room)
                                              }}
                                              className="button small"
                                            >
                                              Yes, Archive
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                      <Accordion>
                                        <AccordionSummary
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <div className="flexRow">
                                            <div style={{ flex: 1 }}>
                                              <span className="icon room color" />
                                              <FormControl
                                                variant="standard"
                                                style={{
                                                  marginTop: 15,
                                                  width: 300,
                                                }}
                                              >
                                                <TextField
                                                  variant="standard"
                                                  multiline
                                                  label="Room Name"
                                                  value={
                                                    room.attributes
                                                      .field_room_number_name
                                                  }
                                                  className="roomName"
                                                  onChange={(event) => {
                                                    dispatch(
                                                      updateRoom({
                                                        room,
                                                        location,
                                                        value:
                                                          event.target.value,
                                                        field:
                                                          "field_room_number_name",
                                                      })
                                                    )
                                                  }}
                                                />
                                              </FormControl>
                                            </div>
                                            {!room.id.includes("newRoom") &&
                                              confirmRoomArchive !==
                                                room.id && (
                                                <Button
                                                  style={{ marginRight: 10 }}
                                                  onClick={() => {
                                                    promptArchiveRoom(room)
                                                  }}
                                                  className="button small"
                                                >
                                                  <span className="icon archive blue" />
                                                  Archive
                                                </Button>
                                              )}
                                          </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <div
                                            className="flexRow-column"
                                            style={{ flex: 1 }}
                                          >
                                            <FormControl
                                              variant="standard"
                                              style={{
                                                width: "90%",
                                                marginTop: 5,
                                                marginLeft: 25,
                                              }}
                                            >
                                              <TextField
                                                variant="standard"
                                                multiline
                                                label="Entry Instructions"
                                                value={
                                                  room.attributes
                                                    .field_entry_instructions
                                                    ? room.attributes
                                                        .field_entry_instructions
                                                        .value
                                                    : ""
                                                }
                                                onChange={(event) => {
                                                  dispatch(
                                                    updateRoom({
                                                      room,
                                                      location,
                                                      value: event.target.value,
                                                      field:
                                                        "field_entry_instructions",
                                                    })
                                                  )
                                                }}
                                              />
                                            </FormControl>
                                            <FormControl
                                              variant="standard"
                                              style={{
                                                position: "absolute",
                                                width: 120,
                                                top: 50,
                                                right: 200,
                                              }}
                                            >
                                              <TextField
                                                variant="standard"
                                                inputProps={{
                                                  type: "number",
                                                  min: 0,
                                                }}
                                                label="Max Capacity"
                                                className="max-capacity"
                                                value={
                                                  room.attributes.field_capacity
                                                    ? room.attributes
                                                        .field_capacity
                                                    : 0
                                                }
                                                onChange={(event) => {
                                                  dispatch(
                                                    updateRoom({
                                                      room,
                                                      location,
                                                      value: event.target.value,
                                                      field: "field_capacity",
                                                    })
                                                  )
                                                }}
                                              />
                                            </FormControl>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )
                                }

                                return null
                              })}
                          </div>

                          <div className="flexRow addButton">
                            <Button
                              style={{ flex: 1 }}
                              onClick={() => {
                                dispatch(addRoom(location))
                              }}
                              className="button small"
                            >
                              <img alt="Add Room" src={plusImg} />
                              Add Room
                            </Button>
                          </div>

                          <footer
                            className="flexRow"
                            style={{ justifyContent: "flex-end" }}
                          >
                            {!showArchived && (
                              <div style={{ flex: 1 }}>
                                {confirmArchive ? (
                                  <em>
                                    Are you sure you want to archive this
                                    location?
                                  </em>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      setConfirmArchive(true)
                                    }}
                                    className="button small"
                                  >
                                    <span className="icon archive blue" />
                                    Archive
                                  </Button>
                                )}
                              </div>
                            )}
                            {confirmArchive ? (
                              <>
                                <Button
                                  onClick={() => {
                                    setConfirmArchive(false)
                                  }}
                                  className="button small cancel"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  style={{ marginLeft: 25 }}
                                  onClick={() => {
                                    archive(location)
                                  }}
                                  className="button small"
                                >
                                  <span className="icon archive blue" />
                                  Archive
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    props.endEdit()
                                    cancelEdit()
                                  }}
                                  className="button small cancel"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  style={{ marginLeft: 25 }}
                                  onClick={() => {
                                    saveEditingLocation(editingLocation)
                                  }}
                                  className={classNames(
                                    "button small",
                                    !editingLocation.attributes.name &&
                                      "disabled"
                                  )}
                                >
                                  Save Changes
                                </Button>
                              </>
                            )}
                          </footer>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flexRow">
                      {showArchived ? (
                        <span className="icon archive blue" />
                      ) : (
                        <span className="icon location color" />
                      )}

                      <div className="details" style={{ marginLeft: 15 }}>
                        <strong>{location.attributes.name}</strong>
                        <span>{address && addressToString(address)}</span>
                      </div>
                    </div>
                  )}
                  {!editingLocation && !showArchived && (
                    <Button
                      onClick={() => {
                        dispatch(setEditingLocation(location))
                      }}
                      className="button small"
                    >
                      Edit
                    </Button>
                  )}
                  {!editingLocation && showArchived && (
                    <Button
                      style={{ marginLeft: 25 }}
                      onClick={() => {
                        saveEditingLocation(location)
                      }}
                      className="button small"
                    >
                      Unarchive
                    </Button>
                  )}
                </li>
              )
            }
            return null
          })}
        </ul>

        {displayedList.length < 1 && searchQuery.length > 0 && (
          <p style={{ maxWidth: 768, margin: "25px auto" }}>
            <em>No locations match your search.</em>
          </p>
        )}
      </div>
    )
  }
}

class ManageLocationsLayout extends Component {
  componentDidMount() {
    this.props.getLocations()
  }

  saveNewLocation = (save) => {
    this.props.saveLocation(this.props.locations.newLocation, true)
  }

  endEdit = () => {
    this.props.cancelEdit()
  }
  render() {
    const props = this.props
    return (
      <ErrorBoundary>
        <LocationsList
          {...props}
          endEdit={this.endEdit}
          saveNewLocation={this.saveNewLocation}
        />
      </ErrorBoundary>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageLocationsLayout)
