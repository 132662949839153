import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"
import { FieldArray, useFormikContext } from "formik"
import "react-quill/dist/quill.snow.css"
import plusImg from "../../../img/plus.svg"
import "../../../styles/create.scss"
import { useAppSelector } from "store/hooks"
import { CourseFormValues } from "../types"
import { MultipleChoiceQuestionAnswerField } from "./MultipleChoiceQuestionAnswerField"

export const MultipleChoiceQuestionFields = () => {
  const { setFieldValue, values } = useFormikContext<CourseFormValues>()
  const session = useAppSelector((state) => state.session)

  const hasMultipleCorrectAnswers =
    values.answers.filter(
      (answer) => answer.attributes.field_mc_answer_type === "correct"
    ).length > 1

  const isOwnCommunityCourse =
    values.creatorOrg?.id === session.group.uuid[0].value

  return (
    <FieldArray
      name="answers"
      render={(arrayHelpers) => (
        <>
          <Typography variant="h4">Answers</Typography>
          <Box role="list">
            {values.answers.map((answer, index) => {
              return (
                <MultipleChoiceQuestionAnswerField
                  key={index}
                  disabled={values.isTemplate && !isOwnCommunityCourse}
                  name={`answers.${index}`}
                  onDelete={
                    !values.isTemplate && values.answers.length > 2
                      ? () => arrayHelpers.remove(index)
                      : undefined
                  }
                />
              )
            })}
          </Box>
          {hasMultipleCorrectAnswers && (
            <div>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="any"
                  value={values.requireAllAnswers ? "all" : "any"}
                  onChange={(event) => {
                    setFieldValue(
                      "requireAllAnswers",
                      event.target.value === "all" ? true : false
                    )
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <FormControlLabel
                      value="any"
                      control={<Radio color="primary" />}
                      label="Allow any correct answer"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="all"
                      control={<Radio color="primary" />}
                      label="Require all correct answers"
                      labelPlacement="end"
                    />
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.mc_shuffle}
                        onChange={() => {
                          setFieldValue("mc_shuffle", !values.mc_shuffle)
                        }}
                      />
                    }
                    label="Shuffle Answers"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          )}
          {!values.isTemplate && (
            <Box
              sx={{
                marginBottom: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                startIcon={<img alt="Add Answer" src={plusImg} />}
                onClick={() =>
                  arrayHelpers.push({
                    attributes: {
                      field_mc_answer: "",
                      field_mc_answer_type: "incorrect",
                    },
                  })
                }
                size="small"
                variant="text"
              >
                Add Answer
              </Button>
            </Box>
          )}
        </>
      )}
    />
  )
}
