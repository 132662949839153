import { Component } from "react"

import {
  TextField,
  Button,
  Popover,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import { connect } from "react-redux"

import {
  updateField,
  setEventImage,
  setEventImageFilename,
  removeEventImage,
  addEventTag,
  deleteEventTag,
} from "./activeEventSlice"
import {
  updateConferenceField,
  removeConferenceImage,
  setConferenceImage,
  setConferenceImageFilename,
  addConferenceTag,
  deleteConferenceTag,
} from "../conference/activeConferenceSlice"
import Loading from "@mobilemind/common/src/components/Loading"
import { getCategories } from "../../../store/reducers/categories"
import DateRangeSelector from "@mobilemind/common/src/components/DateRangeSelector"
import { formats, modules } from "../../../constants/reactQuillConfig"

import "../../../styles/calendar.scss"

import { setCategoryModalOpen } from "../../../store/reducers/categories"

import ReactQuill from "react-quill"
import sessionColors from "../calendar/sessionColors"

import ImageUpload from "../../../components/ImageUpload"
import classNames from "classnames"
import TagSelect from "@mobilemind/common/src/components/TagSelect"

import "react-quill/dist/quill.snow.css"
import CategorySelect from "@mobilemind/common/src/components/CategorySelect"
import manageImg from "../../../img/manage.svg"

const mapStateToProps = ({ tags, categories, activeConference }, ownProps) => {
  return {
    tags,
    categories,
    isConference: ownProps.isConference,
    activeConference,
  }
}

const mapDispatchToProps = {
  updateField,
  setCategoryModalOpen,
  updateConferenceField,
  addEventTag,
  deleteEventTag,
  getCategories,
  addConferenceTag,
  deleteConferenceTag,
}

class EventDetails extends Component {
  state = {
    colorAnchorEl: null,
  }

  componentDidMount() {
    this.props.getCategories()
  }

  handleNotesChange = (newValue) => {
    this.updateField({ field: "notes", value: newValue })
  }

  handleDescriptionChange = (newValue) => {
    this.updateField({ field: "description", value: newValue })
  }

  updateField = (args) => {
    if (this.props.isConference) {
      this.props.updateConferenceField(args)
    } else {
      this.props.updateField(args)
    }
  }

  render() {
    const {
      eventLabel,
      activeEvent,
      isConference,
      isObservation,
      categories,
      isSession,
    } = this.props
    const { colorAnchorEl } = this.state

    let title = isConference ? "Conference" : "Event"
    if (isSession) {
      title = "Session"
    }
    if (isObservation) {
      title = "Observation"
    }

    let loadingText = "Getting " + eventLabel + "..."
    let activeCategory = null
    if (activeEvent.category) {
      activeCategory = categories.data.find(
        (cat) => cat.id === activeEvent.category.id
      )
    }

    return (
      <div className="event-content event-details">
        {!activeEvent.fetched ||
        (!activeEvent.id &&
          isSession &&
          !activeEvent.isNew &&
          !activeEvent.isContinued) ? (
          <div style={{ marginLeft: isSession ? 0 : 325 }}>
            <Loading fullPage={true} message={loadingText} />
          </div>
        ) : (
          <>
            <h2> {title} Details</h2>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className={classNames(
                "required",
                activeEvent.missingFields.includes("Details: Name") && "active"
              )}
            >
              {isSession && (
                <>
                  <Button
                    style={{
                      minWidth: 0,
                      padding: 8,
                      marginTop: -22,
                      marginRight: 5,
                      marginLeft: -5,
                      borderRadius: 50,
                      height: 40,
                      flexShrink: 0,
                    }}
                    onClick={(event) =>
                      this.setState({ colorAnchorEl: event.currentTarget })
                    }
                  >
                    <div
                      style={{
                        backgroundColor: activeEvent.color
                          ? activeEvent.color
                          : sessionColors[0],
                        width: 25,
                        height: 25,
                        borderRadius: 25,
                      }}
                    />
                  </Button>

                  <Popover
                    id="colors-popover"
                    anchorEl={colorAnchorEl}
                    open={colorAnchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={() => this.setState({ colorAnchorEl: null })}
                    disableRestoreFocus
                  >
                    <div className="row">
                      {sessionColors.map((color) => {
                        return (
                          <Button
                            key={color}
                            onClick={() => {
                              this.setState({ colorAnchorEl: null })
                              this.updateField({
                                field: "color",
                                value: color,
                              })
                            }}
                            className="color-button"
                          >
                            <div
                              className="color"
                              style={{ backgroundColor: color }}
                            />
                          </Button>
                        )
                      })}
                    </div>
                  </Popover>
                </>
              )}

              <FormControl
                variant="standard"
                style={{ marginBottom: 20, width: "100%" }}
              >
                <TextField
                  variant="standard"
                  className="large"
                  required
                  label={title + " Name"}
                  value={activeEvent.name}
                  onChange={(event) =>
                    this.updateField({
                      field: "name",
                      value: event.target.value,
                    })
                  }
                />
              </FormControl>
            </div>

            <h3>Date & Time*</h3>
            <div className="flexRow">
              <DateRangeSelector
                startDate={activeEvent.startDate}
                endDate={activeEvent.endDate}
                onChange={this.updateField}
                hideSecondDate={activeEvent.allDay}
              />
              <div style={{ marginTop: -19 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activeEvent.allDay}
                      onChange={(event) =>
                        this.updateField({
                          field: "allDay",
                          value: event.target.checked,
                        })
                      }
                      name="allDay"
                      color="secondary"
                    />
                  }
                  label="All Day"
                />
              </div>
            </div>
            <div
              className={classNames(
                "required",
                activeEvent.missingFields.includes("Details: Description") &&
                  "active"
              )}
            >
              <h3>Description*</h3>
              <ReactQuill
                theme={"snow"}
                onChange={this.handleDescriptionChange}
                value={this.props.activeEvent.description}
                modules={modules}
                formats={formats}
                placeholder={title + " Description"}
              />
            </div>

            <div className="flexRow" style={{ marginTop: 20 }}>
              <div style={{ flex: 1 }}>
                <h3 style={{ marginTop: 20, marginBottom: -25 }}>Type</h3>

                <FormControl
                  variant="standard"
                  className={classNames(
                    "select",
                    isSession &&
                      this.props.activeConference.type === "Virtual Live" &&
                      "disabled"
                  )}
                >
                  <Select
                    variant="standard"
                    labelId="label-event-type"
                    id="event-type-select"
                    value={this.props.activeEvent.type}
                    onChange={(event) =>
                      this.updateField({
                        field: "type",
                        value: event.target.value,
                      })
                    }
                  >
                    <MenuItem value={"In Person Live"}>In Person Live</MenuItem>
                    <MenuItem value={"Virtual Live"}>Virtual Live</MenuItem>
                    <MenuItem value={"Blended"}>Blended</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {!isObservation && (
                <div style={{ flex: 1 }}>
                  <h3 style={{ marginTop: 20 }}>Difficulty</h3>

                  <div
                    style={{ marginTop: isSession ? -20 : -40 }}
                    className="flexRow"
                  >
                    <FormControl
                      variant="standard"
                      style={{ flex: 1, marginRight: 25 }}
                      className="select"
                    >
                      <Select
                        variant="standard"
                        labelId="label-event-difficulty"
                        id="event-difficulty-select"
                        value={this.props.activeEvent.difficulty}
                        onChange={(event) =>
                          this.updateField({
                            field: "difficulty",
                            value: event.target.value,
                          })
                        }
                      >
                        <MenuItem value={"N/A"}>N/A</MenuItem>
                        <MenuItem value={"Basic"}>Basic</MenuItem>
                        <MenuItem value={"Intermediate"}>Intermediate</MenuItem>
                        <MenuItem value={"Advanced"}>Advanced</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
            </div>

            {!isObservation && (
              <>
                <div
                  className={classNames(
                    "required event-category-select",
                    activeEvent.missingFields.includes("Details: Category") &&
                      "active"
                  )}
                >
                  <div className="flexRow" style={{ alignItems: "flex-start" }}>
                    <div>
                      <h3>Category</h3>
                      <div>
                        {categories.fetched && (
                          <CategorySelect
                            onChange={(category) => {
                              this.updateField({
                                field: "category",
                                value: category,
                              })
                            }}
                            currentValue={activeCategory}
                            label={""}
                            categories={categories}
                            allowedLevels={3}
                          />
                        )}
                        {(this.props.session.isOrgAdmin ||
                          this.props.session.isPartner) && (
                          <div style={{ flex: 1 }}>
                            <Button
                              onClick={() => {
                                this.props.setCategoryModalOpen(true)
                              }}
                              className="button small"
                            >
                              <img alt={"Manage Categories"} src={manageImg} />{" "}
                              Manage Categories
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <h3>Tags</h3>
                      <TagSelect
                        allowNewTags={true}
                        tags={this.props.tags}
                        addTag={(tag) => {
                          if (this.props.isConference) {
                            this.props.addConferenceTag(tag)
                          } else {
                            this.props.addEventTag(tag)
                          }
                        }}
                        deleteTag={(tag) => {
                          if (this.props.isConference) {
                            this.props.deleteConferenceTag(tag)
                          } else {
                            this.props.deleteEventTag(tag)
                          }
                        }}
                        selectedTags={this.props.activeEvent.tags}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="flexRow eventImageWrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div>
                    <h3>Banner Image</h3>
                    <ImageUpload
                      setFilename={
                        this.props.isConference
                          ? setConferenceImageFilename
                          : setEventImageFilename
                      }
                      removeImage={
                        this.props.isConference
                          ? removeConferenceImage
                          : removeEventImage
                      }
                      setImage={
                        this.props.isConference
                          ? setConferenceImage
                          : setEventImage
                      }
                      activeImage={
                        this.props.activeEvent.eventImage.file
                          ? this.props.activeEvent.eventImage.file
                          : this.props.activeEvent.eventImage.image
                      }
                      shape="rectangle"
                    />
                  </div>
                  <div>
                    <p>
                      <em>
                        An optional image to be displayed on the{" "}
                        <span style={{ textTransform: "lowercase" }}>
                          {title}
                        </span>{" "}
                        page and calendar popup in the Learn app.
                      </em>
                    </p>
                    <p>
                      <em>
                        For best results, we recommend an image at least 1024px
                        wide.
                      </em>
                    </p>
                  </div>
                </div>
              </>
            )}

            <h3 style={{ marginTop: isSession ? 80 : 0 }}>Additional Notes</h3>
            <ReactQuill
              theme={"snow"}
              onChange={this.handleNotesChange}
              value={this.props.activeEvent.notes}
              modules={modules}
              formats={formats}
              placeholder={"Additional Notes"}
            />
          </>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails)
