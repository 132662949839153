import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"

export const fetchHQAnnouncements = createAsyncThunk(
  "hqAnnouncementsSlice/fetchHQAnnouncements",
  async (args, thunkAPI) => {
    const { collection } = thunkAPI.getState().session

    let response = await fetchWrapper.get(
      "/api/node/hq_announcement?include=field_media"
    )
    let data = await response.json()

    if (collection) {
      data.data = data.data.filter(
        (announcement) => announcement.attributes.field_include_collections
      )
    }
    return data
  }
)

export const hqAnnouncementsSlice = createSlice({
  name: "hqAnnouncementsSlice",
  initialState: {
    fetched: false,
    data: [],
  },
  extraReducers: {
    [fetchHQAnnouncements.fulfilled]: (state, action) => {
      state.fetched = true

      action.payload.data.forEach((announcement) => {
        if (announcement.relationships.field_media.data) {
          announcement.image = action.payload.included.find(
            (included) =>
              included.id === announcement.relationships.field_media.data.id
          )
        }
      })

      state.data = action.payload.data.reverse()
    },
  },
})

export default hqAnnouncementsSlice.reducer
