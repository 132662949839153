import { createSlice } from "@reduxjs/toolkit"

type InitialState = {
  data: any[]
  fetched: boolean
}

const initialState: InitialState = {
  data: [],
  fetched: false,
}

export const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    get: (state, action) => {
      state.data = action.payload
      state.fetched = true
    },
  },
})

export default notifications.reducer
