import { Box, Dialog, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useAppDispatch } from "store/hooks"
import { getOrgList } from "store/reducers/dashboard"
import School from "../../img/school.svg"
import Teleportation from "../../img/teleportation.svg"
import theme from "@mobilemind/common/src/theme/theme"
import { switchActiveMembership } from "@mobilemind/common/src/actions/user"
import Loading from "@mobilemind/common/src/components/Loading"

type ModalProps = {
  open: boolean
  onClose: () => void
}

const styles = {
  header: {
    marginTop: 0,
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
    icon: {
      marginRight: 10,
      width: 30,
      height: 30,
    },
  },
  ul: {
    borderTop: "1px solid #ebebeb",
    margin: "0 -20px",
  },
  li: {
    display: "flex",
    alignItems: "center",
    padding: 3,
    minWidth: 500,
    margin: "0 -20p",
    borderBottom: "1px solid #ebebeb",
    transition: ".3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ebebeb",
    },
    icon: {
      width: 25,
      height: 25,
      border: "3px solid white",
      boxShadow: theme.shadows[2],
      borderRadius: 25,
      marginRight: 10,
    },
    strong: {},
  },
}

export function JumpToOrgSelect({ open, onClose }: ModalProps) {
  const [search, setSearch] = useState("")
  const [isSearching, setIsSearching] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFetched, setIsFetched] = useState(false)
  const [results, setSearchResults] = useState([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isFetched) {
      dispatch(getOrgList({}))
      setIsFetched(true)
    }
    if (open) {
      dispatch(getOrgList({}))
    }
  }, [isFetched, dispatch, open])

  const handleSearch = async (search: string) => {
    setSearch(search)
    if (search) {
      setIsSearching(true)
      const response = await dispatch(getOrgList({ filters: { search } }))
      setSearchResults(response.payload?.data ?? [])
      setIsSearching(false)
    } else {
      setSearchResults([])
    }
  }

  return (
    <Dialog className="modal jump-to-org-modal" open={open} onClose={onClose}>
      <h2 style={styles.header}>
        <img
          src={Teleportation}
          alt="Jump to Organization"
          style={styles.header.icon}
        />
        Jump to Organization
      </h2>

      <TextField
        style={{ marginBottom: 20 }}
        label="Search for Organizations"
        value={search}
        onChange={(e) => {
          handleSearch(e.target.value)
        }}
      />
      {isLoading && <Loading fullPage={true} message="Just a moment..." />}

      <ul style={{ ...styles.ul, pointerEvents: isLoading ? "none" : "all" }}>
        {results.map((org: any) => {
          return (
            <Box
              component={"li"}
              key={org.id}
              sx={styles.li}
              onClick={() => {
                setIsLoading(true)
                dispatch(
                  switchActiveMembership(org.uuid, process.env.REACT_APP_HQ_URL)
                )
              }}
            >
              <img src={School} alt="Organization" style={styles.li.icon} />
              <strong style={styles.li.strong}>{org.label}</strong>
            </Box>
          )
        })}
      </ul>
      {isSearching && (
        <div
          style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
        >
          <Loading message="Searching organizations..." />
        </div>
      )}
    </Dialog>
  )
}
