import { DeleteButton } from "@mobilemind/common/src/components/DeleteButton"
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material"
import { FastField, Field, useField } from "formik"
import { QuizQuestionAnswer } from "../types"

type QuizQuestionAnswerFieldProps = {
  name: string
  onDelete?: () => void
}

export const QuizQuestionAnswerField = (
  props: QuizQuestionAnswerFieldProps
) => {
  const { name, onDelete } = props
  const [field] = useField<QuizQuestionAnswer>(name)
  const answer = field.value
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 4,
        marginBottom: 2,
      }}
      role="listitem"
    >
      <FastField
        as={TextField}
        name={`${name}.value`}
        variant="standard"
        multiline
        placeholder="Enter a possible answer"
      />
      <FormControlLabel
        control={
          <Field
            as={Checkbox}
            name={`${name}.isCorrect`}
            checked={answer.isCorrect}
          />
        }
        label="Correct"
        sx={{ marginRight: 0 }}
      />
      {onDelete && <DeleteButton onClick={onDelete} />}
    </Box>
  )
}
