import { GenieRewriteAllowedField } from "@mobilemind/genie-api/src/types"
import { TextField } from "@mui/material"
import { FastFieldWithError } from "components/FastFieldWithError"
import { useContext } from "react"
import { GenieContext } from "./GenieContext"

export const LearnerInstructionsField = () => {
  const { getGenieElementProps } = useContext(GenieContext)

  return (
    <FastFieldWithError
      as={TextField}
      name="learnerInstructions"
      variant="standard"
      multiline
      label="Instructions"
      margin="normal"
      required
      helperText={
        "The challenge content for the learner that appears in the MobileMind extension."
      }
      {...getGenieElementProps(
        GenieRewriteAllowedField.LearnerInstructions,
        "learnerInstructions"
      )}
    />
  )
}
