import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import moment from "moment"

export const fetchActiveMandatedTraining = createAsyncThunk(
  "activeMandatedTrainingSlice/fetchActiveMandatedTraining",
  async (args, thunkAPI) => {
    const { trainingId } = args

    const url = "/api/renew_course_ser/renew_course_ser?"
    const include =
      "field_image,field_renew_course,field_courses,field_subgroup,field_job_title"

    let query = {
      filter: {
        drupal_internal__id: Number(trainingId),
      },
      include,
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))

    let data = await response.json()

    if (data.data[0]) {
      let activeTraining = data.data[0]
      let subGroups

      activeTraining.courses =
        activeTraining && data.included
          ? data.included.filter(
              (included) => included.type === "course_entity--course_entity"
            )
          : []

      if (data.included) {
        const paragraphs = data.included.filter(
          (included) => included.type === "paragraph--renewab"
        )

        activeTraining.courses.forEach((course) => {
          let renewParagraph = paragraphs.find((paragraph) => {
            return paragraph.relationships.field_course.data.id === course.id
          })

          course.retakeCadence = renewParagraph.attributes.field_retake_cadence
          course.renewParagraph = renewParagraph
        })
      }
      if (data.included) {
        activeTraining.image =
          activeTraining &&
          data.included.filter((included) => included.type === "file--image")

        activeTraining.jobTitles =
          activeTraining &&
          data.included.filter(
            (included) => included.type === "taxonomy_term--job_titles"
          )
        subGroups =
          data.included &&
          data.included.filter((included) => included.type === "group--group")
      }

      let subGroupIds =
        activeTraining.relationships.field_subgroup.data &&
        activeTraining.relationships.field_subgroup.data.map(
          (group) => group.id
        )

      if (!subGroups || !subGroups.length) {
        let groupLevelSubGroups = thunkAPI.getState().session.subgroups
        activeTraining.subGroups =
          groupLevelSubGroups &&
          groupLevelSubGroups.data &&
          groupLevelSubGroups.data.filter((group) =>
            subGroupIds.includes(group.id)
          )
      } else {
        activeTraining.subGroups = subGroups
      }

      return activeTraining
    } else {
      return false
    }
  }
)

export const activeMandatedTrainingSlice = createSlice({
  name: "activeMandatedTrainingSlice",
  initialState: {
    unauthorized: false,
    fetched: false,
    name: "",
    courses: [],
    image: null,
    filename: null,
    archived: false,
    subGroups: [],
    jobTitles: [],
    body: "",
    field_no_prior_credit: false,
    drupal_internal__id: null,
    subGroupOwner: null,
    missingFields: [],
    openDate: moment().format(),
    deadlineDate: moment().add(1, "month").format(),
    originalCourses: [],
  },
  reducers: {
    startNewMT: (state) => {
      state.id = null
      state.name = ""
      state.image = null
      state.filename = null
      state.archived = false
      state.body = ""
      state.field_draft = true
      state.courses = []
      state.subGroups = []
      state.fetched = true
      state.unauthorized = false
      state.field_no_prior_credit = false
      state.jobTitles = []
      state.drupal_internal__id = null
      state.subGroupOwner = null
      state.missingFields = []
      state.openDate = moment().format()
      state.deadlineDate = moment().add(1, "month").format()
      state.originalCourses = []
    },
    updateField: (state, action) => {
      // Prevent duplicates due to AutoComplete weirdness
      if (action.payload.field === "jobTitles") {
        state[action.payload.field] = action.payload.value.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        )
      } else if (action.payload.field === "subGroups") {
        state[action.payload.field] = action.payload.value.filter(
          (v, i, a) =>
            a.findIndex((t) => t.attributes.label === v.attributes.label) === i
        )
      } else {
        state[action.payload.field] = action.payload.value
      }
    },
    setFilename: (state, action) => {
      state.filename = action.payload
    },
    setImage: (state, action) => {
      state.image = action.payload
    },
    removeImage: (state) => {
      state.image = null
    },
    dropCourse: (state, action) => {
      let prevIndex = action.payload.source.index
      let targetIndex = action.payload.destination.index

      if (targetIndex >= state.courses.length) {
        let course = targetIndex - state.courses.length + 1
        while (course--) {
          state.courses.push(undefined)
        }
      }

      state.courses.splice(
        targetIndex,
        0,
        state.courses.splice(prevIndex, 1)[0]
      )
    },
    updateCadence: (state, action) => {
      const { course, value } = action.payload

      let targetCourse = state.courses.find((existing) => {
        return existing.id === course.id
      })
      if (Number(value > 0)) {
        targetCourse.retakeCadence = value
      }
    },
    addCourse: (state, action) => {
      const course = { ...action.payload }
      course.retakeCadence = 1
      state.courses.push(course)
      state.estimatedTime = state.courses
        .map((course) => course.attributes.field_course_estimated_time)
        .reduce((a, b) => a + b, 0)
    },
    removeCourse: (state, action) => {
      state.courses = state.courses.filter(
        (course) => course.id !== action.payload.id
      )
      state.estimatedTime = state.courses
        .map((course) => course.attributes.field_course_estimated_time)
        .reduce((a, b) => a + b, 0)
    },
  },
  extraReducers: {
    [fetchActiveMandatedTraining.pending]: (state, action) => {
      state.fetched = false
    },
    [fetchActiveMandatedTraining.fulfilled]: (state, action) => {
      if (action.payload) {
        const {
          attributes,
          relationships,
          image,
          courses,
          subGroups,
          jobTitles,
        } = action.payload

        state.unauthorized = false
        state.id = action.payload.id
        state.drupal_internal__id = attributes.drupal_internal__id

        state.openDate = attributes.field_open_date
        state.deadlineDate = attributes.field_deadline_date
        state.field_no_prior_credit = attributes.field_no_prior_credit
        state.subGroupOwner =
          relationships.field_subgroup_owner &&
          relationships.field_subgroup_owner.data &&
          relationships.field_subgroup_owner.data.id

        state.name = attributes.title
        state.image = image && image[0]
        state.archived = attributes.field_learning_path_archive
        state.body = attributes.field_description
        state.courses = courses
        state.originalCourses = courses
        state.subGroups = subGroups ? subGroups : []
        state.jobTitles = jobTitles ? jobTitles : []
        state.field_draft = attributes.field_draft

        state.fetched = true
      } else {
        state.unauthorized = true
      }
    },
  },
})

export const {
  startNewMT,
  updateField,
  removeImage,
  setImage,
  setFilename,
  updateCadence,
  addCourse,
  dropCourse,
  removeCourse,
} = activeMandatedTrainingSlice.actions

export default activeMandatedTrainingSlice.reducer
