import { useAppDispatch } from "store/hooks"

import { TextField, FormControl, Select, MenuItem } from "@mui/material"
import { connect } from "react-redux"
import classNames from "classnames"
import Autocomplete from "@mui/material/Autocomplete"
import DeleteButton from "../../../components/DeleteButton"
import ManageImg from "../../../img/manage.svg"
import {
  addAttachment,
  removeAttachment,
  setEventImageFilename,
  removeEventImage,
  updateCourseSearch,
  setRelatedLP,
  selectCourse,
  deleteCourse,
  updateField,
  updateLPSearch,
} from "./activeEventSlice"
import {
  updateConferenceField,
  addConferenceAttachment,
  updateConferenceCourseSearch,
  updateConferenceLPSearch,
  removeConferenceAttachment,
  setConferenceRelatedLP,
  selectConferenceCourse,
  deleteConferenceCourse,
} from "../conference/activeConferenceSlice"

import he from "he"

import FileUpload from "@mobilemind/common/src/components/FileUpload"
import ReactQuill from "react-quill"
import { formats, modules } from "../../../constants/reactQuillConfig"

import "react-quill/dist/quill.snow.css"

import { CategoryIcon } from "@mobilemind/common/src/components/CategoryIcon"
import { setFeedbackFormsModalOpen } from "store/reducers/feedbackForms"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"
import { useState } from "react"

const mapStateToProps = ({
  learningPaths,
  categories,
  entityList,
  courses,
  session,
  feedbackForms,
}) => {
  return {
    orgBadges: entityList.orgBadges.data,
    orgLearningPaths: entityList.orgLearningPaths,
    learningPaths,
    categories,
    courses,
    session,
    feedbackForms,
  }
}

function EventResources(props) {
  const event = props.isConference ? props.activeConference : props.activeEvent
  const {
    preRequisiteCourses,
    preRequisiteCoursesSearch,
    preRequisiteCoursesResults,
    replacementCourses,
    replacementCoursesSearch,
    replacementCoursesResults,
    relatedLP,
    feedbackFormId,
    feedbackRequired,
    feedbackIsAnonymous,
    relatedLPSearch,
    relatedLPResults,
  } = event

  const dispatch = useAppDispatch()

  const [isAnonymousLocked] = useState(
    feedbackIsAnonymous && event.id && !event.field_draft
  )

  const updateInfo = props.isConference ? updateConferenceField : updateField
  const deleteAttachment = props.isConference
    ? removeConferenceAttachment
    : removeAttachment

  let eventLabel = props.isConference ? "Conference" : "Event"
  if (props.conference?.id && !props.isConference) {
    eventLabel = "Session"
  }

  function handleLinksChange(newValue) {
    dispatch(updateInfo({ field: "helpfulLinks", value: newValue }))
  }

  function handleContactChange(newValue) {
    dispatch(updateInfo({ field: "supportContact", value: newValue }))
  }

  function getCategoryImageSrc(course) {
    const categoryId =
      course.relationships && course.relationships.field_category.data.id
    if (categoryId) {
      const category = props.categories.data.find(
        (category) => category.id === categoryId
      )
      return (
        category &&
        process.env.REACT_APP_API_URL + category.image.attributes.uri.url
      )
    } else {
      return course.field_category_image_uri
    }
  }

  function CourseListItem({ course, field }) {
    const name = course.attributes ? course.attributes.name : course.name

    return (
      <li
        key={course.id}
        style={{
          marginBottom: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <CategoryIcon src={getCategoryImageSrc(course)} />
        <strong style={{ marginLeft: 8, width: "50%", marginRight: 10 }}>
          {name}
        </strong>

        <DeleteButton
          onClick={() => {
            if (props.isConference) {
              dispatch(deleteConferenceCourse({ field, value: course }))
            } else {
              dispatch(deleteCourse({ field, value: course }))
            }
          }}
        />
      </li>
    )
  }

  const canManageForms =
    props.session.orgRoles.includes("organization-admin") ||
    props.session.orgRoles.includes("organization-scheduler")

  return (
    <div className="event-content event-mm-content">
      <h2>{eventLabel} Resources</h2>
      <h3 style={{ display: "flex" }}>
        <span style={{ flex: 1 }}>{eventLabel} Feedback</span>
        {canManageForms && (
          <div style={{ marginTop: -10 }}>
            <ButtonSmall
              onClick={() => {
                dispatch(setFeedbackFormsModalOpen(true))
              }}
            >
              <img
                src={ManageImg}
                alt="Manage"
                style={{ width: 20, height: 20, marginRight: 5 }}
              />
              Manage Feedback Forms
            </ButtonSmall>
          </div>
        )}
      </h3>
      <FormControl variant="standard">
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: 20,
          }}
        >
          <Select
            style={{ textTransform: "none", marginRight: 15 }}
            variant="standard"
            label="Feedback Method"
            value={event.feedbackMethod}
            onChange={(event) =>
              dispatch(
                updateInfo({
                  field: "feedbackMethod",
                  value: event.target.value,
                })
              )
            }
          >
            <MenuItem value="link">Link to External Form</MenuItem>
            <MenuItem value="custom">Custom Form</MenuItem>
          </Select>

          {event.feedbackMethod === "link" ? (
            <div>
              <TextField
                variant="standard"
                placeholder="Link to feedback form"
                value={event.feedbackURL}
                onChange={(event) =>
                  dispatch(
                    updateInfo({
                      field: "feedbackURL",
                      value: event.target.value,
                    })
                  )
                }
              />

              <p style={{ marginTop: -10, opacity: 0.5, fontSize: 12 }}>
                A link to a feedback form made available to individuals who have
                checked in after the {eventLabel} starts.
              </p>
            </div>
          ) : (
            <Select
              onChange={(event, newValue) => {
                dispatch(
                  updateInfo({
                    field: "feedbackFormId",
                    value: event.target.value,
                  })
                )
              }}
              style={{ flex: 1, width: "100%" }}
              value={feedbackFormId ?? "none"}
            >
              <MenuItem disabled key={0} value={"none"}>
                <span style={{ opacity: 0.6 }}>Select Form</span>
              </MenuItem>
              {props.feedbackForms.data.map((form) => {
                return (
                  <MenuItem key={form.id} value={form.id}>
                    {form.attributes.label}
                  </MenuItem>
                )
              })}
            </Select>
          )}
        </div>

        {event.feedbackMethod === "custom" && (
          <>
            <SettingSwitch
              checked={feedbackRequired}
              helperText={
                "Attendees must be checked in and submit feedback to receive PD credit."
              }
              label="Require Feedback for PD Credit"
              name="feedbackRequired"
              onChange={(checked) => {
                dispatch(
                  updateInfo({
                    field: "feedbackRequired",
                    value: checked,
                  })
                )
              }}
            />

            <SettingSwitch
              disabled={isAnonymousLocked}
              checked={feedbackIsAnonymous}
              helperText={
                <div style={{ maxWidth: "90%" }}>
                  Responses will remain anonymous to all event managers and
                  administrators.
                  {feedbackIsAnonymous && (
                    <strong
                      style={{ display: "block", marginTop: 3, color: "red" }}
                    >
                      NOTE: After turning on Anonymous Feedback and publishing,
                      you will not be able to change this setting again for this{" "}
                      {eventLabel.toLowerCase()}.
                    </strong>
                  )}
                </div>
              }
              label="Anonymous Feedback"
              name="feedbackIsAnonymous"
              onChange={(checked) => {
                dispatch(
                  updateInfo({
                    field: "feedbackIsAnonymous",
                    value: checked,
                  })
                )
              }}
            />
          </>
        )}
      </FormControl>

      <h3>Prerequisite Courses</h3>
      <ul style={{ listStyleType: "none" }}>
        {preRequisiteCourses.map((course, index) => {
          if (course) {
            return (
              <CourseListItem
                key={index}
                field="preRequisiteCourses"
                course={course}
              />
            )
          }

          return null
        })}
      </ul>
      <Autocomplete
        loadingText={
          preRequisiteCoursesSearch
            ? "Getting courses..."
            : "Start typing to search..."
        }
        options={preRequisiteCoursesResults}
        value={preRequisiteCoursesSearch}
        getOptionLabel={(course) =>
          course.name ? course.name : preRequisiteCoursesSearch
        }
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            value={preRequisiteCoursesSearch}
            onChange={(event) => {
              if (props.isConference) {
                dispatch(
                  updateConferenceCourseSearch({
                    field: "preRequisiteCoursesSearch",
                    value: event.target.value,
                  })
                )
              } else {
                dispatch(
                  updateCourseSearch({
                    field: "preRequisiteCoursesSearch",
                    value: event.target.value,
                  })
                )
              }
            }}
            label="Prerequisite Course(s) Before Attending"
          />
        )}
        onChange={(event, newValue) => {
          if (props.isConference) {
            dispatch(
              selectConferenceCourse({
                field: "preRequisiteCourses",
                value: newValue,
              })
            )
          } else {
            dispatch(
              selectCourse({ field: "preRequisiteCourses", value: newValue })
            )
          }
        }}
      />
      <h3>Related Courses</h3>
      <ul style={{ listStyleType: "none" }}>
        {replacementCourses.map((course, index) => {
          if (course) {
            return (
              <CourseListItem
                key={index}
                field="replacementCourses"
                course={course}
              />
            )
          }

          return null
        })}
      </ul>
      <FormControl variant="standard">
        <Autocomplete
          loadingText={
            replacementCoursesSearch
              ? "Getting courses..."
              : "Start typing to search..."
          }
          options={replacementCoursesResults}
          value={replacementCoursesSearch}
          getOptionLabel={(course) =>
            course.name ? course.name : replacementCoursesSearch
          }
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              value={replacementCoursesSearch}
              onChange={(event) => {
                if (props.isConference) {
                  dispatch(
                    updateConferenceCourseSearch({
                      field: "replacementCoursesSearch",
                      value: event.target.value,
                    })
                  )
                } else {
                  dispatch(
                    updateCourseSearch({
                      field: "replacementCoursesSearch",
                      value: event.target.value,
                    })
                  )
                }
              }}
              label="Related Courses"
            />
          )}
          onChange={(event, newValue) => {
            if (props.isConference) {
              dispatch(
                selectConferenceCourse({
                  field: "replacementCourses",
                  value: newValue,
                })
              )
            } else {
              dispatch(
                selectCourse({ field: "replacementCourses", value: newValue })
              )
            }
          }}
        />
      </FormControl>

      <h3 style={{ marginTop: 25 }}>Prerequisite Learning Path</h3>
      <FormControl variant="standard">
        <Autocomplete
          id="learning-path-select"
          loadingText={
            relatedLPSearch
              ? "Getting learning paths..."
              : "Start typing to search..."
          }
          options={relatedLPResults}
          value={relatedLP ? relatedLP : relatedLPSearch}
          getOptionLabel={(path) => (path.name ? path.name : relatedLPSearch)}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              value={relatedLPSearch}
              onChange={(event) => {
                if (props.isConference) {
                  dispatch(updateConferenceLPSearch(event.target.value))
                } else {
                  dispatch(updateLPSearch(event.target.value))
                }
              }}
              label="Prerequisite Learning Path Before Attending"
            />
          )}
          onChange={(event, newValue) => {
            if (props.isConference) {
              dispatch(setConferenceRelatedLP(newValue))
            } else {
              dispatch(setRelatedLP(newValue))
            }
          }}
        />
      </FormControl>

      <h3>Support Contact</h3>
      <p style={{ marginTop: -5, opacity: 0.5, fontSize: 12 }}>
        A point of contact for learners having trouble with this event.
      </p>

      <ReactQuill
        theme={"snow"}
        onChange={handleContactChange}
        value={event.supportContact ? event.supportContact : ""}
        modules={modules}
        formats={formats}
      />

      <h3 style={{ marginTop: 35 }}>Helpful Links</h3>
      <p style={{ marginTop: 0, opacity: 0.5, fontSize: 12 }}>
        Links to related resources for learners invited to this event.
      </p>

      <ReactQuill
        theme={"snow"}
        onChange={handleLinksChange}
        value={event.helpfulLinks}
        modules={modules}
        formats={formats}
      />

      <h3 style={{ marginTop: 25 }}>Attachments</h3>

      <div className="attachmentsWrapper">
        <FileUpload
          setFilename={setEventImageFilename}
          removeImage={removeEventImage}
          addFile={props.isConference ? addConferenceAttachment : addAttachment}
        />
        <ul>
          {event.attachments.map((file, index) => {
            return (
              <li key={index}>
                {!file.file.includes("/sites/default") ? (
                  <>
                    {file.file.includes("image") ? (
                      <span
                        className="icon image"
                        style={{ backgroundImage: "url(" + file.file + ")" }}
                      />
                    ) : (
                      <span className={classNames("icon", file.extension)} />
                    )}
                  </>
                ) : (
                  <>
                    {file.extension !== "png" &&
                    file.extension !== "jpg" &&
                    file.extension !== "jpeg" ? (
                      <span className={classNames("icon", file.extension)} />
                    ) : (
                      <span
                        className="icon image"
                        style={{
                          backgroundImage:
                            "url(" +
                            process.env.REACT_APP_API_URL +
                            file.file +
                            ")",
                        }}
                      />
                    )}
                  </>
                )}
                <span className="filename">{he.decode(file.filename)}</span>
                <DeleteButton
                  onClick={() => {
                    dispatch(deleteAttachment(file))
                  }}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(EventResources)
