import { Popover, styled } from "@mui/material"

// @todo rename to ValidationPopover or something like that?
export const PublishPopover = styled(Popover)(({ theme }) => ({
  ".MuiPaper-root": {
    fontSize: 15,
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey.A700,
    color: theme.palette.common.white,
    width: 350,
    p: {
      marginBlock: theme.spacing(2),
      fontWeight: "bold",
      ":first-of-type": {
        marginBlockStart: 0,
      },
    },
    ul: {
      marginInlineStart: theme.spacing(5),
      li: {
        marginBlock: theme.spacing(1),
      },
      ":last-of-type": {
        marginBlockEnd: 0,
      },
    },
  },
}))
