import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"

export const getOrganizations = createAsyncThunk(
  "organizationsSlice/getOrganizations",
  async (args, thunkAPI) => {
    let orgs = []
    let i = 0
    let pages = 1

    while (i < pages) {
      let query = {
        page: {
          offset: i * 50,
        },
      }
      let response = await fetchWrapper.get(
        "/api/group/organization?" + qs.stringify(query)
      )

      if (response.ok) {
        let data = await response.json()
        pages = Math.ceil(Number(data.meta.count) / 50)
        orgs = orgs.concat(data.data)
      }
      i++
    }
    return orgs
  }
)

export const saveOrganization = createAsyncThunk(
  "organizationsSlice/saveOrganization",
  async (args, thunkAPI) => {
    const { session, categories } = thunkAPI.getState()
    const activeOrg = thunkAPI.getState().organizations.activeOrg

    let excludedCategories = []

    categories.subCategories.forEach((category) => {
      if (!category.isVisible) {
        excludedCategories.push({
          type: "taxonomy_term--category",
          id: category.id,
        })
      }
    })

    categories.topCategories.forEach((category) => {
      if (!category.isVisible) {
        excludedCategories.push({
          type: "taxonomy_term--category",
          id: category.id,
        })
      }
    })

    let body = {
      data: {
        id: activeOrg.id,
        type: "group--organization",
        relationships: {
          field_categories_to_exclude: {
            data: excludedCategories,
          },
        },
      },
    }

    let response = await fetchWrapper.patch(
      "/api/group/organization/" + activeOrg.id,
      session.token,
      JSON.stringify(body)
    )

    if (response.ok) {
      let data = await response.json()
      return data
    }
  }
)

export const getSingleOrg = createAsyncThunk(
  "organizationsSlice/getSingleOrg",
  async (args, thunkAPI) => {
    const { orgId } = args

    let query = {
      filter: {
        drupal_internal__id: Number(orgId),
      },
      include:
        "field_google_promo_customer,field_partner,field_categories_to_exclude,field_customer_success_rep",
    }
    let response = await fetchWrapper.get(
      "/api/group/organization?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      data.data[0].included = data.included
      return data.data[0]
    }
  }
)

export const organizationsSlice = createSlice({
  name: "organizationsSlice",
  initialState: {
    data: [],
    activeOrg: {},
    fetched: false,
    isFetching: true,
    isSaving: false,
    filters: {
      search: "",
      status: "any",
    },
  },
  reducers: {
    updateSearch: (state, action) => {
      state.filters.search = action.payload
    },
    setStatus: (state, action) => {
      state.filters.status = action.payload
    },
  },
  extraReducers: {
    [saveOrganization.pending]: (state, action) => {
      state.isSaving = true
    },
    [saveOrganization.fulfilled]: (state, action) => {
      state.isSaving = false
    },
    [getSingleOrg.pending]: (state, action) => {
      state.activeOrg = {}
      state.isFetching = true
    },
    [getSingleOrg.fulfilled]: (state, action) => {
      state.isSaving = false
      state.activeOrg = action.payload
      if (action.payload.included) {
        state.activeOrg.excludedCategories = action.payload.included.filter(
          (included) => included.type === "taxonomy_term--category"
        )
      }
    },
    [getOrganizations.fulfilled]: (state, action) => {
      state.fetched = true
      state.data = action.payload
      state.isFetching = false
    },
  },
})

export const { updateSearch, setStatus } = organizationsSlice.actions

export default organizationsSlice.reducer
