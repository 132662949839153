import { useAppDispatch, useAppSelector } from "../../store/hooks"

import { FormControl, TextField } from "@mui/material"

import { resetBackpack } from "../../store/reducers/backpack"
import {
  resetSearch,
  updateLearnerSearch,
} from "../../store/reducers/dashboard"

import Loading from "@mobilemind/common/src/components/Loading"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import classNames from "classnames"
import he from "he"
import { Link } from "react-router-dom"

const LearnerSearch = () => {
  const dashboard = useAppSelector((state) => state.dashboard)
  const dispatch = useAppDispatch()

  return (
    <>
      <div id="learnerSearch">
        <FormControl variant="standard">
          <div className="icon teacher" />
          <TextField
            variant="standard"
            placeholder="Find a learner"
            spellCheck={false}
            value={dashboard.learnerSearch.query}
            onChange={(event) => {
              dispatch(updateLearnerSearch(event.target.value))
            }}
          />
          {dashboard.learnerSearch.isFetching &&
            dashboard.learnerSearch.query && <Loading />}
        </FormControl>
      </div>
      <div
        id="learner-search-popover"
        className={classNames(
          !dashboard.learnerSearch.isFetching &&
            dashboard.learnerSearch.data.length > 0 &&
            "active"
        )}
      >
        <ul>
          {dashboard.learnerSearch.data.map((user: any) => {
            return (
              <li key={user.uid}>
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <Link
                    onClick={() => {
                      dispatch(resetBackpack())
                      dispatch(resetSearch())
                    }}
                    to={"/backpack/" + user.uid}
                  >
                    <UserLabel
                      imagePath={
                        process.env.REACT_APP_API_URL + user.user_picture
                      }
                      firstName={he.decode(user.field_first_name)}
                      lastName={he.decode(user.field_last_name)}
                      subgroups={user.field_subgroup}
                    />
                  </Link>
                }
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default LearnerSearch
