import { Switch } from "@mui/material"

import "../marketplace/marketplace.scss"
import { useFormikContext } from "formik"

import { useAppSelector } from "store/hooks"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { RootState } from "../../store/types"
import { formats, modules } from "../../constants/reactQuillConfig"

export const PartnerApprovalForm = () => {
  const { values, setFieldValue } = useFormikContext()
  const partnerInfo = useAppSelector((state: RootState) => {
    return state.session.group
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const approvalRequired = values.partner_approval_required
  return (
    <>
      <div style={styles.formRow}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: 14,
            flex: 1,
            marginTop: 10,
          }}
        >
          <strong>Partner Approval Required</strong>
          <span style={styles.helperText}>
            If checked, organizations trying to add this content will require
            admin approval.
          </span>
        </div>

        <Switch
          checked={approvalRequired && approvalRequired !== "0"}
          color="secondary"
          name="partner_approval_required"
          onChange={(event) => {
            setFieldValue("partner_approval_required", event.target.checked)
          }}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>
      <div style={styles.formColumn}>
        <div style={styles.fieldLabelSet}>
          <strong>Partner Contact</strong>
          <span style={styles.helperText}>
            Any contact information for organizations interested in{" "}
            <strong>{partnerInfo.label[0].value}</strong>.
          </span>
        </div>
        <div style={{ marginTop: -30 }}>
          <ReactQuill
            theme={"snow"}
            onChange={(event) => {
              setFieldValue("partner_contact", event)
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={values.partner_contact ?? ""}
            modules={modules}
            formats={formats}
            placeholder={"Partner Contact"}
          />
        </div>
      </div>
    </>
  )
}

const styles = {
  formRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  fieldLabelSet: {
    display: "flex",
    flexDirection: "column" as "column",
    fontSize: 14,
    flex: 1,
  },
  formColumn: {
    display: "flex",
    flexDirection: "column" as "column",
    marginBottom: 20,
  },
  helperText: {
    fontSize: 12,
    opacity: 0.6,
    maxWidth: 485,
  },
  contactField: {
    marginTop: 10,
    width: 400,
  },
}
