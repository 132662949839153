import { styled } from "@mui/material"
import YouTube from "react-youtube"

export const ResponsiveYouTube = styled(YouTube)(() => ({
  overflow: "hidden",
  paddingBottom: "56.25%",
  position: "relative",
  height: 0,
  "& iframe": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}))
