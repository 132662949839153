import { Component } from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { getOrganizations, updateSearch, setStatus } from "./organizationsSlice"
import "../../styles/organizations.scss"
import Loading from "@mobilemind/common/src/components/Loading"

const mapStateToProps = ({ organizations }) => {
  return {
    organizations,
  }
}

const mapDispatchToProps = {
  getOrganizations,
  updateSearch,
  setStatus,
}

class OrganizationsList extends Component {
  componentDidMount() {
    !this.props.organizations.fetched && this.props.getOrganizations()
  }

  render() {
    const { organizations } = this.props
    const searchQuery = organizations.filters.search
    const status = organizations.filters.status
    return (
      <div className="page organizations-list">
        <header className={"form-header"}>
          <div className="header-inner">
            <div className="flexRow">
              <h2>MobileMind Organizations</h2>

              <div className="flexRow inputSearch">
                <span className="icon search" />
                <TextField
                  variant="standard"
                  label="Search"
                  value={searchQuery}
                  onChange={(event) =>
                    this.props.updateSearch(event.target.value)
                  }
                />
              </div>

              <div className="status-filter">
                <FormControl variant="standard">
                  <InputLabel>Status</InputLabel>
                  <Select
                    variant="standard"
                    value={status}
                    onChange={(event) =>
                      this.props.setStatus(event.target.value)
                    }
                  >
                    <MenuItem value={"any"}>Any</MenuItem>
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {this.props.organizations.fetched && (
              <div className="labels">
                <span style={{ paddingLeft: 25 }}>Name</span>
                <span style={{ paddingRight: 35 }}>Status</span>
              </div>
            )}
          </div>
        </header>

        {!this.props.organizations.fetched && (
          <Loading message={"Getting organizations..."} />
        )}

        <ul>
          {organizations.data.map((org) => {
            if (
              !searchQuery ||
              (searchQuery &&
                org.attributes.label
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()))
            ) {
              if (
                status === "any" ||
                (status === "active" &&
                  org.attributes.field_status === "Active") ||
                (status === "inactive" &&
                  org.attributes.field_status === "Inactive")
              ) {
                return (
                  <li key={org.attributes.drupal_internal__id}>
                    <Link
                      to={
                        "/organizations/" + org.attributes.drupal_internal__id
                      }
                    >
                      <span className="icon school-building" />

                      <span className="title">{org.attributes.label}</span>

                      <span className="status">
                        {org.attributes.field_status}
                      </span>
                    </Link>
                  </li>
                )
              }
            }
            return null
          })}
        </ul>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsList)
