import DateRangeSelector from "@mobilemind/common/src/components/DateRangeSelector"
import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"
import yup from "@mobilemind/common/src/utility/yup"
import { TextField } from "@mui/material"
import { createNextState } from "@reduxjs/toolkit"
import { Field, useFormikContext } from "formik"
import moment from "moment"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "../../../styles/external-pd.scss"
import { ExternalEvent } from "./RecommendedEventModal"
import { formats, modules } from "../../../constants/reactQuillConfig"

export const validationSchema1 = yup.object({
  title: yup.string().required(),
  description: yup.string(),
  startDate: yup
    .string()
    .required()
    .default(() => moment().toISOString()),
  endDate: yup
    .string()
    .required()
    .default(() => moment().add(1, "hour").toISOString()),
})

export const ExternalEventStep1 = () => {
  const { setFieldValue, setValues, values } = useFormikContext<ExternalEvent>()
  return (
    <>
      <section>
        <header>Title</header>
        <Field
          name="title"
          as={TextField}
          variant="standard"
          required
          placeholder={"Event Title*"}
        />
      </section>

      <section className={"dateTime"}>
        {!values.isDateless && (
          <>
            <header>Date & Time</header>
            <DateRangeSelector
              onChange={(params) => {
                setValues(
                  createNextState((draft) => {
                    draft[params.field] = params.value
                    // Copilot wrote this code, and this comment
                    if (params.field === "startDate") {
                      if (
                        moment(params.value).isAfter(moment(values.endDate))
                      ) {
                        draft.endDate = moment(params.value)
                          .add(1, "hour")
                          .toISOString()
                      }
                    }
                  })
                )
              }}
              startDate={values.startDate}
              endDate={values.endDate}
            />
          </>
        )}

        <div style={{ marginTop: 15 }}>
          <SettingSwitch
            checked={values.isDateless}
            name="Always Available"
            label="Always Available"
            onChange={(checked) => {
              setFieldValue("isDateless", checked)
            }}
            helperText="Allows learners to attend this event and request credit for it any time, until it is archived."
          />
        </div>
      </section>
      <section>
        <header>Description</header>
        {
          <ReactQuill
            theme={"snow"}
            onChange={(value: string) => {
              setFieldValue("description", value)
            }}
            value={values.description}
            modules={modules}
            formats={formats}
          />
        }
      </section>
    </>
  )
}
