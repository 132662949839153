import { useAppDispatch } from "store/hooks"
import classNames from "classnames"
import moment from "moment"
import { Dialog, Button, FormControlLabel, Checkbox } from "@mui/material"
import "../styles/modal.scss"

import { addressToString } from "@mobilemind/common/src/functions"
import { updateField } from "../features/events/eventSingle/activeEventSlice"
import { updateConferenceField } from "../features/events/conference/activeConferenceSlice"
import Loading from "@mobilemind/common/src/components/Loading"
// import UserInitial from '@mobilemind/common/src/components/UserInitial'

function ModalEventConfirmation(props) {
  const {
    onClose,
    open,
    event,
    canPublish,
    confirm,
    isConference,
    isSession,
    session,
  } = props

  const dispatch = useAppDispatch()
  const updateInfo = isConference ? updateConferenceField : updateField

  const handleClose = () => {
    onClose()
  }

  const confirmPublish = () => {
    confirm()
    onClose()
  }

  let address
  if (event.currentLocation) {
    address = addressToString(event.currentLocation.attributes.field_address)
  }

  let publishText = isConference ? "Conference" : "Event"
  if (isSession) {
    publishText = "Session"
  }

  const isMultiDay =
    moment(event.startDate).format("MM-DD") !==
    moment(event.endDate).format("MM-DD")

  let dateDisplay
  if (isMultiDay) {
    dateDisplay = (
      <div className="flexRow">
        <div style={{ marginRight: 20 }}>
          <strong style={{ display: "block", fontSize: 12 }}>START</strong>
          {moment(event.startDate).format("dddd, MMMM Do, YYYY h:mma")}
        </div>

        <div>
          <strong style={{ display: "block", fontSize: 12 }}>END</strong>
          {moment(event.endDate).format("dddd, MMMM Do, YYYY h:mma")}
        </div>
      </div>
    )
  }

  return (
    <Dialog
      className="modal eventConfirmationModal"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <header>
        <h2>
          <span className={classNames("chip", isConference && "conference")} />
          {event.name}
        </h2>

        <div className="flexRow event-meta">
          <span className="icon calendar" />
          <span className="dateTime">
            {isMultiDay ? (
              <>{dateDisplay}</>
            ) : (
              <>
                {moment(event.startDate).format("dddd, MMMM Do, YYYY")}
                <br />
                {event.allDay ? (
                  <>All Day</>
                ) : (
                  <>
                    {moment(event.startDate).format("h:mma") !==
                      moment(event.endDate).format("h:mma") && (
                      <>
                        {moment(event.startDate).format("h:mma")} -{" "}
                        {moment(event.endDate).format("h:mma")}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </span>
        </div>

        {event.currentLocation && (
          <div className="flexRow event-meta">
            <span className="icon location" />
            <div>
              <div>
                <strong>{event.currentLocation.attributes.name}</strong>
              </div>
              {address && (
                <span className="highlight">
                  {addressToString(
                    event.currentLocation.attributes.field_address
                  )}
                </span>
              )}
            </div>
          </div>
        )}
      </header>

      {isSession ? (
        <p>Are you ready to publish this session?</p>
      ) : (
        <p>
          Are you ready to publish this {isConference ? "conference" : "event"}?
          Learners will be immediately notified by selecting either of the
          notification options below and the{" "}
          {isConference ? "conference" : "event"} will appear on their calendar.
        </p>
      )}

      {event.attendeeMethod !== "all" && (
        <ul className="current-people-list">
          {event.subGroups.length > 0 && (
            <>
              {event.subGroups.map((group) => {
                if (group) {
                  return (
                    <li key={group.id} className={"event-select-item"}>
                      <div className="flexRow">
                        <span className="icon group"></span>
                        <strong>{group.attributes.label}</strong>
                      </div>
                    </li>
                  )
                }
                return null
              })}
            </>
          )}

          {event.jobTitles.length > 0 && session.jobTitles.fetched && (
            <>
              {event.jobTitles.map((jobTitle) => {
                const activeJobTitle = session.jobTitles.data.find(
                  (item) => item.id === jobTitle.id
                )

                return (
                  <li
                    key={activeJobTitle.id}
                    className={classNames("event-select-item")}
                  >
                    <div className="flexRow">
                      <span
                        className={classNames(
                          "icon role",
                          activeJobTitle.attributes.name.toLowerCase()
                        )}
                      ></span>
                      <strong>{activeJobTitle.attributes.name}</strong>
                    </div>
                  </li>
                )
              })}
            </>
          )}
        </ul>
      )}

      {!isSession && (
        <>
          <div className="flexRow">
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  checked={event.sendPublishEmail}
                  onChange={(event) => {
                    dispatch(
                      updateInfo({
                        field: "sendPublishEmail",
                        value: event.target.checked,
                      })
                    )
                  }}
                  name="visible"
                />
              }
            />
            <span style={{ marginLeft: -20 }}>Notify via Email</span>
          </div>

          <div className="flexRow">
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  checked={event.pushNotifications}
                  onChange={(event) => {
                    dispatch(
                      updateInfo({
                        field: "pushNotifications",
                        value: event.target.checked,
                      })
                    )
                  }}
                  name="visible"
                />
              }
            />
            <span style={{ marginLeft: -20 }}>
              Notify via Push Notification
            </span>
          </div>
        </>
      )}

      <footer style={{ width: "100%", marginLeft: -10 }}>
        <Button
          style={{ flex: 1, margin: 0 }}
          onClick={handleClose}
          className="button small cancel"
        >
          Continue Editing
        </Button>
        {canPublish ? (
          <Button
            style={{
              flex: 1,
              margin: 0,
            }}
            onClick={confirmPublish}
            size="small"
          >
            Publish {publishText}
          </Button>
        ) : (
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Loading />
          </div>
        )}
      </footer>
    </Dialog>
  )
}

export default ModalEventConfirmation
