import { FieldArray, useFormikContext } from "formik"
import { Stack, Typography } from "@mui/material"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import DeleteButton from "components/DeleteButton"
import { CourseFormValues } from "../types"

type ListProps = {
  viewOnly?: boolean
}

export const ReviewersListIndividuals = ({ viewOnly }: ListProps) => {
  const { values } = useFormikContext<CourseFormValues>()

  return (
    <FieldArray
      name="reviewers"
      render={(arrayHelpers) => (
        <>
          {values.reviewers.map((user: any, index: number) => {
            let firstName,
              lastName,
              initials = ""
            if (user.attributes && !user.attributes.field_first_name) {
              firstName = user.attributes.display_name
              initials = firstName.toUpperCase().charAt(0)
            } else {
              firstName = user.attributes
                ? user.attributes.field_first_name
                : user.field_first_name
              lastName = user.attributes
                ? user.attributes.field_last_name
                : user.field_last_name
              if (firstName && lastName) {
                initials =
                  firstName.toUpperCase().charAt(0) +
                  lastName.toUpperCase().charAt(0)
              }
            }

            return (
              <Stack
                key={index}
                direction="row"
                sx={{
                  alignItems: "center",
                  marginBottom: "15px!important",
                  marginLeft: "0!important",
                  paddingRight: 6,
                }}
                spacing={2}
              >
                <UserInitial fontSize={12} size={30} initials={initials} />

                <Typography sx={{ fontWeight: "bold" }}>
                  {firstName} {lastName}
                </Typography>

                {!viewOnly && (
                  <DeleteButton onClick={() => arrayHelpers.remove(index)} />
                )}
              </Stack>
            )
          })}
        </>
      )}
    />
  )
}
