import { useAppSelector } from "store/hooks"
import { LineChart } from "@mui/x-charts/LineChart"

import { Box, Card, Typography } from "@mui/material"
import moment from "moment"
import "../../styles/reports.scss"
import theme from "@mobilemind/common/src/theme/theme"
import {
  OrgRubricCriteria,
  OrgRubricDomain,
} from "@mobilemind/common/src/types/rubrics"

type MonthChartProps = {
  width: number
}

export function MonthChart({ width }: MonthChartProps) {
  const analytics = useAppSelector((state) => state.analytics)

  const { dateFrom, dateTo, criteriaId } = analytics.filters
  const { monthData, singleRubricResult } = analytics

  const startYear = moment(dateFrom).year()
  const endYear = moment(dateTo).year()

  const yearsArray: number[] = []
  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year)
  }

  const xAxisData = Array.from({ length: 12 }, (item, index) => {
    const monthNum = index + 1
    const month =
      monthNum < 10 ? "0" + monthNum.toString() : monthNum.toString()

    return new Date("2023-" + month + "-15")
  })

  const seriesChunkData = yearsArray.map((year) => {
    const scoreArray = monthData
      .map((datapoint: any) => {
        if (datapoint.year === year) {
          return datapoint.totalScoreAverage
        } else return null
      })
      .filter((item) => item !== null)

    return scoreArray.filter((item) => item !== undefined)
  })

  const seriesLabelArray = seriesChunkData.map((item, index) => {
    return {
      label: yearsArray[index].toString(),
      data: item.map((item: any) => item),
    }
  })

  const monthColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    "#FFD86D",
    "#F3842A",
    "#2D48A7",
    "#D71B54",
    "#1B8C7D",
  ]

  let criteriaName: string = ""
  let criteriaDescription: string = ""
  if (criteriaId && singleRubricResult) {
    singleRubricResult.domain.forEach((domain: OrgRubricDomain) => {
      domain.criteria.forEach((criteria: OrgRubricCriteria) => {
        if (Number(criteria.criteria_id) === Number(criteriaId)) {
          criteriaName = criteria.name
          criteriaDescription = criteria.description
        }
      })
    })
  }

  return (
    <Card
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {xAxisData.length > 1 && (
        <LineChart
          colors={monthColors}
          xAxis={[
            {
              label: "Month",
              data: xAxisData,
              tickInterval: xAxisData,
              valueFormatter: (date) => {
                return moment(date).format("MMM")
              },
            },
          ]}
          yAxis={[{ label: "Average Score" }]}
          series={seriesLabelArray}
          grid={{ vertical: true, horizontal: true }}
          width={width}
          height={600}
        />
      )}

      {(criteriaName || criteriaDescription) && (
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box sx={{ padding: 5 }}>
            <Typography sx={{ fontWeight: "bold" }}>{criteriaName}</Typography>
            <Typography>{criteriaDescription}</Typography>
          </Box>
        </Box>
      )}
    </Card>
  )
}
