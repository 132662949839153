import { Component } from "react"
import { connect } from "react-redux"
import {
  updateProfile,
  updateUserPicture,
} from "@mobilemind/common/src/actions/user"

import { changeTab } from "../store/reducers/navbar"

import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material"
import classNames from "classnames"
import Loading from "@mobilemind/common/src/components/Loading"
import PropTypes from "prop-types"

import { getJobTitles } from "../features/jobTitles/jobTitlesSlice"

import ProfilePicture from "./ProfilePicture"
import "../styles/profile.scss"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { getSubGroups } from "../store/reducers/session"

import { openSnackbar } from "../features/snackbar/snackbarSlice"

const mapStateToProps = ({ jobTitles, session }) => {
  return {
    session,
    jobTitles: jobTitles.data.filter((title) => {
      return (
        title.attributes.name !== "School" &&
        title.attributes.name !== "District"
      )
    }),
    user: session.user,
  }
}

const mapDispatchToProps = {
  updateProfile,
  getJobTitles,
  getSubGroups,
  changeTab,
  updateUserPicture,
  openSnackbar,
}

class ProfileLayout extends Component {
  constructor(props) {
    super(props)
    let jobTitleOther = this.props.user.attributes.field_job_title_other

    this.state = {
      timezone: this.props.user.attributes.timezone,
      jobTitle:
        this.props.user.relationships.field_job_title.data &&
        this.props.user.relationships.field_job_title.data.id,
      jobTitleOther: jobTitleOther ? jobTitleOther : "",
      isOther: jobTitleOther ? true : false,
      firstName: this.props.user.attributes.field_first_name,
      lastName: this.props.user.attributes.field_last_name,
      digestFreq: this.props.user.attributes.field_reviewer_email_frequency,
      bio: this.props.user.attributes.field_bio
        ? this.props.user.attributes.field_bio
        : "",
      isSaving: false,
      hasChanged: false,
    }

    this.updateFirstName = this.updateFirstName.bind(this)
    this.updateLastName = this.updateLastName.bind(this)
  }

  componentDidUpdate(prevProps) {
    // Once we get the user, determine if they have an "other" job title
    if (prevProps.user !== this.props.user) {
      if (this.props.user.attributes.field_job_title_other) {
        this.setState({ isOther: true })
      }
    }
  }

  componentDidMount = () => {
    this.props.changeTab(null)
    this.props.getJobTitles()
    this.props.session.group.uuid &&
      !this.props.session.subGroups.fetched &&
      this.props.getSubGroups(this.props.session.group.uuid[0].value)
  }

  updateFirstName = (event) => {
    const { user } = this.props
    let hasChanged =
      user.relationships.field_job_title.data &&
      (event.target.value !== user.attributes.field_first_name ||
        user.jobTitle.id !== this.state.jobTitle)
    this.setState({ firstName: event.target.value, hasChanged: hasChanged })
  }

  updateFreq = (event) => {
    const { user } = this.props
    let hasChanged =
      user.relationships.field_job_title.data &&
      event.target.value !== user.attributes.field_reviewer_email_frequency
    this.setState({ digestFreq: event.target.value, hasChanged: hasChanged })
  }

  updateTimezone = (event) => {
    const { user } = this.props
    let hasChanged =
      user.attributes.timezone &&
      event.target.value !== user.attributes.timezone
    this.setState({ timezone: event.target.value, hasChanged: hasChanged })
  }

  updateLastName = (event) => {
    const { user } = this.props
    let hasChanged =
      user.relationships.field_job_title.data &&
      (event.target.value !== user.attributes.field_last_name ||
        user.jobTitle.id !== this.state.jobTitle)
    this.setState({ lastName: event.target.value, hasChanged: hasChanged })
  }

  updateJobTitle = (event) => {
    const { user, jobTitles } = this.props
    let userJobTitle = user.relationships.field_job_title.data
    let hasChanged =
      userJobTitle &&
      (userJobTitle.id !== event.target.value ||
        user.attributes.field_first_name !== this.state.firstName ||
        user.attributes.field_last_name !== this.state.lastName)

    let jobTitle = jobTitles.find((title) => title.id === event.target.value)
    if (jobTitle.attributes.name.includes("Other")) {
      this.setState({ jobTitle: event.target.value, isOther: true })
    } else {
      this.setState({
        jobTitle: event.target.value,
        jobTitleOther: "",
        isOther: false,
        hasChanged: hasChanged,
      })
    }
  }

  updateJobTitleOther = (event) => {
    this.setState({ jobTitleOther: event.target.value, hasChanged: true })
  }

  updateBio = (event) => {
    this.setState({ bio: event.target.value, hasChanged: true })
  }

  removeImage = () => {
    let profile = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      jobTitle: this.state.jobTitle,
      jobTitleOther: this.state.jobTitleOther,
    }

    this.props.updateUserPicture()
    this.setState({ userPicture: null })
    this.props.updateProfile(profile, this.props.session, true)
  }

  save = async () => {
    this.setState({ isSaving: true })

    let profile = {
      bio: this.state.bio,
      timezone: this.state.timezone,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      jobTitle: this.state.jobTitle,
      jobTitleOther: this.state.jobTitleOther,
      digestFreq: this.state.digestFreq,
    }

    await this.props.updateProfile(profile, this.props.session)

    this.props.openSnackbar({ message: "Profile saved." })
    this.setState({ isSaving: false, hasChanged: false, isSaved: true })

    setTimeout(() => {
      this.setState({ isSaved: false })
    }, 1800)
  }

  render() {
    const {
      firstName,
      lastName,
      timezone,
      jobTitle,
      isSaving,
      isSaved,
      digestFreq,
      hasChanged,
    } = this.state
    const { session } = this.props

    let allRoles = session.orgRoles.concat(session.groupRoles).join(",")
    const showDigestFrequency =
      allRoles.includes("admin") || allRoles.includes("reviewer")
    const showBio =
      allRoles.includes("personnel") ||
      allRoles.includes("admin") ||
      allRoles.includes("scheduler")
    let locked = isSaving || isSaved

    return (
      <div className="page profile">
        <div
          className={classNames("flexRow profileWrapper", locked && "locked")}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: 35,
            }}
          >
            <ProfilePicture removeImage={this.removeImage} />
          </div>
          <div className="profileContent">
            <h2>Your Profile</h2>
            <div className="profileForm">
              <FormControl variant="standard">
                <TextField
                  variant="standard"
                  label="First Name"
                  value={firstName}
                  onChange={this.updateFirstName}
                />
              </FormControl>
              <FormControl variant="standard">
                <TextField
                  variant="standard"
                  label="Last Name"
                  value={lastName}
                  onChange={this.updateLastName}
                />
              </FormControl>
              <FormControl variant="standard" className="inputSelect">
                <InputLabel id="label-job-title-select">Job Title</InputLabel>
                <Select
                  variant="standard"
                  labelId="label-job-title-select"
                  id="job-title-select"
                  value={jobTitle}
                  onChange={this.updateJobTitle}
                >
                  {this.props.jobTitles &&
                    this.props.jobTitles.map((jobTitle) => {
                      return (
                        <MenuItem key={jobTitle.id} value={jobTitle.id}>
                          {jobTitle.attributes.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
              {this.state.isOther && (
                <FormControl variant="standard">
                  <TextField
                    variant="standard"
                    label="Please specify your job title"
                    value={this.state.jobTitleOther}
                    onChange={this.updateJobTitleOther}
                  />
                </FormControl>
              )}

              <FormControl variant="standard" className="timezone-select">
                <InputLabel>Time Zone</InputLabel>
                <Select
                  variant="standard"
                  value={timezone}
                  onChange={this.updateTimezone}
                >
                  <MenuItem value={"America/New_York"}>
                    Eastern Standard Time
                  </MenuItem>
                  <MenuItem value={"America/Chicago"}>
                    Central Standard Time
                  </MenuItem>
                  <MenuItem value={"America/Denver"}>
                    Mountain Standard Time
                  </MenuItem>
                  <MenuItem value={"America/Los_Angeles"}>
                    Pacific Standard Time
                  </MenuItem>
                  <MenuItem value={"America/Anchorage"}>
                    Alaska Standard Time
                  </MenuItem>
                </Select>
              </FormControl>

              {showDigestFrequency && (
                <FormControl variant="standard" className="inputSelect">
                  <InputLabel id="label-frequency-select">
                    Reviewer Digest Frequency
                  </InputLabel>
                  <Select
                    variant="standard"
                    labelId="label-frequency-select"
                    id="frequency-select"
                    value={digestFreq}
                    onChange={this.updateFreq}
                  >
                    <MenuItem key={"daily"} value={"daily"}>
                      Daily
                    </MenuItem>
                    <MenuItem key={"weekly"} value={"weekly"}>
                      Weekly
                    </MenuItem>
                  </Select>
                </FormControl>
              )}

              {showBio && (
                <FormControl variant="standard" className="bio">
                  <TextField
                    variant="standard"
                    label="Presenter Bio"
                    value={this.state.bio}
                    multiline
                    onChange={this.updateBio}
                  />
                  <div style={{ marginTop: -35, marginBottom: 25 }}>
                    <FormHelperText>
                      This is displayed on events where you are listed as a
                      presenter.
                    </FormHelperText>
                  </div>
                </FormControl>
              )}

              <footer>
                {hasChanged && !isSaving && (
                  <ButtonLarge
                    onClick={this.save}
                    className={"button primary large"}
                  >
                    Save Profile
                  </ButtonLarge>
                )}
                {isSaving && (
                  <div>
                    <Loading message="Saving Profile..." />
                  </div>
                )}
              </footer>
            </div>
          </div>
        </div>

        {session.subgroups && session.subgroups.data && (
          <div className={classNames("profileCard")}>
            <div className={classNames("profileContent groupList")}>
              <h3>
                <span>Your Groups</span>
              </h3>

              <ul>
                {session.subgroups.data.map((group, index) => {
                  return (
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          width: 25,
                          height: 25,
                          marginRight: 10,
                        }}
                        className="icon school"
                      />
                      <span>{group.attributes.label}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    )
  }
}

ProfileLayout.propTypes = {
  jobTitles: PropTypes.array,
  session: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLayout)
