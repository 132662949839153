import { FormControl, InputLabel, MenuItem } from "@mui/material"

import "./marketplace.scss"
import _ from "lodash"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { Formik, Form, Field, useFormikContext } from "formik"

import { getMarketplaceItems } from "store/reducers/marketplace"

import { TextField, TextFieldProps, Select } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useAppDispatch } from "store/hooks"

const initialValues = {
  search: "",
  added: "any",
  status: "any",
  pricing: "any",
}

export const SidebarMarketplace = () => {
  const dispatch = useAppDispatch()
  const [initialFetched, setInitialFetched] = useState(false)

  useEffect(() => {
    if (!initialFetched) {
      dispatch(getMarketplaceItems(initialValues))
      setInitialFetched(true)
    }
  }, [initialFetched, dispatch])

  return (
    <div
      id="sidebar"
      className="marketplace-sidebar"
      style={{ paddingTop: 15 }}
    >
      <div className="filters">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            dispatch(getMarketplaceItems(values))
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <header style={{ marginBottom: 15 }}>
                <strong>Filter Partners</strong>
                <ButtonSmall
                  onClick={() => {
                    setFieldValue("search", "")
                    setFieldValue("added", "any")
                    setFieldValue("pricing", "any")
                    setFieldValue("status", "any")
                    dispatch(getMarketplaceItems(initialValues))
                  }}
                >
                  Reset
                </ButtonSmall>
              </header>
              <SearchField
                onChange={(event) => {
                  setFieldValue("search", event.target.value)
                }}
              />

              <FormControl margin="normal" style={{ marginTop: 25 }}>
                <InputLabel id="pricing-label">Pricing</InputLabel>
                <Field name="pricing" component={SelectComponent}>
                  <MenuItem value={"any"}>Any</MenuItem>
                  <MenuItem value={"0"}>Free</MenuItem>
                  <MenuItem value={"1"}>Premium</MenuItem>
                </Field>
              </FormControl>
              <FormControl margin="normal">
                <InputLabel id="added-label">Added</InputLabel>
                <Field name="added" component={SelectComponent}>
                  <MenuItem value={"any"}>Any</MenuItem>
                  <MenuItem value={"1"}>Added</MenuItem>
                  <MenuItem value={"0"}>Not Added</MenuItem>
                </Field>
              </FormControl>

              <FormControl margin="normal">
                <InputLabel id="status-label">Partner Status</InputLabel>
                <Field name="status" component={SelectComponent}>
                  <MenuItem value={"any"}>Any</MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"underconstruction"}>Coming Soon</MenuItem>
                </Field>
              </FormControl>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const SelectComponent = ({ children, form, field }: any) => {
  const { name, value } = field
  const { setFieldValue } = form
  const { submitForm } = useFormikContext()

  return (
    <Select
      name={name}
      value={value}
      onChange={(event) => {
        setFieldValue(name, event.target.value)
        submitForm()
      }}
    >
      {children}
    </Select>
  )
}
const SearchField = (props: TextFieldProps) => {
  const { submitForm } = useFormikContext()

  const DEFAULT_DEBOUNCE_WAIT = 750
  const debounce = _.debounce(submitForm, DEFAULT_DEBOUNCE_WAIT)
  const debounceSubmitForm = useCallback(debounce, [submitForm]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Field
      as={TextField}
      {...props}
      name="search"
      label="Search"
      onChange={(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => {
        props.onChange?.(event)
        debounceSubmitForm()
      }}
    />
  )
}
