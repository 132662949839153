import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import _ from "lodash"

export const fetchActiveUser = createAsyncThunk(
  "activeUserSlice/fetchActiveUser",
  async (userId) => {
    let query = {
      filter: {
        drupal_internal__uid: userId,
      },
    }

    let response = await fetchWrapper.get(
      "/api/user/user?" +
        qs.stringify(query) +
        "&include=roles,user_picture,field_job_title"
    )
    let data = await response.json()
    let user = data.data[0]
    let image =
      data.included &&
      data.included.find((included) => included.type === "file--image")
    user.user_picture = image ? image.attributes.uri.url : "default_temp"
    return user
  }
)

export const activeUserSlice = createSlice({
  name: "activeUserSlice",
  initialState: {
    fetched: false,
    learningPaths: {
      fetched: false,
      sort: "Name",
      sortOrder: "asc",
      data: [],
    },
    badges: {
      fetched: false,
      sort: "Name",
      sortOrder: "asc",
      data: [],
    },
    assessments: {
      fetched: false,
      sort: "Name",
      sortOrder: "asc",
      data: [],
    },
    user: {
      user_picture: "",
    },
  },
  reducers: {
    setActiveUser: (state, action) => {
      state.user = action.payload
      state.fetched = true
    },
    setUserBadges: (state, action) => {
      // Filter out badges with 'missing' ids because drupal is dumb
      let newUserBadges = action.payload.filter(
        (userBadge) => userBadge.relationships.field_badge.data.id !== "missing"
      )
      newUserBadges.forEach((badge) => {
        if (!state.badges.data.find((existing) => existing.id === badge.id)) {
          state.badges.data.push(badge)
        }
      })
      state.badges.fetched = true
      state.badges.data = _.orderBy(
        state.badges.data,
        (badge) => badge.attributes.name
      )
    },
    getBadges: (state, action) => {
      state.badges.data.forEach((userBadge) => {
        userBadge.badge = action.payload.find(
          (badge) => badge.id === userBadge.relationships.field_badge.data.id
        )
      })
    },
    setSort: (state, action) => {
      state[action.payload.type].sort = action.payload.value
    },
    setSortOrder: (state, action) => {
      state[action.payload].data.reverse()
      state[action.payload].sortOrder =
        state[action.payload].sortOrder === "desc" ? "asc" : "desc"
    },
  },
  extraReducers: {
    "session/getUserLearningPaths": (state, action) => {
      let userLearningPaths = action.payload.data.filter((userPath) => {
        userPath.learningPath =
          action.meta &&
          action.meta.included.find(
            (path) =>
              path.id === userPath.relationships.field_learning_path.data.id
          )
        return userPath.attributes.field_status === "completed"
      })
      state.learningPaths.fetched = true
      state.learningPaths.data = userLearningPaths
    },
    "assessments/get": (state, action) => {
      let assessments = action.payload.map((assessment) => {
        assessment.course = action.meta.included.find(
          (course) =>
            course.id === assessment.relationships.field_course.data.id
        )
        return assessment
      })

      let completedAssessments = assessments.filter(
        (assessment) => assessment.attributes.field_status === "completed"
      )
      completedAssessments.forEach((assessment) => {
        if (
          !state.assessments.data.find(
            (existing) => existing.id === assessment.id
          )
        ) {
          state.assessments.data.push(assessment)
        }
      })
      state.assessments.fetched = true
      state.assessments.data = _.orderBy(
        state.assessments.data,
        (assessment) => assessment.course && assessment.course.attributes.name
      )
    },
    [fetchActiveUser.pending]: (state) => {
      state.fetched = false

      state.learningPaths.fetched = false
      state.badges.fetched = false
      state.assessments.fetched = false

      state.learningPaths.data = []
      state.badges.data = []
      state.assessments.data = []
    },
    [fetchActiveUser.fulfilled]: (state, action) => {
      state.fetched = true
      state.user = action.payload
    },
  },
})

export const sortedLearningPaths = (state) => {
  let sort = state.activeUser.learningPaths.sort
  let learningPaths = state.activeUser.learningPaths.data

  let sorted = []
  if (sort === "Name") {
    sorted = _.orderBy(
      learningPaths,
      (userPath) =>
        userPath.learningPath && userPath.learningPath.attributes.name,
      state.activeUser.learningPaths.sortOrder
    )
  }
  if (sort === "Date Completed") {
    sorted = _.orderBy(
      learningPaths,
      (userPath) => userPath.attributes.changed,
      state.activeUser.learningPaths.sortOrder
    )
  }
  return sorted
}

export const sortedBadges = (state) => {
  let sort = state.activeUser.badges.sort
  let userBadges = state.activeUser.badges.data

  let sorted = []
  if (sort === "Name") {
    sorted = _.orderBy(
      userBadges,
      (userBadge) => userBadge.badge && userBadge.badge.attributes.name,
      state.activeUser.badges.sortOrder
    )
  }
  if (sort === "Date Earned") {
    sorted = _.orderBy(
      userBadges,
      (userBadge) => userBadge.attributes.created,
      state.activeUser.badges.sortOrder
    )
  }
  return sorted
}

export const sortedAssessments = (state) => {
  let sort = state.activeUser.assessments.sort
  let assessments = state.activeUser.assessments.data
  let categories = state.categories.data

  let sorted = []
  if (sort === "Name") {
    sorted = _.orderBy(
      assessments,
      (assessment) => assessment.course && assessment.course.attributes.name,
      state.activeUser.assessments.sortOrder
    )
  }
  if (sort === "Category") {
    sorted = _.orderBy(
      assessments,
      (assessment) => {
        let category = categories.find(
          (cat) =>
            assessment.course &&
            cat.id === assessment.course.relationships.field_category.data.id
        )
        return category && category.attributes.name
      },
      state.activeUser.assessments.sortOrder
    )
  }
  if (sort === "Date Completed") {
    sorted = _.orderBy(
      assessments,
      (assessment) => assessment.attributes.field_completed_date,
      state.activeUser.assessments.sortOrder
    )
  }
  return sorted
}

export const { setActiveUser, setSort, setSortOrder } = activeUserSlice.actions

export default activeUserSlice.reducer
