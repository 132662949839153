import { SubmissionType } from "@mobilemind/common/src/types/course"
import yup from "@mobilemind/common/src/utility/yup"
import { isGroupLevel } from "functions"
import { CourseFormValues, MultipleChoiceQuestionAnswer } from "./types"

export const overviewSchema = yup.object({
  field_category: yup.mixed().label("Category").required(),
  name: yup
    .string()
    .label("Course title")
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .max(50, "${label} cannot exceed 50 characters"),
  objective: yup.string().label("Learn").required(),
  content: yup.string().label("Apply").required(),
  estimatedTime: yup
    .number()
    .label("Estimated time")
    .nullable()
    .transform((value, originalValue) => {
      // Cast empty string to null
      if (originalValue === "") {
        return null
      }
      // Otherwise, return the (numeric) value
      return value
    })
    // eslint-disable-next-line no-template-curly-in-string
    .min(0, "${label} cannot be negative")
    // eslint-disable-next-line no-template-curly-in-string
    .max(1000, "${label} cannot exceed 1,000 minutes"),
})

export const checkboxSchema = yup.object({
  checkboxOptions: yup
    .array()
    .of(yup.string().required())
    .required()
    .min(2, "At least 2 poll options"),
})

export const multipleChoiceAnswerSchema = yup.object({
  attributes: yup
    .object({
      field_mc_answer: yup
        .string()
        .required("Cannot publish with empty multiple choice answers"),
      field_mc_answer_type: yup.string().oneOf(["correct", "incorrect"]),
    })
    .required(),
}) as yup.ObjectSchema<MultipleChoiceQuestionAnswer>

export const multipleChoiceSchema = yup.object({
  question: yup.string().label("Question").required(),
  answers: yup
    .array()
    .of(multipleChoiceAnswerSchema)
    .min(2, "At least 2 multiple choice answers")
    .test({
      test: (answers) => {
        return answers?.some(
          (answer) => answer?.attributes?.field_mc_answer_type === "correct"
        )
      },
      message: "At least 1 correct multiple choice answer",
    }),
})

const quizQuestionAnswerSchema = yup.object({
  value: yup.string().label("Answer").required("Missing quiz answer(s)"),
})

const quizQuestionSchema = yup.object({
  value: yup.string().label("Question").required("Missing quiz question(s)"),
  answers: yup.array().of(quizQuestionAnswerSchema).required(),
})

const quizSchema = yup.object({
  quizQuestions: yup
    .array()
    .of(quizQuestionSchema)
    .min(1, "Missing quiz question(s)"),
})

export const getChallengeSchema = (values: CourseFormValues) => {
  return yup
    .object({
      learnerInstructions: yup.string().label("Instructions").required(),
      question: yup
        .string()
        .label("Question")
        .when("submissionType", {
          is: (value: SubmissionType) => value !== SubmissionType.Quiz,
          then: (schema) => schema.required(),
          // Question is not used with `Quiz` submission types, since each
          // item in the question has its own question.
          otherwise: (schema) => schema.strip(),
        }),
      reviewers: yup.mixed(),
      reviewerGroups: yup.mixed(),
    })
    .withMutation((schema) => {
      switch (values.submissionType) {
        case SubmissionType.Checkbox: {
          return schema.concat(checkboxSchema)
        }
        case SubmissionType.MultipleChoice: {
          return schema.concat(multipleChoiceSchema)
        }
        case SubmissionType.Quiz: {
          return schema.concat(quizSchema)
        }
        default: {
          return schema
        }
      }
    })
    .withMutation((schema) => {
      if (
        !values.isTemplate &&
        (values.submissionType === SubmissionType.Url ||
          values.submissionType === SubmissionType.Image ||
          (values.submissionType === SubmissionType.Text &&
            values.reviewMethod === "manual"))
      ) {
        schema.test({
          test: function (values) {
            if (!values?.reviewers?.length && !values?.reviewerGroups?.length) {
              return this.createError({
                path: "reviewers",
                message: "Assign at least 1 reviewer",
              })
            } else {
              return true
            }
          },
        })
      }
      return schema
    })
}

export const challengeSchema = yup.lazy(getChallengeSchema)

export const getSettingsSchema = (session: any) => {
  return yup.object({
    supportEmail: yup.string().label("Support email").email(),
    subGroups: yup.array().withMutation((schema) => {
      if (isGroupLevel(session) && session.subgroups?.data?.length > 1) {
        schema.min(1, "Make visible to at least 1 group")
      }
      return schema
    }),
  })
}
