import { createNextState } from "@reduxjs/toolkit"
import classNames from "classnames"
import { useFormikContext } from "formik"
import "react-quill/dist/quill.snow.css"
import { useAppSelector } from "store/hooks"
import DeleteButton from "../../../components/DeleteButton"
import "../../../styles/external-pd.scss"
import { ExternalEvent } from "./RecommendedEventModal"

export function ParticipantList() {
  const { values, setValues } = useFormikContext<ExternalEvent>()
  const session = useAppSelector((state) => state.session)
  const jobTitles = useAppSelector((state) => state.jobTitles)

  return (
    <ul style={{ marginBottom: 10 }} className="current-people-list">
      {values.subGroups.length > 0 && session.subGroups.fetched && (
        <>
          {values.subGroups.map((group) => {
            const activeGroup: any = session.subGroups.data.find(
              (item: any) => item.entity_id === Number(group.entity_id)
            )
            if (activeGroup) {
              return (
                <li key={activeGroup.id} className={"event-select-item"}>
                  <div className="flexRow">
                    <span className="icon group"></span>
                    <strong>{activeGroup.attributes.label}</strong>
                    <DeleteButton
                      onClick={() => {
                        setValues(
                          createNextState((draft) => {
                            // TODO(bn): use lodash `remove`
                            draft.subGroups = draft.subGroups.filter(
                              (existing) =>
                                existing.entity_id !== activeGroup.entity_id
                            )
                          })
                        )
                      }}
                    />
                  </div>
                </li>
              )
            }
            return null
          })}
        </>
      )}
      {values.jobTitles.length > 0 && jobTitles.fetched && (
        <>
          {values.jobTitles.map((jobTitle) => {
            const activeJobTitle = jobTitles.data.find((item: any) => {
              return (
                item.id === jobTitle.id ||
                item.attributes.drupal_internal__tid === jobTitle.entity_id
              )
            }) as any
            if (activeJobTitle) {
              return (
                <li
                  key={activeJobTitle.id}
                  className={classNames("event-select-item")}
                >
                  <div className="flexRow">
                    <span
                      className={classNames(
                        "icon role",
                        activeJobTitle.attributes.name.toLowerCase()
                      )}
                    ></span>
                    <strong>{activeJobTitle.attributes.name}</strong>
                    <DeleteButton
                      onClick={() => {
                        setValues(
                          createNextState((draft) => {
                            // TODO(bn): use lodash `remove`
                            draft.jobTitles = draft.jobTitles.filter(
                              (existing) => existing.id !== activeJobTitle.id
                            )
                          })
                        )
                      }}
                    />
                  </div>
                </li>
              )
            }
            return null
          })}
        </>
      )}
    </ul>
  )
}
