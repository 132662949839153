import { Component } from "react"
import { connect } from "react-redux"
import { Button } from "@mui/material"

import {
  startNewEvent,
  setIsFetched,
  breakEventLock,
  changeEventTab,
} from "../eventSingle/activeEventSlice"

import classNames from "classnames"

import { setIsEditingEvent, breakConferenceLock } from "./activeConferenceSlice"
import { getCourses } from "../../../actions/courses"
import { getOrgBadges } from "../../../actions/badges"

import EventDetails from "../eventSingle/EventDetails"
import EventLocationContainer from "../eventSingle/EventLocationContainer"
import EventRegistration from "../eventSingle/EventRegistration"
import EventPersonnelContainer from "../eventSingle/EventPersonnelContainer"
import EventResources from "../eventSingle/EventResources"
import EventAttendees from "../eventSingle/EventAttendees"
import Loading from "@mobilemind/common/src/components/Loading"

import EventNavbarContainer from "../eventSingle/EventNavbarContainer"
import ErrorBoundary from "../../../components/ErrorBoundary"

import ContentLock from "../../../components/ContentLock"
import { setContentLock } from "../../../actions"

const mapStateToProps = ({
  session,
  activeEvent,
  activeConference,
  entityList,
}) => {
  return {
    activeEvent,
    isSession: true,
    conference: activeConference,
    orgBadges: entityList.orgBadges,
    session,
  }
}

const mapDispatchToProps = {
  changeEventTab,
  startNewEvent,
  setIsEditingEvent,
  getCourses,
  getOrgBadges,
  breakEventLock,
  breakConferenceLock,
  setContentLock,
  setIsFetched,
}

class ConferenceEventLayout extends Component {
  componentDidMount = () => {
    const { activeEvent, session } = this.props

    if (!activeEvent.editorLock) {
      this.props.setContentLock({
        id: activeEvent.id,
        type: "mobilemind_event_entity--conference_event",
        clear: false,
      })
    }

    this.unlisten = this.props.history.listen(() => {
      const id = activeEvent.currentEditor && activeEvent.currentEditor.id
      if (!activeEvent.editorLock || (id && id === session.user.id)) {
        if (!activeEvent.isSaving) {
          this.props.setContentLock({
            type: "mobilemind_event_entity--conference_event",
            id: activeEvent.id,
            clear: true,
          })
        }
      }
    })

    this.props.setIsFetched(true)

    !this.props.orgBadges.fetched &&
      this.props.getOrgBadges({
        searchQuery: "",
        status: "any",
        category: null,
        subGroup: "any",
        jobTitle: "any",
        sortBy: "created",
        sortOrder: "DESC",
      })

    window.addEventListener("keydown", this.handleEsc)
  }
  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.handleEsc)
  }

  handleEsc = (event) => {
    if (event.keyCode === 27) {
      this.goBack()
    }
  }

  goBack = () => {
    const { activeEvent, session } = this.props

    // If we are the one editing this, clear the lock out when we leave
    if (
      !activeEvent.currentEditor ||
      activeEvent.currentEditor.id === session.user.id
    ) {
      this.props.setContentLock({
        id: activeEvent.id,
        type: "mobilemind_event_entity--conference_event",
        clear: true,
      })
    }

    this.props.setIsEditingEvent(false)
    setTimeout(() => {
      this.props.startNewEvent()
    }, 1000)
    this.props.setIsFetched(true)
  }

  render() {
    const props = this.props
    const { activeEvent } = props

    const { currentTab } = activeEvent

    let highlightedTabs = activeEvent.missingFields.map((field) => {
      if (field.includes("Details")) {
        return "details"
      }
      if (field.includes("Location")) {
        return "location"
      }
      if (field.includes("Participants")) {
        return "participants"
      }
      return null
    })

    return (
      <ErrorBoundary>
        {activeEvent.isSaving && (
          <div style={{ position: "relative", left: -325 }}>
            <Loading
              fullPage={true}
              message={
                this.props.isSession ? "Saving session..." : "Saving event..."
              }
            />
          </div>
        )}

        <div
          className={classNames(
            "conference-event-edit ",
            activeEvent.isSaving && "saving"
          )}
        >
          <Button
            onClick={() => {
              this.goBack()
            }}
            className="button small back"
          >
            <span className="icon back white" />
            Back to Schedule
          </Button>
          {activeEvent.editorLock && activeEvent.currentEditor && (
            <ContentLock
              contentType={"session"}
              editor={activeEvent.currentEditor}
              id={activeEvent.id}
              type={"mobilemind_event_entity--conference_event"}
              editorLock={activeEvent.editorLock}
              updateLock={() => this.props.breakEventLock()}
            />
          )}
          <EventNavbarContainer history={this.props.history} isSession={true} />
          <div
            className={classNames(
              "sidebar sidebar-event sidebar-conference-event",
              !activeEvent.fetched && "disabled"
            )}
          >
            <ul className="sidebar-tabs">
              <li className="divider">Required</li>
              <li
                onClick={() => {
                  this.props.changeEventTab("details")
                }}
                className={classNames(
                  activeEvent.currentTab === "details" && "active",
                  highlightedTabs.includes("details") && "highlight"
                )}
              >
                <span className="icon details" />
              </li>
              <li
                onClick={() => {
                  this.props.changeEventTab("location")
                }}
                className={classNames(
                  activeEvent.currentTab === "location" && "active",
                  highlightedTabs.includes("location") && "highlight"
                )}
              >
                <span className="icon location" />
              </li>
              <li
                onClick={() => {
                  this.props.changeEventTab("participants")
                }}
                className={classNames(
                  activeEvent.currentTab === "participants" && "active",
                  highlightedTabs.includes("participants") && "highlight"
                )}
              >
                <span className="icon attendees" />
              </li>

              <li className="divider">Optional</li>
              <li
                onClick={() => {
                  this.props.changeEventTab("resources")
                }}
                className={classNames(
                  activeEvent.currentTab === "resources" && "active"
                )}
              >
                <span className="icon laptop black" />
              </li>
              <li
                onClick={() => {
                  this.props.changeEventTab("personnel")
                }}
                className={classNames(
                  activeEvent.currentTab === "personnel" && "active"
                )}
              >
                <span className="icon personnel" />
              </li>
              <li
                onClick={() => {
                  this.props.changeEventTab("registration")
                }}
                className={classNames(
                  activeEvent.currentTab === "registration" && "active"
                )}
              >
                <span className="icon registration" />
              </li>
            </ul>
          </div>
          {currentTab === "details" && <EventDetails {...props} />}
          {currentTab === "location" && <EventLocationContainer {...props} />}
          {currentTab === "participants" && <EventAttendees {...props} />}
          {currentTab === "resources" && <EventResources {...props} />}
          {currentTab === "personnel" && <EventPersonnelContainer {...props} />}
          {currentTab === "registration" && <EventRegistration {...props} />}
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConferenceEventLayout)
