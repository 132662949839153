import { SubmissionType } from "@mobilemind/common/src/types/course"
import { GenieRewriteAllowedField } from "@mobilemind/genie-api/src/types"
import { LoadingButton } from "@mui/lab"
import { ButtonProps } from "@mui/material"
import { useAsync } from "@react-hook/async"
import { useFormikContext } from "formik"
import { useMemo } from "react"
import { useAppDispatch } from "store/hooks"
import { ReactComponent as MagicLampIcon } from "../../../img/magic-lamp.svg"
import {
  generateQuestion,
  generateQuizQuestion,
  showCourseGenieError,
  showPopper,
} from "../store/courseGenieSlice"
import { CourseFormValues } from "../types"

type GenieRecreateQuestionButtonProps = ButtonProps & {
  field: GenieRewriteAllowedField
  name: string
}

export const GenieRecreateQuestionButton = (
  props: GenieRecreateQuestionButtonProps
) => {
  const { field, name, ...otherProps } = props

  const dispatch = useAppDispatch()
  const { setFieldValue, setValues, values } =
    useFormikContext<CourseFormValues>()
  const [{ status }, handleClick] = useAsync(async () => {
    try {
      if (values.submissionType === SubmissionType.Quiz) {
        const response = await dispatch(generateQuizQuestion()).unwrap()
        setFieldValue(name, response)
      } else {
        const response = await dispatch(
          generateQuestion(values.submissionType)
        ).unwrap()
        setValues((values) => {
          return {
            ...values,
            ...response,
          }
        })
      }
    } catch {
      dispatch(showCourseGenieError())
    } finally {
      dispatch(showPopper(null))
    }
  })

  const text = useMemo(() => {
    switch (values.submissionType) {
      case SubmissionType.MultipleChoice:
      case SubmissionType.Checkbox:
      case SubmissionType.Quiz: {
        return "Create New Question + Anwsers"
      }
      default: {
        return "Create New Question"
      }
    }
  }, [values.submissionType])

  return (
    <LoadingButton
      {...otherProps}
      onClick={handleClick}
      loading={status === "loading"}
      loadingPosition="start"
      variant="text"
      size="small"
      startIcon={<MagicLampIcon width={20} height={20} fill="currentColor" />}
    >
      {text}
    </LoadingButton>
  )
}
