import { Dialog } from "@mui/material"
import { Card, Button } from "@mui/material"
import classNames from "classnames"
import "../styles/modal.scss"
import "../styles/widget.scss"

import Rating from "./Rating"
import CategoryIcon from "./CategoryIcon"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"

function ModalAnnouncementPreview(props) {
  const {
    onClose,
    open,
    label,
    featuredCourse,
    featuredLearningPath,
    announcement,
  } = props

  let isTip = label === "Tip of the Day"
  let isFeaturedCourse = label === "Featured Course"
  let isFeaturedLearningPath = label === "Featured Learning Path"

  let imageSrc
  if (announcement.image) {
    if (announcement.image.attributes) {
      imageSrc =
        process.env.REACT_APP_API_URL + announcement.image.attributes.uri.url
    } else {
      imageSrc = announcement.image
    }
  }

  let categoryLabel
  if (isFeaturedCourse) {
    categoryLabel =
      featuredCourse &&
      featuredCourse.category &&
      featuredCourse.category.attributes.name.toLowerCase()
  }

  return (
    <Dialog
      className="modal announcement-preview"
      onClose={() => onClose()}
      open={open}
    >
      <p>
        Here's how this announcement will appear on the{" "}
        <strong>Dashboard</strong> of the <strong>Learn</strong> app.
      </p>
      <>
        {!isFeaturedCourse && !isFeaturedLearningPath && (
          <Card className={classNames("widget announcement")}>
            {label && (
              <div className={classNames("widgetLabel", label.toLowerCase())}>
                {label}
              </div>
            )}
            <div className="timestamp">Posted Today</div>
            {!isTip && !isFeaturedCourse && <h2>{announcement.name}</h2>}
            {imageSrc && (
              <div className="announcementImage">
                <img src={imageSrc} alt={announcement.name} />
              </div>
            )}
            {isTip && (
              <div className="flexRow">
                {!imageSrc && <div className="icon tip" />}
                {announcement.body && <p>{announcement.body}</p>}
              </div>
            )}
            {!isTip && announcement.body && (
              <SanitizedHTML html={announcement.body} />
            )}
          </Card>
        )}
        {isFeaturedCourse && featuredCourse && (
          <Card className={classNames("widget announcement featuredCourse")}>
            {label && (
              <div className={classNames("widgetLabel", label.toLowerCase())}>
                {label}
              </div>
            )}
            <div className="timestamp">Posted Today</div>
            <div className="card-image" data-bg={categoryLabel}>
              <header>
                <CategoryIcon category={featuredCourse.category} />
                <div className="course-label">
                  <span className="category-label">{categoryLabel}</span>
                  <div className="course-level">
                    <Rating
                      level={featuredCourse.attributes.field_level.toLowerCase()}
                    />
                  </div>
                </div>
              </header>
              <h2>{featuredCourse.attributes.name}</h2>
            </div>
            <p>{featuredCourse.attributes.field_course_objective}</p>
            <footer>
              <Button className="button small">View Course</Button>
            </footer>
          </Card>
        )}
        {isFeaturedLearningPath &&
          featuredLearningPath &&
          featuredLearningPath.image && (
            <Card
              className={classNames("widget announcement featuredLearningPath")}
            >
              {label && (
                <div className={classNames("widgetLabel", label.toLowerCase())}>
                  {label}
                </div>
              )}
              <div className="timestamp">Posted Today</div>
              <div
                className="card-image"
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_API_URL +
                    featuredLearningPath.image.attributes.uri.url +
                    ")",
                }}
              />
              <h2>{featuredLearningPath.attributes.name}</h2>
              <SanitizedHTML html={announcement.body} />

              <footer>
                <Button className="button small">View Learning Path</Button>
              </footer>
            </Card>
          )}
      </>
    </Dialog>
  )
}

export default ModalAnnouncementPreview
