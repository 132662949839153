import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

export const locationsSlice = createSlice({
  name: "locationsSlice",
  initialState: {
    data: [],
    fetched: false,
    editingLocation: null,
    locationSearch: "",
    rooms: [],
    newLocation: {
      name: "",
      attributes: {},
      field_directions: "",
      field_capacity: 0,
    },
  },
  reducers: {
    addRoom: (state, action) => {
      let targetLocation = state.data.find(
        (location) => location.id === action.payload.id
      )
      targetLocation.rooms.push({
        id: "newRoom-" + targetLocation.rooms.length,
        attributes: {
          name: "",
        },
        relationships: {
          field_location: {
            data: {
              id: action.payload.id,
            },
          },
        },
      })
    },
    getLocations: (state = { data: [] }, action) => {
      state.data = _.orderBy(
        action.payload,
        (location) => location.attributes.name,
        "asc"
      )
      state.locationSearch = ""
      state.fetched = true
    },
    updateRoom: (state, action) => {
      let targetLocation = state.data.find(
        (location) => location.id === action.payload.location.id
      )
      let targetRoom = targetLocation.rooms.find(
        (room) => room.id === action.payload.room.id
      )
      targetRoom.attributes[action.payload.field] = action.payload.value

      if (action.payload.field === "field_room_number_name") {
        targetRoom.attributes.name = action.payload.value
      }
    },
    getRoomsForLocation: (state, action) => {
      let targetLocation = state.data.find(
        (location) => location.id === action.meta.location.id
      )

      targetLocation.rooms = action.payload
    },
    updateLocationSearch: (state, action) => {
      state.locationSearch = action.payload
    },
    updateNewLocation: (state, action) => {
      state.newLocation.attributes[action.payload.field] = action.payload.value
    },
    archiveLocation: (state, action) => {
      let targetLocation = state.data.find(
        (location) => location.id === action.payload.id
      )

      if (targetLocation) {
        targetLocation.attributes.field_archive = true
      }
    },
    addNewLocation: (state, action) => {
      if (action.payload) {
        state.data.unshift(action.payload)
        state.newLocation = {
          name: "",
          attributes: {},
          field_directions: "",
          field_capacity: 0,
        }
      }
    },
    resetState: (state) => {
      state.editingLocation = null
    },
    removeLocation: (state, action) => {
      state.data = state.data.filter(
        (location) => location.id !== action.payload.id
      )
    },
    cancelEdit: (state, action) => {
      state.editingLocation = null
    },
    setEditingLocation: (state, action) => {
      state.editingLocation = action.payload
    },
    updateEditingLocation: (state, action) => {
      if (action.payload.field !== "address") {
        state.editingLocation.attributes[action.payload.field] =
          action.payload.value
      } else {
        const addressComponents = action.payload.components

        state.editingLocation.attributes.field_address = {
          address_line1: "",
        }

        addressComponents.forEach((component) => {
          if (component.types.includes("street_number")) {
            state.editingLocation.attributes.field_address.address_line1 +=
              component.long_name + " "
          }
          if (component.types.includes("route")) {
            state.editingLocation.attributes.field_address.address_line1 +=
              component.short_name
          }
          if (component.types.includes("subpremise")) {
            state.editingLocation.attributes.field_address.address_line2 =
              component.long_name
          }
          if (component.types.includes("locality")) {
            state.editingLocation.attributes.field_address.locality =
              component.long_name
          }
          if (component.types.includes("administrative_area_level_1")) {
            state.editingLocation.attributes.field_address.administrative_area =
              component.short_name
          }
          if (component.types.includes("country")) {
            state.editingLocation.attributes.field_address.country_code =
              component.short_name
          }
          if (component.types.includes("postal_code")) {
            state.editingLocation.attributes.field_address.postal_code =
              component.short_name
          }
        })
      }
    },
    addUpdatedLocation: (state, action) => {
      state.data.forEach((location) => {
        if (location.id === action.payload.id) {
          location.attributes = action.payload.attributes
        }
      })
    },
    setNewLocation: (state, action) => {
      const addressComponents = action.payload.components

      state.newLocation.attributes.name = action.payload.name
      state.newLocation.attributes.field_address = {
        address_line1: "",
      }

      addressComponents.forEach((component) => {
        if (component.types.includes("street_number")) {
          state.newLocation.attributes.field_address.address_line1 +=
            component.long_name + " "
        }
        if (component.types.includes("route")) {
          state.newLocation.attributes.field_address.address_line1 +=
            component.short_name
        }
        if (component.types.includes("subpremise")) {
          state.newLocation.attributes.field_address.address_line2 =
            component.long_name
        }
        if (component.types.includes("locality")) {
          state.newLocation.attributes.field_address.locality =
            component.long_name
        }
        if (component.types.includes("administrative_area_level_1")) {
          state.newLocation.attributes.field_address.administrative_area =
            component.short_name
        }
        if (component.types.includes("country")) {
          state.newLocation.attributes.field_address.country_code =
            component.short_name
        }
        if (component.types.includes("postal_code")) {
          state.newLocation.attributes.field_address.postal_code =
            component.short_name
        }
      })
    },
  },
})

export const filterLocations = (state) => {
  return state.locations.data.filter((location) =>
    location.attributes.name
      .toLowerCase()
      .includes(state.locations.locationSearch.toLowerCase())
  )
}

export const {
  setNewLocation,
  updateNewLocation,
  addRoom,
  resetState,
  updateRoom,
  cancelEdit,
  addNewLocation,
  updateLocationSearch,
  setEditingLocation,
  updateEditingLocation,
} = locationsSlice.actions

export default locationsSlice.reducer
