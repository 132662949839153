import { Component } from "react"
import { connect } from "react-redux"

import { setCurrentPage, setSortOrder } from "./entityListSlice"

import { getMandatedTraining } from "../../actions/mandatedTraining"
import { startNewMT } from "../createMandatedTraining/activeMandatedTrainingSlice"
import SidebarEntityList from "./SidebarEntityList"

import EntityList from "./EntityList"

import { getSubGroups, getOrganizations } from "../../store/reducers/session"
import { getJobTitles } from "../jobTitles/jobTitlesSlice"

const mapStateToProps = ({
  session,
  jobTitles,

  entityList,
}) => {
  return {
    mandatedTraining: entityList.mandatedTraining,
    filters: entityList.mandatedTraining.filters,
    session,
    jobTitles,
    entityList,
    fetched: entityList.mandatedTraining.fetched,
  }
}

const mapDispatchToProps = {
  getSubGroups,
  getJobTitles,
  getOrganizations,
  getMandatedTraining,
  startNewMT,
  setCurrentPage,
  setSortOrder,
}

class MandatedTrainingListContainer extends Component {
  componentDidMount() {
    const { session, jobTitles, mandatedTraining } = this.props
    this.props.setCurrentPage("Mandated Training")

    this.props.startNewMT()

    !mandatedTraining.fetched &&
      this.props.getMandatedTraining(mandatedTraining.filters)

    !session.subGroups.fetched && this.props.getSubGroups()
    session.isSiteAdmin &&
      !session.organizations.fetched &&
      this.props.getOrganizations()

    !jobTitles.fetched && this.props.getJobTitles()
  }

  render() {
    const { session, jobTitles, currentTab, filters, entityList } = this.props

    const props = {
      entityType: "Mandated Training",
      iconClass: "icon mandated black",
      createPath: "/create/mandated-training/new",
      fetched: this.props.fetched,
      total: entityList.mandatedTraining.data.length,
      isSiteAdmin: session.isSiteAdmin,
      currentTab: "Mandated Training",
      setSortOrder: this.props.setSortOrder,
      session,
      filters,
    }

    const sidebarProps = {
      type: "Mandated Training",
      filters,
      entityList,
      session,
      organizations: session.organizations,
      subGroups: session.subGroups,
      jobTitles: jobTitles,
      fetchData: this.props.getMandatedTraining,
      currentTab,
    }

    return (
      <>
        <SidebarEntityList {...sidebarProps} />
        <EntityList {...props} />
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MandatedTrainingListContainer)
