import { Component } from "react"
import { connect } from "react-redux"
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import moment from "moment"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"

import {
  getAssessments,
  getAssignedCourses,
  setLearningPath,
  removeAssessment,
  clearRevisions,
  getAssessmentRevisions,
  setActiveRevision,
  revertAssessmentGradingInProgress,
  setSortOrder,
  setReviewerGroupsModalOpen,
  setIsViewByCourseOpen,
} from "./reviewSlice"

import {
  getLearningPaths,
  updateAssessment,
} from "@mobilemind/common/src/actions/course"

import { getOrgCourses, removeReviewerFromCourse } from "../../actions/courses"

import { getCategories } from "../../store/reducers/categories"
import SidebarReview from "./SidebarReview"
import AssessmentCard from "../../components/ReviewCard"
import manageImg from "../../img/manage.svg"
import reviewerGroupsImg from "../../img/reviewer-group-blue.svg"
import he from "he"
import "../../styles/review.scss"
import theme from "@mobilemind/common/src/theme/theme"
import { getSubGroups } from "../../store/reducers/session"

import classNames from "classnames"
import Loading from "@mobilemind/common/src/components/Loading"
import ModalAssignedCourses from "../../components/ModalAssignedCourses"
import ErrorBoundary from "../../components/ErrorBoundary"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"

import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"

const mapStateToProps = ({
  assessments,
  entityList,
  learningPaths,
  session,
  categories,
}) => {
  const assignedCourses = !assessments.assignedCourses.data.content
    ? assessments.assignedCourses.data
    : []

  return {
    isReviewerGroupsModalOpen: assessments.isReviewerGroupsModalOpen,
    isViewByCourseModalOpen: assessments.isViewByCourseModalOpen,
    orgCourses: entityList.orgCourses,
    assignedCourses,
    session,
    assessments,
    learningPaths,
    categories,
  }
}

const mapDispatchToProps = {
  getAssessments,
  removeAssessment,
  getLearningPaths,
  getOrgCourses,
  getAssignedCourses,
  removeReviewerFromCourse,
  clearRevisions,
  setLearningPath,
  updateAssessment,
  getAssessmentRevisions,
  revertAssessmentGradingInProgress,
  setActiveRevision,
  getCategories,
  getSubGroups,
  setReviewerGroupsModalOpen,
  setIsViewByCourseOpen,
  setSortOrder,
}

class ReviewContainer extends Component {
  state = {
    currentAssessment: null,
    comments: "",
    goToNext: true,
  }

  componentDidMount = () => {
    const { learningPaths, categories } = this.props

    this.props.getOrgCourses({
      category: "any",
      searchQuery: "",
      sortBy: "created",
      status: "any",
      instructionsOpen: false,
      learnerCommentsOpen: false,
    })

    !learningPaths.fetched && this.props.getLearningPaths()
    !categories.fetched && this.props.getCategories()

    this.props.getAssignedCourses()

    this.props.getAssessments({
      searchQuery: this.props.assessments.searchQuery,
      filters: this.props.assessments.filters,
      currentTab: this.props.assessments.currentTab,
      reviewType: this.props.assessments.reviewType,
    })
  }

  componentDidUpdate = (prevProps) => {
    const { assessments, learningPaths } = this.props
    if (learningPaths.fetched && assessments.filters.learningPath) {
      this.props.setLearningPath(assessments.filters.learningPath)
    }
    if (
      prevProps.assessments.searchQuery !== assessments.searchQuery ||
      prevProps.assessments.currentTab !== assessments.currentTab ||
      prevProps.assessments.reviewType !== assessments.reviewType ||
      prevProps.assessments.filters.learningPath !==
        assessments.filters.learningPath ||
      prevProps.assessments.filters.group !== assessments.filters.group ||
      prevProps.assessments.filters.category !== assessments.filters.category
    ) {
      this.props.getAssignedCourses()

      this.props.getAssessments({
        searchQuery: assessments.searchQuery,
        filters: assessments.filters,
        currentTab: assessments.currentTab,
        reviewType: assessments.reviewType,
      })
    }
  }

  removeUserFromCourse = (course) => {
    this.props.removeReviewerFromCourse(course)
  }

  submitChallenge = (status) => {
    const { assessments } = this.props
    const { currentAssessment, goToNext, comments } = this.state

    const currentIndex = assessments.data.findIndex((item) => {
      return item.drupal_internal__id === currentAssessment.drupal_internal__id
    })

    const nextAssessment = assessments.data[currentIndex + 1]

    this.setState({ isLoading: true })
    this.props.clearRevisions()
    this.props
      .updateAssessment(currentAssessment.assessment_uuid, status, comments)
      .then(() => {
        this.props.removeAssessment(currentAssessment)
        this.props.clearRevisions()
        if (goToNext && nextAssessment) {
          this.props
            .getAssessmentRevisions({
              id: nextAssessment.drupal_internal__id,
              uuid: nextAssessment.assessment_uuid,
            })
            .then(() => {
              this.setState({ currentAssessment: nextAssessment })
            })
        }
        this.setState({
          instructionsOpen: false,
          learnerCommentsOpen: false,
          isLoading: false,
          comments: "",
          currentAssessment: null,
        })
      })
  }

  render() {
    const { assessments, session, categories } = this.props
    const { activeRevision } = assessments
    const orderedRevisions = [...assessments.currentRevisions].reverse()
    const latestRevision = orderedRevisions[0]
    const { isOrgAdmin } = session
    const activeFilters =
      assessments.searchQuery ||
      assessments.filters.category ||
      assessments.filters.learningPath ||
      assessments.filters.group !== "All"

    const { currentAssessment, goToNext, isLoading } = this.state
    const currentCategory =
      currentAssessment &&
      categories.data.find(
        (cat) => cat.attributes.name === currentAssessment.field_category
      )
    const fullSizeModal =
      currentAssessment &&
      (currentAssessment.field_submission_type === "URL" ||
        currentAssessment.field_submission_type === "Image")

    return (
      <ErrorBoundary>
        <div className={classNames("page page-review hasSidebar")}>
          <SidebarReview {...this.props} />

          <Dialog
            id={"reviewer-assessment-modal"}
            className={classNames(fullSizeModal && "fullsize")}
            onClose={() => {
              this.props.revertAssessmentGradingInProgress({
                uuid: currentAssessment.assessment_uuid,
              })
              this.props.clearRevisions()
              this.setState({ comments: "", currentAssessment: null })
            }}
            open={currentAssessment}
          >
            {currentAssessment && (
              <>
                <div
                  className="flexRow modal-header"
                  style={{ marginBottom: 20 }}
                >
                  <div style={{ flex: 1 }}>
                    <UserLabel
                      firstName={he.decode(
                        currentAssessment.field_first_name.substring(0, 30)
                      )}
                      lastName={he.decode(
                        currentAssessment.field_last_name.substring(0, 30)
                      )}
                      imagePath={
                        process.env.REACT_APP_API_URL +
                        currentAssessment.user_picture
                      }
                      subgroups={currentAssessment.field_subgroup}
                    />
                  </div>

                  {latestRevision &&
                    activeRevision &&
                    latestRevision.vid !== activeRevision.vid && (
                      <div className="latest-button">
                        <ButtonSmall
                          onClick={() => {
                            this.props.setActiveRevision(latestRevision.vid)
                          }}
                        >
                          Go to Latest
                        </ButtonSmall>
                      </div>
                    )}

                  {activeRevision && (
                    <FormControl
                      style={{ marginRight: 20, marginTop: -5, width: "auto" }}
                      className="revision-version"
                    >
                      <InputLabel
                        style={{ marginLeft: -15 }}
                        id="label-revision-version-select"
                      >
                        Submission History
                      </InputLabel>
                      <Select
                        variant="standard"
                        labelId="label-revision-version-select"
                        id="revision-version-select"
                        value={activeRevision.vid}
                        onChange={(event) => {
                          this.props.setActiveRevision(event.target.value)
                        }}
                      >
                        {orderedRevisions.map((rev, index) => {
                          const timestamp = rev.created.split('"')[1]
                          return (
                            <MenuItem value={rev.vid}>
                              {moment(timestamp).format("MMMM Do YYYY h:mm A")}{" "}
                              {!index && <>(Latest)</>}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  )}

                  <div className="courseInfo">
                    <div
                      className="category-icon icon"
                      style={{
                        backgroundImage:
                          currentCategory && currentCategory.image
                            ? "url(" +
                              process.env.REACT_APP_API_URL +
                              currentCategory.image.attributes.uri.url +
                              ")"
                            : "initial",
                      }}
                    />
                    <strong>{he.decode(currentAssessment.course_title)}</strong>
                    <ButtonSmall>
                      <div
                        onClick={() => {
                          this.props.revertAssessmentGradingInProgress({
                            uuid: currentAssessment.assessment_uuid,
                          })
                          this.props.clearRevisions()
                          this.setState({
                            comments: "",
                            currentAssessment: null,
                          })
                        }}
                        className="close"
                      ></div>
                    </ButtonSmall>
                  </div>
                </div>

                {activeRevision && (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      pointerEvents: isLoading ? "none" : "all",
                      opacity: isLoading ? 0.6 : 1,
                      transition: 0.3 + "s",
                    }}
                  >
                    {currentAssessment.field_submission_type === "URL" && (
                      <div
                        style={{
                          marginBottom: 20,
                          display: "flex",
                          position: "relative",
                          flex: 1,
                        }}
                      >
                        <iframe
                          title="URL Submission"
                          src={activeRevision.field_document_url__revision_id}
                        />

                        <a
                          href={currentAssessment.field_document_url}
                          rel="noreferrer"
                          target="_blank"
                          className="URL-help"
                        >
                          <span className="icon broken" />
                          <p>
                            Having trouble viewing this URL?
                            <br />
                            <strong>Try opening in a new tab.</strong>
                          </p>
                        </a>
                      </div>
                    )}

                    {currentAssessment.field_submission_type === "Image" && (
                      <div className="scrollContainer">
                        <a
                          className="img-link"
                          href={
                            process.env.REACT_APP_API_URL +
                            activeRevision.field_image_submission__revision_id
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            alt="Challenge Submission"
                            src={
                              process.env.REACT_APP_API_URL +
                              activeRevision.field_image_submission__revision_id
                            }
                          />
                        </a>
                      </div>
                    )}
                    {currentAssessment.field_submission_type === "Text" && (
                      <div className="scrollContainer">
                        <p>
                          {he.decode(
                            activeRevision.field_text_submission__revision_id
                          )}
                        </p>
                      </div>
                    )}

                    {activeRevision.field_status__revision_id ===
                      "InProgress" &&
                      activeRevision.field_comments__revision_id.length > 0 && (
                        <div className="reviewer-comments">
                          <div>
                            <div className="reviewer-info">
                              <UserLabel
                                firstName={he.decode(
                                  activeRevision.reviewer_first_name.substring(
                                    0,
                                    30
                                  )
                                )}
                                lastName={he.decode(
                                  activeRevision.reviewer_last_name.substring(
                                    0,
                                    30
                                  )
                                )}
                                imagePath={
                                  process.env.REACT_APP_API_URL +
                                  activeRevision.user_picture
                                }
                                subgroups={""}
                              />
                            </div>

                            <p>
                              {he.decode(
                                activeRevision.field_comments__revision_id
                              )}
                            </p>
                          </div>
                        </div>
                      )}

                    {latestRevision.vid === activeRevision.vid && (
                      <>
                        <div className="actions-footer">
                          {this.state.learnerCommentsOpen &&
                            currentAssessment.field_learner_comments && (
                              <div className="learner-comments">
                                <strong>Learner Comments</strong>
                                <br />
                                <em>
                                  "
                                  {he.decode(
                                    currentAssessment.field_learner_comments
                                  )}
                                  "
                                </em>
                              </div>
                            )}

                          {this.state.instructionsOpen &&
                            currentAssessment.field_reviewer && (
                              <div className="instructions-container">
                                <strong>Reviewer Instructions</strong>
                                <SanitizedHTML
                                  html={he.decode(
                                    currentAssessment.field_reviewer
                                  )}
                                />
                              </div>
                            )}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div className="comments-container">
                              {currentAssessment.field_reviewer && (
                                <div
                                  className={classNames(
                                    "instructions",
                                    this.state.instructionsOpen && "isOpen"
                                  )}
                                >
                                  <ButtonSmall
                                    onClick={() => {
                                      this.setState({
                                        learnerCommentsOpen: false,
                                        instructionsOpen:
                                          !this.state.instructionsOpen,
                                      })
                                    }}
                                  >
                                    <span className="icon review-instructions"></span>
                                    Reviewer Instructions
                                  </ButtonSmall>
                                </div>
                              )}

                              {currentAssessment.field_learner_comments.length >
                                0 && (
                                <div
                                  className={classNames(
                                    "instructions learner",
                                    this.state.learnerCommentsOpen && "isOpen"
                                  )}
                                >
                                  <ButtonSmall
                                    onClick={() => {
                                      this.setState({
                                        instructionsOpen: false,
                                        learnerCommentsOpen:
                                          !this.state.learnerCommentsOpen,
                                      })
                                    }}
                                  >
                                    <span className="icon review-instructions"></span>
                                    Learner Comments
                                  </ButtonSmall>
                                </div>
                              )}

                              <TextField
                                multiline
                                placeholder="Enter your feedback here"
                                value={this.state.comments}
                                onChange={(event) =>
                                  this.setState({
                                    comments: event.target.value,
                                  })
                                }
                              />
                            </div>
                            <div>
                              <ButtonLarge
                                onClick={() => {
                                  this.submitChallenge("in_progress")
                                }}
                              >
                                Return with Feedback
                              </ButtonLarge>
                              <ButtonLarge
                                onClick={() => {
                                  this.submitChallenge("completed")
                                }}
                              >
                                Mark as Complete
                              </ButtonLarge>
                            </div>
                            <FormControlLabel
                              label="Go to Next Challenge"
                              control={
                                <Checkbox
                                  style={{
                                    color: theme.palette.secondary.main,
                                  }}
                                  checked={goToNext}
                                  onChange={(event) => {
                                    this.setState({
                                      goToNext: event.target.checked,
                                    })
                                  }}
                                />
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {isLoading && (
                  <div className="comments-container" style={{ marginTop: 30 }}>
                    <Loading message={"Updating challenge..."} />
                  </div>
                )}
              </>
            )}
          </Dialog>

          <h2>
            <div className="flexRow title">
              <span className="icon review black" />
              {assessments.currentTab === "review" && <>Review Challenges</>}
              {assessments.currentTab === "reviewed" && (
                <>
                  Reviewed by{" "}
                  {this.props.session.user.attributes.field_first_name}
                </>
              )}
            </div>
            {assessments.currentTab === "review" && (
              <>
                {this.props.session.isOrgAdmin && (
                  <Button
                    onClick={() => {
                      this.props.setReviewerGroupsModalOpen(true)
                    }}
                    className="button small"
                  >
                    <img alt={"Reviewer Groups"} src={reviewerGroupsImg} />
                    Reviewer Groups
                  </Button>
                )}

                <Button
                  onClick={() => {
                    this.props.setIsViewByCourseOpen(true)
                  }}
                  className="button small"
                >
                  <img alt={"View By Course"} src={manageImg} />
                  {isOrgAdmin ? (
                    <>View By Course</>
                  ) : (
                    <>Manage Assigned Courses</>
                  )}
                </Button>
              </>
            )}
          </h2>

          <ModalAssignedCourses
            onClose={() => {
              this.props.setIsViewByCourseOpen(false)
            }}
            reviewerGroups={assessments.reviewerGroups}
            session={this.props.session}
            open={this.props.isViewByCourseModalOpen}
            assignedCourses={assessments.assignedCourses}
            remove={this.removeUserFromCourse}
            orgUsers={session.orgUsers}
          />

          {assessments.fetched ? (
            <div>
              <div className="counter">
                <Button
                  onClick={() => {
                    this.props.setSortOrder(
                      assessments.filters.sortOrder === "DESC" ? "ASC" : "DESC"
                    )
                  }}
                >
                  <span
                    style={{
                      transform:
                        assessments.filters.sortOrder === "DESC"
                          ? "scaleY(-1)"
                          : "scaleY(1)",
                    }}
                    className="icon sort"
                  />
                </Button>
                <span>{assessments.total_items}</span>{" "}
                {assessments.currentTab === "reviewed" ? (
                  <>Reviewed</>
                ) : (
                  <>Submitted</>
                )}{" "}
                Challenges
              </div>
              <div className="card-grid">
                {assessments.data.length < 1 && assessments.fetched && (
                  <em style={{ fontSize: 14 }}>
                    {activeFilters ? (
                      <>No challenges match your filter criteria.</>
                    ) : (
                      <>You have no outstanding challenges to review.</>
                    )}
                  </em>
                )}

                <Grid container spacing={3}>
                  {assessments.data.map((assessment) => {
                    return (
                      <AssessmentCard
                        key={assessment.drupal_internal__id}
                        disabled={
                          assessment.field_status === "GradingInProgress"
                        }
                        onClick={() => {
                          this.props.getAssessmentRevisions({
                            id: assessment.drupal_internal__id,
                            uuid: assessment.assessment_uuid,
                          })
                          this.setState({ currentAssessment: assessment })
                        }}
                        reviewType={assessments.reviewType}
                        currentTab={assessments.currentTab}
                        assessment={assessment}
                      />
                    )
                  })}
                </Grid>
              </div>
            </div>
          ) : (
            <Loading message="Getting challenges..." />
          )}
          {assessments.fetched &&
            assessments.total_items - assessments.items_per_page > 0 && (
              <div className="counter">
                <span>
                  {assessments.total_items - assessments.items_per_page}
                </span>{" "}
                more challenges. Use filters to find a specific challenge to
                review.
              </div>
            )}
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewContainer)
