import { Button, ButtonProps, styled } from "@mui/material"
import { useFormikContext } from "formik"
import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"
import Values from "values.js"
import { ReactComponent as MagicLampIcon } from "../../../img/magic-lamp-color.svg"
import {
  PopperType,
  selectCourseGeniePopper,
  selectCourseGenieUsed,
  showPopper,
} from "../store/courseGenieSlice"
import { CourseFormValues } from "../types"
import { CourseFormStep } from "./CourseFormStepper"
import { GeniePopper } from "./GeniePopper"
import { GeniePopperFeedbackContent } from "./GeniePopperFeedbackContent"
import { GeniePopperHelperContent } from "./GeniePopperHelperContent"

export const GenieLampButton = styled((props: ButtonProps) => {
  const dispatch = useAppDispatch()
  const popper = useAppSelector(selectCourseGeniePopper)
  const genieUsed = useAppSelector(selectCourseGenieUsed)
  const ref = useRef<HTMLButtonElement>(null)

  const { status } = useFormikContext<CourseFormValues>()
  const [feedbackShown, setFeedbackShown] = useState(false)
  useEffect(() => {
    /**
     * Show the feedback popper if:
     * -User is on the review step
     * -User hasn't been on the review step since the genie was used
     * -The genie was used
     */
    if (
      status.activeStep === CourseFormStep.Review &&
      genieUsed &&
      !feedbackShown
    ) {
      dispatch(showPopper({ type: PopperType.Feedback }))
      setFeedbackShown(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.activeStep, genieUsed, feedbackShown])

  return (
    <>
      {ref.current && (
        <>
          <GeniePopper
            open={popper?.type === PopperType.Helper}
            anchorEl={ref.current}
            placement="top-end"
          >
            <GeniePopperHelperContent />
          </GeniePopper>
          <GeniePopper
            open={popper?.type === PopperType.Feedback}
            anchorEl={ref.current}
            placement="top-end"
          >
            <GeniePopperFeedbackContent />
          </GeniePopper>
        </>
      )}
      <Button
        ref={ref}
        {...props}
        onClick={() => {
          if (!popper) {
            if (status.activeStep === CourseFormStep.Review) {
              dispatch(showPopper({ type: PopperType.Feedback }))
            } else {
              dispatch(showPopper({ type: PopperType.Helper }))
            }
          } else {
            dispatch(showPopper(null))
          }
        }}
        disabled={status.activeStep === CourseFormStep.Review && !genieUsed}
      >
        <MagicLampIcon width={90} height={90} />
      </Button>
    </>
  )
})(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  right: 0,
  margin: theme.spacing(4),
  zIndex: 1,
  borderRadius: 64,
  backgroundColor: theme.palette.secondary.main,
  padding: 0,
  width: 64,
  height: 64,
  "&:hover": {
    backgroundColor: new Values(theme.palette.secondary.main)
      .tint(15)
      .hexString(),
  },
  "&:focus": {
    backgroundColor: new Values(theme.palette.secondary.main)
      .tint(15)
      .hexString(),
  },
  "&>svg": {
    marginRight: -3,
  },
}))
