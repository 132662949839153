import { YouTubeSearchItem } from "@mobilemind/genie-api/src/types"
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material"
import he from "he"
import moment from "moment"
import { forwardRef } from "react"
import { ResponsiveYouTube } from "./ResponsiveYouTube"

export const PLAYER_WIDTH = 480
export const PLAYER_HEIGHT = 270

type CreateCourseGenieSearchResultsCardProps = {
  item: YouTubeSearchItem
  onClick: () => void
}

export const CreateCourseGenieSearchResultsCard = forwardRef<
  HTMLDivElement,
  CreateCourseGenieSearchResultsCardProps
>((props, ref) => {
  const { item, onClick } = props

  return (
    <Card ref={ref}>
      <CardActionArea onClick={onClick}>
        <CardMedia>
          <ResponsiveYouTube videoId={item.id} />
        </CardMedia>
        <CardContent>
          <Stack spacing={4}>
            <Stack>
              <Typography variant="h3" gutterBottom={true}>
                {he.decode(item.title)}
              </Typography>
              <Typography variant="body2">
                {he.decode(item.description)}
              </Typography>
            </Stack>
            <Stack sx={{ opacity: 0.6 }}>
              {item.channelTitle && (
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {item.channelTitle}
                </Typography>
              )}
              {item.publishedAt && (
                <Typography variant="body2">
                  {moment(item.publishedAt).startOf("day").fromNow()}
                </Typography>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
})
