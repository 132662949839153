import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

export const badgesSlice = createSlice({
  name: "badgesSlice",
  initialState: {
    data: [],
    fetched: false,
  },
  reducers: {
    getBadges: (state, action) => {
      let badgeData = []
      action.payload.forEach((badge) => {
        let newBadge = { ...badge }
        if (newBadge.relationships.field_badge_image) {
          newBadge.image = action.meta.images.find(
            (image) =>
              newBadge.relationships.field_badge_image.data &&
              image.id === newBadge.relationships.field_badge_image.data.id
          )
        }
        let existing = state.data.find(
          (existing) => existing.id === newBadge.id
        )

        if (!existing) {
          badgeData.push(newBadge)
        }
      })

      state.data = _.orderBy([state.data, badgeData].flat(), [
        (badge) => badge.attributes.name && badge.attributes.name.toLowerCase(),
      ])
      state.fetched = action.meta.fetched
    },
  },
})

export default badgesSlice.reducer
