import theme from "@mobilemind/common/src/theme/theme"
import School from "../../img/school.svg"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { useEffect, useCallback, useState } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { getCollectionMembers, getOrgList } from "store/reducers/dashboard"
import _ from "lodash"
import { Formik, Form, Field, useFormikContext } from "formik"

import { TextField, Select, MenuItem, TextFieldProps } from "@mui/material"
import { CollectionOrgEdit } from "./CollectionOrgEdit"
import Loading from "@mobilemind/common/src/components/Loading"
import { switchActiveMembership } from "@mobilemind/common/src/actions/user"
import { CollectionOrg } from "./types"
import BottomScrollListener from "react-bottom-scroll-listener"

const styles = {
  ul: {
    listStyleType: "none",
    padding: 0,
    marginTop: 15,
    position: "relative" as "relative",
    zIndex: 8,
  },
  li: {
    padding: "25px 20px",
    marginBottom: 1,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    firstChild: {
      borderRadius: "12px 0 0 0",
    },
    lastChild: {
      borderRadius: "0 0 12px 12px",
    },
    onlyChild: {
      borderRadius: "12px 0 12px 12px",
    },
    icon: {
      width: 30,
      height: 30,
      marginRight: 10,
      border: "3px solid white",
      boxShadow: theme.shadows[2],
      borderRadius: 30,
    },
    strong: {
      flex: 1,
    },
  },
}

const initialValues = {
  search: "",
  status: "any",
}

const emptyOrg = {
  customer_rep: {
    uuid: "",
    first_name: "",
    last_name: "",
    email: "",
    user_image: "",
  },
  excluded_cat_list: [],
  excluded_lp_list: [],
  id: "",
  label: "",
  max_total_members: "10",
  max_total_premium: "5",
  org_address: {
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip: "",
  },
  org_external_pd_access: true,
  org_hide_mm_content: false,
  org_hq: true,
  org_status: true,
  partner_list: [],
  uuid: "",
}

export const CollectionOrgList = (props: any) => {
  const dispatch = useAppDispatch()

  const { data, fetching, totalPages } = useAppSelector(
    (state) => state.dashboard.orgList
  )
  const session = useAppSelector((state) => state.session)
  const [isFetched, setIsFetched] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [activeOrg, setActiveOrg] = useState<CollectionOrg | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const collectionName =
    session.userCollection &&
    session.userCollection[0] &&
    session.userCollection[0].label[0].value

  useEffect(() => {
    if (!isFetched) {
      dispatch(getOrgList({ filters: initialValues }))
      dispatch(getCollectionMembers({}))
      setIsFetched(true)
    }
  }, [isFetched, dispatch])

  const handleScroll = () => {
    if (currentPage < totalPages - 1) {
      dispatch(
        getOrgList({ filters: initialValues, currentPage: currentPage + 1 })
      )
      setCurrentPage(currentPage + 1)
    }
  }

  const SearchField = (props: TextFieldProps) => {
    const { submitForm } = useFormikContext()

    const DEFAULT_DEBOUNCE_WAIT = 750
    const debounce = _.debounce(submitForm, DEFAULT_DEBOUNCE_WAIT)
    const debounceSubmitForm = useCallback(debounce, [submitForm]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Field
        style={{ width: 220, marginBottom: 0 }}
        as={TextField}
        {...props}
        name="search"
        label="Search for Organizations"
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          props.onChange?.(event)
          setCurrentPage(0)
          debounceSubmitForm()
        }}
      />
    )
  }

  return (
    <div className={"collection-org-list"}>
      <BottomScrollListener onBottom={handleScroll}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            dispatch(getOrgList({ filters: values }))
          }}
        >
          {({ setFieldValue, values, submitForm }) => (
            <Form style={{ display: "flex", alignItems: "center" }}>
              <CollectionOrgEdit
                activeOrg={activeOrg}
                onClose={() => {
                  dispatch(getOrgList({ filters: values }))
                  setActiveOrg(null)
                }}
              />

              <h2 style={{ flex: 1 }}>{collectionName} Organizations</h2>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "white",
                  padding: 10,
                  paddingBottom: 10,
                  borderRadius: "12px 0 0 0",
                  right: 20,
                  marginBottom: -20,
                }}
              >
                {isLoading && (
                  <Loading fullPage={true} message="Just a moment..." />
                )}
                <ButtonSmall
                  onClick={() => {
                    setActiveOrg(emptyOrg)
                  }}
                >
                  <span className="icon add" />
                  Add Organization
                </ButtonSmall>

                <Field
                  style={{
                    width: 220,
                    marginRight: 15,
                    marginLeft: 15,
                    marginBottom: 0,
                  }}
                  as={Select}
                  {...props}
                  name="status"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentPage(0)
                    setFieldValue("status", event.target.value)
                    submitForm()
                  }}
                >
                  <MenuItem value={"any"}>Any Status</MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"inactive"}>Inactive</MenuItem>
                </Field>
                <SearchField
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    setFieldValue("search", event.target.value)
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>

        <ul style={styles.ul}>
          {data.map((org, index) => {
            let lineItemStyles = { ...styles.li }

            if (!index) {
              lineItemStyles = { ...lineItemStyles, ...styles.li.firstChild }
            }
            if (index === data.length - 1) {
              lineItemStyles = { ...lineItemStyles, ...styles.li.lastChild }
            }
            if (data.length === 1) {
              lineItemStyles = {
                ...lineItemStyles,
                ...styles.li.onlyChild,
              }
            }

            return (
              <li
                key={org.id}
                onClick={(event) => {
                  const element = event.target as HTMLElement
                  if (element.localName !== "button") {
                    setActiveOrg(org)
                  }
                }}
                style={{ ...lineItemStyles, cursor: "pointer" }}
              >
                <img src={School} alt="school" style={styles.li.icon} />

                <div className="icon school"></div>
                <strong style={styles.li.strong}>{org.label}</strong>

                {org.max_total_members && (
                  <div style={{ marginRight: 25, marginLeft: 25 }}>
                    <strong style={{ display: "block", fontSize: 14 }}>
                      Max Total Members
                    </strong>
                    <span style={{ fontSize: 14, opacity: 0.6 }}>
                      {org.max_total_members}
                    </span>
                  </div>
                )}
                <ButtonSmall
                  onClick={() => {
                    setIsLoading(true)
                    dispatch(
                      switchActiveMembership(
                        org.uuid,
                        process.env.REACT_APP_HQ_URL
                      )
                    )
                  }}
                >
                  Jump to Org
                </ButtonSmall>
              </li>
            )
          })}
        </ul>
        {fetching && (
          <div
            style={{
              zIndex: 10,
              position: "fixed",
              left: 255,
              right: 0,
              bottom: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loading message="Getting more organizations..." />
          </div>
        )}
      </BottomScrollListener>
    </div>
  )
}
