import { Box, Dialog, Typography } from "@mui/material"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { reorderRooms } from "../activeConferenceSlice"
import { useAppDispatch } from "store/hooks"
import LocationIcon from "../../../../img/location-color.svg"

import { updateConferenceRoomOrder } from "../../../../actions/events"

function ArrangeRoomsModal({
  open,
  rooms,
  conference,
  selectedLocation,
  onClose,
  refresh,
}) {
  const [currentOrder, updateOrder] = useState(rooms)
  const dispatch = useAppDispatch()

  useEffect(() => {
    updateOrder(rooms)
  }, [rooms])

  function dropRoom(dropped) {
    let targetIndex = dropped.destination.index

    let current = currentOrder.filter((room) => room.id !== dropped.draggableId)
    current.splice(
      targetIndex,
      0,
      rooms.find((room) => room.id === dropped.draggableId)
    )
    updateOrder(current)
  }

  const fullLocation = conference.locations.find(
    (loc) => loc.id === selectedLocation
  )

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": {
          maxHeight: "none",
        },
      }}
    >
      <div id="arrange-rooms-modal">
        <h2>
          {fullLocation && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <img src={LocationIcon} alt="Location" width={25} />
              <Typography
                sx={{ paddingLeft: 3, fontWeight: "bold", fontSize: 18 }}
              >
                {fullLocation.attributes.name}
              </Typography>
            </Box>
          )}
        </h2>

        <Typography
          sx={{ marginBottom: -2, fontSize: 14, fontStyle: "italic" }}
        >
          Drag a room to repostion its session column on the conference
          calendar.
        </Typography>

        <DragDropContext
          onDragEnd={(droppedItem) => {
            dropRoom(droppedItem)
          }}
        >
          <Droppable droppableId={"rooms-list"}>
            {(provided) => (
              <ul
                style={{ height: 41 * rooms.length }}
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="roomsList"
              >
                {currentOrder.length > 0 &&
                  currentOrder.map((room, index) => {
                    if (room.attributes.name !== "Virtual Sessions") {
                      return (
                        <Draggable
                          key={room.id}
                          draggableId={room.id}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <span className="icon hamburger" />
                              <span className="icon room" />
                              <span className="name">
                                {room.attributes.name}
                              </span>
                            </li>
                          )}
                        </Draggable>
                      )
                    }

                    return null
                  })}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <footer>
          <ButtonSmall
            classes="cancel"
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </ButtonSmall>
          <ButtonSmall
            onClick={() => {
              dispatch(reorderRooms({ currentOrder, selectedLocation }))
              dispatch(
                updateConferenceRoomOrder({
                  conferenceId: conference.id,
                  selectedLocation,
                  currentOrder,
                })
              )
              onClose()
              refresh()
            }}
          >
            Save Changes
          </ButtonSmall>
        </footer>
      </div>
    </Dialog>
  )
}

export default ArrangeRoomsModal
