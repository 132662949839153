import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material"
import { CourseFormValues } from "../types"
import Jigsaw from "../../../img/jigsaw.svg"

type MultipleChoiceOptionsProps = {
  course: CourseFormValues
}

export const TemplateMultipleChoiceOptions = ({
  course,
}: MultipleChoiceOptionsProps) => {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{ alignItems: "center", marginBottom: 2 }}
        spacing={2}
      >
        <img src={Jigsaw} width={30} height={30} alt="Question" />
        <Typography variant="h3">
          Challenge Question (Multiple Choice)
        </Typography>
      </Stack>
      <Typography>{course.question}</Typography>
      <Stack sx={{ marginTop: 2 }} spacing={2}>
        {course.answers.map((answer, index) => {
          return (
            <Box
              key={index}
              role="listitem"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <FormControlLabel
                sx={{ marginRight: -1 }}
                label=""
                control={
                  <Checkbox
                    disabled={true}
                    checked={
                      answer.attributes.field_mc_answer_type === "correct"
                    }
                  />
                }
              />
              {answer.attributes.field_mc_answer}
            </Box>
          )
        })}
      </Stack>
    </Box>
  )
}
