import { createSlice } from "@reduxjs/toolkit"

export const goalsSlice = createSlice({
  name: "goalsSlice",
  initialState: {
    data: [],
    fetched: false,
  },
  reducers: {
    getGoals: (state, action) => {
      const users = action.meta.included
        ? action.meta.included.filter(
            (included) => included.type === "user--user"
          )
        : []
      const subGroups = action.meta.included
        ? action.meta.included.filter(
            (included) => included.type === "group--group"
          )
        : []
      const jobTitles = action.meta.included
        ? action.meta.included.filter(
            (included) => included.type === "taxonomy_term--job_titles"
          )
        : []
      const paths = action.meta.included
        ? action.meta.included.filter(
            (included) => included.type === "learning_path--learning_path"
          )
        : []

      action.payload.forEach((goal) => {
        const userIds = goal.relationships.field_teacher.data.map(
          (user) => user.id
        )
        const subGroupIds = goal.relationships.field_subgroup.data.map(
          (group) => group.id
        )
        const jobTitleIds = goal.relationships.field_job_title.data.map(
          (title) => title.id
        )
        goal.users = users.filter((user) => userIds.includes(user.id))
        goal.subGroups = subGroups.filter((group) =>
          subGroupIds.includes(group.id)
        )
        goal.jobTitles = jobTitles.filter((title) =>
          jobTitleIds.includes(title.id)
        )
        goal.learningPath = paths.find(
          (path) =>
            path.id === goal.relationships.field_learning_path.data[0].id
        )
      })

      state.data = action.payload
      state.fetched = action.meta.fetched
    },
  },
})

export default goalsSlice.reducer
