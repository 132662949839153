import { Button, DialogActions, DialogContent, Typography } from "@mui/material"
import { useContext } from "react"
import { CreateCourseGenieStep } from "../common/types"
import { CreateCourseGenieContext } from "./CreateCourseGenieContext"
import { GenieMessage } from "./GenieMessage"

export const CreateCourseGenieStart = () => {
  const { setCurrentStep } = useContext(CreateCourseGenieContext)
  return (
    <>
      <DialogContent>
        <GenieMessage sx={{ marginBottom: 0 }}>
          <Typography paragraph={true}>
            It's me, your <strong>AI Content Genie</strong>! Let's build a great
            micro-course.
          </Typography>
          <Typography>
            <strong>
              Do you already have a YouTube video for this course?
            </strong>
          </Typography>
        </GenieMessage>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setCurrentStep(CreateCourseGenieStep.Search)}
        >
          No, help me find one.
        </Button>
        <Button
          variant="contained"
          onClick={() => setCurrentStep(CreateCourseGenieStep.VideoUrl)}
        >
          Sure do!
        </Button>
      </DialogActions>
    </>
  )
}
