import { useState } from "react"
import { connect } from "react-redux"
import "../styles/successChecklist.scss"

import ProgressBar from "@mobilemind/common/src/components/ProgressBar"
import classNames from "classnames"

import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { Popover } from "@mui/material"

import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
}))

const mapStateToProps = ({ session }) => {
  return {
    successChecklist: session.successChecklist,
  }
}

function ChecklistItem({ label, iconClass, completed }) {
  return (
    <li>
      <span className={classNames("icon left", iconClass)} />
      <span className="label">{label}</span>
      {completed ? (
        <span className="icon check" />
      ) : (
        <span className="icon check blank" />
      )}
    </li>
  )
}

function SuccessChecklist(props) {
  const { successChecklist } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const classes = useStyles()

  const {
    fetched,
    field_badge,
    field_course,
    field_learning_path,
    field_event,
    field_group,
    field_user,
  } = successChecklist

  let completed = 0

  const checklistArray = [
    { field_course },
    { field_badge },
    { field_learning_path },
    { field_event },
    { field_group },
    { field_user },
  ]

  checklistArray.forEach((item) => {
    if (item[Object.keys(item)]) {
      completed += 1
    }
  })

  const progress = ((completed / 6) * 100).toFixed(0)

  if (fetched) {
    return (
      <>
        <div
          onClick={(event) => {
            setAnchorEl(event.currentTarget)
          }}
          className="success-navbar"
        >
          <CircularProgressbarWithChildren strokeWidth={16} value={progress}>
            <span className="percent">{progress}</span>
          </CircularProgressbarWithChildren>
        </div>

        <Popover
          id="checklist-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          onClose={() => setAnchorEl(null)}
        >
          <div className="success-checklist-container">
            <h2>
              <span className="icon check black" />
              Success Checklist
            </h2>

            <ul>
              {checklistArray.map((item, index) => {
                const srcItem = Object.keys(item)[0]
                let label, iconClass

                switch (srcItem) {
                  case "field_course":
                    label = "Publish 2 Micro-Courses"
                    iconClass = "laptop"
                    break
                  case "field_badge":
                    label = "Publish a Badge"
                    iconClass = "badge"
                    break
                  case "field_learning_path":
                    label = "Publish a Learning Path"
                    iconClass = "learningPath"
                    break
                  case "field_event":
                    label = "Publish an Event"
                    iconClass = "event"
                    break
                  case "field_group":
                    label = "Create a Group"
                    iconClass = "group"
                    break
                  default:
                    label = "Add 5 Users"
                    iconClass = "users"
                }

                return (
                  <ChecklistItem
                    key={index}
                    completed={item[Object.keys(item)]}
                    iconClass={iconClass}
                    label={label}
                  />
                )
              })}
            </ul>

            <footer>
              <ProgressBar width={progress} />
            </footer>
          </div>
        </Popover>
      </>
    )
  }
  return null
}

export default connect(mapStateToProps)(SuccessChecklist)
