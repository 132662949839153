import { Dialog, styled } from "@mui/material"

// Provides a styled dialog with a blurred backdrop that is pinned to the top of the screen
export const StyledDialog = styled(Dialog)(({ theme }) => ({
  // Shift the dialog down to make room for the app bar, and lower the zIndex
  // of the backdrop so that it doesn't cover the app bar
  paddingTop: 50,
  zIndex: 999,
  // Align the dialog content to the top, so that when the contents change,
  // the top of the dialog doesn't change position
  "& .MuiDialog-container": {
    alignItems: "flex-start",
  },
  // Blur the content behind the backdrop
  "& .MuiBackdrop-root": {
    // @ts-ignore
    ...theme.mixins.backdropBlur,
  },
}))
