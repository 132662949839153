import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import classNames from "classnames"
import { useAppDispatch } from "store/hooks"
import moment from "moment"

import {
  getPartnerCompliance,
  setBadge,
  setContentType,
  setCourse,
  setLearningPath,
  updatePartnerFilters,
} from "./complianceSlice"

function SidebarPartnerCompliance(props) {
  const dispatch = useAppDispatch()

  const { contentType, course, start, end, badge, learningPath } =
    props.compliance.partnerFilters

  const { orgBadges, orgCourses, orgLearningPaths } = props.entityList

  return (
    <div id="sidebar">
      <form className="filters">
        <header style={{ marginBottom: 15 }}>
          <strong>Filter</strong>
        </header>

        <FormControl variant="standard" className="inputSelect contentType">
          <InputLabel id="label-contentType-select">Content Type</InputLabel>
          <Select
            variant="standard"
            labelId="label-contentType-select"
            id="contentType-select"
            value={contentType}
            onChange={(event) =>
              dispatch(
                setContentType({ value: event.target.value, isPartner: true })
              )
            }
          >
            <MenuItem value={"lp_compliance_report"}>Learning Path</MenuItem>
            <MenuItem value={"badges_compliance_report"}>Badge</MenuItem>
            <MenuItem value={"course_compliance_report"}>Course</MenuItem>
          </Select>
        </FormControl>

        {contentType === "lp_compliance_report" && (
          <FormControl
            variant="standard"
            style={{ marginTop: 20 }}
            className={classNames(
              "inputSelect learningPath",
              !learningPath && "attract"
            )}
          >
            <Select
              variant="standard"
              value={learningPath ? learningPath.id : ""}
              onChange={(event, newValue) => {
                dispatch(
                  setLearningPath({
                    value: orgLearningPaths.data.find(
                      (path) => Number(path.id) === Number(newValue.props.value)
                    ),
                    isPartner: true,
                  })
                )
                dispatch(getPartnerCompliance())
              }}
            >
              {orgLearningPaths.data.map((path) => {
                return <MenuItem value={path.id}>{path.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        )}

        {contentType === "badges_compliance_report" && (
          <FormControl
            variant="standard"
            style={{ marginTop: 20 }}
            className={classNames("inputSelect badge", !badge && "attract")}
          >
            <Select
              variant="standard"
              value={badge ? badge.id : ""}
              onChange={(event, newValue) => {
                dispatch(
                  setBadge({
                    value: orgBadges.data.find(
                      (path) => Number(path.id) === Number(newValue.props.value)
                    ),
                    isPartner: true,
                  })
                )
                dispatch(getPartnerCompliance())
              }}
            >
              {orgBadges.data.map((badge) => {
                return <MenuItem value={badge.id}>{badge.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        )}

        {contentType === "course_compliance_report" && (
          <FormControl
            variant="standard"
            style={{ marginTop: 20 }}
            className={classNames("inputSelect course", !course && "attract")}
          >
            <Select
              variant="standard"
              value={course ? course.id : ""}
              onChange={(event, newValue) => {
                dispatch(
                  setCourse({
                    value: orgCourses.data.find(
                      (path) => Number(path.id) === Number(newValue.props.value)
                    ),
                    isPartner: true,
                  })
                )
                dispatch(getPartnerCompliance())
              }}
            >
              {orgCourses.data.map((path) => {
                return <MenuItem value={path.id}>{path.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        )}

        <div id="date-range-container">
          <DatePicker
            label="Date From"
            value={moment(start)}
            onChange={(date) => {
              dispatch(
                updatePartnerFilters({
                  name: "start",
                  value: JSON.stringify(date),
                })
              )
              dispatch(getPartnerCompliance())
            }}
          />

          <DatePicker
            label="Date To"
            value={moment(end)}
            onChange={(date) => {
              dispatch(
                updatePartnerFilters({
                  name: "end",
                  value: JSON.stringify(date),
                })
              )
              dispatch(getPartnerCompliance())
            }}
          />
        </div>
      </form>
    </div>
  )
}

export default SidebarPartnerCompliance
