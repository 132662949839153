import { GenieRewriteAllowedField } from "@mobilemind/genie-api/src/types"
import { LoadingButton } from "@mui/lab"
import { ButtonProps } from "@mui/material"
import { useAsync } from "@react-hook/async"
import { useFormikContext } from "formik"
import { get } from "lodash"
import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  rewriteField,
  selectCourseGenieLoading,
  showCourseGenieError,
  showPopper,
} from "../store/courseGenieSlice"
import { CourseFormValues } from "../types"

type GenieRewriteFieldButtonProps = Omit<ButtonProps, "action"> & {
  field: GenieRewriteAllowedField
  name: string
  action: "rewrite" | "longer" | "shorter"
}

export const GenieRewriteFieldButton = (
  props: GenieRewriteFieldButtonProps
) => {
  const { field, name, action, ...otherProps } = props
  const dispatch = useAppDispatch()
  const loading = useAppSelector(selectCourseGenieLoading)
  const { setFieldValue, values } = useFormikContext<CourseFormValues>()
  const content = get(values, name, "")

  const [{ status }, handleClick] = useAsync(async (event) => {
    try {
      const value = await dispatch(
        rewriteField({ field, content, action })
      ).unwrap()
      setFieldValue(name, value)
    } catch {
      dispatch(showCourseGenieError())
    } finally {
      dispatch(showPopper(null))
    }
  })

  return (
    <LoadingButton
      {...otherProps}
      // Disable if other fields are rewriting, or if this field is empty
      disabled={loading || !content}
      onClick={handleClick}
      loading={status === "loading"}
      loadingPosition="start"
      variant="text"
      size="small"
    />
  )
}
