import qs from "qs"
import moment from "moment"

export function generateReportExportURL(args, session) {
  let { reportType, orgId, subGroup, filters } = args
  let dateRange = {}

  let groupId = orgId

  let query = {
    sort_order: filters.sortOrder === "DESC" ? "ASC" : "DESC",
  }
  if (filters.group !== "all") {
    groupId = subGroup.entity_id
  }

  if (
    (reportType === "compliance-learning-path" ||
      reportType === "group-compliance-learning-path") &&
    filters.learningPath
  ) {
    query.id = filters.learningPath.field_lp_id
  }

  if (
    (reportType === "compliance-badge" ||
      reportType === "group-compliance-badge") &&
    filters.badge
  ) {
    reportType = "compliance-badge"
    query.id = filters.badge.id
  }

  if (
    (reportType === "compliance-goal" ||
      reportType === "group-compliance-goal") &&
    filters.goal
  ) {
    query.id = filters.goal.attributes.drupal_internal__id
  }

  if (
    (reportType === "compliance-course" ||
      reportType === "group-compliance-course") &&
    filters.course
  ) {
    query.course = filters.course.name
  }

  if (filters.sort && !reportType.includes("compliance")) {
    query.sort_by = filters.sort
  }

  if (filters.userSearch) {
    query.search = filters.userSearch
  }

  if (
    filters.dateFrom &&
    filters.dateTo &&
    !reportType.includes("time-spent")
  ) {
    dateRange.min = filters.dateFrom.replace('"', "").slice(0, 10)
    dateRange.max = filters.dateTo.replace('"', "").slice(0, 10)

    if (filters.dateFrom || filters.dateTo) {
      if (filters.leaderboardType === "badges-earned") {
        query.created = dateRange
      } else {
        query.changed = dateRange
      }
    }
  }

  // TIME SPENT EXPORT
  if (reportType.includes("time-spent")) {
    query.sort_order = "DESC"
    if (reportType.includes("course")) {
      query.type = "assessment"
      query.sort_by = "assessment_time_spent"
    }
    if (reportType.includes("ext-event")) {
      query.type = "ext_event"
      query.sort_by = "ext_usr_event_time_spent"
    }
    if (reportType === "leaderboard-event-time-spent") {
      query.type = "event"
      query.sort_by = "event_time_spent"
    }
    if (filters.schoolHours !== "all") {
      query.school_hours = filters.schoolHours
    }

    if (
      session.isOrgAdmin ||
      session.orgRoles.includes("organization-reporter")
    ) {
      if (filters.group !== "all") {
        query.field_subgroup = groupId
      }
    } else if (filters.group === "all") {
      query.field_subgroup = session.subgroup.id[0].value
    }

    if (filters.category) {
      query.category = filters.category.attributes.drupal_internal__tid
    }

    if (filters.dateFrom) {
      query.date_from = moment(filters.dateFrom).unix()
    }
    if (filters.dateFrom && filters.dateTo) {
      query.date_to = moment(filters.dateTo).unix()
      query.date_from = moment(filters.dateFrom).unix()
    }

    query.date_to = filters.dateTo
      ? moment(moment(filters.dateTo.replace('"', "").slice(0, 10))).unix()
      : moment().unix()
    query.date_from = filters.dateFrom
      ? moment(moment(filters.dateFrom.replace('"', "").slice(0, 10))).unix()
      : moment().subtract(20, "years").unix()

    return (
      process.env.REACT_APP_API_URL +
      "/api/time-spent-leaderboard?" +
      qs.stringify(query)
    )
  } else if (
    reportType !== "leaderboard-time-spent" &&
    reportType !== "group-leaderboard-time-spent"
  ) {
    query._format = "csv"
    return (
      process.env.REACT_APP_API_URL +
      "/report-export/" +
      reportType +
      "/" +
      groupId +
      "?" +
      qs.stringify(query)
    )
  }

  return (
    process.env.REACT_APP_API_URL +
    "/report-export/combined/" +
    groupId +
    "?" +
    qs.stringify(query)
  )
}

export function generateRubricComplianceExportURL({
  session,
  isOrgLevel,
  compliance,
}) {
  const { searchInput, sortBy, dateTo, dateFrom, rubric, group, jobTitle } =
    compliance.filters

  /**
   *
   * This plugin returns user completed rubrics for a specific rubric compliance endpoint.
   * * /api/compliance_rubric/export/{rubric_id}/?
   * * * search= [str] (user first / last name)
   * * * &sort_by= [str (first_name, last_name, completed_date)]
   * * * &sort_order= [str (ASC, DESC)]
   * * * &end = [str] (completed date filter (YYYY-MM-DD))
   * * * &start = [str] (completed date filter format (YYYY-MM-DD))
   * * * &subgroup = [int] (drupal id of subgroup)
   * * * &job_title = [int] (drupal id of job title)
   * * * &page[offset]= [int]
   */

  const query = {
    search: searchInput,
    sort_by: sortBy.replace("field_", "").replace("_value", ""),
    sort_order: "DESC",
    end: moment(dateTo.split("T")[0]).format("YYYY-MM-DD"),
  }

  if (dateFrom) {
    query.start = moment(dateFrom.split("T")[0]).format("YYYY-MM-DD")
  }

  if (!isOrgLevel) {
    query.subgroup = session.subgroup.id[0].value
  } else if (group !== "all") {
    query.subgroup = group
  }

  if (jobTitle !== "all") {
    query.job_title = jobTitle
  }

  const rubricId = Number(rubric)

  const exportURL =
    process.env.REACT_APP_API_URL +
    "/api/compliance_rubric/export/" +
    rubricId +
    "?" +
    qs.stringify(query)

  return exportURL
}

export function generateMTComplianceExportURL({
  session,
  isOrgLevel,
  compliance,
}) {
  const {
    searchInput,
    sortBy,
    dateTo,
    dateFrom,
    mandatedTraining,
    group,
    jobTitle,
  } = compliance.filters

  /**
   * This plugin returns mandated training compliance data as a cvs file export for authenticated users
   * * /api/mt_compliance/export/{mt_id}/?
   * * * search= [str] (user first and or last name)
   * * * subgroup= [str] (subgroup name)
   * * * job_title = [int] (job title id)
   * * * &start = [str](Y-m-d format)
   * * * &end = [str](Y-m-d format)
   * * * &sort_by= [str (first_name, last_name, compliance_date)]
   * * * &sort_order= [str (ASC, DESC)]
   */

  const query = {
    search: searchInput,
    sort_by: sortBy,
    sort_order: "DESC",
    end: moment(dateTo.split("T")[0]).format("YYYY-MM-DD"),
  }

  if (dateFrom) {
    query.start = moment(dateFrom.split("T")[0]).format("YYYY-MM-DD")
  }

  if (!isOrgLevel) {
    query.subgroup = session.subgroup.id[0].value
  } else if (group !== "all") {
    query.subgroup = group
  }

  if (jobTitle !== "all") {
    query.job_title = jobTitle
  }

  const mandatedTrainingId = Number(mandatedTraining)

  const exportURL =
    process.env.REACT_APP_API_URL +
    "/api/mt_compliance/export/" +
    mandatedTrainingId +
    "?" +
    qs.stringify(query)
  return exportURL
}

export function isArrayIdentical(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}

export function isGroupLevel(session) {
  return (
    !session.orgRoles.includes("organization-admin") &&
    !session.orgRoles.includes("organization-creator") &&
    !session.orgRoles.includes("organization-drafter")
  )
}

export function updateEventField(state, action) {
  const field = action.payload.field
  const newValue = action.payload.value
  if (field === "attendeeMethod" && newValue === "custom") {
    if (
      !state.subGroups.length &&
      !state.jobTitles.length &&
      !state.attendees.length
    ) {
      // Open the invitee select if there's nothing there
      state.isAttendeeModalOpen = true
    }
  }

  if (field === "name") {
    state.badgeName = newValue
  }

  if (field === "sendReminders" && newValue.includes("none")) {
    if (newValue.length && !state.sendReminders.includes("none")) {
      state.sendReminders = ["none"]
    } else {
      newValue.shift()
      state.sendReminders = newValue
    }
  }
  if (field === "currentLocation" || field === "eventRoom") {
    if (newValue) {
      const locationCapacity = newValue.attributes.field_capacity
        ? newValue.attributes.field_capacity
        : 0

      state.eventMax = locationCapacity
    } else {
      if (field === "eventRoom") {
        state.eventRoom = "none"
        state.eventMax = state.currentLocation.attributes.field_capacity
      }
      if (field === "currentLocation") {
        state.eventMax = 0
      }
    }
  }

  if (field === "sendRegistrationReminders" && newValue.includes("none")) {
    if (newValue.length && !state.sendRegistrationReminders.includes("none")) {
      state.sendRegistrationReminders = ["none"]
    } else {
      newValue.shift()
      state.sendRegistrationReminders = newValue
    }
  }

  if (field === "startDate" || field === "endDate") {
    if (field === "startDate") {
      state.endDate = moment(newValue).add(1, "hour").format()
      state.checkInEnd = state.endDate

      state.checkInStart = moment(newValue).format()
      state.registrationEnds = moment(state.startDate).format()
      state[field] = newValue
    }
    if (field === "endDate") {
      state.checkInEnd = moment(newValue).format()
      state[field] = newValue
    }
  } else {
    state[field] = newValue
  }

  return state
}

export function hexToRGB(h) {
  let r = 0,
    g = 0,
    b = 0

  // eslint-disable-next-line
  if (h.length == 4) {
    r = "0x" + h[1] + h[1]
    g = "0x" + h[2] + h[2]
    b = "0x" + h[3] + h[3]
  }
  // eslint-disable-next-line
  else if (h.length == 7) {
    r = "0x" + h[1] + h[2]
    g = "0x" + h[3] + h[4]
    b = "0x" + h[5] + h[6]
  }

  return { r, g, b, a: undefined }
}
