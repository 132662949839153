import { Dialog, Box, Typography } from "@mui/material"
import CourseCard from "../../../../components/CourseCard"
import { CourseFormValues } from "features/courseForm/types"
import "../../../../styles/modal.scss"

type CardPreviewProps = {
  onClose: () => void
  open: boolean
  course: CourseFormValues
}

export function CourseCardPreview({ onClose, open, course }: CardPreviewProps) {
  return (
    <Dialog onClose={() => onClose()} open={open}>
      <Box sx={{ padding: 3, maxWidth: 450 }}>
        <Typography sx={{ marginBlock: 3, textAlign: "center" }}>
          Here's how this course will appear throughout the{" "}
          <strong>Learn</strong> app.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: 3,
            overflow: "hidden",
          }}
        >
          <CourseCard course={course} />
        </Box>
      </Box>
    </Dialog>
  )
}
