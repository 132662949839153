type RatingProps = {
  level: string
}

function Rating(props: RatingProps) {
  return (
    <>
      <span className="icon star"></span>
      {props.level === "intermediate" && <span className="icon star"></span>}
      {props.level === "advanced" && (
        <>
          <span className="icon star"></span>
          <span className="icon star"></span>
        </>
      )}
    </>
  )
}

export default Rating
