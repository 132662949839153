import Rating from "./Rating"
import CategoryIcon from "./CategoryIcon"
import classNames from "classnames"
import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"

type ItemProps = {
  animationDelay: string
  category: any
  course: any
  courseAdded?: any
  isSingle: boolean
  jobTitles: any[]
  groups?: any[]
  onClick: () => void
}

function CourseListItem(props: ItemProps) {
  const {
    onClick,
    animationDelay,
    category,
    course,
    courseAdded,
    groups,
    jobTitles,
  } = props

  return (
    <li
      onClick={onClick}
      style={{ animationDelay: animationDelay }}
      className={classNames(
        "course-list-item",
        props.isSingle && "single",
        courseAdded && "added"
      )}
    >
      <h2>
        {category && <CategoryIcon category={category} />}
        <div style={{ marginRight: 15 }}>{course.attributes.name}</div>

        <div className="course-level">
          <Rating level={course.attributes.field_level.toLowerCase()} />
        </div>

        {course.attributes.field_draft && (
          <div
            style={{
              backgroundColor: "#999",
              color: "white",
              fontWeight: "bold",
              padding: "2px 10px",
              borderRadius: 20,
              fontSize: 10,
              marginLeft: 10,
            }}
          >
            DRAFT
          </div>
        )}
      </h2>

      <footer className="course-details">
        <p>{course.attributes.field_course_objective}</p>
      </footer>

      <ul className="visibilityList">
        {groups &&
          groups.length > 0 &&
          groups.map((group) => {
            if (group) {
              return (
                <li key={group.id}>
                  <span className="icon group"></span>
                  <strong>{group.attributes.label}</strong>
                </li>
              )
            }
            return null
          })}
        {jobTitles &&
          jobTitles.length > 0 &&
          jobTitles.map((jobTitle: any) => {
            if (jobTitle) {
              return (
                <li key={jobTitle.id}>
                  <span
                    className={classNames(
                      "icon role",
                      jobTitle.attributes.name.toLowerCase()
                    )}
                  ></span>
                  <strong>{jobTitle.attributes.name}</strong>
                </li>
              )
            }
            return null
          })}
      </ul>
      <span
        className={classNames("icon indicator", courseAdded ? "check" : "add")}
      />
      {course.attributes.field_course_estimated_time > 0 && (
        <div className="estimatedTime">
          <TimeCapsule
            hours={
              convertMinutesToHoursMinutes(
                course.attributes.field_course_estimated_time
              ).hours
            }
            minutes={
              convertMinutesToHoursMinutes(
                course.attributes.field_course_estimated_time
              ).minutes
            }
          />
        </div>
      )}
    </li>
  )
}

export default CourseListItem
