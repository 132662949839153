import { Component } from "react"
import { connect } from "react-redux"
import { updateEditingLocation } from "../../locations/locationsSlice"

import LiveViewLayout from "../eventSingle/LiveViewLayout"
import { addressToString } from "@mobilemind/common/src/functions"
import EventNavbarContainer from "../eventSingle/EventNavbarContainer"
import classNames from "classnames"
import ErrorBoundary from "../../../components/ErrorBoundary"

const mapStateToProps = ({ activeConference }) => {
  return {
    activeConference,
  }
}

const mapDispatchToProps = {
  updateEditingLocation,
}

class ConferenceLiveViewLayout extends Component {
  componentDidMount() {
    if (this.props.editing) {
      this.setState({
        address: addressToString(
          this.props.locations.editingLocation.attributes.field_address
        ),
      })
    }
  }

  render() {
    const props = this.props

    return (
      <ErrorBoundary>
        <div className="conference-live-view-layout">
          <div
            className={classNames(
              "conferenceNavbarWrapper",
              props.activeConference.isEditingEvent && "locked"
            )}
          >
            <EventNavbarContainer
              {...props}
              isConference={true}
              isViewOnly={true}
            />
          </div>
          <LiveViewLayout isConference={true} {...props} />
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConferenceLiveViewLayout)
