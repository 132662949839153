import BackpackLayout from "@mobilemind/common/src/components/backpack/BackpackLayout"
import { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { clearActiveRubric } from "../../store/reducers/rubrics"
import { getOrgRubrics } from "../../actions/rubrics"

import "../rubrics/rubric.scss"

import {
  clearBackpackRubric,
  fetchSingleUserRubric,
  increaseBadgePage,
  // Courses
  increaseCoursePage,
  increaseEventsPage,
  increaseLPPage,

  // Mandated Training
  increaseMandatedTrainingPage,
  // User Rubrics
  increaseRubricPage,
  // Badges
  setBadgeCarousel,
  setCourseCarousel,
  setDateMax,
  setDateMin,
  // Events
  setEventsCarousel,
  // Learning Paths
  setLPCarousel,
  setMandatedTrainingCarousel,
  setRubricCarousel,
  setSaveDateSetting,
  updateFilters,
} from "../../store/reducers/backpack"

import ButtonSmall from "../../components/ButtonSmall"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import "./backpack.scss"

const BackpackContainer = (props: any) => {
  const backpack = useAppSelector((state) => state.backpack)
  const session = useAppSelector((state) => state.session)
  const categories = useAppSelector((state) => state.categories)
  const rubrics = useAppSelector((state) => state.entityList.orgRubrics)

  const [rubricsFetched, setRubricsFetched] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!rubricsFetched) {
      // @ts-ignore
      dispatch(getOrgRubrics())
      setRubricsFetched(true)
    }
    dispatch(clearActiveRubric())
  }, [rubricsFetched, dispatch])

  const childProps = {
    // Pass the user id
    userId: Number(props.match.params.id),
    updateFilters,
    setDateMin,
    setDateMax,
    setSaveDateSetting,

    // Rubrics
    rubrics: rubrics.data,
    goToRubric: (rubricId: number, userId: number) => {
      props.history.push("/rubric/" + rubricId + "/" + userId)
    },
    // Events
    setEventsCarousel,
    increaseEventsPage,

    // Courses
    increaseCoursePage,
    setCourseCarousel,

    // Badges
    setBadgeCarousel,
    increaseBadgePage,

    // Learning Paths
    setLPCarousel,
    increaseLPPage,

    // Mandated Training
    increaseMandatedTrainingPage,
    setMandatedTrainingCarousel,

    // User Rubrics
    increaseRubricPage,
    setRubricCarousel,
    fetchSingleUserRubric: (userRubricId: string) =>
      dispatch(fetchSingleUserRubric(userRubricId)),
    clearBackpackRubric: () => dispatch(clearBackpackRubric()),

    // State
    session,
    backpack,
    categories,
  }

  return (
    <div className="hq-backpack">
      <div className="backButtonContainer">
        <ButtonSmall onClick={() => props.history.push("/")}>
          <span className="icon" />
          Back
        </ButtonSmall>
      </div>
      <BackpackLayout {...childProps} />
    </div>
  )
}

export default withRouter(BackpackContainer)
