import parseDataUrl from "parse-data-url"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import he from "he"
import moment from "moment"

export const getJwtToken =
  (serviceAccountInfo) => async (dispatch, getState) => {
    const { session } = getState()

    let auth = btoa(serviceAccountInfo.name + ":" + serviceAccountInfo.password)

    let options = {
      credentials: "include",
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Basic " + auth,
      }),
    }

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + "/rest_api/id_token",
        options
      )
      if (response.ok) {
        let token = await response.json()
        let expires_in = token.expires_in.replace(" minutes", "")
        let expiry = moment().add(expires_in, "m").format()

        let newProfile = {
          data: {
            id: session.serviceAccountId,
            type: "user--service",
            attributes: { field_token_expiry: expiry },
          },
        }
        let body = JSON.stringify(newProfile)

        let serviceAccount = await fetchWrapper.patch(
          "/api/user/service/" + session.serviceAccountId,
          session.token,
          body
        )
        if (serviceAccount.ok) {
          let new_expiry = await serviceAccount.json()
          token.expiry = new_expiry.data.attributes.field_token_expiry
          return dispatch({
            type: "session/getJwtToken",
            payload: token,
          })
        }
      } else {
        let token = await response.json()
        return dispatch({
          type: "session/getJwtToken",
          payload: token,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

export const getServiceAccount = () => async (dispatch, getState) => {
  const { session } = getState()

  try {
    // this returns the full service account object, or a 401 if the user is not logged in
    let response = await fetchWrapper.get(
      "/api/user/service/" +
        session.serviceAccountId +
        "?include=user_picture,field_user_import"
    )

    if (response.ok) {
      let data = await response.json()

      return dispatch({
        type: "session/getServiceAccount",
        payload: data,
      })
    }
  } catch (err) {
    console.log(err)
  }
}

export const updateServiceAccount =
  (attributes, removeImage = false) =>
  async (dispatch, getState) => {
    const { session } = getState()

    let newProfile = {
      data: {
        id: session.serviceAccountId,
        type: "user--service",
        attributes,
      },
    }
    if (removeImage) {
      newProfile.data.relationships = {
        user_picture: {
          data: [],
        },
      }
    }

    let body = JSON.stringify(newProfile)

    try {
      let response = await fetchWrapper.patch(
        "/api/user/service/" +
          session.serviceAccountId +
          "?include=user_picture",
        session.token,
        body
      )
      if (response.ok) {
        let data = await response.json()

        return dispatch({
          type: "session/updateServiceAccount",
          payload: data,
        })
      }
    } catch (err) {
      console.log(err)
      dispatch({
        type: "snackbar/update",
        payload: err.message,
      })
    }
  }

export const uploadImage =
  (image, url, filename) => async (dispatch, getState) => {
    let token = getState().session.token

    let uploadedImage = parseDataUrl(image).toBuffer()

    let options = {
      credentials: "include",
      method: "POST",
      headers: new Headers({
        Accept: "application/vnd.api+json",
        "Content-Type": "application/octet-stream",
        "X-CSRF-Token": token,
        "Content-Disposition": 'file; filename="' + he.encode(filename) + '"',
      }),
      body: uploadedImage,
    }

    try {
      let response = await fetch(process.env.REACT_APP_API_URL + url, options)

      if (response.ok) {
        let data = await response.json()
        // Go ahead and return the image if we need to do something with it
        dispatch({
          type: "assessment/addImage",
          payload: data.data,
        })
        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
