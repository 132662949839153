import { Component } from "react"
import { connect } from "react-redux"
import { getOrgBadges } from "../../actions/badges"
import { getCategories } from "../../store/reducers/categories"
import SidebarEntityList from "./SidebarEntityList"
import EntityList from "./EntityList"
import {
  setCurrentPage,
  increasePage,
  setSortOrder,
} from "../entityList/entityListSlice"

import { getSubGroups, getOrganizations } from "../../store/reducers/session"
import { getJobTitles } from "../jobTitles/jobTitlesSlice"
import Loading from "@mobilemind/common/src/components/Loading"
import BottomScrollListener from "react-bottom-scroll-listener"

const mapStateToProps = ({ session, jobTitles, categories, entityList }) => {
  return {
    currentTab: entityList.orgBadges.currentTab,
    orgBadges: entityList.orgBadges,
    filters: entityList.orgBadges.filters,
    categories,
    session,
    jobTitles,
    entityList,
    fetched: entityList.orgBadges.fetched,
  }
}

const mapDispatchToProps = {
  getOrgBadges,
  getCategories,
  getSubGroups,
  getOrganizations,
  getJobTitles,
  setCurrentPage,
  setSortOrder,
  increasePage,
}

class BadgeListContainer extends Component {
  componentDidMount() {
    const { filters, session, jobTitles } = this.props
    this.props.setCurrentPage("Badges")
    !this.props.orgBadges.fetched && this.props.getOrgBadges(filters)
    !this.props.categories.fetched && this.props.getCategories()

    !session.subGroups.fetched && this.props.getSubGroups()
    session.isSiteAdmin &&
      !session.organizations.fetched &&
      this.props.getOrganizations()

    !jobTitles.fetched && this.props.getJobTitles()
  }

  handleScroll = () => {
    const { orgBadges } = this.props.entityList

    const { currentPage, totalPages } = orgBadges

    if (currentPage + 1 < totalPages) {
      this.props.increasePage({ type: "orgBadges" })
      setTimeout(() => {
        this.props.getOrgBadges(this.props.filters)
      }, 450)
    }
  }

  render() {
    const { entityList, categories, filters, session, jobTitles, currentTab } =
      this.props

    const props = {
      entityType: "Badge",
      iconClass: "icon badge",
      createPath: "/create/badges/new",
      fetched: this.props.fetched,
      isSiteAdmin: session.isSiteAdmin,
      currentTab: this.props.currentTab,
      filters,
      session,
      total: entityList.orgBadges.totalRecords,
      setSortOrder: this.props.setSortOrder,
    }

    const sidebarProps = {
      type: "Badges",
      filters,
      entityList,
      categories,
      subGroups: session.subGroups,
      session,
      organizations: session.organizations,
      jobTitles,
      fetchData: this.props.getOrgBadges,
      currentTab,
    }

    return (
      <BottomScrollListener onBottom={this.handleScroll}>
        <SidebarEntityList {...sidebarProps} />
        <EntityList {...props} />
        {entityList.orgBadges.isFetchingMore && (
          <div className="loadingMoreContainer">
            <Loading message={"Getting more badges..."} />
          </div>
        )}
      </BottomScrollListener>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgeListContainer)
