import { Box, Card } from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { Link } from "react-router-dom"

const WidgetMarketplace = (props: any) => {
  return (
    <Card className="dashboard-widget widget-marketplace">
      <Box
        component="header"
        sx={{ backgroundColor: theme.palette.secondary.main }}
      >
        ReadyMade Marketplace
      </Box>
      <Box
        component="p"
        sx={{ fontSize: 14, marginTop: 0, padding: "20px 20px 0 20px" }}
      >
        Check out add-on content from our partners to boost your learners'
        achievements!
      </Box>
      <footer
        style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
      >
        <Link to="/marketplace">
          <ButtonSmall>
            <span
              className="icon puzzle-pieces"
              style={{ width: 25, height: 25 }}
            />

            <span>Browse Marketplace</span>
          </ButtonSmall>
        </Link>
      </footer>
    </Card>
  )
}

export default WidgetMarketplace
