import { Component, useEffect } from "react"
import { connect } from "react-redux"
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"
import classNames from "classnames"
import Loading from "@mobilemind/common/src/components/Loading"
import CategoryIcon from "../../components/CategoryIcon"
import { getCourses } from "../../actions/courses"
import { getGoals } from "../../actions/goals"
import { Link } from "react-router-dom"

import { generateTimestamp } from "@mobilemind/common/src/functions/index"
import moment from "moment"
import ComplianceNavbarContainer from "./ComplianceNavbarContainer"
import SidebarCompliance from "./SidebarCompliance"
import ProgressBar from "../../components/ProgressBar"
import BottomScrollListener from "react-bottom-scroll-listener"
import KnowBe4Icon from "../../img/kb4.svg"

import RubricIconColor from "../../img/checklist-color.svg"
import { getOrgRubrics } from "actions/rubrics"
import { getSubGroups } from "../../store/reducers/session"
import { getMandatedTraining } from "../../actions/mandatedTraining"
import pluralize from "pluralize"
import he from "he"

import {
  getCompliance,
  setSortOrder,
  increaseCurrentPage,
  setCourse,
  setBadge,
  setGoal,
  setLearningPath,
  getPartnerCompliance,
  getComplianceTotals,
  setContentType,
  getKnowBe4Campaigns,
} from "./complianceSlice"

import { getOrgLearningPaths } from "../../actions/learningPaths"

import "../../styles/reports.scss"
import { getJobTitles } from "../jobTitles/jobTitlesSlice"
import ErrorBoundary from "../../components/ErrorBoundary"
import { resetBackpack } from "../../store/reducers/backpack"

import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import SidebarPartnerCompliance from "./SidebarPartnerCompliance"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import { useAppDispatch } from "store/hooks"
import KnowBe4ComplianceTable from "./KnowBe4ComplianceTable"

import { ChallengeContent } from "./ChallengeContent"
import { RubricComplianceTable } from "./RubricComplianceTable"

const mapStateToProps = ({
  entityList,
  goals,
  compliance,
  session,
  jobTitles,
}) => {
  return {
    entityList,
    jobTitles,
    goals,
    compliance,
    session,
  }
}

const mapDispatchToProps = {
  getCourses,
  getGoals,
  getSubGroups,
  setCourse,
  setGoal,
  getJobTitles,
  setBadge,
  setLearningPath,
  getOrgLearningPaths,
  setContentType,
  getMandatedTraining,
  getOrgRubrics,
  getKnowBe4Campaigns,
}

function ComplianceReport(props) {
  const { filters, partnerFilters } = props.compliance
  const dispatch = useAppDispatch()

  let orgId, isAdmin
  if (
    props.session.orgRoles.includes("organization-admin") ||
    props.session.orgRoles.includes("organization-reporter")
  ) {
    isAdmin = true
    orgId = props.session.group.id[0].value
  } else if (
    props.session.groupRoles.includes("group-admin") ||
    props.session.groupRoles.includes("group-reporter")
  ) {
    orgId = props.session.subgroup.id[0].value
  }
  const { isPartner } = props.session

  useEffect(() => {
    if (filters.contentType !== "knowbe4") {
      if (!isPartner) {
        const requestArgs = {
          filters: {
            contentType: filters.contentType,
            course: filters.course,
            activeEvent: filters.activeEvent,
            rsvpStatus: filters.rsvpStatus,
            attended: filters.attended,
            learningPath: filters.learningPath,
            mandatedTraining: filters.mandatedTraining,
            rubric: filters.rubric,
            badge: filters.badge,
            goal: filters.goal,
            sortBy: filters.sortBy,
            sortOrder: filters.sortOrder,
            page: props.compliance.currentPage,
            group: filters.group,
            groupAdminGroup: filters.groupAdminGroup,
            jobTitle: filters.jobTitle,
            dateFrom: filters.dateFrom,
            dateTo: filters.dateTo,
          },
          searchQuery: props.compliance.searchQuery,
          orgRoles: props.session.orgRoles,
          orgId,
          subGroups: props.session.subGroups.data,
        }
        dispatch(getCompliance(requestArgs))
        dispatch(getComplianceTotals(requestArgs))
      }
    }
  }, [
    isPartner,
    filters.group,
    filters.groupAdminGroup,
    filters.jobTitle,
    filters.dateFrom,
    filters.dateTo,
    filters.sortBy,
    filters.sortOrder,
    filters.course,
    filters.learningPath,
    filters.mandatedTraining,
    filters.rubric,
    filters.badge,
    filters.goal,
    filters.activeEvent,
    filters.rsvpStatus,
    filters.attended,
    filters.contentType,
    props.compliance.searchQuery,
    props.compliance.currentPage,
    props.session.subGroups.data,
    props.session.group.id,
    orgId,
    props.session.orgRoles,
    dispatch,
  ])

  let compliantUsers = []

  if (!isPartner) {
    compliantUsers = props.compliance.complianceTotal
  } else {
    compliantUsers = Array.from(Array(props.compliance.total)).length
  }
  function handleScroll() {
    // If we aren't already fetching more results
    if (!props.compliance.fetching) {
      // And if there are more pages to fetch
      if (props.compliance.currentPage < props.compliance.totalPages - 1) {
        dispatch(increaseCurrentPage())

        if (isPartner) {
          dispatch(getPartnerCompliance())
        }
      }
    }
  }

  const activeTraining = props.entityList.mandatedTraining.data.find(
    (training) => Number(training.id) === Number(filters.mandatedTraining)
  )

  const compliantUsersCount = (
    <>
      <div
        style={{ cursor: "pointer", paddingRight: 4 }}
        className="flexRow"
        onClick={() => dispatch(setSortOrder())}
      >
        <span
          className={classNames(
            "icon sort",
            filters.sortOrder === "ASC" && "flipped"
          )}
        />
        <>
          <span>{compliantUsers}</span> {pluralize("User", compliantUsers)}
        </>
      </div>
    </>
  )

  return (
    <BottomScrollListener onBottom={handleScroll}>
      <Table>
        <TableHead>
          <TableRow>
            {!isPartner ? (
              <TableCell>
                {filters.contentType === "course" && (
                  <div className="flexRow">
                    {compliantUsersCount} Completed this Course
                  </div>
                )}

                {filters.contentType === "learning-path" && (
                  <div className="flexRow">
                    {compliantUsersCount}Completed this Learning Path
                  </div>
                )}
                {filters.contentType === "badge" && (
                  <div className="flexRow">
                    {compliantUsersCount} Earned this Badge
                  </div>
                )}

                {filters.contentType === "event" && (
                  <div className="flexRow">
                    {compliantUsersCount} Attended this Event
                  </div>
                )}
                {filters.contentType === "goal" && (
                  <div className="flexRow">
                    {compliantUsersCount} Achieved this Goal
                  </div>
                )}
              </TableCell>
            ) : (
              <TableCell>
                {partnerFilters.contentType === "course_compliance_report" && (
                  <div className="flexRow">
                    {compliantUsersCount} Completed this Course
                  </div>
                )}

                {partnerFilters.contentType === "lp_compliance_report" && (
                  <div className="flexRow">
                    {compliantUsersCount}Completed this Learning Path
                  </div>
                )}
                {partnerFilters.contentType === "badges_compliance_report" && (
                  <div className="flexRow">
                    <>{compliantUsersCount} Earned this Badge</>
                  </div>
                )}
              </TableCell>
            )}

            <TableCell>
              {filters.contentType === "event" && filters.activeEvent && (
                <div className="entityName">
                  {filters.activeEvent.field_award_credit_for_conferenc && (
                    <div
                      style={{
                        backgroundColor: "#54839F",
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 15px",
                        borderRadius: 25,
                        color: "white",
                        fontSize: 12,
                        textTransform: "uppercase",
                        marginRight: 15,
                      }}
                    >
                      <span
                        style={{ height: 20, width: 20, marginRight: 5 }}
                        className="icon conference white"
                      />
                      Conference
                    </div>
                  )}
                  <span
                    style={{
                      height: 20,
                      width: 20,
                      marginRight: 10,
                      position: "relative",
                      top: -1,
                    }}
                    className="icon event color"
                  />
                  {filters.activeEvent.name}
                </div>
              )}
              {filters.contentType === "course" && filters.course && (
                <div className="entityName">
                  <CategoryIcon
                    imageUrl={filters.course.field_category_image_uri}
                    category={filters.course.category}
                  />
                  {filters.course.name}
                </div>
              )}
              {filters.contentType === "mandated-training" &&
                activeTraining && (
                  <div
                    className="entityName"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="icon mandated-training" />
                      {activeTraining.name}
                    </div>
                  </div>
                )}
              {filters.contentType === "learning-path" &&
                filters.learningPath && (
                  <div className="entityName">
                    {filters.learningPath.field_lp_image_uri && (
                      <div
                        className="entityImage"
                        style={{
                          backgroundImage:
                            "url(" +
                            filters.learningPath.field_lp_image_uri +
                            ")",
                        }}
                      />
                    )}
                    {filters.learningPath.name}
                  </div>
                )}
              {partnerFilters.contentType === "lp_compliance_report" &&
                partnerFilters.learningPath && (
                  <div className="entityName">
                    {partnerFilters.learningPath.image && (
                      <div
                        className="entityImage"
                        style={{
                          backgroundImage:
                            "url(" +
                            process.env.REACT_APP_API_URL +
                            partnerFilters.learningPath.image +
                            ")",
                        }}
                      />
                    )}
                    {partnerFilters.learningPath.name}
                  </div>
                )}
              {partnerFilters.contentType === "badges_compliance_report" &&
                partnerFilters.badge && (
                  <div className="entityName">
                    <div
                      className="entityImage"
                      style={{
                        backgroundImage:
                          "url(" +
                          process.env.REACT_APP_API_URL +
                          partnerFilters.badge.image +
                          ")",
                      }}
                    />
                    {partnerFilters.badge.name}
                  </div>
                )}
              {partnerFilters.contentType === "course_compliance_report" &&
                partnerFilters.course && (
                  <div className="entityName">
                    <div
                      className="entityImage"
                      style={{
                        backgroundImage:
                          "url(" +
                          process.env.REACT_APP_API_URL +
                          partnerFilters.course.field_category_image +
                          ")",
                      }}
                    />
                    {partnerFilters.course.name}
                  </div>
                )}

              {filters.contentType === "badge" &&
                filters.badge &&
                filters.badge && (
                  <div className="entityName">
                    <div
                      className="entityImage"
                      style={{
                        backgroundImage:
                          "url(" + filters.badge.field_badge_image_uri + ")",
                      }}
                    />
                    {filters.badge.name}
                  </div>
                )}
              {filters.contentType === "goal" && filters.goal && (
                <div className="goalDetails">
                  <div className="entityName deadline">
                    Goal Deadline:{" "}
                    {moment(filters.goal.attributes.field_goal_date).format(
                      "MMMM Do, YYYY"
                    )}
                  </div>
                  <div className="entityName">
                    {filters.goal.learningPath &&
                      filters.goal.learningPath.attributes.name}
                  </div>
                  {(filters.goal.users.length > 0 ||
                    filters.goal.jobTitles.length > 0 ||
                    filters.goal.subGroups.length > 0) && (
                    <div className="visibility">
                      <span className="icon eye" />
                      Goal Visibility
                      <div className="content">
                        <div className="inner">
                          {filters.goal.users.length > 0 && (
                            <>
                              <header>Learners</header>
                              <ul>
                                {filters.goal.users.map((user) => {
                                  return (
                                    <li key={user.id}>
                                      {user.attributes.display_name}
                                    </li>
                                  )
                                })}
                              </ul>
                            </>
                          )}
                          {filters.goal.jobTitles.length > 0 && (
                            <>
                              <header>Job Titles</header>
                              <ul>
                                {filters.goal.jobTitles.map((title) => {
                                  return <li>{title.attributes.name}</li>
                                })}
                              </ul>
                            </>
                          )}
                          {filters.goal.subGroups.length > 0 && isAdmin && (
                            <>
                              <header>Groups</header>
                              <ul>
                                {filters.goal.subGroups.map((group) => {
                                  return <li>{group.attributes.label}</li>
                                })}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        {!isPartner && (
          <TableBody>
            {props.compliance.data.length > 0 &&
              props.compliance.data.map((user, index) => {
                let numCourses, percentComplete, trackWidth

                if (
                  filters.learningPath &&
                  filters.contentType === "learning-path"
                ) {
                  numCourses =
                    filters.learningPath.field_course_list.split(",").length
                  percentComplete =
                    (Number(user.lp_courses_completed) / numCourses) * 100
                }
                if (filters.goal && filters.contentType === "goal") {
                  numCourses = user.field_total_courses
                  percentComplete =
                    (Number(user.field_courses_completed) / numCourses) * 100

                  const dailyPace = Math.ceil(
                    Number(user.field_estimated_time) /
                      Number(user.field_duration)
                  )
                  const differenceDays = moment(
                    user.field_goal_date.slice(0, 10)
                  ).diff(moment(), "days")
                  const daysElapsed =
                    Number(user.field_duration) - differenceDays

                  trackWidth =
                    ((daysElapsed * dailyPace) /
                      Number(user.field_estimated_time)) *
                    100
                }

                let backpackId = user.uid ? user.uid : user.user_uuid
                if (user.user_id) {
                  backpackId = user.user_id
                }

                return (
                  <TableRow
                    key={user.uid ? user.uid : user.user_uuid}
                    className={
                      user.field_status === "Completed" ? "active" : "inactive"
                    }
                  >
                    <TableCell
                      sx={{
                        borderRadius: !index ? "0 12px 0 0" : 0,
                        display: "table-cell",
                      }}
                    >
                      <div className="flexRow">
                        <div
                          className="userInfo flexRow"
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div className="flexRow">
                            {filters.contentType === "course" && (
                              <ChallengeContent user={user} />
                            )}
                            {
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              <Link
                                onClick={() => {
                                  dispatch(resetBackpack())
                                }}
                                to={"/backpack/" + backpackId}
                              >
                                <UserLabel
                                  firstName={he.decode(
                                    user.field_first_name ?? ""
                                  )}
                                  lastName={he.decode(
                                    user.field_last_name ?? ""
                                  )}
                                  imagePath={
                                    user.user_picture
                                      ? process.env.REACT_APP_API_URL +
                                        user.user_picture
                                      : null
                                  }
                                  subgroups={user.field_subgroup}
                                />
                              </Link>
                            }
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRadius: !index ? "0 12px 0 0" : 0,
                        display: "table-cell",
                      }}
                      align={"right"}
                    >
                      <span>
                        <strong className="compliance-completed-date">
                          {filters.contentType === "event" && (
                            <>
                              <TimeCapsule
                                minutes={
                                  user.field_credit
                                    ? Number(
                                        user.field_credit.replace("min", "")
                                      )
                                    : 0
                                }
                              />
                              {user.field_attended === "1" && (
                                <span
                                  className="icon check"
                                  style={{
                                    width: 25,
                                    height: 25,
                                    marginRight: 5,
                                    marginLeft: 20,
                                  }}
                                />
                              )}
                            </>
                          )}

                          {filters.contentType === "mandated-training" && (
                            <div className="flexRow training-compliance">
                              {user.in_compliance_date ? (
                                <div>
                                  <strong>Training Completed</strong>
                                  <span style={{ opacity: 0.5, fontSize: 12 }}>
                                    {moment(user.in_compliance_date).format(
                                      "M/D/YYYY"
                                    )}
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <strong
                                    style={{ color: "rgb(228, 149, 66)" }}
                                  >
                                    Training Incomplete
                                  </strong>
                                </div>
                              )}
                              {user.in_compliance_date ? (
                                <span className="icon check" />
                              ) : (
                                <span className="icon overdue" />
                              )}
                            </div>
                          )}

                          {user.changed && (
                            <>
                              {user.field_status === "InProgress" && (
                                <>
                                  Started {generateTimestamp(user.changed)}{" "}
                                  <span className="icon laptop color" />
                                </>
                              )}
                              {(user.field_status === "GradingInProgress" ||
                                user.field_status === "Submitted") && (
                                <>
                                  Submitted {generateTimestamp(user.changed)}{" "}
                                  <span className="icon inbox" />
                                </>
                              )}

                              {user.field_status === "Completed" && (
                                <>
                                  {filters.contentType === "course" ? (
                                    <>
                                      {user.field_status === "Completed" && (
                                        <>
                                          <div>
                                            Completed{" "}
                                            {generateTimestamp(
                                              user.field_completed_date
                                            )}{" "}
                                            <div className="attempt-popover">
                                              {user.field_attempts}{" "}
                                              {pluralize(
                                                "Attempt",
                                                Number(user.field_attempts)
                                              )}
                                            </div>
                                          </div>
                                          <span className="icon check" />
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {user.field_status === "Completed" && (
                                        <>
                                          Completed{" "}
                                          {generateTimestamp(user.changed)}{" "}
                                          <span className="icon check" />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {user.badgeCreated && filters.badge && (
                            <div className="flexRow badgeEarned">
                              Earned {generateTimestamp(user.badgeCreated)}
                              <div
                                className="badgeImage"
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    filters.badge.field_badge_image_uri +
                                    ")",
                                }}
                              />
                              <span className="icon check" />
                            </div>
                          )}
                        </strong>

                        {user.field_submission_type === "Quiz" && (
                          <div className="quizScore">
                            <strong>{user.field_quiz_score * 100}%</strong>
                          </div>
                        )}
                      </span>

                      {(filters.contentType === "learning-path" ||
                        filters.contentType === "goal") &&
                        user.field_status !== "Completed" && (
                          <div className="complianceProgress">
                            <span>
                              {user.coursesComplete} / {numCourses} Courses
                              Completed
                            </span>
                            <ProgressBar
                              width={percentComplete}
                              trackWidth={trackWidth > 0 ? trackWidth : 0}
                            />
                          </div>
                        )}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        )}

        {isPartner && (
          <TableBody>
            {props.compliance.data.length > 0 &&
              props.compliance.data.map((org, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <Accordion style={{ flex: 1 }}>
                        <AccordionSummary>
                          <div className="flexRow" style={{ flex: 1 }}>
                            <div
                              className="partnerInfo flexRow"
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <span className="icon district" />
                              <div className="flexRow">
                                <strong>{org.label}</strong>
                                {org.org_subgroups.length > 0 && (
                                  <span className="icon caret" />
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: theme.palette.primary.main,
                              fontSize: 28,
                            }}
                          >
                            <strong>{org.completed_count}</strong>
                            <span className="icon check" />
                          </div>
                        </AccordionSummary>

                        {org.org_subgroups.length > 0 && (
                          <AccordionDetails>
                            <ul
                              style={{
                                listStyleType: "none",
                                padding: 0,
                                marginTop: -5,
                                marginLeft: -10,
                                marginRight: -10,
                                marginBottom: 0,
                              }}
                            >
                              {org.org_subgroups.map((subgroup, index) => {
                                return (
                                  <li
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: index ? 10 : 0,
                                      padding: 10,
                                      paddingLeft: 45,
                                      paddingRight: 45,
                                      borderTop: "1px solid #ebebeb",
                                    }}
                                  >
                                    <strong style={{ flex: 1 }}>
                                      <span className="icon school" />
                                      {subgroup.label}
                                    </strong>

                                    <span className="count">
                                      {subgroup.completed_count}
                                    </span>
                                  </li>
                                )
                              })}
                            </ul>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        )}
      </Table>
    </BottomScrollListener>
  )
}

class ComplianceLayout extends Component {
  componentDidMount = () => {
    this.props.session.group.uuid &&
      !this.props.session.subGroups.fetched &&
      this.props.getSubGroups(this.props.session.group.uuid[0].value)

    const allRoles = this.props.session.orgRoles
      .concat(this.props.session.groupRoles)
      .join(",")

    const isObserverOnly =
      !allRoles.includes("organization-admin") &&
      !allRoles.includes("group-admin") &&
      !allRoles.includes("reporter")

    const isObserver =
      allRoles.includes("observer") || allRoles.includes("observation")

    if (isObserverOnly && isObserver) {
      this.props.setContentType({ value: "rubric" })
    }

    this.props.getKnowBe4Campaigns()
    if (this.props.session)
      !this.props.entityList.mandatedTraining.fetched &&
        this.props.getMandatedTraining({
          jobTitle: "any",
          pathType: "any",
          searchQuery: "",
          sortBy: "changed",
          sortOrder: "DESC",
          status: "any",
          subGroup: "any",
        })
    !this.props.entityList.orgRubrics.fetched && this.props.getOrgRubrics()

    this.props.getJobTitles()
  }

  render() {
    const props = this.props
    const { compliance, session } = props
    const { partnerFilters, filters } = compliance
    const { isPartner } = session

    return (
      <ErrorBoundary>
        <div
          className={classNames(
            "page hasSidebar reports compliance",
            isPartner && "partner-report"
          )}
        >
          {isPartner ? (
            <SidebarPartnerCompliance {...props} />
          ) : (
            <SidebarCompliance {...props} />
          )}
          <ComplianceNavbarContainer />

          <>
            {!compliance.learningPath.missingEntity &&
              ((isPartner &&
                partnerFilters.contentType === "lp_compliance_report") ||
                (!isPartner && filters.contentType === "learning-path")) && (
                <ComplianceReport {...props} />
              )}

            {!compliance.mandatedTraining.missingEntity &&
              !isPartner &&
              filters.contentType === "mandated-training" && (
                <ComplianceReport {...props} />
              )}
            {!compliance.badge.missingEntity &&
              ((isPartner &&
                partnerFilters.contentType === "badges_compliance_report") ||
                (!isPartner && filters.contentType === "badge")) && (
                <ComplianceReport {...props} />
              )}
            {!compliance.course.missingEntity &&
              ((isPartner &&
                partnerFilters.contentType === "course_compliance_report") ||
                (!isPartner && filters.contentType === "course")) && (
                <ComplianceReport {...props} />
              )}
            {!props.compliance.goal.missingEntity &&
              props.compliance.filters.contentType === "goal" && (
                <ComplianceReport {...props} />
              )}
            {!props.compliance.activeEvent.missingEntity &&
              props.compliance.filters.contentType === "event" && (
                <ComplianceReport {...props} />
              )}

            {!props.compliance.knowBe4Campaign.missingEntity &&
              props.compliance.filters.contentType === "knowbe4" && (
                <KnowBe4ComplianceTable {...props} />
              )}

            {!props.compliance.rubric.missingEntity &&
              props.compliance.filters.contentType === "rubric" && (
                <RubricComplianceTable {...props} />
              )}
          </>

          {props.compliance.learningPath.missingEntity &&
            ((props.compliance.filters.contentType === "learning-path" &&
              !isPartner) ||
              (props.compliance.partnerFilters.contentType ===
                "lp_compliance_report" &&
                isPartner)) && (
              <p className="helperText">
                <span className="icon learningPath color" />
                Select a Learning Path in the Sidebar to see compliance for that
                specific Learning Path.
              </p>
            )}

          {props.compliance.mandatedTraining.missingEntity &&
            props.compliance.filters.contentType === "mandated-training" && (
              <p className="helperText">
                <span className="icon mandated color" />
                Select a Mandated Training in the Sidebar to see compliance for
                that specific training.
              </p>
            )}

          {props.compliance.course.missingEntity &&
            ((props.compliance.filters.contentType === "course" &&
              !isPartner) ||
              (props.compliance.partnerFilters.contentType ===
                "course_compliance_report" &&
                isPartner)) && (
              <p className="helperText">
                <span className="icon laptop color" />
                Select a Course in the Sidebar to see compliance for that
                specific Course.
              </p>
            )}

          {props.compliance.badge.missingEntity &&
            ((props.compliance.filters.contentType === "badge" && !isPartner) ||
              (props.compliance.partnerFilters.contentType ===
                "badges_compliance_report" &&
                isPartner)) && (
              <p className="helperText">
                <span className="icon badge" />
                Select a Badge in the Sidebar to see compliance for that
                specific Badge.
              </p>
            )}

          {props.compliance.activeEvent.missingEntity &&
            props.compliance.filters.contentType === "event" && (
              <p className="helperText">
                <span className="icon event color" />
                Select an Event in the Sidebar to see attendance for that
                specific Event.
              </p>
            )}

          {props.compliance.goal.missingEntity &&
            props.compliance.filters.contentType === "goal" && (
              <p className="helperText">
                <span className="icon goal" />
                Select a Goal in the Sidebar to see compliance for that specific
                Goal.
              </p>
            )}
          {props.compliance.knowBe4Campaign.missingEntity &&
            props.compliance.filters.contentType === "knowbe4" && (
              <p className="helperText">
                <img className="icon" src={KnowBe4Icon} alt={"KnowBe4"} />
                Select a KnowBe4 Campaign in the Sidebar to see compliance for
                that specific campaign.
              </p>
            )}
          {props.compliance.rubric.missingEntity &&
            props.compliance.filters.contentType === "rubric" && (
              <p className="helperText">
                <img className="icon" src={RubricIconColor} alt={"rubric"} />
                Select a Rubric in the Sidebar to see results for that specific
                rubric.
              </p>
            )}

          {props.compliance.fetching &&
            props.compliance.filters.contentType !== "knowbe4" && (
              <Loading
                message={
                  props.compliance.currentPage > 0
                    ? "Getting more results..."
                    : "Getting results..."
                }
              />
            )}

          {props.compliance.fetching &&
            props.compliance.filters.contentType === "knowbe4" && (
              <div style={{ marginTop: -35 }}>
                <Loading
                  message={
                    "Getting campaign results, this may take a moment..."
                  }
                />
              </div>
            )}
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceLayout)
