import { StyledDialog } from "components/StyledDialog"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { CreateCourseGenieFormValues } from "../common/createCourseGenieSchema"
import { CreateCourseGenieStep } from "../common/types"
import { CreateCourseGenieContext } from "../components/CreateCourseGenieContext"
import { CreateCourseGenieSearch } from "../components/CreateCourseGenieSearch"
import { CreateCourseGenieStart } from "../components/CreateCourseGenieStart"
import { CreateCourseGenieVideoUrl } from "../components/CreateCourseGenieVideoUrl"

type CreateCourseGenieContainerProps = {
  open: boolean
  onClose: () => void
}

export const CreateCourseGenieContainer = (
  props: CreateCourseGenieContainerProps
) => {
  const history = useHistory()
  const [currentStep, setCurrentStep] = useState<CreateCourseGenieStep | null>(
    null
  )

  const handleCreateCourse = (
    videoId: string,
    values: CreateCourseGenieFormValues
  ) => {
    history.push("/create/courses/new", {
      ...values,
      videoId,
    })
  }

  useEffect(() => {
    if (props.open && !currentStep) {
      setCurrentStep(CreateCourseGenieStep.Start)
    } else if (!props.open) {
      setCurrentStep(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  return (
    <StyledDialog
      open={currentStep !== null}
      onClose={props.onClose}
      // Make the dialog wider on the search results page but not on the
      // review page
      maxWidth={
        currentStep === CreateCourseGenieStep.SearchResults ? "lg" : "sm"
      }
      fullWidth={false}
    >
      <CreateCourseGenieContext.Provider
        value={{
          currentStep,
          setCurrentStep,
          onSubmit: handleCreateCourse,
        }}
      >
        {currentStep === CreateCourseGenieStep.Start && (
          <CreateCourseGenieStart />
        )}
        {(currentStep === CreateCourseGenieStep.Search ||
          currentStep === CreateCourseGenieStep.SearchResults ||
          currentStep === CreateCourseGenieStep.SearchReview) && (
          <CreateCourseGenieSearch />
        )}
        {currentStep === CreateCourseGenieStep.VideoUrl && (
          <CreateCourseGenieVideoUrl />
        )}
      </CreateCourseGenieContext.Provider>
    </StyledDialog>
  )
}
