import { Box, FormControl, Stack, Typography } from "@mui/material"
import HandHeartIcon from "../../../img/hand-heart.svg"
import SchoolIcon from "../../../img/school.svg"
import { CourseFormValues } from "../types"

type CommunityContributionProps = {
  course: CourseFormValues
}

export const CommunityContribution = ({
  course,
}: CommunityContributionProps) => {
  if (
    course.creatorOrgName &&
    (course.displayCredit ||
      course.sourceTemplate?.attributes.field_credit_organization)
  ) {
    return (
      <FormControl>
        <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
          <img src={HandHeartIcon} alt="Community" width={30} height={30} />

          <Typography variant="h3" sx={{ marginTop: 5 }}>
            Community Contribution
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{ alignItems: "center", marginTop: 3 }}
          spacing={2}
        >
          <Box
            sx={{
              width: 20,
              height: 20,
              borderWidth: 3,
              borderColor: "white",
              borderStyle: "solid",
              boxShadow: 1,
              borderRadius: 5,
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={SchoolIcon} alt="Community" width={20} height={20} />
          </Box>

          <Typography
            sx={{ fontSize: 12, textTransform: "uppercase", opacity: 0.8 }}
          >
            {course.sourceTemplate ? (
              <>{course.creatorOrgName}</>
            ) : (
              <>{course.displayCredit && <>{course.creatorOrgName}</>}</>
            )}
          </Typography>
        </Stack>
      </FormControl>
    )
  }
  return null
}
