import { SkillLevel, SubmissionType } from "@mobilemind/common/src/types/course"

export enum CreateCourseGenieStep {
  Start,
  Search,
  SearchResults,
  SearchReview,
  VideoUrl,
}

export type CreateCourseGenieStartValues = {
  videoId: string
  level: SkillLevel
  submissionType: SubmissionType
}
