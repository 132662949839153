import { flatMapHelperText } from "@mobilemind/common/src/utility/yup"
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { FastField, Field, FieldArray, useFormikContext } from "formik"
import { get } from "lodash"
import { useMemo } from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { CourseFormValues } from "../types"
import { QuizAddQuestionButton } from "./QuizAddQuestionButton"
import { QuizQuestionField } from "./QuizQuestionField"

export const QuizSubmissionTypeFields = () => {
  const { errors, submitCount, values } = useFormikContext<CourseFormValues>()
  const quizErrors = useMemo(
    () => flatMapHelperText(get(errors, "quizQuestions")),
    [errors]
  )
  return (
    <>
      <FormControl
        required={true}
        error={submitCount > 0 && quizErrors.length > 0}
      >
        <FieldArray
          name="quizQuestions"
          render={(arrayHelpers) => (
            <>
              <DragDropContext
                onDragEnd={(droppedItem) => {
                  const { source, destination } = droppedItem
                  if (source && destination) {
                    arrayHelpers.move(source.index, destination.index)
                  }
                }}
              >
                <Droppable droppableId={"quiz-questions-list"}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      role="list"
                    >
                      {values.quizQuestions.map((question, questionIndex) => {
                        return (
                          <QuizQuestionField
                            key={question.id + "-" + questionIndex}
                            name={`quizQuestions[${questionIndex}]`}
                            questionIndex={questionIndex}
                            onDelete={
                              values.quizQuestions.length > 1
                                ? () => arrayHelpers.remove(questionIndex)
                                : undefined
                            }
                          />
                        )
                      })}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>

              <Box
                component="footer"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {values.quizQuestions.length < 20 ? (
                  <QuizAddQuestionButton {...arrayHelpers} />
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontSize: 14,
                      marginTop: 0,
                      paddingBottom: 5,
                      fontWeight: "bold",
                      opacity: 0.6,
                    }}
                  >
                    Micro-course quizzes are limited to 20 questions.
                  </p>
                )}
              </Box>
            </>
          )}
        />
        {submitCount > 0 && quizErrors.length > 0 && (
          <FormHelperText sx={{ marginInline: 0 }}>
            {quizErrors.map((value) => (
              <div key={value}>{value}</div>
            ))}
          </FormHelperText>
        )}
      </FormControl>

      <Stack
        direction="row"
        sx={{ alignItems: "flex-start", marginBottom: 6, marginTop: 4 }}
        spacing={5}
      >
        <Stack
          direction="column"
          sx={{ flex: 1, marginBottom: 10, paddingTop: 3 }}
        >
          <Stack direction="row" sx={{ flex: 1, alignItems: "center" }}>
            <Typography sx={{ flex: 1 }} variant="h3">
              Show Missed Questions
            </Typography>

            <Field
              as={Checkbox}
              name="showMissedQuestions"
              checked={values.showMissedQuestions}
            />
          </Stack>
          <FormHelperText sx={{ marginTop: -2 }}>
            Learners will see which questions they answered incorrectly.
          </FormHelperText>

          <Stack direction="column" sx={{ flex: 1, marginTop: 2 }}>
            <Stack direction="row" sx={{ flex: 1, alignItems: "center" }}>
              <Typography sx={{ flex: 1 }} variant="h3">
                Show Correct Answers
              </Typography>

              <Field
                as={Checkbox}
                name="showCorrectAnswers"
                checked={values.showCorrectAnswers}
              />
            </Stack>
            <FormHelperText sx={{ marginTop: -2 }}>
              Learnings will see the correct answer for questions they missed.
            </FormHelperText>
          </Stack>
        </Stack>

        <Stack direction="column" sx={{ flex: 1 }}>
          <Stack direction="row" sx={{ flex: 1, alignItems: "center" }}>
            <Typography sx={{ flex: 1 }} variant="h3">
              Required Score to Pass
            </Typography>
            <Box sx={{ width: 65, position: "relative", top: 12 }}>
              <FastField
                as={TextField}
                name="quizPassThreshold"
                className="passThreshold"
                label="Score"
                margin="normal"
                variant="standard"
                inputProps={{ type: "number", min: 0, max: 100 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                sx={{
                  width: 65,

                  "& .MuiInput-root": {
                    width: 65,
                    "& .MuiInputBase-input": {
                      width: 65,
                      maxWidth: 65,
                    },
                  },
                }}
              />
            </Box>
          </Stack>
          <FormHelperText sx={{ marginTop: -5 }}>
            The minimum score to pass the quiz.
          </FormHelperText>
        </Stack>
      </Stack>
    </>
  )
}
