import { Button } from "@mui/material"
import "../styles/login.scss"
import logoImg from "../img/logo.png"
import theme from "@mobilemind/common/src/theme/theme"

function Login() {
  return (
    <div className="page login">
      <div
        className="backgroundWallpaper"
        style={{
          transition: "1s",
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          filter: "grayscale(1)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: "url(" + theme.mixins.images.hqBackground + ")",
        }}
      />

      <div
        style={{
          opacity: 0.85,
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          background:
            "linear-gradient(120deg, " +
            "black" +
            " 0%, " +
            "#eee" +
            " 70%, " +
            "#eee" +
            " 100%)",
        }}
        className="background"
      />
      <div className="loginWrapper">
        <div className="flexRow">
          <img style={{ height: 65 }} src={logoImg} alt="MobileMind" />
        </div>
        <div className="flexRow">
          <Button
            className="button login"
            href={`${process.env.REACT_APP_API_URL}/user/login/google`}
          >
            <span className="icon google official" />
            <span> Sign in with Google</span>
          </Button>
        </div>
        <div className="flexRow">
          <Button
            className="button login microsoft"
            href={`${process.env.REACT_APP_API_URL}/user/login/microsoft`}
          >
            <span className="icon microsoft" />
            Sign in with Microsoft
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login
