import { createSlice } from "@reduxjs/toolkit"

export const entityListSlice = createSlice({
  name: "entityList",
  initialState: {
    currentPage: "Courses",
    orgCourses: {
      fetched: false,
      currentTab: "Courses",
      isFetchingMore: false,
      data: [],
      currentPage: 0,
      totalPages: 1,
      totalRecords: 0,

      templates: {
        fetched: false,
        isFetchingMore: false,
        data: [],
        currentPage: 0,
        totalPages: 1,
        totalRecords: 0,
        filters: {
          searchQuery: "",
          category: null,
          source: "any",
          state: { name: "Any" },
          sortBy: "number_completed",
          sortOrder: "DESC",
        },
      },

      filters: {
        searchQuery: "",
        status: "any",
        category: null,
        subGroup: "any",
        jobTitle: "any",
        sortBy: "changed",
        sortOrder: "DESC",
      },
    },
    orgBadges: {
      currentTab: "Badges",
      fetched: false,
      isFetchingMore: false,
      data: [],
      currentPage: 0,
      totalPages: 1,
      totalRecords: 0,

      filters: {
        searchQuery: "",
        status: "any",
        category: null,
        subGroup: "any",
        jobTitle: "any",
        sortBy: "changed",
        sortOrder: "DESC",
      },
    },
    orgRubrics: {
      currentTab: "Rubrics",
      fetched: false,
      isFetchingMore: false,
      data: [],
      currentPage: 0,
      totalPages: 1,
      totalRecords: 0,

      filters: {
        searchQuery: "",
        status: "any",
        sortOrder: "DESC",
        sort_by: "changed",
      },
    },
    orgLearningPaths: {
      currentTab: "Learning Paths",
      fetched: false,
      isFetchingMore: false,
      data: [],
      currentPage: 0,
      totalPages: 1,
      totalRecords: 0,

      filters: {
        searchQuery: "",
        status: "any",
        pathType: "any",
        subGroup: "any",
        jobTitle: "any",
        sortBy: "changed",
        sortOrder: "DESC",
      },
    },
    mandatedTraining: {
      data: [],
      fetched: false,
      filters: {
        searchQuery: "",
        status: "any",
        pathType: "any",
        subGroup: "any",
        jobTitle: "any",
        sortBy: "changed",
        sortOrder: "DESC",
      },
    },
    orgGoals: {
      currentTab: "Goals",
      fetched: false,
      isFetchingMore: false,
      data: [],
      currentPage: 0,
      totalPages: 1,
      totalRecords: 0,
      filters: {
        searchQuery: "",
        status: "any",
        subGroup: "any",
        jobTitle: "any",
        sortBy: "changed",
        sortOrder: "DESC",
      },
    },
    orgAnnouncements: {
      currentTab: "Announcements",
      fetched: false,
      isFetchingMore: false,
      data: [],
      currentPage: 0,
      totalPages: 1,
      totalRecords: 0,

      filters: {
        searchQuery: "",
        status: "any",
        announcementType: "any",
        subGroup: "any",
        sortBy: "changed",
        sortOrder: "DESC",
      },
    },
  },
  reducers: {
    setRubricStatusFilter: (state, action) => {
      if (action.payload === "draft") {
        state.orgRubrics.filters.field_draft_value = 1
        state.orgRubrics.filters.field_archive_value = 0
      } else if (action.payload === "archived") {
        state.orgRubrics.filters.field_draft_value = 0
        state.orgRubrics.filters.field_archive_value = 1
      } else if (action.payload === "published") {
        state.orgRubrics.filters.field_draft_value = 0
        state.orgRubrics.filters.field_archive_value = 0
      } else {
        state.orgRubrics.filters.field_draft_value = null
        state.orgRubrics.filters.field_archive_value = null
      }

      state.orgRubrics.filters.status = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    handleCourseCheck: (state, action) => {
      let targetCourse = state.orgCourses.data.find(
        (course) => course.uuid === action.payload.uuid
      )
      targetCourse.isChecked = action.payload.isChecked
    },
    publishCourse: (state, action) => {
      let targetCourse = state.orgCourses.data.find(
        (course) => course.uuid === action.payload.uuid
      )
      targetCourse.isChecked = false
      targetCourse.field_draft = "0"
    },
    removeCourseReviewer: (state, action) => {
      state.orgCourses.data = state.orgCourses.data.map((course) => {
        if (course.uuid === action.payload.course.uuid) {
          course.removed = true
        }

        return course
      })
    },

    increasePage: (state, action) => {
      if (action.payload.type === "templates") {
        state.orgCourses.templates.currentPage =
          state.orgCourses.templates.currentPage + 1
        state.orgCourses.templates.isFetchingMore = true
      } else {
        state[action.payload.type].currentPage =
          state[action.payload.type].currentPage + 1
        state[action.payload.type].isFetchingMore = true
      }
    },

    getTemplates: (state, action) => {
      if (!state.orgCourses.templates.currentPage) {
        state.orgCourses.templates.data = action.payload
      } else {
        let newTemplates = []
        action.payload.forEach((course) => {
          let existing = state.orgCourses.templates.data.find(
            (item) => item.id === course.id
          )
          if (!existing) {
            newTemplates.push(course)
          }
        })
        state.orgCourses.templates.data =
          state.orgCourses.templates.data.concat(newTemplates)
      }

      state.orgCourses.templates.totalPages = action.meta.pages
      state.orgCourses.templates.totalRecords = action.meta.totalRecords
      state.orgCourses.templates.isFetchingMore = false
      state.orgCourses.templates.fetched = action.meta.fetched
    },

    getOrgCourses: (state, action) => {
      state.orgCourses.fetched = true

      action.payload.forEach((course) => {
        course.isChecked = false
      })

      if (!state.orgCourses.currentPage) {
        state.orgCourses.data = action.payload
      } else {
        action.payload.forEach((course) => {
          let existing = state.orgCourses.data.find(
            (item) => item.id === course.id
          )
          if (!existing) {
            state.orgCourses.data = state.orgCourses.data.concat(action.payload)
          }
        })
      }

      state.orgCourses.totalPages = action.meta.pages
      state.orgCourses.totalRecords = action.meta.totalRecords
      state.orgCourses.isFetchingMore = false
    },
    getOrgBadges: (state, action) => {
      state.orgBadges.fetched = true
      state.orgBadges.totalPages = action.meta.pages
      state.orgBadges.totalRecords = action.meta.totalRecords
      state.orgBadges.isFetchingMore = false

      if (!state.orgBadges.currentPage) {
        state.orgBadges.data = action.payload
      } else {
        action.payload.forEach((badge) => {
          let existing = state.orgBadges.data.find(
            (item) => item.id === badge.id
          )
          if (!existing) {
            state.orgBadges.data = state.orgBadges.data.concat(action.payload)
          }
        })
      }
    },
    getOrgRubrics: (state, action) => {
      state.orgRubrics.fetched = true
      state.orgRubrics.totalPages = action.meta.pages
      state.orgRubrics.totalRecords = action.meta.totalRecords
      state.orgRubrics.isFetchingMore = false

      if (!state.orgRubrics.currentPage) {
        state.orgRubrics.data = action.payload
      } else {
        action.payload.forEach((badge) => {
          let existing = state.orgRubrics.data.find(
            (item) => item.id === badge.id
          )
          if (!existing) {
            state.orgRubrics.data = state.orgRubrics.data.concat(action.payload)
          }
        })
      }
    },

    getOrgAnnouncements: (state, action) => {
      state.orgAnnouncements.totalPages = action.meta.pages
      state.orgAnnouncements.totalRecords = action.meta.totalRecords
      state.orgAnnouncements.isFetchingMore = false
      state.orgAnnouncements.fetched = true

      if (!state.orgAnnouncements.currentPage) {
        state.orgAnnouncements.data = action.payload
      } else {
        action.payload.forEach((announcement) => {
          let existing = state.orgAnnouncements.data.find(
            (item) => item.id === announcement.id
          )
          if (!existing) {
            state.orgAnnouncements.data = state.orgAnnouncements.data.concat(
              action.payload
            )
          }
        })
      }
    },

    getOrgLearningPaths: (state, action) => {
      state.orgLearningPaths.totalPages = action.meta.pages
      state.orgLearningPaths.totalRecords = action.meta.totalRecords
      state.orgLearningPaths.isFetchingMore = false
      state.orgLearningPaths.fetched = true

      if (!state.orgLearningPaths.currentPage) {
        state.orgLearningPaths.data = action.payload
      } else {
        action.payload.forEach((path) => {
          let existing = state.orgLearningPaths.data.find(
            (item) => item.id === path.id
          )
          if (!existing) {
            state.orgLearningPaths.data = state.orgLearningPaths.data.concat(
              action.payload
            )
          }
        })
      }
    },
    getMandatedTraining: (state, action) => {
      state.mandatedTraining.data = action.payload
      state.mandatedTraining.fetched = true
    },

    getOrgGoals: (state, action) => {
      state.orgGoals.totalPages = action.meta.pages
      state.orgGoals.totalRecords = action.meta.totalRecords
      state.orgGoals.isFetchingMore = false
      state.orgGoals.fetched = true

      if (!state.orgGoals.currentPage) {
        state.orgGoals.data = action.payload
      } else {
        action.payload.forEach((goal) => {
          let existing = state.orgGoals.data.find((item) => item.id === goal.id)
          if (!existing) {
            state.orgGoals.data = state.orgGoals.data.concat(action.payload)
          }
        })
      }
    },

    addCreatedBadge: (state, action) => {
      action.payload.name = action.payload.attributes.name
      action.payload.created = action.payload.attributes.created
      action.payload.uuid = action.payload.id
      action.payload.field_draft = action.payload.attributes.field_draft
        ? "1"
        : "0"
      state.orgBadges.data.unshift(action.payload)
    },
    addCreatedLearningPath: (state, action) => {
      action.payload.name = action.payload.attributes.name
      action.payload.created = action.payload.attributes.created
      action.payload.uuid = action.payload.id
      action.payload.field_draft = action.payload.attributes.field_draft
        ? "1"
        : "0"
      state.orgLearningPaths.data.unshift(action.payload)
    },
    addCreatedAnnouncement: (state, action) => {
      action.payload.name = action.payload.attributes.title
      action.payload.created = action.payload.attributes.created
      action.payload.uuid = action.payload.id
      action.payload.field_draft = action.payload.attributes.field_draft
        ? "1"
        : "0"
      state.orgAnnouncements.data.unshift(action.payload)
    },
    addCreatedCourse: (state, action) => {
      state.orgCourses.currentTab = "Courses"
      action.payload.name = action.payload.attributes.name
      action.payload.created = action.payload.attributes.created
      action.payload.uuid = action.payload.id
      action.payload.field_draft = action.payload.attributes.field_draft
        ? "1"
        : "0"
      state.orgCourses.data.unshift(action.payload)
    },
    addCreatedGoal: (state, action) => {
      action.payload.name = action.payload.attributes.name
      action.payload.created = action.payload.attributes.created
      action.payload.uuid = action.payload.id
      action.payload.field_draft = action.payload.attributes.field_draft
        ? "1"
        : "0"
      state.orgGoals.data.unshift(action.payload)
    },
    publishMandatedTraining: (state, action) => {
      let targetTraining = state.mandatedTraining.data.find(
        (training) => training.uuid === action.payload.id
      )
      targetTraining.field_draft = "0"
      targetTraining.image = action.meta.image
    },
    deleteCourse: (state, action) => {
      state.orgCourses.data = state.orgCourses.data.filter(
        (course) => course.uuid !== action.payload
      )
    },
    deleteBadge: (state, action) => {
      state.orgBadges.data = state.orgBadges.data.filter(
        (badge) => badge.uuid !== action.payload
      )
    },
    deleteAnnouncement: (state, action) => {
      state.orgAnnouncements.data = state.orgAnnouncements.data.filter(
        (announcement) => announcement.uuid !== action.payload
      )
    },
    deleteMandatedTraining: (state, action) => {
      state.mandatedTraining.data = state.mandatedTraining.data.filter(
        (training) => training.uuid !== action.payload
      )
    },
    deleteLearningPath: (state, action) => {
      state.orgLearningPaths.data = state.orgLearningPaths.data.filter(
        (path) => path.uuid !== action.payload
      )
    },
    deleteGoal: (state, action) => {
      state.orgGoals.data = state.orgGoals.data.filter(
        (goal) => goal.uuid !== action.payload
      )
    },
    setPathType: (state, action) => {
      state.orgLearningPaths.fetched = false
      state.orgLearningPaths.data = []
      state.orgLearningPaths.currentPage = 0
      state.orgLearningPaths.filters.pathType = action.payload
    },
    setAnnouncementType: (state, action) => {
      state.orgAnnouncements.fetched = false
      state.orgAnnouncements.filters.currentPage = 0
      state.orgAnnouncements.data = []
      state.orgAnnouncements.filters.announcementType = action.payload
    },
    updateBadge: (state, action) => {
      if (action.payload.image) {
        action.payload.image = action.payload.image.attributes
          ? action.payload.image.attributes.uri.url
          : action.payload.image
      }

      action.payload.name = action.payload.attributes.name
      action.payload.created = action.payload.attributes.created
      action.payload.field_draft = action.payload.attributes.field_draft
        ? "1"
        : "0"
      action.payload.field_archive = action.payload.attributes.field_archive
        ? "1"
        : "0"

      let index = state.orgBadges.data.findIndex(
        (badge) => badge.uuid === action.payload.id
      )
      action.payload.id = action.payload.attributes.drupal_internal__id
      state.orgBadges.data[index] = action.payload
      state.orgBadges.data = state.orgBadges.data.filter(
        (badge) => badge.uuid !== action.payload.id
      )
    },
    updateLearningPath: (state, action) => {
      if (action.payload.image) {
        action.payload.image = action.payload.image.attributes
          ? action.payload.image.attributes.uri.url
          : action.payload.image
      }

      action.payload.name = action.payload.attributes.name
      action.payload.created = action.payload.attributes.created
      action.payload.field_draft = action.payload.attributes.field_draft
        ? "1"
        : "0"
      action.payload.field_archive = action.payload.attributes.field_archive
        ? "1"
        : "0"

      let index = state.orgLearningPaths.data.findIndex(
        (path) => path.uuid === action.payload.id
      )
      action.payload.id = action.payload.attributes.drupal_internal__id
      state.orgLearningPaths.data[index] = action.payload
      state.orgLearningPaths.data = state.orgLearningPaths.data.filter(
        (path) => path.uuid !== action.payload.id
      )
    },
    updateAnnouncement: (state, action) => {
      if (action.payload.image) {
        action.payload.image = action.payload.image.attributes
          ? action.payload.image.attributes.uri.url
          : action.payload.image
      }

      action.payload.name = action.payload.attributes.title
      action.payload.created = action.payload.attributes.created
      action.payload.field_draft = action.payload.attributes.field_draft
        ? "1"
        : "0"
      action.payload.field_archive = action.payload.attributes.field_archive
        ? "1"
        : "0"

      let index = state.orgAnnouncements.data.findIndex(
        (announcement) => announcement.uuid === action.payload.id
      )
      action.payload.id = action.payload.attributes.drupal_internal__nid
      state.orgAnnouncements.data[index] = action.payload
      state.orgAnnouncements.data = state.orgAnnouncements.data.filter(
        (announcement) => announcement.uuid !== action.payload.id
      )
    },
    updateCourse: (state, action) => {
      action.payload.name = action.payload.attributes.name
      action.payload.created = action.payload.attributes.created
      action.payload.field_draft = action.payload.attributes.field_draft
        ? "1"
        : "0"
      action.payload.field_archive = action.payload.attributes.field_archive
        ? "1"
        : "0"

      let index = state.orgCourses.data.findIndex(
        (course) => course.uuid === action.payload.id
      )
      action.payload.id = action.payload.attributes.drupal_internal__id
      state.orgCourses.data[index] = action.payload
      state.orgCourses.data = state.orgCourses.data.filter(
        (course) => course.uuid !== action.payload.id
      )
    },
    updateGoal: (state, action) => {
      action.payload.name = action.payload.attributes.name
      action.payload.created = action.payload.attributes.created
      action.payload.field_draft = action.payload.attributes.field_draft
        ? "1"
        : "0"

      let index = state.orgGoals.data.findIndex(
        (goal) => goal.uuid === action.payload.id
      )
      action.payload.id = action.payload.attributes.drupal_internal__id
      state.orgGoals.data[index] = action.payload
      state.orgGoals.data = state.orgGoals.data.filter(
        (goal) => goal.uuid !== action.payload.id
      )
    },
    setCategory: (state, action) => {
      switch (state.currentPage) {
        case "Badges":
          state.orgBadges.fetched = false
          state.orgBadges.data = []
          state.orgBadges.currentPage = 0
          state.orgBadges.filters.category = action.payload.value
          break
        // Courses
        default:
          if (state.orgCourses.currentTab === "Templates") {
            state.orgCourses.templates.fetched = false
            state.orgCourses.templates.currentPage = 0
            state.orgCourses.templates.filters.category = action.payload.value
          } else {
            state.orgCourses.fetched = false
            state.orgCourses.currentPage = 0
            state.orgCourses.filters.category = action.payload.value
          }
          break
      }
    },
    setSearchQuery: (state, action) => {
      switch (state.currentPage) {
        case "Badges":
          state.orgBadges.fetched = false
          state.orgBadges.currentPage = 0
          state.orgBadges.data = []
          state.orgBadges.filters.searchQuery = action.payload
          break
        case "Rubrics":
          state.orgRubrics.fetched = false
          state.orgRubrics.currentPage = 0
          state.orgRubrics.data = []
          state.orgRubrics.filters.searchQuery = action.payload
          break

        case "Learning Paths":
          state.orgLearningPaths.fetched = false
          state.orgLearningPaths.currentPage = 0
          state.orgLearningPaths.data = []
          state.orgLearningPaths.filters.searchQuery = action.payload
          break
        case "Goals":
          state.orgGoals.fetched = false
          state.orgGoals.currentPage = 0
          state.orgGoals.data = []
          state.orgGoals.filters.searchQuery = action.payload
          break

        case "Announcements":
          state.orgAnnouncements.fetched = false
          state.orgAnnouncements.currentPage = 0
          state.orgAnnouncements.data = []
          state.orgAnnouncements.filters.searchQuery = action.payload
          break
        case "Mandated Training":
          state.mandatedTraining.fetched = false
          state.mandatedTraining.currentPage = 0
          state.mandatedTraining.data = []
          state.mandatedTraining.filters.searchQuery = action.payload
          break
        // Courses
        default:
          if (state.orgCourses.currentTab === "Templates") {
            state.orgCourses.templates.fetched = false
            state.orgCourses.templates.currentPage = 0
            state.orgCourses.templates.data = []
            state.orgCourses.templates.filters.searchQuery = action.payload
          } else {
            state.orgCourses.fetched = false
            state.orgCourses.currentPage = 0
            state.orgCourses.data = []
            state.orgCourses.filters.searchQuery = action.payload
          }
          break
      }
    },
    setJobTitle: (state, action) => {
      switch (state.currentPage) {
        case "Badges":
          state.orgBadges.fetched = false
          state.orgBadges.data = []
          state.orgBadges.currentPage = 0
          state.orgBadges.filters.jobTitle = action.payload
          break
        case "Learning Paths":
          state.orgLearningPaths.fetched = false
          state.orgLearningPaths.data = []
          state.orgLearningPaths.currentPage = 0
          state.orgLearningPaths.filters.jobTitle = action.payload
          break
        case "Goals":
          state.orgGoals.fetched = false
          state.orgGoals.currentPage = 0
          state.orgGoals.data = []
          state.orgGoals.filters.jobTitle = action.payload
          break
        case "Mandated Training":
          state.mandatedTraining.fetched = false
          state.mandatedTraining.currentPage = 0
          state.mandatedTraining.data = []
          state.mandatedTraining.filters.jobTitle = action.payload
          break

        default:
          state.orgCourses.currentPage = 0
          state.orgCourses.filters.jobTitle = action.payload
          break
      }
    },
    setOrganization: (state, action) => {
      switch (state.currentPage) {
        case "Badges":
          state.orgBadges.fetched = false
          state.orgBadges.data = []
          state.orgBadges.currentPage = 0
          state.orgBadges.filters.organization = action.payload
          break
        case "Learning Paths":
          state.orgLearningPaths.fetched = false
          state.orgLearningPaths.data = []
          state.orgLearningPaths.currentPage = 0
          state.orgLearningPaths.filters.organization = action.payload
          break
        case "Goals":
          state.orgGoals.fetched = false
          state.orgGoals.currentPage = 0
          state.orgGoals.data = []
          state.orgGoals.filters.organization = action.payload
          break
        case "Announcements":
          state.orgAnnouncements.fetched = false
          state.orgAnnouncements.data = []
          state.orgAnnouncements.currentPage = 0
          state.orgAnnouncements.filters.organization = action.payload
          break
        default:
          state.orgCourses.fetched = false
          state.orgCourses.data = []
          state.orgCourses.currentPage = 0
          state.orgCourses.filters.organization = action.payload
          break
      }
    },
    setSubGroup: (state, action) => {
      switch (state.currentPage) {
        case "Badges":
          state.orgBadges.fetched = false
          state.orgBadges.data = []
          state.orgBadges.currentPage = 0
          state.orgBadges.filters.subGroup = action.payload
          break
        case "Learning Paths":
          state.orgLearningPaths.fetched = false
          state.orgLearningPaths.data = []
          state.orgLearningPaths.currentPage = 0
          state.orgLearningPaths.filters.subGroup = action.payload
          break
        case "Goals":
          state.orgGoals.fetched = false
          state.orgGoals.currentPage = 0
          state.orgGoals.data = []
          state.orgGoals.filters.subGroup = action.payload
          break
        case "Announcements":
          state.orgAnnouncements.fetched = false
          state.orgAnnouncements.data = []
          state.orgAnnouncements.currentPage = 0
          state.orgAnnouncements.filters.subGroup = action.payload
          break
        case "Mandated Training":
          state.mandatedTraining.fetched = false
          state.mandatedTraining.currentPage = 0
          state.mandatedTraining.data = []
          state.mandatedTraining.filters.subGroup = action.payload
          break

        default:
          state.orgCourses.fetched = false
          state.orgCourses.data = []
          state.orgCourses.currentPage = 0
          state.orgCourses.filters.subGroup = action.payload
          break
      }
    },
    setTemplateSource: (state, action) => {
      state.orgCourses.templates.filters.source = action.payload
    },
    setTemplateState: (state, action) => {
      state.orgCourses.templates.filters.state = action.payload
    },
    setStatus: (state, action) => {
      switch (state.currentPage) {
        case "Badges":
          state.orgBadges.fetched = false
          state.orgBadges.data = []
          state.orgBadges.currentPage = 0
          state.orgBadges.filters.status = action.payload
          break
        case "Rubrics":
          state.orgRubrics.fetched = false
          state.orgRubrics.data = []
          state.orgRubrics.currentPage = 0
          state.orgRubrics.filters.status = action.payload
          break
        case "Learning Paths":
          state.orgLearningPaths.fetched = false
          state.orgLearningPaths.data = []
          state.orgLearningPaths.currentPage = 0
          state.orgLearningPaths.filters.status = action.payload
          break
        case "Goals":
          state.orgGoals.fetched = false
          state.orgGoals.currentPage = 0
          state.orgGoals.data = []
          state.orgGoals.filters.status = action.payload
          break
        case "Announcements":
          state.orgAnnouncements.fetched = false
          state.orgAnnouncements.data = []
          state.orgAnnouncements.currentPage = 0
          state.orgAnnouncements.filters.status = action.payload
          break
        case "Mandated Training":
          state.mandatedTraining.fetched = false
          state.mandatedTraining.data = []
          state.mandatedTraining.currentPage = 0
          state.mandatedTraining.filters.status = action.payload
          break
        default:
          state.orgCourses.fetched = false
          state.orgCourses.data = []
          state.orgCourses.currentPage = 0
          state.orgCourses.filters.status = action.payload
          break
      }
    },
    setSortBy: (state, action) => {
      switch (state.currentPage) {
        case "Badges":
          state.orgBadges.fetched = false
          state.orgBadges.data = []
          state.orgBadges.currentPage = 0
          state.orgBadges.filters.sortBy = action.payload
          break
        case "Rubrics":
          state.orgRubrics.fetched = false
          state.orgRubrics.data = []
          state.orgRubrics.currentPage = 0
          state.orgRubrics.filters.sort_by = action.payload
          break
        case "Learning Paths":
          state.orgLearningPaths.fetched = false
          state.orgLearningPaths.data = []
          state.orgLearningPaths.currentPage = 0
          state.orgLearningPaths.filters.sortBy = action.payload
          break
        case "Goals":
          state.orgGoals.fetched = false
          state.orgGoals.currentPage = 0
          state.orgGoals.data = []
          state.orgGoals.filters.sortBy = action.payload
          break
        case "Announcements":
          state.orgAnnouncements.fetched = false
          state.orgAnnouncements.data = []
          state.orgAnnouncements.currentPage = 0
          state.orgAnnouncements.filters.sortBy = action.payload
          break
        case "Mandated Training":
          state.mandatedTraining.fetched = false
          state.mandatedTraining.data = []
          state.mandatedTraining.currentPage = 0
          state.mandatedTraining.filters.sortBy = action.payload
          break
        // Courses
        default:
          if (state.orgCourses.currentTab === "Templates") {
            state.orgCourses.templates.fetched = false
            state.orgCourses.templates.data = []
            state.orgCourses.templates.currentPage = 0
            state.orgCourses.templates.filters.sortBy = action.payload
          } else {
            state.orgCourses.fetched = false
            state.orgCourses.data = []
            state.orgCourses.currentPage = 0
            state.orgCourses.filters.sortBy = action.payload
          }
          break
      }
    },
    setSortOrder: (state) => {
      switch (state.currentPage) {
        case "Badges":
          state.orgBadges.fetched = false
          state.orgBadges.data = []
          state.orgBadges.currentPage = 0
          state.orgBadges.filters.sortOrder =
            state.orgBadges.filters.sortOrder === "DESC" ? "ASC" : "DESC"
          break
        case "Rubrics":
          state.orgRubrics.fetched = false
          state.orgRubrics.data = []
          state.orgRubrics.currentPage = 0
          state.orgRubrics.filters.sortOrder =
            state.orgRubrics.filters.sortOrder === "DESC" ? "ASC" : "DESC"
          break
        case "Learning Paths":
          state.orgLearningPaths.fetched = false
          state.orgLearningPaths.data = []
          state.orgLearningPaths.currentPage = 0
          state.orgLearningPaths.filters.sortOrder =
            state.orgLearningPaths.filters.sortOrder === "DESC" ? "ASC" : "DESC"
          break
        case "Goals":
          state.orgGoals.fetched = false
          state.orgGoals.currentPage = 0
          state.orgGoals.data = []
          state.orgGoals.filters.sortOrder =
            state.orgGoals.filters.sortOrder === "DESC" ? "ASC" : "DESC"
          break
        case "Announcements":
          state.orgAnnouncements.fetched = false
          state.orgAnnouncements.data = []
          state.orgAnnouncements.currentPage = 0
          state.orgAnnouncements.filters.sortOrder =
            state.orgAnnouncements.filters.sortOrder === "DESC" ? "ASC" : "DESC"
          break

        case "Mandated Training":
          state.mandatedTraining.fetched = false
          state.mandatedTraining.data = []
          state.mandatedTraining.currentPage = 0
          state.mandatedTraining.filters.sortOrder =
            state.mandatedTraining.filters.sortOrder === "DESC" ? "ASC" : "DESC"
          break

        // Courses
        default:
          if (state.orgCourses.currentTab === "Templates") {
            state.orgCourses.templates.fetched = false
            state.orgCourses.templates.data = []
            state.orgCourses.templates.currentPage = 0
            state.orgCourses.templates.filters.sortOrder =
              state.orgCourses.templates.filters.sortOrder === "DESC"
                ? "ASC"
                : "DESC"
          } else {
            state.orgCourses.fetched = false
            state.orgCourses.data = []
            state.orgCourses.currentPage = 0
            state.orgCourses.filters.sortOrder =
              state.orgCourses.filters.sortOrder === "DESC" ? "ASC" : "DESC"
          }
          break
      }
    },
    resetFilters: (state) => {
      switch (state.currentPage) {
        case "Badges":
          state.orgBadges.fetched = false
          state.orgBadges.data = []
          state.orgBadges.currentPage = 0

          state.orgBadges.filters.searchQuery = ""
          state.orgBadges.filters.status = "any"
          state.orgBadges.filters.category = null
          state.orgBadges.filters.organization = "any"
          state.orgBadges.filters.subGroup = "any"
          state.orgBadges.filters.jobTitle = "any"
          break
        case "Rubrics":
          state.orgRubrics.fetched = false
          state.orgRubrics.data = []
          state.orgRubrics.currentPage = 0

          state.orgRubrics.filters.searchQuery = ""
          state.orgRubrics.filters.status = "any"
          state.orgRubrics.filters.sort_by = "changed"

          break

        case "Learning Paths":
          state.orgLearningPaths.fetched = false
          state.orgLearningPaths.data = []
          state.orgLearningPaths.currentPage = 0

          state.orgLearningPaths.filters.searchQuery = ""
          state.orgLearningPaths.filters.status = "any"
          state.orgLearningPaths.filters.pathType = "any"
          state.orgLearningPaths.filters.organization = "any"
          state.orgLearningPaths.filters.subGroup = "any"
          state.orgLearningPaths.filters.jobTitle = "any"
          break

        case "Goals":
          state.orgGoals.fetched = false
          state.orgGoals.currentPage = 0
          state.orgGoals.data = []

          state.orgGoals.filters.searchQuery = ""
          state.orgGoals.filters.status = "any"
          state.orgGoals.filters.subGroup = "any"
          state.orgGoals.filters.jobTitle = "any"
          break

        case "Announcements":
          state.orgAnnouncements.fetched = false
          state.orgAnnouncements.data = []
          state.orgAnnouncements.currentPage = 0

          state.orgAnnouncements.filters.searchQuery = ""
          state.orgAnnouncements.filters.status = "any"
          state.orgAnnouncements.filters.announcementType = "any"
          state.orgAnnouncements.filters.organization = "any"
          state.orgAnnouncements.filters.subGroup = "any"
          state.orgAnnouncements.filters.jobTitle = "any"
          break

        case "Mandated Training":
          state.mandatedTraining.fetched = false
          state.mandatedTraining.data = []
          state.mandatedTraining.currentPage = 0

          state.mandatedTraining.filters.searchQuery = ""
          state.mandatedTraining.filters.status = "any"
          state.mandatedTraining.filters.announcementType = "any"
          state.mandatedTraining.filters.subGroup = "any"
          state.mandatedTraining.filters.jobTitle = "any"
          break

        // Courses
        default:
          if (state.orgCourses.currentTab === "Templates") {
            state.orgCourses.templates.fetched = false
            state.orgCourses.templates.data = []
            state.orgCourses.templates.currentPage = 0

            state.orgCourses.templates.filters.searchQuery = ""
            state.orgCourses.templates.filters.category = null
            state.orgCourses.templates.filters.source = "any"
            state.orgCourses.templates.filters.sortBy = "created"
            state.orgCourses.templates.filters.state = { name: "Any" }
          } else {
            state.orgCourses.fetched = false
            state.orgCourses.data = []
            state.orgCourses.currentPage = 0

            state.orgCourses.filters.searchQuery = ""
            state.orgCourses.filters.status = "any"
            state.orgCourses.filters.category = null
            state.orgCourses.filters.sortBy = "changed"
            state.orgCourses.filters.organization = "any"
            state.orgCourses.filters.subGroup = "any"
            state.orgCourses.filters.jobTitle = "any"
          }
          break
      }
    },
    setCurrentTab: (state, action) => {
      state[action.payload.page].currentTab = action.payload.tab
    },
  },
})

export const selectEntityList = (state) => {
  let data
  switch (state.entityList.currentPage) {
    case "Badges":
      data = state.entityList.orgBadges.data
      break
    case "Rubrics":
      data = state.entityList.orgRubrics.data
      break
    case "Learning Paths":
      data = state.entityList.orgLearningPaths.data
      break
    case "Goals":
      data = state.entityList.orgGoals.data
      break
    case "Announcements":
      data = state.entityList.orgAnnouncements.data
      break
    case "Mandated Training":
      data = state.entityList.mandatedTraining.data
      break
    // Courses
    default:
      data =
        state.entityList.orgCourses.currentTab === "Templates"
          ? state.entityList.orgCourses.templates.data
          : state.entityList.orgCourses.data
      break
  }
  return data
}

export const {
  handleCourseCheck,
  setSearchQuery,
  setSortOrder,
  setStatus,
  setSortBy,
  setCategory,
  setSubCategory,
  setTemplateSource,
  setCurrentPage,
  setPathType,
  increasePage,
  setAnnouncementType,
  setJobTitle,
  setTemplateState,
  setOrganization,
  setSubGroup,
  resetFilters,
  setCurrentTab,
  setRubricStatusFilter,
} = entityListSlice.actions

export default entityListSlice.reducer
