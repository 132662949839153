import DragHandleIcon from "@mui/icons-material/DragHandle"
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import classNames from "classnames"
import { FastFieldWithError } from "components/FastFieldWithError"
import { FieldArray, useField, useFormikContext } from "formik"
import { useContext, useMemo } from "react"
import { Draggable } from "react-beautiful-dnd"
import plusImg from "../../../img/plus.svg"
import trashImg from "../../../img/trash.svg"
import { CourseFormValues, QuizQuestion } from "../types"
import { QuizQuestionAnswerField } from "./QuizQuestionAnswerField"
import { GenieContext } from "./GenieContext"
import { GenieRewriteAllowedField } from "@mobilemind/genie-api/src/types"

type QuizQuestionFieldProps = {
  name: string
  questionIndex: number
  onDelete?: () => void
}
export const QuizQuestionField = (props: QuizQuestionFieldProps) => {
  const { name, questionIndex, onDelete } = props
  const { getGenieElementProps } = useContext(GenieContext)
  const [field] = useField<QuizQuestion>(name)
  const { setFieldValue } = useFormikContext<CourseFormValues>()
  const question = field.value

  const draggableId = question.id + "-" + questionIndex

  const hasMultipleCorrectAnswers = useMemo(() => {
    return question.answers.filter((answer) => answer.isCorrect).length > 1
  }, [question.answers])

  return (
    <div className="quiz-draggable">
      <Draggable draggableId={draggableId} index={questionIndex}>
        {(provided, snapshot) => (
          <Box
            className={classNames(snapshot.isDragging && "isDragging")}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            role="listitem"
            sx={{
              borderTop: "1px solid",
              borderColor: "grey.A200",
              padding: 5,
              marginInline: -5,
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                marginBottom: 3,
                backgroundColor: "grey.A200",
                borderRadius: 5,
                padding: 1,
              }}
            >
              {/* @todo use a rounded SVG icon */}
              <DragHandleIcon sx={{ marginRight: 2, color: "grey.500" }} />
              <strong style={{ flex: 1, textAlign: "center" }}>
                Question {questionIndex + 1}
              </strong>
            </Stack>

            <Stack sx={{ marginBottom: 5 }} direction="row" spacing={2}>
              <FastFieldWithError
                as={TextField}
                name={`${name}.value`}
                variant="standard"
                multiline
                size="medium"
                placeholder="Enter your question"
                sx={{ gridColumnStart: 2 }}
                {...getGenieElementProps(
                  GenieRewriteAllowedField.Question,
                  name
                )}
              />
            </Stack>

            <Typography variant="h4">Answers</Typography>
            <FieldArray
              name={`${name}.answers`}
              render={(arrayHelpers) => (
                <>
                  <div role="list">
                    {question.answers.map((answer, answerIndex) => {
                      return (
                        <QuizQuestionAnswerField
                          key={answerIndex}
                          name={`${name}.answers[${answerIndex}]`}
                          onDelete={
                            question.answers.length > 2
                              ? () => arrayHelpers.remove(answerIndex)
                              : undefined
                          }
                        />
                      )
                    })}
                  </div>
                  {hasMultipleCorrectAnswers && (
                    <RadioGroup
                      row
                      aria-label="position"
                      name={`${name}.requireAllAnswers`}
                      defaultValue="any"
                      value={question.requireAllAnswers ? "all" : "any"}
                      onChange={(event) => {
                        setFieldValue(
                          `${name}.requireAllAnswers`,
                          event.target.value === "all" ? true : false
                        )
                      }}
                    >
                      <FormControlLabel
                        value={"any"}
                        control={<Radio color="primary" />}
                        label="Allow any correct answer"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value={"all"}
                        control={<Radio color="primary" />}
                        label="Require all correct answers"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 4,
                    }}
                  >
                    <Button
                      onClick={() =>
                        arrayHelpers.push({
                          value: "",
                          isCorrect: false,
                        })
                      }
                      startIcon={<img alt="Add Answer" src={plusImg} />}
                      size="small"
                    >
                      Add Answer
                    </Button>
                    {onDelete && (
                      <Button
                        onClick={onDelete}
                        startIcon={<img alt="Remove Question" src={trashImg} />}
                        size="small"
                      >
                        Remove Question
                      </Button>
                    )}
                  </Box>
                </>
              )}
            />
          </Box>
        )}
      </Draggable>
    </div>
  )
}
