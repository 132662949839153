import { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Button,
  FormControl,
} from "@mui/material"
import CategoryIcon from "../../components/CategoryIcon"
import Loading from "@mobilemind/common/src/components/Loading"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import DeleteButton from "../../components/DeleteButton"

import CourseSelect from "../courses/CourseSelect"
import BadgeNavbarContainer from "./BadgeNavbarContainer"
import { addCourse } from "../createBadge/activeBadgeSlice"
import { convertEstimatedTime } from "@mobilemind/common/src/functions/index"
import { getSubGroups, getJobTitles } from "../../store/reducers/session"
import Autocomplete from "@mui/material/Autocomplete"
import classNames from "classnames"
import { isGroupLevel } from "../../functions"

import { saveAs } from "file-saver"

import {
  updateField,
  fetchActiveBadge,
  startNewBadge,
  removeCourse,
  dropCourse,
} from "./activeBadgeSlice"

import { useParams } from "react-router-dom"

import "../../styles/create.scss"
import BadgeStudio from "./BadgeStudio"
import ErrorBoundary from "../../components/ErrorBoundary"
import { useAppDispatch } from "store/hooks"

const mapStateToProps = ({ session, activeBadge, entityList, categories }) => {
  return {
    session,
    subGroups: session.subGroups,
    orgBadges: entityList.orgBadges,
    badge: activeBadge,
    categories,
  }
}

function CreateBadgeLayout(props) {
  const dispatch = useAppDispatch()
  const badgeId = useParams().id

  const [isStudioOpen, setIsStudioOpen] = useState(false)

  const { badge, categories, session } = props
  let { subGroups, jobTitles, orgUsers } = session

  useEffect(() => {
    if (badgeId !== "new") {
      dispatch(fetchActiveBadge(badgeId))
    } else {
      dispatch(startNewBadge())
    }
    if (!subGroups.fetched && session.group.uuid) {
      dispatch(getSubGroups(session.group.uuid[0].value))
    }
    if (!jobTitles.fetched) {
      dispatch(getJobTitles())
    }
  }, [
    badgeId,
    jobTitles.fetched,
    orgUsers.fetched,
    session.group.uuid,
    subGroups.fetched,
    dispatch,
  ])

  if (
    session.subgroups &&
    props.session.subgroups.data &&
    isGroupLevel(session)
  ) {
    subGroups = session.subgroups
  } else {
    subGroups = session.subGroups
  }

  // Single group member
  let isSingleGroupMember = isGroupLevel(session) && subGroups.data.length === 1

  let ownerValue
  if (isGroupLevel(session)) {
    ownerValue = badge.subGroupOwner
      ? badge.subGroupOwner
      : subGroups.data[0] && subGroups.data[0].id
  } else {
    ownerValue = badge.subGroupOwner ? badge.subGroupOwner : "org"
    let canFindGroup = subGroups.data.find(
      (group) => group.id === badge.subGroupOwner
    )

    if (!canFindGroup && ownerValue !== "org") {
      let byEntityId = subGroups.data.find(
        (group) => group.relationships.entity_id.data.id === badge.subGroupOwner
      )
      if (byEntityId) {
        ownerValue = byEntityId.id
      }
    }
  }

  function downloadBadge() {
    let src = badge.image.attributes
      ? process.env.REACT_APP_API_URL + badge.image.attributes.uri.url
      : badge.image
    let name = badge.name ? badge.name + ".png" : "New-Badge.png"
    saveAs(src, name)
  }

  if (!badge.fetched) {
    return <Loading fullPage={true} message={"Getting badge..."} />
  } else {
    return (
      <ErrorBoundary>
        <div className="page create badge">
          <BadgeStudio
            open={isStudioOpen}
            onClose={() => setIsStudioOpen(false)}
          />

          <BadgeNavbarContainer history={props.history} />
          <div className="flexRow">
            <div className="column left">
              <div
                className={classNames(
                  "required",
                  badge.missingFields.includes("Badge Name") && "active"
                )}
              >
                <TextField
                  variant="standard"
                  label="Badge Name"
                  required
                  value={badge.name}
                  inputProps={{ maxLength: 50 }}
                  helperText={"Maximum length is 50 characters."}
                  onChange={(event) =>
                    dispatch(
                      updateField({ field: "name", value: event.target.value })
                    )
                  }
                />
              </div>

              <div
                style={{ marginTop: 20 }}
                className={classNames(
                  "required",
                  badge.missingFields.includes("Image") && "active"
                )}
              >
                {badge.image ? (
                  <div
                    style={{
                      width: 300,
                      height: 300,
                      borderRadius: 300,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      alt={badge.name}
                      style={{ width: 300, height: 300 }}
                      src={
                        badge.image.attributes
                          ? process.env.REACT_APP_API_URL +
                            badge.image.attributes.uri.url
                          : badge.image
                      }
                    />
                  </div>
                ) : (
                  <Button
                    onClick={() => setIsStudioOpen(true)}
                    className="studioCircleContainer"
                  >
                    <div className="studioButtonContainer">
                      <Button
                        onClick={() => setIsStudioOpen(true)}
                        className="button primary"
                      >
                        <span className="icon" />
                        Badge Studio
                      </Button>
                    </div>
                  </Button>
                )}

                {badge.image && (
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="studioButtonContainer">
                      <Button
                        style={{ flex: 1, marginRight: 5 }}
                        onClick={() => setIsStudioOpen(true)}
                        className="button primary"
                      >
                        <span className="icon" />
                        {badge.image.attributes ? (
                          <>Create New</>
                        ) : (
                          <>Edit Badge</>
                        )}
                      </Button>
                    </div>

                    <div className="downloadLink">
                      <Button
                        style={{ flex: 1, margin: 0 }}
                        onClick={downloadBadge}
                        className="button primary active"
                      >
                        <span className="icon" />
                        Download
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              {!badge.field_draft && (
                <FormControlLabel
                  className="checkbox checkbox-archived"
                  control={
                    <Checkbox
                      checked={badge.archived}
                      onChange={(event) => {
                        dispatch(
                          updateField({
                            field: "archived",
                            value: event.target.checked,
                          })
                        )
                      }}
                      name="archived"
                    />
                  }
                  label="Archived"
                />
              )}

              {subGroups.data.length > 0 && (
                <>
                  <h3 style={{ marginTop: 25 }}>Badge Owner</h3>

                  <FormControl
                    variant="standard"
                    className={classNames(
                      "inputSelect subGroupSelect",
                      (isSingleGroupMember || !isGroupLevel(session)) &&
                        "locked"
                    )}
                  >
                    <Select
                      variant="standard"
                      labelId="label-subgroup-owner-select"
                      id="subgroup-owner-select"
                      value={ownerValue}
                      onChange={(event) =>
                        dispatch(
                          updateField({
                            field: "subGroupOwner",
                            value: event.target.value,
                          })
                        )
                      }
                    >
                      {props.session.orgRoles.includes(
                        "organization-admin"
                      ) && (
                        <MenuItem key={0} value={"org"}>
                          Org - {props.session.group.label[0].value}
                        </MenuItem>
                      )}
                      {subGroups.data.map((group) => {
                        return (
                          <MenuItem key={group.id} value={group.id}>
                            {group.attributes.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <p
                      style={{
                        fontSize: "0.75rem",
                        color: "rgba(0, 0, 0, 0.54)",
                        margin: "10px 0 0",
                        lineHeight: "1.4",
                      }}
                    >
                      {ownerValue === "org" ? (
                        <>
                          Organization Admins and Creators will be able to edit
                          this badge.
                        </>
                      ) : (
                        <>
                          Group Admins and Creators in{" "}
                          {isSingleGroupMember ? (
                            <>your group</>
                          ) : (
                            <>this group</>
                          )}{" "}
                          will be able to edit this badge. Organization Admins
                          and Creators will also be able to edit.
                        </>
                      )}
                    </p>
                  </FormControl>
                </>
              )}

              {subGroups.data.length > 0 && (
                <>
                  <div
                    className={classNames(
                      "required",
                      badge.missingFields.includes(
                        "Make visible to at least 1 group"
                      ) && "active"
                    )}
                  >
                    <h3 style={{ marginTop: 25 }}>Group Visibility</h3>
                    <FormControl
                      variant="standard"
                      className={classNames(
                        "subGroupSelect",
                        isSingleGroupMember && "locked"
                      )}
                    >
                      <Autocomplete
                        multiple
                        id="subgroup-select"
                        options={
                          subGroups.data.length > 0 ? subGroups.data : []
                        }
                        value={
                          isSingleGroupMember ? subGroups.data : badge.subGroups
                        }
                        getOptionLabel={(option) => option.attributes.label}
                        onChange={(event, values) =>
                          dispatch(
                            updateField({ field: "subGroups", value: values })
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              badge.subGroups.length
                                ? "Add Groups"
                                : "All Groups"
                            }
                            variant="standard"
                          />
                        )}
                      />
                      <p
                        style={{
                          fontSize: "0.75rem",
                          color: "rgba(0, 0, 0, 0.54)",
                          margin: "5px 0 0",
                          lineHeight: "1.4",
                        }}
                      >
                        Learners in{" "}
                        {isSingleGroupMember ? (
                          <>your group</>
                        ) : (
                          <>these groups</>
                        )}{" "}
                        will be able to view the badge in the Learn app.
                      </p>
                    </FormControl>
                  </div>
                </>
              )}

              {jobTitles.data.length > 0 && (
                <>
                  <h3 style={{ marginTop: 25 }}>Job Title Visibility</h3>

                  <FormControl
                    variant="standard"
                    className={classNames("inputSelect subGroupSelect")}
                  >
                    <Autocomplete
                      multiple
                      id="subgroup-select"
                      options={jobTitles.data.length > 0 ? jobTitles.data : []}
                      value={badge.jobTitles}
                      getOptionLabel={(option) => option.attributes.name}
                      onChange={(event, values) =>
                        dispatch(
                          updateField({ field: "jobTitles", value: values })
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={
                            badge.jobTitles.length
                              ? "Add Job Titles"
                              : "All Job Titles"
                          }
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </>
              )}

              {badge.estimatedTime > 0 && (
                <>
                  <h3>Estimated Time</h3>
                  <span className="icon time" />
                  <span className="count">
                    {convertEstimatedTime(badge.estimatedTime)}
                  </span>
                </>
              )}
              <div
                className={classNames(
                  "required",
                  badge.missingFields.includes("Include at least 1 course") &&
                    "active"
                )}
              >
                <h3 style={{ marginTop: 25 }}>Courses Required</h3>

                <DragDropContext
                  onDragEnd={(droppedItem) => {
                    dispatch(dropCourse(droppedItem))
                  }}
                >
                  <Droppable droppableId={"course-list"}>
                    {(provided) => (
                      <ul
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="courseList"
                      >
                        {badge &&
                          badge.courses.map((course, index) => {
                            let category
                            if (!course.category) {
                              category = categories.data.find(
                                (cat) =>
                                  course.relationships.field_category.data &&
                                  cat.id ===
                                    course.relationships.field_category.data.id
                              )
                            } else {
                              category = course.category
                            }
                            return (
                              <Draggable
                                key={course.id}
                                draggableId={course.id}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <span className="icon hamburger" />
                                    {category && (
                                      <CategoryIcon category={category} />
                                    )}
                                    <span className="name">
                                      {course.attributes.name}
                                    </span>
                                    <DeleteButton
                                      onClick={() =>
                                        dispatch(removeCourse(course))
                                      }
                                    />
                                  </li>
                                )}
                              </Draggable>
                            )
                          })}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            <div className="column right">
              <CourseSelect
                coursesAdded={badge.courses}
                addCourse={addCourse}
                removeCourse={removeCourse}
              />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(mapStateToProps)(CreateBadgeLayout)
