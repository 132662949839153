import { useEffect } from "react"
import { useAppDispatch } from "store/hooks"
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"

import Autocomplete from "@mui/material/Autocomplete"
import CategorySelect from "@mobilemind/common/src/components/CategorySelect"

import {
  setSearchQuery,
  setSortBy,
  setCategory,
  setTemplateSource,
  setTemplateState,
  resetFilters,
} from "../features/entityList/entityListSlice"

import "../styles/sidebar.scss"

const UsaStates = require("usa-states").UsaStates

function SidebarTemplates(props) {
  const { filters, categories, session } = props
  const { searchQuery, sortBy, category } = filters

  let fetchData = props.fetchData
  const dispatch = useAppDispatch()

  const usStates = new UsaStates()
  let allStates = usStates.states
  allStates.unshift({ name: "Any" })

  useEffect(() => {
    fetchData(filters, true)
  }, [filters, fetchData])

  return (
    <div id="sidebar" style={{ paddingTop: props.hasTabs && 65 }}>
      <form className="filters">
        <header>
          <strong>Filter Courses</strong>
          <Button
            className="button small"
            onClick={() => {
              dispatch(resetFilters())
            }}
          >
            Reset
          </Button>
        </header>

        <div className="flexRow inputSearch">
          <span className="icon search" />
          <TextField
            variant="standard"
            label="Search"
            value={searchQuery}
            onChange={(event) => dispatch(setSearchQuery(event.target.value))}
          />
        </div>

        {categories && (
          <CategorySelect
            label={"Category"}
            allowedLevels={3}
            currentValue={category}
            hideOrgContent={true}
            categories={categories}
            onChange={(newCategory) =>
              dispatch(setCategory({ value: newCategory }))
            }
          />
        )}

        <FormControl variant="standard" className="inputSelect source">
          <InputLabel id="label-source-select">Source</InputLabel>
          <Select
            variant="standard"
            labelId="label-source-select"
            id="source-select"
            value={filters.source}
            onChange={(event) =>
              dispatch(setTemplateSource(event.target.value))
            }
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={"community"}>Community</MenuItem>
            <MenuItem value={"mobilemind"}>MobileMind</MenuItem>
            <MenuItem value={session.group.id[0].value}>
              {session.group.label[0].value}
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="standard" className="inputSelect">
          <Autocomplete
            id="state-select"
            options={allStates}
            value={filters.state ? filters.state : "Any"}
            getOptionLabel={(state) => {
              if (state) {
                if (state.name) {
                  return state.name
                }
                return state
              }
              return "Any"
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                label="Contributor State"
              />
            )}
            onChange={(event, newValue) => dispatch(setTemplateState(newValue))}
          />
        </FormControl>

        <header style={{ marginTop: 10, marginBottom: 20 }}>
          <strong>Sort Courses</strong>
        </header>

        <FormControl variant="standard">
          <InputLabel style={{ backgroundColor: "white", padding: "0 5px" }}>
            Sort By
          </InputLabel>
          <Select
            variant="standard"
            value={sortBy}
            onChange={(event) => dispatch(setSortBy(event.target.value))}
          >
            <MenuItem value={"number_completed"}>Challenges Completed</MenuItem>
            <MenuItem value={"number_used"}>Orgs Using</MenuItem>
            <MenuItem value={"field_course_estimated_time"}>
              Estimated Time
            </MenuItem>
            <MenuItem value={"name"}>Course Name</MenuItem>
            <MenuItem value={"field_creator_org"}>
              Creator Organization
            </MenuItem>
            <MenuItem value={"created"}>Date Created</MenuItem>
          </Select>
        </FormControl>
      </form>
    </div>
  )
}

export default SidebarTemplates
