import Loading from "@mobilemind/common/src/components/Loading"
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { Component, useState } from "react"
import { connect } from "react-redux"
import GoalNavbarContainer from "./GoalNavbarContainer"

import { convertEstimatedTime } from "@mobilemind/common/src/functions/index"
import Autocomplete from "@mui/material/Autocomplete"
import classNames from "classnames"
import moment from "moment"
import { isGroupLevel } from "../../functions"
import { getJobTitles, getSubGroups } from "../../store/reducers/session"
import plusImg from "../../img/plus.svg"

import {
  fetchActiveGoal,
  setEmailSend,
  setLearningPath,
  startNewGoal,
  updateField,
  updateLPSearch,
} from "./activeGoalSlice"

import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import ErrorBoundary from "../../components/ErrorBoundary"
import "../../styles/create.scss"

import { withRouter } from "react-router-dom"
import { useAppDispatch } from "store/hooks"
import UserSelect from "features/userSelect/UserSelect"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import DeleteButton from "components/DeleteButton"

const mapStateToProps = ({ session, activeGoal }, ownProps) => {
  const isOrgLevel =
    session.orgRoles.includes("organization-admin") ||
    session.orgRoles.includes("organization-creator") ||
    session.orgRoles.includes("organization-drafter")

  return {
    isOrgLevel,
    goalId: Number(ownProps.match.params.id),
    session,
    subGroups: session.subGroups,
    goal: activeGoal,
  }
}

const mapDispatchToProps = {
  getSubGroups,
  getJobTitles,
  fetchActiveGoal,
  startNewGoal,
}

class CreateGoalLayout extends Component {
  componentDidMount() {
    const { session, goalId } = this.props
    let { subGroups, jobTitles } = session

    if (!window.location.href.includes("new")) {
      this.props.fetchActiveGoal(goalId)
    } else {
      this.props.startNewGoal()
    }

    if (!subGroups.fetched) {
      this.props.getSubGroups(session.group.uuid[0].value)
    }
    if (!jobTitles.fetched) {
      this.props.getJobTitles()
    }
  }

  render() {
    const { goal, session, subGroups, history, isOrgLevel } = this.props

    const props = {
      session,
      subGroups,
      goal,
      history,
      isOrgLevel,
    }

    if (goal.fetched || window.location.href.includes("new")) {
      return <CreateGoalInner {...props} />
    } else {
      return (
        <>
          {window.location.href.includes("new") ? (
            <Loading fullPage={true} message={"Starting new goal..."} />
          ) : (
            <Loading fullPage={true} message={"Getting goal..."} />
          )}
        </>
      )
    }
  }
}

function CreateGoalInner(props) {
  const dispatch = useAppDispatch()
  const { goal, session, isOrgLevel } = props
  let { subGroups, jobTitles } = session
  const { LPSearch, LPSearchResults } = goal

  const [isUserModalOpen, setIsUserModalOpen] = useState(false)

  let dailyPace, weeklyPace, hours, minutes

  if (goal.learningPath) {
    const today = new Date()
    const timeToComplete = Number(goal.learningPath.field_total_course_time)

    hours =
      timeToComplete >= 60
        ? Math.floor(timeToComplete / 60).toLocaleString()
        : 0
    minutes = timeToComplete % 60

    const deadline = moment(goal.deadline)
    const daysLeft = moment(deadline).diff(today, "days")
    const weeksLeft = moment(deadline).diff(today, "weeks")

    dailyPace = Math.ceil(timeToComplete / daysLeft)
    weeklyPace = weeksLeft
      ? Math.ceil(timeToComplete / weeksLeft)
      : timeToComplete
  }

  if (session.subgroups && session.subgroups.data && isGroupLevel(session)) {
    subGroups = session.subgroups
  } else {
    subGroups = session.subGroups
  }

  // Single group member
  let isSingleGroupMember = isGroupLevel(session) && subGroups.data.length === 1

  let ownerValue
  if (isGroupLevel(session)) {
    ownerValue = goal.subGroupOwner ? goal.subGroupOwner : subGroups.data[0].id
  } else {
    ownerValue = goal.subGroupOwner ? goal.subGroupOwner : "org"
    let canFindGroup = subGroups.data.find(
      (group) => group.id === goal.subGroupOwner
    )

    if (!canFindGroup && ownerValue !== "org") {
      let byEntityId = subGroups.data.find(
        (group) => group.relationships.entity_id.data.id === goal.subGroupOwner
      )
      if (byEntityId) {
        ownerValue = byEntityId.id
      }
    }
  }

  return (
    <ErrorBoundary>
      <div className="page create goal">
        <GoalNavbarContainer history={props.history} />

        <div className="flexRow">
          <div className="column left">
            <div className="goalCard">
              <header
                className={classNames(
                  "flexRow required",
                  goal.missingFields.includes("Goal Title") && "active"
                )}
              >
                <TextField
                  variant="standard"
                  label="Title"
                  required
                  value={goal.name}
                  inputProps={{ maxLength: 50 }}
                  helperText={"Maximum length is 50 characters."}
                  onChange={(event) =>
                    dispatch(
                      updateField({ field: "name", value: event.target.value })
                    )
                  }
                />
              </header>

              {goal.learningPath && (
                <>
                  <h3>Estimated Time to Complete</h3>
                  <TimeCapsule hours={hours} minutes={minutes} />

                  <h3>On Track Pace</h3>

                  <div>
                    <span style={{ marginLeft: 0 }} className="count">
                      {!goal.dailyPace ? (
                        <>{convertEstimatedTime(dailyPace)}</>
                      ) : (
                        <>{convertEstimatedTime(goal.dailyPace)}</>
                      )}
                    </span>
                    <span className="pace">/ day</span>
                  </div>
                  <div>
                    <span style={{ marginLeft: 0 }} className="count">
                      {!goal.weeklyPace ? (
                        <>{convertEstimatedTime(weeklyPace)}</>
                      ) : (
                        <>{convertEstimatedTime(goal.weeklyPace)}</>
                      )}
                    </span>
                    <span className="pace">/ week</span>
                  </div>
                </>
              )}
              <footer className="flexRow">
                <span className="icon goal" />

                <FormControl
                  variant="standard"
                  className={classNames("inputSelect learningPath")}
                >
                  <Autocomplete
                    id="learning-path-select"
                    disabled={goal.id && !goal.field_draft ? true : false}
                    loadingText={
                      LPSearch
                        ? "Getting learning paths..."
                        : "Start typing to search..."
                    }
                    options={LPSearchResults}
                    value={goal.learningPath ? goal.learningPath : LPSearch}
                    getOptionLabel={(path) =>
                      path.name ? path.name : LPSearch
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        value={LPSearch}
                        onChange={(event) =>
                          dispatch(updateLPSearch(event.target.value))
                        }
                        label="Learning Path to Complete"
                      />
                    )}
                    onChange={(event, newValue) => {
                      dispatch(setLearningPath(newValue))
                    }}
                  />
                </FormControl>
              </footer>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <FormControl
                variant="standard"
                className="datepicker-form-control"
                style={{ flex: 1, marginRight: 30 }}
              >
                <h3 style={{ marginBottom: 10 }}>Goal Deadline</h3>

                <DatePicker
                  value={moment(goal.deadline)}
                  disablePast={true}
                  onChange={(date) => {
                    dispatch(
                      updateField({
                        field: "deadline",
                        value: JSON.stringify(date),
                        learningPath: goal.learningPath,
                      })
                    )
                  }}
                />
              </FormControl>

              <FormControl
                variant="standard"
                style={{ flex: 1 }}
                className={classNames(
                  "required",
                  "datepicker-form-control",
                  goal.missingFields.includes(
                    "Expiration date must be on or after goal deadline"
                  ) && "active"
                )}
              >
                <h3 style={{ marginBottom: 10 }}>Expiration Date</h3>

                <DatePicker
                  value={moment(goal.expirationDate)}
                  disablePast={true}
                  onChange={(date) => {
                    dispatch(
                      updateField({
                        field: "expirationDate",
                        value: JSON.stringify(date),
                        learningPath: goal.learningPath,
                      })
                    )
                  }}
                />
              </FormControl>
            </div>
            <p
              style={{
                fontSize: "0.75rem",
                color: "rgba(0, 0, 0, 0.54)",
                margin: "20px 0 10px",
                lineHeight: "1.4",
              }}
            >
              After a goal's <strong>Expiration Date</strong>, the selected
              Learning Path will revert to normal and learners will no longer be
              able to complete this goal.
            </p>
          </div>

          <div className="column right">
            {subGroups.data.length > 0 && (
              <>
                <h3>Goal Owner</h3>

                <FormControl
                  variant="standard"
                  className={classNames(
                    "inputSelect subGroupSelect",
                    (isSingleGroupMember || !isGroupLevel(session)) && "locked"
                  )}
                >
                  <Select
                    variant="standard"
                    labelId="label-subgroup-owner-select"
                    id="subgroup-owner-select"
                    value={ownerValue}
                    onChange={(event) =>
                      dispatch(
                        updateField({
                          field: "subGroupOwner",
                          value: event.target.value,
                        })
                      )
                    }
                  >
                    {props.session.orgRoles.includes("organization-admin") && (
                      <MenuItem key={0} value={"org"}>
                        Org - {props.session.group.label[0].value}
                      </MenuItem>
                    )}
                    {subGroups.data.map((group) => {
                      return (
                        <MenuItem key={group.id} value={group.id}>
                          {group.attributes.label}
                        </MenuItem>
                      )
                    })}
                  </Select>

                  <p
                    style={{
                      fontSize: "0.75rem",
                      color: "rgba(0, 0, 0, 0.54)",
                      margin: "10px 0 0",
                      lineHeight: "1.4",
                    }}
                  >
                    {ownerValue === "org" ? (
                      <>
                        Organization Admins and Creators will be able to edit
                        this goal.
                      </>
                    ) : (
                      <>
                        Group Admins and Creators in{" "}
                        {isSingleGroupMember ? (
                          <>your group</>
                        ) : (
                          <>this group</>
                        )}{" "}
                        will be able to edit this goal. Organization Admins and
                        Creators will also be able to edit.
                      </>
                    )}
                  </p>
                </FormControl>
              </>
            )}

            <h3>Targeted Learners</h3>

            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="any"
                value={goal.targetAudience}
                onChange={(event) => {
                  dispatch(
                    updateField({
                      field: "targetAudience",
                      value: event.target.value,
                    })
                  )
                }}
              >
                <Box sx={{ flex: 1, flexDirection: "column", display: "flex" }}>
                  {isOrgLevel && (
                    <FormControlLabel
                      value="all-org"
                      control={<Radio color="primary" />}
                      label={
                        "All " + session.group.label[0].value + " Learners"
                      }
                      labelPlacement="end"
                    />
                  )}
                  <FormControlLabel
                    value="groups-job-titles"
                    control={<Radio color="primary" />}
                    label="Groups / Job Titles"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="learners"
                    control={<Radio color="primary" />}
                    label="Individual Learners"
                    labelPlacement="end"
                  />
                </Box>
              </RadioGroup>
            </FormControl>

            {goal.targetAudience === "groups-job-titles" && (
              <div>
                <div
                  className={classNames(
                    "required goal",
                    goal.missingFields.includes(
                      "Make visible to at least 1 group"
                    ) && "active"
                  )}
                >
                  <FormControl
                    variant="standard"
                    className={classNames(
                      "subGroupSelect",
                      isSingleGroupMember && "locked"
                    )}
                  >
                    <Autocomplete
                      multiple
                      id="subgroup-select"
                      options={subGroups.data.length > 0 ? subGroups.data : []}
                      value={
                        isSingleGroupMember ? subGroups.data : goal.subGroups
                      }
                      getOptionLabel={(option) => option.attributes.label}
                      onChange={(event, values) =>
                        dispatch(
                          updateField({ field: "subGroups", value: values })
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={
                            goal.subGroups && goal.subGroups.length
                              ? "Add Groups"
                              : "All Groups"
                          }
                          variant="standard"
                        />
                      )}
                    />
                    <p
                      style={{
                        fontSize: "0.75rem",
                        color: "rgba(0, 0, 0, 0.54)",
                        margin: "-10px 0 10px",
                        lineHeight: "1.4",
                      }}
                    >
                      Learners in{" "}
                      {isSingleGroupMember ? (
                        <>your group</>
                      ) : (
                        <>these groups</>
                      )}{" "}
                      will be assigned this goal in the Learn app.
                    </p>
                  </FormControl>
                </div>
                <FormControl
                  variant="standard"
                  className={classNames("inputSelect subGroupSelect")}
                >
                  <Autocomplete
                    multiple
                    id="subgroup-select"
                    options={
                      jobTitles && jobTitles.data.length > 0
                        ? jobTitles.data
                        : []
                    }
                    value={goal.jobTitles}
                    getOptionLabel={(option) =>
                      option.attributes.name ? option.attributes.name : " "
                    }
                    onChange={(event, values) =>
                      dispatch(
                        updateField({ field: "jobTitles", value: values })
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          goal.jobTitles && goal.jobTitles.length
                            ? "Add Job Titles"
                            : "All Job Titles"
                        }
                        variant="standard"
                      />
                    )}
                  />
                  <p
                    style={{
                      fontSize: "0.75rem",
                      color: "rgba(0, 0, 0, 0.54)",
                      margin: "-10px 0 10px",
                      lineHeight: "1.4",
                    }}
                  >
                    Learners with{" "}
                    {isSingleGroupMember ? (
                      <>this job title</>
                    ) : (
                      <>these job titles</>
                    )}{" "}
                    will be able assigned this goal in the Learn app.
                  </p>
                </FormControl>
              </div>
            )}

            {goal.targetAudience === "learners" && (
              <div>
                {goal.users.map((user) => {
                  return (
                    <div
                      key={user.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {user.user_picture?.includes("default_temp") ||
                          !user.user_picture ? (
                            <UserInitial
                              containerStyle={{ marginRight: 10 }}
                              fontSize={14}
                              size={35}
                              initials={
                                user.field_first_name
                                  .substring(0, 1)
                                  .toUpperCase() +
                                user.field_last_name
                                  .substring(0, 1)
                                  .toUpperCase()
                              }
                            />
                          ) : (
                            <span
                              className="userImage"
                              style={{
                                height: 29,
                                width: 29,
                                border: "3px solid white",
                                marginLeft: 1,
                                backgroundImage:
                                  "url(" +
                                  process.env.REACT_APP_API_URL +
                                  user.user_picture +
                                  ")",
                              }}
                            />
                          )}
                        </div>
                        <strong style={{ fontSize: 14, marginLeft: 2 }}>
                          {user.field_first_name} {user.field_last_name}
                        </strong>
                      </div>
                      <DeleteButton
                        onClick={() => {
                          dispatch(
                            updateField({
                              field: "users",
                              value: user,
                              type: "remove",
                            })
                          )
                        }}
                      />
                    </div>
                  )
                })}

                <Button
                  size="small"
                  onClick={() => setIsUserModalOpen(true)}
                  startIcon={<img alt={"Add Learners"} src={plusImg} />}
                >
                  Select Learners
                </Button>
              </div>
            )}

            <UserSelect
              open={isUserModalOpen}
              initialTab={"users"}
              userLabel={"Learners"}
              entity={goal}
              selectedUsers={goal.users}
              update={(args) => () => {
                const { value, type } = args
                dispatch(updateField({ field: "users", value, type }))
              }}
              onClose={() => setIsUserModalOpen(false)}
              visibleTabs={"users"}
            />

            <h3 style={{ marginBottom: 10 }}>Send Reminders</h3>

            <FormControl variant="standard">
              <Select
                variant="standard"
                id="event-type-select"
                multiple
                value={goal.goalReminders}
                onChange={(event) =>
                  dispatch(
                    updateField({
                      field: "goalReminders",
                      value: event.target.value,
                    })
                  )
                }
              >
                <MenuItem className="multiSelect" value={"none"}>
                  No Reminders
                </MenuItem>
                <MenuItem className="multiSelect" value={"1_day"}>
                  1 Day Before
                </MenuItem>
                <MenuItem className="multiSelect" value={"1_week"}>
                  1 Week Before
                </MenuItem>
                <MenuItem className="multiSelect" value={"1_month"}>
                  1 Month Before
                </MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  checked={goal.isEmailSend}
                  onChange={(event) => {
                    dispatch(setEmailSend(event.target.checked))
                  }}
                />
              }
              label={"Send Email to Learners"}
            />
            <p className="helperText">
              Notify learners they have a new goal and how long they have to
              complete it.
            </p>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateGoalLayout)
)
