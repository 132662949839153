import CategorySelect from "@mobilemind/common/src/components/CategorySelect"
import { SkillLevelButtonGroup } from "@mobilemind/common/src/components/SkillLevelButtonGroup"
import TagSelect from "@mobilemind/common/src/components/TagSelect"
import { GenieRewriteAllowedField } from "@mobilemind/genie-api/src/types"
import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material"
import { createNextState } from "@reduxjs/toolkit"
import { FastFieldWithError } from "components/FastFieldWithError"
import { useFormikContext } from "formik"
import { has } from "lodash"
import { FocusEvent, KeyboardEvent, useContext } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useAppSelector } from "store/hooks"
import CourseTooltip from "../../../components/CourseTooltip"
import { formats, modules } from "../../../constants/reactQuillConfig"
import { ReactComponent as TimeIcon } from "../../../img/time.svg"
import "../../../styles/create.scss"
import { CourseFormValues } from "../types"
import { CourseFormBody } from "./CourseFormBody"
import { CourseFormButtons } from "./CourseFormButtons"
import { CourseFormStep } from "./CourseFormStepper"
import { CourseVideoContainer } from "./CourseVideoContainer"
import { GenieContext } from "./GenieContext"
import { NextButton } from "./NextButton"

type CourseFormOverviewProps = {
  type?: "new" | "edit" | "template"
}

export const CourseFormOverview = (props: CourseFormOverviewProps) => {
  const categories = useAppSelector((state) => state.categories)
  const tags = useAppSelector((state) => state.tags)

  const { type } = props
  const { errors, setFieldValue, setValues, values, submitCount } =
    useFormikContext<CourseFormValues>()

  const { getGenieElementProps } = useContext(GenieContext)

  return (
    <CourseFormBody>
      <FastFieldWithError
        as={TextField}
        name="name"
        variant="standard"
        required
        label="Course Title"
        margin="normal"
        inputProps={{ maxLength: 50 }}
        helperText={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span>Maximum length is 50 characters.</span>
            {type !== "template" && <CourseTooltip />}
          </Box>
        }
        // Prevent validateDOMNesting warning
        FormHelperTextProps={{ component: "span" }}
        size="large"
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr",
          justifyContent: "flex-start",
          gap: 4,
          marginBottom: 5,
        }}
      >
        <CategorySelect
          label="Category"
          categories={categories}
          allowedLevels={3}
          hideOrgContent={false}
          currentValue={values.field_category ?? null}
          onChange={(category) => {
            setFieldValue("field_category", category)
          }}
          margin="none"
          required={true}
          error={submitCount > 0 && has(errors, "field_category")}
        />
        <FormControl sx={{ maxWidth: 110 }}>
          <Typography variant="caption">Skill Level</Typography>
          <SkillLevelButtonGroup
            value={values.level}
            onChange={(event, value) => setFieldValue("level", value)}
            sx={{ marginTop: -1 }}
          />
        </FormControl>
        <FastFieldWithError
          as={TextField}
          name="estimatedTime"
          label="Estimated Time"
          placeholder="0"
          variant="standard"
          type="number"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: <TimeIcon width={20} height={20} />,
            endAdornment: <Typography>minutes</Typography>,
            sx: { gap: 1, width: "max-content" },
          }}
          inputProps={{
            min: 0,
            max: 1000,
            sx: {
              width: "6ch",
            },
          }}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            // Reject `+`, `-`, and `.` characters
            if (event.key.match(/[+-.]/)) {
              event.preventDefault()
            }
          }}
          onFocus={(event: FocusEvent<HTMLInputElement>) =>
            event.target.select()
          }
        />
      </Box>
      <FastFieldWithError
        as={TextField}
        name="objective"
        variant="standard"
        multiline
        label="Learn"
        margin="normal"
        required
        helperText={
          "A brief description of what the learner will have learned after completing this course. This text appears on the course card throughout the app."
        }
        {...getGenieElementProps(GenieRewriteAllowedField.Learn, "objective")}
      />
      <FastFieldWithError
        as={TextField}
        name="content"
        variant="standard"
        multiline
        label="Apply"
        margin="normal"
        required
        helperText={
          "Explain what the learner will actually be doing in this course."
        }
        {...getGenieElementProps(GenieRewriteAllowedField.Apply, "content")}
      />
      <FastFieldWithError
        as={TextField}
        name="field_video_link"
        variant="standard"
        label="Video"
        margin="normal"
        helperText={
          "Enter the URL for a video to include. YouTube, Vimeo, Google Drive, and ScreenPal videos are currently supported. Note: Copy and paste the URL to view the video, do not use a share button or embed code."
        }
      />
      {values.field_video_link && (
        <FormControl variant="standard" margin="normal">
          <CourseVideoContainer videoLink={values.field_video_link} />
        </FormControl>
      )}
      <InputLabel id="label-resources">Additional Resources</InputLabel>
      <FormControl variant="standard" margin="normal">
        <ReactQuill
          theme={"snow"}
          onChange={(value) => setFieldValue("resources", value)}
          value={values.resources ? values.resources : ""}
          modules={modules}
          formats={formats}
          placeholder={
            "List any links to helpful or supplemental resources to assist the learner in completing this course."
          }
        />
      </FormControl>
      {!values.isTemplate && (
        <FormControl margin="normal">
          <Typography>Tags</Typography>
          <TagSelect
            allowNewTags={true}
            tags={tags}
            addTag={(tag) =>
              setValues(
                createNextState((draft) => {
                  draft.tags.push(tag)
                })
              )
            }
            deleteTag={(index) =>
              setValues(
                createNextState((draft) => {
                  draft.tags.splice(index, 1)
                })
              )
            }
            selectedTags={values.tags}
          />
        </FormControl>
      )}

      <CourseFormButtons>
        <NextButton step={CourseFormStep.Challenge} />
      </CourseFormButtons>
    </CourseFormBody>
  )
}
