import { createSlice } from "@reduxjs/toolkit"

type InitialState = {
  fetched: boolean
  data: any[]
}

const initialState: InitialState = {
  fetched: false,
  data: [],
}

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    getTags: (state, action) => {
      state.fetched = true
      // eslint-disable-next-line
      action.payload.forEach((tag: any) => {
        tag.attributes.name = tag.attributes.name.toLowerCase()
      })
      state.data = action.payload
    },
    addTag: (state, action) => {
      state.data.push(action.payload)
    },
  },
})

export default tagsSlice.reducer
