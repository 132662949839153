import { OrgRubricCriteria } from "@mobilemind/common/src/types/rubrics"
import { Box, Button, Stack, TextField } from "@mui/material"
import { FastField, FieldArray, useField } from "formik"
import plusImg from "../../../img/plus.svg"
import { criteriaSchema } from "../EditRubricForm"
import { RubricCriteriaHeader } from "./RubricCriteriaHeader"
import { RubricCriteriaLevelsContainer } from "./RubricCriteriaLevelsContainer"

type RubricCriteriaContainerProps = {
  name: string
  index: number
}

export function RubricCriteriaContainer({
  name,
}: RubricCriteriaContainerProps) {
  const [field] = useField<OrgRubricCriteria[]>(name)

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <Box>
          {field.value.map((criteria, criteriaIndex) => {
            return (
              <Box key={`${name}.${criteriaIndex}`}>
                <RubricCriteriaHeader
                  name={`${name}.${criteriaIndex}`}
                  index={criteriaIndex}
                  onRemove={() => {
                    arrayHelpers.remove(criteriaIndex)
                  }}
                />
                <Stack spacing={4} sx={{ padding: 4 }}>
                  <FastField
                    as={TextField}
                    name={`${name}.${criteriaIndex}.description`}
                    variant="standard"
                    multiline
                    label="Criteria Description"
                    margin="normal"
                  />
                  <RubricCriteriaLevelsContainer
                    name={`${name}.${criteriaIndex}.criteria_levels`}
                    index={criteriaIndex}
                  />
                </Stack>
              </Box>
            )
          })}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: 3,
            }}
          >
            <Button
              size="small"
              startIcon={<img src={plusImg} alt="Add Criteria" />}
              onClick={() => {
                arrayHelpers.push(criteriaSchema.getDefault())
              }}
            >
              Add Criteria
            </Button>
          </Box>
        </Box>
      )}
    />
  )
}
