import { useState } from "react"

import { Button, Popover } from "@mui/material"

import SanitizeHTML from "@mobilemind/common/src/components/SanitizedHTML"

export function ChallengeContent(props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const { user } = props

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "challenge-popover" : undefined

  let userSubmission, imageSubmission
  if (user.field_text_submission) {
    userSubmission = user.field_text_submission
  } else if (user.field_multiple_choice_submission) {
    userSubmission = user.field_multiple_choice_submission.substring(
      user.field_multiple_choice_submission.indexOf("=") + 1
    )
  } else if (user.field_document_url) {
    userSubmission = user.field_document_url
  } else if (user.field_image_submission) {
    imageSubmission =
      process.env.REACT_APP_API_URL + user.field_image_submission
  } else if (user.field_checkbox_options) {
    userSubmission = user.field_checkbox_options
  }

  return (
    <div style={{ marginLeft: 20 }} className="submissionButton">
      {props.user.field_status === "Completed" &&
        user.field_submission_type !== "Quiz" && (
          <Button
            aria-describedby={id}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            <span className="icon feedback" />
          </Button>
        )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={"challengePopover"}
        style={{ marginTop: -13, overflow: "visible" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="inner">
          <header
            style={{ textTransform: "capitalize" }}
            className="answerHeader"
          >
            {user.field_first_name.toLowerCase()}'s Answer:
          </header>
          {userSubmission && <SanitizeHTML html={userSubmission} />}
          {imageSubmission && (
            <a
              className="submissionLink"
              target="_blank"
              rel="noreferrer"
              href={imageSubmission}
            >
              <img
                alt={"Submission"}
                className="submissionImage"
                src={imageSubmission}
              />
            </a>
          )}
        </div>
      </Popover>
    </div>
  )
}
