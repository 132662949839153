import { IconButton, Tooltip, styled } from "@mui/material"
import classNames from "classnames"
import { MouseEvent } from "react"
import { ReactComponent as StarIcon } from "../img/star-black.svg"
import { ReactComponent as StarOutline } from "../img/star-black-outline.svg"
import { SkillLevel } from "../types/course"

type SkillLevelButtonGroupProps = {
  centered?: boolean
  className?: string
  value: SkillLevel
  onChange?: (event: MouseEvent<HTMLButtonElement>, value: SkillLevel) => void
}

export const SkillLevelButtonGroup = styled(
  (props: SkillLevelButtonGroupProps) => {
    const { className, value, onChange } = props
    const options = Object.values(SkillLevel)
    const selectedIndex = options.indexOf(value)

    return (
      // @todo could use ButtonGroup component
      <div className={className}>
        {options.map((option, index) => (
          <IconButton
            key={option}
            onClick={(event) => onChange?.(event, option)}
            disabled={!onChange}
            className={classNames(index <= selectedIndex && "Mui-selected")}
            edge={
              index === 0
                ? "start"
                : index === options.length - 1
                ? "end"
                : false
            }
          >
            <Tooltip
              title={option}
              slotProps={{
                tooltip: {
                  sx: (theme) => ({
                    fontWeight: "bold",
                    padding: `${theme.spacing(1, 2)}!important`,
                    backgroundColor: theme.palette.grey.A200,
                    boxShadow: theme.shadows[2],
                  }),
                },
              }}
            >
              {index <= selectedIndex ? (
                <StarIcon width={20} height={20} />
              ) : (
                <StarOutline width={20} height={20} />
              )}
            </Tooltip>
          </IconButton>
        ))}
      </div>
    )
  }
)(({ centered }) => {
  return {
    display: "flex",
    ...(centered && { justifyContent: "center" }),
    "& .MuiIconButton-root": {
      "&.Mui-disabled": {
        pointerEvents: "unset",
      },
      "&.MuiIconButton-edgeStart": {
        marginLeft: -10,
      },
      "&.MuiIconButton-edgeEnd": {
        marginRight: -10,
      },
    },
  }
})
