import { Button } from "@mui/material"
import classNames from "classnames"
import theme from "@mobilemind/common/src/theme/theme"

// @deprecated Use <Button size="small"> instead
function ButtonSmall(props) {
  return (
    <Button
      onClick={props.onClick}
      style={{ color: theme.palette.primary.main }}
      className={classNames("small button", props.classes)}
    >
      {props.children}
    </Button>
  )
}

export default ButtonSmall
