import { generateVideoEmbedURL } from "@mobilemind/common/src/functions"
import { Box } from "@mui/material"

/**
 * Renders a container for displaying a course video.
 * If a video link is provided, it generates an embed URL and displays the video using an iframe.
 * The container is responsive and adjusts its size to maintain a 16:9 aspect ratio.
 */
type VideoContainerProps = {
  videoLink?: string
}
export const CourseVideoContainer = ({ videoLink }: VideoContainerProps) => {
  if (videoLink) {
    return (
      <Box
        /**
         * All Box and iframe styles are needed to allow full width + responsive embed (without more CSS)
         */
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* ensures 16:9 */,
          paddingTop: 25,
          height: 0,
        }}
        sx={{ borderRadius: 1, overflow: "hidden" }}
        margin="normal"
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="Course Video"
          id="course-video-player"
          src={generateVideoEmbedURL(videoLink)}
          frameBorder="0"
        />
      </Box>
    )
  }
  return null
}
