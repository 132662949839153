import { Component } from "react"
import { connect } from "react-redux"
import PlacesAutocomplete from "react-places-autocomplete"
import { geocodeByAddress } from "react-places-autocomplete"
import { updateEditingLocation } from "../../locations/locationsSlice"
import { Input, FormControl } from "@mui/material"
import { addressToString } from "@mobilemind/common/src/functions"

const mapDispatchToProps = {
  updateEditingLocation,
}

class LocationSearchInput extends Component {
  state = {
    address: "",
  }

  componentDidMount() {
    if (this.props.editing) {
      this.setState({
        address: addressToString(
          this.props.locations.editingLocation.attributes.field_address
        ),
      })
    }
  }

  handleChange = (address) => {
    this.setState({ address })
  }

  handleSelect = (address, placeId, suggestion) => {
    this.setState({ address })

    geocodeByAddress(address).then((results) => {
      if (!this.props.editing) {
        this.props.setNewLocation({
          name: suggestion.formattedSuggestion.mainText,
          components: results[0].address_components,
        })
      } else {
        this.props.updateEditingLocation({
          field: "name",
          value: suggestion.formattedSuggestion.mainText,
        })
        this.props.updateEditingLocation({
          field: "address",
          components: results[0].address_components,
        })
      }
    })
  }

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <>
            <div className="flexRow locationSelectWrapper">
              <span className="icon location-color" />
              <FormControl variant="standard">
                <Input
                  multiline
                  {...getInputProps({
                    placeholder: "Search for location",
                    className: "locationSearch",
                  })}
                />
              </FormControl>
            </div>
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item"
                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, { className })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    )
  }
}

export default connect(null, mapDispatchToProps)(LocationSearchInput)
