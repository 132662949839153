import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"

export const fetchActiveBadge = createAsyncThunk(
  "activeBadgeSlice/fetchActiveBadge",
  async (badgeId, thunkAPI) => {
    let query = {
      filter: {
        drupal_internal__id: badgeId,
      },
      include:
        "field_badge_image,field_course,field_subgroup,field_job_title,field_teacher",
    }

    let response = await fetchWrapper.get(
      "/api/badges_entity/badges_entity?" + qs.stringify(query)
    )
    if (response.ok) {
      let data = await response.json()
      data.orgId = thunkAPI.getState().session.group.uuid[0].value
      data.isSiteAdmin = thunkAPI.getState().session.isSiteAdmin

      data.subGroups =
        data.included &&
        data.included.filter((included) => included.type === "group--group")
      let subGroupIds =
        data.data[0].relationships.field_subgroup.data &&
        data.data[0].relationships.field_subgroup.data.map((group) => group.id)

      if (!data.subGroups || !data.subGroups.length) {
        let groupLevelSubGroups = thunkAPI.getState().session.subgroups
        data.subGroups =
          groupLevelSubGroups &&
          groupLevelSubGroups.data &&
          groupLevelSubGroups.data.filter((group) =>
            subGroupIds.includes(group.id)
          )
      }

      data.jobTitles =
        data.included &&
        data.included.filter(
          (included) => included.type === "taxonomy_term--job_titles"
        )
      data.users =
        data.included &&
        data.included.filter((included) => included.type === "user--user")

      return data
    }
  }
)

export const activeBadgeSlice = createSlice({
  name: "activeBadgeSlice",
  initialState: {
    unauthorized: false,
    fetched: false,
    name: "",
    courses: [],
    image: null,
    archived: false,
    field_draft: true,
    estimatedTime: 0,
    subGroups: [],
    jobTitles: [],
    users: [],
    originalCourses: [],
    originalJobTitles: [],
    originalSubGroups: [],
    subGroupOwner: null,
    missingFields: [],
  },
  reducers: {
    startNewBadge: (state) => {
      state.name = ""
      state.image = null
      state.courses = []
      state.archived = false
      state.id = null
      state.fetched = true
      state.field_draft = true
      state.estimatedTime = 0
      state.unauthorized = false
      state.subGroups = []
      state.jobTitles = []
      state.originalCourses = []
      state.originalJobTitles = []
      state.originalSubGroups = []
      state.missingFields = []
      state.subGroupOwner = null
    },
    updateField: (state, action) => {
      // Prevent duplicates due to AutoComplete weirdness
      if (
        action.payload.field === "jobTitles" ||
        action.payload.field === "users"
      ) {
        state[action.payload.field] = action.payload.value.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        )
      } else if (action.payload.field === "subGroups") {
        state[action.payload.field] = action.payload.value.filter(
          (v, i, a) =>
            a.findIndex((t) => t.attributes.label === v.attributes.label) === i
        )
      } else {
        state[action.payload.field] = action.payload.value
      }
    },
    addCourse: (state, action) => {
      state.courses.push(action.payload)
      state.estimatedTime = state.courses
        .map((course) => course.attributes.field_course_estimated_time)
        .reduce((a, b) => a + b, 0)
    },
    removeCourse: (state, action) => {
      state.courses = state.courses.filter(
        (course) => course.id !== action.payload.id
      )
      state.estimatedTime = state.courses
        .map((course) => course.attributes.field_course_estimated_time)
        .reduce((a, b) => a + b, 0)
    },
    setImage: (state, action) => {
      if (action.payload.length) {
        state.image = action.payload
      }
    },
    removeImage: (state) => {
      state.image = null
    },
    setFilename: (state, action) => {
      state.filename = action.payload
    },
    dropCourse: (state, action) => {
      let prevIndex = action.payload.source.index
      let targetIndex =
        action.payload.destination && action.payload.destination.index

      if (targetIndex && targetIndex >= state.courses.length) {
        let course = targetIndex - state.courses.length + 1
        while (course--) {
          state.courses.push(undefined)
        }
      }

      state.courses.splice(
        targetIndex,
        0,
        state.courses.splice(prevIndex, 1)[0]
      )
    },
  },
  extraReducers: {
    [fetchActiveBadge.pending]: (state) => {
      state.fetched = false
    },
    [fetchActiveBadge.fulfilled]: (state, action) => {
      const courses =
        action.payload.included &&
        action.payload.included.filter(
          (included) => included.type === "course_entity--course_entity"
        )
      const orgId = action.payload.orgId
      const badge = action.payload.data[0]

      if (
        action.payload.isSiteAdmin ||
        (badge &&
          badge.relationships.field_group.data &&
          orgId === badge.relationships.field_group.data.id) ||
        (badge.relationships.field_partner.data &&
          orgId === badge.relationships.field_partner.data.id)
      ) {
        state.subGroupOwner =
          action.payload.data[0].relationships.field_subgroup_owner.data &&
          action.payload.data[0].relationships.field_subgroup_owner.data.id

        state.unauthorized = false
        state.courses = courses ? courses : []
        state.originalCourses = courses ? courses : []
        state.originalJobTitles = action.payload.jobTitles
          ? action.payload.jobTitles
          : []
        state.originalSubGroups = action.payload.subGroups
          ? action.payload.subGroups
          : []
        state.estimatedTime = courses
          ? courses
              .map((course) => course.attributes.field_course_estimated_time)
              .reduce((a, b) => a + b, 0)
          : 0
        state.name = action.payload.data[0].attributes.name
        state.image =
          action.payload.included &&
          action.payload.included.find(
            (included) => included.type === "file--image"
          )
        state.archived = action.payload.data[0].attributes.field_archive
        state.id = action.payload.data[0].id
        state.field_draft = action.payload.data[0].attributes.field_draft
        state.subGroups = action.payload.subGroups
          ? action.payload.subGroups
          : []
        state.jobTitles = action.payload.jobTitles
          ? action.payload.jobTitles
          : []
        state.users = action.payload.users ? action.payload.users : []

        state.fetched = true
      } else {
        state.unauthorized = true
      }
    },
  },
})

export const {
  updateField,
  setFilename,
  addCourse,
  setImage,
  removeImage,
  startNewBadge,
  removeCourse,
  dropCourse,
} = activeBadgeSlice.actions
export default activeBadgeSlice.reducer
