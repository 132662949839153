import { GenieCourseFeedbackParameters } from "@mobilemind/genie-api/src/types"
import { Box, Button, Stack, TextField, alpha, styled } from "@mui/material"
import { GenieMessage } from "features/genie/components/GenieMessage"
import { FastField, Formik } from "formik"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { ReactComponent as ThumbsUp } from "../../../img/thumbs-up.svg"
import { saveFeedback } from "../store/courseGenieSlice"

const ThumbButton = styled(Button)(() => ({
  backgroundColor: "transparent",
  borderRadius: 50,
  padding: 0,
  height: 50,
  width: 50,
  minWidth: 0,
  "&:hover": {
    backgroundColor: alpha("#000", 0.05),
  },
}))

export const ThumbsDown = styled(ThumbsUp)(() => ({
  transform: "rotate(180deg)",
}))

export const GeniePopperFeedbackContent = () => {
  const dispatch = useAppDispatch()
  const session = useAppSelector((state) => state.session)

  const handleSubmit = (values: GenieCourseFeedbackParameters) => {
    // Fire and forget
    dispatch(saveFeedback(values))
  }

  const initialValues: GenieCourseFeedbackParameters = {
    rating: 0,
    comment: "",
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue, submitCount, submitForm, values }) => (
        <Box sx={{ fontSize: 14 }}>
          {!values.rating && (
            <Box>
              <GenieMessage>
                Now <em>that</em> looks like a course!
                <br />
                <strong>How did I do?</strong>
              </GenieMessage>

              <Stack direction="row" justifyContent="center" spacing={5}>
                <ThumbButton
                  onClick={() => {
                    setFieldValue("rating", 1)
                    submitForm()
                  }}
                >
                  <ThumbsUp width={50} />
                </ThumbButton>

                <ThumbButton onClick={() => setFieldValue("rating", -1)}>
                  <ThumbsDown width={50} />
                </ThumbButton>
              </Stack>
            </Box>
          )}

          {values.rating > 0 && (
            <GenieMessage>
              <strong>Glad to hear it!</strong>
              <br />I can't wait to see what we do next!
            </GenieMessage>
          )}
          {values.rating < 0 && !submitCount && (
            <Box>
              <GenieMessage>
                <strong>Sorry to hear that!</strong>
                <br /> Please let me know any details about what wasn't great so
                I can improve.
              </GenieMessage>

              <FastField
                as={TextField}
                name="message"
                fullWidth
                multiline
                rows={6}
                placeholder="Enter your feedback here..."
                variant="outlined"
                sx={{
                  "& textarea": {
                    fontSize: 14,
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  },
                  marginBottom: 1,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBlock: 2,
                }}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  Submit Feedback
                </Button>
              </Box>
            </Box>
          )}

          {values.rating < 0 && submitCount > 0 && (
            <GenieMessage>
              <strong>
                Thanks {session.user.attributes.field_first_name}!
              </strong>
              <br /> I'm always looking to improve, and your feedback helps me
              do that.
            </GenieMessage>
          )}
        </Box>
      )}
    </Formik>
  )
}
