import {
  TextField,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"

import "../marketplace/marketplace.scss"
import { Field, useFormikContext } from "formik"
import ReactQuill from "react-quill"

import "react-quill/dist/quill.snow.css"
import theme from "@mobilemind/common/src/theme/theme"
import { formats, modules } from "../../constants/reactQuillConfig"

const styles = {
  toggleButton: {
    borderRadius: 12,
    marginRight: 12,
    borderWidth: 0,
    marginTop: 5,
    fontWeight: 700,
  },
  freePaidIcon: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
  name: {
    marginRight: 50,
    flex: 1,
  },
  descriptionLabel: {
    fontSize: 12,
    position: "absolute" as "absolute",
    display: "block",
    top: 321,
    paddingLeft: 15,
    backgroundColor: "white",
    padding: 5,
    zIndex: 2,
    marginLeft: 9,
  },
  formControl: {
    position: "relative" as "relative",
    marginBottom: 20,
  },
}

export const PartnerOverviewForm = () => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <>
      <FormControl style={styles.formControl}>
        <div
          className="partner-overview"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Field
            as={TextField}
            style={styles.name}
            label="Name"
            id="name"
            name="name"
          />
          <div>
            <ToggleButtonGroup
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={values.partner_free_or_paid}
              exclusive
              onChange={(event: any) => {
                setFieldValue("partner_free_or_paid", event.target.value)
              }}
            >
              <ToggleButton
                style={{
                  ...styles.toggleButton,

                  backgroundColor:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    values.partner_free_or_paid === "free"
                      ? theme.palette.secondary.main
                      : "transparent",
                }}
                value="free"
              >
                <span className="icon free" style={styles.freePaidIcon} />
                Free
              </ToggleButton>
              <ToggleButton
                style={{
                  ...styles.toggleButton,
                  backgroundColor:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    values.partner_free_or_paid === "paid"
                      ? "#EEED6E"
                      : "transparent",
                }}
                value="paid"
              >
                <span className="icon diamond" style={styles.freePaidIcon} />
                Premium
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </FormControl>
      <span style={styles.descriptionLabel}>Description</span>
      <ReactQuill
        theme={"snow"}
        onChange={(event) => {
          setFieldValue("partner_description", event)
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={values.partner_description}
        modules={modules}
        formats={formats}
        placeholder={"Description"}
      />

      <FormControl style={{ marginBottom: 25 }}>
        <Field
          as={TextField}
          label="Topics"
          id="partner_topic_list"
          name="partner_topic_list"
        />
      </FormControl>
    </>
  )
}
