import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { filterByPartners } from "@mobilemind/common/src/functions/index"
import qs from "qs"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import { isGroupLevel } from "../../functions"

export const getCourses = createAsyncThunk(
  "coursesSlice/getCourses",
  async (args, thunkAPI) => {
    const { categories } = thunkAPI.getState()

    const filters = thunkAPI.getState().courses.filters
    const group = thunkAPI.getState().session.group
    const session = thunkAPI.getState().session
    const { isPartner } = session

    let query = {
      sort: "name",
      include: "field_category,field_mul",
      filter: {
        archive: {
          group: {
            conjunction: "OR",
          },
        },
        "null-archive": {
          condition: {
            operator: "IS NULL",
            path: "field_archive",
            memberOf: "archive",
          },
        },
        "not-archived": {
          condition: {
            path: "field_archive",
            value: false,
            memberOf: "archive",
          },
        },
        search: {
          condition: {
            operator: "CONTAINS",
            path: "name",
            value: filters.searchQuery,
          },
        },
      },
    }

    // Category / Sub Category
    if (filters.category) {
      // If we don't have a subcategory selected
      query.filter.category = {
        condition: {
          operator: "=",
          path: "field_category.id",
          value: filters.category.id,
        },
      }
    }

    if (!args.hideMM) {
      // Any source
      if (filters.source === "any") {
        query.filter.source = {
          group: {
            conjunction: "OR",
          },
        }
        if (!isPartner) {
          query.filter.mobilemind = {
            condition: {
              operator: "IS NULL",
              path: "field_organization.id",
              memberOf: "source",
            },
          }
        }
        query.filter.organization = {
          condition: {
            path:
              group.type[0].target_id === "partner"
                ? "field_partner.id"
                : "field_organization.id",
            value: group.uuid && group.uuid[0].value,
            memberOf: "source",
          },
        }
      }
      // Just mobilemind courses (null org)
      else if (filters.source === "mobilemind") {
        query.filter.mobilemind = {
          condition: {
            operator: "IS NULL",
            path: "field_organization.id",
          },
        }
      }
      // Otherwise a particular organization
      else {
        if (group.type[0].target_id === "partner") {
          query.filter["field_partner.id"] = filters.source
        } else {
          query.filter["field_organization.id"] = filters.source
        }
      }
    } else {
      query.filter.source = {
        group: {
          conjunction: "OR",
        },
      }
      query.filter.organization = {
        condition: {
          value: group.uuid[0].value,
          path: "field_organization.id",
          memberOf: "source",
        },
      }
      query.filter.partner = {
        condition: {
          operator: "IS NOT NULL",
          path: "field_partner.id",
          memberOf: "source",
        },
      }
    }

    // Current page
    query.page = {
      offset: 0,
      limit: 15,
    }

    let response = await fetchWrapper.get(
      "/api/course_entity/course_entity?" + qs.stringify(query)
    )
    let courses = await response.json()

    courses.data.forEach((course) => {
      if (course.relationships.field_mul.data.length) {
        let mulIds = course.relationships.field_mul.data.map((mul) => mul.id)
        course.field_mul = courses.included.filter((included) =>
          mulIds.includes(included.id)
        )
      }
      if (course.relationships.field_category.data) {
        course.category = categories.data.find(
          (cat) => cat.id === course.relationships.field_category.data.id
        )
      }
    })

    if (isGroupLevel(session) && !isPartner) {
      let userGroupIds =
        session.subgroups && session.subgroups.data.map((group) => group.id)

      courses.data = courses.data.filter((course) => {
        let noGroups = !course.relationships.field_subgroup.data.length
        let hasMemberGroup = course.relationships.field_subgroup.data.find(
          (subgroup) => userGroupIds.includes(subgroup.id)
        )
        return noGroups || hasMemberGroup
      })
    }

    let filteredByPartnership = filterByPartners(
      session.group,
      session.subgroup,
      courses.data
    )

    if (isPartner) {
      filteredByPartnership = filteredByPartnership.filter((course) => {
        return course.relationships.field_partner.data
      })
    }

    return filteredByPartnership
  }
)

const debouncedGetCourses = debounceThunk(getCourses, 750)

export const updateFilters = createAsyncThunk(
  "exploreSlice/updateFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetCourses({ ...args, hideMM: args.hideMM }))
    return args
  }
)

export const resetFilters = createAsyncThunk(
  "exploreSlice/resetFilters",
  async (args, thunkAPI) => {
    const filters = {
      searchQuery: "",
      category: null,
      source: "any",
    }

    thunkAPI.dispatch(getCourses(filters))
    return filters
  }
)

export const coursesSlice = createSlice({
  name: "coursesSlice",
  initialState: {
    data: [],
    hasFetched: false,
    isFetching: true,
    currentPage: 0,
    totalPages: 0,
    filters: {
      category: null,
      source: "any",
      searchQuery: "",
    },
  },
  reducers: {
    setSource: (state, action) => {
      state.currentPage = 0
      state.filters.source = action.payload
    },
    setSearchQuery: (state, action) => {
      state.currentPage = 0
      state.filters.searchQuery = action.payload
    },
    increaseCurrentPage: (state) => {
      state.currentPage++
    },
    getCourses: (state, action) => {
      state.data = state.data.concat(action.payload)
      state.fetched = action.meta.fetched
    },
  },
  extraReducers: {
    [updateFilters.pending]: (state) => {
      state.isFetching = true
    },
    [updateFilters.fulfilled]: (state, action) => {
      if (action.payload === "increaseCurrentPage") {
        state.currentPage++
      } else {
        state.currentPage = 0
        state.totalPages = 0
        state.filters[action.payload.name] = action.payload.value
      }
    },
    [resetFilters.pending]: (state) => {
      state.currentPage = 0
    },
    [resetFilters.fulfilled]: (state, action) => {
      state.filters = action.payload
    },
    [getCourses.pending]: (state) => {
      state.isFetching = true
    },
    [getCourses.fulfilled]: (state, action) => {
      state.hasFetched = true
      state.isFetching = false

      if (!state.currentPage) {
        state.data = action.payload
      } else {
        action.payload.forEach((newCourse) => {
          let existing = state.data.some((course) => course.id === newCourse.id)
          if (!existing) {
            state.data.push(newCourse)
          }
        })
      }

      state.totalPages =
        action.payload.meta && Math.ceil(action.payload.meta.count / 50)
    },
  },
})

export const { setCategory, setSource, setSearchQuery, increaseCurrentPage } =
  coursesSlice.actions
export default coursesSlice.reducer
