import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { SubGroup } from "@mobilemind/common/src/types/groups"
import { MobileMindUser } from "@mobilemind/common/src/types/session"
import {
  SliceCaseReducers,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"
import moment from "moment"
import qs from "qs"
import type { AppDispatch, RootState } from "store/types"
import { CourseFormValues } from "./types"
import { transformCourseToFormValues } from "./utility"

export const fetchActiveCourse = createAsyncThunk<
  CourseFormValues,
  { id: string; template: boolean; shouldLock: boolean },
  { state: RootState }
>(
  "activeCourseSlice/fetchActiveCourse",
  async (args, { dispatch, getState }) => {
    const { id, template, shouldLock } = args
    const { categories } = getState()
    let course,
      response,
      editorLock: string | null = null,
      currentEditor: MobileMindUser | null = null
    let query = {
      filter: {
        drupal_internal__id: id,
      },
      include:
        "field_mul,field_tags,field_reviewers,field_category,field_current_editor,field_related_courses,field_subgroup,field_job_title,field_teacher,field_quiz,field_source_template",
    }
    if (template) {
      query.include = "field_mul,field_category,field_quiz"
      response = await fetchWrapper.get(
        "/api/course_template/course_template?" + qs.stringify(query)
      )
    } else {
      response = await fetchWrapper.get(
        "/api/course_entity/course_entity?" + qs.stringify(query)
      )
    }

    if (response.ok) {
      let data: any = await response.json()

      if (data.included) {
        data.data[0].attributes.field_multiple_choice_options =
          data.included.filter(
            (included: any) => included.type === "paragraph--multiple_choice"
          )

        if (data.data[0].relationships.field_category.data) {
          data.data[0].attributes.field_category = categories.data.find(
            (cat) =>
              cat.id === data.data[0].relationships.field_category.data.id
          )
        }

        data.data[0].relatedCourses = data.included.filter(
          (included: any) => included.type === "course_entity--course_entity"
        )
        data.data[0].jobTitles = data.included.filter(
          (included: any) => included.type === "taxonomy_term--job_titles"
        )
        data.data[0].users = data.included.filter(
          (included: any) => included.type === "user--user"
        )
        data.data[0].quizEntity = data.included.find(
          (included: any) => included.type === "quiz_entity--course_quiz"
        )
        data.data[0].sourceTemplate = data.included.find(
          (included: any) =>
            included.type === "course_template--course_template"
        )

        data.data[0].tags = data.included.filter(
          (included: any) => included.type === "taxonomy_term--tags"
        )
        if (!template) {
          editorLock = data.data[0].attributes.field_editor_lock_time
          currentEditor =
            data.data[0].relationships.field_current_editor.data &&
            data.data[0].users.find(
              (user: MobileMindUser) =>
                user.id ===
                data.data[0].relationships.field_current_editor.data.id
            )
        }
      }

      if (data.data[0]) {
        course = data.data[0]
        let answerQuery: {
          filter: {
            "field_course.id"?: any
            "field_template.id"?: any
          }
        } = {
          filter: {
            "field_course.id": data.data[0].id,
          },
        }
        if (template) {
          answerQuery.filter = { "field_template.id": data.data[0].id }
        }

        let subGroups =
          data.included &&
          data.included.filter(
            (included: any) => included.type === "group--group"
          )
        let subGroupIds =
          course.relationships.field_subgroup.data &&
          course.relationships.field_subgroup.data.map(
            (group: SubGroup) => group.id
          )

        if (!subGroups || !subGroups.length) {
          let groupLevelSubGroups = getState().session.subgroups
          course.subGroups =
            groupLevelSubGroups &&
            // @ts-ignore
            groupLevelSubGroups.data &&
            // @ts-ignore
            groupLevelSubGroups.data.filter((group: SubGroup) =>
              subGroupIds.includes(group.id)
            )
        } else {
          course.subGroups = subGroups
        }

        let answers = await fetchWrapper.get(
          "/api/answer_key/course_answers?" + qs.stringify(answerQuery)
        )

        if (answers.ok) {
          let answerData = await answers.json()
          course.answerData = answerData.data[0]
        }

        // If there's a quiz
        if (data.data[0].quizEntity) {
          let quizRequest = await fetchWrapper.get(
            "/api/full-quiz/" +
              data.data[0].quizEntity.attributes.drupal_internal__id
          )
          let quiz = await quizRequest.json()

          course.quiz = quiz[0]
        }
      }
    }

    // Set the state course lock if it's not a template and the course should be locked by the current user.
    // @todo: this is a side effect and should be handled in a middleware.
    if (!template && shouldLock) {
      dispatch(
        setCourseLock({
          editorLock,
          currentEditor,
        })
      )
    }
    return transformCourseToFormValues(course)
  }
)

// @todo: combine this with setContentLock
export const lockCourse = createAsyncThunk<
  { editorLock: string; currentEditor: any },
  string,
  { dispatch: AppDispatch; state: RootState }
>("activeCourseSlice/createCourseLock", async (id, { getState }) => {
  const { session } = getState()
  const editorLock = moment().format()
  const currentEditor = session.user
  const body = {
    data: {
      id,
      type: "course_entity--course_entity",
      attributes: {
        field_editor_lock_time: editorLock,
      },
      relationships: {
        field_current_editor: {
          data: {
            type: "user--user",
            id: currentEditor.id,
          },
        },
      },
    },
  }
  await fetchWrapper.patch(
    "/api/course_entity/course_entity/" + id,
    session.token,
    JSON.stringify(body)
  )
  return {
    editorLock,
    currentEditor,
  }
})

// @todo: combine this with setContentLock
// @todo also: This does not work because the only ID it is being given is the drupal internal one, not UUID
export const unlockCourse = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("activeCourseSlice/clearCourseLock", async (id, { getState }) => {
  const { session } = getState()
  const body = {
    data: {
      id,
      type: "course_entity--course_entity",
      attributes: {
        field_editor_lock_time: null,
      },
      relationships: {
        field_current_editor: {
          data: null,
        },
      },
    },
  }
  await fetchWrapper.patch(
    "/api/course_entity/course_entity/" + id,
    session.token,
    JSON.stringify(body)
  )
})

export const getRelatedCourses = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("activeCourseSlice/getRelatedCourses", async (args, thunkAPI) => {
  const { session, activeCourse } = thunkAPI.getState()

  let url = session.isPartner
    ? "/api/mm_partner_portal/course_explore?"
    : "/api/course_entity/explore?"

  let response = await fetchWrapper.get(
    url + qs.stringify({ search: activeCourse.relatedSearch })
  )

  if (response.ok) {
    let data = await response.json()

    const courses: any[] = []
    data.data.forEach((course: any) => {
      if (!courses.find((existing) => existing.id === course.id)) {
        courses.push(course)
      }
    })

    return courses
  }
})

const debouncedGetRelated = debounceThunk(getRelatedCourses, 750)
export const updateRelatedSearch = createAsyncThunk<
  string,
  string,
  { state: RootState }
>("activeCourseSlice/updateRelatedSearch", async (args, thunkAPI) => {
  thunkAPI.dispatch(debouncedGetRelated())
  return args
})

type ActiveCourseState = {
  editorLock: string | null
  currentEditor: MobileMindUser | null
  relatedSearch: string
  relatedSearchResults: any[]
}

export const activeCourseSlice = createSlice<
  ActiveCourseState,
  SliceCaseReducers<ActiveCourseState>
>({
  name: "activeCourseSlice",
  initialState: {
    editorLock: null,
    currentEditor: null,
    relatedSearch: "",
    relatedSearchResults: [],
  },
  reducers: {
    setCourseLock: (state, action) => {
      state.editorLock = action.payload.editorLock
      state.currentEditor = action.payload.currentEditor
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateRelatedSearch.pending, (state, action) => {
      state.relatedSearch = action.meta.arg
    })
    builder.addCase(getRelatedCourses.fulfilled, (state, action) => {
      state.relatedSearchResults = action.payload
    })
    builder.addCase(lockCourse.fulfilled, (state, action) => {
      state.editorLock = action.payload.editorLock
      state.currentEditor = action.payload.currentEditor
    })
    builder.addCase(unlockCourse.fulfilled, (state, action) => {
      state.editorLock = null
      state.currentEditor = null
    })
  },
})

export const { setCourseLock } = activeCourseSlice.actions

// Selector function to determine if the active course is locked
export const selectIsContentLocked = createSelector(
  [
    (state: RootState) => state.activeCourse,
    (state: RootState) => state.session,
  ],
  (activeCourse, session) => {
    const { editorLock, currentEditor } = activeCourse
    return editorLock && currentEditor?.id !== session.user.id
  }
)

export default activeCourseSlice.reducer
