import { Snackbar, Button } from "@mui/material"
import { connect } from "react-redux"

import { closeSnackbar } from "../features/snackbar/snackbarSlice"
import { useAppDispatch } from "store/hooks"

const mapStateToProps = ({ snackbar }) => {
  return {
    snackbar,
  }
}

function CustomSnackbar(props) {
  const { snackbar } = props
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(closeSnackbar())
  }

  return (
    <div className="snackbarContainer">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleClose}
        message={snackbar.message}
        action={
          <Button className="button small" onClick={handleClose}>
            Got It
          </Button>
        }
      />
    </div>
  )
}

export default connect(mapStateToProps)(CustomSnackbar)
