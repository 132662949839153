import moment from "moment"

type ConvertProps = {
  field_open_time: [{ value: string }]
  field_close_time: [{ value: string }]
}
export function convertSchoolStartEndTime({
  field_open_time,
  field_close_time,
}: ConvertProps) {
  const today = moment().format().split("T")[0]

  let startTime = field_open_time[0]?.value
    .replace("am", ":00")
    .replace("pm", ":00")

  if (startTime && startTime.split(":")[0].length === 1) {
    startTime = "0" + startTime
  }

  let endTime = field_close_time[0]?.value
    .replace("am", ":00")
    .replace("pm", ":00")

  if (endTime && field_close_time[0]?.value.includes("pm")) {
    let hour = Number(endTime.split(":")[0]) + 12
    endTime = hour + ":" + endTime.split(":")[1] + ":00"
  }

  if (endTime && endTime.split(":")[0].length === 1) {
    endTime = "0" + endTime
  }

  return {
    startTime: moment(today + "T" + startTime).format(),
    endTime: moment(today + "T" + endTime).format(),
  }
}
