import { useCallback } from "react"
import { useAppDispatch } from "store/hooks"
import {
  Select,
  FormControl,
  TextField,
  InputLabel,
  Button,
  MenuItem,
} from "@mui/material"
import _ from "lodash"

import {
  setGroup,
  setSearchQuery,
  setSearchInput,
  setSortBy,
  setGroupAdminGroup,
  resetFilters,
} from "./teacherAccessSlice"

function SidebarTeacherAccess(props) {
  const dispatch = useAppDispatch()

  const { searchInput, group, sortBy, groupAdminGroup } =
    props.teacherAccess.filters
  const { session } = props

  const setQuery = (value) => dispatch(setSearchQuery(value))
  // eslint-disable-next-line
  const setQueryDebounced = useCallback(_.debounce(setQuery, 500), [])

  function handleChange(value) {
    dispatch(setSearchInput(value))
    setQueryDebounced(value)
  }

  return (
    <div id="sidebar">
      <form className="filters">
        <header style={{ marginBottom: 15 }}>
          <strong>Filter</strong>
          <Button
            className="button small"
            onClick={() => {
              dispatch(resetFilters())
            }}
          >
            Reset
          </Button>
        </header>

        {(session.orgRoles.includes("organization-admin") ||
          session.orgRoles.includes("organization-reporter")) && (
          <FormControl variant="standard" className="inputSelect group">
            <InputLabel id="label-group-select">Group</InputLabel>
            <Select
              variant="standard"
              labelId="label-group-select"
              id="group-select"
              value={group}
              onChange={(event) => dispatch(setGroup(event.target.value))}
            >
              <MenuItem value={"all"}>All</MenuItem>
              {session.subGroups.data.map((group) => {
                return (
                  <MenuItem key={group.id} value={group.id}>
                    {group.attributes.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        {!session.orgRoles.includes("organization-admin") &&
          !session.orgRoles.includes("organization-reporter") && (
            <FormControl variant="standard" className="inputSelect group">
              <InputLabel id="label-group-select">Group</InputLabel>
              <Select
                variant="standard"
                labelId="label-group-select"
                id="group-select"
                value={
                  groupAdminGroup
                    ? groupAdminGroup
                    : session.subgroups.data[0].drupal_internal__id
                }
                onChange={(event) => {
                  dispatch(setGroupAdminGroup(event.target.value))
                }}
              >
                {session.subgroups.data.map((group) => {
                  return (
                    <MenuItem key={group.id} value={group.drupal_internal__id}>
                      {group.attributes.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}

        <div className="flexRow inputSearch">
          <span className="icon search" />
          <TextField
            variant="standard"
            label="Learner Search"
            value={searchInput}
            onChange={(event) => handleChange(event.target.value)}
          />
        </div>

        <header style={{ marginBottom: 20 }}>
          <strong>Sort</strong>
        </header>
        <FormControl variant="standard" className="inputSelect sort">
          <InputLabel id="label-sort-select">Sort By</InputLabel>
          <Select
            variant="standard"
            labelId="label-sort-select"
            id="sort-select"
            value={sortBy}
            onChange={(event) => dispatch(setSortBy(event.target.value))}
          >
            <MenuItem value={"login"}>Last Login</MenuItem>
            <MenuItem value={"field_first_name_value"}>First Name</MenuItem>
            <MenuItem value={"field_last_name_value"}>Last Name</MenuItem>
            <MenuItem value={"mail"}>Email</MenuItem>
          </Select>
        </FormControl>
      </form>
    </div>
  )
}

export default SidebarTeacherAccess
