import { IconButton, IconButtonProps } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"

export const DeleteButton = (props: IconButtonProps) => {
  return (
    <IconButton {...props}>
      <CancelIcon fontSize="small" sx={{ color: "grey.500" }} />
    </IconButton>
  )
}
