import { GenieRewriteAllowedField } from "@mobilemind/genie-api/src/types"
import { TextField } from "@mui/material"
import { FastFieldWithError } from "components/FastFieldWithError"
import { useContext } from "react"
import { GenieContext } from "./GenieContext"

export const QuestionField = () => {
  const { getGenieElementProps } = useContext(GenieContext)
  return (
    <FastFieldWithError
      as={TextField}
      name="question"
      label="Question"
      margin="normal"
      variant="standard"
      multiline
      size="medium"
      required={true}
      helperText={
        'A final question for the learner in the extension, e.g. "Enter the URL to your submission below."'
      }
      {...getGenieElementProps(GenieRewriteAllowedField.Question, "question")}
    />
  )
}
