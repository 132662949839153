import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"

export const getTeacherAccess = createAsyncThunk(
  "teacherAccessSlice/getTeacherAccess",
  async (args) => {
    const { filters, groupId, searchQuery, subGroups } = args

    let baseUrl =
      args.orgRoles.includes("organization-admin") ||
      args.orgRoles.includes("organization-reporter")
        ? "/api/leaderboard/"
        : "/api/group-leaderboard/"

    let url = baseUrl + groupId + "?"

    let query = {
      search: searchQuery,
      field_subgroup_value: "",
      sort_by: filters.sortBy === "login" ? "access" : filters.sortBy,
      sort_order: filters.sortOrder,
      page: filters.page,
    }

    if (filters.group !== "all") {
      url =
        baseUrl +
        subGroups.find((group) => group.id === filters.group).entity_id +
        "?"
    } else if (
      !args.orgRoles.includes("organization-admin") &&
      !args.orgRoles.includes("organization-reporter")
    ) {
      let newGroupId = filters.groupAdminGroup
        ? filters.groupAdminGroup
        : groupId
      url = baseUrl + newGroupId + "?"
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))
    let data = await response.json()

    return data
  }
)

export const teacherAccessSlice = createSlice({
  name: "teacherAccessSlice",
  initialState: {
    data: [],
    total: 0,
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
    searchQuery: "",
    filters: {
      sortOrder: "DESC",
      sortBy: "login",
      group: "all",
      groupAdminGroup: null,
      searchInput: "",
    },
  },
  reducers: {
    setGroup: (state, action) => {
      state.filters.group = action.payload
      state.currentPage = 0
      state.totalPages = 0
      state.data = []
    },
    increaseCurrentPage: (state) => {
      state.currentPage++
    },
    setSearchInput: (state, action) => {
      state.filters.searchInput = action.payload
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
      state.currentPage = 0
      state.data = []
    },
    setSortOrder: (state) => {
      state.currentPage = 0
      state.data = []
      state.filters.sortOrder =
        state.filters.sortOrder === "DESC" ? "ASC" : "DESC"
    },
    setSortBy: (state, action) => {
      state.currentPage = 0
      state.filters.sortBy = action.payload
      state.data = []
    },
    setGroupAdminGroup: (state, action) => {
      state.currentPage = 0
      state.filters.groupAdminGroup = action.payload
      state.data = []
    },
    resetFilters: (state) => {
      let { sortOrder, sortBy, group, searchInput } = state.filters
      if (
        sortOrder !== "DESC" ||
        sortBy !== "login" ||
        group !== "all" ||
        searchInput !== ""
      ) {
        state.fetching = true
        state.data = []
        state.searchQuery = ""
        state.filters = {
          sortOrder: "DESC",
          sortBy: "login",
          group: "all",
          searchInput: "",
          page: 0,
        }
      }
    },
  },
  extraReducers: {
    [getTeacherAccess.pending]: (state) => {
      state.fetching = true
    },
    [getTeacherAccess.fulfilled]: (state, action) => {
      if (!action.payload.rows.content) {
        let data = []
        action.payload.rows.forEach((row) => {
          let exists = state.data.some((user) => user.uuid === row.uuid)
          if (!exists) {
            data.push(row)
          }
        })

        state.data = [state.data, data].flat()
      }

      state.totalPages = action.payload.pager.total_pages
      state.fetching = false
      state.totalItems = action.payload.pager.total_items
    },
  },
})

export const {
  setGroup,
  setGroupAdminGroup,
  setSortOrder,
  setSortBy,
  resetFilters,
  increaseCurrentPage,
  setSearchQuery,
  setSearchInput,
} = teacherAccessSlice.actions

export default teacherAccessSlice.reducer
