import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import moment from "moment"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import { RootState } from "store/types"
import { OrgRubric } from "@mobilemind/common/src/types/rubrics"

type RubricQuery = {
  criteria?: boolean
  criteria_id?: number
  start: string
  end: string
  subgroup?: number
  uid?: number
}

export const getSingleRubric = createAsyncThunk<any, any, { state: RootState }>(
  "analytics/getSingleRubric",
  async (args, thunkAPI) => {
    const { rubricUUID } = args

    const response = await fetchWrapper.get(`/api/rubric/${rubricUUID}`)

    if (response.ok) {
      const data = await response.json()
      return data.data
    }
  }
)

export const getRubricAnalyticsYear = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("analytics/getRubricAnalyticsYear", async (args, thunkAPI) => {
  const { rubricId, criteriaId, subgroup, user, dateFrom, dateTo } =
    thunkAPI.getState().analytics.filters
  const { session } = thunkAPI.getState()
  const allRoles = session.orgRoles.concat(session.groupRoles).join(",")

  const criteria = criteriaId === "allCriteria" ? false : true

  let query: RubricQuery = {
    criteria,
    start: dateFrom,
    end: dateTo,
  }
  if (subgroup !== "all") {
    query.subgroup = subgroup
  }
  if (allRoles.includes("group-observation_admin")) {
    if (subgroup === "all") {
      query.subgroup = session.subgroup.id[0].value
    } else {
      query.subgroup = subgroup
    }
  }
  if (user) {
    query.uid = Number(user.uid)
  }

  query.criteria = criteria
  if (criteria) {
    query.criteria_id = Number(criteriaId)
  }

  const yearsArray = []
  const startYear = Number(moment(dateFrom).startOf("year").format("YYYY"))
  query.start = moment(dateFrom).startOf("year").format("YYYY-MM-DD")

  const endYear = Number(moment(dateTo).endOf("year").format("YYYY"))
  query.end = moment(dateTo).endOf("year").format("YYYY-MM-DD")

  let totalScore = 0
  const totalsURL =
    "/api/rubric_analytics/" + rubricId + "?" + qs.stringify(query)
  const totalsResponse = await fetchWrapper.get(totalsURL)
  if (totalsResponse.ok) {
    let totalsData = await totalsResponse.json()

    totalScore = Number(
      Number(
        totalsData.data.user_rubric_data[0].rubric_total_score_average ?? 0
      ).toFixed(1)
    )
  }

  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year)
  }

  let yearData = []

  let i = 0
  while (i < yearsArray.length) {
    let currentFetchingYear = yearsArray[i]

    query.start = moment("01/01/" + currentFetchingYear)
      .startOf("year")
      .format("YYYY-MM-DD")
    query.end = moment("01/01/" + currentFetchingYear)
      .endOf("year")
      .format("YYYY-MM-DD")

    const url = "/api/rubric_analytics/" + rubricId + "?" + qs.stringify(query)

    let response = await fetchWrapper.get(url)
    let data = await response.json()

    const datapoint = {
      year: currentFetchingYear,
      totalScoreAverage: Number(
        data.data.user_rubric_data[0].rubric_total_score_average
      ).toFixed(1),
    }
    yearData.push(datapoint)
    i++
  }

  return { yearData, totalScore }
})

export const getRubricAnalyticsMonth = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("analytics/getRubricAnalyticsMonth", async (args, thunkAPI) => {
  const { rubricId, criteriaId, subgroup, user, dateFrom, dateTo } =
    thunkAPI.getState().analytics.filters
  const { session } = thunkAPI.getState()
  const allRoles = session.orgRoles.concat(session.groupRoles).join(",")
  const criteria = criteriaId === "allCriteria" ? false : true

  let query: RubricQuery = {
    start: dateFrom,
    end: dateTo,
  }
  if (user) {
    query.uid = Number(user.uid)
  }

  if (subgroup !== "all") {
    query.subgroup = subgroup
  }

  if (allRoles.includes("group-observation_admin")) {
    if (subgroup === "all") {
      query.subgroup = session.subgroup.id[0].value
    } else {
      query.subgroup = subgroup
    }
  }

  let totalScore = 0
  const startYear = Number(moment(dateFrom).startOf("year").format("YYYY"))
  query.start = moment(dateFrom).startOf("year").format("YYYY-MM-DD")

  const endYear = Number(moment(dateTo).endOf("year").format("YYYY"))
  query.end = moment(dateTo).endOf("year").format("YYYY-MM-DD")

  const totalsURL =
    "/api/rubric_analytics/" + rubricId + "?" + qs.stringify(query)
  const totalsResponse = await fetchWrapper.get(totalsURL)
  if (totalsResponse.ok) {
    let totalsData = await totalsResponse.json()
    totalScore = Number(
      Number(
        totalsData.data.user_rubric_data[0].rubric_total_score_average
      ).toFixed(1)
    )
  }

  query.criteria = criteria
  if (criteria) {
    query.criteria_id = Number(criteriaId)
  }

  const monthsRequestArray = []

  // @ts-ignore
  for (let year = startYear; year <= endYear; year++) {
    for (let month = 1; month <= 12; month++) {
      monthsRequestArray.push({
        start: moment(month + "/01/" + year).format("YYYY-MM-DD"),
        end: moment(month + "/01/" + year)
          .endOf("month")
          .format("YYYY-MM-DD"),
      })
    }
  }

  let monthData = []
  //  let criteriaData = []
  let i = 0

  while (i < monthsRequestArray.length) {
    let currentFetchingPeriod = monthsRequestArray[i]

    query.start = currentFetchingPeriod.start
    query.end = currentFetchingPeriod.end

    const url = "/api/rubric_analytics/" + rubricId + "?" + qs.stringify(query)
    let response = await fetchWrapper.get(url)
    let data = await response.json()

    const datapoint = {
      year: Number(moment(currentFetchingPeriod.start).format("YYYY")),
      month: Number(moment(currentFetchingPeriod.start).format("M")),
      totalScoreAverage: Number(
        data.data.user_rubric_data[0].rubric_total_score_average ?? 0
      ),
    }

    monthData.push(datapoint)

    i++
  }

  return { monthData, totalScore }
})

export const getUsers = createAsyncThunk<any, any, { state: RootState }>(
  "analytics/getUsers",
  async (args, thunkAPI) => {
    const { userSearch, subgroup } = thunkAPI.getState().analytics.filters
    const { session } = thunkAPI.getState()

    const isOrgLevel =
      !session.groupRoles.includes("group-observation_admin") &&
      (session.orgRoles.includes("organization-admin") ||
        session.orgRoles.includes("organization-observation_admin"))

    let groupId = isOrgLevel
      ? session.group.id[0].value
      : session.subgroup.id[0].value

    let query = {
      search: userSearch,
    }

    if (subgroup !== "all") {
      groupId = subgroup
    }

    // @ts-ignore
    query["role[0]"] = "all"
    let response = await fetchWrapper.get(
      "/api/organization-members/" + groupId + "?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()
      return data.rows
    }
  }
)

const debouncedGetUsers = debounceThunk(getUsers, 750)
export const updateUserSearch = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("analytics/updateUserSearch", async (args, thunkAPI) => {
  thunkAPI.dispatch(debouncedGetUsers())
  return args
})

type OrgUserResult = {
  field_bio: string
  field_first_name: string
  field_job_title: string
  field_last_name: string
  field_subgroup: string
  group_roles: string
  uid: string
  user_picture: string
  uuid: string
}

type InitialState = {
  fetched: boolean
  currentTab: string
  totalScore: number
  yearData: {
    year: number
    totalScoreAverage: string
  }[]
  singleRubricResult: OrgRubric | null
  userSearchResults: OrgUserResult[]
  monthData: {
    month: number
    totalScoreAverage: number
    year: number
  }[]
  monthCriteriaData: any[]
  filters: {
    userSearch: string
    user: OrgUserResult | null
    contentType: string
    rubricId: number | null
    subgroup: "all" | number
    dateFrom: string
    dateTo: string
    criteriaId: string | "allCriteria"
  }
}

const initialState: InitialState = {
  fetched: false,
  currentTab: "month",
  yearData: [],
  monthData: [],
  monthCriteriaData: [],
  totalScore: 0,
  userSearchResults: [],
  singleRubricResult: null,
  filters: {
    userSearch: "",
    user: null,
    contentType: "rubric",
    rubricId: null,
    subgroup: "all",
    dateFrom: moment().startOf("year").format(),
    dateTo: moment().endOf("year").format(),
    criteriaId: "allCriteria",
  },
}

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload
    },
    resetFilters: (state) => {
      state.filters.userSearch = ""
    },
    setRubricId: (state, action) => {
      state.filters.rubricId = action.payload
      state.filters.criteriaId = "allCriteria"
    },
    clearUserSearchResults: (state) => {
      state.userSearchResults = []
    },
    updateAnalyticsFilters: (state, action) => {
      //@ts-ignore
      state.filters[action.payload.name] = action.payload.value
    },
    setUser: (state, action) => {
      state.filters.user = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(getRubricAnalyticsYear.pending, (state, action) => {
      state.fetched = false
    })
    builder.addCase(getRubricAnalyticsYear.fulfilled, (state, action) => {
      state.fetched = true
      state.yearData = action.payload.yearData
      state.totalScore = action.payload.totalScore
    })
    builder.addCase(updateUserSearch.pending, (state, action) => {
      // @ts-ignore
      state.filters.userSearch = action.meta.arg
    })
    builder.addCase(getRubricAnalyticsMonth.pending, (state, action) => {
      state.fetched = false
    })
    builder.addCase(getRubricAnalyticsMonth.fulfilled, (state, action) => {
      state.fetched = true
      state.monthData = action.payload.monthData
      state.totalScore = action.payload.totalScore
    })

    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.userSearchResults = action.payload
    })

    builder.addCase(getSingleRubric.fulfilled, (state, action) => {
      state.singleRubricResult = action.payload
    })
  },
})

export const {
  resetFilters,
  setRubricId,
  setCurrentTab,
  updateAnalyticsFilters,
  clearUserSearchResults,
  setUser,
} = analyticsSlice.actions

export default analyticsSlice.reducer
