import { Button, Typography, Stack, Box } from "@mui/material"

import { CourseFormValues } from "features/courseForm/types"
import "../../../../styles/extension.scss"
import { ExtensionPreviewChallengeFooter } from "./ExtensionPreviewChallengeFooter"
import { SubmissionType } from "@mobilemind/common/src/types/course"

type MultipleChoiceProps = {
  course: CourseFormValues
  setPage: (page: number) => void
}

export function ExtensionPreviewMultipleChoice({
  course,
  setPage,
}: MultipleChoiceProps) {
  const isPoll = course.submissionType === SubmissionType.Checkbox
  const answers = isPoll
    ? course.checkboxOptions
    : course.answers.map((a) => a.attributes.field_mc_answer)

  return (
    <>
      <Box
        sx={{
          paddingInline: 3,
          paddingBlock: 5,
          color: "white",
          overflowY: "auto",
        }}
      >
        <Typography sx={{ marginBottom: 2 }}>{course.question}</Typography>

        <Stack spacing={2}>
          {answers.map((answer) => {
            return (
              <Button
                sx={(theme) => ({
                  flex: 1,
                  textAlign: "left",
                  justifyContent: "flex-start",
                  "&:focus": {
                    backgroundColor: theme.palette.secondary.main,
                  },
                })}
                variant="contained"
                size="large"
                color="secondary"
              >
                {answer}
              </Button>
            )
          })}
        </Stack>
      </Box>

      <ExtensionPreviewChallengeFooter setPage={setPage} />
    </>
  )
}
