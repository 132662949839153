import { Component } from "react"
import { connect } from "react-redux"
import { getOrgLearningPaths } from "../../actions/learningPaths"

import { fetchLearningPathTypes } from "../learningPathTypes/learningPathTypesSlice"
import {
  setCurrentPage,
  increasePage,
  setSortOrder,
} from "../entityList/entityListSlice"
import SidebarEntityList from "./SidebarEntityList"

import EntityList from "./EntityList"
import Loading from "@mobilemind/common/src/components/Loading"

import { getSubGroups, getOrganizations } from "../../store/reducers/session"
import { getJobTitles } from "../jobTitles/jobTitlesSlice"
import BottomScrollListener from "react-bottom-scroll-listener"

const mapStateToProps = ({
  session,
  jobTitles,
  learningPathTypes,
  entityList,
}) => {
  return {
    currentTab: entityList.orgLearningPaths.currentTab,
    orgLearningPaths: entityList.orgLearningPaths,
    filters: entityList.orgLearningPaths.filters,
    learningPathTypes,
    session,
    jobTitles,
    entityList,
    fetched: entityList.orgLearningPaths.fetched,
  }
}

const mapDispatchToProps = {
  getOrgLearningPaths,
  fetchLearningPathTypes,
  getSubGroups,
  getJobTitles,
  getOrganizations,
  increasePage,
  setCurrentPage,
  setSortOrder,
}

class LearningPathListContainer extends Component {
  componentDidMount() {
    const { session, jobTitles } = this.props

    this.props.setCurrentPage("Learning Paths")
    !this.props.orgLearningPaths.fetched &&
      this.props.getOrgLearningPaths(this.props.filters)
    !this.props.learningPathTypes.fetched && this.props.fetchLearningPathTypes()

    !session.subGroups.fetched && this.props.getSubGroups()
    session.isSiteAdmin &&
      !session.organizations.fetched &&
      this.props.getOrganizations()

    !jobTitles.fetched && this.props.getJobTitles()
  }

  handleScroll = () => {
    const { orgLearningPaths } = this.props.entityList
    const { currentPage, totalPages } = orgLearningPaths

    if (currentPage + 1 < totalPages) {
      this.props.increasePage({ type: "orgLearningPaths" })
      setTimeout(() => {
        this.props.getOrgLearningPaths()
      }, 450)
    }
  }

  render() {
    const { session, jobTitles, currentTab, filters, entityList } = this.props

    const props = {
      entityType: "Learning Path",
      iconClass: "icon learningPath",
      createPath: "/create/learning-paths/new",
      fetched: this.props.fetched,
      total: entityList.orgLearningPaths.totalRecords,
      isSiteAdmin: session.isSiteAdmin,
      currentTab: this.props.currentTab,
      setSortOrder: this.props.setSortOrder,
      session,
      filters,
    }

    const sidebarProps = {
      type: "Learning Paths",
      filters,
      entityList,
      learningPathTypes: this.props.learningPathTypes.data,
      session,
      organizations: session.organizations,
      subGroups: session.subGroups,
      jobTitles: jobTitles,
      fetchData: this.props.getOrgLearningPaths,
      currentTab,
    }

    return (
      <BottomScrollListener onBottom={this.handleScroll}>
        <SidebarEntityList {...sidebarProps} />
        <EntityList {...props} />
        {entityList.orgLearningPaths.isFetchingMore && (
          <div className="loadingMoreContainer">
            <Loading message={"Getting more learning paths..."} />
          </div>
        )}
      </BottomScrollListener>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LearningPathListContainer)
