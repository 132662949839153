import { CategorySelect } from "@mobilemind/common/src/components/CategorySelect"
import CancelIcon from "@mui/icons-material/Cancel"
import { Box, IconButton, InputLabel, TextField } from "@mui/material"
import { createNextState } from "@reduxjs/toolkit"
import { Field, useFormikContext } from "formik"
import "react-quill/dist/quill.snow.css"
import { useAppSelector } from "store/hooks"
import "../../../styles/external-pd.scss"
import { ExternalEvent, TimeBlock } from "./RecommendedEventModal"
import { CategoryTaxonomy } from "@mobilemind/common/src/types/taxonomy/category"

export function TimeBlockList() {
  const { setFieldValue, setValues, values } = useFormikContext<ExternalEvent>()
  const categories = useAppSelector((state) => state.categories)

  return (
    <Box
      component="ul"
      sx={{
        display: "grid",
        // Creates a four column layout with the first two columns being 50px wide, the third column taking up the remaining space, and the fourth column being as wide as its content
        gridTemplateColumns: "repeat(2, 50px) 1fr min-content",
        alignItems: "flex-start",
        // Add 15px of space between columns...
        columnGap: 3,
        // ...and 10px of space between rows.
        rowGap: 2,
        "& .MuiInputLabel-root": {
          overflow: "unset",
        },
      }}
    >
      <InputLabel shrink={true}>Hours</InputLabel>
      <InputLabel shrink={true}>Minutes</InputLabel>
      <InputLabel shrink={true}>Category</InputLabel>
      {/* Add an empty header element for the delete icon */}
      <span />

      {values.timeBlocks.map((timeBlock: TimeBlock, index: number) => {
        const blockCategory =
          categories.data.find(
            (cat: CategoryTaxonomy) => cat.id === timeBlock.categoryId
          ) ?? null

        return (
          <Box
            component="li"
            key={index}
            sx={{
              // `display: "contents"` is used to remove the wrapping div that Box normally adds, so that the children can be displayed as grid items
              display: "contents",
            }}
          >
            <Field
              as={TextField}
              variant="standard"
              type="number"
              name={`timeBlocks[${index}].hours`}
              value={timeBlock.hours ?? ""}
              placeholder="0"
              InputProps={{
                sx: {
                  fontWeight: "bold",
                },
              }}
              inputProps={{
                min: 0,
              }}
            />
            <Field
              as={TextField}
              variant="standard"
              type="number"
              name={`timeBlocks[${index}].minutes`}
              value={timeBlock.minutes ?? ""}
              placeholder="0"
              InputProps={{
                sx: {
                  fontWeight: "bold",
                },
              }}
              inputProps={{
                min: 0,
                max: 59,
              }}
            />
            <CategorySelect
              currentValue={blockCategory}
              onChange={(selected) => {
                setFieldValue(
                  `timeBlocks[${index}].categoryId`,
                  selected?.id ?? null
                )
              }}
              categories={categories}
              allowedLevels={3}
              sx={{ marginRight: 0 }}
            />

            {values.timeBlocks.length > 1 && (
              <IconButton
                onClick={() => {
                  setValues(
                    createNextState((draft) => {
                      draft.timeBlocks.splice(index, 1)
                    })
                  )
                }}
              >
                <CancelIcon fontSize="small" sx={{ color: "grey.500" }} />
              </IconButton>
            )}
          </Box>
        )
      })}
    </Box>
  )
}
