import {
  YouTubeSearchItem,
  YouTubeSearchResponse,
} from "@mobilemind/genie-api/src/types"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material"
import { useAsync } from "@react-hook/async"
import usePrevious from "@react-hook/previous"
import { useEffect, useRef } from "react"
import {
  CreateCourseGenieSearchResultsCard,
  PLAYER_WIDTH,
} from "./CreateCourseGenieSearchResultsCard"
import { GenieMessage } from "./GenieMessage"

type CreateCourseGenieSearchResultsProps = {
  results: YouTubeSearchResponse
  onBack: () => void
  onSelect: (item: YouTubeSearchItem) => void
  onClickMore: () => Promise<void>
}

export const CreateCourseGenieSearchResults = (
  props: CreateCourseGenieSearchResultsProps
) => {
  const { results, onBack, onSelect, onClickMore } = props
  const [{ status }, searchCallback] = useAsync(onClickMore)

  const cardRefs = useRef<Record<string, HTMLDivElement | null>>({})

  const prevResults = usePrevious(results)
  useEffect(() => {
    // If more results...
    if (prevResults) {
      // ...find the first item from the latest tranche of results...
      const nextFirstItem = results.items[prevResults.items.length ?? 0]
      // ...and scroll that element into view, smoothly.
      cardRefs.current?.[nextFirstItem?.id]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  return (
    <>
      <DialogContent
        sx={(theme) => ({
          // Ensure scrolling to the next tranche of results leaves a nice amount
          // of padding at the top of the dialog.
          scrollPaddingTop: theme.spacing(4),
        })}
      >
        <GenieMessage>
          <Typography>
            <strong>Great!</strong> I found some YouTube videos that could help.
            Feel free to preview them and select the video that would work best
            for you. If none of these are what you are looking for, I can try
            again.
          </Typography>
        </GenieMessage>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              sm: `repeat(1, ${PLAYER_WIDTH}px)`,
              lg: `repeat(2, ${PLAYER_WIDTH}px)`,
            },
            justifyContent: "center",
            gap: 4,
          }}
        >
          {results.items.map((item) => {
            return (
              <CreateCourseGenieSearchResultsCard
                ref={(ref) => {
                  cardRefs.current[item.id] = ref
                }}
                key={item.id}
                item={item}
                onClick={() => onSelect(item)}
              />
            )
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack} variant="contained" color="secondary">
          Change Search
        </Button>
        <LoadingButton
          onClick={searchCallback}
          loading={status === "loading"}
          disabled={!results.nextPageToken}
          variant="contained"
          color="secondary"
        >
          More Results
        </LoadingButton>
      </DialogActions>
    </>
  )
}
