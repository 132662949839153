import { Box } from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { MarketplaceItem } from "./types"
import Diamond from "../../img/diamond.svg"
import Free from "../../img/free.svg"
import { SubGroup } from "@mobilemind/common/src/types/groups"

type PartnerItemProps = {
  partner: MarketplaceItem
  index: number
  containerStyles?: {
    width: number
  }
  selectedGroups?: SubGroup[]
  onClick: () => void
}

export const PartnerMarketplaceItem = ({
  partner,
  index,
  containerStyles,
  onClick,
  selectedGroups,
}: PartnerItemProps) => {
  const isPremium = partner.partner_free_or_paid === "paid"
  const isFeatured = partner.partner_featured === "1"
  const isComingSoon = partner.partner_status === "UnderConstruction"

  const backgroundImageSrc = partner.partner_image.includes("data:image")
    ? partner.partner_image
    : process.env.REACT_APP_API_URL + partner.partner_image

  const isAdded =
    partner.added_to_org || (selectedGroups && selectedGroups.length > 0)

  return (
    <div style={{ position: "relative" }}>
      {isComingSoon && (
        <div style={{ ...styles.featured, ...styles.comingSoon }}>
          Coming Soon
        </div>
      )}

      <div
        onClick={() => {
          !isComingSoon && onClick()
        }}
        style={{
          ...styles.container,
          ...containerStyles,
          animationDelay: index * 0.1 + "s",
          pointerEvents: isComingSoon ? "none" : "auto",
          opacity: isComingSoon ? 0.5 : 1,
          boxShadow: isComingSoon ? "none" : theme.shadows[1],
        }}
        className="store-item"
      >
        <div
          style={{
            ...styles.background,
            backgroundImage: "url(" + backgroundImageSrc + ")",
          }}
        ></div>

        {isFeatured && !isComingSoon && (
          <div style={styles.featured}>Featured</div>
        )}

        {!isAdded ? (
          <>
            {isPremium ? (
              <img alt="Paid" src={Diamond} style={styles.paidIcon} />
            ) : (
              <img alt="Free" src={Free} style={styles.paidIcon} />
            )}
          </>
        ) : (
          <>
            <div style={styles.statusIndicator}>
              <div
                className="capsule"
                style={{ ...styles.capsule, ...styles.capsuleAdded }}
              >
                <span className="icon check" style={styles.checkIcon} />
                Added
              </div>
            </div>
          </>
        )}

        <Box
          component="div"
          sx={{
            padding: 3,
          }}
        >
          <Box component={"header"} sx={{ fontWeight: 700 }}>
            {partner.name}
          </Box>
          <div
            style={{
              height: 100,
              ...styles.paragraph,
            }}
          >
            {partner.partner_description && (
              <>
                {SanitizedHTML({
                  html: partner.partner_description,
                })}
              </>
            )}

            <span style={styles.textOverflow} />
          </div>
          {partner.partner_topic_list &&
            partner.partner_topic_list.length > 0 && (
              <div style={styles.topicsContainer}>
                {partner.partner_topic_list
                  .split(",")
                  .map((item: string, index: number) => {
                    if (item.length > 0) {
                      return (
                        <div key={index} style={styles.topic}>
                          {item}
                        </div>
                      )
                    }
                    return null
                  })}
              </div>
            )}
        </Box>
      </div>
    </div>
  )
}

const styles = {
  container: {
    backgroundColor: "white",
    borderRadius: 12,
    overflow: "hidden",
    cursor: "pointer",
    top: 0,
    transition: "0.3s",
    position: "relative" as "relative",
    "&:hover": {
      boxShadow: theme.shadows[2],
      transform: "scale(1.02)",
    },
  },
  featured: {
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 700,
    borderRadius: 20,
    padding: "3px 10px",
    position: "absolute" as "absolute",
    fontSize: 10,
    textTransform: "uppercase" as "uppercase",
    top: 15,
    left: 10,
  },
  comingSoon: {
    backgroundColor: theme.palette.secondary.main,
    color: "black",
    zIndex: 4,
    left: 20,
  },
  checkIcon: {
    height: 10,
    width: 10,
    marginRight: 5,
  },
  statusIndicator: {
    position: "absolute" as "absolute",
    top: 15,
    right: 10,
    display: "flex",
    justifyContent: "center",
    zIndex: 3,
  },
  background: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 125,
    position: "relative" as "relative",
  },
  paidIcon: {
    height: 30,
    width: 30,
    position: "absolute" as "absolute",
    filter: "drop-shadow(2px 2px 2px rgb(0 0 0 / 0.35))",
    top: 10,
    right: 10,
  },
  capsule: {
    textTransform: "uppercase" as "uppercase",
    fontSize: 10,
    padding: "5px 8px",
  },
  capsuleAdded: {
    boxShadow: theme.shadows[2],
    borderRadius: 20,
    padding: " 3px 10px",
    fontSize: 10,
    backgroundColor: "#24DC91",
    fontWeight: 700,
    color: "black",
  },
  capsuleFree: {
    backgroundColor: "white",
    color: "black",
    fontWeight: 700,
  },
  paragraph: {
    marginTop: 0,
    opacity: 0.8,
    marginBottom: 8,
    fontSize: 14,
    overflow: "hidden",
    position: "relative" as "relative",
  },

  textOverflow: {
    position: "absolute" as "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 40,
    background:
      "linear-gradient(0deg, rgba(255,255,255,1) 6%, rgba(255,255,255,0) 100%)",
  },
  topicsContainer: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    marginTop: 15,
    overflow: "hidden",
  },
  topic: {
    backgroundColor: "#E94668",
    color: "white",
    padding: "5px 10px",
    borderRadius: 40,
    marginRight: 5,
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
  },
}
