import { JobTitle } from "../types/taxonomy/jobTitle"
import { api } from "./index"

export const taxonomyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getJobTitles: builder.query<JobTitle[], void>({
      query: () => "/api/taxonomy_term/job_titles",
      transformResponse: (response: { data: JobTitle[] }) => response.data,
    }),
  }),
})

export const { useGetJobTitlesQuery } = taxonomyApi
