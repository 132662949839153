import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { useFormikContext } from "formik"
import moment from "moment"
import "react-quill/dist/quill.snow.css"
import { useAppSelector } from "store/hooks"
import "../../../styles/external-pd.scss"
import { ParticipantList } from "./ParticipantList"
import { ExternalEvent } from "./RecommendedEventModal"
import { TimeBlockList } from "./TimeBlockList"

const styles = {
  image: {
    width: 75,
    border: "3px solid white",
    height: 75,
    boxShadow: "1px 3px 5px 0 rgba(0, 0, 0, 0.4)",
    borderRadius: 10,
    marginRight: 10,
    backgroundSize: "cover",
  },
  dateText: {
    fontWeight: "bold",
    opacity: 0.7,
    fontSize: 14,
  },
}

export const ExternalEventStep4 = () => {
  const { values } = useFormikContext<ExternalEvent>()
  const session = useAppSelector((state) => state.session)

  return (
    <>
      <LearningBuddyMessage message={"Does everything look good?"} />

      <div className="preview">
        <div className="flexRow">
          {values.image && (
            <div
              style={{
                backgroundImage: "url(" + values.image + ")",
                ...styles.image,
              }}
            />
          )}

          <div>
            <strong style={{ display: "block", fontSize: 22, flex: 1 }}>
              {values.title}
            </strong>

            {!values.isDateless ? (
              <>
                {moment(values.startDate).format("MM/DD") ===
                moment(values.endDate).format("MM/DD") ? (
                  <span style={styles.dateText}>
                    {moment(values.startDate).format("MMMM Do YYYY")}{" "}
                    {moment(values.startDate).format("h:mma")} -{" "}
                    {moment(values.endDate).format("h:mma")}
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: "bold",
                      opacity: 0.7,
                      fontSize: 14,
                    }}
                  >
                    {moment(values.startDate).format("MMMM Do YYYY")}{" "}
                    {moment(values.startDate).format("h:mma")} -{" "}
                    {moment(values.endDate).format("MMMM Do YYYY")}{" "}
                    {moment(values.endDate).format("h:mma")}
                  </span>
                )}
              </>
            ) : (
              <span
                style={{
                  fontWeight: "bold",
                  opacity: 0.7,
                  fontSize: 14,
                }}
              >
                Always Available
              </span>
            )}
          </div>
        </div>
        <section>
          <header>Description</header>
          <SanitizedHTML html={values.description} />
        </section>

        <section className="availableHours">
          <header>Available Time</header>
          <TimeBlockList />
        </section>

        <section className="participants">
          <header>Participants</header>
          {values.participantMethod === "custom" ? (
            <ParticipantList />
          ) : (
            <p>
              All {session.group.label[0].value} learners are able to request
              credit for this event.
            </p>
          )}
        </section>
      </div>
    </>
  )
}
