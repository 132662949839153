import { Box, Dialog } from "@mui/material"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"
import FormIcon from "../../img/feedback-form.svg"
import FormColor from "../../img/feedback-form-color.svg"

import {
  getFeedbackForms,
  setFeedbackFormsModalOpen,
} from "store/reducers/feedbackForms"

import "../../styles/categories.scss"
import "../../styles/modal.scss"

import classNames from "classnames"
import { EventFeedbackFormEntity } from "./types"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"

import { FeedbackFormEdit } from "./FeedbackFormEdit"
import { generateTimestamp } from "@mobilemind/common/src/functions"

const emptyForm = {
  type: "mm_form--event_feedback",
  attributes: {
    field_scale_max: 10,
    field_scale_min: 1,
    label: "",
  },
  questions: [
    {
      attributes: {
        field_minimum_label: "",
        field_maximum_label: "",
        field_question_name: "",
        parent_field_name: "field_questions",
        field_required: true,
      },
      type: "paragraph--likert_scale",
    },
  ],
}

const styles = {
  listHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    marginBottom: -10,
    fontSize: 12,
    opacity: 0.6,
  },
  listItem: {
    padding: 2,
    paddingRight: 3,
    paddingLeft: 3,
    display: "flex",
    alignItems: "center",
    marginLeft: -2,
    marginRight: -2,
    marginBottom: 2,
    borderRadius: 12,
    backgroundColor: "white",
    transition: ".3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ebebeb",
    },
  },
}

function FeedbackFormsModal() {
  const feedbackForms = useAppSelector((state) => state.feedbackForms)
  const { data, fetched, isOpen } = feedbackForms
  const [editingForm, setEditingForm] =
    useState<EventFeedbackFormEntity | null>(null)

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!fetched) {
      dispatch(getFeedbackForms())
    }
  }, [fetched, dispatch])

  let minWidth = editingForm ? "75vw" : 650
  if (window.innerWidth > 1400 && editingForm) {
    minWidth = 1000
  }
  return (
    <Dialog
      data-no-focus-lock
      PaperProps={{
        sx: {
          minWidth,
          transition: ".3s",
          maxWidth: 500,
        },
      }}
      onClose={() => {
        dispatch(setFeedbackFormsModalOpen(false))
        setEditingForm(null)
      }}
      className={classNames("modal feedback-forms-modal")}
      open={isOpen}
    >
      <h2 style={{ margin: "0 0 5px" }}>
        {editingForm && (
          <ButtonSmall
            onClick={() => {
              setEditingForm(null)
            }}
          >
            <span className="icon caret" />
          </ButtonSmall>
        )}
        <img
          src={FormColor}
          alt="Feedback Form"
          style={{
            marginLeft: editingForm ? 10 : 0,
            marginRight: 10,
            width: 35,
            height: 35,
          }}
        />
        {!editingForm ? (
          <>
            <strong style={{ flex: 1 }}>Event Feedback Forms</strong>
            <ButtonSmall
              onClick={() => {
                setEditingForm(emptyForm)
              }}
            >
              <span className="icon add" />
              <span style={{ textTransform: "uppercase" }}>Create Form</span>
            </ButtonSmall>
          </>
        ) : (
          <>
            <strong>
              {editingForm.attributes.label ? (
                <>{editingForm.attributes.label}</>
              ) : (
                <>New Feedback Form</>
              )}
            </strong>
          </>
        )}
      </h2>
      {!editingForm && (
        <>
          <div style={styles.listHeader}>
            <label style={{ width: "75%" }}>Name</label>
            <label>Last Updated</label>
          </div>
          <ul style={{ listStyleType: "none" }}>
            {data.map((form: EventFeedbackFormEntity) => {
              return (
                <Box
                  onClick={() => {
                    setEditingForm(form)
                  }}
                  key={form.id}
                  sx={styles.listItem}
                >
                  <div
                    style={{
                      width: "75%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt={"Feedback Form"}
                      src={FormIcon}
                      style={{ marginRight: 10, width: 20, height: 20 }}
                    />
                    <strong>{form.attributes.label}</strong>
                  </div>
                  <span style={{ fontSize: 14, paddingLeft: 3 }}>
                    {generateTimestamp(form.attributes.changed)}
                  </span>
                </Box>
              )
            })}
          </ul>
        </>
      )}

      {editingForm && (
        <FeedbackFormEdit
          onSave={() => setEditingForm(null)}
          editingForm={editingForm}
        />
      )}
    </Dialog>
  )
}

export default FeedbackFormsModal
