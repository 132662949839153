import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"

export const fetchLearningPathTypes = createAsyncThunk(
  "learningPathTypesSlice/fetchLearningPathTypes",
  async () => {
    let response = await fetchWrapper.get(
      "/api/taxonomy_term/learning_path_types"
    )
    let data = await response.json()
    return data.data
  }
)

export const learningPathTypesSlice = createSlice({
  name: "learningPathTypesSlice",
  initialState: {
    fetched: false,
    data: [],
  },
  extraReducers: {
    [fetchLearningPathTypes.fulfilled]: (state, action) => {
      state.fetched = true
      state.data = action.payload
    },
  },
})

export default learningPathTypesSlice.reducer
