import { Dialog, Card } from "@mui/material"
import CategoryIcon from "./CategoryIcon"
import ProgressBar from "./ProgressBar"
import moment from "moment"

import "../styles/modal.scss"
import "../styles/widget.scss"
import "../styles/certificate.scss"

import logoImg from "../img/logo.png"

function ModalLearningPathPreview(props) {
  const { onClose, open, categories, pathType, learningPath, type, user } =
    props

  let imageSrc
  if (learningPath.image) {
    if (learningPath.image.attributes) {
      imageSrc =
        process.env.REACT_APP_API_URL + learningPath.image.attributes.uri.url
    } else {
      imageSrc = learningPath.image
    }
  }

  let pathCategories = [
    ...new Set(
      learningPath.courses.map((course) =>
        categories.data.find(
          (cat) =>
            course.relationships.field_category.data &&
            cat.id === course.relationships.field_category.data.id
        )
      )
    ),
  ]

  if (type === "certificate") {
    return (
      <Dialog
        className="modal certificate-modal learning-path-certificate"
        onClose={() => onClose()}
        open={open}
      >
        <p>
          Here's what this Learning Path's certificate will look like.
          Certificates are awarded to users who complete this Learning Path.
        </p>

        <div className={"certificateWrapper"}>
          <div className="certificateLearningPath">
            <div className="inner">
              <h2>
                <span>
                  This certifies that on {moment().format("MMMM D, YYYY")}
                </span>
                <span style={{ textTransform: "capitalize" }}>
                  {user.attributes.field_first_name.toLowerCase()}{" "}
                  {user.attributes.field_last_name.toLowerCase()}
                </span>
                <span>
                  successfully completed all the required courses for the
                  Learning Path
                </span>
              </h2>
              <span className="title">{learningPath.name}</span>
              <div className="flexRow categories">
                {pathCategories.map((category) => {
                  return <CategoryIcon key={category.id} category={category} />
                })}
              </div>
            </div>
            {imageSrc && (
              <img
                alt={learningPath.name}
                className="learningPathImage"
                src={imageSrc}
              />
            )}
            <div className="logoWrapper">
              <img alt="MobileMind" className="logo" src={logoImg} />
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog
      className="modal learning-path-preview"
      onClose={() => onClose()}
      open={open}
    >
      <p>
        Here's what your Learning Path will look like in the{" "}
        <strong>Learn</strong> app:
      </p>

      <Card className="widget learningPath inProgress">
        <div className="topContent">
          <div className="widgetLabel">
            {pathType ? pathType.attributes.name : "Develop a Skill"}
          </div>
          <div
            className="pathImage"
            style={{ backgroundImage: 'url("' + imageSrc + '")' }}
          />
        </div>
        <div className="bottomContent">
          <h2>{learningPath.name}</h2>
          <p>{learningPath.body}</p>

          <div className="pathStatus">
            0 / {learningPath.courses.length} Courses Completed
            <ProgressBar width={0} />
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default ModalLearningPathPreview
