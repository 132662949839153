import { Button, Stack } from "@mui/material"

type FooterProps = {
  setPage: (page: number) => void
}

export function ExtensionPreviewChallengeFooter({ setPage }: FooterProps) {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ padding: 3, paddingInline: 8, justifyContent: "center" }}
    >
      <Button
        sx={{ flex: 1 }}
        onClick={() => setPage(0)}
        variant="contained"
        size="large"
        color="secondary"
      >
        Back
      </Button>
      <Button
        sx={{ flex: 1 }}
        disabled
        variant="contained"
        size="large"
        color="primary"
      >
        Submit
      </Button>
    </Stack>
  )
}
