import { Box, Stack, Typography } from "@mui/material"
import { CourseFormValues } from "../types"
import Jigsaw from "../../../img/jigsaw.svg"

type ChallengeQuestionProps = {
  course: CourseFormValues
}

export const TemplateChallengeQuestion = ({
  course,
}: ChallengeQuestionProps) => {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{ alignItems: "center", marginBottom: 2 }}
        spacing={2}
      >
        <img src={Jigsaw} width={30} height={30} alt="Question" />
        {course.submissionType === "Text" && (
          <Typography variant="h3">
            {course.reviewMethod === "manual" ? (
              <>Challenge Question (Manual Review)</>
            ) : (
              <>Challenge Question (Automated Review)</>
            )}
          </Typography>
        )}
        {course.submissionType === "Image" && (
          <Typography variant="h3">
            Challenge Question (Image Submission)
          </Typography>
        )}
        {course.submissionType === "URL" && (
          <Typography variant="h3">
            Challenge Question (URL Submission)
          </Typography>
        )}
      </Stack>
      <Typography>{course.question}</Typography>
    </Box>
  )
}
