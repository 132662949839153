import { Box, Stack } from "@mui/material"
import { GenieMessage } from "features/genie/components/GenieMessage"
import { selectCourseGenieState } from "../store/courseGenieSlice"
import { useAppSelector } from "store/hooks"
import { useFormikContext } from "formik"
import { CourseFormValues } from "../types"

const editableFields = [
  "Learn",
  "Apply",
  "Learner Instructions",
  "Question",
  "Reviewer Instructions",
]

const messages = {
  firstDraft: <strong>First draft complete!</strong>,
  mistakes: (
    <>
      I can sometimes make mistakes, so make sure to read everything and make
      the course yours.
    </>
  ),
  help: (
    <>
      I can help with any of the following fields, just click on them and I'll
      appear!
    </>
  ),
}

export const GeniePopperHelperContent = () => {
  const genieState = useAppSelector(selectCourseGenieState)
  const { values } = useFormikContext<CourseFormValues>()

  /**
   * We show different content in a different order if the user hasn't viewed the helper yet.
   * Since this popper is triggered immediately after the course is generated, when setPopper(null) is called
   * we can infer that the user has viewed the helper and subsequent renders will be different.
   */
  const { hasViewedHelper } = genieState
  const showInitial = !hasViewedHelper && !values.id

  return (
    <Box sx={{ fontSize: 14 }}>
      <GenieMessage>
        {showInitial ? (
          <>
            {messages.firstDraft} {messages.mistakes}
            <br />
            <br />
            {messages.help}
          </>
        ) : (
          <>
            {messages.help}
            <br />
            <br />
            {messages.mistakes}
          </>
        )}
      </GenieMessage>
      <Box padding={2}>
        <Stack sx={{ marginTop: -2 }} spacing={1}>
          {editableFields.map((field: string) => {
            return (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                key={field}
              >
                <Box
                  sx={{
                    background: "black",
                    width: 4,
                    height: 4,
                    borderRadius: 1,
                    marginRight: 1,
                  }}
                />
                {field}
              </Box>
            )
          })}
        </Stack>
      </Box>
    </Box>
  )
}
