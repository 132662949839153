import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

export const learningPathsSlice = createSlice({
  name: "learningPathsSlice",
  initialState: {
    data: [],
    fetched: false,
  },
  reducers: {
    getLearningPaths: (state = { data: [] }, action) => {
      let existingPaths = state.data
      let newPaths = action.payload
      let images =
        action.meta.included &&
        action.meta.included.filter(
          (included) => included.type === "file--image"
        )
      let courses =
        action.meta.included &&
        action.meta.included.filter(
          (included) => included.type === "course_entity--course_entity"
        )

      newPaths.forEach((path) => {
        const courseIds = path.relationships.field_courses.data.map(
          (course) => course.id
        )
        let pathCourses = courses.filter((course) =>
          courseIds.includes(course.id)
        )

        path.totalEstimatedTime = pathCourses
          .map((course) => course.attributes.field_course_estimated_time)
          .reduce((a, b) => {
            return a + b
          }, 0)
        path.image =
          images &&
          images.find(
            (image) =>
              path.relationships.field_image.data &&
              image.id === path.relationships.field_image.data.id
          )

        if (!existingPaths.some((existing) => existing.id === path.id)) {
          existingPaths.push(path)
        }
      })

      let sortedPaths = _.orderBy(existingPaths, [
        (path) => path.attributes.name && path.attributes.name.toLowerCase(),
      ])

      state.data = sortedPaths
      state.fetched = action.meta.fetched
    },
  },
})

export default learningPathsSlice.reducer
