import { DeleteButton } from "@mobilemind/common/src/components/DeleteButton"
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material"
import { FastFieldWithError } from "components/FastFieldWithError"
import { useField, useFormikContext } from "formik"
import { CourseFormValues, MultipleChoiceQuestionAnswer } from "../types"

type MultipleChoiceQuestionAnswerFieldProps = {
  name: string
  onDelete?: () => void
  disabled?: boolean
}

export const MultipleChoiceQuestionAnswerField = (
  props: MultipleChoiceQuestionAnswerFieldProps
) => {
  const { name, onDelete } = props
  const [field] = useField<MultipleChoiceQuestionAnswer>(name)
  const { setFieldValue } = useFormikContext<CourseFormValues>()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 4,
        marginBottom: 4,
      }}
      role="listitem"
    >
      <FastFieldWithError
        as={TextField}
        name={`${name}.attributes.field_mc_answer`}
        variant="standard"
        multiline
        placeholder="Enter a possible answer"
        disabled={props.disabled}
        required
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={field.value.attributes.field_mc_answer_type === "correct"}
            onChange={(event) => {
              setFieldValue(
                `${name}.attributes.field_mc_answer_type`,
                event.target.checked ? "correct" : "incorrect"
              )
            }}
          />
        }
        label="Correct"
        disabled={props.disabled}
        sx={{ mr: 0 }}
      />
      {onDelete && <DeleteButton onClick={onDelete} />}
    </Box>
  )
}
