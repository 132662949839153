import { Dialog, Button } from "@mui/material"

import "../styles/modal.scss"
import "../styles/widget.scss"

function ModalAnnouncementEmailConfirm(props) {
  const { onClose, resendEmail, saveAnnouncement, open } = props

  return (
    <Dialog
      className="modal announcement-email-confirm"
      onClose={() => onClose()}
      open={open}
    >
      <h2>
        <span className="icon paper-plane" /> Resend Announcement Email
      </h2>
      <p>
        You've previously sent out an email to learners with the content of this
        announcement.
      </p>
      <p>Do you want to send an email again with any changes you've made?</p>

      <footer className="flexRow">
        <Button
          onClick={() => {
            saveAnnouncement({ noResend: true })
          }}
          className="button small cancel"
        >
          No, Don't Resend
        </Button>
        <Button onClick={resendEmail} className="button small">
          Yes, Send Email Again
        </Button>
      </footer>
    </Dialog>
  )
}

export default ModalAnnouncementEmailConfirm
