import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Typography,
} from "@mui/material"

import ChecklistColor from "../../img/checklist-color.svg"

import he from "he"

import "../../styles/reports.scss"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import moment from "moment"
import BottomScrollListener from "react-bottom-scroll-listener"
import { getRubricCompliance } from "actions/rubrics"
import { increaseCurrentPage } from "./complianceSlice"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import { Link } from "react-router-dom"
import { resetBackpack } from "../../store/reducers/backpack"

export function RubricComplianceTable(props) {
  const compliance = useAppSelector((state) => state.compliance)
  const entityList = useAppSelector((state) => state.entityList)
  const { filters, data } = compliance

  const dispatch = useAppDispatch()

  const activeRubric = entityList.orgRubrics.data.find(
    (rubric) => Number(rubric.id) === Number(filters.rubric)
  )

  function handleScroll() {
    // If we aren't already fetching more results
    if (!props.compliance.fetching) {
      // And if there are more pages to fetch
      if (props.compliance.currentPage < props.compliance.totalPages - 1) {
        dispatch(increaseCurrentPage())
        dispatch(getRubricCompliance())
      }
    }
  }

  return (
    <BottomScrollListener onBottom={handleScroll}>
      <Box sx={{ borderRadius: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "100%" }}>
                <div
                  className="entityName"
                  style={{ justifyContent: "flex-start" }}
                >
                  <img
                    src={ChecklistColor}
                    alt={"Rubric"}
                    style={{ height: 25, width: 25, marginRight: 10 }}
                  />
                  {he.decode(activeRubric?.name)}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          {data.length === 0 && !compliance.fetching && (
            <div classname="fadeIn">
              <LearningBuddyMessage
                message={
                  <Typography>
                    <strong>Hmmm.</strong> No rubric submissions were found.
                  </Typography>
                }
              />
            </div>
          )}

          <TableBody>
            {data.length > 0 &&
              data.map((row) => {
                return (
                  <TableRow key={row.uid}>
                    <TableCell>
                      <Link
                        onClick={() => {
                          dispatch(resetBackpack())
                        }}
                        to={"/backpack/" + row.uid}
                      >
                        <div className="flexRow">
                          <div
                            className="userInfo flexRow"
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div className="flexRow">
                              <UserLabel
                                firstName={he.decode(row.first_name)}
                                lastName={he.decode(row.last_name)}
                                imagePath={
                                  row.user_picture
                                    ? process.env.REACT_APP_API_URL +
                                      row.user_picture
                                    : null
                                }
                                subgroups={row.user_subgroups}
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell align={"right"}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          width: 400,
                        }}
                      >
                        <strong
                          style={{
                            paddingRight: 25,
                            opacity: 0.6,
                            width: "100%",
                          }}
                        >
                          Completed on{" "}
                          {moment(row.completed_date).format("M/D/YYYY")}
                        </strong>

                        <Box
                          sx={(theme) => ({
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 1,
                            minWidth: 50,
                            height: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          })}
                        >
                          <strong style={{ fontSize: 22 }}>
                            {row.total_score}
                          </strong>
                        </Box>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </Box>
    </BottomScrollListener>
  )
}
