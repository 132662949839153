import { useState } from "react"
import { Box, Card, MenuItem, Select, TextField } from "@mui/material"
import { Formik, Field } from "formik"

import "../../styles/categories.scss"
import "../../styles/modal.scss"

import theme from "@mobilemind/common/src/theme/theme"
import { EventFeedbackFormEntity } from "./types"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useAppDispatch } from "store/hooks"
import { saveFeedbackForm } from "store/reducers/feedbackForms"
import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"
import Loading from "@mobilemind/common/src/components/Loading"
import DragHandleIcon from "@mui/icons-material/DragHandle"

export function FeedbackFormEdit(props: {
  editingForm: EventFeedbackFormEntity
  onSave: () => void
}) {
  const editingForm = props.editingForm
  const dispatch = useAppDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const [highlightFields, setHighlightFields] = useState(false)

  const { onSave } = props
  const { attributes } = editingForm
  const initialValues = {
    field_scale_max: attributes.field_scale_max,
    field_scale_min: attributes.field_scale_min,
    label: attributes.label,
    questions: editingForm.questions,
  }

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        setIsSaving(true)
        await dispatch(saveFeedbackForm({ editingForm, values }))
        onSave()
        setIsSaving(false)
        setHighlightFields(false)
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        let numberArray: number[] = []

        if (
          values.field_scale_max &&
          (values.field_scale_min || values.field_scale_min === 0)
        ) {
          numberArray = Array.from(
            {
              length: Math.abs(
                values.field_scale_max - values.field_scale_min + 1
              ),
            },
            (_, i) => {
              const modifier = Number(values.field_scale_min) ?? 0
              return i + modifier
            }
          )
        }

        const missingRequiredFields =
          values.questions?.some(
            (question) => !question.attributes.field_question_name
          ) || !values.label

        return (
          <>
            {isSaving && (
              <div style={{ position: "absolute", top: 17, right: 20 }}>
                <Loading message="Saving feedback form..." />
              </div>
            )}
            <div
              style={{
                pointerEvents: isSaving ? "none" : "all",
                opacity: isSaving ? 0.5 : 1,
                ...styles.container,
              }}
            >
              <Box component="div" style={styles.requiredIndicator.container}>
                <Field
                  style={{ flex: 1, marginRight: 15 }}
                  as={TextField}
                  label="Form Name"
                  id="label"
                  name="label"
                />

                <div
                  // @ts-ignore
                  style={{
                    opacity: highlightFields && !values.label ? 1 : 0,
                    ...styles.requiredIndicator,
                  }}
                />
              </Box>

              <Field
                style={{ maxWidth: 100, marginRight: 15 }}
                as={TextField}
                onChange={(event: any) => {
                  if (values.field_scale_max) {
                    if (
                      event.target.value >= 0 &&
                      event.target.value < values.field_scale_max
                    ) {
                      setFieldValue(
                        "field_scale_min",
                        Number(event.target.value)
                      )
                    }
                  }
                }}
                type="number"
                label="Scale Min"
                id={"field_scale_min"}
                name={"field_scale_min"}
              />

              <Field
                style={{ maxWidth: 100 }}
                as={TextField}
                onChange={(event: any) => {
                  // Make sure we're between 1 and 10
                  if (event.target.value > 0 && event.target.value <= 10) {
                    // And that we aren't going below one more than the minimum
                    if (
                      values.field_scale_min ||
                      values.field_scale_min === 0
                    ) {
                      if (event.target.value >= values.field_scale_min + 1) {
                        setFieldValue(
                          "field_scale_max",
                          Number(event.target.value)
                        )
                      }
                    }
                  }
                }}
                type="number"
                label="Scale Max"
                id={"field_scale_max"}
                name={"field_scale_max"}
              />

              <span className="icon info" style={styles.iconInfo}>
                <div className="popover-content">
                  <p>
                    All numerical scale questions will share the same scale.
                    However, <strong>labels for the scale</strong> can be
                    customized for each question.
                  </p>
                </div>
              </span>
            </div>
            <div
              style={{
                pointerEvents: isSaving ? "none" : "all",
                opacity: isSaving ? 0.5 : 1,
                ...styles.questionContainer,
              }}
            >
              <DragDropContext
                onDragEnd={(result) => {
                  if (values.questions) {
                    if (!result.destination) {
                      return
                    }

                    setFieldValue(
                      "questions",
                      reorder(
                        values.questions,
                        result.source.index,
                        result.destination.index
                      )
                    )
                  }
                }}
              >
                <Droppable droppableId={"questionList"}>
                  {(provided) => (
                    <ul
                      style={{ listStyleType: "none" }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="questionList"
                    >
                      {values.questions &&
                        values.questions.map((question, index) => {
                          const { field_minimum_label, field_maximum_label } =
                            question.attributes
                          return (
                            <Draggable
                              key={index}
                              draggableId={"question-" + index}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Card style={styles.questionBlock}>
                                    <Box
                                      key={question.id}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: 20,
                                      }}
                                    >
                                      <DragHandleIcon
                                        sx={{
                                          color: "grey.500",
                                          gridRowStart: 2,
                                          marginRight: 3,
                                        }}
                                      />
                                      <Box
                                        style={
                                          styles.requiredIndicator.container
                                        }
                                      >
                                        <Field
                                          style={{
                                            marginBottom: 0,
                                            marginRight: 15,
                                            postMessage: "relative",
                                            zIndex: 3,
                                          }}
                                          required
                                          as={TextField}
                                          value={
                                            question.attributes
                                              .field_question_name
                                          }
                                          label="Question"
                                          id={`questions.${index}.attributes.field_question_name`}
                                          name={`questions.${index}.attributes.field_question_name`}
                                        />
                                        <div
                                          // @ts-ignore
                                          style={{
                                            opacity:
                                              highlightFields &&
                                              !question.attributes
                                                .field_question_name
                                                ? 1
                                                : 0,
                                            ...styles.requiredIndicator,
                                          }}
                                        />
                                      </Box>
                                      <Field
                                        as={Select}
                                        value={question.type}
                                        label="Type"
                                        id={`questions.${index}.type`}
                                        name={`questions.${index}.type`}
                                      >
                                        <MenuItem value="paragraph--likert_scale">
                                          Numerical Scale
                                        </MenuItem>
                                        <MenuItem value="paragraph--open_ended_questions">
                                          Paragraph
                                        </MenuItem>
                                      </Field>
                                    </Box>
                                    {question.type ===
                                      "paragraph--likert_scale" && (
                                      <>
                                        <Box
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Field
                                            style={{
                                              marginRight: 15,
                                              maxWidth: 300,
                                            }}
                                            as={TextField}
                                            value={
                                              question.attributes
                                                .field_minimum_label
                                            }
                                            onChange={(event: any) => {
                                              setFieldValue(
                                                `questions.${index}.attributes.field_minimum_label`,
                                                event.target.value
                                              )
                                            }}
                                            label="Minimum Label"
                                          />
                                          <Field
                                            as={TextField}
                                            style={{ maxWidth: 300 }}
                                            onChange={(event: any) => {
                                              setFieldValue(
                                                `questions.${index}.attributes.field_maximum_label`,
                                                event.target.value
                                              )
                                            }}
                                            value={
                                              question.attributes
                                                .field_maximum_label
                                            }
                                            label="Maximum Label"
                                          />
                                        </Box>

                                        <div style={styles.numberArray}>
                                          {numberArray.map((number, index) => {
                                            return (
                                              <div
                                                style={
                                                  styles.numberArray.number
                                                }
                                              >
                                                {number}
                                              </div>
                                            )
                                          })}
                                        </div>

                                        <div style={styles.minMaxLabels}>
                                          {field_minimum_label ? (
                                            <span
                                              style={styles.minMaxLabels.span}
                                            >
                                              {field_minimum_label}
                                              <div
                                                style={{
                                                  ...styles.minMaxTriangle,
                                                  left: 14,
                                                }}
                                              />
                                            </span>
                                          ) : (
                                            <div />
                                          )}

                                          {field_maximum_label && (
                                            <span
                                              style={styles.minMaxLabels.span}
                                            >
                                              {field_maximum_label}
                                              <div
                                                style={{
                                                  ...styles.minMaxTriangle,
                                                  right: 14,
                                                }}
                                              />
                                            </span>
                                          )}
                                        </div>
                                      </>
                                    )}

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderTop: "1px solid rgba(0,0,0,.15)",
                                        margin: "0px -20px -20px",
                                        padding: 15,
                                      }}
                                    >
                                      <ButtonSmall
                                        onClick={() => {
                                          if (values.questions) {
                                            const existing = [
                                              ...values.questions,
                                            ]
                                            existing.splice(index, 1)
                                            setFieldValue("questions", existing)
                                          }
                                        }}
                                      >
                                        <span className="icon trash" />
                                        <span style={{ color: "#DA3737" }}>
                                          Delete
                                        </span>
                                      </ButtonSmall>
                                      <div style={{ marginBottom: -15 }}>
                                        <SettingSwitch
                                          onChange={() => {
                                            setFieldValue(
                                              `questions.${index}.attributes.field_required`,
                                              !question.attributes
                                                .field_required
                                            )
                                          }}
                                          checked={
                                            question.attributes.field_required
                                          }
                                          helperText={
                                            "Learners cannot submit the form without answering this question."
                                          }
                                          label="Response Required"
                                          name={`questions.${index}.attributes.field_required`}
                                        />
                                      </div>
                                    </div>
                                  </Card>
                                </li>
                              )}
                            </Draggable>
                          )
                        })}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <div
              style={{
                display: isSaving ? "none" : "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {missingRequiredFields && highlightFields && (
                <div
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: 12,
                    marginBottom: 15,
                  }}
                >
                  Please complete all required fields.
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginRight: 20 }}>
                  <ButtonSmall
                    onClick={() => {
                      if (values.questions) {
                        const existing = [...values.questions]
                        existing.push({
                          attributes: {
                            field_minimum_label: "",
                            field_maximum_label: "",
                            field_question_name: "",
                            parent_field_name: "field_questions",
                            field_required: true,
                          },
                          type: "paragraph--likert_scale",
                        })

                        setFieldValue("questions", existing)
                      }
                    }}
                  >
                    <span className="icon add" />
                    <span style={{ textTransform: "uppercase" }}>
                      Add Question
                    </span>
                  </ButtonSmall>
                </div>
                <ButtonLarge
                  onClick={() => {
                    if (missingRequiredFields) {
                      setHighlightFields(true)
                    } else {
                      submitForm()
                    }
                  }}
                >
                  <span>Save Form</span>
                </ButtonLarge>
              </div>
            </div>
          </>
        )
      }}
    </Formik>
  )
}

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    marginTop: 25,
  },
  listItem: {
    padding: 2,
    paddingRight: 3,
    paddingLeft: 3,
    display: "flex",
    alignItems: "center",
    marginLeft: -2,
    marginRight: -2,
    marginBottom: 2,
    borderRadius: 12,
    backgroundColor: "white",
    transition: ".3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(241 241 241)",
    },
  },
  questionBlock: {
    marginBottom: 20,
    marginTop: 20,
    borderRadius: 12,
    padding: 20,
  },
  iconInfo: {
    width: 25,
    height: 25,
    marginLeft: 15,
    cursor: "pointer",
  },
  questionContainer: {
    backgroundColor: "#ebebeb",
    padding: "10px 25px",
    marginLeft: -20,
    marginRight: -20,
    overflowY: "auto" as "auto",
    maxHeight: "70vh",
    marginBottom: 15,
    minHeight: 350,
  },
  minMaxTriangle: {
    width: 0,
    height: 0,
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderBottom: "6px solid rgba(0,0,0,1)",
    position: "absolute" as "absolute",
    top: -6,
  },
  requiredIndicator: {
    fontSize: 12,
    transition: ".15s",
    position: "absolute" as "absolute",
    top: -1,
    left: -1,
    right: -1,
    borderRadius: 12,
    border: "3px dotted red",
    pointerEvents: "none" as "none",
    height: 52,
    container: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column" as "column",
      flex: 1,
      position: "relative" as "relative",
      marginRight: 15,
    },
  },
  minMaxLabels: {
    position: "relative" as "relative",
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    fontSize: 12,
    color: "white",
    marginTop: -40,
    paddingBottom: 10,
    span: {
      backgroundColor: "black",
      borderRadius: 8,
      display: "flex",
      fontSize: 12,
      justifyContent: "center",
      alignItems: "center",
      padding: "5px 10px",
      minWidth: 20,
    },
  },
  numberArray: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 40,
    paddingBottom: 10,
    number: {
      borderRadius: 40,
      border: "1px solid " + theme.palette.secondary.main,
      width: 40,
      height: 40,
      display: "flex",
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      alignItems: "center",
    },
  },
}
