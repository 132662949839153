import { useAppDispatch, useAppSelector } from "store/hooks"
import { Box, Dialog, FormControl } from "@mui/material"
import { Formik, Form, Field } from "formik"
import SettingsIcon from "../../img/settings-color.svg"
import School from "../../img/school-original.svg"
import { TextField } from "@mui/material"
import { CategoryIcon } from "@mobilemind/common/src/components/CategoryIcon"
import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"
import theme from "@mobilemind/common/src/theme/theme"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { saveOrganizationSettings } from "actions"
import { useState } from "react"
import Loading from "@mobilemind/common/src/components/Loading"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { switchActiveMembership } from "@mobilemind/common/src/actions/user"
import { Select } from "@mui/material"
import { MenuItem } from "@mui/material"

type ExcludedItem = {
  name: string
  id: string
  uuid: string
  lp_image?: string
  partner_image?: string
}

export const CollectionOrgEdit = (props: any) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { activeOrg, onClose } = props

  const { collectionMembers } = useAppSelector((state) => state.dashboard)

  const selectedCollectionMember = collectionMembers.data.find(
    (member) => activeOrg && member.uuid === activeOrg.customer_rep.uuid
  )

  const [selectedMember, setSelectedMember] = useState<any>(
    selectedCollectionMember
  )

  function SectionHeader(props: { label: string }) {
    return (
      <div
        style={{
          marginBottom: 20,
          marginTop: -15,
          marginLeft: -15,
          marginRight: -15,
          borderRadius: "12px 12px 0 0",
          padding: 6,
          fontSize: 14,
          backgroundColor: theme.palette.secondary.main,
          textAlign: "center",
        }}
      >
        <strong>{props.label}</strong>
      </div>
    )
  }

  let customerRepUUID = activeOrg && activeOrg.customer_rep.uuid
  if (selectedMember) {
    customerRepUUID = selectedMember.uuid
  }
  return (
    <Dialog
      id="edit-org-modal"
      className="modal"
      open={activeOrg ? true : false}
      onClose={onClose}
    >
      <h2 style={styles.h2}>
        {activeOrg && activeOrg.uuid ? (
          <>
            <img src={SettingsIcon} alt={"Settings"} style={styles.headerImg} />
            Organization Settings
          </>
        ) : (
          <>
            <img src={School} alt={"School"} style={styles.headerImg} />
            New Organization
          </>
        )}
      </h2>
      <div style={styles.container} className="modal-container">
        {activeOrg && (
          <Formik
            initialValues={activeOrg}
            onSubmit={async (values) => {
              setIsLoading(true)
              await dispatch(saveOrganizationSettings({ activeOrg: values }))
              onClose()
              setIsLoading(false)
            }}
          >
            {({ setFieldValue, values, submitForm }) => {
              return (
                <Form style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 25, flex: 1 }}>
                      <Box component="div" sx={styles.panel}>
                        <SectionHeader label="Organization Info" />

                        <FormControl>
                          <Field
                            as={TextField}
                            {...props}
                            name="label"
                            label="Name"
                          />
                        </FormControl>
                        <FormControl>
                          <Field
                            as={TextField}
                            type="number"
                            {...props}
                            name="max_total_members"
                            label="Max Total Members"
                          />
                        </FormControl>
                        <FormControl>
                          <Field
                            as={TextField}
                            type="number"
                            {...props}
                            name="max_total_premium"
                            label="Max Total Premium"
                          />
                        </FormControl>

                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <FormControl>
                            <Field
                              value={values.org_address.address_line1}
                              as={TextField}
                              {...props}
                              name="org_address.address_line1"
                              label="Address Line 1"
                            />
                          </FormControl>

                          <FormControl>
                            <Field
                              value={values.org_address.address_line2}
                              as={TextField}
                              {...props}
                              name="org_address.address_line2"
                              label="Address Line 2"
                            />
                          </FormControl>
                          <div style={{ display: "flex", marginBottom: -20 }}>
                            <FormControl style={{ flex: 1, marginRight: 15 }}>
                              <Field
                                value={values.org_address.city}
                                as={TextField}
                                {...props}
                                name="org_address.city"
                                label="City"
                              />
                            </FormControl>
                            <FormControl style={{ width: 80, marginRight: 15 }}>
                              <Field
                                value={values.org_address.state}
                                as={TextField}
                                {...props}
                                name="org_address.state"
                                label="State"
                              />
                            </FormControl>
                            <FormControl style={{ width: 80 }}>
                              <Field
                                value={values.org_address.zip}
                                as={TextField}
                                {...props}
                                name="org_address.zip"
                                label="ZIP"
                              />
                            </FormControl>
                          </div>
                        </div>

                        <SettingSwitch
                          checked={values.org_status}
                          name="org_status"
                          helperText={""}
                          label="Active"
                          onChange={(checked) => {
                            setFieldValue("org_status", checked)
                          }}
                        />
                      </Box>

                      <Box component="div" sx={styles.panel}>
                        <SectionHeader label="KnowBe4 Integration" />
                        <FormControl
                          sx={{
                            "& .MuiInputBase-root textarea": {
                              fontSize: 12,
                            },
                          }}
                        >
                          <Field
                            multiline
                            as={TextField}
                            {...props}
                            name="knowbe4"
                            label="KnowBe4 API Key"
                          />
                        </FormControl>
                      </Box>
                    </div>

                    <div style={{ flex: 1 }}>
                      <Box component="div" sx={styles.panel}>
                        <SectionHeader label="Content Settings" />

                        <SettingSwitch
                          checked={values.org_external_pd_access}
                          name="org_external_pd_access"
                          helperText={
                            "Allows users to create External PD events, request credit, and review requests."
                          }
                          label="External PD Access"
                          onChange={(checked) => {
                            setFieldValue("org_external_pd_access", checked)
                          }}
                        />

                        <SettingSwitch
                          checked={values.org_hide_mm_content}
                          name="org_hide_mm_content"
                          helperText={
                            "Disables all Courses, Badges, and Learning Paths created by MobileMind."
                          }
                          label="Hide MobileMind Content"
                          onChange={(checked) => {
                            setFieldValue("org_hide_mm_content", checked)
                          }}
                        />

                        <SettingSwitch
                          checked={values.org_hq}
                          name="org_hq"
                          helperText={
                            "Enables content creation and management in HQ."
                          }
                          label="HQ Access"
                          onChange={(checked) => {
                            setFieldValue("org_hq", checked)
                          }}
                        />
                      </Box>
                      {activeOrg.uuid && (
                        <>
                          <Box
                            component="div"
                            style={{ borderRadius: 12 }}
                            sx={{ ...styles.panel }}
                          >
                            <SectionHeader label="Excluded Categories" />
                            <ul style={styles.wrapList}>
                              {values.excluded_cat_list.map(
                                (cat: ExcludedItem) => {
                                  return (
                                    <li style={styles.excludedRow}>
                                      {cat.lp_image && (
                                        <CategoryIcon
                                          size="small"
                                          style={{ marginRight: 5 }}
                                          src={
                                            process.env.REACT_APP_API_URL +
                                            cat.lp_image
                                          }
                                        />
                                      )}
                                      {cat.name}
                                    </li>
                                  )
                                }
                              )}
                              {values.excluded_cat_list.length === 0 && (
                                <li style={styles.excludedRow}>
                                  No Excluded Categories.
                                </li>
                              )}
                            </ul>
                            {activeOrg.uuid && (
                              <footer
                                style={{
                                  fontSize: 12,
                                  justifyContent: "center",
                                  marginTop: 0,
                                  padding: 10,
                                  marginBottom: -15,
                                }}
                              >
                                <ButtonSmall
                                  onClick={() => {
                                    setIsLoading(true)
                                    dispatch(
                                      switchActiveMembership(
                                        activeOrg.uuid,
                                        process.env.REACT_APP_HQ_URL
                                      )
                                    )
                                  }}
                                >
                                  Jump to Org to Edit
                                </ButtonSmall>
                              </footer>
                            )}
                          </Box>

                          <Box component="div" sx={{ ...styles.panel }}>
                            <SectionHeader label="Excluded Learning Paths" />
                            <ul style={styles.wrapList}>
                              {values.excluded_lp_list.map(
                                (lp: ExcludedItem) => {
                                  return (
                                    <li style={styles.excludedRow}>
                                      {lp.lp_image && (
                                        <div
                                          style={{
                                            boxShadow: theme.shadows[3],
                                            width: 20,
                                            height: 20,
                                            marginRight: 5,
                                            borderRadius: 6,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            backgroundImage:
                                              "url(" +
                                              process.env.REACT_APP_API_URL +
                                              lp.lp_image +
                                              ")",
                                          }}
                                        />
                                      )}
                                      {lp.name}
                                    </li>
                                  )
                                }
                              )}
                              {values.excluded_lp_list.length === 0 && (
                                <li style={styles.excludedRow}>
                                  No Excluded Learning Paths.
                                </li>
                              )}
                            </ul>
                          </Box>

                          <Box component="div" sx={styles.panel}>
                            <SectionHeader label="Partner Access" />
                            <ul style={styles.wrapList}>
                              {values.partner_list.map(
                                (partner: ExcludedItem) => {
                                  return (
                                    <li style={styles.excludedRow}>
                                      {partner.partner_image && (
                                        <div
                                          style={{
                                            boxShadow: theme.shadows[3],
                                            width: 20,
                                            height: 20,
                                            marginRight: 5,
                                            borderRadius: 6,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            backgroundImage:
                                              "url(" +
                                              process.env.REACT_APP_API_URL +
                                              partner.partner_image +
                                              ")",
                                          }}
                                        />
                                      )}
                                      {partner.name}
                                    </li>
                                  )
                                }
                              )}
                              {values.partner_list.length === 0 && (
                                <li style={styles.excludedRow}>
                                  No Partner content added.
                                </li>
                              )}
                            </ul>
                            {activeOrg.uuid && (
                              <footer
                                style={{
                                  fontSize: 12,
                                  justifyContent: "center",
                                  marginTop: 0,
                                  padding: 10,
                                  marginBottom: -15,
                                }}
                              >
                                <ButtonSmall
                                  onClick={() => {
                                    setIsLoading(true)
                                    dispatch(
                                      switchActiveMembership(
                                        activeOrg.uuid,
                                        process.env.REACT_APP_HQ_URL
                                      )
                                    )
                                  }}
                                >
                                  Jump to Org to Edit
                                </ButtonSmall>
                              </footer>
                            )}
                          </Box>
                        </>
                      )}
                      <Box
                        component="div"
                        sx={{ ...styles.panel, ...styles.panel.editable }}
                      >
                        <SectionHeader label="Customer Success Rep" />

                        <Select
                          style={{ width: "100%" }}
                          value={customerRepUUID ?? "None"}
                          onChange={(event: any) => {
                            const selected = collectionMembers.data.find(
                              (member) => member.uuid === event.target.value
                            )
                            const payload = {
                              email: selected.email,
                              uuid: selected.uuid,
                              first_name: selected.field_first_name,
                              last_name: selected.field_last_name,
                              user_image: selected.user_picture,
                            }
                            setSelectedMember(payload)
                            setFieldValue("customer_rep", payload)
                          }}
                        >
                          <MenuItem value={"None"}>None</MenuItem>

                          {collectionMembers.data.map((member: any) => {
                            return (
                              <MenuItem value={member.uuid}>
                                {member.field_first_name}{" "}
                                {member.field_last_name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </Box>
                    </div>
                  </div>
                  <footer style={styles.footer}>
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <ButtonLarge
                        onClick={() => {
                          setIsLoading(true)
                          submitForm()
                        }}
                      >
                        {activeOrg.uuid ? (
                          <> Save Settings</>
                        ) : (
                          <>Save Organization</>
                        )}
                      </ButtonLarge>
                    )}
                  </footer>
                </Form>
              )
            }}
          </Formik>
        )}
      </div>
    </Dialog>
  )
}

const styles = {
  excludedRow: {
    display: "flex",
    fontSize: 12,
    alignItems: "center",
    marginRight: 15,
    marginBottom: 5,
  },
  h2: {
    backgroundColor: "#ebebeb",
    justifyContent: "center",
    padding: 25,
    marginBottom: -15,
    marginTop: -20,
    marginLeft: -20,
    marginRight: -20,
  },
  headerImg: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  container: {
    backgroundColor: "#ebebeb",
    marginLeft: -20,
    marginRight: -20,
    marginBottom: -20,
    padding: 20,
    overflow: "auto" as "auto",
  },
  wrapList: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    marginBottom: 0,
  },
  panel: {
    borderRadius: 1,
    backgroundColor: "white",
    padding: 3,
    marginBottom: 4,
    boxShadow: theme.shadows[2],
    transition: ".4s",
    editable: {
      cursor: "pointer" as "pointer",
      "&:hover": {
        transform: "scale(1.04)",
      },
    },
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}
