import { SyntheticEvent, useState } from "react"
import {
  Button,
  Tab,
  TextField,
  Typography,
  Box,
  Stack,
  Select,
  MenuItem,
} from "@mui/material"

import moment from "moment"
import { SessionImportModal } from "./SessionImportModal"

import plusImg from "../../../../img/plus.svg"
import minimizeImg from "../../../../img/minimize.svg"
import maximizeImg from "../../../../img/maximize.svg"
import ImportIcon from "../../../../img/import.svg"
import CellsIcon from "../../../../img/cells.svg"
import LocationsIcon from "../../../../img/location-color.svg"
import RoomsIcon from "../../../../img/room.svg"
import CalendarIcon from "../../../../img/calendar.svg"
import ListIcon from "../../../../img/list.svg"
import SearchIcon from "../../../../img/search.svg"

import { ConferenceScheduleViewSelectTabs } from "@mobilemind/common/src/components/ConferenceScheduleViewSelectTabs"

import ArrangeRoomsModal from "./ArrangeRoomsModal"
import { TabsCapsule } from "@mobilemind/common/src/components/tabsCapsule/TabsCapsule"

import { updateListViewFilters } from "../activeConferenceSlice"
import { useAppDispatch } from "store/hooks"
import {
  MobileMindLocationLocationBase,
  MobileMindRoomRoomBase,
} from "@mobilemind/common/src/types/jsonapi"

type ToolbarProps = {
  isMultiDay: boolean
  currentDay: number
  currentDate: string
  // We'll type this out later, hoo boy
  duration: number
  conference: any
  canAddSession: boolean
  currentTab: string
  rooms: any[]
  selectedLocation: MobileMindLocationLocationBase
  onChangeLocation: (locationId: string) => void
  setCurrentTab: (tab: string) => void
  handleDayChange: (event: SyntheticEvent, day: number) => void
  handleViewChange: (tab: string) => void
  setIsFullScreen: (isFullScreen: boolean) => void
  startConferenceEvent: () => void
  refreshCalendar: () => void
}

export const ConferenceScheduleToolbar = (props: ToolbarProps) => {
  const {
    isMultiDay,
    currentDay,
    currentDate,
    duration,
    conference,
    canAddSession,
    currentTab,
    rooms,
    selectedLocation,
    onChangeLocation,
    setCurrentTab,
    handleDayChange,
    handleViewChange,
    setIsFullScreen,
    startConferenceEvent,
    refreshCalendar,
  } = props

  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [isRoomsModalOpen, setIsRoomsModalOpen] = useState(false)
  const dispatch = useAppDispatch()

  const availableRoomsForFilter = selectedLocation
    ? rooms.filter(
        (room) => room.relationships.field_location.data.id === selectedLocation
      )
    : rooms

  return (
    <Box sx={{ border: "1px solid transparent" }}>
      {conference.locations?.length > 0 && (
        <>
          <ArrangeRoomsModal
            rooms={rooms}
            selectedLocation={selectedLocation}
            open={isRoomsModalOpen}
            conference={conference}
            refresh={refreshCalendar}
            onClose={() => setIsRoomsModalOpen(false)}
          />
        </>
      )}
      {isImportModalOpen && (
        <SessionImportModal
          // @ts-ignore
          selectedLocation={selectedLocation}
          currentDate={moment(currentDate).format("YYYY-MM-DD")}
          open={isImportModalOpen}
          onClose={() => setIsImportModalOpen(false)}
        />
      )}{" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          position: "absolute",
          left: 20,
          right: 0,
          top: 6,
        }}
      >
        <ConferenceScheduleViewSelectTabs
          value={currentTab}
          onChange={(event, value) => {
            handleViewChange(value)
          }}
        >
          <Tab
            value="calendar"
            icon={<img src={CalendarIcon} width={20} alt="Calendar View" />}
          />
          <Tab
            value="list"
            icon={<img src={ListIcon} width={20} alt="List View" />}
          />
        </ConferenceScheduleViewSelectTabs>

        <Button
          size="small"
          onClick={() => setIsFullScreen(!conference.isFullScreen)}
        >
          <img
            width={20}
            alt={conference.isFullScreen ? "Minimize" : "Full Screen"}
            src={conference.isFullScreen ? minimizeImg : maximizeImg}
          />
        </Button>
      </Box>
      <Box sx={{ justifyContent: "center", display: "flex", marginBottom: 3 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
          {moment(currentDate).format("dddd, MMMM Do YYYY")}
        </Typography>
      </Box>
      {isMultiDay && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 3,
          }}
        >
          <TabsCapsule value={currentDay} onChange={handleDayChange}>
            {Array.from(Array(duration).keys()).map((item) => {
              const dayLabel = moment(conference.startDate)
                .add(item, "days")
                .format("dddd")

              return <Tab value={item} label={dayLabel} />
            })}
          </TabsCapsule>
        </Box>
      )}
      <Stack
        direction="row"
        sx={{
          flexWrap: "wrap",
          marginTop: 4,
          paddingTop: 2,
          marginBottom: -8,
          backgroundColor: "white",
          position: "relative",
          zIndex: 3,
        }}
        spacing={2}
      >
        <Box style={{ marginRight: 15, display: "flex", alignItems: "center" }}>
          <img
            src={SearchIcon}
            width={15}
            style={{ position: "relative", top: -8, marginRight: 10 }}
            alt="Search"
          />
          <TextField
            value={conference.listViewFilters.searchQuery}
            onChange={(event) => {
              if (event.target.value && event.target.value.length) {
                setCurrentTab("list")
              }
              dispatch(
                updateListViewFilters({
                  field: "searchQuery",
                  value: event.target.value,
                })
              )
            }}
            sx={{ maxWidth: 300 }}
            name="sessionSearch"
            placeholder="Search for Sessions"
            margin="normal"
            variant="standard"
          />
        </Box>
        {conference.locations.length > 1 && (
          <Box sx={{ display: "flex" }}>
            <img
              src={LocationsIcon}
              style={{ position: "relative", top: -8, marginRight: 10 }}
              width={15}
              alt="Search"
            />
            <Box>
              <Select
                variant="standard"
                labelId="label-location-select"
                id="location-select"
                value={selectedLocation}
                sx={{ minWidth: 175 }}
                onChange={(event: any) => {
                  onChangeLocation(event.target.value)
                  dispatch(
                    updateListViewFilters({
                      field: "roomId",
                      value: "all",
                    })
                  )
                }}
              >
                {conference.locations.map(
                  (location: MobileMindLocationLocationBase) => {
                    return (
                      <MenuItem key={location.id} value={location.id}>
                        {location.attributes.name}
                      </MenuItem>
                    )
                  }
                )}
              </Select>
            </Box>
          </Box>
        )}

        {currentTab === "list" && (
          <Box sx={{ display: "flex", paddingLeft: 2 }}>
            <img
              src={RoomsIcon}
              style={{ position: "relative", top: -8, marginRight: 10 }}
              width={25}
              alt="Search"
            />
            <Box>
              <Select
                variant="standard"
                labelId="label-roomId-select"
                id="roomId-select"
                value={conference.listViewFilters.roomId}
                sx={{ minWidth: 175 }}
                onChange={(event: any) => {
                  dispatch(
                    updateListViewFilters({
                      field: "roomId",
                      value: event.target.value,
                    })
                  )
                }}
              >
                <MenuItem key={"all"} value={"all"}>
                  All Rooms
                </MenuItem>
                {availableRoomsForFilter.map((room: MobileMindRoomRoomBase) => {
                  return (
                    <MenuItem key={room.id} value={room.id}>
                      {room.attributes.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </Box>
        )}

        {canAddSession && (
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            {currentTab !== "list" && conference.locations?.length > 0 && (
              <Button
                size="small"
                sx={{ lineHeight: 1 }}
                startIcon={<img alt={"Arrange Rooms"} src={CellsIcon} />}
                onClick={() => {
                  setIsRoomsModalOpen(true)
                }}
              >
                Arrange Rooms
              </Button>
            )}
            <Button
              size="small"
              sx={{ lineHeight: 1 }}
              onClick={() => setIsImportModalOpen(true)}
              startIcon={<img alt={"Import Session"} src={ImportIcon} />}
            >
              Import Session
            </Button>

            <Button
              size="small"
              sx={{ lineHeight: 1 }}
              onClick={startConferenceEvent}
              startIcon={<img alt={"Add Session"} src={plusImg} />}
            >
              Add Session
            </Button>
          </Box>
        )}
      </Stack>
    </Box>
  )
}
