import { Component } from "react"
import { connect } from "react-redux"
import { getOrgCourses } from "../../actions/courses"

import SidebarTemplates from "../../components/SidebarTemplates"
import { getCategories } from "../../store/reducers/categories"
import {
  increasePage,
  setCurrentPage,
  setCurrentTab,
  setSortOrder,
} from "../entityList/entityListSlice"
import EntityList from "./EntityList"
import SidebarEntityList from "./SidebarEntityList"

import BottomScrollListener from "react-bottom-scroll-listener"
import { getOrganizations, getSubGroups } from "../../store/reducers/session"
import { getJobTitles } from "../jobTitles/jobTitlesSlice"

import Loading from "@mobilemind/common/src/components/Loading"
import { Tab, Tabs } from "@mui/material"
import ErrorBoundary from "../../components/ErrorBoundary"

const mapStateToProps = ({ session, jobTitles, categories, entityList }) => {
  let filters, fetched
  if (entityList.orgCourses.currentTab === "Courses") {
    filters = entityList.orgCourses.filters
    fetched = entityList.orgCourses.fetched
  } else if (entityList.orgCourses.currentTab === "Templates") {
    filters = entityList.orgCourses.templates.filters
    fetched = entityList.orgCourses.templates.fetched
  }

  return {
    currentTab: entityList.orgCourses.currentTab,
    orgCourses: entityList.orgCourses,
    filters,
    categories,
    entityList,
    session,
    jobTitles,
    fetched,
  }
}

const mapDispatchToProps = {
  getOrgCourses,
  getCategories,
  setCurrentPage,
  getSubGroups,
  getOrganizations,
  getJobTitles,
  increasePage,
  setCurrentTab,
  setSortOrder,
}

class CourseListContainer extends Component {
  componentDidMount() {
    const { session, jobTitles } = this.props
    this.props.setCurrentPage("Courses")

    !this.props.orgCourses.fetched &&
      this.props.getOrgCourses(this.props.filters)
    !this.props.categories.fetched && this.props.getCategories()

    !session.subGroups.fetched && this.props.getSubGroups()

    session.isSiteAdmin &&
      !session.organizations.fetched &&
      this.props.getOrganizations()
    !jobTitles.fetched && this.props.getJobTitles()
  }

  handleTabChange = (event, newValue) => {
    this.props.setCurrentTab({ page: "orgCourses", tab: newValue })

    if (newValue === "Templates") {
      this.props.getOrgCourses(this.props.filters, true)
    }
  }

  handleScroll = () => {
    const { orgCourses } = this.props.entityList

    if (orgCourses.currentTab === "Templates") {
      const { currentPage, totalPages } = orgCourses.templates

      if (currentPage + 1 < totalPages) {
        this.props.increasePage({ type: "templates" })
        setTimeout(() => {
          this.props.getOrgCourses(this.props.filters, true)
        }, 450)
      }
    } else {
      const { currentPage, totalPages } = orgCourses

      if (currentPage + 1 < totalPages) {
        this.props.increasePage({ type: "orgCourses" })
        setTimeout(() => {
          this.props.getOrgCourses(this.props.filters)
        }, 450)
      }
    }
  }

  render() {
    const {
      currentTab,
      session,
      entityList,
      categories,
      jobTitles,
      getOrgCourses,
      filters,
    } = this.props
    let iconClass

    if (currentTab === "Courses") {
      iconClass = "icon laptop"
    } else if (currentTab === "Templates") {
      iconClass = "icon templates"
    }

    const loadingMoreMessage =
      "Getting " +
      (currentTab === "Templates" ? "community courses..." : "more courses...")
    const props = {
      iconClass,
      createPath: "/create/courses/new",
      fetched: this.props.fetched,
      filters,
      total:
        currentTab === "Templates"
          ? entityList.orgCourses.templates.totalRecords
          : entityList.orgCourses.totalRecords,
      setSortOrder: this.props.setSortOrder,
      isSiteAdmin: session.isSiteAdmin,
      entityType: "Course",
      currentTab,
      session,
      selectable: true,
    }

    const sidebarProps = {
      type: "Courses",
      filters,
      session,
      entityList,
      categories,
      subGroups: session.subGroups,
      organizations: session.organizations,
      jobTitles,
      fetchData: getOrgCourses,
      currentTab,
      hasTabs: true,
    }

    return (
      <ErrorBoundary>
        <BottomScrollListener onBottom={this.handleScroll}>
          <div id="tabs-course-list">
            <Tabs value={currentTab} onChange={this.handleTabChange}>
              <Tab value="Courses" label="Your Courses" />
              <Tab value="Templates" label="Community" />
            </Tabs>
          </div>

          {currentTab === "Courses" && <SidebarEntityList {...sidebarProps} />}
          {currentTab === "Templates" && <SidebarTemplates {...sidebarProps} />}

          <EntityList {...props} />
          {(entityList.orgCourses.isFetchingMore ||
            entityList.orgCourses.templates.isFetchingMore) && (
            <div className="loadingMoreContainer">
              <Loading message={loadingMoreMessage} />
            </div>
          )}
        </BottomScrollListener>
      </ErrorBoundary>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseListContainer)
