import { SkillLevel, SubmissionType } from "@mobilemind/common/src/types/course"
import yup from "@mobilemind/common/src/utility/yup"

export type CreateCourseGenieFormValues = {
  level: SkillLevel
  submissionType: SubmissionType
}

export const createCourseGenieSchema: yup.ObjectSchema<CreateCourseGenieFormValues> =
  yup.object({
    level: yup.mixed<SkillLevel>().required().default(SkillLevel.Basic),
    submissionType: yup
      .mixed<SubmissionType>()
      .required()
      .default(SubmissionType.MultipleChoice),
  })
