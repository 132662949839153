import { SkillLevelButtonGroup } from "@mobilemind/common/src/components/SkillLevelButtonGroup"
import { FormControl, Typography, styled } from "@mui/material"
import { StyledProps } from "@mui/styles"
import { CourseSubmissionTypeSelect } from "features/courseForm/components/CourseSubmissionTypeSelect"
import { useFormikContext } from "formik"
import { CreateCourseGenieFormValues } from "../common/createCourseGenieSchema"

export const CreateCourseGenieReviewFields = styled(
  (props: Partial<StyledProps>) => {
    const { setFieldValue, values } =
      useFormikContext<CreateCourseGenieFormValues>()
    return (
      <div className={props.className}>
        <CourseSubmissionTypeSelect required={true} />
        <FormControl>
          <Typography variant="caption">Skill Level</Typography>
          <SkillLevelButtonGroup
            value={values.level}
            onChange={(event, value) => setFieldValue("level", value)}
          />
        </FormControl>
      </div>
    )
  }
)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr max-content",
  gap: theme.spacing(2),
}))
