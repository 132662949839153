import {
  YouTubeSearchItem,
  YouTubeSearchResponse,
} from "@mobilemind/genie-api/src/types"
import axios from "axios"
import debouncePromise from "debounce-promise"
import { useCallback, useContext, useRef, useState } from "react"
import { genieApi } from "../common/genieApi"
import { CreateCourseGenieStep } from "../common/types"
import { CreateCourseGenieContext } from "./CreateCourseGenieContext"
import {
  CreateCourseGenieSearchForm,
  CreateCourseGenieSearchFormValues,
} from "./CreateCourseGenieSearchForm"
import { CreateCourseGenieSearchResults } from "./CreateCourseGenieSearchResults"
import { CreateCourseGenieSearchReview } from "./CreateCourseGenieSearchReview"

export const CreateCourseGenieSearch = () => {
  const controllerRef = useRef<AbortController>()

  const { currentStep, setCurrentStep } = useContext(CreateCourseGenieContext)

  const [search, setSearch] = useState(
    process.env.REACT_APP_CREATE_COURSE_GENIE_SEARCH_TEXT ?? ""
  )

  const [searchResults, setSearchResults] =
    useState<YouTubeSearchResponse | null>(null)

  const [selectedVideoId, setSelectedVideoId] = useState<string | null>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getYouTubeRecommendations = useCallback(
    debouncePromise(async (q: string, pageToken?: string | null) => {
      controllerRef.current?.abort()
      controllerRef.current = new AbortController()
      setSearch(q)
      const nextSearchResults = await genieApi
        .get<YouTubeSearchResponse>("/recommend", {
          params: { q, pageToken },
          signal: controllerRef.current.signal,
        })
        .then((response) => response.data)
        .catch((err) => {
          if (axios.isCancel(err)) {
            return null
          } else {
            throw err
          }
        })
      if (nextSearchResults) {
        if (searchResults) {
          setSearchResults({
            ...nextSearchResults,
            items: [...searchResults.items, ...nextSearchResults.items],
          })
        } else {
          setSearchResults(nextSearchResults)
        }
      }
    }),
    [searchResults]
  )

  if (currentStep === CreateCourseGenieStep.Search) {
    return (
      <CreateCourseGenieSearchForm
        initialValues={{ search }}
        onSubmit={async (values: CreateCourseGenieSearchFormValues) => {
          await getYouTubeRecommendations(values.search)
          setCurrentStep(CreateCourseGenieStep.SearchResults)
        }}
        onBack={() => {
          setCurrentStep(CreateCourseGenieStep.Start)
        }}
      />
    )
  } else if (
    currentStep === CreateCourseGenieStep.SearchResults &&
    searchResults
  ) {
    return (
      <CreateCourseGenieSearchResults
        results={searchResults}
        onBack={() => {
          // Stop current axios request, if necessary
          controllerRef.current?.abort()
          setCurrentStep(CreateCourseGenieStep.Search)
          setSearchResults(null)
        }}
        onClickMore={async () => {
          await getYouTubeRecommendations(search, searchResults.nextPageToken)
        }}
        onSelect={(item: YouTubeSearchItem) => {
          setCurrentStep(CreateCourseGenieStep.SearchReview)
          setSelectedVideoId(item.id)
        }}
      />
    )
  } else if (
    currentStep === CreateCourseGenieStep.SearchReview &&
    selectedVideoId
  ) {
    return (
      <CreateCourseGenieSearchReview
        videoId={selectedVideoId}
        onBack={() => {
          setCurrentStep(CreateCourseGenieStep.SearchResults)
          setSelectedVideoId(null)
        }}
      />
    )
  }
  return null
}
