import { useFormikContext } from "formik"
import { BackButton } from "./BackButton"
import { CourseFormButtons } from "./CourseFormButtons"
import { CourseTemplateView } from "./CourseTemplateView"
import { CourseFormStep } from "./CourseFormStepper"
import { CourseFormValues } from "../types"
import { CourseFormBody } from "./CourseFormBody"

export const CourseFormReview = () => {
  const { values } = useFormikContext<CourseFormValues>()
  return (
    <>
      <CourseTemplateView course={values} />
      <CourseFormBody>
        <CourseFormButtons>
          <BackButton step={CourseFormStep.Settings} />
        </CourseFormButtons>
      </CourseFormBody>
    </>
  )
}
