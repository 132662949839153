import { styled } from "@mui/material"
import { StyledProps } from "@mui/styles"
import { PropsWithChildren } from "react"
import { ReactComponent as GenieIcon } from "../../../img/genie.svg"

type GenieMessageProps = PropsWithChildren<Partial<StyledProps>> & {
  align?: "left" | "center"
  size?: "small" | "medium"
}

export const GenieMessage = styled((props: GenieMessageProps) => {
  return (
    <div className={props.className}>
      <GenieIcon />
      <div className="message">{props.children}</div>
    </div>
  )
})(({ theme, align = "center", size = "medium" }) => {
  const dimension = size === "small" ? 30 : 45
  return {
    width: "100%",
    maxWidth: theme.breakpoints.values.sm,
    backgroundColor: theme.palette.secondary.light,
    marginInline: "auto",
    marginTop: `${dimension / 2}px`,
    marginBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    textAlign: align === "left" ? "left" : "center",
    "&>svg": {
      width: dimension,
      height: dimension,
      marginTop: `-${dimension / 2}px`,
      marginInline: theme.spacing(4),
    },
    "&>.message": {
      ...(size === "small"
        ? {
            padding: theme.spacing(2),
            marginTop: theme.spacing(-1),
          }
        : {
            padding: theme.spacing(4),
            marginTop: theme.spacing(-2),
          }),
    },
  }
})
