import Values from "values.js"

import theme from "@mobilemind/common/src/theme/theme"

const primary = new Values(theme.palette.primary.main)
const secondary = new Values(theme.palette.secondary.main)

const appointmentColors = {
  conference: primary.shade(30).hexString(),
  external: secondary.shade(35).hexString(),
  event: primary.hexString(),
}
export default appointmentColors
