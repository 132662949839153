import { Dialog } from "@mui/material"
import { PartnerGroupSelect } from "./PartnerGroupSelect"
import { RootState } from "../../store/types"

import theme from "@mobilemind/common/src/theme/theme"
import pluralize from "pluralize"
import Extension from "../../img/extension.svg"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import CheckIcon from "../../img/check.svg"
import Diamond from "../../img/diamond.svg"
import Free from "../../img/free.svg"
import Eject from "../../img/eject-blue.svg"
import Lock from "../../img/lock.svg"
import Content from "../../img/content.svg"
import HubspotForm from "react-hubspot-form"

import { PartnerSingleCarousel } from "./PartnerSingleCarousel"
import { MarketplaceItem } from "./types"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import Loading from "@mobilemind/common/src/components/Loading"
import { useState } from "react"
import {
  removePartnerFromOrganization,
  removePartnerFromGroups,
} from "../../actions/partners"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { openSnackbar } from "../snackbar/snackbarSlice"
import { SubGroup } from "@mobilemind/common/src/types/groups"
import Mail from "../../img/mail.svg"
import { getMarketplaceItems } from "store/reducers/marketplace"
import { getSubGroups } from "store/reducers/session"

type ModalProps = {
  open: boolean
  partner: MarketplaceItem | null
  subGroupsWithPartner?: SubGroup[]
  onClose: () => void
  isPreview?: boolean
}

/**
 * Renders a modal component for displaying partner information.
 * @param props - The modal props including partner, open state, and onClose callback.
 * @returns The PartnerSingleModal component.
 */
export const PartnerSingleModal = ({
  open,
  partner,
  subGroupsWithPartner,
  onClose,
  isPreview,
}: ModalProps) => {
  const { session } = useAppSelector((state: RootState) => {
    return state
  })

  const { group, isOrgAdmin, userCollection } = session
  let isPremium, canAddToOrg, requiresApproval
  const dispatch = useAppDispatch()

  if (partner) {
    isPremium = partner.partner_free_or_paid === "paid"
    requiresApproval = partner.partner_approval_required === "1"
    canAddToOrg = !partner.added_to_org && !requiresApproval && !isPremium
  }

  const [isRequestFormShown, setIsRequestFormShown] = useState(false)
  const [showGroupSelect, setShowGroupSelect] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  let partnerImage
  if (partner) {
    partnerImage = partner.partner_image.includes("data:image")
      ? partner.partner_image
      : process.env.REACT_APP_API_URL + partner.partner_image
  }

  const isAdded =
    (partner && partner.added_to_org) ||
    (subGroupsWithPartner && subGroupsWithPartner.length)
      ? true
      : false

  const canAddPartners =
    isOrgAdmin || (userCollection && userCollection.length) ? true : false

  const isCollectionAdminView =
    session.isCollectionAdmin && !session.activeMembership?.data

  return (
    <Dialog
      id="partner-single-modal"
      className="modal"
      open={open}
      onClose={() => {
        onClose()
        setTimeout(() => {
          setIsRequestFormShown(false)
          setShowGroupSelect(false)
        }, 600)
      }}
    >
      {partner && (
        <>
          <div
            className="modal-content"
            style={{
              padding: "10px 20px 5px 20px",
              marginLeft: -20,
              marginRight: -20,
              borderBottom: "1px solid #ebebeb",
            }}
          >
            {(isRequestFormShown || showGroupSelect) && (
              <div style={{ marginTop: -15, marginBottom: 10, marginLeft: -5 }}>
                <ButtonSmall
                  onClick={() => {
                    setIsRequestFormShown(false)
                    setShowGroupSelect(false)
                  }}
                >
                  <span
                    className="icon caret"
                    style={{ width: 15, height: 15 }}
                  />{" "}
                  Back
                </ButtonSmall>
              </div>
            )}
            <div style={styles.headerContainer}>
              {partner.partner_featured === "1" && (
                <div style={styles.featuredBug}>Featured</div>
              )}
              <div
                style={{
                  ...styles.partnerImage,
                  backgroundImage: "url(" + partnerImage + ")",
                }}
              >
                {isPremium ? (
                  <img
                    src={Diamond}
                    alt={"Premium"}
                    style={styles.paidIndicator}
                  />
                ) : (
                  <img src={Free} alt={"Free"} style={styles.paidIndicator} />
                )}
              </div>

              <div>
                {isAdded && (
                  <div style={styles.addedMessage}>
                    <img
                      src={CheckIcon}
                      alt={"Check"}
                      style={styles.addedMessage.icon}
                    />
                    <span style={styles.addedMessage.text}>
                      {isCollectionAdminView ? (
                        <>
                          <>Added to {userCollection[0].label[0].value}</>
                        </>
                      ) : (
                        <>
                          {subGroupsWithPartner &&
                          subGroupsWithPartner.length > 0 ? (
                            <>
                              Added to {subGroupsWithPartner.length}{" "}
                              {pluralize("Group", subGroupsWithPartner.length)}
                            </>
                          ) : (
                            <>Added to {group.label[0].value}</>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                )}
                <strong style={styles.title}>{partner.name}</strong>
                <div style={styles.contentContainer}>
                  {Number(partner.field_course_count) > 0 && (
                    <div style={styles.contentItem}>
                      <span
                        className="icon laptop"
                        style={styles.contentIcon}
                      ></span>
                      <strong style={styles.contentCount}>
                        {partner.field_course_count}
                      </strong>
                      <span style={styles.contentLabel}>
                        {pluralize(
                          "Course",
                          Number(partner.field_course_count)
                        )}
                      </span>
                    </div>
                  )}

                  {Number(partner.field_badges_count) > 0 && (
                    <div style={styles.contentItem}>
                      <span
                        className="icon badge color"
                        style={styles.contentIcon}
                      ></span>
                      <strong style={styles.contentCount}>
                        {partner.field_badges_count}
                      </strong>
                      <span style={styles.contentLabel}>
                        {pluralize("Badge", Number(partner.field_badges_count))}
                      </span>
                    </div>
                  )}

                  {Number(partner.field_lp_count) > 0 && (
                    <div style={{ ...styles.contentItem, marginRight: 0 }}>
                      <span
                        className="icon learningPath color"
                        style={styles.contentIcon}
                      ></span>
                      <strong style={styles.contentCount}>
                        {partner.field_lp_count}
                      </strong>

                      <span style={styles.contentLabel}>
                        Learning{" "}
                        {pluralize("Path", Number(partner.field_lp_count))}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!isRequestFormShown && !showGroupSelect && (
            <>
              <div style={{ margin: "15px 0" }}>
                <div style={styles.sectionHeader}>
                  <img
                    src={Content}
                    alt="About Partner"
                    style={styles.sectionHeader.img}
                  />
                  <strong>About this Content</strong>
                </div>

                <SanitizedHTML html={partner.partner_description} />
              </div>

              <PartnerSingleCarousel partner={partner} />

              <ul style={styles.topicList}>
                {partner.partner_topic_list &&
                  partner.partner_topic_list
                    .split(",")
                    .map((topic: string, index: number) => {
                      return (
                        <li style={styles.topicItem} key={index}>
                          {topic}
                        </li>
                      )
                    })}
              </ul>

              {partner.partner_contact && canAddPartners && (
                <div className="partner-contact-info">
                  <div style={styles.sectionHeader}>
                    <img
                      src={Mail}
                      alt="Contact"
                      style={styles.sectionHeader.img}
                    />
                    <strong>Contact</strong>
                  </div>
                  <SanitizedHTML html={partner.partner_contact} />
                </div>
              )}
            </>
          )}
          {showGroupSelect && (
            <PartnerGroupSelect
              onClose={() => {
                onClose()
                setTimeout(() => {
                  setIsRequestFormShown(false)
                  setShowGroupSelect(false)
                }, 600)
              }}
              partner={partner}
              subGroupsWithPartner={subGroupsWithPartner ?? []}
            />
          )}

          {isRequestFormShown && (
            <div style={{ marginTop: 25 }}>
              <HubspotForm
                portalId={"20989892"}
                formId={"aebf0d07-8dfc-4b30-a235-7ce44f783460"}
                onSubmit={() => {}}
                loading={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: 100,
                    }}
                  >
                    <Loading />
                  </div>
                }
              />
            </div>
          )}

          {!showGroupSelect &&
            !isRequestFormShown &&
            !isPreview &&
            !isLoading &&
            canAddPartners && (
              <footer style={styles.footer}>
                {isAdded && (
                  <div style={{ marginBottom: 10, marginTop: 10 }}>
                    <ButtonSmall
                      onClick={async () => {
                        setIsLoading(true)
                        if (
                          subGroupsWithPartner &&
                          subGroupsWithPartner.length > 0
                        ) {
                          await dispatch(
                            removePartnerFromGroups({
                              partner,
                              subGroupsWithPartner,
                            })
                          )
                        } else {
                          await dispatch(
                            removePartnerFromOrganization({ partner })
                          )
                        }
                        await dispatch(getSubGroups({}))
                        await dispatch(getMarketplaceItems({}))
                        setIsLoading(false)

                        onClose()
                        dispatch(
                          openSnackbar({
                            message: (
                              <>
                                <strong>{partner.name}</strong> removed.
                              </>
                            ),
                          })
                        )
                      }}
                    >
                      <img
                        alt="Eject"
                        src={Eject}
                        style={{ width: 20, height: 20, marginRight: 10 }}
                      />
                      {isCollectionAdminView ? (
                        <> Remove From {userCollection[0].label[0].value}</>
                      ) : (
                        <>
                          {subGroupsWithPartner &&
                          subGroupsWithPartner.length > 0 ? (
                            <>
                              Remove From {subGroupsWithPartner.length}{" "}
                              {pluralize("Group", subGroupsWithPartner.length)}
                            </>
                          ) : (
                            <> Remove From {group.label[0].value}</>
                          )}
                        </>
                      )}
                    </ButtonSmall>
                  </div>
                )}
                {canAddToOrg && !isAdded && (
                  <ButtonLarge
                    onClick={() => {
                      setShowGroupSelect(true)
                    }}
                  >
                    <img
                      alt="Extension"
                      src={Extension}
                      style={{ width: 25, marginRight: 10 }}
                    />
                    {!isCollectionAdminView ? (
                      <>Add to {group.label[0].value}</>
                    ) : (
                      <>
                        {userCollection && userCollection[0] && (
                          <>Add to {userCollection[0].label[0].value}</>
                        )}
                      </>
                    )}
                  </ButtonLarge>
                )}

                {isAdded && !isCollectionAdminView && (
                  <ButtonLarge
                    onClick={() => {
                      setShowGroupSelect(true)
                    }}
                  >
                    <img
                      alt="Lock"
                      src={Lock}
                      style={{ width: 25, marginRight: 10 }}
                    />
                    Manage Access
                  </ButtonLarge>
                )}

                {!canAddToOrg && !isRequestFormShown && !isAdded && (
                  <>
                    <ButtonLarge
                      onClick={() => {
                        setIsRequestFormShown(true)
                      }}
                    >
                      <img
                        alt="Extension"
                        src={Extension}
                        style={{ width: 25, marginRight: 10 }}
                      />
                      Request Content
                    </ButtonLarge>
                  </>
                )}
              </footer>
            )}

          {isLoading && (
            <footer style={styles.footer}>
              <Loading />
            </footer>
          )}
        </>
      )}
    </Dialog>
  )
}

const styles = {
  topicList: {
    marginTop: 15,
    marginBottom: -10,
    display: "flex",
    flexWrap: "wrap" as "wrap",
    listStyleType: "none",
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    img: {
      width: 25,
      height: 25,
      marginRight: 5,
    },
  },
  featuredBug: {
    zIndex: 5,
    position: "absolute" as "absolute",
    display: "flex",
    alignItems: "center",
    padding: "3px 0",
    textTransform: "uppercase" as "uppercase",
    backgroundColor: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 10,
    color: "white",
    borderRadius: "0px 0px 12px 12px",
    justifyContent: "center",
    width: 100,
    margin: "82px auto 0px",
  },
  topicItem: {
    backgroundColor: "rgb(233, 70, 104)",
    color: "white",
    padding: "5px 10px",
    borderRadius: 40,
    marginRight: 5,
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative" as "relative",
    marginBottom: 20,
  },
  partnerImage: {
    marginRight: 20,
    flexShrink: 0,
    width: 100,
    height: 100,
    borderRadius: 12,
    boxShadow: theme.shadows[3],
    position: "relative" as "relative",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat" as "no-repeat",
  },
  title: {
    fontSize: 24,
    display: "block",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as "column",
  },
  addedMessage: {
    display: "inline-flex",
    alignItems: "center",
    marginBottom: 5,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 15,
    padding: "5px 10px",
    icon: {
      width: 15,
      height: 15,
      marginRight: 5,
    },
    text: {
      fontSize: 12,
      fontWeight: 700,
    },
  },
  contentItem: {
    marginRight: 40,
  },
  paidIndicator: {
    width: 30,
    height: 30,
    position: "absolute" as "absolute",
    right: -8,
    top: -8,
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
  },
  contentLabel: {
    textTransform: "uppercase" as "uppercase",
    fontSize: 10,
    marginTop: -5,
    position: "relative" as "relative",
    fontWeight: "bold",
    opacity: 0.6,
    top: -3,
  },
  contentCount: {
    fontSize: 18,
    marginRight: 5,
  },
  contentIcon: {
    width: 20,
    height: 20,
    position: "relative" as "relative",
    top: 3,
    marginRight: 5,
  },
}
