import { Box, TextField, Typography } from "@mui/material"
import { Field, useFormikContext } from "formik"
import getYouTubeID from "get-youtube-id"
import { useMemo } from "react"
import { CreateCourseGenieVideoUrlFormValues } from "./CreateCourseGenieVideoUrl"
import { ResponsiveYouTube } from "./ResponsiveYouTube"

export const CreateCourseGenieVideoUrlField = () => {
  const { errors, touched, values } =
    useFormikContext<CreateCourseGenieVideoUrlFormValues>()

  const videoId = useMemo(
    () => getYouTubeID(values.videoUrl),
    [values.videoUrl]
  )

  return (
    <>
      <Field
        as={TextField}
        name="videoUrl"
        label="Video URL"
        required={true}
        fullWidth={true}
        error={touched.videoUrl && !!errors.videoUrl}
        helperText={touched.videoUrl && errors.videoUrl}
        autoFocus={true}
      />
      <Box>
        <Typography variant="caption">Video</Typography>
        <ResponsiveYouTube videoId={videoId} />
      </Box>
    </>
  )
}
