import { combineReducers } from "redux"

// New fancy Typed slices
import session from "./reducers/session"
import dashboard from "./reducers/dashboard"
import calendar from "./reducers/calendar"
import externalPD from "./reducers/externalPD"
import navbar from "./reducers/navbar"
import categories from "./reducers/categories"
import backpack from "./reducers/backpack"
import productTour from "./reducers/productTour"
import tags from "./reducers/tags"
import notifications from "./reducers/notifications"
import marketplace from "./reducers/marketplace"
import feedbackForms from "./reducers/feedbackForms"
import activeCourse from "../features/courseForm/activeCourseSlice"
import { courseGenieReducer as courseGenie } from "../features/courseForm/store/courseGenieSlice"

import announcementTypes from "../features/announcementTypes/announcementTypesSlice"
import learningPathTypes from "../features/learningPathTypes/learningPathTypesSlice"
import courses from "../features/courses/coursesSlice"
import badges from "../features/badges/badgesSlice"
import learningPaths from "../features/learningPaths/learningPathsSlice"
import goals from "../features/goals/goalsSlice"
import entityList from "../features/entityList/entityListSlice"
import activeGoal from "../features/createGoal/activeGoalSlice"
import activeBadge from "../features/createBadge/activeBadgeSlice"
import activeAnnouncement from "../features/createAnnouncement/activeAnnouncementSlice"
import activeLearningPath from "../features/createLearningPath/activeLearningPathSlice"
import activeMandatedTraining from "../features/createMandatedTraining/activeMandatedTrainingSlice"
import activeUser from "../features/userSingle/activeUserSlice"
import leaderboards from "../features/leaderboards/leaderboardsSlice"
import teacherAccess from "../features/teacherAccess/teacherAccessSlice"
import compliance from "../features/compliance/complianceSlice"
import jobTitles from "../features/jobTitles/jobTitlesSlice"
import quickLinks from "../features/createQuickLinks/quickLinksSlice"
import hqAnnouncements from "../features/hqAnnouncements/hqAnnouncementsSlice"
import activeEvent from "../features/events/eventSingle/activeEventSlice"
import activeConference from "../features/events/conference/activeConferenceSlice"
import locations from "../features/locations/locationsSlice"
import rooms from "../features/rooms/roomsSlice"
import userSelect from "../features/userSelect/userSelectSlice"

import assessments from "../features/review/reviewSlice"
import organizations from "../features/organizations/organizationsSlice"
import snackbar from "../features/snackbar/snackbarSlice"
import rubrics from "../store/reducers/rubrics"
import analytics from "../store/reducers/analytics"

import { api } from "@mobilemind/common/src/api"

const reducers = combineReducers({
  session,
  dashboard,
  calendar,
  externalPD,
  navbar,
  categories,
  backpack,
  productTour,
  tags,
  notifications,
  marketplace,
  activeCourse,
  feedbackForms,
  rubrics,
  activeMandatedTraining,
  entityList,
  announcementTypes,
  learningPathTypes,
  courses,
  activeUser,
  badges,
  learningPaths,
  activeGoal,
  goals,
  activeBadge,
  activeAnnouncement,
  activeLearningPath,
  activeEvent,
  activeConference,
  locations,
  rooms,
  leaderboards,
  teacherAccess,
  compliance,
  analytics,
  jobTitles,
  quickLinks,
  hqAnnouncements,
  userSelect,
  assessments,
  snackbar,
  organizations,
  courseGenie,
  [api.reducerPath]: api.reducer,
})

export default reducers
