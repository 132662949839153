import { isGroupLevel } from "functions"
import { RootState } from "./types"

export const selectIsSiteAdmin = (state: RootState): boolean => {
  const { session } = state
  return !!session.isSiteAdmin
}

export const selectOrgId = (state: RootState): string | undefined => {
  const { session } = state
  return session.group?.id?.[0]?.value
}

export const selectOrgUuid = (state: RootState): string | undefined => {
  const { session } = state
  return session.group?.uuid?.[0]?.value
}

export const selectHasGenieAccess = (state: RootState): boolean =>
  state.session.isPartner ||
  (state.session.group.field_genie_access &&
    !!state.session.group.field_genie_access[0]?.value)

export const selectGenieWishesFetched = (state: RootState): boolean =>
  state.session.genieWishesFetched

export const selectGenieWishesRemaining = (state: RootState): number =>
  state.session.genieWishesRemaining

export const selectSubGroups = (state: RootState): any => {
  // @todo: get rid of duplicate subgroups or subGroups property on session slice.
  const { session } = state
  // @ts-ignore
  if (session.subgroups && session.subgroups.data && isGroupLevel(session)) {
    return session.subgroups
  } else {
    return session.subGroups
  }
}

// Returns whether the current user is allowed to share a course to the community.
export const selectUserCanShare = (state: RootState): boolean => {
  const { session } = state
  return (
    (session.orgRoles.includes("organization-admin") ||
      session.orgRoles.includes("organization-creator")) &&
    !session.isSiteAdmin
  )
}
