import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { createCourseGenieSchema } from "../common/createCourseGenieSchema"
import { CreateCourseGenieContext } from "./CreateCourseGenieContext"
import { CreateCourseGenieReviewFields } from "./CreateCourseGenieReviewFields"
import { GenieMessage } from "./GenieMessage"
import { ResponsiveYouTube } from "./ResponsiveYouTube"

type CreateCourseGenieSearchReviewProps = {
  videoId: string
  onBack: () => void
}

export const CreateCourseGenieSearchReview = (
  props: CreateCourseGenieSearchReviewProps
) => {
  const { videoId, onBack } = props
  const { onSubmit } = useContext(CreateCourseGenieContext)

  return (
    <Formik
      initialValues={createCourseGenieSchema.getDefault()}
      onSubmit={async (values) => {
        await onSubmit(videoId, values)
      }}
      validationSchema={createCourseGenieSchema}
      validateOnMount={true}
    >
      <Box component={Form} sx={{ display: "contents" }}>
        <DialogContent>
          <GenieMessage>
            <Typography>
              <strong>Excellent choice!</strong> Select a submission type below,
              and when you're ready, I'll create a new course with this video.
            </Typography>
          </GenieMessage>
          <Stack spacing={4}>
            <CreateCourseGenieReviewFields />
            <Box>
              <Typography variant="caption">Video</Typography>
              <ResponsiveYouTube videoId={videoId} />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={onBack}>
            Go Back
          </Button>
          <Button variant="contained" type="submit">
            Let's Go!
          </Button>
        </DialogActions>
      </Box>
    </Formik>
  )
}
