import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import Play from "../../img/play.svg"
import { MarketplaceItem } from "./types"
import { generateVideoEmbedURL } from "@mobilemind/common/src/functions/index"
import theme from "@mobilemind/common/src/theme/theme"

const styles = {
  dotContainer: {
    marginTop: -15,
    transition: ".3s",
    cursor: "pointer",
  },
  playIcon: {
    position: "absolute" as "absolute",
    width: 20,
    height: 20,
  },
  dotImage: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: 40,
    height: 40,
    borderRadius: 12,
    marginLeft: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  dotBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    width: 40,
    height: 40,
    borderRadius: 12,
    position: "absolute" as "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    borderRadius: 12,
    cursor: "pointer",
    height: 162,
    marginLeft: 5,
    marginRight: 5,
    backgroundPosition: "center",
    backgroundSize: "cover",
    transition: ".3s",
    transform: "scale(1)",
    "&:hover": {
      boxShadow: theme.shadows[2],
      transform: "scale(1.02)",
    },
  },
}

type CarouselProps = {
  partner: MarketplaceItem
}

export const PartnerSingleCarousel = ({ partner }: CarouselProps) => {
  // Put together our slides using the partner's image files
  const imageItems = partner.partner_preview
    ? partner.partner_preview.map(
        (imageSrc: { uuid?: string; uri: string }) => {
          // These are the images that are displayed in the carousel,
          // They'll link to the full-size image in a new tab

          const backgroundImage = imageSrc.uri.includes("data:image")
            ? imageSrc.uri
            : process.env.REACT_APP_API_URL + imageSrc.uri

          return (
            <div
              className="partner-carousel-item"
              style={{
                ...styles.image,
                display: "block",
                backgroundImage: "url(" + backgroundImage + ")",
              }}
              onClick={() => {
                let windowParams =
                  "left=20,top=20,right=auto,width=1200,height=900,personalbar=0,toolbar=0,scrollbars=0,resizable=1"
                const confirmWindow =
                  process.env.REACT_APP_API_URL + imageSrc.uri
                window.open(confirmWindow, "", windowParams)
              }}
            ></div>
          )
        }
      )
    : []

  // If they have a video link
  if (partner.partner_promo_video) {
    const videoURL = generateVideoEmbedURL(partner.partner_promo_video)
    // Stick it at the front of the carousel array
    imageItems.unshift(
      <iframe
        height={imageItems.length ? 162 : 325}
        width="100%"
        style={{ borderWidth: 0, borderRadius: 12 }}
        src={videoURL}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    )
  }

  const ThumbnailItem = (props: { activeIndex: number; isActive: boolean }) => {
    const { isActive, activeIndex } = props
    const activeItem = imageItems && imageItems[activeIndex]
    if (activeItem) {
      return (
        <div style={{ display: "flex" }}>
          <div style={{ ...styles.dotContainer, opacity: isActive ? 1 : 0.6 }}>
            {activeItem.type === "iframe" ? (
              <div
                style={{
                  ...styles.dotImage,
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_API_URL +
                    partner.partner_image +
                    ")",
                }}
              >
                <div style={styles.dotBackdrop} />
                <img src={Play} alt="Play" style={styles.playIcon} />
              </div>
            ) : (
              <div
                style={{
                  ...activeItem.props.style,
                  ...styles.dotImage,
                }}
              />
            )}
          </div>
        </div>
      )
    }

    return null
  }

  return (
    <AliceCarousel
      responsive={{
        0: { items: 2 },
        568: { items: 2 },
        1024: { items: 2 },
      }}
      items={imageItems.map((item) => item)}
      controlsStrategy="alternate"
      disableButtonsControls={true}
      renderDotsItem={(partner) => {
        return <ThumbnailItem {...partner} />
      }}
    />
  )
}
