type IconProps = {
  category?: any
  imageUrl?: string
}

function CategoryIcon(props: IconProps) {
  const { category, imageUrl } = props
  let path

  if (imageUrl) {
    path = imageUrl
  } else if (category.image && category.image.attributes) {
    path = process.env.REACT_APP_API_URL + category.image.attributes.uri.url
  }

  return (
    <span
      className={"category-icon"}
      style={{
        backgroundImage: "url(" + path + ")",
      }}
    />
  )
}

export default CategoryIcon
