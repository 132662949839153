import { useMemo } from "react"
import { useAppSelector } from "store/hooks"
import {
  getChallengeSchema,
  getSettingsSchema,
  overviewSchema,
} from "../schemas"
import { CourseFormValues } from "../types"
import yup from "@mobilemind/common/src/utility/yup"

export const useCourseFormSchema = () => {
  const session = useAppSelector((state) => state.session)
  return useMemo(() => {
    return yup.lazy((values: CourseFormValues) => {
      return yup
        .object()
        .concat(overviewSchema)
        .concat(getChallengeSchema(values))
        .concat(getSettingsSchema(session))
    })
  }, [session])
}
