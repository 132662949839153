import { Component } from "react"
import { connect } from "react-redux"

import { Paper, Dialog } from "@mui/material"
import {
  ViewState,
  GroupingState,
  IntegratedGrouping,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler"

import classNames from "classnames"
import moment from "moment"
import EventTooltip from "../calendar/EventTooltip"
import { setSearchActive } from "../../../store/reducers/calendar"
import { dropEvent } from "../../../actions/events"

import { ConferenceScheduleToolbar } from "./components/ConferenceScheduleToolbar"

import { changeTab } from "../../../store/reducers/navbar"
import {
  fetchActiveEvent,
  setIsFetched,
  updateField,
  startConferenceEvent,
  startNewEvent,
} from "../eventSingle/activeEventSlice"

import {
  setIsEditingEvent,
  updateConferenceField,
  updateListViewFilters,
  setIsLoadingEvent,
  setIsFullScreen,
} from "./activeConferenceSlice"

import { generateContrastColor } from "@mobilemind/common/src/functions/index"

import "../../../styles/calendar.scss"

import {
  Scheduler,
  DayView,
  Resources,
  Appointments,
  AppointmentTooltip,
  GroupingPanel,
  CurrentTimeIndicator,
  DragDropProvider,
} from "@devexpress/dx-react-scheduler-material-ui"

import ConferenceEventLayout from "./ConferenceEventLayout"
import Loading from "@mobilemind/common/src/components/Loading"
import ConferenceEventList from "./ConferenceEventList"
import ErrorBoundary from "../../../components/ErrorBoundary"

import { Appointment } from "./ConferenceAppointment"
import { Box } from "@mui/material"
import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"

const mapStateToProps = ({ session, activeConference }) => {
  let targetPersonnel =
    activeConference.personnel &&
    activeConference.personnel.find((user) => user.id === session.user.id)
  let eventRole = targetPersonnel && targetPersonnel.field_event_role_name
  return {
    eventRole,
    conference: activeConference,
    allRooms: activeConference.availableRooms,
    session,
  }
}

const mapDispatchToProps = {
  dropEvent,
  updateField,
  fetchActiveEvent,
  updateListViewFilters,
  startConferenceEvent,
  updateConferenceField,
  changeTab,
  startNewEvent,
  setIsLoadingEvent,
  setIsFetched,
  setSearchActive,
  setIsFullScreen,
  setIsEditingEvent,
}

class ConferenceSchedule extends Component {
  constructor(props) {
    super(props)

    const firstLocationRooms = props.allRooms.filter((room) => {
      return (
        room.relationships.field_location.data.id ===
        props.conference.locations[0].id
      )
    })

    const formattedRooms = firstLocationRooms.map((room) => {
      return {
        id: room.id,
        text: room.attributes.name,
        attributes: {
          name: room.attributes.name,
        },
      }
    })

    formattedRooms.unshift({
      id: "none",
      text: "Virtual Sessions",
      attributes: {
        name: "Virtual Sessions",
      },
    })

    const nonDupeRooms = []
    formattedRooms.forEach((room) => {
      const existing = nonDupeRooms.find(
        (nonDupeRoom) => nonDupeRoom.id === room.id
      )
      if (!existing) {
        nonDupeRooms.push(room)
      }
    })

    this.state = {
      currentDay: 0,
      createAnchorEl: null,
      currentDate: props.conference.startDate,
      visible: false,
      isFormVisible: false,
      currentTab: "calendar",
      isNewEventModalOpen: false,
      showArchived: false,
      isRoomsModalOpen: false,
      isImportModalOpen: false,
      showCalendar: true,
      selectedLocation:
        props.conference.locations && props.conference.locations[0]
          ? props.conference.locations[0].id
          : null,
      resources: [
        {
          fieldName: "roomId",
          title: "",
          instances: nonDupeRooms,
        },
      ],
      grouping: [
        {
          resourceName: "roomId",
        },
      ],
      appointmentMeta: {
        target: null,
        data: {},
      },
    }

    this.toggleVisibility = () => {
      const { visible: tooltipVisibility } = this.state
      this.setState({ visible: !tooltipVisibility })
    }

    this.onAppointmentMetaChange = ({ data, target }) => {
      this.setState({ appointmentMeta: { data, target } })
    }

    this.myAppointment = this.myAppointment.bind(this)
    this.commitChanges = this.commitChanges.bind(this)
  }

  sourceAppointment = (props) => {
    return (
      <div style={{ opacity: 0.5 }}>
        <Appointment
          {...props}
          toggleVisibility={this.toggleVisibility}
          onAppointmentMetaChange={this.onAppointmentMetaChange}
          color={props.data.color}
          textColor={generateContrastColor(props.data.color)}
        />
      </div>
    )
  }

  myAppointment = (props) => {
    return (
      <Appointment
        {...props}
        toggleVisibility={this.toggleVisibility}
        onAppointmentMetaChange={this.onAppointmentMetaChange}
        color={props.data.color}
        textColor={generateContrastColor(props.data.color)}
      />
    )
  }

  TimeIndicator = ({ top, ...restProps }) => {
    return (
      <div {...restProps}>
        <div className={classNames("indicator-circle")} />
        <div className={classNames("indicator-line")} />
      </div>
    )
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const scheduleEl = document.getElementsByClassName("scheduleWrapper")

    if (scheduleEl && scheduleEl[0] && scheduleEl[0].firstChild?.firstChild) {
      scheduleEl[0].firstChild.firstChild.scrollTop = 270
    }
  }

  commitChanges = ({ added, changed }) => {
    const fullSession = this.props.activeConference.conferenceEvents.find(
      (conferenceEvent) => {
        return conferenceEvent.event_uuid === Object.keys(changed)[0]
      }
    )
    const sessionLogId = fullSession.attendance_log_uuid
    if (this.props.canSchedule && changed) {
      this.props.dropEvent(changed, "Day", sessionLogId)
    }
  }

  openNewEventModal = () => {
    this.setState({ isNewEventModalOpen: true })
  }

  startConferenceEvent = (clickEvent) => {
    this.props.setIsEditingEvent(true)
    this.props.setIsFetched(true)

    let clickEventStart, clickEventEnd
    if (clickEvent) {
      let fullRoom = this.props.allRooms.find(
        (room) => room.id === clickEvent.groupingInfo[0].id
      )

      let roomMaxCapacity =
        fullRoom && fullRoom.attributes.field_capacity
          ? fullRoom.attributes.field_capacity
          : 0

      this.props.updateField({ field: "eventMax", value: roomMaxCapacity })
      this.props.updateField({ field: "sendPublishEmail", value: false })

      clickEventStart = moment(clickEvent.startDate)
      clickEventEnd = moment(clickEvent.endDate)
    }

    this.props.startNewEvent()
    this.props.startConferenceEvent({
      conference: this.props.conference,
      clickEvent,
      currentDate: clickEvent && clickEvent.startDate,
      // add the currently selected location to the new event, so we can select rooms
      currentLocation: this.props.conference.locations.find(
        (location) => location.id === this.state.selectedLocation
      ),
    })

    this.props.updateField({
      field: "startDate",
      value:
        clickEvent && clickEventStart
          ? moment(clickEventStart).format("YYYY-MM-DDTHH:mm:ss")
          : this.props.activeConference.startDate,
    })

    if (clickEvent) {
      this.props.updateField({
        field: "endDate",
        value:
          clickEvent && clickEventEnd
            ? moment(clickEventEnd).format("YYYY-MM-DDTHH:mm:ss")
            : moment(this.props.activeConference.startDate)
                .add(1, "hour")
                .format(),
      })
    }

    this.props.changeTab({ currentTab: "details" })
  }

  componentDidUpdate(prevProps, prevState) {
    const { conference } = this.props

    // Clear the tooltip if we're getting an event
    if (prevProps.conference.isLoadingEvent !== conference.isLoadingEvent) {
      this.setState({ visible: false })
    }
    if (prevProps.conference.isCopyingEvent !== conference.isCopyingEvent) {
      this.setState({ visible: false })
    }

    if (prevState.showCalendar !== this.state.showCalendar) {
      this.setState({
        resources: [
          {
            fieldName: "roomId",
            title: " ",
            instances: this.state.selectedLocation
              ? this.props.allRooms.filter((room) => {
                  return (
                    room.relationships.field_location.data.id ===
                    this.state.selectedLocation
                  )
                })
              : this.props.allRooms,
          },
        ],
      })
    }

    if (
      prevState.currentTab === "list" &&
      this.state.currentTab === "calendar"
    ) {
      const scheduleEl = document.getElementsByClassName("scheduleWrapper")
      scheduleEl[0].firstChild.firstChild.scrollTop = 360
    }

    if (prevProps.conference.loadEvent !== conference.loadEvent) {
      this.props.setIsEditingEvent(true)
      this.props.setIsFetched(true)
      this.props.setIsLoadingEvent(true)
      this.props.setIsLoadingEvent(false)
    }
  }

  handleDayChange = (event, newValue) => {
    this.setState({
      currentDate: moment(this.props.activeEvent.startDate)
        .add(newValue, "day")
        .format("YYYY-MM-DD"),
    })

    this.setState({ currentDay: newValue })
  }

  handleViewChange = (newValue) => {
    this.setState({ currentTab: newValue })
    this.props.updateListViewFilters({ field: "roomId", value: "all" })

    if (newValue === "calendar") {
      this.props.updateListViewFilters({ field: "searchQuery", value: "" })
    }
  }

  loadConferenceEvent = async (event) => {
    this.setState({ visible: false })
    this.props.setIsLoadingEvent(true)

    this.props
      .fetchActiveEvent({
        isSession: true,
        id: event.drupal_internal__id ? event.drupal_internal__id : event.id,
      })
      .then(() => {
        this.props.setIsLoadingEvent(true)
        this.props.setIsEditingEvent(true)
        this.props.setIsLoadingEvent(false)
      })
  }

  toolTipLayout = (props) => {
    return (
      <Dialog
        id="event-tooltip-container"
        onClose={() => this.setState({ visible: false })}
        open={this.state.visible}
      >
        <EventTooltip appointmentData={props.appointmentMeta.data} />
      </Dialog>
    )
  }

  render() {
    const component = this
    const { conference, eventRole } = this.props

    const { visible, grouping, appointmentMeta, currentDate, currentDay } =
      this.state

    const allRoles = this.props.session.orgRoles
      .concat(this.props.session.groupRoles)
      .join(",")

    let canAddSession =
      allRoles.includes("admin") ||
      allRoles.includes("scheduler") ||
      eventRole === "Manager" ||
      eventRole === "Presenter"

    function TimeTableCell({ onDoubleClick, ...restProps }) {
      const height = 50
      return (
        <DayView.TimeTableCell
          style={{ height }}
          onClick={() => {
            if (canAddSession) {
              component.startConferenceEvent(restProps)
            }
          }}
          {...restProps}
        />
      )
    }

    function TimeLabel(props) {
      return <DayView.TimeScaleLabel {...props} />
    }
    const TickCell = (props) => {
      return null
    }

    let formattedEvents = conference.conferenceEvents.map((event) => {
      let startDate = event.field_event_date_time_value
      let endDate = event.field_event_date_time_end_value

      return {
        id: event.event_uuid,
        addOnId: event.addon_uuid,
        attendanceLogId: event.attendance_log_uuid,
        location_uuid: event.location_uuid,
        isConference: false,
        isSession: true,
        image: event.field_event_image_path && {
          attributes: {
            uri: {
              url: event.field_event_image_path,
            },
          },
        },
        field_draft: event.field_draft === "True" ? true : false,
        field_archive: event.field_archive === "True" ? true : false,
        drupal_internal__id: event.id,
        startDate,
        endDate,
        title: event.name,
        description: event.field_description,
        roomId: event.room_uuid ? event.room_uuid : "none",
        loadConferenceEvent: () => {
          this.loadConferenceEvent(event)
        },
        color: event.field_hex_color ? event.field_hex_color : "#59C3E5",
        category: "None",
      }
    })

    let resources = this.state.resources

    // Replace this with location or modify if location doesn't have room
    // if (!resources.instances || !resources.instances.length) {
    //   if (conference.currentLocation) {
    //     resources.instances = [
    //       { id: "none", text: conference.currentLocation.attributes.name },
    //     ]
    //   } else {
    //     resources[0].instances = [{ id: "none", text: "" }]
    //   }
    // }

    const duration =
      moment(conference.endDate.split("T")[0]).diff(
        moment(conference.startDate.split("T")[0]),
        "days"
      ) + 1

    const filteredEvents = formattedEvents.filter((event) => {
      let byDay = true
      let filters = conference.listViewFilters

      const matchesSearch = event.title
        ?.toLowerCase()
        ?.includes(filters.searchQuery?.toLowerCase())

      let bySearch = filters.searchQuery ? matchesSearch : true

      let byRoom
      let byArchive = !event.field_archive

      if (filters.roomId !== "all") {
        byRoom = event.roomId && event.roomId === filters.roomId
      } else {
        byRoom = true
      }

      let byLocation = conference.locations.length === 1
      if (conference.locations.length > 1) {
        byLocation = event.location_uuid === this.state.selectedLocation
      }
      if (conference.locations?.length < 1) {
        byLocation = true
      }

      if (!filters.searchQuery && this.state.currentTab === "list") {
        if (duration > 1) {
          let tabStart = moment(this.props.conference.startDate)
            .add(this.state.currentDay, "days")
            .startOf("day")
          let tabEnd = moment(tabStart).endOf("day")

          let isEventOnCurrentTab =
            moment(event.startDate).isAfter(moment(tabStart)) &&
            moment(event.startDate).isBefore(moment(tabEnd))

          byDay = isEventOnCurrentTab
        }
      }

      if (this.state.showArchived) {
        byArchive = true
      }

      return bySearch && byLocation && byRoom && byArchive && byDay
    })
    const roomsForSelectedLocation = this.state.selectedLocation
      ? this.props.allRooms.filter((room) => {
          return (
            room?.relationships.field_location.data.id ===
            this.state.selectedLocation
          )
        })
      : this.props.allRooms

    return (
      <ErrorBoundary>
        <div
          className={classNames(
            "conferenceScheduleWrapper",
            duration > 1 && "multiDay"
          )}
        >
          {!conference.sessionsFetched && (
            <div
              style={{
                position: "absolute",
                right: 20,
                top: 5,
              }}
            >
              <Loading message="Getting sessions..." />
            </div>
          )}

          {(conference.isLoadingEvent || conference.isCopyingEvent) && (
            <Loading
              fullPage={true}
              message={
                conference.isCopyingEvent
                  ? "Copying session..."
                  : "Loading session..."
              }
            />
          )}

          <div
            className={classNames(
              "overlay",
              conference.isEditingEvent && "active"
            )}
          ></div>
          <div
            className={classNames(
              "event-content calendarLayout conferenceCalendar"
            )}
          >
            <div
              className={classNames(
                "calendarInner conference Day",
                conference.isEditingEvent && "isEditing",
                duration > 1 && "multiDay",
                conference.listViewFilters.searchQuery && "searchActive"
              )}
            >
              <ConferenceScheduleToolbar
                isMultiDay={
                  duration > 1 && !conference.listViewFilters.searchQuery
                }
                setIsFullScreen={() => {
                  this.props.setIsFullScreen(
                    !this.props.conference.isFullScreen
                  )
                }}
                startConferenceEvent={() => {
                  this.startConferenceEvent(null, this.state.currentDate)
                }}
                refreshCalendar={() => {
                  this.setState({ showCalendar: false })
                  setTimeout(() => {
                    this.setState({ showCalendar: true })
                  }, 1)
                }}
                selectedLocation={this.state.selectedLocation}
                onChangeLocation={(location) => {
                  const roomsForLocation = this.props.allRooms.filter(
                    (room) => {
                      return (
                        room.relationships.field_location.data.id === location
                      )
                    }
                  )
                  const formattedRooms = roomsForLocation.map((room) => {
                    return {
                      id: room.id,
                      text: room.attributes.name,
                      attributes: {
                        name: room.attributes.name,
                      },
                    }
                  })

                  formattedRooms.unshift({
                    id: "none",
                    text: "Virtual Sessions",
                    attributes: {
                      name: "Virtual Sessions",
                    },
                  })

                  const nonDupeRooms = []
                  formattedRooms.forEach((room) => {
                    const existing = nonDupeRooms.find(
                      (nonDupeRoom) => nonDupeRoom.id === room.id
                    )
                    if (!existing) {
                      nonDupeRooms.push(room)
                    }
                  })

                  this.setState({
                    resources: [
                      {
                        fieldName: "roomId",
                        title: "",
                        instances: nonDupeRooms,
                      },
                    ],
                    selectedLocation: location,
                  })
                }}
                setCurrentTab={(currentTab) => this.setState({ currentTab })}
                rooms={roomsForSelectedLocation}
                currentTab={this.state.currentTab}
                canAddSession={canAddSession}
                currentDate={currentDate}
                currentDay={currentDay}
                duration={duration}
                handleDayChange={this.handleDayChange}
                handleViewChange={this.handleViewChange}
                conference={conference}
              />
              <Paper>
                <div
                  className={classNames(
                    "scheduleWrapper",
                    conference.isEditingEvent && "shift",
                    conference.field_draft && "draft"
                  )}
                >
                  {this.state.showCalendar && (
                    <>
                      {this.state.currentTab === "calendar" ? (
                        <Scheduler data={filteredEvents}>
                          <ViewState
                            currentDate={currentDate}
                            currentViewName={"Day"}
                          />

                          <EditingState onCommitChanges={this.commitChanges} />

                          <IntegratedEditing />

                          <GroupingState
                            grouping={grouping}
                            groupByDate={() => {
                              return true
                            }}
                          />

                          <DayView
                            timeTableCellComponent={TimeTableCell}
                            timeScaleLabelComponent={TimeLabel}
                            timeScaleTickCellComponent={TickCell}
                            cellDuration={60}
                            startDayHour={0}
                            endDayHour={24}
                          />

                          <Appointments
                            appointmentComponent={this.myAppointment}
                          />

                          <Resources
                            data={resources}
                            mainResourceName="roomId"
                          />

                          {formattedEvents.length > 0 && (
                            <AppointmentTooltip
                              visible={
                                visible &&
                                !conference.isEditingEvent &&
                                !conference.isCopyingEvent
                              }
                              layoutComponent={this.toolTipLayout}
                              contentComponent={EventTooltip}
                              onVisibilityChange={this.toggleVisibility}
                              appointmentMeta={appointmentMeta}
                              onAppointmentMetaChange={
                                this.onAppointmentMetaChange
                              }
                            />
                          )}

                          <IntegratedGrouping />
                          <IntegratedEditing />

                          <GroupingPanel />
                          {canAddSession && (
                            <DragDropProvider
                              draftAppointmentComponent={this.myAppointment}
                              sourceAppointmentComponent={
                                this.sourceAppointment
                              }
                            />
                          )}
                          <CurrentTimeIndicator
                            indicatorComponent={this.TimeIndicator}
                          />
                        </Scheduler>
                      ) : (
                        <ConferenceEventList
                          allRooms={this.props.allRooms}
                          filteredEvents={filteredEvents}
                          loadConferenceEvent={this.loadConferenceEvent}
                        />
                      )}
                    </>
                  )}
                </div>
                {conference.isEditingEvent && (
                  <div className="newEventPosition">
                    <div
                      className={classNames(
                        "newEventWrapper",
                        conference.isEditingEvent && "shift"
                      )}
                    >
                      <ConferenceEventLayout history={this.props.history} />
                    </div>
                  </div>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBlock: 6,
                    marginTop: 0,
                    marginBottom: -6,
                  }}
                >
                  {!conference.isEditingEvent &&
                    conference.currentTab !== "list" && (
                      <Box sx={{ width: 200, position: "relative", zIndex: 1 }}>
                        <SettingSwitch
                          checked={this.state.showArchived}
                          label={"Show Archived"}
                          onChange={() => {
                            this.setState({
                              showArchived: !this.state.showArchived,
                            })
                          }}
                        />
                      </Box>
                    )}
                </Box>
              </Paper>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceSchedule)
