import { Component } from "react"
import { Button } from "@mui/material"
import { connect } from "react-redux"

import {
  getSingleOrg,
  updateSearch,
  saveOrganization,
} from "./organizationsSlice"
import "../../styles/organizations.scss"

import Loading from "@mobilemind/common/src/components/Loading"
import { FormControlLabel, Checkbox } from "@mui/material"
import { getCategories, setIsVisible } from "../../store/reducers/categories"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import moment from "moment"
import classNames from "classnames"

import { withRouter } from "react-router-dom"

const mapStateToProps = ({ organizations, categories }, ownProps) => {
  return {
    organizations,
    categories,
    orgId: ownProps.match.params.id,
    activeOrg: organizations.activeOrg,
  }
}

const mapDispatchToProps = {
  getSingleOrg,
  updateSearch,
  getCategories,
  setIsVisible,
  saveOrganization,
}

class OrganizationSingle extends Component {
  componentDidMount() {
    const { orgId } = this.props
    this.props.getSingleOrg({ orgId }).then((response) => {
      this.props.getCategories({
        orgId: response.payload.id,
        excludedCategories: response.payload.excludedCategories,
      })
    })
  }

  saveChanges = () => {
    this.props.saveOrganization().then(() => {
      this.props.history.push("/organizations")
    })
  }

  render() {
    const { activeOrg, categories, organizations } = this.props

    let hasFeatures
    if (activeOrg.attributes) {
      const { field_trial, field_org_hide_mm_content, field_email_block } =
        activeOrg.attributes
      hasFeatures =
        field_trial || field_org_hide_mm_content || field_email_block
    }

    let orgPartners =
      activeOrg.included &&
      activeOrg.included.filter(
        (included) => included.type === "group--partner"
      )
    let successRep =
      activeOrg.included &&
      activeOrg.included.find((included) => included.type === "user--user")

    const address =
      activeOrg.attributes && activeOrg.attributes.field_organization_address

    let licenses = { premium: 0, teacher: 0, total: 0 }

    if (activeOrg.attributes) {
      licenses.premium = activeOrg.attributes.field_max_org_admins
        ? activeOrg.attributes.field_max_org_admins
        : 0
      licenses.total = activeOrg.attributes.field_max_teacher
        ? activeOrg.attributes.field_max_teacher
        : 0
      licenses.teacher = licenses.total - licenses.premium
    }

    return (
      <>
        {!activeOrg.id ? (
          <Loading fullPage={true} message={"Getting organization..."} />
        ) : (
          <div className={classNames("page organization-single")}>
            {organizations.isSaving && (
              <Loading message={"Saving organization..."} />
            )}
            <div
              className={classNames(
                "inner",
                organizations.isSaving && "saving"
              )}
            >
              <div className="subnav">
                <span className={"title"}>
                  <span className={"icon school-building-white"} />
                  {activeOrg.attributes.label}
                </span>

                <Button
                  onClick={() => {
                    this.saveChanges()
                  }}
                  className="button small"
                >
                  <span className="icon save" />
                  Save Changes
                </Button>
              </div>

              <div id="sidebar">
                {address && address.locality && address.administrative_area && (
                  <div className="sidebar-section location">
                    <div className="flexRow">
                      <span className="icon location color" />
                      <div className="address">
                        <span>{address.address_line1}</span>
                        <span>{address.address_line2}</span>
                        <span>
                          {address.locality && <>{address.locality}, </>}
                          {address.administrative_area} {address.postal_code}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {hasFeatures && (
                  <div className="sidebar-section">
                    <h3>Features</h3>
                    <ul className="feature-list">
                      {activeOrg.attributes.field_trial && (
                        <li>
                          <span className="icon trial" />
                          Trial Organization
                        </li>
                      )}
                      {activeOrg.attributes.field_org_hide_mm_content && (
                        <li>
                          <span className="icon mm-hidden" />
                          MobileMind Content Hidden
                        </li>
                      )}
                      {activeOrg.attributes.field_email_block && (
                        <li>
                          <span className="icon no-email" />
                          Emails Blocked
                        </li>
                      )}
                    </ul>
                  </div>
                )}

                {orgPartners && orgPartners.length > 0 && (
                  <div className="sidebar-section partnerships">
                    <h3>Content Partners</h3>
                    <ul>
                      {orgPartners.map((partner) => {
                        return (
                          <li key={partner.id}>
                            <span className="icon partnership" />{" "}
                            {partner.attributes.label}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )}

                {successRep && (
                  <div className="sidebar-section">
                    <h3>Customer Succcess Rep</h3>
                    <ul>
                      <li>
                        <UserInitial
                          fontSize={14}
                          size={35}
                          initials={
                            successRep.attributes.field_first_name
                              .substring(0, 1)
                              .toUpperCase() +
                            successRep.attributes.field_last_name
                              .substring(0, 1)
                              .toUpperCase()
                          }
                        />
                        <span style={{ marginLeft: 8 }}>
                          {successRep.attributes.field_first_name}{" "}
                          {successRep.attributes.field_last_name}
                        </span>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="sidebar-section licenses">
                  <h3>Licenses</h3>
                  <ul>
                    <li>
                      <span>Teacher</span>
                      <strong>{licenses.teacher}</strong>
                    </li>
                    <li>
                      <span>Premium</span>
                      <strong>{licenses.premium}</strong>
                    </li>
                    <li>
                      <span>Total</span>
                      <strong className="total">{licenses.total}</strong>
                    </li>
                  </ul>
                </div>

                {activeOrg.attributes.field_renewal_period_begin && (
                  <div className="sidebar-section renewal">
                    <h3>Renewal Period</h3>
                    <span>Start</span>
                    <strong>
                      {moment(
                        activeOrg.attributes.field_renewal_period_begin
                      ).format("MMMM Do, YYYY")}
                    </strong>
                    <span>End</span>
                    <strong>
                      {moment(
                        activeOrg.attributes.field_renewal_period_end
                      ).format("MMMM Do, YYYY")}
                    </strong>
                  </div>
                )}
              </div>

              <h3>Excluded Categories</h3>

              <div className="manageCategories embed">
                <header className="flexRow">
                  <div>Image</div>
                  <div className="name">Name</div>
                  <div className="grow">
                    Visible to Learners <span className="icon question" />
                  </div>
                </header>

                <ol className="categoryList">
                  {categories.topCategories &&
                    categories.topCategories.map((category, index) => {
                      const children = categories.subCategories.filter(
                        (sub) =>
                          sub.relationships.parent.data[0].id === category.id
                      )

                      return (
                        <li key={index} className="topCategory">
                          <FormControlLabel
                            className="checkbox topCategoryCheckbox"
                            control={
                              <Checkbox
                                checked={category.isVisible}
                                onChange={(event) => {
                                  this.props.setIsVisible({
                                    category: category,
                                    value: event.target.checked,
                                  })
                                }}
                                name="visible"
                              />
                            }
                          />

                          <Accordion>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <div className="flexRow">
                                {category.image && (
                                  <img
                                    className="categoryImage"
                                    src={
                                      process.env.REACT_APP_API_URL +
                                      category.image.attributes.uri.url
                                    }
                                    alt={category.attributes.name}
                                  />
                                )}

                                <div className="title">
                                  {category.attributes.name}
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              {children && children.length > 0 && (
                                <ol className="subCategoryList">
                                  {children.map((child) => {
                                    const isOrgCategory =
                                      child.relationships.field_organization
                                        .data
                                    if (!isOrgCategory) {
                                      return (
                                        <li key={child.id}>
                                          {child.image && (
                                            <img
                                              className="categoryImage"
                                              src={
                                                process.env.REACT_APP_API_URL +
                                                child.image.attributes.uri.url
                                              }
                                              alt={child.attributes.name}
                                            />
                                          )}

                                          <div className="title">
                                            {child.attributes.name}
                                          </div>

                                          <FormControlLabel
                                            className="checkbox"
                                            control={
                                              <Checkbox
                                                checked={child.isVisible}
                                                onChange={(event) => {
                                                  this.props.setIsVisible({
                                                    category: child,
                                                    value: event.target.checked,
                                                  })
                                                }}
                                                name="visible"
                                              />
                                            }
                                          />
                                        </li>
                                      )
                                    }

                                    return null
                                  })}
                                </ol>
                              )}

                              {/* <OrgCategoryList manageOrg={true} parent={category} /> */}
                            </AccordionDetails>
                          </Accordion>
                        </li>
                      )
                    })}
                </ol>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationSingle)
)
