import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material"
import { CourseFormValues } from "../types"
import Jigsaw from "../../../img/jigsaw.svg"

type ChallengeQuizProps = {
  course: CourseFormValues
}

export const TemplateChallengeQuiz = ({ course }: ChallengeQuizProps) => {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{ alignItems: "center", marginBottom: 3 }}
        spacing={2}
      >
        <img src={Jigsaw} width={30} height={30} alt="Question" />
        <Typography variant="h3">Challenge Quiz</Typography>
      </Stack>

      <Stack sx={{ marginTop: 2 }} spacing={2}>
        {course.quizQuestions.map((question, index) => {
          return (
            <Box key={index}>
              <Stack
                direction={"row"}
                sx={{ alignItems: "center" }}
                spacing={2}
              >
                <Stack
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "grey.A200",
                    width: 30,
                    height: 30,
                    fontWeight: "bold",
                    borderRadius: 6,
                  }}
                >
                  {index + 1}
                </Stack>
                <Typography sx={{ fontWeight: "bold" }}>
                  {question.value}
                </Typography>
              </Stack>

              <Grid
                container
                spacing={2}
                sx={{ marginTop: 1, marginBottom: 2 }}
              >
                {question.answers.map((answer, index) => {
                  return (
                    <Grid key={index} item xs={6}>
                      <Stack
                        direction="row"
                        sx={{
                          alignItems: "flex-start",
                          marginLeft: -1,
                        }}
                        spacing={2}
                      >
                        <FormControlLabel
                          sx={{ marginRight: -1 }}
                          label=""
                          control={
                            <Checkbox
                              disabled={true}
                              checked={answer.isCorrect}
                            />
                          }
                        />
                        <Typography sx={{ fontSize: 14, paddingTop: 2 }}>
                          {answer.value}
                        </Typography>
                      </Stack>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          )
        })}
      </Stack>

      <Stack
        direction="row"
        sx={{
          alignItems: "flex-start",
          marginBottom: 6,
          marginTop: 4,
        }}
        spacing={5}
      >
        <Box sx={{ flex: 1, marginBottom: 10 }}>
          <Stack direction="row" sx={{ flex: 1, alignItems: "center" }}>
            <Typography sx={{ flex: 1 }} variant="h3">
              Show Missed Questions
            </Typography>
            <Checkbox checked={course.showMissedQuestions} disabled={true} />
          </Stack>

          <Stack direction="row" sx={{ flex: 1, alignItems: "center" }}>
            <Typography sx={{ flex: 1 }} variant="h3">
              Show Correct Answers
            </Typography>
            <Checkbox checked={course.showCorrectAnswers} disabled={true} />
          </Stack>
        </Box>

        <Box sx={{ flex: 1, paddingTop: 2 }}>
          <Typography gutterBottom={true} sx={{ flex: 1 }} variant="h3">
            Required Score to Pass
          </Typography>
          <Typography sx={{ fontSize: 14 }}>
            {course.quizPassThreshold}%
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}
