import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import { DatePicker } from "@mui/x-date-pickers"
import { Component, useEffect } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"

import Loading from "@mobilemind/common/src/components/Loading"
import classNames from "classnames"
import ImageUpload from "../../components/ImageUpload"
import { isGroupLevel } from "../../functions"
import AnnouncementNavbarContainer from "./AnnouncementNavbarContainer"
import moment from "moment"

import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { getMandatedTraining } from "../../actions/mandatedTraining"

import {
  fetchActiveAnnouncement,
  removeImage,
  setBody,
  setExpirationDate,
  setFeaturedCourse,
  setFeaturedLearningPath,
  setMandatedTraining,
  setFilename,
  setName,
  setPublishMethod,
  setSubGroups,
  setType,
  startNewAnnouncement,
  fetchLearningPathList,
  updateField,
  startNewPartnerAnnouncement,
  setPartnerType,
  setImage,
} from "../createAnnouncement/activeAnnouncementSlice"

import { getSubGroups } from "../../store/reducers/session"
import { fetchAnnouncementTypes } from "../announcementTypes/announcementTypesSlice"

import { getCourses } from "../../actions/courses"

import ErrorBoundary from "../../components/ErrorBoundary"
import "../../styles/create.scss"
import { useAppDispatch } from "store/hooks"
import { formats, modules } from "../../constants/reactQuillConfig"

const mapStateToProps = (
  { session, activeAnnouncement, announcementTypes, courses, entityList },
  ownProps
) => {
  let filteredAnnouncementTypes = announcementTypes.data.filter((type) => {
    let name = type.attributes.name
    return (
      name !== "Support" &&
      name !== "Social" &&
      name !== "Survey" &&
      name !== "MobileMind News"
    )
  })

  return {
    session,
    announcement: activeAnnouncement,
    mandatedTraining: entityList.mandatedTraining,
    filteredAnnouncementTypes,
    announcementTypes,
    partner: ownProps.location.state?.partner,
    selectedGroups: ownProps.location.state?.selectedGroups,
    courses,
    learningPaths: activeAnnouncement.learningPaths,
  }
}

const mapDispatchToProps = {
  getCourses,
  fetchLearningPathList,
  fetchAnnouncementTypes,
  getSubGroups,
  getMandatedTraining,
}

function AnnouncementForm(props) {
  const dispatch = useAppDispatch()

  const announcementId = useParams().id
  let {
    session,
    announcement,
    subGroups,
    filteredAnnouncementTypes,
    mandatedTraining,
    courses,
    partner,
    selectedGroups,
  } = props

  const partnerType = filteredAnnouncementTypes.find((type) => {
    return type.attributes.name === "New Partner"
  })

  useEffect(() => {
    if (announcementId !== "new") {
      dispatch(fetchActiveAnnouncement(announcementId))
    } else if (partner) {
      dispatch(startNewAnnouncement())
      dispatch(startNewPartnerAnnouncement({ partner }))
      dispatch(setPartnerType(partnerType))
      dispatch(setSubGroups(selectedGroups))
      dispatch(setImage(selectedGroups))
    } else {
      dispatch(startNewAnnouncement())
    }
  }, [partner, announcementId, dispatch, partnerType, selectedGroups])

  const handleSubGroupChange = (event, values) => {
    dispatch(setSubGroups(values))
  }

  function handleBodyChange(newValue) {
    dispatch(setBody(newValue))
  }

  const isCollectionAdminView =
    session.isCollectionAdmin && !session.activeMembership.data

  const featuredCourseType = filteredAnnouncementTypes.find(
    (type) => type.attributes.name === "Featured Course"
  )
  const featuredLearningPathType = filteredAnnouncementTypes.find(
    (type) => type.attributes.name === "Featured Learning Path"
  )
  const mandatedTrainingType = filteredAnnouncementTypes.find(
    (type) => type.attributes.name === "Mandated Training"
  )

  const isFeaturedCourse =
    featuredCourseType && announcement.type === featuredCourseType.id

  const isFeaturedLearningPath =
    featuredLearningPathType &&
    announcement.type === featuredLearningPathType.id

  const isMandatedTraining =
    mandatedTrainingType && announcement.type === mandatedTrainingType.id

  if (session.subgroups && session.subgroups.data) {
    subGroups = session.subgroups
  }

  if (session.subgroups && session.subgroups.data && isGroupLevel(session)) {
    subGroups = session.subgroups
  } else {
    subGroups = props.subGroups
  }

  // Single group member
  const isSingleGroupMember =
    isGroupLevel(session) && subGroups.data.length === 1

  let ownerValue
  if (!isCollectionAdminView) {
    if (isGroupLevel(session)) {
      ownerValue = announcement.subGroupOwner
        ? announcement.subGroupOwner
        : subGroups.data[0].id
    } else {
      ownerValue = announcement.subGroupOwner
        ? announcement.subGroupOwner
        : "org"
      let canFindGroup = subGroups.data.find(
        (group) => group.id === announcement.subGroupOwner
      )

      if (!canFindGroup && ownerValue !== "org") {
        let byEntityId = subGroups.data.find(
          (group) =>
            group.relationships.entity_id.data.id === announcement.subGroupOwner
        )
        if (byEntityId) {
          ownerValue = byEntityId.id
        }
      }
    }
  }
  const allLearningPaths = announcement.learningPaths.data

  if (!announcement.fetched) {
    return <Loading fullPage={true} message={"Getting announcement..."} />
  } else {
    const type = filteredAnnouncementTypes.find(
      (announcementType) => announcementType.id === announcement.type
    )
    const isImageAllowed =
      type &&
      type.attributes.name !== "Featured Course" &&
      type.attributes.name !== "Featured Learning Path" &&
      type.attributes.name !== "Mandated Training"

    let emailHelperText
    if (announcement.publishMethod === "email_and_announcement") {
      emailHelperText =
        "Learners will receive an email containing this announcement and will see it on their dashboard until it expires."
    } else if (announcement.publishMethod === "no_email") {
      emailHelperText =
        "Learners will see this announcement on their dashboard until it expires."
    } else {
      emailHelperText =
        "Learners will receive an email containing this announcement, but it will not appear on their dashboard."
    }

    let canEmail =
      session.group &&
      (!session.group.field_email_block[0] ||
        !session.group.field_email_block[0].value)
    if (session.userCollection && session.userCollection[0]) {
      canEmail = true
    }
    return (
      <div className="flexRow">
        <div className="column left">
          {isImageAllowed && (
            <ImageUpload
              shape={"square"}
              activeImage={announcement.image}
              setImage={setImage}
              setFilename={setFilename}
              removeImage={removeImage}
            />
          )}

          <div className="column right groupFields">
            {subGroups.data.length > 0 && (
              <>
                <h3>Announcement Owner</h3>
                <FormControl
                  variant="standard"
                  className={classNames(
                    "inputSelect subGroupSelect",
                    (isSingleGroupMember || !isGroupLevel(session)) && "locked"
                  )}
                >
                  <Select
                    variant="standard"
                    labelId="label-subgroup-owner-select"
                    id="subgroup-owner-select"
                    value={ownerValue}
                    onChange={(event) =>
                      dispatch(
                        updateField({
                          field: "subGroupOwner",
                          value: event.target.value,
                        })
                      )
                    }
                  >
                    {props.session.orgRoles.includes("organization-admin") && (
                      <MenuItem key={0} value={"org"}>
                        Org - {props.session.group.label[0].value}
                      </MenuItem>
                    )}
                    {subGroups.data.map((group) => {
                      return (
                        <MenuItem key={group.id} value={group.id}>
                          {group.attributes.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <p
                    style={{
                      fontSize: "0.75rem",
                      color: "rgba(0, 0, 0, 0.54)",
                      margin: "10px 0 0",
                      lineHeight: "1.4",
                    }}
                  >
                    {ownerValue === "org" ? (
                      <>
                        Organization Admins and Creators will be able to edit.
                      </>
                    ) : (
                      <>
                        Group Admins and Creators in{" "}
                        {isSingleGroupMember ? (
                          <>your group</>
                        ) : (
                          <>this group</>
                        )}{" "}
                        will be able to edit this course. Organization Admins
                        and Creators will also be able to edit.
                      </>
                    )}
                  </p>
                </FormControl>
              </>
            )}

            {subGroups.data.length > 0 && (
              <>
                <div
                  className={classNames(
                    "required",
                    announcement.missingFields.includes(
                      "Make visible to at least 1 group"
                    ) && "active"
                  )}
                >
                  <h3 style={{ margin: "-10px 0 -15px 0" }}>
                    Group Visibility
                  </h3>
                  <FormControl
                    variant="standard"
                    style={{ width: "100%" }}
                    className={classNames(
                      "subGroupSelect",
                      isSingleGroupMember && "locked"
                    )}
                  >
                    <Autocomplete
                      multiple
                      id="subgroup-select"
                      options={subGroups.data.length > 0 ? subGroups.data : []}
                      value={
                        isSingleGroupMember
                          ? subGroups.data
                          : announcement.subGroups
                      }
                      getOptionLabel={(option) => option.attributes.label}
                      onChange={(event, values) =>
                        handleSubGroupChange(event, values)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={"All Groups"}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                  <p
                    style={{
                      fontSize: "0.75rem",
                      color: "rgba(0, 0, 0, 0.54)",
                      margin: isSingleGroupMember
                        ? "-35px 0 35px"
                        : "-10px 0 0",
                      lineHeight: "1.4",
                    }}
                  >
                    Learners in{" "}
                    {isSingleGroupMember ? <>your group</> : <>these groups</>}{" "}
                    will be able to view this announcement in the Learn app.
                  </p>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="column right">
          <div
            className={classNames(
              "required",
              announcement.missingFields.includes("Title") && "active"
            )}
          >
            <TextField
              variant="standard"
              label="Title"
              required
              value={announcement.name}
              inputProps={{ maxLength: 50 }}
              helperText={"Maximum length is 50 characters."}
              onChange={(event) => dispatch(setName(event.target.value))}
            />
          </div>
          {filteredAnnouncementTypes.length > 0 && (
            <FormControl
              variant="standard"
              className={classNames(
                "required",
                announcement.missingFields.includes("Title") && "active"
              )}
            >
              <InputLabel
                style={{ marginLeft: 0 }}
                required
                id="label-type-select"
              >
                Type
              </InputLabel>
              <Select
                variant="standard"
                labelId="label-type-select"
                id="type-select"
                value={
                  announcement.type
                    ? announcement.type
                    : filteredAnnouncementTypes[0].id
                }
                onChange={(event) => dispatch(setType(event.target.value))}
              >
                <MenuItem key={0} value={"Select Type"}>
                  Select Type
                </MenuItem>
                {filteredAnnouncementTypes.map((type) => {
                  let shouldReturn = true
                  if (isCollectionAdminView) {
                    if (
                      type.attributes.name === "Featured Course" ||
                      type.attributes.name === "Featured Learning Path" ||
                      type.attributes.name === "Mandated Training"
                    ) {
                      shouldReturn = false
                    }
                  }
                  if (shouldReturn) {
                    return (
                      <MenuItem key={type.id} value={type.id}>
                        {type.attributes.name}
                      </MenuItem>
                    )
                  }
                  return null
                })}
              </Select>
            </FormControl>
          )}

          {isFeaturedCourse && courses.data.length > 0 && (
            <FormControl variant="standard">
              <Autocomplete
                id="label-course-select"
                options={courses.data}
                value={
                  announcement.featuredCourse
                    ? announcement.featuredCourse
                    : null
                }
                getOptionLabel={(course) => course.attributes.name}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Featured Course"
                  />
                )}
                onChange={(event, newValue) =>
                  dispatch(setFeaturedCourse(newValue))
                }
              />
            </FormControl>
          )}

          {isFeaturedLearningPath && (
            <FormControl variant="standard">
              <Autocomplete
                id="label-course-select"
                options={allLearningPaths}
                value={
                  announcement.featuredLearningPath
                    ? announcement.featuredLearningPath
                    : null
                }
                getOptionLabel={(path) =>
                  path.attributes ? path.attributes.name : path.name
                }
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Featured Learning Path"
                  />
                )}
                onChange={(event, newValue) =>
                  dispatch(setFeaturedLearningPath(newValue))
                }
              />
            </FormControl>
          )}

          {isMandatedTraining && mandatedTraining.fetched && (
            <FormControl variant="standard">
              <Autocomplete
                id="label-training-select"
                options={mandatedTraining.data}
                value={
                  announcement.selectedTraining
                    ? announcement.selectedTraining
                    : null
                }
                getOptionLabel={(training) => training.name}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Mandated Training"
                  />
                )}
                onChange={(event, newValue) =>
                  dispatch(setMandatedTraining(newValue))
                }
              />
            </FormControl>
          )}

          <div
            style={{ marginBottom: 30 }}
            className={classNames(
              "required",
              announcement.missingFields.includes("Body") && "active"
            )}
          >
            <InputLabel id="label-resources">Body</InputLabel>
            <ReactQuill
              theme={"snow"}
              onChange={handleBodyChange}
              value={announcement.body}
              modules={modules}
              formats={formats}
            />
          </div>
          <div className="datepicker-form-control">
            <DatePicker
              label="Expiration Date"
              value={moment(announcement.expirationDate)}
              onChange={(date) => {
                dispatch(setExpirationDate(JSON.stringify(date)))
              }}
            />
          </div>
          {canEmail && (
            <FormControl variant="standard">
              <InputLabel required id="label-publish-method-select">
                Publish Method
              </InputLabel>
              <Select
                variant="standard"
                labelId="label-publish-method-select"
                id="publish-method-select"
                value={
                  announcement.publishMethod
                    ? announcement.publishMethod
                    : "email_and_announcement"
                }
                onChange={(event) =>
                  dispatch(setPublishMethod(event.target.value))
                }
              >
                <MenuItem value={"email_and_announcement"}>
                  Email and Dashboard
                </MenuItem>
                <MenuItem value={"no_email"}>Dashboard Only</MenuItem>
                <MenuItem value={"email_only"}>Email Only</MenuItem>
              </Select>
              <p className="MuiFormHelperText-root">{emailHelperText}</p>
            </FormControl>
          )}
        </div>
      </div>
    )
  }
}

class CreateAnnouncementLayout extends Component {
  componentDidMount = () => {
    const {
      courses,
      mandatedTraining,
      announcementTypes,
      session,
      announcement,
    } = this.props
    if (!session.isCollectionAdmin) {
      this.props.getSubGroups(session.group.uuid[0].value)
      !courses.fetched && this.props.getCourses()
      !mandatedTraining.fetched &&
        this.props.getMandatedTraining(mandatedTraining.filters)
      !announcement.learningPaths.fetched && this.props.fetchLearningPathList()
    }
    !announcementTypes.fetched && this.props.fetchAnnouncementTypes()
  }

  render() {
    const props = {
      session: this.props.session,
      courses: this.props.courses,
      announcement: this.props.announcement,
      filteredAnnouncementTypes: this.props.filteredAnnouncementTypes,
      orgAnnouncements: this.props.orgAnnouncements,
      subGroups: this.props.session.subGroups,
      partner: this.props.partner,
      selectedGroups: this.props.selectedGroups,
      mandatedTraining: this.props.mandatedTraining,
    }

    return (
      <ErrorBoundary>
        <div className="page create announcement">
          <AnnouncementNavbarContainer history={this.props.history} />
          <AnnouncementForm {...props} />
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAnnouncementLayout)
